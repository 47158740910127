import React, { useContext } from 'react';
import { BasicDialog } from '../../../../../common/ui-components/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { IconButton, TextField, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { TabPanel, TabsProps } from '../../../../../common/ui-components/Tab';
import EffectiveSettingsStep from '../effective-settings/effective-settings';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { clearStoreUpdateProfile, fetchUpdateProfile } from '../../../../../store/profile/profiles';
import { useSnackbar } from 'notistack';
import { UserPermission } from '../../../../../config';
import InspectionFlow from '../inspection-flow/inspection-flow';
import PopupBack from '../../../../popup-back/popup-back';
import { showPopup } from '../../../../../store/events/popup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { classes } from '../../style';
import { IArgsProfile, IProfile } from '../../../../../infrastructure/DTO/profile/profile.dto';
import { ICompany } from '../../../../../infrastructure/DTO/company/company.dto';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { IUserManagementProvider, UserManagementContext } from '../../../../../context/UserManagementProvider';
import { ProfileName } from '../profile-name';
import { ExportImportButtons } from '../export-import-buttons';
import { IEffectiveSettings } from '../../../../../infrastructure/DTO/effective-settings/effective-settings.dto';
import { IInspectionFlow } from '../../../../../infrastructure/DTO/Inspection-flow/Inspection-flow.dto';
import { importEffectiveSettings } from '../../../../../store/effective-settings/effective-settings';
import { importInspectionFlow } from '../../../../../store/effective-settings/inspection-flow';
import { ExportFeaturesCsv, ExportInspectionFlowCsv } from '../../../../../types/ExportCsvType';
import errorParseMessage from '../../../../../config/error-parse';

interface IEditRoleComponent {
    data: IProfile | null;
    company_name: string;
}

type PopupBackType = {
    show: boolean;
    close: boolean;
    tab: number;
};

const EditProfile: React.FC<IEditRoleComponent> = (props: IEditRoleComponent) => {
    const { token, checkPermission } = useContext(CustomAuthContext);
    const [popupBack, setPopupBack] = React.useState<PopupBackType>({ show: false, close: false, tab: 1 });
    const { company, openEditProfile, handleEditProfile, buttonNext } = useContext<IUserManagementProvider>(UserManagementContext);

    const { effectiveSettings } = useSelector((state: AppState) => state.effectiveSettings);
    const { inspectionFlow } = useSelector((state: AppState) => state.inspectionFlow);

    const { statusUpdate, errorUpdate } = useSelector((state: AppState) => state.profiles);
    const { popup } = useSelector((state: AppState) => state.popupEvent);
    const { enqueueSnackbar } = useSnackbar();
    const [valueTab, setValueTab] = React.useState<number>(0);
    const dispatch: AppDispatch = useDispatch();
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const [profileName, setProfileName] = React.useState<string>('');

    const [featuresToCsv, setFeaturesToCsv] = React.useState<Array<ExportFeaturesCsv>>([]);
    const [inspectionFlowToCsv, setInspectionFlowToCsv] = React.useState<Array<ExportInspectionFlowCsv>>([]);

    const handleEffectiveSettings = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    /**
     * Set data InspectionFlow to export CSV
     */
    React.useEffect(() => {
        if (inspectionFlow.inspection_flow) {
            let dataCsv: Array<ExportInspectionFlowCsv> = [];

            inspectionFlow.inspection_flow.inspection_flow_sections.forEach((item) => {
                dataCsv.push({
                    name: item.section_name,
                    is_active: item.is_active,
                    position: item.order_position,
                    type: item.section_id,
                    validation: item.validation,
                });
                item.inspection_flow_images.forEach((flow_images) => {
                    if (flow_images.part_id !== null && flow_images.detection_status_mode.code === 'MANUAL') {
                        dataCsv.push({
                            name: flow_images.part_name as string,
                            is_active: flow_images.is_active,
                            position: flow_images.order_position,
                            type: flow_images.part_id as string,
                            side: 'Part',
                        });
                    }
                    if (flow_images.camera_view !== null && flow_images.detection_status_mode.code === 'AUTOMATED') {
                        dataCsv.push({
                            name: flow_images.camera_view.description as string,
                            is_active: flow_images.is_active,
                            position: flow_images.order_position,
                            type: flow_images.part_id as string,
                            side: 'Side',
                        });
                    }
                });
            });

            setInspectionFlowToCsv(dataCsv);
        }
    }, [inspectionFlow]);

    /**
     * Set data EffectiveSettings to export CSV
     */
    React.useEffect(() => {
        if (effectiveSettings.length > 0) {
            let dataCsv: Array<ExportFeaturesCsv> = [];

            effectiveSettings.forEach((item) => {
                if (item.setting.is_active) {
                    dataCsv.push({
                        feature_name: item.setting.name,
                        value: item.value,
                        description: item.setting.description,
                    });
                }
            });

            setFeaturesToCsv(
                dataCsv
                    .slice(0)
                    .sort((a, b) =>
                        a.feature_name.toLowerCase() < b.feature_name.toLowerCase()
                            ? -1
                            : a.feature_name.toLowerCase() > b.feature_name.toLowerCase()
                            ? 1
                            : 0
                    )
            );
        }
    }, [effectiveSettings]);

    /**
     * Change Tab View
     * @param event
     * @param newValue
     */
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (!popup) {
            setValueTab(newValue);
        } else {
            setPopupBack({ show: true, close: false, tab: newValue });
        }
    };

    /**
     * Get Status Update Profile
     * Close Popup after status Succeeded
     */
    React.useEffect(() => {
        if (statusUpdate === StatusRedux.Succeeded) {
            enqueueSnackbar('Updated', {
                variant: 'success',
            });

            dispatch(clearStoreUpdateProfile());
        }

        if (statusUpdate === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorUpdate), {
                variant: 'error',
            });

            dispatch(clearStoreUpdateProfile());
        }
    }, [statusUpdate]);

    /**
     * Close Popup
     */
    const handlePopup = () => {
        if (!popup) {
            handleEditProfile();
            setValueTab(0);
        } else {
            setPopupBack({ show: true, close: true, tab: 1 });
        }
    };

    const handleClosePopup = () => {
        setPopupBack({ show: false, close: true, tab: 0 });
    };

    const back = () => {
        dispatch(showPopup(false));

        if (popupBack.close) {
            setPopupBack({ show: false, close: true, tab: 0 });
            handleEditProfile();
            setValueTab(0);
        } else {
            setValueTab(popupBack.tab);
            setPopupBack({ show: false, close: false, tab: popupBack.tab });
        }
    };

    /**
     * Update Profile
     * @param event
     */
    const handleSend = (event: any) => {
        event.preventDefault();

        const args: IArgsProfile = {
            company_id: company?.company_id,
            profile_id: props.data?.profile_id as string,
        };

        const body: Partial<IProfile> = {
            name: profileName,
        };

        dispatch(fetchUpdateProfile({ token, body, args }));
    };

    /**
     * Update global state effective settings
     * @param obj
     * @interface IEffectiveSettings
     */
    const updateStateEffectiveSettings = (obj: Array<IEffectiveSettings>): void => {
        if (obj.length > 0) {
            if (checkObjIsEffectiveSettings(obj[0])) {
                dispatch(importEffectiveSettings(obj));

                enqueueSnackbar('Data is imported, click to save.', {
                    variant: 'success',
                });
            }
        }
    };

    /**
     * Update global state inspection flow
     * @param obj
     * @interface IInspectionFlow
     */
    const updateStateIInspectionFlow = (obj: IInspectionFlow): void => {
        if (checkObjIsIInspectionFlow(obj)) {
            dispatch(importInspectionFlow(obj));
            enqueueSnackbar('Data is imported, click to save.', {
                variant: 'success',
            });
        }
    };

    const checkObjIsEffectiveSettings = (obj: IEffectiveSettings): obj is IEffectiveSettings =>
        'effective_setting_id' in obj && 'setting' in obj && 'value' in obj;
    const checkObjIsIInspectionFlow = (obj: IInspectionFlow): obj is IInspectionFlow => 'inspection_flow' in obj;

    return (
        <BasicDialog
            handlePopup={handlePopup}
            open={openEditProfile}
            title={profileName ?? ''}
            width='xl'
            buttons={
                <Grid container spacing={3} justifyContent='flex-end'>
                    {valueTab === 0 && (
                        <Grid item>
                            {!checkPermission(UserPermission.USER_MANAGEMENT) && (
                                <Button
                                    disabled={buttonNext}
                                    form='edit-profile'
                                    size='small'
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                    color='secondary'
                                    endIcon={<SaveIcon />}
                                >
                                    Save
                                </Button>
                            )}
                        </Grid>
                    )}
                    {valueTab === 1 && (
                        <Grid item xs>
                            {!checkPermission(UserPermission.USER_MANAGEMENT) && (
                                <Grid container justifyContent='space-between'>
                                    <Grid item>
                                        <ExportImportButtons<Array<IEffectiveSettings>, Array<ExportFeaturesCsv>>
                                            dataToCsv={featuresToCsv}
                                            data={effectiveSettings}
                                            file_name={`${company?.name}__${props.data?.name}`}
                                            updateState={updateStateEffectiveSettings}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            form='effective-settings'
                                            size='small'
                                            fullWidth
                                            type='submit'
                                            variant='contained'
                                            color='secondary'
                                            endIcon={<SaveIcon />}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {valueTab === 2 && (
                        <Grid item xs>
                            {!checkPermission(UserPermission.USER_MANAGEMENT) && (
                                <Grid container justifyContent='space-between'>
                                    <Grid item>
                                        <ExportImportButtons<IInspectionFlow, Array<ExportInspectionFlowCsv>>
                                            dataToCsv={inspectionFlowToCsv}
                                            data={inspectionFlow}
                                            file_name={`${company?.name}__${props.data?.name}__inspection_flow`}
                                            updateState={updateStateIInspectionFlow}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            form='inspection-flow'
                                            size='small'
                                            fullWidth
                                            type='submit'
                                            variant='contained'
                                            color='secondary'
                                            endIcon={<SaveIcon />}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
            }
            tabs={
                <Tabs value={valueTab} onChange={handleChangeTab} aria-label='tabs'>
                    <Tab label='Profile Name' {...TabsProps(0)} />
                    <Tab label='Available Features' {...TabsProps(1)} />
                    {!checkPermission(UserPermission.ADMIN) && <Tab label='Inspection Flow' {...TabsProps(2)} />}
                </Tabs>
            }
        >
            <PopupBack open={popupBack.show} close={handleClosePopup} back={back} />
            <Box sx={{ height: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
                <TabPanel value={valueTab} index={0}>
                    <Box sx={{ padding: '20px' }}>
                        <form id='edit-profile' autoComplete='off' onSubmit={handleSend}>
                            <Grid container spacing={3} justifyContent='flex-end'>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        name='company_name'
                                        id='company_name'
                                        type='text'
                                        label='Company Name'
                                        variant='outlined'
                                        value={company?.name ?? ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
                                        <Grid item xs>
                                            <TextField
                                                fullWidth
                                                disabled
                                                name='profile_id'
                                                id='profile_id'
                                                type='text'
                                                label='Profile ID'
                                                variant='outlined'
                                                value={props.data?.profile_id ?? ''}
                                            />
                                        </Grid>
                                        <Grid>
                                            <Tooltip arrow disableFocusListener title={<Box sx={classes.textTooltip}>Click To Copy</Box>}>
                                                <IconButton
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(props.data?.profile_id as string).then((r) => r);
                                                    }}
                                                    color='primary'
                                                    aria-label='copy profile id'
                                                    component='label'
                                                >
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <ProfileName profile={props.data} changeProfileName={setProfileName} />
                            </Grid>
                        </form>
                    </Box>
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                    <EffectiveSettingsStep
                        handleNext={handleEffectiveSettings}
                        company={company as ICompany}
                        profileName={props.data?.name as string}
                    />
                </TabPanel>
                <TabPanel value={valueTab} index={2}>
                    <InspectionFlow />
                </TabPanel>
            </Box>
        </BasicDialog>
    );
};

export default EditProfile;
