import React, { useContext } from 'react';
import { Box, Chip, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import { classes } from './style';
import { StatusRedux } from '../../../enums/StatusRedux';
import { Spinner } from '../../spinner';
import { Error } from '../../error';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../store';
import { EditCompany } from './edit-company';
import { AddCompany } from './add-company';
import { ICompany } from '../../../infrastructure/DTO/company/company.dto';
import { IUserManagementProvider, UserManagementContext } from '../../../context/UserManagementProvider';
import errorParseMessage from '../../../config/error-parse';
import { ClearStateCompanies } from '../../../store/company/companies';
import { ClearStateCompany } from '../../../store/company/company';

const Companies: React.FC = () => {
    const { page, limit, createCompanyPopup, handleCreateCompanyPopup, changePage, changeRowsPerPage } =
        useContext<IUserManagementProvider>(UserManagementContext);
    const { companies, statusCompanies, errorCompanies, statusUpdate, statusIsActive } = useSelector((state: AppState) => state.companies);
    const [countCompanies, setCountCompanies] = React.useState<number>(0);
    const [dataById, setDataById] = React.useState<ICompany | null>(null);
    const [openEditPopup, setOpenEditPopup] = React.useState<boolean>(false);

    /**
     * Get status companies, update company, status activated/deactivated
     * Set companies
     * Set counts
     */
    React.useEffect(() => {
        if (statusCompanies === StatusRedux.Succeeded || statusUpdate === StatusRedux.Succeeded || statusIsActive === StatusRedux.Succeeded) {
            setCountCompanies(companies.count);
        }
    }, [statusCompanies, statusUpdate, statusIsActive]);

    /**
     * @type string
     * @type null
     * @param id
     * Find company and open popup to edit company
     */
    const handleSetIdPopupEdit = (id?: string) => () => {
        const data: ICompany | undefined = companies.companies.find((i) => i.company_id === id);

        if (data !== undefined) {
            setDataById(data);
            setOpenEditPopup(true);
        }
    };

    /**
     * Close popup
     */
    const handleClose = () => {
        setOpenEditPopup(false);
    };

    return (
        <>
            <AddCompany open={createCompanyPopup} handleClose={handleCreateCompanyPopup} count={countCompanies} setCount={setCountCompanies} />

            <Paper elevation={2} sx={classes.main}>
                {(() => {
                    switch (statusCompanies) {
                        case StatusRedux.Loading:
                            return <Spinner style={{ height: 'calc(100vh - 235px)' }} />;
                        case StatusRedux.Succeeded:
                            return (
                                <TableContainer sx={classes.container}>
                                    <Table stickyHeader aria-label='sticky table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={classes.cellCnt} align='left'>
                                                    #
                                                </TableCell>
                                                <TableCell sx={classes.cellLong} align='left'>
                                                    ID
                                                </TableCell>
                                                <TableCell sx={classes.cellLong} align='left'>
                                                    Company Name
                                                </TableCell>
                                                <TableCell sx={classes.cellShort} align='left'>
                                                    Industry
                                                </TableCell>
                                                <TableCell sx={classes.cellShort} align='left'>
                                                    Country
                                                </TableCell>
                                                <TableCell sx={classes.cellShort} align='left'>
                                                    Business Units
                                                </TableCell>
                                                <TableCell sx={classes.cellShort} align='left'>
                                                    Profiles
                                                </TableCell>
                                                <TableCell sx={classes.cellShort} align='left'>
                                                    Status
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {companies.companies.length > 0 &&
                                            companies.companies.map((data, index: number) => {
                                                return (
                                                    <TableBody data-testid='companies' key={index}>
                                                        <TableRow hover tabIndex={-1}>
                                                            <TableCell onClick={handleSetIdPopupEdit(data.company_id)} sx={classes.cellCnt}>
                                                                {limit * page + (index + 1)}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={classes.cellId}
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(data.company_id as string).then((r) => r);
                                                                }}
                                                            >
                                                                <Tooltip arrow disableFocusListener title={<Box>Click To Copy</Box>}>
                                                                    <Link color='inherit' href='#'>
                                                                        {data.company_id}
                                                                    </Link>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell onClick={handleSetIdPopupEdit(data.company_id)} sx={classes.cellShort}>
                                                                {data.name}
                                                            </TableCell>
                                                            <TableCell onClick={handleSetIdPopupEdit(data.company_id)} sx={classes.cellShort}>
                                                                {data.industry}
                                                            </TableCell>
                                                            <TableCell onClick={handleSetIdPopupEdit(data.company_id)} sx={classes.cellShort}>
                                                                {data.country}
                                                            </TableCell>
                                                            <TableCell onClick={handleSetIdPopupEdit(data.company_id)} sx={classes.cellShort}>
                                                                {data.business_units?.length}
                                                            </TableCell>
                                                            <TableCell onClick={handleSetIdPopupEdit(data.company_id)} sx={classes.cellShort}>
                                                                {data.profiles?.filter((p) => p.is_active).length}
                                                            </TableCell>
                                                            <TableCell onClick={handleSetIdPopupEdit(data.company_id)} sx={classes.cellShort}>
                                                                {data.is_active ? (
                                                                    <Chip label='Enabled' variant='outlined' color='primary' />
                                                                ) : (
                                                                    <Chip label='Disabled' variant='outlined' color='error' />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                );
                                            })}
                                        <EditCompany data={dataById} open={openEditPopup} handleClose={handleClose} />
                                    </Table>
                                </TableContainer>
                            );
                        case StatusRedux.Failed:
                            return (
                                <Box sx={classes.container}>
                                    <Error msg={errorParseMessage(errorCompanies)} />
                                </Box>
                            );
                    }
                })()}
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component='div'
                    count={countCompanies}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={changePage}
                    onRowsPerPageChange={changeRowsPerPage}
                />
            </Paper>
        </>
    );
};

export default Companies;
