import { CSSObject, Theme } from '@mui/material';

const panelTop = (): CSSObject => ({
    width: '100%',
    borderBottom: '1px solid #ccc',
    marginBottom: '20px',
    paddingBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const spinner = (): CSSObject => ({
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 250px)',
});

const cardImg = (): CSSObject => ({
    '& > img': {
        width: '100%',
    },
});

const stage = (): CSSObject => ({
    width: 'auto',
    minHeight: '550px',
    overflow: 'hidden',
    cursor: 'move',
});

const containerTable = (): CSSObject => ({
    maxHeight: '550px',
});

const imgUploaded = (): CSSObject => ({
    textAlign: 'center',
    width: '100%',
    border: '1px solid #ccc',
    '& > img': {
        width: '100%',
        height: '550px',
        objectFit: 'contain',
    },
});

const tableRow = (theme: Theme): CSSObject => ({
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
});

const mainHeaderPage = (): CSSObject => ({
    borderBottom: '1px solid #ccc',
    marginBottom: '20px',
});

const titlePage = (): CSSObject => ({
    padding: '20px 0px 10px 0px',
});

const emptyMsg = (): CSSObject => ({
    width: '100%',
    height: 'calc(100vh - 300px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5em',
    textTransform: 'uppercase',
});

export const classes = {
    emptyMsg,
    titlePage,
    mainHeaderPage,
    tableRow,
    imgUploaded,
    containerTable,
    stage,
    cardImg,
    spinner,
    panelTop,
};
