import { CSSObject, Theme } from '@mui/material';

const root = (): CSSObject => ({
    display: 'flex',
});

const toolbar = (theme: Theme): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
});

const content = (theme: Theme): CSSObject => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    width: 'calc(100% - 240px)',
});

export const classes = {
    root,
    toolbar,
    content,
};
