import * as React from 'react';
import { Dialog, IconButton, Table, TableContainer, TableRow } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { Spinner } from '../../../spinner';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { EditRole } from './edit-role';
import { Error } from '../../../error';
import { classes } from '../../user/style';
import { AppDispatch, AppState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { clearStoreRemove, fetchAllRoles, fetchRemoveRole } from '../../../../store/role/role';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { IArgsRole, IRole } from '../../../../infrastructure/DTO/role/role.dto';
import errorParseMessage from "../../../../config/error-parse";

type EditPopup = {
    open: boolean;
    id: string;
};

const RoleView: React.FC = () => {
    const { token } = useContext(CustomAuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const [edit, setEdit] = React.useState<EditPopup>({
        open: false,
        id: '',
    });
    const [open, setOpen] = React.useState<{ role_id: string; modal: boolean }>({
        role_id: '',
        modal: false,
    });
    const dispatch: AppDispatch = useDispatch();
    const { roles, statusRole, errorRole } = useSelector((state: AppState) => state.getRoles);
    const { statusRemove, errorRemove } = useSelector((state: AppState) => state.getRoles);

    const handleEdit = (id: string) => () => {
        setEdit({
            open: true,
            id: id,
        });
    };

    const handleEditClose = () => {
        setEdit({
            ...edit,
            open: false,
        });
    };

    const handleRemoveRole = () => {
        const args: IArgsRole = {
            role_id: open.role_id as string,
        };

        dispatch(
            fetchRemoveRole({
                token,
                args,
            })
        );

        setOpen({
            modal: false,
            role_id: '',
        });
    };

    const handlePopup = (role_id: string) => () => {
        setOpen({
            modal: !open.modal,
            role_id: role_id,
        });
    };

    React.useEffect(() => {
        dispatch(fetchAllRoles({ token: token, args: null }));
    }, []);

    React.useEffect(() => {
        if (statusRemove === StatusRedux.Succeeded) {
            enqueueSnackbar('Role Removed Successfully', {
                variant: 'success',
            });

            dispatch(clearStoreRemove());
        }
        if (statusRemove === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorRemove), {
                variant: 'error',
            });

            dispatch(clearStoreRemove());
        }
    }, [statusRemove]);

    return (
        <>
            <Dialog open={open.modal} onClose={handlePopup}>
                <Box sx={classes.headerPopup}>
                    <Box sx={classes.titlePopup}>Are you sure you want to delete?</Box>
                </Box>
                <Box p={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button fullWidth onClick={handlePopup('')} variant='contained' color='primary'>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth onClick={handleRemoveRole} variant='contained' style={{ color: 'white', background: 'red' }}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
            {(() => {
                switch (statusRole) {
                    case StatusRedux.Loading:
                        return <Spinner />;
                    case StatusRedux.Succeeded:
                        return (
                            <TableContainer sx={classes.container}>
                                <Table stickyHeader aria-label='sticky table'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={classes.cellLong} key={1} align='left'>
                                                #
                                            </TableCell>
                                            <TableCell sx={classes.cellLong} key={2} align='left'>
                                                Name
                                            </TableCell>
                                            <TableCell sx={classes.cellLong} key={3} align='left'>
                                                Permission
                                            </TableCell>
                                            <TableCell sx={classes.cellLong} key={4} align='left'>
                                                Active
                                            </TableCell>
                                            <TableCell sx={classes.cellLong} key={5} align='left'>
                                                Remove
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {roles !== null &&
                                        roles.roles.map((data: IRole, index: number) => (
                                            <TableBody key={index}>
                                                <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                                                    <TableCell onClick={handleEdit(data.role_id)} sx={classes.cellShort} key={1}>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell onClick={handleEdit(data.role_id)} sx={classes.cellShort} key={2}>
                                                        {data.name}
                                                    </TableCell>
                                                    <TableCell onClick={handleEdit(data.role_id)} sx={classes.cellShort} key={3}>
                                                        {data.permissions !== null &&
                                                            data.permissions
                                                                .filter((i) => i.is_active)
                                                                .map((p, index) => (
                                                                    <Chip
                                                                        key={index}
                                                                        avatar={<Avatar>{p.name[0].toUpperCase()}</Avatar>}
                                                                        sx={classes.chip}
                                                                        size='small'
                                                                        label={p.name}
                                                                        color='primary'
                                                                    />
                                                                ))}
                                                    </TableCell>
                                                    <TableCell onClick={handleEdit(data.role_id)} sx={classes.cellShort} key={4}>
                                                        {data.is_active ? (
                                                            <Chip label='Enable' variant='outlined' color='primary' />
                                                        ) : (
                                                            <Chip label='Disable' variant='outlined' style={{ color: 'red', borderColor: 'red' }} />
                                                        )}
                                                    </TableCell>
                                                    <TableCell key={5}>
                                                        <IconButton disabled={data.permissions.length !== 0} onClick={handlePopup(data.role_id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                {edit.id === data.role_id && <EditRole role={data} open={edit.open} close={handleEditClose} />}
                                            </TableBody>
                                        ))}
                                </Table>
                            </TableContainer>
                        );
                    case StatusRedux.Failed:
                        return <Error msg={errorParseMessage(errorRole)} />;
                }
            })()}
        </>
    );
};

export default RoleView;
