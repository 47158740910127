import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserServices from '../../services/users/user-services';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchBody } from '../IFetch';
import { IUser } from '../../infrastructure/DTO/users/user.dto';
import { IArgsRouteUser, IChangePassword } from '../../infrastructure/DTO/users/user-view.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchUserChangePass = createAsyncThunk<IUser, IFetchBody<IChangePassword, IArgsRouteUser>>('psw/change', async (params) => {
    return await UserServices.changeUserPassword(params);
});

export const changeUserPassword = createSlice({
    name: 'psw',
    initialState: {
        user: {} as IUser,
        userChangePswStatus: '',
        userChangePswError: {} as IErrorResponse,
    },
    reducers: {
        ClearStoreChangePassword: (state) => {
            state.user = {} as IUser;
            state.userChangePswStatus = '';
            state.userChangePswError = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserChangePass.pending, (state) => {
            state.userChangePswStatus = StatusRedux.Loading;
        });
        builder.addCase(fetchUserChangePass.fulfilled, (state, action) => {
            state.userChangePswStatus = StatusRedux.Succeeded;
            state.user = action.payload;
        });
        builder.addCase(fetchUserChangePass.rejected, (state, action) => {
            state.userChangePswStatus = StatusRedux.Failed;
            state.userChangePswError = JSON.parse(action.error.message as string);
        });
    },
});

export const { ClearStoreChangePassword } = changeUserPassword.actions;
export default changeUserPassword;
