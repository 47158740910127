import { UserPermission } from '../config';
import { Inspection } from '../components/pages/inspection';
import { InspectionCreate } from '../components/pages/inspection/create-inspection';
import { RolePermission } from '../components/pages/role-permission';
import { ProfileCompany } from '../components/pages/profile-company';
import { UsageReport } from '../components/pages/usage-report';
import { DamageLab } from '../components/pages/damagelab';
import DamageLabSingle from '../components/pages/damagelab/damagelab-single/damage-lab-single';
import DamageLabMultiple from '../components/pages/damagelab/damagelab-multiple/damagelab-multiple';
import Main from '../components/pages/damagelab/damagelab-single/main/main';
import { RoutesType } from '../routes/RouteType';
import { InspectionList } from '../components/pages/inspection/list';
import { FlexibleSettingsProfile } from '../components/pages/flexible-settings/profile';
import UserProvider from '../components/pages/user-management/user-provider';
import PrivateRoute from '../routes/private-route';
import * as React from 'react';
import { useContext } from 'react';
import { CustomAuthContext } from '../context/AuthProvider';
import LayoutDamageLab from '../components/pages/damagelab/layout-damagelab';
import DamageLabLayout from '../components/pages/inspection/drill-down/damage-lab-layout/damage-lab-layout';
import DamageLabProvider from '../components/pages/inspection/drill-down/damage-lab-layout/damage-lab-provider';
import ReportInspection from '../components/pages/inspection/view-report/report';

function usePrivateRoutesConfig() {
    const { getPermission } = useContext(CustomAuthContext);

    /**
     * @type Array<string>
     * @param permissions
     * @return boolean
     * Check, if render link in the menu
     * Check if render component
     */
    const is_render = (permissions: Array<string>): boolean => {
        let res: boolean = false;
        permissions.forEach((p) => {
            if (getPermission().includes(p.replace(/\s+/g, ''))) {
                res = true;
            }
        });
        return res;
    };

    const routes: RoutesType = [
        {
            id: 1,
            element: (
                <PrivateRoute
                    permissions={[
                        UserPermission.ADMIN,
                        UserPermission.USER_MANAGEMENT,
                        UserPermission.USER_VIEW,
                        UserPermission.EXPORT_DATA,
                        UserPermission.USER_MANAGEMENT_APIKEY,
                        UserPermission.VIEWER,
                    ]}
                >
                    <UserProvider />
                </PrivateRoute>
            ),
            children: [],
            title: 'User Management',
            path: 'user-management',
            icon: 'group',
            index: false,
            main: true,
            render: is_render([
                UserPermission.ADMIN,
                UserPermission.USER_MANAGEMENT,
                UserPermission.EXPORT_DATA,
                UserPermission.USER_MANAGEMENT_APIKEY,
                UserPermission.VIEWER,
            ]),
        },
        {
            id: 2,
            element: (
                <PrivateRoute>
                    <InspectionList />
                </PrivateRoute>
            ),
            title: 'Inspections',
            path: 'inspections',
            icon: 'image_search',
            index: false,
            main: false,
            render: true,
            children: [],
            childrenMenu: [
                {
                    id: 3,
                    path: 'inspection',
                    title: 'Create New',
                    icon: 'add_photo_alternate',
                    index: false,
                    children: [],
                    element: null,
                    render: is_render([UserPermission.ADMIN]),
                },
                {
                    id: 4,
                    path: 'inspections',
                    title: 'All Inspections',
                    icon: 'view_list',
                    index: false,
                    render: true,
                    children: [],
                    element: null,
                },
                {
                    id: 5,
                    path: 'usage-report',
                    title: 'Usage Report',
                    icon: 'list_alt',
                    children: [],
                    element: null,
                    index: false,
                    render: is_render([UserPermission.ADMIN]),
                },
            ],
        },
        {
            id: 6,
            element: (
                <PrivateRoute permissions={[UserPermission.ADMIN]}>
                    <Inspection />
                </PrivateRoute>
            ),
            path: 'inspection',
            main: false,
            index: false,
            render: is_render([UserPermission.ADMIN]),
            children: [],
        },
        {
            id: 7,
            element: (
                <PrivateRoute permissions={[UserPermission.ADMIN, UserPermission.CREATE_INSPECTION]}>
                    <InspectionCreate />
                </PrivateRoute>
            ),
            path: 'inspection/create/:id',
            main: false,
            index: false,
            render: is_render([UserPermission.ADMIN]),
            children: [],
        },
        {
            id: 8,
            element: (
                <PrivateRoute permissions={[UserPermission.ADMIN]}>
                    <RolePermission />
                </PrivateRoute>
            ),
            path: 'role-permission',
            index: false,
            title: 'Role & Permission',
            icon: 'admin_panel_settings',
            main: true,
            render: is_render([UserPermission.ADMIN]),
            children: [],
        },
        {
            id: 9,
            element: (
                <PrivateRoute
                    permissions={[
                        UserPermission.ADMIN,
                        UserPermission.USER_MANAGEMENT,
                        UserPermission.USER_VIEW,
                        UserPermission.EXPORT_DATA,
                        UserPermission.USER_MANAGEMENT_APIKEY,
                        UserPermission.VIEWER,
                    ]}
                >
                    <ProfileCompany />
                </PrivateRoute>
            ),
            path: 'profile',
            index: false,
            render: true,
            main: false,
            children: [],
        },
        {
            id: 10,
            element: (
                <PrivateRoute permissions={[UserPermission.ADMIN]}>
                    <UsageReport />
                </PrivateRoute>
            ),
            path: 'usage-report',
            title: 'Usage Report',
            icon: 'list_alt',
            index: false,
            render: is_render([UserPermission.ADMIN]),
            children: [],
        },
        {
            id: 11,
            element: (
                <PrivateRoute permissions={[UserPermission.ADMIN]}>
                    <LayoutDamageLab />
                </PrivateRoute>
            ),
            path: 'damage-lab',
            title: 'DamageLab',
            icon: 'burst_mode',
            index: false,
            render: is_render([UserPermission.ADMIN]),
            main: true,
            children: [
                {
                    id: 12,
                    element: (
                        <PrivateRoute permissions={[UserPermission.ADMIN]}>
                            <DamageLab />
                        </PrivateRoute>
                    ),
                    title: 'DamageLab',
                    icon: 'burst_mode',
                    index: true,
                    main: false,
                    render: is_render([UserPermission.ADMIN]),
                    children: [],
                },
                {
                    id: 13,
                    element: (
                        <PrivateRoute permissions={[UserPermission.ADMIN]}>
                            <Main />
                        </PrivateRoute>
                    ),
                    path: 'main',
                    index: false,
                    main: false,
                    render: is_render([UserPermission.ADMIN]),
                    children: [],
                },
                {
                    id: 14,
                    element: (
                        <PrivateRoute permissions={[UserPermission.ADMIN]}>
                            <DamageLabSingle />
                        </PrivateRoute>
                    ),
                    path: 'single',
                    index: false,
                    main: false,
                    render: is_render([UserPermission.ADMIN]),
                    children: [],
                },
                {
                    id: 15,
                    element: (
                        <PrivateRoute permissions={[UserPermission.ADMIN]}>
                            <DamageLabMultiple />
                        </PrivateRoute>
                    ),
                    path: 'multiple',
                    index: false,
                    main: false,
                    render: is_render([UserPermission.ADMIN]),
                    children: [],
                },
            ],
        },
        {
            id: 16,
            element: (
                <PrivateRoute permissions={[UserPermission.ADMIN]}>
                    <FlexibleSettingsProfile />
                </PrivateRoute>
            ),
            path: 'flexible-settings/profile',
            index: false,
            main: false,
            render: is_render([UserPermission.ADMIN]),
            children: [],
        },
        {
            id: 20,
            element: (
                <PrivateRoute permissions={[UserPermission.ADMIN]}>
                    <DamageLabProvider />
                </PrivateRoute>
            ),
            path: 'drill-down',
            main: false,
            index: false,
            render: is_render([UserPermission.ADMIN]),
            children: [],
        },
    ];

    return routes;
}

export default usePrivateRoutesConfig;
