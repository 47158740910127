import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Radio,
    RadioGroup,
    Slide,
    Typography,
} from '@mui/material';
import { IEffectiveSettings } from '../../../../../infrastructure/DTO/effective-settings/effective-settings.dto';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

interface ISettingAllowedValuesPopupComponent {
    popup: {
        open: boolean;
        settings: IEffectiveSettings | null;
    };
    handleClose(): void;
    handleChangeAllowedValues(values: IEffectiveSettings): void;
}

const SettingAllowedValuesPopup: React.FC<ISettingAllowedValuesPopupComponent> = (props: ISettingAllowedValuesPopupComponent) => {
    const [settings, setSettings] = React.useState<IEffectiveSettings | undefined>(undefined);

    React.useMemo(() => {
        if (props.popup.open && props.popup.settings) {
            setSettings(props.popup.settings);
        }
    }, [props.popup.settings, props.popup.open]);

    const handleSave = () => {
        props.handleChangeAllowedValues(settings as IEffectiveSettings);
        props.handleClose();
    };

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (settings?.setting.setting_allowed_values?.length) {
            const res: IEffectiveSettings = {
                ...settings,
                value: event.target.value,
            };

            setSettings(res);
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            open={props.popup.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.handleClose}
            aria-describedby='alert-dialog-slide-description'
        >
            <DialogTitle>{props.popup.settings?.setting.name}</DialogTitle>
            <DialogContent>
                <Box>
                    <FormControl sx={{ width: '100%' }}>
                        {settings?.setting?.setting_allowed_values &&
                            settings?.setting.setting_allowed_values.map((values, index) => {
                                return (
                                    <Box key={index}>
                                        <RadioGroup
                                            onChange={handleChangeValue}
                                            sx={{ width: '100%' }}
                                            aria-labelledby='radio-buttons'
                                            name='radio-buttons'
                                        >
                                            <FormControlLabel
                                                value={values.value}
                                                control={<Radio checked={settings.value === values.value} />}
                                                label={
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Box>
                                                            <Typography mt={1} variant='button' display='block' gutterBottom>
                                                                {values.value}
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='subtitle2' gutterBottom>
                                                                {values.description}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                }
                                            />
                                        </RadioGroup>
                                        <Divider />
                                    </Box>
                                );
                            })}
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Close</Button>
                <Button onClick={handleSave}>Select</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettingAllowedValuesPopup;
