import { CSSObject, Theme } from '@mui/material';

let titlePage = (): CSSObject => ({
    textTransform: 'uppercase',
});

let pageMain = (): CSSObject => ({
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 'calc(100vh - 190px)',
    width: '100%',
    marginTop: '30px',
});

const cellLong = (): CSSObject => ({
    minWidth: '200px',
    '&:hover': {
        cursor: 'pointer',
    },
});

const cellID = (): CSSObject => ({
    width: '330px',
    '&:hover': {
        cursor: 'pointer',
    },
});

let containerMain = (): CSSObject => ({
    height: 'calc(100vh - 250px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
});

let iconBtn = (): CSSObject => ({
    marginLeft: '10px',
});

let lineProgress = (): CSSObject => ({
    height: '2px',
});

let container = (): CSSObject => ({
    height: 'calc(100vh - 235px)',
    position: 'relative',
});

let bodyPopup = (): CSSObject => ({
    paddingBottom: '25px',
});

let cell = (): CSSObject => ({
    maxWidth: '350px',
});

let chip = (): CSSObject => ({
    margin: '5px',
});

let searchBox = (): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    height: 'calc(100vh - 250px)',
});

let tableRow = (theme: Theme): CSSObject => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
});

const textTooltip = (): CSSObject => ({
    textAlign: 'center',
});

let tableHead = (theme: Theme): CSSObject => ({
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: '350px',
});

const main = (): CSSObject => ({
    width: '100%',
    height: 'calc(100vh - 180px)',
});

const cellCnt = (): CSSObject => ({
    width: '50px',
    minWidth: '50px',
    maxWidth: '50px',
});

export const classes = {
    main,
    cellCnt,
    tableHead,
    textTooltip,
    tableRow,
    cell,
    chip,
    searchBox,
    bodyPopup,
    lineProgress,
    titlePage,
    iconBtn,
    pageMain,
    containerMain,
    cellLong,
    cellID,
    container,
};
