import React from 'react';
import { IBoundingBox, IContours, ICoordinates, IDimensions } from '../infrastructure/DTO/damage-lab/damage-lab.dto';
import { IMeasurements } from '../infrastructure/DTO/damage-lab/anomaly-measurements.dto';
import DataMenu, { MenuType } from '../components/pages/inspection/drill-down/damage-lab-layout/damagelab-menu/menu';
import { IImageOverView, IZoomMeasurement } from '../infrastructure/DTO/multilayered/multilayered.dto';

export type DrawerPanelType = { left: boolean; right: boolean; top: boolean; bottom: boolean };
export type SelectedPartType = { id: string; color: string } | null;
export type MeasurementType = Map<string, { measurement: Array<IMeasurements>; bounding_box: IBoundingBox }>;
export type PointsType = Map<string, { pointsArr: Array<number>; color: string }>;
export type imageInfoType = { weight: string; width: number; height: number };
export type MeasurementCurrentDataType = {
    result: Array<{ key: string; value: any | null; tag: any }> | null;
    minimalEnclosingBox: Array<{ key: string; value: any | null; tag: any }> | null;
    threeBoundingBox: Array<{ key: string; value: any | null }> | null;
};
export type PartsType = {
    part_id: string;
    part_name: string;
    bounding_box: IBoundingBox;
    color: any;
    contours: Array<IContours>;
};

export interface IDamageLabLayoutContext {
    refDiv: any;
    stageRef: any;
    image: IImageOverView | null;
    images: Array<IImageOverView>;
    coordinates: ICoordinates;
    canvasSize: IDimensions;
    menu: Array<MenuType>;
    scale: number;
    drawerPanel: DrawerPanelType;
    currentPart: Array<IContours>;
    selectedPart: SelectedPartType;
    loading: boolean;
    panelViewLayout: string;
    measurements: MeasurementType;
    measurementCurrentData: MeasurementCurrentDataType;
    checkedMeasurement: Array<string>;
    points: PointsType;
    parts: Array<PartsType>;
    imageInfo: imageInfoType;
    opacitySliderThreeD: number;
    zoomPerArea: IZoomMeasurement | undefined;
    quadrangle: Array<number>;
    panelContours: Array<Array<number>>;
    measurementPoints: Array<number>;
    showMeasurementInfo: boolean;
    setCheckedMeasurement(checked: Array<string>): void;
    setImage(img: IImageOverView | null): void;
    setImages(img: Array<IImageOverView>): void;
    setCoordinates(coordinates: ICoordinates): void;
    setCanvasSize(size: IDimensions): void;
    setMenu(menu: Array<MenuType>): void;
    setScale(scale: number): void;
    setDrawerPanel(panel: DrawerPanelType): void;
    setCurrentPart(current: Array<IContours>): void;
    setSelectedPart(part: SelectedPartType): void;
    setLoading(loading: boolean): void;
    setPanelViewLayout(layout: string): void;
    setMeasurements(measurement: MeasurementType): void;
    setMeasurementCurrentData(current: MeasurementCurrentDataType): void;
    setPoints(points: PointsType): void;
    setImageInfo(imageInfo: imageInfoType): void;
    setOpacitySliderThreeD(event: Event, opacity: number | number[]): void;
    setZoomPerArea(zoomPerArea: IZoomMeasurement | undefined): void;
    setQuadrangle(quadrangle: Array<number>): void;
    setPanelContours(panelContours: Array<Array<number>>): void;
    setMeasurementPoints(measurementPoints: Array<number>): void;
    handleMeasurementInfo(show: boolean): void;
    setParts(parts: Array<PartsType>): void;
}

const initContext = {
    refDiv: null,
    stageRef: null,
    image: null,
    images: [] as Array<IImageOverView>,
    coordinates: {
        x: 1,
        y: 1,
    },
    canvasSize: {
        width: 1,
        height: 1,
    },
    menu: DataMenu,
    scale: 1,
    drawerPanel: {
        left: false,
        right: true,
        top: false,
        bottom: false,
    },
    currentPart: [],
    selectedPart: null,
    loading: true,
    imageInfo: {
        weight: '',
        width: 1,
        height: 1,
    },
    panelViewLayout: '',
    measurements: new Map<string, { measurement: Array<IMeasurements>; bounding_box: IBoundingBox }>(),
    measurementCurrentData: {
        result: null,
        minimalEnclosingBox: null,
        threeBoundingBox: null,
    },
    parts: [],
    checkedMeasurement: [],
    points: new Map<string, { pointsArr: Array<number>; color: string }>(),
    opacitySliderThreeD: 0.5,
    zoomPerArea: undefined,
    quadrangle: [],
    panelContours: [],
    measurementPoints: [],
    showMeasurementInfo: false,
};

export const DamageLabLayoutContext = React.createContext<IDamageLabLayoutContext>({
    ...initContext,
    setImage: (img: IImageOverView): void => {},
    setImages: (img: Array<IImageOverView>): void => {},
    setCoordinates: (coordinates: ICoordinates): void => {},
    setCanvasSize: (size: IDimensions): void => {},
    setMenu: (menu: Array<MenuType>): void => {},
    setScale: (scale: number): void => {},
    setDrawerPanel: (panel: DrawerPanelType): void => {},
    setCurrentPart: (current: Array<IContours>): void => {},
    setSelectedPart: (part: SelectedPartType): void => {},
    setLoading: (loading: boolean): void => {},
    setPanelViewLayout: (layout: string): void => {},
    setMeasurements: (measurement: MeasurementType): void => {},
    setMeasurementCurrentData: (current: MeasurementCurrentDataType): void => {},
    setPoints: (points: PointsType): void => {},
    setImageInfo: (imageInfo: imageInfoType): void => {},
    setCheckedMeasurement: (checked: Array<string>): void => {},
    setOpacitySliderThreeD: (event: Event, opacity: number | number[]): void => {},
    setZoomPerArea: (zoomPerArea: IZoomMeasurement | undefined): void => {},
    setQuadrangle: (quadrangle: Array<number>): void => {},
    setPanelContours: (panelContours: Array<Array<number>>): void => {},
    setMeasurementPoints: (measurementPoints: Array<number>): void => {},
    handleMeasurementInfo: (show: boolean): void => {},
    setParts: (parts: Array<PartsType>): void => {},
});
export default DamageLabLayoutContext;
