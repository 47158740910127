import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import CompanyServices from '../../services/companies/company';
import { IFetchArgs, IFetchBody } from '../IFetch';
import { IArgsCompany, ICompanies, ICompany } from '../../infrastructure/DTO/company/company.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetAllCompanies = createAsyncThunk<ICompanies, IFetchArgs<IArgsCompany>>(
    'company/get-all',
    async (params: IFetchArgs<IArgsCompany>) => await CompanyServices.getAllCompanies(params)
);
export const fetchCreateCompany = createAsyncThunk<ICompany, IFetchBody<ICompany>>(
    'company/post',
    async (params: IFetchBody<ICompany>) => await CompanyServices.create(params)
);

export const fetchUpdateCompany = createAsyncThunk<ICompany, IFetchBody<ICompany, IArgsCompany>>(
    'company/put',
    async (params: IFetchBody<ICompany, IArgsCompany>) => await CompanyServices.update(params)
);

export const fetchActivateDeactivateCompany = createAsyncThunk<ICompany, IFetchArgs<IArgsCompany>>(
    'company/activateDeactivate',
    async (params: IFetchArgs<IArgsCompany>) => await CompanyServices.activateDeactivateCompany(params)
);

export const companiesSlice = createSlice({
    name: 'company',
    initialState: {
        companies: {} as ICompanies,
        statusCreate: '',
        errorCreate: {} as IErrorResponse,
        statusIsActive: '',
        errorIsActive: {} as IErrorResponse,
        statusUpdate: '',
        errorUpdate: {} as IErrorResponse,
        statusCompanies: '',
        errorCompanies: {} as IErrorResponse,
    },
    reducers: {
        ClearStateCompanies: (state) => {
            state.statusCompanies = '';
            state.errorCompanies = {} as IErrorResponse;
        },
        ClearCreateCompany: (state) => {
            state.statusCreate = '';
            state.errorCreate = {} as IErrorResponse;
        },
        ClearUpdateCompany: (state) => {
            state.statusUpdate = '';
            state.errorUpdate = {} as IErrorResponse;
            state.statusIsActive = '';
            state.errorIsActive = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetAllCompanies.pending, (state) => {
            state.statusCompanies = StatusRedux.Loading;
        });
        builder.addCase(fetchGetAllCompanies.fulfilled, (state, action) => {
            state.statusCompanies = StatusRedux.Succeeded;
            state.companies = action.payload;
        });
        builder.addCase(fetchGetAllCompanies.rejected, (state, action) => {
            state.statusCompanies = StatusRedux.Failed;
            state.errorCompanies = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchCreateCompany.pending, (state) => {
            state.statusCreate = StatusRedux.Loading;
        });
        builder.addCase(fetchCreateCompany.fulfilled, (state, action) => {
            state.statusCreate = StatusRedux.Succeeded;
            state.companies.companies.push(action.payload);
        });
        builder.addCase(fetchCreateCompany.rejected, (state, action) => {
            state.statusCreate = StatusRedux.Failed;
            state.errorCreate = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUpdateCompany.pending, (state) => {
            state.statusUpdate = StatusRedux.Loading;
        });
        builder.addCase(fetchUpdateCompany.fulfilled, (state, action) => {
            state.statusUpdate = StatusRedux.Succeeded;
            const index = state.companies.companies.findIndex((x: ICompany) => x.company_id === action.payload.company_id);

            state.companies.companies = [
                ...state.companies.companies.slice(0, index),
                { ...state.companies.companies[index], ...action.payload },
                ...state.companies.companies.slice(index + 1),
            ];
        });
        builder.addCase(fetchUpdateCompany.rejected, (state, action) => {
            state.statusUpdate = StatusRedux.Failed;
            state.errorUpdate = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchActivateDeactivateCompany.pending, (state) => {
            state.statusIsActive = StatusRedux.Loading;
        });
        builder.addCase(fetchActivateDeactivateCompany.fulfilled, (state, action) => {
            state.statusIsActive = StatusRedux.Succeeded;
            const index = state.companies.companies.findIndex((x: ICompany) => x.company_id === action.payload.company_id);

            state.companies.companies = [
                ...state.companies.companies.slice(0, index),
                { ...state.companies.companies[index], is_active: !state.companies.companies[index].is_active },
                ...state.companies.companies.slice(index + 1),
            ];
        });
        builder.addCase(fetchActivateDeactivateCompany.rejected, (state, action) => {
            state.statusIsActive = StatusRedux.Failed;
            state.errorIsActive = JSON.parse(action.error.message as string);
        });
    },
});

export const { ClearStateCompanies, ClearCreateCompany, ClearUpdateCompany } = companiesSlice.actions;
export default companiesSlice;
