import React from 'react';
import { Line } from 'react-konva';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import { IDamageLabLayout } from '../IDamageLabLayout';
import DamageLabLayoutContext, { IDamageLabLayoutContext } from '../../../../../../context/DamageLabLayoutContext';

interface ISegmentCarLayoutComponent extends IDamageLabLayout {}

const SegmentCarLayout: React.FC<ISegmentCarLayoutComponent> = (props: ISegmentCarLayoutComponent) => {
    const { scale } = React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);
    const { imageOverView } = useSelector((state: AppState) => state.imageOverView);
    const [points, setPoints] = React.useState<Array<number>>([]);

    React.useEffect(() => {
        if (props.view) {
            const points: Array<number> = [];

            if (imageOverView.vehicle_segmentation !== null) {
                imageOverView.vehicle_segmentation.contours[0].forEach((p) => {
                    points.push(Math.floor(p.x * scale), Math.floor(p.y * scale));
                });
            }

            setPoints(points);
        } else {
            setPoints([]);
        }
    }, [scale, props.view]);

    return (
        <Line
            key={1}
            points={points}
            stroke='green'
            strokeWidth={2}
            tension={0}
            lineCap='square'
            closed={true}
            globalCompositeOperation='source-over'
        />
    );
};

export default SegmentCarLayout;
