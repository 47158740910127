import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs } from '../IFetch';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';
import Multilayered from '../../services/multilayered/multilayered';
import { IArgsImagesOverView, IImageOverViewAssessment } from '../../infrastructure/DTO/multilayered/multilayered.dto';

export const fetchGetImageOverView = createAsyncThunk<IImageOverViewAssessment, IFetchArgs<IArgsImagesOverView>>(
    'images-overview/image',
    async (params: IFetchArgs<IArgsImagesOverView>) => {
        return await Multilayered.getImageOverView(params);
    }
);

export const imageOverViewSlice = createSlice({
    name: 'images-overview',
    initialState: {
        imageOverView: {} as IImageOverViewAssessment,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        clearStoreImageOverView: (state) => {
            state.imageOverView = {} as IImageOverViewAssessment;
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetImageOverView.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetImageOverView.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.imageOverView = action.payload;
        });
        builder.addCase(fetchGetImageOverView.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreImageOverView } = imageOverViewSlice.actions;
export default imageOverViewSlice;
