import {CSSObject} from "@mui/material";
import {green} from "@mui/material/colors";

const card = (): CSSObject => ({
    maxHeight: '350px',
    marginBottom: '25px!important'
})

const cardImg = (): CSSObject => ({
    width: 'auto!important',
    height: '300px',
    margin: '0 auto',
})

const cardContent = (): CSSObject => ({
    textAlign: 'center',
    padding: '0 0 20px 0'
})

const cardContentEmpty = (): CSSObject => ({
    textAlign: 'center',
    padding: '20px'
})

const textEmpty = (): CSSObject => ({
    marginTop: '25px'
})

const uploadBox = (): CSSObject => ({
    padding: '20px',
    width: '100%',
    height: '500px'
})

const imgUpload = (): CSSObject => ({
    width: '100%',
    '& > img': {
        width: '100%',
        height: '450px',
        objectFit: 'contain'
    },
})

const paper = (): CSSObject => ({
    minHeight: '500px',
    width: '100%',
    position: 'relative'
})

const mainHeaderPage = (): CSSObject => ({
    marginBottom: '20px'
})

const titlePage = (): CSSObject => ({
    marginBottom: '10px',
    textTransform: 'uppercase'
})

const btn = (): CSSObject => ({
    marginLeft: '15px!important'
})

const btnPage = (): CSSObject => ({
    marginBottom: '10px',
    textAlign: 'right'
})

const title = (): CSSObject => ({
    fontSize: '1.7em',
    fontWeight: 500
})

const buttonProgress = (): CSSObject => ({
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
})


export const classes = {
    buttonProgress,
    title, 
    btnPage, 
    btn,
    card, 
    cardImg,
    imgUpload, 
    paper, 
    mainHeaderPage, 
    titlePage,
    cardContent, 
    cardContentEmpty,
    textEmpty, 
    uploadBox
}