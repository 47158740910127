import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Inspection from '../../services/inspection/inspection';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs } from '../IFetch';
import { IInspectionCaseId } from '../../infrastructure/DTO/inspection/inspection.dto';
import { IThresholdSettings } from '../../infrastructure/DTO/threshold-settings/threshold-settings.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetThresholdSettings = createAsyncThunk<IThresholdSettings, IFetchArgs<IInspectionCaseId>>(
    'threshold-settings/get',
    async (params: IFetchArgs<IInspectionCaseId>) => {
        return await Inspection.getThresholdSettings(params);
    }
);

export const thresholdSettingsSlice = createSlice({
    name: 'threshold-settings',
    initialState: {
        thresholdSettings: {} as IThresholdSettings,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        clearGetStatusThresholdSettings: (state) => {
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetThresholdSettings.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetThresholdSettings.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.thresholdSettings = action.payload;
        });
        builder.addCase(fetchGetThresholdSettings.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearGetStatusThresholdSettings } = thresholdSettingsSlice.actions;
export default thresholdSettingsSlice;
