import { createSlice } from '@reduxjs/toolkit';

interface IPic {
    image: HTMLImageElement
    width: number
    height: number
}

export const damageLabImageSlice = createSlice({
    name: 'damage-lab-pic',
    initialState: {
        imageMain: {} as IPic,
    },
    reducers: {
        setImage: (state,action) => {
            state.imageMain = action.payload;
        },
    },
});

export const { setImage } = damageLabImageSlice.actions;
export default damageLabImageSlice;