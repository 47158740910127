import { CSSObject, Theme } from '@mui/material';

const paper = (): CSSObject => ({
    marginBottom: '15px',
    padding: '15px',
});

const flex = (): CSSObject => ({
    display: 'flex',
    flexDirection: 'row-reverse',
});

const headerPopupMain = (theme: Theme): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
});

const iconButton = (theme: Theme): CSSObject => ({
    padding: '0 20px!important',
    color: `${theme.palette.primary.contrastText}!important`,
});

export const classes = { paper, iconButton, headerPopupMain, flex };
