import React, { useContext } from 'react';
import { BasicDialog } from '../../../../../common/ui-components/Dialog';
import { Step, StepLabel, Stepper } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { classes } from '../../style';
import { clearStoreCreateProfile, fetchCreateProfile } from '../../../../../store/profile/profiles';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { useSnackbar } from 'notistack';
import EffectiveSettingsStep from '../effective-settings/effective-settings';
import FlowInspection from '../inspection-flow/inspection-flow';
import { UserPermission } from '../../../../../config';
import { IArgsProfile, IProfile } from '../../../../../infrastructure/DTO/profile/profile.dto';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { IUserManagementProvider, UserManagementContext } from '../../../../../context/UserManagementProvider';
import { ProfileName } from '../profile-name';
import { ExportImportButtons } from '../export-import-buttons';
import { IInspectionFlow } from '../../../../../infrastructure/DTO/Inspection-flow/Inspection-flow.dto';
import { ExportFeaturesCsv, ExportInspectionFlowCsv } from '../../../../../types/ExportCsvType';
import { IEffectiveSettings } from '../../../../../infrastructure/DTO/effective-settings/effective-settings.dto';
import { importEffectiveSettings } from '../../../../../store/effective-settings/effective-settings';
import { importInspectionFlow } from '../../../../../store/effective-settings/inspection-flow';
import PopupBack from '../../../../popup-back/popup-back';
import { showPopup } from '../../../../../store/events/popup';
import errorParseMessage from '../../../../../config/error-parse';

interface ICreateProfileComponent {
    setCountProfiles(cnt: number): void;
}

type PopupBackType = {
    show: boolean;
    close: boolean;
    tab: number;
};

const CreateProfile: React.FC<ICreateProfileComponent> = (props: ICreateProfileComponent) => {
    const { token, checkPermission } = useContext(CustomAuthContext);
    const { popup } = useSelector((state: AppState) => state.popupEvent);
    const { profile, company, openCreateProfile, handleCreateProfile, setProfile, buttonNext } =
        useContext<IUserManagementProvider>(UserManagementContext);
    const { enqueueSnackbar } = useSnackbar();
    const [profileName, setProfileName] = React.useState<string>('');
    const { profiles, statusCreate, errorCreate } = useSelector((state: AppState) => state.profiles);
    const steps = ['Profile', 'Features', 'Inspection Flow'];
    const [activeStep, setActiveStep] = React.useState<number>(0);
    const dispatch: AppDispatch = useDispatch();

    const [popupBack, setPopupBack] = React.useState<PopupBackType>({ show: false, close: false, tab: 1 });

    const { effectiveSettings } = useSelector((state: AppState) => state.effectiveSettings);
    const { inspectionFlow } = useSelector((state: AppState) => state.inspectionFlow);

    const [featuresToCsv, setFeaturesToCsv] = React.useState<Array<ExportFeaturesCsv>>([]);
    const [inspectionFlowToCsv, setInspectionFlowToCsv] = React.useState<Array<ExportInspectionFlowCsv>>([]);

    /**
     * Send Profile
     * @param event
     */
    const handleNext = (event: any) => {
        event.preventDefault();

        const args: IArgsProfile = {
            company_id: company?.company_id,
            profile_id: profile?.profile_id,
        };

        const body: Partial<IProfile> = {
            name: profileName.trim(),
            is_active: true,
        };

        dispatch(fetchCreateProfile({ token, body, args }));
    };

    /**
     * Get status
     */
    React.useEffect(() => {
        if (statusCreate === StatusRedux.Succeeded) {
            enqueueSnackbar('Created', {
                variant: 'success',
            });

            setProfile(profiles.profiles[profiles.profiles.length - 1]);

            dispatch(clearStoreCreateProfile());
            props.setCountProfiles(profiles.count + 1);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        if (statusCreate === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorCreate), {
                variant: 'error',
            });

            dispatch(clearStoreCreateProfile());
        }
    }, [statusCreate]);

    const handleEffectiveSettings = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    /**
     * Set data InspectionFlow to export CSV
     */
    React.useEffect(() => {
        if (inspectionFlow.inspection_flow) {
            let dataCsv: Array<ExportInspectionFlowCsv> = [];

            inspectionFlow.inspection_flow.inspection_flow_sections.forEach((item) => {
                dataCsv.push({
                    name: item.section_name,
                    is_active: item.is_active,
                    position: item.order_position,
                    type: item.section_id,
                    validation: item.validation,
                });
                item.inspection_flow_images.forEach((flow_images) => {
                    if (flow_images.part_id !== null && flow_images.detection_status_mode.code === 'MANUAL') {
                        dataCsv.push({
                            name: flow_images.part_name as string,
                            is_active: flow_images.is_active,
                            position: flow_images.order_position,
                            type: flow_images.part_id as string,
                            side: 'Part',
                        });
                    }
                    if (flow_images.camera_view !== null && flow_images.detection_status_mode.code === 'AUTOMATED') {
                        dataCsv.push({
                            name: flow_images.camera_view.description as string,
                            is_active: flow_images.is_active,
                            position: flow_images.order_position,
                            type: flow_images.part_id as string,
                            side: 'Side',
                        });
                    }
                });
            });

            setInspectionFlowToCsv(dataCsv);
        }
    }, [inspectionFlow]);

    /**
     * Set data EffectiveSettings to export CSV
     */
    React.useEffect(() => {
        if (effectiveSettings.length > 0) {
            let dataCsv: Array<ExportFeaturesCsv> = [];

            effectiveSettings.forEach((item) => {
                if (item.setting.is_active) {
                    dataCsv.push({
                        feature_name: item.setting.name,
                        value: item.value,
                        description: item.setting.description,
                    });
                }
            });

            setFeaturesToCsv(
                dataCsv
                    .slice(0)
                    .sort((a, b) =>
                        a.feature_name.toLowerCase() < b.feature_name.toLowerCase()
                            ? -1
                            : a.feature_name.toLowerCase() > b.feature_name.toLowerCase()
                            ? 1
                            : 0
                    )
            );
        }
    }, [effectiveSettings]);

    /**
     * Update global state effective settings
     * @param obj
     * @interface IEffectiveSettings
     */
    const updateStateEffectiveSettings = (obj: Array<IEffectiveSettings>): void => {
        if (obj.length > 0) {
            if (checkObjIsEffectiveSettings(obj[0])) {
                dispatch(importEffectiveSettings(obj));
            }
        }
    };

    /**
     * Update global state inspection flow
     * @param obj
     * @interface IInspectionFlow
     */
    const updateStateIInspectionFlow = (obj: IInspectionFlow): void => {
        if (checkObjIsIInspectionFlow(obj)) {
            dispatch(importInspectionFlow(obj));
        }
    };

    const checkObjIsEffectiveSettings = (obj: IEffectiveSettings): obj is IEffectiveSettings =>
        'effective_setting_id' in obj && 'setting' in obj && 'value' in obj;
    const checkObjIsIInspectionFlow = (obj: IInspectionFlow): obj is IInspectionFlow => 'inspection_flow' in obj;

    /**
     * Close Popup
     */
    const handleClose = () => {
        if (!popup) {
            handleCreateProfile();
            setProfileName('');
            setActiveStep(0);
        } else {
            setPopupBack({ show: true, close: true, tab: 1 });
        }
    };

    const handleClosePopup = () => {
        setPopupBack({ show: false, close: true, tab: 0 });
    };

    const back = () => {
        dispatch(showPopup(false));

        if (popupBack.close) {
            setPopupBack({ show: false, close: true, tab: 0 });
            handleCreateProfile();
        } else {
            setPopupBack({ show: false, close: false, tab: popupBack.tab });
        }
    };

    return (
        <BasicDialog
            handlePopup={handleClose}
            open={openCreateProfile}
            title='Create Profile'
            width='xl'
            buttons={
                <Grid container spacing={3} justifyContent='flex-end'>
                    {activeStep === 0 && (
                        <Grid item>
                            {!checkPermission(UserPermission.USER_MANAGEMENT) && (
                                <Button
                                    disabled={buttonNext}
                                    form='create-profile'
                                    size='small'
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                    color='secondary'
                                    endIcon={<SaveIcon />}
                                >
                                    Next
                                </Button>
                            )}
                        </Grid>
                    )}
                    {activeStep === 1 && (
                        <Grid item xs>
                            {!checkPermission(UserPermission.USER_MANAGEMENT) && (
                                <Grid container justifyContent='space-between'>
                                    <Grid item>
                                        <ExportImportButtons<Array<IEffectiveSettings>, Array<ExportFeaturesCsv>>
                                            dataToCsv={featuresToCsv}
                                            data={effectiveSettings}
                                            file_name={`${company?.name}__${profileName}`}
                                            updateState={updateStateEffectiveSettings}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            form='effective-settings'
                                            size='small'
                                            fullWidth
                                            type='submit'
                                            variant='contained'
                                            color='secondary'
                                            endIcon={<SaveIcon />}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {(activeStep === 2 || activeStep === 3) && (
                        <Grid item xs>
                            {!checkPermission(UserPermission.USER_MANAGEMENT) && (
                                <Grid container justifyContent='space-between'>
                                    <Grid item>
                                        <ExportImportButtons<IInspectionFlow, Array<ExportInspectionFlowCsv>>
                                            dataToCsv={inspectionFlowToCsv}
                                            data={inspectionFlow}
                                            file_name={`${company?.name}__${profileName}__inspection_flow`}
                                            updateState={updateStateIInspectionFlow}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            form='inspection-flow'
                                            size='small'
                                            fullWidth
                                            type='submit'
                                            variant='contained'
                                            color='secondary'
                                            endIcon={<SaveIcon />}
                                        >
                                            Finish
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
            }
        >
            <Box sx={{ height: '70vh' }}>
                <PopupBack open={popupBack.show} close={handleClosePopup} back={back} />
                <Grid container spacing={2} direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid item xs={12}>
                        <Box sx={classes.bodyPopup}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: {
                                        optional?: React.ReactNode;
                                    } = {};
                                    return (
                                        <Step key={index} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {activeStep === 0 && (
                            <form id='create-profile' autoComplete='off' onSubmit={handleNext}>
                                <Grid container spacing={3} justifyContent='flex-end'>
                                    <ProfileName changeProfileName={setProfileName} />
                                </Grid>
                            </form>
                        )}
                        {activeStep === 1 && <EffectiveSettingsStep handleNext={handleEffectiveSettings} company={company} />}
                        {(activeStep === 2 || activeStep === 3) && <FlowInspection step={activeStep} handleNext={handleEffectiveSettings} />}
                    </Grid>
                </Grid>
            </Box>
        </BasicDialog>
    );
};

export default CreateProfile;
