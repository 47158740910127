import * as React from 'react';
import { useParams } from 'react-router-dom';
import InspectionDetails from './inspection-steps/inspection-details';
import './inspections.css';
import InspectionType from './inspection-steps/inspection-type';
import InspectionUploadImages from './inspection-steps/inspection-upload-images';
import InspectionProvider from '../../../../context/InspectionProvider';
import { clearStoreImages } from '../../../../store/inspection/images';
import { clearStoreInspections } from '../../../../store/inspection/inspection';
import { clearProcess } from '../../../../store/inspection/process';
import { onbeforeunload } from '../../../../utils/onbeforeunload';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { showPopup } from '../../../../store/events/popup';

type RouteParams = {
    id: string;
};

const InspectionCreate: React.FC = () => {
    const { id } = useParams<RouteParams>();
    const dispatch: AppDispatch = useDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(clearStoreImages());
            dispatch(clearStoreInspections());
            dispatch(clearProcess());
            onbeforeunload(undefined);
            dispatch(showPopup(false));
        };
    }, []);

    return (
        <InspectionProvider>
            {id === '1' && <InspectionDetails />}
            {id === '2' && <InspectionType />}
            {id === '3' && <InspectionUploadImages />}
        </InspectionProvider>
    );
};

export default InspectionCreate;
