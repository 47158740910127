import { IFeature } from '../infrastructure/DTO/inspection/feature.dto';

export const InspectionFeature: Array<IFeature> = [
    {
        id: 0,
        name: 'EXIF DATA EXTRACTION',
        type: 'EXIF_DATA_EXTRACTION',
        description: 'Extract and return exif data(if present) from the uploaded files.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 1,
        name: 'VEHICLE MODEL CHECK',
        type: 'VEHICLE_MODEL_CHECK',
        checked: false,
        viewInBulk: false,
        description: 'Cross=check detected car model, make, submodel, bodystyle and year to the provided data.',
        depends: [],
    },
    {
        id: 2,
        name: 'LICENSE PLATE NUMBER DETECTION',
        type: 'LICENSE_PLATE_NUMBER_DETECTION',
        description: 'Detect license plate number (available only for rear and front views where LPN is visible',
        checked: false,
        viewInBulk: true,
        depends: [],
    },
    {
        id: 3,
        name: 'ALL ANGLES CHECK',
        type: 'ALL_ANGLES_CHECK',
        description: 'Check if four angles(LEFT, RIGHT, FRONT, REAR) were uploaded.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 4,
        name: 'PRINCIPAL COLOR DETECTION',
        type: 'PRINCIPAL_COLOR_DETECTION',
        description: 'Detect principal color of the car. The result will be returned in RGB format.',
        checked: false,
        viewInBulk: false,
        depends: [13],
    },
    {
        id: 5,
        name: 'DAMAGE MEASUREMENT',
        type: 'DAMAGE_MEASUREMENT',
        description: 'Measure the damage in absolute units (inch/cm) and return standard damage severity codes.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 6,
        name: 'PANEL DISTANCE MEASUREMENT',
        type: 'PANEL_DISTANCE_MEASUREMENT',
        description: 'Return distances from damage to the panels.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 7,
        name: 'FRAUD DETECTION',
        type: 'FRAUD_DETECTION',
        description: 'Generate DamagePrints and match the damage to the repository.Alert is triggered whenever a match is found.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 8,
        name: 'PARTS SEGMENTATION',
        type: 'PARTS_SEGMENTATION',
        description: 'Return exact boundaries (outline) of all visible vehicle parts.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 9,
        name: 'VEHICLE SEGMENTATION',
        type: 'VEHICLE_SEGMENTATION',
        description: 'Return the exact outline of the car and the boundaries of the object.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 10,
        name: 'RETURN VEHICLE MASKS',
        type: 'RETURN_VEHICLE_MASKS',
        description:
            'If specified, canonical masks of the particular vehicle model from four angles will be returned in PNG format. May be used for visual guidance in taking pictures.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 11,
        name: 'CLOSEUP REGISTRATION',
        type: 'CLOSEUP_REGISTRATION',
        description: 'Check if close-up pictures match to the full-frame view of the car and return guidance in case there is no match.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 12,
        name: 'RUN DAMAGE DETECTION ON UPLOAD',
        type: 'RUN_DAMAGE_DETECTION_ON_UPLOAD',
        description: 'Damage detection process is triggered automatically by upload and the result is returned synchronously.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 13,
        name: 'GENERATE DAMAGE OVERLAY',
        type: 'GENERATE_DAMAGE_OVERLAY',
        description: 'Overlay all detected damages on the original image and return in PNG.',
        checked: false,
        viewInBulk: false,
        depends: [4],
    },
    {
        id: 14,
        name: 'OVERALL DAMAGE GRADE',
        type: 'OVERALL_DAMAGE_GRADE',
        description: 'This grade explains how damaged the car is as a simple overview.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 15,
        name: 'CLOSEUP DAMAGE MEASUREMENT',
        type: 'CLOSEUP_DAMAGE_MEASUREMENT',
        description: 'Measure the damage in absolute units (inch/cm) and return standard damage severity codes.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 16,
        name: 'CLOSEUP PANEL DISTANCE MEASUREMENT',
        type: 'CLOSEUP_PANEL_DISTANCE_MEASUREMENT',
        description: 'Return distances from damage to the panels.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 17,
        name: 'ENABLE EIGHT ANGLES',
        type: 'ENABLE_EIGHT_ANGLES',
        description: 'Returns the position of the camera, accurate to the corner from which the image was taken.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 18,
        name: 'DAMAGE AREA CODE',
        type: 'DAMAGE_AREA_CODE',
        description: 'Return damage area code.',
        checked: false,
        viewInBulk: false,
        depends: [],
    },
    {
        id: 19,
        name: 'CONSOLIDATE RESULTS',
        type: 'CONSOLIDATE_RESULTS',
        description: 'Consolidate results.',
        checked: false,
        viewInBulk: true,
        depends: [],
    },
];
