import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Inspection from '../../services/inspection/inspection';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchBody } from '../IFetch';
import { IInspectionFilter, IInspectionsList } from '../../infrastructure/DTO/inspection/inspection.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchInspectionFilter = createAsyncThunk<IInspectionsList, IFetchBody<IInspectionFilter>>(
    'inspection/filter',
    async (params: IFetchBody<IInspectionFilter>) => {
        return await Inspection.getInspectionsByFilter(params);
    }
);

export const initFilter: IInspectionFilter = {
    company_id: '',
    business_unit_id: '',
    client_process_id: '',
    limit: 25,
    offset: 0,
    sort_by: 'date',
    order_by: 'desc',
    inspection_id: '',
    client_name: '',
    lpn: '',
    vin: '',
    inspection_types: [],
    inspection_statuses: [],
    from_date: null,
    to_date: null,
    closure_reason: [],
    search: '',
    call_back_response_result_not_sent: null,
};

export const inspectionListSlice = createSlice({
    name: 'inspection',
    initialState: {
        inspectionListData: {} as IInspectionsList,
        inspectionFilter: initFilter as IInspectionFilter,
        statusInspectionList: '',
        errorInspectionList: {} as IErrorResponse,
    },
    reducers: {
        clearStoreInspectionList: (state) => {
            state.statusInspectionList = '';
            state.errorInspectionList = {} as IErrorResponse;
            state.inspectionListData = {} as IInspectionsList;
            state.inspectionFilter = initFilter;
        },
        inspectionFilterStore: (state, action) => {
            state.inspectionFilter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInspectionFilter.pending, (state) => {
            state.statusInspectionList = StatusRedux.Loading;
        });
        builder.addCase(fetchInspectionFilter.fulfilled, (state, action) => {
            state.statusInspectionList = StatusRedux.Succeeded;
            state.inspectionListData = action.payload;
        });
        builder.addCase(fetchInspectionFilter.rejected, (state, action) => {
            state.statusInspectionList = StatusRedux.Failed;
            state.errorInspectionList = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreInspectionList, inspectionFilterStore } = inspectionListSlice.actions;
export default inspectionListSlice;
