import React, { useContext, useRef } from 'react';
import Konva from '../../../../../../common/ui-components/Konva';
import { Group, Image as Pic, Layer, Line } from 'react-konva';
import { classes } from './style';
import {
    Checkbox,
    Collapse,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { DAMAGE_COLOR, DAMAGE_NAME } from '../../../../../../enums/Damage';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../../../store';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { clearStatusDamageLab, fetchAnomalyMeasurements } from '../../../../../../store/damage-lab/upload-img';
import { StatusRedux } from '../../../../../../enums/StatusRedux';
import { useSnackbar } from 'notistack';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { BasicDialog } from '../../../../../../common/ui-components/Dialog';
import { SkeletonList, SkeletonImg } from '../../../../../../common/ui-components/Skeleton';
import { CustomAuthContext } from '../../../../../../context/AuthProvider';
import { IMeasurements } from '../../../../../../infrastructure/DTO/damage-lab/anomaly-measurements.dto';
import { IBoundingBox } from '../../../../../../infrastructure/DTO/damage-lab/damage-lab.dto';
import { IArgsDamageLab } from '../../../../../../infrastructure/DTO/damage-lab/anomalies-detection.dto';
import errorParseMessage from '../../../../../../config/error-parse';

type DamagesType = {
    id: number;
    damage_id: string;
    damage_status: string;
    damage_type: number;
    score: string;
    contours: Array<{ x: number; y: number }>;
};

interface IZoom {
    id?: string;
    isZoom: boolean;
    x: number;
    y: number;
    width: number;
    height: number;
}

const Measurement: React.FC = () => {
    const { token } = useContext(CustomAuthContext);
    const [coordinates, setCoordinates] = React.useState<{ x: number; y: number }>({ x: 1, y: 1 });
    const { damageLabData, anomalyMeasurements, status, error } = useSelector((state: AppState) => state.damageLabSingleImg);
    const refDiv = useRef<HTMLDivElement | null>(null);
    const { imageMain } = useSelector((state: AppState) => state.damageLabImage);
    const [size, setSize] = React.useState<{ width: number; height: number }>({ width: 1, height: 1 });
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const [measurements, setMeasurements] = React.useState<Map<string, { measurement: Array<IMeasurements>; bounding_box: IBoundingBox }>>(
        new Map<string, { measurement: Array<IMeasurements>; bounding_box: IBoundingBox }>()
    );
    const [measurementCurrentData, setMeasurementCurrentData] = React.useState<{
        result: Array<{ key: string; value: any | null; tag: any }> | null;
        minimalEnclosingBox: Array<{ key: string; value: any | null; tag: any }> | null;
        threeBoundingBox: Array<{ key: string; value: any | null }> | null;
    }>({
        result: null,
        minimalEnclosingBox: null,
        threeBoundingBox: null,
    });
    const [points, setPoints] = React.useState<Map<string, { pointsArr: Array<number>; color: string }>>(
        new Map<string, { pointsArr: Array<number>; color: string }>()
    );
    const [measurementPoints, setMeasurementPoints] = React.useState<Array<number>>([]);
    const [checked, setChecked] = React.useState<Array<string>>([]);
    const [wheel, setWheel] = React.useState<boolean>(true);
    const [opened, setOpened] = React.useState<Array<string>>([]);
    const [damages, setDamages] = React.useState<Array<DamagesType>>([]);
    const [panelContours, setPanelContours] = React.useState<Array<Array<number>>>([]);
    const [quadrangle, setQuadrangle] = React.useState<Array<number>>([]);
    const [showInfo, setInfo] = React.useState<boolean>(false);
    const [zoomPerArea, setZoomPerArea] = React.useState<IZoom>({
        isZoom: false,
        x: 1,
        y: 1,
        width: 1,
        height: 1,
    });

    React.useEffect(() => {
        const args: IArgsDamageLab = {
            file_id: damageLabData.uploaded_file_id,
            inspection_case_id: damageLabData.inspection_case_id,
        };

        if (refDiv.current !== null) {
            setSize({
                width: refDiv.current.clientWidth,
                height: refDiv.current.clientHeight,
            });
        }

        dispatch(
            fetchAnomalyMeasurements({
                token,
                args,
            })
        );
    }, []);

    React.useEffect(() => {
        if (refDiv.current !== null) {
            let scale = Math.min(size.width / imageMain.width, size.height / imageMain.height);

            setCoordinates({
                x: size.width / 2 - (imageMain.width * scale) / 2,
                y: size.height / 2 - (imageMain.height * scale) / 2,
            });
        }
    }, [size.width, size.height, refDiv.current]);

    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            enqueueSnackbar('Submitted Successfully', {
                variant: 'success',
            });

            const objMeasurements = new Map<string, { measurement: Array<IMeasurements>; bounding_box: IBoundingBox }>(new Map());

            if (anomalyMeasurements.anomaly_measurements?.length > 0) {
                if (damageLabData.detected_damages) {
                    damageLabData.detected_damages.forEach((damage) => {
                        let indexAnomaly = anomalyMeasurements.anomaly_measurements.findIndex((anomaly) => anomaly.damage_id === damage.damage_id);
                        let indexDamage = damageLabData.detected_damages.findIndex((anomaly) => anomaly.damage_id === damage.damage_id);

                        if (indexAnomaly !== -1) {
                            objMeasurements.set(damage.damage_id, {
                                measurement: anomalyMeasurements.anomaly_measurements[indexAnomaly].measurements,
                                bounding_box: damageLabData.detected_damages[indexDamage].segmentation.bounding_box,
                            });
                        }
                    });
                }
            }

            const arrDamages: Array<DamagesType> = [];

            if (damageLabData.detected_damages) {
                damageLabData.detected_damages
                    .filter((damage) => damage.damage_status.code !== 'DELETED')
                    .map((i, index: number) => {
                        arrDamages.push({
                            id: index + 1,
                            damage_id: i.damage_id,
                            damage_status: i.damage_status.code,
                            damage_type: i.damage_type,
                            score: (i.segmentation.score * 100).toFixed(2),
                            contours: i.segmentation.contours[0],
                        });
                    });
            }

            setDamages(arrDamages);
            toggleCheckedDamagesAll(true);

            dispatch(clearStatusDamageLab());
            setMeasurements(objMeasurements);
        }

        if (status === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(error), {
                variant: 'error',
            });

            dispatch(clearStatusDamageLab());
        }
    }, [status]);

    const handleToggle = (value: DamagesType) => () => {
        let point: Array<number> = [];
        let scale = Math.min(size.width / imageMain.width, size.height / imageMain.height);

        value.contours.map((p) => {
            point.push(Number(p.x * scale), Number(p.y * scale));
        });

        if (points.has(value.damage_id)) {
            points.delete(value.damage_id);
        } else {
            setPoints(points.set(value.damage_id, { pointsArr: point, color: DAMAGE_COLOR[value.damage_type] }));
        }

        const currentIndex = checked.indexOf(value.damage_id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value.damage_id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleClickOpen = (value: DamagesType) => () => {
        const currentIndex = opened.indexOf(value.damage_id);
        const openedArr = [...opened];

        if (currentIndex === -1) {
            openedArr.push(value.damage_id);
        } else {
            openedArr.splice(currentIndex, 1);
        }
        setOpened(openedArr);
    };

    const handleMove = (e: any) => {
        setCoordinates({
            x: e.target.x(),
            y: e.target.y(),
        });
    };

    const toggleCheckedDamagesAll = (check: boolean) => {
        const obj = new Map();
        const newChecked = [...checked];
        let scale = Math.min(size.width / imageMain.width, size.height / imageMain.height);

        if (check) {
            if (damageLabData.detected_damages !== null) {
                damageLabData.detected_damages.forEach((item) => {
                    let point: Array<number> = [];

                    item.segmentation.contours[0].forEach((p) => {
                        point.push(Number(p.x * scale), Number(p.y * scale));
                    });

                    obj.set(item.damage_id, { pointsArr: point, color: DAMAGE_COLOR[item.damage_type] });
                    newChecked.push(item.damage_id);
                });
            }

            setPoints(obj);
            setChecked(newChecked);
        } else {
            setPoints(new Map());
            setChecked([]);
        }
    };

    const handleAnomaly = (measurement: IMeasurements) => () => {
        showMeasurement(measurement);
    };

    const showMeasurement = (measurement: IMeasurements) => {
        let points: number[] = [];
        let panelContoursPoints: any = [];
        let scale = Math.min(size.width / imageMain.width, size.height / imageMain.height);

        let result: Array<{ key: string; value: any | null; tag: any }> = [
            { key: 'Damaged Part', value: measurement?.damaged_part.part_description, tag: null },
            { key: 'Side', value: damageLabData.camera_view.replace('_', ' '), tag: null },
            { key: 'Damage Area Codes', value: measurement?.damage_area_codes !== null && measurement?.damage_area_codes.join(', '), tag: null },
            {
                key: `Part Area, `,
                value: !isNaN(measurement?.damaged_part_area as number) ? measurement?.damaged_part_area?.toFixed(2) : 0,
                tag: (
                    <span>
                        cm<sup>2</sup>
                    </span>
                ),
            },
            {
                key: 'Damage Area, ',
                value: !isNaN(measurement?.damage_area as number) ? measurement?.damage_area?.toFixed(2) : 0,
                tag: (
                    <span>
                        cm<sup>2</sup>
                    </span>
                ),
            },
            {
                key: 'Damage Severity, %',
                value: !isNaN(measurement?.damage_severity as number) ? +((measurement?.damage_severity as number) * 100).toFixed(2) : 0,
                tag: null,
            },
        ];

        let minimalEnclosingBox: Array<{ key: string; value: any | null; tag: any }> = [
            { key: 'Width, cm', value: measurement?.minimal_enclosing_box?.width?.toFixed(2), tag: null },
            { key: 'Height, cm', value: measurement?.minimal_enclosing_box?.height?.toFixed(2), tag: null },
            {
                key: 'Area, ',
                value: measurement?.minimal_enclosing_box?.area?.toFixed(2),
                tag: (
                    <span>
                        cm<sup>2</sup>
                    </span>
                ),
            },
            { key: 'Minimum distance to Panel, cm', value: measurement?.minimum_distance_to_panels.distance?.toFixed(2), tag: null },
        ];

        let threeBoundingBox: Array<{ key: string; value: any | null }> = [
            { key: 'XgeoMin, cm', value: measurement?.minimal_3d_enclosing_box.min.x?.toFixed(2) },
            { key: 'YgeoMin, cm', value: measurement?.minimal_3d_enclosing_box.min.y?.toFixed(2) },
            { key: 'ZgeoMin, cm', value: measurement?.minimal_3d_enclosing_box.min.z?.toFixed(2) },
            { key: 'XgeoMax, cm', value: measurement?.minimal_3d_enclosing_box.max.x?.toFixed(2) },
            { key: 'YgeoMax, cm', value: measurement?.minimal_3d_enclosing_box.max.y?.toFixed(2) },
            { key: 'ZgeoMax, cm', value: measurement?.minimal_3d_enclosing_box.max.z?.toFixed(2) },
        ];

        setMeasurementCurrentData({
            minimalEnclosingBox: minimalEnclosingBox,
            result: result,
            threeBoundingBox: threeBoundingBox,
        });

        setZoomPerArea({
            isZoom: true,
            id: measurement?.measurement_id,
            x: measurement.roi.x * scale,
            y: measurement.roi.y * scale,
            width: measurement.roi.width * scale,
            height: measurement.roi.height * scale,
        });

        setCoordinates({
            x: -(measurement.roi.x * scale),
            y: -(measurement.roi.y * scale),
        });

        setWheel(false);

        if (measurement.contour !== null) {
            measurement.contour.forEach((point) => {
                points.push(Math.floor(point.x * scale), Math.floor(point.y * scale));
            });
        }

        if (measurement.panel_contours !== null) {
            measurement.panel_contours.forEach((point) => {
                let points: number[] = [];
                point.forEach((p) => {
                    points.push(Math.floor(p.x * scale), Math.floor(p.y * scale));
                });
                panelContoursPoints.push(points);
            });
        }

        setPanelContours(panelContoursPoints);
        setMeasurementPoints(points);

        if (measurement.minimal_enclosing_box?.quadrangle !== undefined) {
            if (measurement.minimal_enclosing_box?.quadrangle !== null) {
                setQuadrangle([
                    Math.floor(measurement.minimal_enclosing_box.quadrangle.corner1.x * scale),
                    Math.floor(measurement.minimal_enclosing_box.quadrangle.corner1.y * scale),
                    Math.floor(measurement.minimal_enclosing_box.quadrangle.corner2.x * scale),
                    Math.floor(measurement.minimal_enclosing_box.quadrangle.corner2.y * scale),
                    Math.floor(measurement.minimal_enclosing_box.quadrangle.corner4.x * scale),
                    Math.floor(measurement.minimal_enclosing_box.quadrangle.corner4.y * scale),
                    Math.floor(measurement.minimal_enclosing_box.quadrangle.corner3.x * scale),
                    Math.floor(measurement.minimal_enclosing_box.quadrangle.corner3.y * scale),
                ]);
            } else {
                setQuadrangle([]);
            }
        } else {
            setQuadrangle([]);
        }
    };

    const handleShowMeasure = (measurement: IMeasurements) => () => {
        setInfo(true);
        showMeasurement(measurement);
    };

    const handleCloseMeasure = () => {
        setInfo(false);
        setMeasurementCurrentData({
            minimalEnclosingBox: null,
            result: null,
            threeBoundingBox: null,
        });
    };

    return (
        <>
            {status === StatusRedux.Loading ? (
                <Grid container spacing={5} direction='row'>
                    <Grid item xs={12} sm={12} md={6} lg={8} xl={9}>
                        <SkeletonImg width={'100%'} height={'650px'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SkeletonList checkbox={true} num={5} />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid container spacing={5} direction='row'>
                        <Grid sx={classes.anim} item xs={12} sm={12} md={6} lg={8} xl={9}>
                            <Konva
                                zoomPerArea={zoomPerArea}
                                wheel={wheel}
                                konvaRef={refDiv}
                                style={classes.stage}
                                width={size.width}
                                height={size.height}
                            >
                                <Layer>
                                    <Group x={coordinates.x} y={coordinates.y} draggable onDragMove={handleMove} onDragEnd={handleMove}>
                                        {!zoomPerArea.isZoom && (
                                            <>
                                                <Pic
                                                    width={imageMain.width * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                                    height={imageMain.height * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                                    image={imageMain.image}
                                                />
                                                {Array.from(points.values()).map((line, i) => (
                                                    <Group key={i}>
                                                        <Line
                                                            key={i}
                                                            points={line.pointsArr}
                                                            stroke={line.color}
                                                            strokeWidth={1.5}
                                                            closed={true}
                                                            lineCap='square'
                                                            globalCompositeOperation='source-over'
                                                        />
                                                    </Group>
                                                ))}
                                            </>
                                        )}
                                        {zoomPerArea.isZoom && (
                                            <>
                                                <Pic
                                                    width={imageMain.width * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                                    height={imageMain.height * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                                    image={imageMain.image}
                                                />
                                                {
                                                    <Group>
                                                        <Line
                                                            key={1}
                                                            points={measurementPoints}
                                                            stroke='#ffffff'
                                                            fill='rgb(255 0 0 / 45%)'
                                                            closed={true}
                                                            strokeWidth={0.3}
                                                            lineCap='square'
                                                            globalCompositeOperation='source-over'
                                                        />
                                                        {panelContours.length > 0 &&
                                                            panelContours.map((point, index) => (
                                                                <Line
                                                                    key={index}
                                                                    points={point}
                                                                    stroke='red'
                                                                    strokeWidth={0.5}
                                                                    globalCompositeOperation='source-over'
                                                                />
                                                            ))}
                                                        {quadrangle.length > 0 && (
                                                            <Line
                                                                key={3}
                                                                points={quadrangle}
                                                                stroke='yellow'
                                                                closed={true}
                                                                strokeWidth={0.5}
                                                                lineCap='square'
                                                                globalCompositeOperation='source-over'
                                                            />
                                                        )}
                                                    </Group>
                                                }
                                            </>
                                        )}
                                    </Group>
                                </Layer>
                            </Konva>
                        </Grid>
                        <Grid sx={classes.anim} item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <List sx={classes.listMain} subheader={<ListSubheader component='div'>Damages List</ListSubheader>}>
                                {damages !== null &&
                                    damages.map((value, index) => {
                                        const labelId = `checkbox-list-label-${value.damage_id}`;

                                        return (
                                            <div key={index}>
                                                <ListItem dense button>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            onClick={handleToggle(value)}
                                                            sx={classes.checkBoxList}
                                                            disabled={zoomPerArea.isZoom}
                                                            edge='start'
                                                            checked={checked.indexOf(value.damage_id) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={`#${value.id} - ${DAMAGE_NAME[value.damage_type]}`} />
                                                    <ListItemIcon>
                                                        <Chip
                                                            size='small'
                                                            label={`${value.score} %`}
                                                            style={{ color: '#fff', background: DAMAGE_COLOR[value.damage_type] }}
                                                        />
                                                    </ListItemIcon>
                                                    {opened.indexOf(value.damage_id) !== -1 ? (
                                                        <ExpandLess onClick={handleClickOpen(value)} sx={classes.iconLessMore} />
                                                    ) : (
                                                        <ExpandMore onClick={handleClickOpen(value)} sx={classes.iconLessMore} />
                                                    )}
                                                </ListItem>
                                                <Collapse in={opened.indexOf(value.damage_id) !== -1} timeout='auto' unmountOnExit>
                                                    <List key={index + 1} component='div' disablePadding>
                                                        {measurements.size > 0 &&
                                                            measurements.get(value.damage_id)!.measurement.map((measurement, index) => {
                                                                return (
                                                                    <ListItem
                                                                        key={index + 200}
                                                                        button
                                                                        sx={classes.nested}
                                                                        onClick={handleAnomaly(measurement)}
                                                                    >
                                                                        <ListItemText primary='Measure' secondary={measurement.measurement_id} />
                                                                        <ListItemSecondaryAction>
                                                                            <IconButton
                                                                                onClick={handleShowMeasure(measurement)}
                                                                                edge='end'
                                                                                aria-label='delete'
                                                                            >
                                                                                <InfoIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                );
                                                            })}
                                                    </List>
                                                </Collapse>
                                                <Divider />
                                            </div>
                                        );
                                    })}
                            </List>
                        </Grid>
                    </Grid>
                    <BasicDialog handlePopup={handleCloseMeasure} open={showInfo} title='Measure Result' width='md'>
                        <DialogContent>
                            <Grid container spacing={3} direction='row'>
                                <Grid item xs={12}>
                                    <TableContainer sx={classes.table}>
                                        <Table size='small' stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={classes.head}># ID</TableCell>
                                                    <TableCell sx={classes.head} align='center'>
                                                        Parameter
                                                    </TableCell>
                                                    <TableCell sx={classes.head} align='center'>
                                                        Value
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {measurementCurrentData.result?.map((data, index) => (
                                                    <TableRow hover sx={classes.tableRow} key={index}>
                                                        <TableCell component='th' scope='row'>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {data.key} {data.tag}
                                                        </TableCell>
                                                        <TableCell align='center'>{data.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Typography sx={classes.tableTitle} variant='subtitle2' gutterBottom>
                                    Minimal Enclosing Box
                                </Typography>
                                <Grid item xs={12}>
                                    <TableContainer sx={classes.table}>
                                        <Table size='small' stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={classes.head}># ID</TableCell>
                                                    <TableCell sx={classes.head} align='center'>
                                                        Parameter
                                                    </TableCell>
                                                    <TableCell sx={classes.head} align='center'>
                                                        Value
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {measurementCurrentData.minimalEnclosingBox?.map((data, index) => (
                                                    <TableRow hover sx={classes.tableRow} key={index}>
                                                        <TableCell component='th' scope='row'>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {data.key} {data.tag}
                                                        </TableCell>
                                                        <TableCell align='center'>{data.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Typography sx={classes.tableTitle} variant='subtitle2' gutterBottom>
                                    Minimal 3D Enclosing Box
                                </Typography>
                                <Grid item xs={12}>
                                    <TableContainer sx={classes.table}>
                                        <Table size='small' stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={classes.head}># ID</TableCell>
                                                    <TableCell sx={classes.head} align='center'>
                                                        Parameter
                                                    </TableCell>
                                                    <TableCell sx={classes.head} align='center'>
                                                        Value
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {measurementCurrentData.threeBoundingBox?.map((data, index) => (
                                                    <TableRow hover sx={classes.tableRow} key={index}>
                                                        <TableCell component='th' scope='row'>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align='center'>{data.key}</TableCell>
                                                        <TableCell align='center'>{data.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </BasicDialog>
                </>
            )}
        </>
    );
};

export default Measurement;
