import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, IconButton, Paper } from '@mui/material';
import { classes } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../store';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { clearCreateStatusThresholdSettings, fetchCreateThresholdSettings } from '../../../../store/inspection/create-threshold-settings';
import { clearGetStatusThresholdSettings, fetchGetThresholdSettings } from '../../../../store/inspection/get-threshold-settings';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import Threshold from '../../../../common/ui-components/Threshold';
import Box from '@mui/material/Box';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import {
    IArgsThresholdSettings,
    IThresholdSettings,
    IThresholdsPerDamage,
} from '../../../../infrastructure/DTO/threshold-settings/threshold-settings.dto';
import { IInspectionCaseId } from '../../../../infrastructure/DTO/inspection/inspection.dto';
import errorParseMessage from '../../../../config/error-parse';

interface ThresholdInspectionComponent {
    open: boolean;
    close: Function;
}

const ThresholdInspection: React.FC<ThresholdInspectionComponent> = (props: ThresholdInspectionComponent) => {
    const { token } = useContext(CustomAuthContext);
    const MIN = 0;
    const MAX = 1;
    const { enqueueSnackbar } = useSnackbar();
    const { inspections } = useSelector((state: AppState) => state.inspection);
    const createThresholdSettings = useSelector((state: AppState) => state.createThresholdSettings);
    const getThresholdSettings = useSelector((state: AppState) => state.getThresholdSettings);
    const dispatch: AppDispatch = useDispatch();
    const [data, setData] = React.useState<IThresholdSettings>({
        settings: {
            thresholds_per_damage_type: [
                { damage_type: 4, threshold: 0 },
                { damage_type: 6, threshold: 0 },
                { damage_type: 12, threshold: 0 },
                { damage_type: 50, threshold: 0 },
                { damage_type: 51, threshold: 0 },
                { damage_type: 52, threshold: 0 },
                { damage_type: 53, threshold: 0 },
                { damage_type: 54, threshold: 0 },
            ],
        },
    });

    React.useEffect(() => {
        const args: IInspectionCaseId = {
            inspection_case: inspections.inspection_case_id,
        };
        dispatch(
            fetchGetThresholdSettings({
                token,
                args,
            })
        );
    }, []);

    React.useEffect(() => {
        if (getThresholdSettings.status === StatusRedux.Succeeded) {
            setData({
                settings: {
                    thresholds_per_damage_type: getThresholdSettings.thresholdSettings.settings.thresholds_per_damage_type
                        .filter((t) => t.damage_type !== 8)
                        .map((i) => {
                            return { damage_type: i.damage_type, threshold: i.threshold };
                        }),
                },
            });

            dispatch(clearGetStatusThresholdSettings());
        }
    }, [getThresholdSettings.status]);

    const handleClosePopup = () => {
        props.close();
    };

    React.useEffect(() => {
        if (createThresholdSettings.status === StatusRedux.Succeeded) {
            enqueueSnackbar('Successfully Reset', {
                variant: 'success',
            });

            dispatch(clearCreateStatusThresholdSettings());
            props.close();
        }

        if (createThresholdSettings.status === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(createThresholdSettings.error), {
                variant: 'error',
            });

            dispatch(clearCreateStatusThresholdSettings());
        }
    }, [createThresholdSettings.status]);

    const handleChangeInput = (event: any) => {
        let res: IThresholdsPerDamage;
        let { name, value } = event.target;

        if (parseFloat(value) > MAX) value = MAX;
        if (parseFloat(value) < MIN || value === undefined) value = MIN;

        res = {
            damage_type: parseInt(name),
            threshold: parseFloat(value),
        };

        setData({
            settings: {
                thresholds_per_damage_type: [
                    ...data.settings.thresholds_per_damage_type.map((i) => {
                        if (i.damage_type === res.damage_type) {
                            i.threshold = res.threshold;
                        }
                        return i;
                    }),
                ],
            },
        });
    };

    const handleApplyThreshold = () => {
        const dataSend: IArgsThresholdSettings = {
            settings: data.settings,
        };

        const args: IInspectionCaseId = {
            inspection_case: inspections.inspection_case_id,
        };

        dispatch(
            fetchCreateThresholdSettings({
                token: token,
                args: args,
                body: dataSend,
            })
        );
    };

    return (
        <Dialog scroll='paper' fullWidth maxWidth='md' open={props.open} onClose={handleClosePopup} aria-labelledby='max-width-dialog-title'>
            <Box sx={classes.headerPopupMain}>
                <DialogTitle id='max-width-dialog-title'>Thresholds</DialogTitle>
                <IconButton sx={classes.iconButton} onClick={handleClosePopup}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent dividers={true}>
                <Paper sx={classes.paper} elevation={3}>
                    <Threshold
                        handleChangeInput={handleChangeInput}
                        handleApplyThreshold={handleApplyThreshold}
                        thresholds_per_damage_type={data.settings.thresholds_per_damage_type}
                        thresholds_per_size={{ xs: 12, sm: 12, md: 6, lg: 3, xl: 3 }}
                    />
                </Paper>
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(ThresholdInspection);
