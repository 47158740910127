import { alpha, CSSObject, Theme } from '@mui/material';

const root = (): CSSObject => ({
    width: '100%',
    marginBottom: '50px',
});

const textCenter = (): CSSObject => ({
    textAlign: 'center',
});

const main = (): CSSObject => ({
    width: '100%',
    height: 'calc(100vh - 180px)',
});

const container = (): CSSObject => ({
    height: 'calc(100vh - 235px)',
    position: 'relative',
});

const titlePage = (): CSSObject => ({
    textTransform: 'uppercase',
});

const titleFilterBlock = (): CSSObject => ({
    marginBottom: '10px',
    textTransform: 'uppercase',
    textAlign: 'center',
});

const filterDateName = (): CSSObject => ({
    marginTop: '10px',
    fontSize: '1.2em',
});

const btnPage = (): CSSObject => ({
    textAlign: 'right',
    '& > button': {
        marginLeft: '25px',
    },
});

const titleMainBlock = (): CSSObject => ({
    textTransform: 'uppercase',
});

const subTitleMainBlock = (): CSSObject => ({
    fontSize: '1.3em',
    textAlign: 'center',
    fontWeight: 500,
    textTransform: 'uppercase',
    paddingTop: '20px',
    marginTop: '10px',
    paddingBottom: '20px',
});

const titleBlock = (theme: Theme): CSSObject => ({
    textAlign: 'center',
    paddingTop: '20px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: alpha(theme.palette.secondary.main, 1),
});

const numberBlock = (theme: Theme): CSSObject => ({
    fontSize: '35px',
    marginTop: '10px',
    paddingBottom: '20px',
    textAlign: 'center',
    fontWeight: 600,
    color: alpha(theme.palette.secondary.main, 1),
});

const visuallyHidden = (): CSSObject => ({
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: '20px',
    width: 1,
});

const cellLong = (): CSSObject => ({
    width: '300px',
    maxWidth: '300px',
    minWidth: '300px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
});

const cellCnt = (): CSSObject => ({
    width: '50px',
    minWidth: '50px',
    maxWidth: '50px',
});

const cellId = (): CSSObject => ({
    width: '150px',
});

const btnCopy = (): CSSObject => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
});

const cellShort = (): CSSObject => ({
    width: '100px',
    maxWidth: '100px',
    minWidth: '100px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:hover': {
        cursor: 'pointer',
    },
});

const iconBtn = (): CSSObject => ({
    marginRight: '5px',
});

const uppercaseText = (): CSSObject => ({
    textTransform: 'uppercase',
});

const filterBlock = (): CSSObject => ({
    padding: '15px 10px!important',
    height: '100vh',
    overflowY: 'scroll',
    width: '270px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const filterChip = (): CSSObject => ({
    margin: '5px!important',
});

const tooltipUserName = (): CSSObject => ({
    padding: '3px 0px',
    display: 'flex',
});

const emptyResult = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',
    alignItems: 'center',
    fontSize: '1.2em',
    fontWeight: 'bold',
});

const paper = (): CSSObject => ({
    marginBottom: '15px',
    padding: '15px',
});

const buttonBlock = (): CSSObject => ({
    marginBottom: '15px',
});

const flex = (): CSSObject => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > button': {
        marginLeft: '15px',
    },
});

export const classes = {
    paper,
    buttonBlock,
    flex,
    emptyResult,
    numberBlock,
    visuallyHidden,
    filterChip,
    cellLong,
    cellId,
    tooltipUserName,
    btnCopy,
    cellShort,
    iconBtn,
    titleFilterBlock,
    titleBlock,
    subTitleMainBlock,
    titleMainBlock,
    btnPage,
    filterDateName,
    uppercaseText,
    filterBlock,
    root,
    titlePage,
    textCenter,
    main,
    container,
    cellCnt,
};
