import * as React from 'react';
import './app.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { UserLoginPage } from '../login';
import PrivateRoute from '../../routes/private-route';
import { LayoutMain } from '../pages/layout-main';
import usePrivateRoutesConfig from '../../config/private-routes-config';
import { MenuRoute } from '../../routes/RouteType';
import { NotFound } from '../not-found';

const App: React.FC = () => {
    const routes: Array<Pick<MenuRoute, 'path' | 'render' | 'element' | 'index' | 'children'>> = usePrivateRoutesConfig();

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<UserLoginPage />} />
                <Route path='home' element={<Navigate to='inspections' replace />} />
                <Route
                    path='home'
                    element={
                        <PrivateRoute>
                            <LayoutMain />
                        </PrivateRoute>
                    }
                >
                    <>
                        <Route path='*' element={<NotFound />} />
                        {routes.map((r, i) => {
                            return (
                                r.render && (
                                    <React.Fragment key={i}>
                                        <Route path={r.path} element={r.element}>
                                            {r.children.map(
                                                (ch, j) =>
                                                    ch?.render && (
                                                        <React.Fragment key={j}>
                                                            ch?.index ? (
                                                            <Route index element={ch?.element} />
                                                            ) : (
                                                            <Route path={ch?.path as string} element={ch?.element} />)
                                                        </React.Fragment>
                                                    )
                                            )}
                                        </Route>
                                    </React.Fragment>
                                )
                            );
                        })}
                    </>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
