import {CSSObject} from "@mui/material";

const block = (): CSSObject => ({
    padding: '50px 80px',
    textAlign: 'center',
})

const blockMain = (): CSSObject => ({
    '&:first-of-type': {
        borderRight: '1px solid #ccc'
    }
})

const titleTop = (): CSSObject => ({
    textAlign: 'center',
    margin: '50px auto'
})

const titlePage = (): CSSObject => ({
    marginBottom: 10,
    textTransform: 'uppercase'
})

const blockTitleMain = (): CSSObject => ({
    textTransform: 'uppercase',
    fontSize: '1.7em',
    fontWeight: 'bold'
})

const blockDescription = (): CSSObject => ({
    maxWidth: '215px',
    minHeight: '100px',
    width: '100%',
    margin: '30px auto'
})

const blockButton = (): CSSObject => ({
    margin: '10px auto 0px'
})

const icon = (): CSSObject => ({
    marginLeft: '20px'
})




const inputBlock = (): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '20px',
    '& > div': {
        width: '100%',
    },
})


export const classes = {
    block,
    blockMain,
    titleTop,
    titlePage,
    blockTitleMain,
    blockDescription,
    blockButton,
    inputBlock,icon,

}