import {CSSObject, Theme} from "@mui/material";

const form = (): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
})

const headerPopup = (theme: Theme): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
})

const closeIconPopup = (theme: Theme): CSSObject => ({
    color: `${theme.palette.primary.contrastText}!important`
})

const flex = (): CSSObject => ({
    display: 'flex',
    flexDirection: 'row-reverse'
})


const formControl = (theme: Theme): CSSObject => ({
    marginTop: theme.spacing(2),
    minWidth: '120px',
})

const formControlLabel = (theme: Theme): CSSObject => ({
    marginTop: theme.spacing(1),
})

const responseData = (): CSSObject => ({
    border: '5px solid #2a3eb1',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
})

const responseDataUserHeader = (): CSSObject => ({
    textTransform: 'uppercase',
    fontSize: '1.2em',
    fontWeight: 600,
    marginTop: '20px',
    marginBottom: '20px'
})

const responseDataUserKey = (): CSSObject => ({
    fontSize: '1.2em',
    marginBottom: '5px'
})

const responseDataUserBtn = (): CSSObject => ({
    marginBottom: '5px'
})

const btnClose = (): CSSObject => ({
    zIndex: 99999
})

const paddingPopup = (): CSSObject => ({
    padding: '10px'
})

const paper = (): CSSObject => ({
    marginBottom: '15px',
    padding: '15px'
})

const headerBlockInput = (): CSSObject => ({
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '20px'
})

const icon = (): CSSObject => ({
    width: '20px!important',
    height: '20px!important',
    position: 'absolute',
    right: '-30px',
    top: '18px'
})


const inputPhoneNumber = (theme: Theme): CSSObject => ({
    width: '100%',
    background: 'transparent',
    color: `${theme.palette.primary.contrastText}!important`
})


export const classes = {
    icon, headerBlockInput, paper, paddingPopup, btnClose, inputPhoneNumber,
    form, headerPopup, closeIconPopup, flex,
    responseDataUserBtn, responseDataUserKey, responseDataUserHeader,
    formControl, formControlLabel, responseData
}