import {CSSObject, keyframes, Theme} from "@mui/material";

const fadeIn = keyframes`
    "0%": {
      opacity: 0
    },
    "100%": {
        opacity: 1
    }
`;

const pic = (): CSSObject => ({
    marginTop: '20px',
    width: '100%', 
    '& > img': {
        width: '100%',
    }
})

const emptyDiv = (): CSSObject => ({
    width: '100%',
    minHeight: '600px',
    height: '100%',
    fontSize: '1.2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const anim = (theme: Theme): CSSObject => ({
    animation: `${fadeIn} 500ms ${theme.transitions.easing.easeInOut}`,
})


export const classes = {
    pic, emptyDiv, anim
}