import React, { useContext } from 'react';
import { Autocomplete, Grid, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../store';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { UserPermission } from '../../../../config';
import { fetchGetBusinessUnits } from '../../../../store/business-units/business-units';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import { clearStatusDamagesFileCsv, fetchGetDamages } from '../../../../store/company/damages-export';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Spinner } from '../../../spinner';
import Box from '@mui/material/Box';
import { classes } from './style';
import { ICompanyDamagesExport } from '../../../../infrastructure/DTO/company/company.dto';
import { IArgsBusinessUnit } from '../../../../infrastructure/DTO/bussiness-unit/business-units.dto';
import errorParseMessage from "../../../../config/error-parse";

const DamagesExport: React.FC = () => {
    const { checkPermission, token } = useContext(CustomAuthContext);
    const dispatch: AppDispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const businessUnits = useSelector((state: AppState) => state.businessUnits);
    const damagesExport = useSelector((state: AppState) => state.exportDamages);
    const [stateBusinessUnits, setStateBusinessUnits] = React.useState<Array<string>>([]);
    const [selectedBusinessUnit, setBusinessUnit] = React.useState<string | null>('');

    const { companies, statusCompanies } = useSelector((state: AppState) => state.companies);
    const { company, status } = useSelector((state: AppState) => state.company);
    const [stateCompanies, setStateCompanies] = React.useState<Array<string>>([]);
    const [selectedCompany, setCompany] = React.useState<string | null>(null);

    const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
    const [dateTo, setDateTo] = React.useState<Date | null>(null);

    const [result, setResult] = React.useState<string>('');
    const [searchBtn, setSearchBtn] = React.useState<boolean>(true);

    const [data, setData] = React.useState<ICompanyDamagesExport>({
        company_id: '',
        business_unit_id: '',
        date_from: '',
        date_to: '',
    });

    React.useEffect(() => {
        return () => {
            dispatch(clearStatusDamagesFileCsv());
            setData({
                company_id: '',
                business_unit_id: '',
                date_from: '',
                date_to: '',
            });
            setResult('');
        };
    }, []);

    /**
     * Get All Companies
     */
    React.useEffect(() => {
        if (statusCompanies === StatusRedux.Succeeded) {
            let companiesNames: string[] = companies.companies.map((c) => c.name) as string[];
            setStateCompanies(companiesNames);
        }
    }, [statusCompanies]);

    /**
     * Get Company
     */
    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            setCompany(company.name as string);
        }
    }, [status]);

    /**
     * Get Business Units By Company ID
     */
    React.useEffect(() => {
        if (businessUnits.status === StatusRedux.Succeeded) {
            let businessUnitsNames: string[] | undefined = businessUnits?.businessUnits?.business_units?.map((c) => c.name);
            if (businessUnitsNames) {
                setStateBusinessUnits(businessUnitsNames);
            } else {
                setStateBusinessUnits([]);
            }
        }
    }, [businessUnits.status]);

    React.useEffect(() => {
        if (selectedCompany) {
            let company_id: string = '';

            if (checkPermission(UserPermission.ADMIN)) {
                company_id = selectedCompany;
            } else {
                company_id = companies?.companies.find((c) => c.name === selectedCompany)?.company_id as string;
            }

            const args: IArgsBusinessUnit = {
                company_id: company_id,
                limit: null,
                offset: null,
            };

            dispatch(
                fetchGetBusinessUnits({
                    token,
                    args,
                })
            );
        }
    }, [selectedCompany]);

    const handleChangeInputDateFrom = (event: Date | null) => {
        if (event) {
            setDateFrom(event);
            setDateTo(null);
            setData({
                ...data,
                date_from: moment(event).format('YYYY-MM-DD') as string,
            });
        } else {
            setDateTo(null);
            setData({
                ...data,
                date_from: '',
            });
        }
    };

    const handleChangeInputDateTo = (event: Date | null) => {
        if (event) {
            setDateTo(event);
            setData({
                ...data,
                date_to: moment(event).format('YYYY-MM-DD') as string,
            });
        } else {
            setDateTo(event);
            setData({
                ...data,
                date_to: '',
            });
        }
    };

    const handleReport = () => {
        const args: ICompanyDamagesExport = data;
        dispatch(fetchGetDamages({ token, args }));
    };

    React.useEffect(() => {
        if (damagesExport.status === StatusRedux.Succeeded) {
            enqueueSnackbar('Your file is ready!', {
                variant: 'success',
            });

            setResult(damagesExport.csv);
        }

        if (damagesExport.status === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(damagesExport.error), {
                variant: 'error',
            });

            dispatch(clearStatusDamagesFileCsv());
        }
    }, [damagesExport.status]);

    const downloadFile = () => {
        let blob = new Blob([result], { type: 'text/csv;charset=utf-8;' });
        let url = URL.createObjectURL(blob);
        let pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', 'report.csv');
        setResult('');
        dispatch(clearStatusDamagesFileCsv());
        pom.click();
    };

    React.useEffect(() => {
        if (data.company_id.length === 0 || data.date_from.length === 0 || data.date_to.length === 0) {
            setSearchBtn(true);
        } else {
            setSearchBtn(false);
        }
    }, [data]);

    return (
        <Paper elevation={2} sx={classes.tableRoot}>
            <Grid container spacing={1} direction='row' alignItems='center' justifyContent='space-between'>
                <Grid item xs={12}>
                    <Grid container spacing={1} direction='row' alignItems='center' justifyContent='space-between'>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Autocomplete
                                value={selectedCompany}
                                disablePortal
                                autoHighlight
                                fullWidth
                                onChange={(event: any, company_name: string | null) => {
                                    if (company_name) {
                                        let company_id: string = companies?.companies.find((c) => c.name === company_name)?.company_id as string;
                                        setData({
                                            ...data,
                                            company_id: company_id,
                                            business_unit_id: '',
                                        });
                                        setCompany(company_name);
                                        setBusinessUnit(null);
                                    } else {
                                        setCompany(company_name);
                                        setBusinessUnit(null);
                                        setData({
                                            ...data,
                                            company_id: '',
                                            business_unit_id: '',
                                        });
                                    }
                                }}
                                size='small'
                                id='companies'
                                options={stateCompanies}
                                getOptionLabel={(option: string) => option}
                                isOptionEqualToValue={(option: string, value: string) => option === value || value === ''}
                                renderInput={(params) => <TextField {...params} label='Company Name' variant='outlined' />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Autocomplete
                                disabled={selectedCompany === null}
                                value={selectedBusinessUnit}
                                disablePortal
                                autoHighlight
                                fullWidth
                                onChange={(event: any, business_unit_name: string | null) => {
                                    if (business_unit_name) {
                                        let business_unit_id: string = businessUnits.businessUnits?.business_units.find(
                                            (b) => b.name === business_unit_name
                                        )?.business_unit_id as string;
                                        setData({
                                            ...data,
                                            business_unit_id: business_unit_id,
                                        });
                                        setBusinessUnit(business_unit_name);
                                    } else {
                                        setBusinessUnit('');
                                        setData({
                                            ...data,
                                            business_unit_id: '',
                                        });
                                    }
                                }}
                                size='small'
                                id='bu'
                                options={stateBusinessUnits}
                                getOptionLabel={(option: string) => option}
                                isOptionEqualToValue={(option: string, value: string) => option === value || value === ''}
                                renderInput={(params) => <TextField {...params} label='Business Unit' variant='outlined' />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <DatePicker
                                label='Date From'
                                mask='__/__/____'
                                value={dateFrom}
                                inputFormat='dd/MM/yyyy'
                                onChange={handleChangeInputDateFrom}
                                renderInput={(params: any) => <TextField fullWidth={true} size='small' {...params} />}
                            />
                        </Grid>
                        <Grid item sm={12} md={2} lg={2} xl={2}>
                            <DatePicker
                                disabled={dateFrom === null}
                                label='Date To'
                                maxDate={moment(dateFrom).add(59, 'days').toDate()}
                                mask='__/__/____'
                                value={dateTo}
                                inputFormat='dd/MM/yyyy'
                                onChange={handleChangeInputDateTo}
                                renderInput={(params: any) => <TextField fullWidth={true} size='small' {...params} />}
                            />
                        </Grid>
                        <Grid item>
                            <Button disabled={searchBtn} fullWidth onClick={handleReport} color='secondary' variant='contained'>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction='row' alignItems='center' justifyContent='center'>
                        {damagesExport.status === StatusRedux.Loading && <Spinner style={{ height: 'calc(100vh - 295px)' }} />}
                        {result.length > 0 && (
                            <Box sx={classes.downloadBlock}>
                                <Button size='large' onClick={downloadFile} color='secondary' variant='outlined' endIcon={<FileDownloadIcon />}>
                                    Click to download file
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DamagesExport;
