import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CsvDownloadButton from 'react-json-to-csv';
import PublishIcon from '@mui/icons-material/Publish';
import { useTheme } from '@mui/material/styles';
import { showPopup } from '../../../../../store/events/popup';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../store';
import { IUserManagementProvider, UserManagementContext } from '../../../../../context/UserManagementProvider';
import { useSnackbar } from 'notistack';

interface IExportImportButtons<T, K> {
    data: T;
    dataToCsv: K;
    file_name: string;
    updateState(obj: T): void;
}

const ExportImportButtons = <T, K>(props: IExportImportButtons<T, K>) => {
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const inputRef = React.useRef<any>(null);
    const { company } = useContext<IUserManagementProvider>(UserManagementContext);

    const exportDataJson = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(props.data))}`;
        const link = document.createElement('a');

        link.href = jsonString;
        link.download = `${props.file_name}.json`;
        link.click();
    };

    const importDataJson = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList: FileList | null = e.target.files;

        if (fileList !== null) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                let obj = JSON.parse(event.target.result) as Array<T | K>;

                dispatch(showPopup(true));
                props.updateState(obj as T);
            };
            if (fileList.length !== 0) {
                reader.readAsText(fileList[0]);
            }
        }
    };

    return (
        <Grid container spacing={1} justifyContent='flex-end'>
            <Grid item>
                <Button size='small' variant='outlined' endIcon={<FileDownloadIcon />}>
                    <CsvDownloadButton
                        style={{
                            border: '0px',
                            padding: '3.5px',
                            background: 'transparent',
                            cursor: 'pointer',
                            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                            fontWeight: 500,
                            color: theme.palette.primary.main,
                            fontSize: '0.8125rem',
                            letterSpacing: '0.02857em',
                            textTransform: 'uppercase',
                        }}
                        filename={`${company?.name}__${props.file_name}.csv`}
                        data={props.dataToCsv as object[]}
                    >
                        Export CSV
                    </CsvDownloadButton>
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={exportDataJson} size='small' variant='outlined' endIcon={<FileDownloadIcon />}>
                    Export JSON
                </Button>
            </Grid>
            <Grid item>
                <Button component='label' size='small' variant='outlined' endIcon={<PublishIcon />}>
                    Import JSON
                    <input onChange={importDataJson} ref={inputRef} hidden accept='application/json' multiple type='file' />
                </Button>
            </Grid>
        </Grid>
    );
};

export default ExportImportButtons;
