import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import CompanyServices from '../../services/companies/company';
import { IFetchArgs } from '../IFetch';
import { ICompanyDamagesExport } from '../../infrastructure/DTO/company/company.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetDamages = createAsyncThunk<string, IFetchArgs<ICompanyDamagesExport>>(
    'statistics/damages',
    async (params: IFetchArgs<ICompanyDamagesExport>) => {
        return await CompanyServices.exportDamages(params);
    }
);

export const getDamagesFileSlice = createSlice({
    name: 'statistics',
    initialState: {
        csv: '' as string,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        clearStatusDamagesFileCsv: (state) => {
            state.csv = '';
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetDamages.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetDamages.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.csv = action.payload;
        });
        builder.addCase(fetchGetDamages.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});
export const { clearStatusDamagesFileCsv } = getDamagesFileSlice.actions;

export default getDamagesFileSlice;
