import React, { ChangeEvent, useContext } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { FormControl, FormControlLabel, InputLabel, List, ListItem, ListItemIcon, ListItemText, Select, Switch, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../../store';
import {
    clearStoreInspectionFlow,
    clearStoreUpdateStoreInspectionFlow,
    fetchGetInspectionFlow,
    fetchUpdateInspectionFlow,
} from '../../../../../store/effective-settings/inspection-flow';
import { classes } from './style';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { clearStoreStatusUpdatedEffectiveSettings } from '../../../../../store/effective-settings/effective-settings';
import FormGroup from '@mui/material/FormGroup';
import {
    IArgsInspectionFlow,
    IInspectionFlow,
    IInspectionFlowImages,
    IInspectionFlowSection,
} from '../../../../../infrastructure/DTO/Inspection-flow/Inspection-flow.dto';
import { IUserManagementProvider, UserManagementContext } from '../../../../../context/UserManagementProvider';
import { showPopup } from '../../../../../store/events/popup';
import { InspectionFlowValidation } from '../../../../../enums/InspectionFlowValidation';
import MenuItem from '@mui/material/MenuItem';
import errorParseMessage from '../../../../../config/error-parse';

interface IFlowInspectionComponent {
    handleNext?: Function;
    step?: number;
}

const FlowInspection: React.FC<IFlowInspectionComponent> = (props: IFlowInspectionComponent) => {
    const { token } = useContext(CustomAuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const { profile, company } = useContext<IUserManagementProvider>(UserManagementContext);
    const flow = useSelector((state: AppState) => state.inspectionFlow);
    const [inspectionFlow, setInspectionFlow] = React.useState<Array<IInspectionFlowSection>>([]);

    /**
     * Get Inspection Flow by Company ID
     */
    React.useEffect(() => {
        const args: IArgsInspectionFlow = {
            company_id: company?.company_id,
            profile_id: profile?.profile_id,
        };
        dispatch(fetchGetInspectionFlow({ token, args }));
        return () => {
            dispatch(clearStoreStatusUpdatedEffectiveSettings());
            dispatch(clearStoreInspectionFlow());
            setInspectionFlow([]);
        };
    }, []);

    const _inspectionFlow = (data: IInspectionFlow): Array<IInspectionFlowSection> => {
        return data.inspection_flow.inspection_flow_sections?.map((item) => {
            return {
                ...item,
                inspection_flow_images: item.inspection_flow_images.slice().sort((a, b) => a.order_position - b.order_position),
            };
        });
    };

    /**
     * Set Inspection Flow to UseState
     */
    React.useEffect(() => {
        if (flow.status === StatusRedux.Succeeded) {
            let res = _inspectionFlow(flow.inspectionFlow);
            setInspectionFlow(res);
        }
        if (flow.status === StatusRedux.Failed) {
            setInspectionFlow([]);
        }
    }, [flow.status]);

    /**
     * Set Inspection Flow to UseState From File
     */
    React.useEffect(() => {
        if (flow.statusImport === StatusRedux.Succeeded) {
            let res = _inspectionFlow(flow.inspectionFlow);
            setInspectionFlow(res);
        }
    }, [flow.statusImport]);

    const handleChangeValidation = (event: any) => {
        const { name, value } = event.target;
        const newArr: IInspectionFlowSection[] = inspectionFlow;

        let updatedArr = newArr.map((item) => {
            if (item.section_id === name) {
                item.validation = value;
                return item;
            }
            return item;
        });

        setInspectionFlow(updatedArr);
    };

    /**
     * Switch is_active
     * @param event
     */
    const handleChangeSwitchMain = (event: any) => {
        const { name, checked } = event.target;
        const newArr: IInspectionFlowSection[] = inspectionFlow;

        let updatedArr = newArr.map((item) => {
            if (item.section_id === name) {
                item.is_active = !item.is_active;
                let res = item.inspection_flow_images.map((flow) => {
                    return {
                        ...flow,
                        is_active: checked,
                    };
                });
                return {
                    ...item,
                    inspection_flow_images: res,
                };
            }
            return item;
        });

        setInspectionFlow(updatedArr);
    };

    /**
     * Switch is_active
     * Switch is_optional
     * @param event
     */
    const handleChangeSwitchSub = (event: any) => {
        const { name, checked, value } = event.target;
        const newArr: IInspectionFlowSection[] = inspectionFlow;

        let updatedArr = newArr.map((item) => {
            let res = item.inspection_flow_images.map((flow) => {
                if (flow.part_id !== null) {
                    if (flow.part_id === name) {
                        if (value === 'is_active') {
                            return {
                                ...flow,
                                is_active: checked as boolean,
                            };
                        } else {
                            return {
                                ...flow,
                                is_optional: checked as boolean,
                            };
                        }
                    } else {
                        return flow;
                    }
                }
                if (flow.camera_view !== null) {
                    if (flow.camera_view?.description === name) {
                        if (value === 'is_active') {
                            return {
                                ...flow,
                                is_active: checked as boolean,
                            };
                        } else {
                            return {
                                ...flow,
                                is_optional: checked as boolean,
                            };
                        }
                    } else {
                        return flow;
                    }
                }
            });
            return {
                ...item,
                inspection_flow_images: res,
            };
        });

        setInspectionFlow(updatedArr as Array<IInspectionFlowSection>);
    };

    /**
     * Drag end Drop
     * @type DropResult
     * @param result
     */
    const handleDragEnd = (result: DropResult) => {
        const newArr: IInspectionFlowSection[] = inspectionFlow;
        let updatedArr: IInspectionFlowSection[] = [];

        if (result.destination) {
            if (result.destination.droppableId === result.source.droppableId) {
                let findArr: IInspectionFlowSection = newArr.find((f) => f.section_id === result.source.droppableId) as IInspectionFlowSection;
                const [removed] = findArr.inspection_flow_images.splice(result.source.index, 1);
                findArr.inspection_flow_images.splice(result.destination?.index, 0, removed);

                updatedArr = newArr.map((item) => {
                    if (item.section_id === result.source.droppableId) {
                        let res = item.inspection_flow_images.map((flow, index) => {
                            return {
                                ...flow,
                                order_position: index + 1,
                            };
                        });
                        return {
                            ...item,
                            inspection_flow_images: res,
                        };
                    }
                    return item;
                });
            } else {
                let findFrom: IInspectionFlowSection = newArr.find((f) => f.section_id === result.source.droppableId) as IInspectionFlowSection;
                if (findFrom.inspection_flow_images.length === 1) return;
                const [removed] = findFrom.inspection_flow_images.splice(result.source.index, 1);

                let findTo: IInspectionFlowSection = newArr.find((f) => f.section_id === result.destination?.droppableId) as IInspectionFlowSection;
                findTo.inspection_flow_images.splice(result.destination?.index, 0, removed);

                updatedArr = newArr.map((item) => {
                    if (item.section_id === result.source.droppableId) {
                        let res = item.inspection_flow_images.map((flow, index) => {
                            return {
                                ...flow,
                                order_position: index + 1,
                            };
                        });
                        return {
                            ...item,
                            inspection_flow_images: res as Array<IInspectionFlowImages>,
                        };
                    }
                    if (item.section_id === result.destination?.droppableId) {
                        let res = item.inspection_flow_images.map((flow, index) => {
                            return {
                                ...flow,
                                order_position: index + 1,
                            };
                        });
                        return {
                            ...item,
                            inspection_flow_images: res as Array<IInspectionFlowImages>,
                        };
                    }
                    return item;
                });
            }

            setInspectionFlow(updatedArr);
        }
    };

    /**
     * Send data Update Inspection Flow
     */
    const handleSend = (event: any) => {
        event.preventDefault();

        const args: IArgsInspectionFlow = {
            company_id: company?.company_id,
            profile_id: profile?.profile_id,
        };

        const body: Partial<IInspectionFlow> = {
            inspection_flow: {
                inspection_flow_sections: inspectionFlow,
            },
        };

        if (!_validateAtLeastSides()) {
            dispatch(showPopup(false));
            dispatch(fetchUpdateInspectionFlow({ token, body, args }));
            if (props.step === 2) {
                props.handleNext !== undefined && props.handleNext();
            }
        } else {
            enqueueSnackbar('An inspection must contain at least 4 active and non-optional sides!', {
                variant: 'error',
            });
        }
    };

    /**
     * Get Status Update Inspection Flow
     */
    React.useEffect(() => {
        if (flow.statusUpdate === StatusRedux.Succeeded) {
            enqueueSnackbar('Saved Successfully', {
                variant: 'success',
            });

            dispatch(clearStoreStatusUpdatedEffectiveSettings());
            dispatch(clearStoreUpdateStoreInspectionFlow());
        }
        if (flow.statusUpdate === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(flow.errorUpdate), {
                variant: 'error',
            });

            dispatch(clearStoreStatusUpdatedEffectiveSettings());
            dispatch(clearStoreUpdateStoreInspectionFlow());
        }
    }, [flow.statusUpdate]);

    const _validateAtLeastSides = (): boolean => {
        let count: number = 0;

        inspectionFlow.forEach((flow) => {
            flow.inspection_flow_images.forEach((image_flow) => {
                if (image_flow.detection_status_mode.description === 'Auto' && image_flow.is_active && !image_flow.is_optional) {
                    count += 1;
                }
            });
        });

        return count < 4;
    };

    const _ucFirst = (value: string): string => {
        return (value[0].toUpperCase() + value.toLowerCase().slice(1)).replace(/_/g, ' ');
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            {inspectionFlow.length === 0 && flow.status !== StatusRedux.Failed && (
                <Box
                    sx={{
                        width: '100%',
                        height: 'calc(100% - 150px)',
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress color='primary' />
                </Box>
            )}
            {flow.status === StatusRedux.Failed && (
                <Box
                    sx={{
                        width: '100%',
                        height: 'calc(100% - 150px)',
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {errorParseMessage(flow.error)}
                </Box>
            )}
            <form style={{ paddingRight: '20px' }} id='inspection-flow' onSubmit={handleSend}>
                {inspectionFlow.length !== 0 && (
                    <Droppable type='inspection-cat' droppableId='list' isDropDisabled={true}>
                        {(provided) => (
                            <Box sx={classes.sectionBoxMain} {...provided.droppableProps} ref={provided.innerRef}>
                                {inspectionFlow
                                    .sort((a, b) => a.order_position - b.order_position)
                                    .map((inspection, index) => (
                                        <Draggable
                                            isDragDisabled={true}
                                            key={inspection.section_id}
                                            draggableId={inspection.section_id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <Box key={index} sx={classes.sectionBox} ref={provided.innerRef} {...provided.draggableProps}>
                                                    <ListItem
                                                        sx={{ margin: '10px 0' }}
                                                        secondaryAction={
                                                            inspection.section_id !== 'EXTERIOR' && (
                                                                <IconButton edge='end' aria-label='is_active'>
                                                                    <Switch
                                                                        size='small'
                                                                        name={inspection.section_id}
                                                                        checked={inspection.is_active}
                                                                        onChange={handleChangeSwitchMain}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                </IconButton>
                                                            )
                                                        }
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant='h6' gutterBottom>
                                                                    {inspection.section_name.toUpperCase()}
                                                                </Typography>
                                                            }
                                                        />
                                                        <FormControl sx={{ minWidth: '130px', margin: '0 15px' }} size='small'>
                                                            <InputLabel id={`validation-section-${inspection.section_id}`}>Validation</InputLabel>
                                                            <Select
                                                                name={inspection.section_id}
                                                                labelId={`validation-section-${inspection.section_id}`}
                                                                id={`validation-section-${inspection.section_id}`}
                                                                value={inspection.validation.toUpperCase()}
                                                                label='Validation'
                                                                onChange={handleChangeValidation}
                                                            >
                                                                <MenuItem value={InspectionFlowValidation.NONE}>
                                                                    {_ucFirst(InspectionFlowValidation.NONE)}
                                                                </MenuItem>
                                                                <MenuItem value={InspectionFlowValidation.ALL}>
                                                                    {_ucFirst(InspectionFlowValidation.ALL)}
                                                                </MenuItem>
                                                                <MenuItem value={InspectionFlowValidation.ALL_BUT_ONE}>
                                                                    {_ucFirst(InspectionFlowValidation.ALL_BUT_ONE)}
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </ListItem>
                                                    <Divider />
                                                    <Collapse sx={classes.collapse} in={true} timeout='auto' unmountOnExit>
                                                        <Droppable type='inspection-child' droppableId={inspection.section_id}>
                                                            {(provided) => (
                                                                <List
                                                                    sx={
                                                                        inspection.inspection_flow_images.length === 0
                                                                            ? { minHeight: '50px' }
                                                                            : { minHeight: 'auto' }
                                                                    }
                                                                    component='div'
                                                                    disablePadding
                                                                    className='characters'
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    {inspection.inspection_flow_images.length !== 0 &&
                                                                        inspection.inspection_flow_images.map((side, i) => {
                                                                            if (
                                                                                (side.part_id !== null &&
                                                                                    side.detection_status_mode.code === 'MANUAL') ||
                                                                                side.detection_status_mode.code === 'MILAGE'
                                                                            ) {
                                                                                return (
                                                                                    <ItemList
                                                                                        index={i}
                                                                                        disabled={!inspection.is_active}
                                                                                        handleChange={handleChangeSwitchSub}
                                                                                        id={side.part_id}
                                                                                        inspectionFlowImagesLength={
                                                                                            inspection.inspection_flow_images.length
                                                                                        }
                                                                                        is_active={side.is_active}
                                                                                        is_optional={side.is_optional}
                                                                                        key={side.part_id}
                                                                                        primaryName={side.part_name}
                                                                                        secondaryName={`Part`}
                                                                                    />
                                                                                );
                                                                            }
                                                                            if (
                                                                                side.camera_view !== null &&
                                                                                side.detection_status_mode.code === 'AUTOMATED'
                                                                            ) {
                                                                                return (
                                                                                    <ItemList
                                                                                        index={i}
                                                                                        disabled={!inspection.is_active}
                                                                                        handleChange={handleChangeSwitchSub}
                                                                                        id={side.camera_view?.description ?? null}
                                                                                        inspectionFlowImagesLength={
                                                                                            inspection.inspection_flow_images.length
                                                                                        }
                                                                                        is_active={side.is_active}
                                                                                        is_optional={side.is_optional}
                                                                                        key={side.camera_view?.description as string}
                                                                                        primaryName={side?.camera_view?.description ?? null}
                                                                                        secondaryName={`Side`}
                                                                                    />
                                                                                );
                                                                            }
                                                                        })}
                                                                    {provided.placeholder}
                                                                </List>
                                                            )}
                                                        </Droppable>
                                                    </Collapse>
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                )}
            </form>
        </DragDropContext>
    );
};

interface IItemList {
    id: string | null;
    key: any;
    index: number;
    primaryName: string | null;
    disabled: boolean;
    secondaryName: string;
    inspectionFlowImagesLength: number;
    is_active: boolean;
    is_optional: boolean;
    handleChange(e: ChangeEvent<HTMLInputElement>): void;
}

/***
 * @interface IItemList
 * @param props
 * @constructor
 */
const ItemList: React.FC<IItemList> = (props: IItemList) => {
    return (
        props && (
            <Box key={props.primaryName as string}>
                <Draggable key={props.id as string} draggableId={props.id as string} index={props.index}>
                    {(provided) => (
                        <>
                            <ListItem
                                sx={{
                                    pl: 1,
                                    bgcolor: 'background.paper',
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                secondaryAction={
                                    <FormGroup aria-label='position' row>
                                        <FormControlLabel
                                            sx={classes.switchController}
                                            value='is_optional'
                                            control={
                                                <Switch
                                                    disabled={!props.is_active}
                                                    size='small'
                                                    name={props.id as string}
                                                    checked={props.is_optional}
                                                    onChange={props.handleChange}
                                                    inputProps={{
                                                        'aria-label': 'controlled',
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography variant='caption' display='block' gutterBottom>
                                                    optional
                                                </Typography>
                                            }
                                            labelPlacement='top'
                                        />
                                        <FormControlLabel
                                            sx={classes.switchController}
                                            value='is_active'
                                            control={
                                                <Switch
                                                    disabled={props.disabled}
                                                    size='small'
                                                    name={props.id as string}
                                                    checked={props.is_active}
                                                    onChange={props.handleChange}
                                                    inputProps={{
                                                        'aria-label': 'controlled',
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography variant='caption' display='block' gutterBottom>
                                                    active
                                                </Typography>
                                            }
                                            labelPlacement='top'
                                        />
                                    </FormGroup>
                                }
                            >
                                <ListItemIcon sx={classes.icon}>
                                    <DragIndicatorIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography sx={classes.sectionTitle} variant='subtitle2' display='block' gutterBottom>
                                            {props.primaryName as string}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography sx={classes.sectionTitle} variant='body2' display='block' gutterBottom>
                                            {props.secondaryName}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <Divider
                                sx={props.inspectionFlowImagesLength === props.key + 1 ? { display: 'none' } : { display: 'block' }}
                                variant='inset'
                                component='li'
                            />
                        </>
                    )}
                </Draggable>
            </Box>
        )
    );
};

export default FlowInspection;
