import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import './forgot-password.css';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import useCheckInputEmail from '../../hooks/validation/use-input-email';
import TextField from '@mui/material/TextField';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} children={props.children} />;
});

interface PropsType {
    handleClickOpen: () => void;
    handleClose: () => void;
    open: boolean;
}

const ForgotPassword: React.FC<PropsType> = (props: PropsType) => {
    const [resetEmail, setResetEmail] = React.useState<string>('');
    const [send, setSend] = React.useState<boolean>(false);
    const { inputEmail, checkValueEmail } = useCheckInputEmail();

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setResetEmail(event.currentTarget.value);
    };

    const handleSendMail = () => {
        setSend(true);

        setTimeout(() => {
            setSend(false);
            setResetEmail('');
            props.handleClose();
        }, 1500);
    };

    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}
                aria-labelledby='alert-dialog-slide-title'
                aria-describedby='alert-dialog-slide-description'
            >
                <IconButton aria-label='close' className='close-icon' onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>
                <div className='p-mode'>
                    <DialogContent>
                        <div className='modal-reset-pass-title' id='alert-dialog-slide-title'>
                            Reset password
                        </div>
                        <div className='modal-reset-pass-desc'>
                            <DialogContentText id='alert-dialog-slide-description'>
                                Enter the email address connected to your account and we will send you an instruction email to reset your password
                            </DialogContentText>
                            <div className='modal-reset-pass-btn'>
                                <form id='reset-password' autoComplete='off' onSubmit={handleSendMail}>
                                    <TextField
                                        error={inputEmail.error}
                                        helperText={inputEmail.error ? inputEmail.errorMsg : null}
                                        fullWidth
                                        name='email'
                                        required={true}
                                        id='outlined-basic'
                                        type='email'
                                        label='E-mail'
                                        variant='outlined'
                                        value={resetEmail ?? ''}
                                        onChange={handleChangeEmail}
                                        onKeyUp={checkValueEmail}
                                    />
                                </form>
                                <Button type='submit' className='modal-reset-pass-btn' size='large' fullWidth variant='contained' color='secondary'>
                                    send
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </div>
                {send && (
                    <div className='box-after-send'>
                        <IconButton aria-label='close' className='close-icon' onClick={props.handleClose}>
                            <CloseIcon />
                        </IconButton>
                        <div className='title'>Check your email</div>
                        <div className='desc'>We have sent password recovery instructions to your address</div>
                        <div className='pic'>
                            <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M24.207 51.0519L32.8197 44.4526M95.7927 51.0519L87.1801 44.4526'
                                    stroke='#000133'
                                    strokeWidth='4'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M33.1553 56.2597L33.1554 28.6811C33.1554 26.2159 35.1642 24.207 37.6295 24.207H82.3705C84.8402 24.207 86.8446 26.2159 86.8446 28.6811L86.8446 56.2597'
                                    stroke='#00E681'
                                    strokeWidth='4'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <line
                                    x1='43.5171'
                                    y1='38.7278'
                                    x2='75.743'
                                    y2='38.7278'
                                    stroke='#00E681'
                                    strokeWidth='4'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <line
                                    x1='43.5171'
                                    y1='50.48'
                                    x2='63.8265'
                                    y2='50.48'
                                    stroke='#00E681'
                                    strokeWidth='4'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M66.0711 69.967L95.7927 51.0515V91.3185C95.7927 93.7882 93.7883 95.7926 91.3186 95.7926H28.6811C26.2114 95.7926 24.207 93.7882 24.207 91.3185V51.0515L53.9287 69.967'
                                    stroke='#000133'
                                    strokeWidth='4'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M91.3186 95.7929H28.6811C26.1712 95.7929 24.207 93.8287 24.207 91.3188C24.207 90.6074 24.5471 89.9363 25.1197 89.5157L58.6755 67.1452C59.0693 66.8544 59.5346 66.7112 59.9999 66.7112C60.4652 66.7112 60.9305 66.8544 61.3242 67.1452L94.88 89.5157C95.4527 89.9363 95.7927 90.6074 95.7927 91.3188C95.7927 93.8287 93.8286 95.7929 91.3186 95.7929Z'
                                    stroke='#000133'
                                    strokeWidth='4'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                        </div>
                        <div className='bottom-title'>
                            Did not receive your email? Check your spam folder or <span>try another email address.</span>
                        </div>
                    </div>
                )}
            </Dialog>
        </div>
    );
};

export default ForgotPassword;
