import {CSSObject, Theme} from "@mui/material";

const imgBottomBlock = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
})

const imgBottomBlockText = (): CSSObject => ({
    '& > div': {
        fontWeight: 600,
        fontSize: '0.8em!important',
        color: 'red!important',
        margin: '5px 0px!important'
    }
})

const dialogContent = (): CSSObject => ({
    padding: '0!important'
})

const buttonsGroup = (): CSSObject => ({
    textAlign: 'center',
    marginTop: '20px!important'
})

const headerPopup = (theme: Theme): CSSObject => ({
    background: theme.palette.primary.main, 
    color: theme.palette.primary.contrastText
})

const jsonViewer = (): CSSObject => ({
    padding: '15px',
    overflow: 'hidden'
})

const pd = (): CSSObject => ({
    padding: '15px',
    height: 'calc(100vh - 150px)',
    position: 'relative',
    overflow: 'hidden'
})

const listDetectedDamages = (): CSSObject => ({
    padding: '15px',
    height: 'calc(100vh - 150px)',
    position: 'relative',
    overflowY: 'scroll'
})

const stage = (): CSSObject => ({
    width: '100%',
    height: 'calc(100vh - 250px)',
    overflow: 'hidden',
    cursor: 'all-scroll'
})

const stageImg = (): CSSObject => ({
    width: '100%',
    height: 'auto',
})

const iconsZoomPreview = (): CSSObject => ({
    zIndex: 9999,
    marginRight: '20px'
})

const panelButtons = (): CSSObject => ({
    display: 'flex',
    flexDirection: 'row-reverse'
})

const iconBtn = (): CSSObject => ({
    paddingLeft: '10px'
})

const iconText = (): CSSObject => ({
    fontSize: '0.5em',
    marginRight: '5px'
})

const box = (): CSSObject => ({
    width: '300px'
})

export const classes = {
    imgBottomBlock,
    listDetectedDamages,
    stage,
    stageImg,
    iconsZoomPreview,
    panelButtons,
    iconText,
    iconBtn,
    box,
    imgBottomBlockText,
    dialogContent,buttonsGroup,headerPopup,jsonViewer,pd
}