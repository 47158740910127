import React, { useState } from 'react';
import { ClearStore, fetchUserCheckEmail } from '../../store/users/check-email';
import { StatusRedux } from '../../enums/StatusRedux';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, AppDispatch } from '../../store';

const useCheckInputEmail = (user_id?: string | undefined) => {
    const dispatch: AppDispatch = useDispatch();
    const [email, setEmail] = useState<string>('');
    const { usersList, userListStatus } = useSelector((state: AppState) => state.checkUserEmail);
    const [inputEmail, setInputEmail] = useState({
        errorMsg: '',
        error: false,
    });

    const checkValueEmail = (event: any) => {
        const { _, value } = event.target;

        if (value !== null && value !== undefined) {
            let emailMatch = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

            if (!value?.match(emailMatch)) {
                validate(true);
            } else {
                validate(false);
                setEmail(value);
                dispatch(
                    fetchUserCheckEmail({
                        args: {
                            email: value,
                        },
                    })
                );
            }
        } else {
            validate(false);
            setEmail(value);
            dispatch(
                fetchUserCheckEmail({
                    args: {
                        email: value,
                    },
                })
            );
        }
    };

    const validate = (check: boolean) => {
        setInputEmail({
            errorMsg: check ? 'Email not valid' : '',
            error: check,
        });
    };

    React.useEffect(() => {
        if (userListStatus === StatusRedux.Succeeded) {
            if (usersList.users !== undefined) {
                let res = usersList.users?.filter((user) => user.email?.toLowerCase() === email.toLowerCase() && user.user_id !== user_id);
                if (res?.length !== 0) {
                    setInputEmail({
                        errorMsg: `Email ${email} is already!`,
                        error: true,
                    });
                } else {
                    setInputEmail({
                        errorMsg: '',
                        error: false,
                    });
                }
            }
        }
    }, [userListStatus, email]);

    const handleFocusInputEmail = () => () => {
        dispatch(ClearStore());
    };

    return { inputEmail, handleFocusInputEmail, checkValueEmail };
};

export default useCheckInputEmail;
