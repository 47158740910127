import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import { classes } from './style';
import Box from '@mui/material/Box';
import { UserPermission } from '../../../config';
import { CustomAuthContext } from '../../../context/AuthProvider';
import { clearStore } from '../../../store/users/users';
import { AppDispatch, AppState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { StatusRedux } from '../../../enums/StatusRedux';
import CreateUser from './add-user/create-user';
import Paper from '@mui/material/Paper';
import { Spinner } from '../../spinner';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import EditUser from './edit-user/edit-user';
import { Error } from '../../error';
import TablePagination from '@mui/material/TablePagination';
import { IUser } from '../../../infrastructure/DTO/users/user.dto';
import { IUserManagementProvider, UserManagementContext } from '../../../context/UserManagementProvider';
import errorParseMessage from "../../../config/error-parse";

const User: React.FC = () => {
    const { checkPermission } = useContext(CustomAuthContext);
    const { page, limit, company, order, orderBy, createUserPopup, changePage, changeRowsPerPage, handleCreateUserPopup, handleSortUser } =
        useContext<IUserManagementProvider>(UserManagementContext);
    const dispatch: AppDispatch = useDispatch();
    const [countUsers, setCountUsers] = React.useState<number>(0);
    const [openEditPopup, setOpenEditPopup] = React.useState<boolean>(false);
    const [dataById, setDataById] = React.useState<IUser | null>(null);
    const { usersData, status, error } = useSelector((state: AppState) => state.users);

    /**
     * @param status
     * set count users
     */
    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            setCountUsers(usersData.Count);
        }
    }, [status]);

    /**
     * @type string
     * @param id
     * Open edit popup
     * set data to update
     */
    const handleSetIdPopupEdit = (id?: string) => () => {
        const data: IUser = usersData.UsersList.find((i) => i.user_id === id) as IUser;
        setDataById(data);
        setOpenEditPopup(true);
    };

    /**
     * Close popup
     */
    const handleClose = () => {
        setOpenEditPopup(false);
        dispatch(clearStore());
    };

    return (
        <>
            <CreateUser
                company={company}
                open={createUserPopup}
                handleClose={handleCreateUserPopup}
                countUsers={countUsers}
                setCountUsers={setCountUsers}
            />

            <Grid container spacing={2} direction='row' justifyContent='center' alignItems='center'>
                <Grid item xs={12}>
                    <Paper elevation={2} sx={classes.main}>
                        {(() => {
                            switch (status) {
                                case StatusRedux.Loading:
                                    return <Spinner style={{ height: 'calc(100vh - 235px)' }} />;
                                case StatusRedux.Succeeded:
                                    return (
                                        <TableContainer sx={classes.container}>
                                            <Table stickyHeader aria-label='sticky table'>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={classes.cellCnt} key={0} align='left'>
                                                            #
                                                        </TableCell>
                                                        <TableCell sx={classes.cellLong} key={1} align='left'>
                                                            <TableSortLabel
                                                                active={orderBy === 'client'}
                                                                direction={orderBy === 'client' ? order : 'desc'}
                                                                onClick={handleSortUser('client', order)}
                                                            >
                                                                Name
                                                                {orderBy === 'client' ? (
                                                                    <Box component='span' sx={classes.visuallyHidden}>
                                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                    </Box>
                                                                ) : null}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        {!checkPermission(UserPermission.ADMIN) && (
                                                            <TableCell sx={classes.cellLong} key={2} align='left'>
                                                                Company Name
                                                            </TableCell>
                                                        )}
                                                        <TableCell sx={classes.cellShort} key={3} align='left'>
                                                            Business Unit
                                                        </TableCell>
                                                        <TableCell sx={classes.cellLong} key={7} align='left'>
                                                            Email
                                                        </TableCell>
                                                        <TableCell sx={classes.cellLong} key={8} align='left'>
                                                            User ID
                                                        </TableCell>
                                                        <TableCell sx={classes.cellShort} key={9} align='left'>
                                                            <TableSortLabel
                                                                active={orderBy === 'active'}
                                                                direction={orderBy === 'active' ? order : 'desc'}
                                                                onClick={handleSortUser('active', order)}
                                                            >
                                                                Status
                                                                {orderBy === 'active' ? (
                                                                    <Box component='span' sx={classes.visuallyHidden}>
                                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                    </Box>
                                                                ) : null}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {usersData.UsersList !== undefined &&
                                                    usersData.UsersList.map((data: IUser, index) => (
                                                        <TableBody key={index}>
                                                            <TableRow
                                                                hover
                                                                role='checkbox'
                                                                tabIndex={-1}
                                                                onClick={handleSetIdPopupEdit(data.user_id)}
                                                            >
                                                                <TableCell sx={classes.cellCnt}>{limit * page + (index + 1)}</TableCell>
                                                                <TableCell sx={classes.cellLong}>{data?.name}</TableCell>
                                                                {!checkPermission(UserPermission.ADMIN) && (
                                                                    <TableCell sx={classes.cellLong}>{data?.company?.name}</TableCell>
                                                                )}
                                                                <TableCell sx={classes.cellShort}>{data.business_unit?.name}</TableCell>
                                                                <TableCell sx={classes.cellLong}>{data.email}</TableCell>
                                                                <TableCell sx={classes.cellLong}>{data.user_id}</TableCell>
                                                                <TableCell sx={classes.cellShort}>
                                                                    {data.is_active ? (
                                                                        <Chip label='Enabled' variant='outlined' color='primary' />
                                                                    ) : (
                                                                        <Chip
                                                                            label='Disabled'
                                                                            variant='outlined'
                                                                            style={{ color: 'red', borderColor: 'red' }}
                                                                        />
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    ))}
                                                <EditUser data={dataById} open={openEditPopup} handleClose={handleClose} />
                                            </Table>
                                        </TableContainer>
                                    );
                                case StatusRedux.Failed:
                                    return (
                                        <Box sx={classes.container}>
                                            <Error msg={errorParseMessage(error)} />
                                        </Box>
                                    );
                            }
                        })()}

                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component='div'
                            count={countUsers}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={changePage}
                            onRowsPerPageChange={changeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default User;
