import { useMemo, useRef, useState, useEffect, useCallback, MutableRefObject } from 'react';
import fscreen from 'fscreen';

export function useFullscreen() {
    const fullscreenRef = useRef(null);
    const [active, setActive] = useState<boolean>(false);

    useEffect(() => {
        const handleChange = () => {
            setActive(fscreen.fullscreenElement === fullscreenRef?.current);
        };
        fscreen.addEventListener('fullscreenchange', handleChange);
        return () => fscreen.removeEventListener('fullscreenchange', handleChange);
    }, []);

    const enterFullscreen = useCallback(async () => {
        if (fullscreenRef.current) {
            if (fscreen.fullscreenElement) {
                await fscreen.exitFullscreen();
            }
            return fscreen.requestFullscreen(fullscreenRef.current as unknown as Element);
        }
    }, []);

    const exitFullscreen = useCallback(async () => {
        if (fscreen.fullscreenElement === fullscreenRef?.current) {
            return fscreen.exitFullscreen();
        }
    }, []);

    return {
        fullscreenRef,
        fullscreenEnabled: fscreen.fullscreenEnabled,
        fullscreenActive: active,
        enterFullscreen,
        exitFullscreen,
    };
}
