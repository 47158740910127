import {CSSObject} from "@mui/material";

const container = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'center',
    height: '100vh!important',
    margin: '0 auto !important'
})


const paperBlock = (): CSSObject => ({
    padding: '30px!important',
    position: 'relative',
    background: '#fff',
    color: '#000'
})

const header = (): CSSObject => ({
    padding: '0px 20px',
    fontSize: '1.8em',
    fontWeight: 800,
    textAlign: 'center',
    marginTop: '70px',
    marginBottom: '30px'
})

const logoMain = (): CSSObject => ({
    position: 'relative',
    backgroundImage: "url(../../images/login-pic.png)",
    '&::before': {
        content: "''",
        position: 'absolute',
        top: '-40px',
        left: '85px',
        backgroundImage: 'url(../../images/login-pic.png)',
        width: '100%',
        height: '450px',
        backgroundSize: 'contain',
    }
})

const logoSvg = (): CSSObject => ({
    width: '250px',
    position: 'absolute',
    top: '-70px',
    '& > svg': {
        width: '100%'
    }
})

const form = (): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
})

const formInput = (): CSSObject => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& > div': {
        width: '100%',
    }
})

const icon = (): CSSObject => ({
    width: '20px!important',
    height: '20px!important',
    position: 'absolute',
    right: '-30px',
    top: '18px'
})

const input = (): CSSObject => ({
    '& > div': {
        borderRadius: '0 4px 4px 0'
    }
})


const buttonProgress = (): CSSObject => ({
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
})

export const classes = {
    container,logoMain,header,paperBlock, buttonProgress, input, icon,
    formInput,form,logoSvg
}