import React, { useContext } from 'react';
import ImageUploading from 'react-images-uploading';
import { Paper } from '@mui/material';
import { classes } from './style';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Spinner from '../../../spinner/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../store';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { useNavigate, useLocation } from 'react-router-dom';
import { clearStatusDamageLab, fetchDamageLabSingleImg } from '../../../../store/damage-lab/upload-img';
import { useSnackbar } from 'notistack';
import { setImage } from '../../../../store/damage-lab/image';
import Box from '@mui/material/Box';
import { showPopup } from '../../../../store/events/popup';
import { backButtonDisabled, onbeforeunload } from '../../../../utils/onbeforeunload';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { IImage } from '../../../../infrastructure/DTO/damage-lab/damage-lab.dto';
import errorParseMessage from "../../../../config/error-parse";

const DamageLabSingle: React.FC = () => {
    const { token } = useContext(CustomAuthContext);
    const maxNumber: number = 1;
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const { status, error } = useSelector((state: AppState) => state.damageLabSingleImg);
    const [images, setImagesView] = React.useState<Array<IImage>>([]);

    const handleImages = (imageList: any) => {
        let res: Array<IImage> = imageList.map((item: any, index: number) => {
            return {
                image_id: `f${(+new Date()).toString(18) + index}`,
                name: item.file.name,
                content_type: item.file.type,
                content: item.data_url.split(',')[1],
                context: {
                    view_type: null,
                    side: null,
                    tag: null,
                },
                resolution: {
                    width: 0,
                    height: 0,
                },
            };
        });

        setImagesView(images.concat(res));
        dispatch(
            fetchDamageLabSingleImg({
                token: token,
                body: res[0],
            })
        );
    };

    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            enqueueSnackbar('Submitted Successfully', {
                variant: 'success',
            });

            if (images !== undefined && images.length > 0) {
                const img = new window.Image();
                img.src = `data:image/jpeg;base64, ${images[0].content}`;

                img.onload = () => {
                    dispatch(
                        setImage({
                            image: img,
                            width: img.width,
                            height: img.height,
                        })
                    );

                    navigate('/home/damage-lab/main');
                    dispatch(clearStatusDamageLab());
                };
            }
        }
        if (status === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(error), {
                variant: 'error',
            });

            navigate('/home/damage-lab');
            dispatch(clearStatusDamageLab());
        }
    }, [status]);

    React.useEffect(() => {
        if (status === StatusRedux.Loading || status === StatusRedux.Succeeded) {
            onbeforeunload(true);
            backButtonDisabled(true);
            dispatch(showPopup(true));
        }
    }, [location, status]);

    return (
        <>
            <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h6' sx={classes.titlePage}>
                        SINGLE PICTURE
                    </Typography>
                </Grid>
            </Grid>
            <Paper elevation={2} sx={classes.paper}>
                {status === StatusRedux.Loading && (
                    <Box key={1} sx={classes.spinner}>
                        <Spinner />
                    </Box>
                )}
                <Box key={2} sx={classes.uploadBox}>
                    <ImageUploading multiple value={images} onChange={handleImages} maxNumber={maxNumber} dataURLKey='data_url'>
                        {({ onImageUpload, dragProps }) => (
                            <div className='uploadImageWrapper'>
                                {images.length === 0 && (
                                    <div className='upload' onClick={onImageUpload} {...dragProps}>
                                        <div className='icon'>
                                            <svg width='44' height='43' viewBox='0 0 44 43' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path d='M21.6694 40.4079L21.6694 21.7413' stroke='#000133' strokeWidth='2' />
                                                <path d='M30.0308 27.5746L21.6685 19.4068L13.3063 27.5746' stroke='#000133' strokeWidth='2' />
                                                <path
                                                    d='M34.3084 35.7409C38.2759 32.3205 40.7783 27.3161 40.7783 21.7409C40.7783 11.4316 32.222 3.07421 21.6672 3.07421C11.1124 3.07421 2.5561 11.4316 2.5561 21.7409C2.5561 27.3161 5.05848 32.3205 9.02605 35.7409'
                                                    stroke='#000133'
                                                    strokeWidth='2'
                                                />
                                            </svg>
                                        </div>
                                        <div className='upload-title'>Matching car damage with a single picture</div>
                                    </div>
                                )}
                                {images.length !== 0 &&
                                    images.map((pic: IImage) => (
                                        <Box key={3} sx={classes.imgUpload}>
                                            <img src={`data:image/jpeg;base64, ${pic.content}`} alt={pic.image_id} />
                                        </Box>
                                    ))}
                            </div>
                        )}
                    </ImageUploading>
                </Box>
            </Paper>
        </>
    );
};

export default DamageLabSingle;
