import React from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { classes } from '../../../../damagelab/damagelab-single/parts&anomalies/measurements/style';
import DamageLabLayoutContext, { IDamageLabLayoutContext } from '../../../../../../context/DamageLabLayoutContext';

const MeasurementPopupInfo: React.FC = () => {
    const { measurementCurrentData } = React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);

    return (
        <>
            <TableContainer sx={classes.table}>
                <Table size='small' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={classes.head}># ID</TableCell>
                            <TableCell sx={classes.head} align='center'>
                                Parameter
                            </TableCell>
                            <TableCell sx={classes.head} align='center'>
                                Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {measurementCurrentData.result?.map((data, index) => (
                            <TableRow hover sx={classes.tableRow} key={index}>
                                <TableCell component='th' scope='row'>
                                    {index + 1}
                                </TableCell>
                                <TableCell align='center'>
                                    {data.key} {data.tag}
                                </TableCell>
                                <TableCell align='center'>{data.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography sx={classes.tableTitle} variant='subtitle2' gutterBottom>
                Minimal Enclosing Box
            </Typography>

            <TableContainer sx={classes.table}>
                <Table size='small' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={classes.head}># ID</TableCell>
                            <TableCell sx={classes.head} align='center'>
                                Parameter
                            </TableCell>
                            <TableCell sx={classes.head} align='center'>
                                Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {measurementCurrentData.minimalEnclosingBox?.map((data, index) => (
                            <TableRow hover sx={classes.tableRow} key={index}>
                                <TableCell component='th' scope='row'>
                                    {index + 1}
                                </TableCell>
                                <TableCell align='center'>
                                    {data.key} {data.tag}
                                </TableCell>
                                <TableCell align='center'>{data.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography sx={classes.tableTitle} variant='subtitle2' gutterBottom>
                Minimal 3D Enclosing Box
            </Typography>

            <TableContainer sx={classes.table}>
                <Table size='small' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={classes.head}># ID</TableCell>
                            <TableCell sx={classes.head} align='center'>
                                Parameter
                            </TableCell>
                            <TableCell sx={classes.head} align='center'>
                                Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {measurementCurrentData.threeBoundingBox?.map((data, index) => (
                            <TableRow hover sx={classes.tableRow} key={index}>
                                <TableCell component='th' scope='row'>
                                    {index + 1}
                                </TableCell>
                                <TableCell align='center'>{data.key}</TableCell>
                                <TableCell align='center'>{data.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default MeasurementPopupInfo;
