import React from 'react';
import UserManagementProvider from '../../../context/UserManagementProvider';
import { UserManagement } from '../user-management';

const UserProvider: React.FC = () => {
    return (
        <UserManagementProvider>
            <UserManagement />
        </UserManagementProvider>
    );
};

export default UserProvider;
