import React, { useRef } from 'react';

function usePrevious<T>(value: any): T {
    const ref = useRef();

    React.useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current as unknown as T;
}

export default usePrevious;
