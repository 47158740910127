import React, { useContext } from 'react';
import { IArgsBusinessUnit, IBusinessUnit } from '../../infrastructure/DTO/bussiness-unit/business-units.dto';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../store';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { IBusinessUnitSelect } from '../interface/IBusinessUnitSelect';
import { clearStoreBusinessUnits, fetchGetBusinessUnits } from '../../store/business-units/business-units';
import { StatusRedux } from '../../enums/StatusRedux';
import { CustomAuthContext } from '../../context/AuthProvider';
import { useSnackbar } from 'notistack';
import { IArgsCompany, ICompany } from '../../infrastructure/DTO/company/company.dto';
import { UserPermission } from '../../config';
import { fetchGetCompanyById } from '../../store/company/company';

const BusinessUnitSelect: React.FC<IBusinessUnitSelect> = (props: IBusinessUnitSelect) => {
    const { enqueueSnackbar } = useSnackbar();
    const { token, checkPermission, parseToken } = useContext(CustomAuthContext);
    const dispatch: AppDispatch = useDispatch();
    const [businessUnit, setBusinessUnit] = React.useState<Array<IBusinessUnit>>([]);
    const businessUnits = useSelector((state: AppState) => state.businessUnits);
    const company = useSelector((state: AppState) => state.company);

    React.useEffect(() => {
        if (checkPermission(UserPermission.ADMIN)) {
            const args: IArgsCompany = {
                company_id: parseToken().company,
                user_id: parseToken().sub,
            };

            dispatch(fetchGetCompanyById({ token, args }));
        }
        return () => {
            dispatch(clearStoreBusinessUnits());
            setBusinessUnit([]);
        };
    }, []);

    React.useEffect(() => {
        if (checkPermission(UserPermission.ADMIN)) {
            if (company.status === StatusRedux.Succeeded) {
                _getBusinessUnits(company.company.company_id);
            }
        }
    }, [company.status]);

    /**
     * @param company
     * @type ICompany
     * @type null
     * Get Business Units by Company ID
     */
    React.useEffect(() => {
        if (props.company?.company_id) {
            dispatch(clearStoreBusinessUnits());
            setBusinessUnit([]);
            _getBusinessUnits(props.company?.company_id);
        }
    }, [props.company?.company_id]);

    /**
     *  Get status business unit
     */
    React.useEffect(() => {
        if (businessUnits.status === StatusRedux.Succeeded) {
            setBusinessUnit(businessUnits.businessUnits.business_units);
        }

        if (businessUnits.status === StatusRedux.Failed) {
            enqueueSnackbar(businessUnits.error.errors[0].message, {
                variant: 'error',
            });
        }
    }, [businessUnits.status]);

    React.useEffect(() => {
        if (props.clear) {
            props.setData(null);
        }
    }, [props.clear]);

    const _getBusinessUnits = (company_id: string | undefined) => {
        if (company_id) {
            const args: IArgsBusinessUnit = {
                company_id: company_id,
            };

            dispatch(
                fetchGetBusinessUnits({
                    token,
                    args,
                })
            );
        }
    };

    return (
        <Autocomplete
            size={props.size ?? 'medium'}
            autoHighlight
            disabled={props.disabled}
            value={businessUnit.length > 0 ? props.data : null}
            disablePortal
            fullWidth
            isOptionEqualToValue={(option: IBusinessUnit | null, value: IBusinessUnit | null) => option?.name === value?.name}
            onChange={(event: any, bu: IBusinessUnit | null) => {
                if (bu !== null) {
                    props.setData(bu);
                } else {
                    props.setData(null);
                }
            }}
            id='bu-create'
            options={businessUnit}
            getOptionLabel={(option: IBusinessUnit) => (option.name ? option.name : '')}
            renderInput={(params) => <TextField {...params} label='Business Unit' variant={props.variant ? props.variant : 'standard'} />}
        />
    );
};

export default BusinessUnitSelect;
