import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { classes } from './style';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store';
import Box from '@mui/material/Box';

const MetaData: React.FC = () => {
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);

    return (
        <TableContainer sx={classes.table}>
            {damageLabData.image_metadata !== null ? (
                <Table size='small' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={classes.head}># ID</TableCell>
                            <TableCell sx={classes.head} align='left'>
                                Key
                            </TableCell>
                            <TableCell sx={classes.head} align='left'>
                                Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {damageLabData.image_metadata.map((row, index: number) => (
                            <TableRow hover sx={classes.tableRow} key={index + 1}>
                                <TableCell component='th' scope='row'>
                                    {index + 1}
                                </TableCell>
                                <TableCell align='left'>{row.key}</TableCell>
                                <TableCell align='left'>{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Box sx={classes.emptyDiv}>Not Found</Box>
            )}
        </TableContainer>
    );
};

export default MetaData;
