import {CSSObject, keyframes, Theme} from "@mui/material";

const fadeIn = keyframes`
    "0%": {
      opacity: 0
    },
    "100%": {
        opacity: 1
    }
`;

const stage = (): CSSObject => ({
    width: '100%',
    minHeight: '650px',
    overflow: 'hidden',
    cursor: 'move',
})

const tabBody = (): CSSObject => ({
    marginTop: '25px'
})

const panelTab = (): CSSObject => ({
    width: '100%', 
    overflow: 'hidden'
})

const anim = (theme: Theme): CSSObject => ({
    animation: `${fadeIn} 500ms ${theme.transitions.easing.easeInOut}`,
})

const emptyMsg = (): CSSObject => ({
    width: '100%',
    height: 'calc(100vh - 300px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2em',
    textTransform: 'uppercase',
})

export const classes ={
    emptyMsg, anim, panelTab, tabBody, stage
}