import React, { useContext } from 'react';
import { TabPanel } from '../../../common/ui-components/Tab';
import { Companies } from '../companies';
import { FlexibleSettingsProfile } from '../flexible-settings/profile';
import { BusinessUnits } from '../flexible-settings/business-units';
import { User } from '../user';
import { IUserManagementProvider, UserManagementContext } from '../../../context/UserManagementProvider';
import { CustomAuthContext } from '../../../context/AuthProvider';
import { UserPermission } from '../../../config';

const UserManagement: React.FC = () => {
    const { valueTab } = useContext<IUserManagementProvider>(UserManagementContext);
    const { checkPermission } = useContext(CustomAuthContext);

    return (
        <>
            {!checkPermission(UserPermission.ADMIN) && (
                <>
                    <TabPanel value={valueTab} index={0}>
                        <Companies />
                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                        <FlexibleSettingsProfile />
                    </TabPanel>
                </>
            )}
            <TabPanel value={valueTab} index={!checkPermission(UserPermission.ADMIN) ? 2 : 0}>
                <BusinessUnits />
            </TabPanel>
            <TabPanel value={valueTab} index={!checkPermission(UserPermission.ADMIN) ? 3 : 1}>
                <User />
            </TabPanel>
        </>
    );
};

export default UserManagement;
