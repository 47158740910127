import * as React from 'react';
import { useContext, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { DialogContent, Grid, IconButton, Slide } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { classes } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../store';
import { clearStoreFiles, fetchGetFile } from '../../../../store/inspection/files';
import { Spinner } from '../../../spinner';
import { Error as ErrorComponent } from '../../../error';
import { StatusRedux } from '../../../../enums/StatusRedux';
import MailIcon from '@mui/icons-material/Mail';
import { clearStatusSend, fetchSendFile } from '../../../../store/inspection/sendFile';
import { useSnackbar } from 'notistack';
import InnerHTML from 'dangerously-set-html-content';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { IInspectionCase, IInspectionCaseId, IInspectionSendFileRequest, IReport } from '../../../../infrastructure/DTO/inspection/inspection.dto';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { TabPanel, TabsProps } from '../../../../common/ui-components/Tab';
import useFetch from '../../../../hooks/use-fetch';
import { EnumReport } from '../../../../enums/Report';
import { JsonViewer } from '@textea/json-viewer';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { LoadingButton } from '@mui/lab';
import { UserPermission } from '../../../../config';
import DrillDown from '../drill-down/drill-down';
import useCheckInputEmail from '../../../../hooks/validation/use-input-email';
import TextField from '@mui/material/TextField';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

type ReportType = {
    open: boolean;
    userId: string;
    inspection_id: string;
    inspectionType: string | null;
};

interface IReportInspectionComponent {
    close: Function;
    report: ReportType;
}

const ReportInspection: React.FC = () => {
    const zip = new JSZip();
    const [params, setParams] = useSearchParams();
    const is_open: boolean = params.has('is_open') ? params.get('is_open')?.toLowerCase() === 'true' : false;
    const inspection_id: string = params.has('inspection_id') ? (params.get('inspection_id') as string) : '';
    const [response, loading, errorMsg, hasError, setSettings, clearStateFetch] = useFetch();
    const { token, parseToken, checkPermission } = useContext(CustomAuthContext);
    const pdfRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const [sendPdf, setSendPdf] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>('');
    const { files, status } = useSelector((state: AppState) => state.files);
    const { statusSend } = useSelector((state: AppState) => state.sendFile);
    const dispatch: AppDispatch = useDispatch();
    const [value, setValue] = React.useState<number>(0);
    const [reports, setReports] = React.useState<Array<IReport>>([]);
    const [reportName, setReportName] = React.useState<string>('');
    const [htmlLink, setHtmlLink] = React.useState<string>('');
    const [galleries, setGalleries] = React.useState<Array<any>>([]);
    const [galleriesCloseUp, setGalleriesCloseUp] = React.useState<Array<any>>([]);
    const [popup, setPopup] = React.useState<boolean>(false);
    const [downloadsImage, setDownloadsImage] = React.useState<boolean>(false);
    const [downloadsImageBtnHidden, setDownloadsImageBtnHidden] = React.useState<boolean>(true);
    const { inputEmail, checkValueEmail } = useCheckInputEmail();

    /**
     * @interface IReportInspectionComponent
     * @type ReportType
     * Get report
     */
    React.useEffect(() => {
        if (is_open && inspection_id) {
            const args: IInspectionCaseId = {
                inspection_case: inspection_id as string,
            };

            dispatch(fetchGetFile({ token, args }));
        } else {
            handleClose();
        }
    }, [is_open]);

    React.useEffect(() => {
        if (!is_open) {
            setValue(0);
            setReports([]);
            dispatch(clearStoreFiles());
            clearStateFetch();
        }
    }, [is_open]);

    /**
     * Close Popup
     */
    const handleClose = () => {
        if (popup) {
            return;
        } else {
            setParams({
                is_open: 'false',
            });
        }
    };

    /**
     * Change tab
     * @param event
     * @param newValue
     */
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    /**
     * Set reports links to state reports
     */
    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            if (_checkExistFile('PDF', files.reports)) {
                setValue(1);
            } else if (_checkExistFile('HTML', files.reports)) {
                setValue(2);
            } else if (_checkExistFile('PDF', files.reports) && _checkExistFile('HTML', files.reports)) {
                setValue(0);
            } else {
                setValue(0);
            }

            setReports(files.reports);
        }
    }, [status]);

    /**
     * @enum EnumReport
     * fetch links by EnumReport
     */
    React.useEffect(() => {
        if (reports) {
            const report = reports.find((r) => r.type === EnumReport[value]);

            if (report) {
                switch (EnumReport[value]) {
                    case 'JSON':
                        setSettings(report.link, 'JSON');
                        setReportName(`${inspection_id}.json`);
                        break;
                    case 'PDF':
                        setSettings(report.link, 'BLOB');
                        setReportName(`${inspection_id}.pdf`);
                        break;
                    case 'HTML':
                        setSettings(report.link, 'TEXT');
                        setHtmlLink(report.link);
                        setReportName(`${inspection_id}.html`);
                        break;
                }
            }
        }
    }, [reports, value]);

    /**
     * @param copy
     * Copy
     */
    const handleCopy = (copy: string) => () => {
        navigator.clipboard.writeText(copy).then((r) => r);
    };

    /**
     * Download PDF file
     */
    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = response as string;
        link.setAttribute('download', reportName);
        document.body.appendChild(link);
        link.click();
    };

    /**
     * Change email input
     * @param event
     */
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.target.value as string);
    };

    /**
     * Open popup for send pdf
     */
    const popupSendPdf = () => {
        setSendPdf(!sendPdf);
    };

    /**
     * Send PDF report
     */
    const sendPDF = (event: any) => {
        event.preventDefault();

        const args: IInspectionSendFileRequest = {
            company_id: parseToken().company,
            email: email,
            inspection_case: inspection_id as string,
        };

        dispatch(
            fetchSendFile({
                token,
                args,
            })
        );
        setSendPdf(false);
        setEmail('');
    };

    /**
     * Status sent PDF/HTML report
     */
    React.useEffect(() => {
        if (statusSend === StatusRedux.Succeeded) {
            enqueueSnackbar('Report Sent Successfully', {
                variant: 'success',
                autoHideDuration: 2000,
            });
        }

        if (statusSend === StatusRedux.Failed) {
            enqueueSnackbar('Error, Report Not Sent', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        }

        dispatch(clearStatusSend());
    }, [statusSend]);

    /**
     * Popup in html report
     * if contains 'open' class
     */
    const handleTab = () => {
        let current = galleries.filter((i) => i === document.querySelector('.popup-block.open'));
        let currentCloseUp = galleriesCloseUp.filter((i) => i === document.querySelector('div.popup-block-closeup.open'));

        if (current.length > 0 || currentCloseUp.length > 0) {
            setPopup(true);
        }
    };

    /**
     * Get all galleries from report HTML
     */
    React.useEffect(() => {
        if (value === 2) {
            if (String(response).length > 0) {
                let htmlBody = document.querySelectorAll('#tabpanel-2 > div > div > div');
                if (htmlBody.length > 0) {
                    let galleries = htmlBody[0].getElementsByClassName('popup-block');
                    let galleriesCloseUps = htmlBody[0].getElementsByClassName('popup-block-closeup');
                    setGalleries(Array.from(galleries));
                    setGalleriesCloseUp(Array.from(galleriesCloseUps));
                }
            }
        }
        if (value === 0) {
            const data: IInspectionCase = JSON.parse(JSON.stringify(response as string)) as IInspectionCase;

            if (data.report_visuals) {
                setDownloadsImageBtnHidden(false);
            } else {
                setDownloadsImageBtnHidden(true);
            }
        }
    }, [value, response, loading]);

    /**
     * Esc keydown
     * @param event
     */
    const handleEscDown = (event: any) => {
        if (event.keyCode === 27 || event.keyCode === 13) {
            if (value === 2) {
                if (popup) {
                    const current = galleries.filter((i) => i === document.querySelector('div.popup-block.open'));
                    const currentCloseUp = galleriesCloseUp.filter((i) => i === document.querySelector('div.popup-block-closeup.open'));
                    const checkbox: HTMLElement | null = document.querySelector('.checkbox');

                    current.length > 0 && current[0].classList.remove('open');
                    currentCloseUp.length > 0 && currentCloseUp[0].classList.remove('open');

                    if (checkbox) checkbox.style.display = 'none';
                    setPopup(false);
                }
            }
        }
    };

    /**
     * Close Gallery (click to close button)
     */
    React.useEffect(() => {
        if (document.querySelectorAll('.close').length > 0 || document.querySelectorAll('.close-closeup').length > 0) {
            const close: NodeList = document.querySelectorAll('.close');
            const closeCloseup: NodeList = document.querySelectorAll('.close-closeup');

            [...Array.from(close), ...Array.from(closeCloseup)].forEach((item) => {
                item.addEventListener('click', () => {
                    setPopup(false);
                });
            });
        }
    }, [document.querySelectorAll('.close'), document.querySelectorAll('.close-closeup')]);

    const checkExistFile = (file: string): boolean => {
        const report = reports.find((r) => r.type === file);
        return !Boolean(report);
    };

    /**
     * Image Link to Base64
     * @param url
     */
    const _getBase64FromUrl = async (url: string) => {
        try {
            const data = await fetch(url);

            if (!data.ok) {
                return new Error(`Error! status: ${data.status}`);
            }

            const blob = await data.blob();

            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                };
            });
        } catch (err: any) {
            setDownloadsImage(false);
            enqueueSnackbar(err.message, {
                variant: 'error',
                autoHideDuration: 2000,
            });
            return null;
        }
    };

    /**
     * @param files
     * Set files to Zip
     */
    const _setFilesToZip = async (files: Array<{ name: string; link: string }>) => {
        for (const img of files) {
            let folder = zip.folder('images');
            let res = (await _getBase64FromUrl(img.link)) as string;
            if (res === null) return null;
            let type = res.split(';')[0].split('/')[1];
            folder?.file(`${img.name}.${type}`, res.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''), { base64: true });
        }
    };

    /**
     * Download Images from JSON tab
     * Zip
     */
    const handleDownloadImages = async () => {
        setDownloadsImage(true);
        const data: IInspectionCase = JSON.parse(JSON.stringify(response as string)) as IInspectionCase;
        const arrImages: Array<{ name: string; link: string }> = [];

        if (data.report_visuals) {
            data.report_visuals.forEach((image, index) => {
                if (image.side) {
                    image.full_size_damage_overlay_path &&
                        arrImages.push({
                            name: `${image.uploaded_image_id}_damage_overlay_${index + 1}`,
                            link: image.full_size_damage_overlay_path,
                        });
                    image.full_size_original_image_path &&
                        arrImages.push({
                            name: `${image.uploaded_image_id}_original_image_${index + 1}`,
                            link: image.full_size_original_image_path,
                        });
                }
            });
        }

        let dw = await _setFilesToZip(arrImages);
        if (dw !== null) {
            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, `${inspection_id}.zip`);
                zip.remove('images');
                setDownloadsImage(false);
            });
        }
    };

    const _checkExistFile = (fileType: string, reports: Array<IReport>): boolean => {
        if (reports?.length) {
            const report = reports.find((r) => r.type === fileType);
            return Boolean(report);
        }
        return false;
    };

    return (
        <>
            <Dialog fullWidth maxWidth='xs' open={sendPdf} onClose={popupSendPdf}>
                <Box p={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <form id='send-report' autoComplete='off' onSubmit={sendPDF}>
                                <TextField
                                    error={inputEmail.error}
                                    helperText={inputEmail.error ? inputEmail.errorMsg : null}
                                    fullWidth
                                    name='email'
                                    required={true}
                                    id='outlined-basic'
                                    type='email'
                                    label='E-mail'
                                    variant='outlined'
                                    value={email ?? ''}
                                    onChange={handleChangeEmail}
                                    onKeyUp={checkValueEmail}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={6}>
                            <Button form='send-report' fullWidth color='secondary' variant='contained' type='submit'>
                                Send
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth color='primary' variant='contained' onClick={popupSendPdf}>
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
            <Dialog
                fullScreen
                TransitionComponent={Transition}
                open={inspection_id.length > 0 && is_open}
                onKeyDown={handleEscDown}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
            >
                <AppBar color='default' position='static'>
                    <Box sx={classes.appBar}>
                        <Tabs value={value} onChange={handleChange} aria-label='tabs'>
                            <Tab disabled={!_checkExistFile('JSON', files.reports) || loading} label={'JSON'} {...TabsProps(0)} />
                            <Tab disabled={!_checkExistFile('PDF', files.reports) || loading} label={'PDF'} {...TabsProps(1)} />
                            <Tab
                                data-id='html-report'
                                disabled={!_checkExistFile('HTML', files.reports) || loading}
                                label={'HTML'}
                                {...TabsProps(2)}
                            />
                            {!checkPermission(UserPermission.ADMIN) && <Tab label={'Drill Down'} {...TabsProps(3)} />}
                        </Tabs>
                        <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </AppBar>
                <DialogContent sx={classes.reportContent} dividers={true}>
                    <Box sx={classes.root}>
                        <TabPanel value={value} index={0}>
                            <Box>
                                {loading ? (
                                    <Spinner />
                                ) : !hasError ? (
                                    <JsonViewer
                                        groupArraysAfterLength={10}
                                        collapseStringsAfterLength={500}
                                        displayObjectSize={false}
                                        displayDataTypes={false}
                                        value={response}
                                    />
                                ) : null}
                                {hasError && <ErrorComponent msg={errorMsg} />}
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {loading ? (
                                <Spinner />
                            ) : !hasError ? (
                                <embed
                                    style={{ height: 'calc(100vh - 110px)' }}
                                    ref={pdfRef}
                                    type='application/pdf'
                                    title='Report'
                                    width='100%'
                                    src={response as string}
                                ></embed>
                            ) : null}
                            {hasError && <ErrorComponent msg={errorMsg} />}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {loading ? (
                                <Spinner />
                            ) : !hasError ? (
                                <Box onClick={handleTab}>{String(response) && <InnerHTML html={String(response)} />}</Box>
                            ) : null}
                            {hasError && <ErrorComponent msg={errorMsg} />}
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <DrillDown />
                        </TabPanel>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {(() => {
                        switch (value) {
                            case 0:
                                return (
                                    <>
                                        <Button
                                            disabled={hasError}
                                            startIcon={<GetAppIcon />}
                                            href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(response))}`}
                                            download={reportName}
                                        >
                                            Download JSON
                                        </Button>
                                        {!checkPermission(UserPermission.ADMIN) && (
                                            <LoadingButton
                                                disabled={downloadsImageBtnHidden}
                                                onClick={handleDownloadImages}
                                                loading={downloadsImage}
                                                loadingPosition='start'
                                                startIcon={<FolderZipIcon />}
                                            >
                                                Download Images
                                            </LoadingButton>
                                        )}
                                    </>
                                );
                            case 1:
                                return (
                                    <>
                                        <Button disabled={hasError} onClick={downloadFile} startIcon={<GetAppIcon />}>
                                            Download PDF
                                        </Button>
                                        <Button
                                            disabled={statusSend === StatusRedux.Succeeded || hasError}
                                            onClick={popupSendPdf}
                                            startIcon={<MailIcon />}
                                        >
                                            Send PDF
                                        </Button>
                                    </>
                                );
                            case 2:
                                return (
                                    <>
                                        <Button
                                            disabled={statusSend === StatusRedux.Succeeded || hasError}
                                            onClick={handleCopy(htmlLink)}
                                            startIcon={<FileCopyIcon />}
                                        >
                                            HTML Link
                                        </Button>
                                        <Button
                                            disabled={hasError}
                                            download={reportName}
                                            href={`data:text/html;charset=UTF-8,${encodeURIComponent(response as string)}`}
                                        >
                                            <GetAppIcon sx={{ marginLeft: 1.5 }} /> Download HTML
                                        </Button>
                                        <Button
                                            disabled={statusSend === StatusRedux.Succeeded || hasError}
                                            onClick={popupSendPdf}
                                            startIcon={<MailIcon />}
                                        >
                                            Send HTML
                                        </Button>
                                    </>
                                );
                        }
                    })()}
                </DialogActions>
            </Dialog>
        </>
    );
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} children={props.children} />;
});

export default ReportInspection;
