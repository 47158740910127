import { IconButton, TextField } from '@mui/material';
import * as React from 'react';
import ImageUploading from 'react-images-uploading';
import { PhotoCamera } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { classes } from './style';
import Box from '@mui/material/Box';
import { InspectionContext } from '../../../../context/InspectionProvider';

const Odometer: React.FC = () => {
    const maxNumber = 1;
    const { setInspectionToState, inspectionStateContext } = React.useContext(InspectionContext);

    const onChangeOdometerImage = (image: any, addUpdateIndex: any) => {
        let img_id: string = `f${(+new Date()).toString(16)}`;

        setInspectionToState({
            ...inspectionStateContext,
            odometerImgView: image,
            odometerImg: {
                image_id: img_id,
                name: image[0].file.name,
                content_type: image[0].file.type,
                content: image[0].data_url.split(',')[1],
                context: {
                    view_type: '',
                    side: '',
                    tag: '',
                },
                resolution: {
                    width: 0,
                    height: 0,
                },
            },
        });
    };

    const onChangeOdometerNumber = (event: any) => {
        setInspectionToState({
            ...inspectionStateContext,
            odometer: event.target.value,
        });
    };

    const onRemoveImage = () => {
        setInspectionToState({
            ...inspectionStateContext,
            odometerImgView: [],
            odometerImg: undefined,
        });
    };

    return (
        <React.Fragment>
            <TextField
                variant='standard'
                disabled={inspectionStateContext.odometerImgView.length !== 0}
                autoComplete='off'
                fullWidth
                name='odometer'
                id='outlined-basic'
                type='text'
                label='Odometer'
                value={inspectionStateContext.odometer}
                onChange={onChangeOdometerNumber}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <ImageUploading
                value={inspectionStateContext.odometerImgView}
                onChange={onChangeOdometerImage}
                maxNumber={maxNumber}
                dataURLKey='data_url'
            >
                {({ imageList, onImageUpload }) => (
                    <Box
                        sx={classes.odometerBlock}
                        style={Boolean(inspectionStateContext.odometer) ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
                    >
                        <label htmlFor='icon-button-file' onClick={onImageUpload}>
                            <IconButton
                                disabled={Boolean(inspectionStateContext.odometer)}
                                color='primary'
                                aria-label='upload picture'
                                component='span'
                            >
                                <PhotoCamera />
                            </IconButton>
                        </label>
                        {imageList.map((image, index) => (
                            <Box key={index} sx={classes.odometerImg}>
                                <img src={image['data_url']} alt='odometer' />
                                <Box sx={classes.odometerBtn}>
                                    <IconButton color='primary' aria-label='remove picture' onClick={onRemoveImage}>
                                        <CancelIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )}
            </ImageUploading>
        </React.Fragment>
    );
};

export default Odometer;
