import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserServices from '../../services/users/user-services';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchBody } from '../IFetch';
import { IUser } from '../../infrastructure/DTO/users/user.dto';
import { IToken } from '../../infrastructure/DTO/users/token.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchUserLogin = createAsyncThunk<IToken, IFetchBody<IUser>>('user/login', async (params) => {
    return await UserServices.login(params);
});

export const userLoginSlice = createSlice({
    name: 'user',
    initialState: {
        userLoginData: {} as IToken,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        Logout: (state) => {
            state.userLoginData = {} as IToken;
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserLogin.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchUserLogin.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.userLoginData = action.payload;
        });
        builder.addCase(fetchUserLogin.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { Logout } = userLoginSlice.actions;
export default userLoginSlice;
