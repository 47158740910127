import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import { Image as Pic } from 'react-konva';
import DamageLabLayoutContext, { IDamageLabLayoutContext } from '../../../../../../context/DamageLabLayoutContext';
import { IDamageLabLayout } from '../IDamageLabLayout';
import { IPic } from '../../../../../../infrastructure/DTO/multilayered/multilayered.dto';

interface IThreeDLayoutComponent extends IDamageLabLayout {}

const ThreeDLayout: React.FC<IThreeDLayoutComponent> = (props: IThreeDLayoutComponent) => {
    const { canvasSize, opacitySliderThreeD } = React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);
    const { imageOverView } = useSelector((state: AppState) => state.imageOverView);
    const [pic, setPic] = React.useState<IPic>({
        width: 1,
        height: 1,
        image: null,
    });

    React.useEffect(() => {
        if (props.view) {
            if (imageOverView.anomaly_measurement) {
                if (imageOverView.anomaly_measurement['3d_projection']?.overlay) {
                    const image = new Image();

                    image.onload = function () {
                        setPic({
                            width: image.width,
                            height: image.height,
                            image: image,
                        });
                    };

                    image.src = 'data:image/png;base64,' + imageOverView.anomaly_measurement['3d_projection'].overlay;
                }
            }
        } else {
            setPic({
                width: 1,
                height: 1,
                image: null,
            });
        }
    }, [props.view]);

    return (
        <React.Fragment>
            {pic && (
                <Pic
                    opacity={opacitySliderThreeD}
                    width={pic.width * Math.min(canvasSize.width / pic.width, canvasSize.height / pic.height)}
                    height={pic.height * Math.min(canvasSize.width / pic.width, canvasSize.height / pic.height)}
                    image={pic.image as HTMLImageElement}
                />
            )}
        </React.Fragment>
    );
};

export default ThreeDLayout;
