import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Inspection from '../../services/inspection/inspection';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs, IFetchBody } from '../IFetch';
import { IArgsDamages, IDamagesRequest, IImageResponse, IRemoveDamage } from '../../infrastructure/DTO/inspection/inspection.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchDamageCreate = createAsyncThunk<IImageResponse, IFetchBody<IDamagesRequest, IArgsDamages>>(
    'damages/create',
    async (params: IFetchBody<IDamagesRequest, IArgsDamages>) => {
        return await Inspection.createDamage(params);
    }
);

export const fetchDamageRemove = createAsyncThunk<any, IFetchArgs<IRemoveDamage>>('damages/remove', async (params: IFetchArgs<IRemoveDamage>) => {
    return await Inspection.removeDamageById(params);
});

export const damagesSlice = createSlice({
    name: 'damages',
    initialState: {
        damagesList: {} as IImageResponse,
        status: '',
        statusRemove: '',
        error: {} as IErrorResponse,
        errorRemove: {} as IErrorResponse,
    },
    reducers: {
        clearStatusDamage: (state) => {
            state.status = '';
            state.statusRemove = '';
            state.error = {} as IErrorResponse;
            state.errorRemove = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDamageCreate.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchDamageCreate.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.damagesList = action.payload;
        });
        builder.addCase(fetchDamageCreate.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchDamageRemove.pending, (state) => {
            state.statusRemove = StatusRedux.Loading;
        });
        builder.addCase(fetchDamageRemove.fulfilled, (state, action) => {
            state.statusRemove = StatusRedux.Succeeded;
        });
        builder.addCase(fetchDamageRemove.rejected, (state, action) => {
            state.statusRemove = StatusRedux.Failed;
            state.errorRemove = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStatusDamage } = damagesSlice.actions;
export default damagesSlice;
