import * as React from 'react';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { classes } from './style';
import { HeaderMenu } from '../../header-menu';
import usePrivateRoutesConfig from '../../../config/private-routes-config';

const LayoutMain: React.FC = () => {
    const routes = usePrivateRoutesConfig();

    return (
        <Box sx={classes.root}>
            <HeaderMenu routes={routes} />
            <Box sx={classes.content}>
                <Box sx={classes.toolbar} />
                <Outlet />
            </Box>
        </Box>
    );
};

export default LayoutMain;
