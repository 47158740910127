import React from 'react';
import { BasicDialog } from '../../../../../common/ui-components/Dialog';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Paper, Typography } from '@mui/material';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import TextField from '@mui/material/TextField';
import { ClearStoreGenerateToken, fetchGenerateShortToken, fetchGenerateToken } from '../../../../../store/users/generate-token';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Spinner } from '../../../../spinner';
import GenerateTokenFields from './fields';
import SaveIcon from '@mui/icons-material/Save';
import { IGenerateToken } from '../../../../../infrastructure/DTO/users/user-view.dto';
import { LoadingButton } from '@mui/lab';
import errorParseMessage from '../../../../../config/error-parse';

export enum EnumGenerateToken {
    SHORT = 'SHORT',
    REGULAR = 'REGULAR',
}

interface IGenerateTokenComponent {
    open: boolean;
    apiKey: string;
    close(is_open: boolean): void;
}

const initBodyToken: IGenerateToken = {
    grant_type: 'client_credentials',
    client_secret: '',
    client_process_id: '',
    redirect_url: '',
    fail_url: '',
    unauthorized_url: '',
};

const initViewComponent = {
    fields: true,
    result: false,
};

const GenerateToken: React.FC<IGenerateTokenComponent> = (props: IGenerateTokenComponent) => {
    const generateToken = useSelector((state: AppState) => state.generateToken);
    const [generateBodyToken, setBodyToken] = React.useState<IGenerateToken>(initBodyToken);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const [viewToken, setViewToken] = React.useState<string>('');
    const [viewComponent, setViewComponent] = React.useState<{
        fields: boolean;
        result: boolean;
    }>(initViewComponent);
    const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(false);
    const [chooseToken, setToken] = React.useState<EnumGenerateToken>(EnumGenerateToken.REGULAR);

    const handleChooseToken = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToken(EnumGenerateToken[(event.target as HTMLInputElement).value as EnumGenerateToken]);
    };

    React.useEffect(() => {
        if (props.apiKey.length > 0) {
            setBodyToken({
                ...generateBodyToken,
                client_secret: props.apiKey,
            });
        }

        if (!props.open) {
            setToken(EnumGenerateToken.REGULAR);
            setViewComponent(initViewComponent);
            setBodyToken(initBodyToken);
        }
    }, [props.apiKey, props.open]);

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;

        setBodyToken({
            ...generateBodyToken,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        const body: IGenerateToken = generateBodyToken;

        if (chooseToken === EnumGenerateToken.REGULAR) {
            dispatch(fetchGenerateToken({ body }));
        }

        if (chooseToken === EnumGenerateToken.SHORT) {
            dispatch(fetchGenerateShortToken({ body }));
        }
    };

    const handleCloseToken = () => {
        setViewToken('');
        dispatch(ClearStoreGenerateToken());
        props.close(false);
    };

    /**
     * Status Generate Token
     */
    React.useEffect(() => {
        if (generateToken.status === StatusRedux.Succeeded) {
            setViewToken(`${process.env.REACT_APP_WAP_LINK}?token=${generateToken?.token?.access_token}`);
            setViewComponent({
                fields: false,
                result: true,
            });
        }

        if (generateToken.statusShortToken === StatusRedux.Succeeded) {
            setViewToken(generateToken.shortToken.short_link);
            setViewComponent({
                fields: false,
                result: true,
            });
        }

        if (generateToken.statusShortToken === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(generateToken.errorShortToken), {
                variant: 'error',
            });

            dispatch(ClearStoreGenerateToken());
            props.close(false);
        }

        if (generateToken.status === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(generateToken.error), {
                variant: 'error',
            });

            dispatch(ClearStoreGenerateToken());
            props.close(false);
        }
    }, [generateToken.status, generateToken.statusShortToken]);

    return (
        <BasicDialog
            handlePopup={handleCloseToken}
            open={props.open}
            title={`Inspection Link`}
            width='md'
            buttons={
                <>
                    {viewComponent.fields && (
                        <Grid container spacing={2} justifyContent='flex-end'>
                            <Grid item>
                                <LoadingButton
                                    form='create-link-fields'
                                    loadingPosition='end'
                                    loading={generateToken.status === StatusRedux.Loading || generateToken.statusShortToken === StatusRedux.Loading}
                                    disabled={
                                        isButtonDisabled ||
                                        generateToken.status === StatusRedux.Loading ||
                                        generateToken.statusShortToken === StatusRedux.Loading
                                    }
                                    size='small'
                                    variant='outlined'
                                    color='primary'
                                    endIcon={<SaveIcon />}
                                    onClick={handleSubmit}
                                >
                                    Create
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    )}
                    {viewComponent.result && (
                        <Grid container spacing={2} justifyContent='flex-end'>
                            <Grid item>
                                <Button size='small' variant='outlined' color='primary' onClick={handleCloseToken} endIcon={<CloseIcon />}>
                                    Close
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    size='small'
                                    color='secondary'
                                    variant='contained'
                                    onClick={() => navigator.clipboard.writeText(`${viewToken}`).then((r) => r)}
                                    endIcon={<ContentCopyIcon />}
                                >
                                    Copy
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </>
            }
        >
            {viewComponent.fields && (
                <GenerateTokenFields
                    chooseToken={chooseToken}
                    handleChooseToken={handleChooseToken}
                    setIsButtonDisabled={setIsButtonDisabled}
                    body={generateBodyToken}
                    handleChangeInput={handleChangeInput}
                    handleSubmit={handleSubmit}
                />
            )}
            {viewComponent.result && (
                <Paper sx={{ height: '215px' }} elevation={2}>
                    {generateToken.status === StatusRedux.Loading && <Spinner />}
                    {generateToken.statusShortToken === StatusRedux.Loading && <Spinner />}
                    {generateToken.status === StatusRedux.Succeeded && (
                        <TextField fullWidth id='filled-multiline-static' label='Link' multiline rows={8} value={viewToken} variant='filled' />
                    )}
                    {generateToken.statusShortToken === StatusRedux.Succeeded && (
                        <TextField fullWidth id='filled-multiline-static' label='Link' multiline rows={8} value={viewToken} variant='filled' />
                    )}
                    {generateToken.status === StatusRedux.Succeeded && StatusRedux.Failed && (
                        <Typography variant='button' display='block' gutterBottom>
                            {generateToken.error.errors && generateToken.error.errors[0].message}
                        </Typography>
                    )}
                    {generateToken.statusShortToken === StatusRedux.Succeeded && StatusRedux.Failed && (
                        <Typography variant='button' display='block' gutterBottom>
                            {generateToken.errorShortToken.errors && generateToken.errorShortToken.errors[0].message}
                        </Typography>
                    )}
                </Paper>
            )}
        </BasicDialog>
    );
};

export default GenerateToken;
