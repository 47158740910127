import React from 'react';
import DamageLabLayoutProvider from '../../../../../provider/DamageLabLayoutProvider';
import DamageLabLayout from './damage-lab-layout';

const DamageLabProvider: React.FC = () => {
    return (
        <DamageLabLayoutProvider>
            <DamageLabLayout />
        </DamageLabLayoutProvider>
    );
};

export default DamageLabProvider;
