import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Inspection from '../../services/inspection/inspection';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs, IFetchBody } from '../IFetch';
import {
    ICreateInspection,
    IInspection,
    IInspectionCase,
    IInspectionCaseId,
    IInspectionImageRequest,
    IInspectionOdometerResponse,
    IUpdateInspection,
} from '../../infrastructure/DTO/inspection/inspection.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchCreateInspection = createAsyncThunk<IInspection, IFetchBody<ICreateInspection>>(
    'inspection/create',
    async (params: IFetchBody<ICreateInspection>) => {
        return await Inspection.createInspection(params);
    }
);

export const fetchUpdateInspection = createAsyncThunk<IInspection, IFetchBody<IUpdateInspection, IInspectionCaseId>>(
    'inspection/update',
    async (params: IFetchBody<IUpdateInspection, IInspectionCaseId>) => {
        return await Inspection.updateInspection(params);
    }
);

export const fetchGetInspection = createAsyncThunk<IInspectionCase, IFetchArgs<IInspectionCaseId>>(
    'inspection/get',
    async (params: IFetchArgs<IInspectionCaseId>) => {
        return await Inspection.inspectionData(params);
    }
);

export const fetchInspectionOdometer = createAsyncThunk<IInspectionOdometerResponse, IFetchBody<IInspectionImageRequest, IInspectionCaseId>>(
    'inspection/odometer',
    async (params: IFetchBody<IInspectionImageRequest, IInspectionCaseId>) => {
        return await Inspection.uploadImageOdometer(params);
    }
);

export const inspectionSlice = createSlice({
    name: 'inspection',
    initialState: {
        inspections: {} as IInspection,
        inspection: {} as IInspectionCase,
        inspectionOdometer: {} as IInspectionOdometerResponse,
        statusInspection: '',
        errorInspection: {} as IErrorResponse,
        statusInspections: '',
        errorInspections: {} as IErrorResponse,
        statusOdometer: '',
        errorOdometer: {} as IErrorResponse,
    },
    reducers: {
        clearStoreInspections: (state) => {
            state.inspections = {} as IInspection;
            state.inspection = {} as IInspectionCase;
            state.inspectionOdometer = {} as IInspectionOdometerResponse;
        },
        clearStatusInspections: (state) => {
            state.statusInspection = '';
            state.statusInspections = '';
            state.errorInspection = {} as IErrorResponse;
            state.statusInspection = '';
            state.errorInspection = {} as IErrorResponse;
            state.statusOdometer = '';
            state.errorOdometer = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCreateInspection.pending, (state) => {
            state.statusInspections = StatusRedux.Loading;
        });
        builder.addCase(fetchCreateInspection.fulfilled, (state, action) => {
            state.statusInspections = StatusRedux.Succeeded;
            state.inspections = action.payload;
        });
        builder.addCase(fetchCreateInspection.rejected, (state, action) => {
            state.statusInspections = StatusRedux.Failed;
            state.errorInspections = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUpdateInspection.pending, (state) => {
            state.statusInspections = StatusRedux.Loading;
        });
        builder.addCase(fetchUpdateInspection.fulfilled, (state, action) => {
            state.statusInspections = StatusRedux.Succeeded;
        });
        builder.addCase(fetchUpdateInspection.rejected, (state, action) => {
            state.statusInspections = StatusRedux.Failed;
        });

        builder.addCase(fetchGetInspection.pending, (state) => {
            state.statusInspection = StatusRedux.Loading;
        });
        builder.addCase(fetchGetInspection.fulfilled, (state, action) => {
            state.statusInspection = StatusRedux.Succeeded;
            state.inspection = action.payload;
        });
        builder.addCase(fetchGetInspection.rejected, (state, action) => {
            state.statusInspection = StatusRedux.Failed;
            state.errorInspection = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchInspectionOdometer.pending, (state) => {
            state.statusOdometer = StatusRedux.Loading;
        });
        builder.addCase(fetchInspectionOdometer.fulfilled, (state, action) => {
            state.statusOdometer = StatusRedux.Succeeded;
            state.inspectionOdometer = action.payload;
        });
        builder.addCase(fetchInspectionOdometer.rejected, (state, action) => {
            state.statusOdometer = StatusRedux.Failed;
            state.errorOdometer = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreInspections, clearStatusInspections } = inspectionSlice.actions;
export default inspectionSlice;
