import { Stage } from 'react-konva';
import React, { PropsWithChildren } from 'react';
import { KonvaProps } from '../interface/IKonva';
import Box from '@mui/material/Box';

const Konva: React.FC<PropsWithChildren<KonvaProps>> = (props: KonvaProps) => {
    const { konvaRef, style, width, height, children, wheel, drawing, resetZoom } = props;
    const [zoom, setZoom] = React.useState<{ scale: number; x: number; y: number }>({ scale: 1, x: 1, y: 1 });
    const [isDrawing, setDrawing] = React.useState<boolean>(false);

    const handleWheelZoom = (e: any) => {
        if (wheel) {
            e.evt.preventDefault();
            let scaleBy = 1.02;
            const stage = e.target.getStage();

            const oldScale = stage.scaleX();

            let mousePointTo = {
                x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
                y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
            };

            const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

            setZoom({
                scale: newScale,
                x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
                y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
            });
        }
    };

    const handleMouseDown = () => {
        drawing?.onMouseDown();
        if (drawing?.edit) {
            setDrawing(true);
        }
    };

    const handleMouseMove = (e: any) => {
        let stage = e.target.getStage();
        const oldScale = stage.scaleX();

        if (drawing?.edit) {
            if (!isDrawing) {
                return;
            }

            let coordinatesX: number = props.drawing?.coordinates.x as number;
            let coordinatesY: number = props.drawing?.coordinates.y as number;

            let mousePointTo = {
                x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
                y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
            };

            drawing?.onMousemove({ x: Math.floor(mousePointTo.x - coordinatesX), y: Math.floor(mousePointTo.y - coordinatesY) });
        }
    };

    const handleMouseUp = () => {
        drawing?.onMouseUp();
        setDrawing(false);
    };

    React.useEffect(() => {
        setZoom({
            scale: 1.02,
            x: 1,
            y: 1,
        });
    }, [resetZoom]);

    React.useEffect(() => {
        if (props.zoomPerArea?.isZoom) {
            let scale = Math.min(width / props.zoomPerArea.width, height / props.zoomPerArea.height);

            setZoom({
                ...zoom,
                scale: scale,
            });
        }
    }, [props.zoomPerArea?.isZoom]);

    return (
        <Box sx={style} ref={konvaRef}>
            <Stage
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onWheel={handleWheelZoom}
                scaleX={zoom.scale}
                scaleY={zoom.scale}
                x={zoom.x}
                y={zoom.y}
                width={width}
                height={height}
            >
                {children}
            </Stage>
        </Box>
    );
};

export default Konva;
