import { CSSObject, Theme } from '@mui/material';

export const root = (): CSSObject => ({
    display: 'flex',
    flexGrow: 1,
});

export const nested = (): CSSObject => ({
    paddingLeft: 15,
});

export const toolBar = (theme: Theme): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: '62px',
    marginLeft: '-17px!important',
    padding: theme.spacing(0, 1),
});

export const content = (theme: Theme): CSSObject => ({
    flexGrow: 1,
    padding: theme.spacing(3),
});

export const title = (): CSSObject => ({
    flexGrow: 1,
});

export const icon = (): CSSObject => ({
    marginLeft: '0 10px 0 0',
});

export const btnsHeader = (): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
});

export const menuText = (): CSSObject => ({
    '& > span': {
        textTransform: 'uppercase',
        fontSize: '0.8em',
    },
});

export const menuIconW = (): CSSObject => ({
    minWidth: '30px!important',
});

export const menuIcon = (): CSSObject => ({
    marginLeft: '-2px',
    fontSize: '1.3em',
});
