import {CSSObject, keyframes, Theme} from "@mui/material";

const fadeIn = keyframes`
    "0%": {
      opacity: 0
    },
    "100%": {
        opacity: 1
    }
`;

const stage = (): CSSObject => ({
    width: 'auto',
    minHeight: '650px',
    overflow: 'hidden',
    cursor: 'move'
})

const listMain = (): CSSObject => ({
    height: '650px',
    overflowY: 'scroll'
})

const checkBoxList = (): CSSObject => ({
    minWidth: '30px'
})

const nested = (theme: Theme): CSSObject => ({
    paddingLeft: theme.spacing(4),
})

const iconLessMore = (): CSSObject => ({
    marginLeft: '15px'
})

const tableRow = (theme: Theme): CSSObject => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer'
})

const head = (theme: Theme): CSSObject => ({
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
})

const table = (): CSSObject => ({
    height: 'auto'
})

const tableTitle = (): CSSObject => ({
    width: '100%',
    marginTop: '25px',
    textAlign: 'center'
})

const headerPopup = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 20px'
})

const titlePopup = (theme: Theme): CSSObject => ({
    color: theme.palette.primary.main,
    fontSize: '1.4em',
    fontWeight: 500
})

const anim = (theme: Theme): CSSObject => ({
    animation: `${fadeIn} 500ms ${theme.transitions.easing.easeInOut}`,
}) 

export const classes = {
    anim, 
    titlePopup, 
    stage, 
    checkBoxList, 
    listMain,
    headerPopup, 
    tableTitle, 
    table, 
    tableRow,
    iconLessMore, 
    head, 
    nested
}