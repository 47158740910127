import { ServicesMethod, ServicesType } from '../types/ServicesBase';

export abstract class ServicesBase {
    static async services(method: ServicesMethod, url: string, body: BodyInit | null, headers: HeadersInit, type: ServicesType = 'JSON') {
        const config: RequestInit = { method, headers, body };

        const response = await fetch(url, config);
        let res;

        switch (type) {
            case 'JSON':
                res = await response.json();
                break;
            case 'TEXT':
                res = await response.text();
                break;
            case 'BLOB':
                res = await response.blob();
                break;
        }

        if (response.ok) {
            return res;
        }

        throw new Error(JSON.stringify(res));
    }
}
