import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Inspection from '../../services/inspection/inspection';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs } from '../IFetch';
import { IInspectionSendFileRequest, IInspectionSendFileResponse } from '../../infrastructure/DTO/inspection/inspection.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchSendFile = createAsyncThunk<IInspectionSendFileResponse, IFetchArgs<IInspectionSendFileRequest>>(
    'files/send',
    async (params: IFetchArgs<IInspectionSendFileRequest>) => {
        return await Inspection.sendFile(params);
    }
);

export const inspectionSlice = createSlice({
    name: 'files',
    initialState: {
        fileSend: {} as IInspectionSendFileResponse,
        statusSend: '',
        errorSend: {} as IErrorResponse,
    },
    reducers: {
        clearStatusSend: (state) => {
            state.fileSend = {} as IInspectionSendFileResponse;
            state.statusSend = '';
            state.errorSend = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSendFile.pending, (state) => {
            state.statusSend = StatusRedux.Loading;
        });
        builder.addCase(fetchSendFile.fulfilled, (state, action) => {
            state.statusSend = StatusRedux.Succeeded;
            state.fileSend = action.payload;
        });
        builder.addCase(fetchSendFile.rejected, (state, action) => {
            state.statusSend = StatusRedux.Failed;
            state.errorSend = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStatusSend } = inspectionSlice.actions;
export default inspectionSlice;
