import React, { useRef } from 'react';
import { Group, Image as Pic, Layer, Line } from 'react-konva';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import { classes } from './style';
import { Grid } from '@mui/material';
import Konva from '../../../../../../common/ui-components/Konva';

const SegmentCar: React.FC = () => {
    const { imageMain } = useSelector((state: AppState) => state.damageLabImage);
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);
    const refDiv = useRef<HTMLDivElement | null>(null);
    const [coordinates, setCoordinates] = React.useState({ x: 1, y: 1 });
    const [points, setPoints] = React.useState<Array<number>>([]);
    const [size, setSize] = React.useState<{ width: number; height: number }>({ width: 1, height: 1 });

    React.useEffect(() => {
        if (refDiv.current !== null) {
            setSize({
                width: refDiv.current.clientWidth,
                height: refDiv.current.clientHeight,
            });
        }
    }, []);

    React.useEffect(() => {
        let scale = Math.min(size.width / imageMain.width, size.height / imageMain.height);
        const points: Array<number> = [];

        setCoordinates({
            x: size.width / 2 - (imageMain.width * scale) / 2,
            y: 0,
        });

        if (damageLabData.vehicle_segmentation !== null) {
            damageLabData.vehicle_segmentation.contours[0].forEach((p) => {
                points.push(Math.floor(p.x * scale), Math.floor(p.y * scale));
            });
        }

        setPoints(points);
    }, [size.width, size.height]);

    const handleMove = (e: any) => {
        setCoordinates({
            x: e.target.x(),
            y: e.target.y(),
        });
    };

    return (
        <Grid container spacing={1} direction='row' justifyContent='center'>
            <Grid sx={classes.anim} item xs={12} sm={12} md={10} lg={10} xl={10}>
                <Konva wheel={true} konvaRef={refDiv} style={classes.stage} width={size.width} height={size.height}>
                    <Layer>
                        <Group x={coordinates.x} y={coordinates.y} draggable onDragMove={handleMove} onDragEnd={handleMove}>
                            <Pic
                                width={imageMain.width * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                height={imageMain.height * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                image={imageMain.image}
                            />
                            <Line
                                key={1}
                                points={points}
                                stroke='green'
                                strokeWidth={3.5}
                                tension={0}
                                lineCap='square'
                                closed={true}
                                globalCompositeOperation='source-over'
                            />
                        </Group>
                    </Layer>
                </Konva>
            </Grid>
        </Grid>
    );
};

export default SegmentCar;
