import {CSSObject, keyframes, Theme} from "@mui/material";

const fadeIn = keyframes`
    "0%": {
      opacity: 0
    },
    "100%": {
        opacity: 1
    }
`;

const stage = (): CSSObject => ({
    width: '100%',
    minHeight: '650px',
    overflow: 'hidden',
    cursor: 'move'
})

const tabBody = (): CSSObject => ({
    marginTop: '25px'
})

const panelTab = (): CSSObject => ({
    width: '100%', 
    overflow: 'hidden'
})

const listHeader = (): CSSObject => ({
})

const listMain = (): CSSObject => ({
    height: '650px',
    overflowY: 'scroll'
})

const thresholdBlock = (): CSSObject => ({
    display: 'flex', 
    alignItems: 'center'
})

const thresholdBlockTitle = (): CSSObject => ({
    marginRight: '20px'
})

const main = (): CSSObject => ({
    position: 'relative'
})

const skeletonImg = (): CSSObject => ({
    width: '100%',
    height: '650px'
})

const skeletonDivMain = (): CSSObject => ({
    display: 'flex',
})

const skeletonRect = (): CSSObject => ({
    width: '30px',
    height: '50px',
    marginRight: '10px'
})

const skeletonDiv = (): CSSObject => ({
    width: '100%',
    height: '50px'
})

const anim = (theme: Theme): CSSObject => ({
    animation: `$fadeIn 500ms ${theme.transitions.easing.easeInOut}`,
})


export const classes = {
    anim,
    skeletonImg,
    thresholdBlock, 
    skeletonDiv, 
    main, 
    skeletonRect, 
    skeletonDivMain,
    stage, tabBody, 
    panelTab,
    listHeader, 
    listMain, 
    thresholdBlockTitle
}