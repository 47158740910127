import * as React from 'react';
import { memo, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, Icon, ListItemButton, styled, Theme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { AppDispatch, AppState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Logout } from '../../store/users/login';
import PopupBack from '../popup-back/popup-back';
import { showPopup } from '../../store/events/popup';
import { MenuRoute, RoutesType } from '../../routes/RouteType';
import { CustomThemeContext } from '../../context/CustomThemeProvider';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import { btnsHeader, icon, menuIcon, menuIconW, menuText, title, toolBar } from './style';
import Box from '@mui/material/Box';
import { CustomAuthContext } from '../../context/AuthProvider';
import Button from '@mui/material/Button';
import packageJson from '../../../package.json';
import StorageIcon from '@mui/icons-material/Storage';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

type PropsType = {
    routes: RoutesType;
};

const drawerWidth = 190;
const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `47px`,
    [theme.breakpoints.up('sm')]: {
        width: `47px`,
    },
});
const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const HeaderMenu: React.FC<PropsType> = (props: PropsType) => {
    const { removeToken } = React.useContext(CustomAuthContext);
    const { currentTheme, setTheme } = React.useContext(CustomThemeContext);
    const { parseToken } = useContext(CustomAuthContext);
    const [popupBack, setPopupBack] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();
    const [open, setOpen] = React.useState(localStorage.getItem('menu') !== undefined ? Boolean(Number(localStorage.getItem('menu'))) : false);
    const { popup } = useSelector((state: AppState) => state.popupEvent);
    const dispatch: AppDispatch = useDispatch();

    const handleDrawerOpen = () => {
        localStorage.setItem('menu', '1');
        setOpen(true);
    };

    const handleDrawerClose = () => {
        localStorage.setItem('menu', '0');
        setOpen(false);
    };

    const handleLogout = () => {
        if (popup) {
            setPopupBack(true);
        } else {
            logOut();
        }
    };

    const handleToProfile = () => {
        setAnchorEl(null);
        navigate('/home/profile');
    };

    const handleChangeTheme = (e: any) => {
        if (currentTheme === 'light') {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    };

    const handleClosePopup = () => {
        setPopupBack(false);
    };

    const logOut = () => {
        removeToken();
        dispatch(Logout());
        dispatch(showPopup(false));
        navigate('/');
    };

    const back = () => {
        logOut();
        setPopupBack(false);
        dispatch(showPopup(false));
    };

    return (
        <Box data-print='none'>
            <PopupBack open={popupBack} close={handleClosePopup} back={back} />
            <CssBaseline />
            <AppBar sx={{ marginBottom: 2 }} position='fixed' open={open}>
                <Toolbar>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        onClick={handleDrawerOpen}
                        edge='start'
                        sx={{ marginLeft: '-17px!important', marginRight: '36px', ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant='h6' noWrap sx={title}>
                        CLICK INS
                    </Typography>
                    <Box sx={btnsHeader}>
                        <IconButton aria-label='theme' aria-controls='menu-theme' onClick={handleChangeTheme} color='inherit'>
                            {currentTheme === 'light' ? <NightlightRoundIcon /> : <LightModeIcon />}
                        </IconButton>
                        <Button
                            sx={{ textTransform: 'inherit' }}
                            aria-label='account of current user'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleToProfile}
                            color='inherit'
                        >
                            <AccountCircle />
                            <Typography pt={1} pl={1} variant='caption' display='block' gutterBottom>
                                Hi, {parseToken()?.Name}
                            </Typography>
                        </Button>
                        <IconButton color='inherit' aria-label='Log Off' component='span' onClick={handleLogout}>
                            <ExitToAppOutlinedIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant='permanent' open={open}>
                <Box sx={toolBar}>
                    <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                    <Box>
                        <List sx={{ padding: '0px' }}>
                            {props.routes.map((item, index) => (
                                <div key={index}>
                                    {item.main ? (
                                        <MenuRender
                                            render={item.render}
                                            id={item.id}
                                            path={item?.path as string}
                                            icon={item?.icon}
                                            title={item?.title}
                                        />
                                    ) : (
                                        <SubMenuRender
                                            id={item.id}
                                            path={item?.path as string}
                                            icon={item?.icon}
                                            title={item?.title}
                                            children={item?.childrenMenu as RoutesType}
                                            main={item?.main as boolean}
                                        />
                                    )}
                                </div>
                            ))}
                        </List>
                    </Box>
                    {open && (
                        <>
                            {process.env.REACT_APP_SERVER !== 'SANDBOX' && (
                                <Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '10px' }}>
                                        <StorageIcon fontSize={'small'} />
                                        <Typography sx={{ marginBottom: '0', marginLeft: '10px' }} variant='caption' display='block' gutterBottom>
                                            {process.env.REACT_APP_SERVER} - v{packageJson.version}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Drawer>
        </Box>
    );
};

const MenuRender: React.FC<{ id: number; render: boolean; path: string; icon?: string; title?: string }> = ({ id, render, path, icon, title }) => {
    const navigate = useNavigate();

    const { popup } = useSelector((state: AppState) => state.popupEvent);
    const dispatch: AppDispatch = useDispatch();
    const [popupBack, setPopupBack] = React.useState<boolean>(false);
    const [toComponent, setToComponent] = React.useState<string>(``);

    const handleMenuClick = (path: string) => () => {
        if (popup) {
            setToComponent(path);
            setPopupBack(true);
        } else {
            setToComponent(path);
            navigate(path);
            setPopupBack(false);
        }
    };

    const back = () => {
        setPopupBack(false);
        navigate(toComponent);
        dispatch(showPopup(false));
    };

    const handleClosePopup = () => {
        setPopupBack(false);
    };

    return (
        <>
            {render && (
                <>
                    <PopupBack open={popupBack} close={handleClosePopup} back={back} />
                    <ListItemButton key={`${path}-${id}`} onClick={handleMenuClick(`${path}`)}>
                        <ListItemIcon sx={menuIconW}>
                            <Icon sx={menuIcon}>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText sx={menuText} primary={title} />
                    </ListItemButton>
                    <Divider key={id} />
                </>
            )}
        </>
    );
};

const SubMenuRender: React.FC<{
    id: number;
    path: string;
    icon?: string;
    children: RoutesType | undefined;
    title?: string;
    main: boolean;
}> = ({ id, icon, title, children }) => {
    const [openSubMenu, setOpenSubMenu] = React.useState(false);
    const navigate = useNavigate();
    const { popup } = useSelector((state: AppState) => state.popupEvent);
    const dispatch: AppDispatch = useDispatch();
    const [popupBack, setPopupBack] = React.useState<boolean>(false);
    const [toComponent, setToComponent] = React.useState<string>('');

    const handleClickSubMenu = () => {
        setOpenSubMenu(!openSubMenu);
    };

    const handleMenuClick = (path: string) => () => {
        if (popup) {
            setToComponent(path);
            setPopupBack(true);
        } else {
            setToComponent(path);
            navigate(path);
            setPopupBack(false);
        }
    };

    const back = () => {
        setPopupBack(false);
        navigate(toComponent);
        dispatch(showPopup(false));
    };

    const handleClosePopup = () => {
        setPopupBack(false);
    };

    return (
        <>
            <PopupBack open={popupBack} close={handleClosePopup} back={back} />
            {children !== undefined && (
                <>
                    <ListItemButton key={id} onClick={handleClickSubMenu}>
                        <ListItemIcon sx={menuIconW}>
                            <Icon sx={menuIcon}>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText sx={menuText} primary={title} />
                        {openSubMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Divider hidden={openSubMenu} />
                </>
            )}
            <Collapse in={openSubMenu} timeout='auto' unmountOnExit>
                {children &&
                    children?.map((item: MenuRoute) => {
                        return (
                            item.render && (
                                <List key={item.id} disablePadding>
                                    <ListItemButton key={item.id} onClick={handleMenuClick(`${item.path}`)}>
                                        <ListItemIcon sx={menuIconW}>
                                            <Icon sx={menuIcon}>{item.icon}</Icon>
                                        </ListItemIcon>
                                        <ListItemText sx={menuText} primary={item.title} />
                                    </ListItemButton>
                                </List>
                            )
                        );
                    })}
                <Divider />
            </Collapse>
        </>
    );
};

export default memo(HeaderMenu);
