import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import CompanyServices from '../../services/companies/company';
import { IFetchArgs } from '../IFetch';
import { IArgsCompany, ICompany } from '../../infrastructure/DTO/company/company.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetCompanyById = createAsyncThunk<ICompany, IFetchArgs<IArgsCompany>>('company/get', async (params: IFetchArgs<IArgsCompany>) => {
    return await CompanyServices.getCompanyById(params);
});

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        company: {} as ICompany,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        ClearStateCompany: (state) => {
            state.company = {} as ICompany;
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetCompanyById.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetCompanyById.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.company = action.payload;
        });
        builder.addCase(fetchGetCompanyById.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { ClearStateCompany } = companySlice.actions;
export default companySlice;
