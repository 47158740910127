import {CSSObject} from "@mui/material";

const headerPopup = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
    minHeight: '35px'
})

const titlePopup = (): CSSObject => ({
    color: '#000000',
    fontSize: '1.4em',
    fontWeight: 500
})

const switchPopup = (): CSSObject => ({
    '& > span:nth-child(2)': {
        marginLeft: '15px'
    }
})

const bodyPopup = (): CSSObject => ({
    marginTop: '15px',
    marginBottom: '15px'
})

const iconDelete = (): CSSObject => ({
    color: 'red',
})

const iconEdit = (): CSSObject => ({
    color: '#388e3c'
})

const iconAdd = (): CSSObject => ({
    position: 'absolute',
    right: '30px',
    bottom: '15px'
})

const btn = (): CSSObject => ({
    marginRight: '15px',
})

const inputBtn = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
        width: '100%'
    }
})

const textWarn = (): CSSObject => ({
    padding: '15px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: 'red'
})

export const classes = {
    headerPopup,
    inputBtn,
    btn,
    iconAdd,
    textWarn,
    titlePopup,switchPopup,bodyPopup,iconDelete,iconEdit
}