import React, { useContext, useRef } from 'react';
import { classes } from './style';
import { Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import Konva from '../../../../../../common/ui-components/Konva';
import { Group, Image as Pic, Layer, Line } from 'react-konva';
import { DAMAGE_COLOR, DAMAGE_NAME } from '../../../../../../enums/Damage';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../../../store';
import { clearStatusDamageLab, fetchAnomaliesDetection } from '../../../../../../store/damage-lab/upload-img';
import { StatusRedux } from '../../../../../../enums/StatusRedux';
import { useSnackbar } from 'notistack';
import { SkeletonImg, SkeletonList } from '../../../../../../common/ui-components/Skeleton';
import { IThresholdContext, ThresholdContext } from '../../../../../../context/ThresholdContext';
import { CustomAuthContext } from '../../../../../../context/AuthProvider';
import { IAnomaliesDetectionRequest, IArgsDamageLab } from '../../../../../../infrastructure/DTO/damage-lab/anomalies-detection.dto';
import errorParseMessage from "../../../../../../config/error-parse";

type DamagesType = {
    id: number;
    damage_id: string;
    damage_status: string;
    damage_type: number;
    score: string;
    contours: Array<{ x: number; y: number }>;
};

interface AnomaliesComponent {
    load: boolean;
    group_results: boolean;
    combine_textured_anomalies: boolean;
}

const Anomalies: React.FC<AnomaliesComponent> = (props: AnomaliesComponent) => {
    const { token } = useContext(CustomAuthContext);
    const { handleShowThresholds, thresholds, reset } = React.useContext<IThresholdContext>(ThresholdContext);
    const dispatch: AppDispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [coordinates, setCoordinates] = React.useState<{ x: number; y: number }>({ x: 1, y: 1 });
    const { damageLabData, anomaliesDetection, status, error } = useSelector((state: AppState) => state.damageLabSingleImg);
    const refDiv = useRef<HTMLDivElement | null>(null);
    const { imageMain } = useSelector((state: AppState) => state.damageLabImage);
    const [size, setSize] = React.useState<{ width: number; height: number }>({ width: 1, height: 1 });
    const [damages, setDamages] = React.useState<Array<DamagesType>>([]);
    const [points, setPoints] = React.useState<Map<string, { id: string; pointsArr: Array<number>; color: string }>>(
        new Map<string, { id: string; pointsArr: Array<number>; color: string }>()
    );
    const [scale, setScale] = React.useState<number>(1);
    const [checked, setChecked] = React.useState<Array<string>>([]);

    React.useEffect(() => {
        if (refDiv.current !== null) {
            setSize({
                width: refDiv.current.clientWidth,
                height: refDiv.current.clientHeight,
            });

            if (!reset) {
                let scale = Math.min(refDiv.current.clientWidth / imageMain.width, refDiv.current.clientHeight / imageMain.height);
                const arrDamages: Array<DamagesType> = [];
                const obj = new Map();
                const newChecked = [...checked];

                if (damageLabData.detected_damages) {
                    damageLabData.detected_damages.forEach((i, index: number) => {
                        if (i.damage_status.code !== 'DELETED') {
                            newChecked.push(i.damage_id);

                            arrDamages.push({
                                id: index + 1,
                                damage_id: i.damage_id,
                                damage_status: i.damage_status.code,
                                damage_type: i.damage_type,
                                score: (i.segmentation.score * 100).toFixed(2),
                                contours: i.segmentation.contours[0],
                            });

                            let point: Array<number> = [];

                            i.segmentation.contours[0].forEach((p) => {
                                point.push(Number(p.x * scale), Number(p.y * scale));
                            });

                            obj.set(i.damage_id, {
                                id: i.damage_id,
                                pointsArr: point,
                                color: DAMAGE_COLOR[i.damage_type],
                            });
                        }
                    });
                }

                setChecked(newChecked);
                setDamages(arrDamages);
                setPoints(obj);
            }
        }

        handleShowThresholds(true);

        return () => {
            handleShowThresholds(false);
        };
    }, [reset]);

    React.useEffect(() => {
        if (props.load) {
            const args: IArgsDamageLab = {
                file_id: damageLabData.uploaded_file_id,
                inspection_case_id: damageLabData.inspection_case_id,
            };

            const body: IAnomaliesDetectionRequest = {
                ...thresholds,
                group_results: props.group_results,
                combine_textured_anomalies: props.combine_textured_anomalies,
            };

            dispatch(
                fetchAnomaliesDetection({
                    token,
                    body,
                    args,
                })
            );
        }
    }, [props.load]);

    const handleToggle = (value: DamagesType) => () => {
        let point: Array<number> = [];
        let scale = Math.min(size.width / imageMain.width, size.height / imageMain.height);

        value.contours.map((p) => {
            point.push(Number(p.x * scale), Number(p.y * scale));
        });

        if (points.has(value.damage_id)) {
            points.delete(value.damage_id);
        } else {
            setPoints(points.set(value.damage_id, { id: value.damage_id, pointsArr: point, color: DAMAGE_COLOR[value.damage_type] }));
        }

        const currentIndex = checked.indexOf(value.damage_id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value.damage_id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleMove = (e: any) => {
        setCoordinates({
            x: e.target.x(),
            y: e.target.y(),
        });
    };

    React.useEffect(() => {
        if (reset) {
            const args: IArgsDamageLab = {
                file_id: damageLabData.uploaded_file_id,
                inspection_case_id: damageLabData.inspection_case_id,
            };

            const body: IAnomaliesDetectionRequest = {
                ...thresholds,
                group_results: props.group_results,
                combine_textured_anomalies: props.combine_textured_anomalies,
            };

            dispatch(
                fetchAnomaliesDetection({
                    token,
                    args,
                    body,
                })
            );
        }
    }, [reset]);

    React.useEffect(() => {
        let scale = Math.min(size.width / imageMain.width, size.height / imageMain.height);
        setScale(scale);

        setCoordinates({
            x: size.width / 2 - (imageMain.width * scale) / 2,
            y: size.height / 2 - (imageMain.height * scale) / 2,
        });
    }, [size.width, size.height]);

    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            const arrDamages: Array<DamagesType> = [];

            enqueueSnackbar('Submitted Successfully', {
                variant: 'success',
            });

            if (anomaliesDetection.detected_damages) {
                anomaliesDetection.detected_damages
                    .filter((damage) => damage.damage_status.code !== 'DELETED')
                    .map((i, index: number) => {
                        arrDamages.push({
                            id: index + 1,
                            damage_id: i.damage_id,
                            damage_status: i.damage_status.code,
                            damage_type: i.damage_type,
                            score: (i.segmentation.score * 100).toFixed(2),
                            contours: i.segmentation.contours[0],
                        });
                    });
            }

            setDamages(arrDamages);
            dispatch(clearStatusDamageLab());
        }

        if (status === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(error), {
                variant: 'error',
            });

            dispatch(clearStatusDamageLab());
        }
    }, [status]);

    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            const obj = new Map();
            const newChecked = [...checked];

            if (anomaliesDetection.detected_damages) {
                anomaliesDetection.detected_damages.forEach((item) => {
                    let point: Array<number> = [];

                    item.segmentation.contours[0].forEach((p) => {
                        point.push(Number(p.x * scale), Number(p.y * scale));
                    });

                    obj.set(item.damage_id, { id: item.damage_id, pointsArr: point, color: DAMAGE_COLOR[item.damage_type] });
                    newChecked.push(item.damage_id);
                });
            }

            setPoints(obj);
            setChecked(newChecked);
        }
    }, [status, anomaliesDetection]);

    return (
        <>
            {status === StatusRedux.Loading ? (
                <Grid container spacing={5} direction='row'>
                    <Grid item xs={12} sm={12} md={6} lg={8} xl={9}>
                        <SkeletonImg width={'100%'} height={'650px'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SkeletonList checkbox={true} num={5} />
                    </Grid>
                </Grid>
            ) : (
                <Grid sx={classes.main} container spacing={5} direction='row'>
                    <Grid sx={classes.anim} item xs={12} sm={12} md={6} lg={8} xl={9}>
                        <Grid container spacing={5} direction='row'>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Konva wheel={true} konvaRef={refDiv} style={classes.stage} width={size.width} height={size.height}>
                                    <Layer>
                                        <Group x={coordinates.x} y={coordinates.y} draggable onDragMove={handleMove} onDragEnd={handleMove}>
                                            <Pic
                                                width={imageMain.width * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                                height={imageMain.height * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                                image={imageMain.image}
                                            />
                                            {Array.from(points.values()).map((line, i) => (
                                                <Group key={i}>
                                                    <Line
                                                        key={line.id}
                                                        points={line.pointsArr}
                                                        stroke={line.color}
                                                        strokeWidth={1.5}
                                                        closed={true}
                                                        lineCap='square'
                                                        globalCompositeOperation='source-over'
                                                    />
                                                </Group>
                                            ))}
                                        </Group>
                                    </Layer>
                                </Konva>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={classes.anim} item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <List
                            sx={classes.listMain}
                            subheader={
                                <ListSubheader sx={classes.listHeader} component='div'>
                                    Damages List
                                </ListSubheader>
                            }
                        >
                            {damages !== null &&
                                damages.map((value, index) => {
                                    const labelId = `checkbox-list-label-${value.damage_id}`;

                                    return (
                                        <div key={index}>
                                            <ListItem dense button onClick={handleToggle(value)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge='start'
                                                        checked={checked.indexOf(value.damage_id) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={`#${value.id} - ${DAMAGE_NAME[value.damage_type]}`} />
                                                <ListItemIcon>
                                                    <Chip
                                                        size='small'
                                                        label={`${value.score} %`}
                                                        style={{ color: '#fff', background: DAMAGE_COLOR[value.damage_type] }}
                                                    />
                                                </ListItemIcon>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    );
                                })}
                        </List>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default Anomalies;
