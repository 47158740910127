import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Inspection from '../../services/inspection/inspection';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs } from '../IFetch';
import { IStartAsyncProcessRequest, IStartAsyncProcessResponse } from '../../infrastructure/DTO/inspection/inspection.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchStartAsyncProcess = createAsyncThunk<IStartAsyncProcessResponse, IFetchArgs<IStartAsyncProcessRequest>>(
    'process/start',
    async (params: IFetchArgs<IStartAsyncProcessRequest>) => {
        return await Inspection.startAsyncProcess(params);
    }
);

export const processSlice = createSlice({
    name: 'process',
    initialState: {
        processData: {} as IStartAsyncProcessResponse,
        statusProcess: '',
        errorProcess: {} as IErrorResponse,
    },
    reducers: {
        clearProcess: (state) => {
            state.statusProcess = '';
            state.errorProcess = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStartAsyncProcess.pending, (state, action) => {
            state.statusProcess = StatusRedux.Loading;
        });
        builder.addCase(fetchStartAsyncProcess.fulfilled, (state, action) => {
            state.statusProcess = StatusRedux.Succeeded;
            state.processData = action.payload;
        });
        builder.addCase(fetchStartAsyncProcess.rejected, (state, action) => {
            state.statusProcess = StatusRedux.Failed;
            state.errorProcess = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearProcess } = processSlice.actions;
export default processSlice;
