import { ServicesBase } from '../ServicesBase';
import { IFetchArgs } from '../../store/IFetch';
import { IInspectionCaseId } from '../../infrastructure/DTO/inspection/inspection.dto';

class ImagesOfInspection extends ServicesBase {
    static async getImages(params: IFetchArgs<IInspectionCaseId>): Promise<any> {
        return await this.services('GET', `/home/inspections/${params.args.inspection_case}/images`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }
}

export default ImagesOfInspection;
