import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import { classes } from './style';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Group, Image as Pic, Layer, Rect, Text } from 'react-konva';
import Konva from '../../../../../../common/ui-components/Konva';
import Box from '@mui/material/Box';

const KeyPointsDetection: React.FC = () => {
    const [hoverPoint, setHoverPoint] = React.useState('0');
    const [points, setPoints] = React.useState<Array<{ x: number; y: number; id: string }>>([]);
    const [coordinates, setCoordinates] = React.useState({ x: 1, y: 1 });
    const refDiv = useRef<HTMLDivElement | null>(null);
    const [size, setSize] = React.useState<{ width: number; height: number }>({ width: 1, height: 1 });
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);
    const { imageMain } = useSelector((state: AppState) => state.damageLabImage);

    React.useEffect(() => {
        if (refDiv.current !== null) {
            setSize({
                width: refDiv.current.clientWidth,
                height: refDiv.current.clientHeight,
            });
        }
    }, []);

    React.useEffect(() => {
        let scale = Math.min(size.width / imageMain.width, size.height / imageMain.height);
        const points: Array<{ x: number; y: number; id: string }> = [];

        setCoordinates({
            x: size.width / 2 - (imageMain.width * scale) / 2,
            y: size.height / 2 - (imageMain.height * scale) / 2,
        });

        if (damageLabData.key_points_detection !== null) {
            damageLabData.key_points_detection.forEach((point) => {
                points.push({ x: Math.floor(point.position.x * scale), y: Math.floor(point.position.y * scale), id: point.key_point_id });
            });
        }

        setPoints(points);
    }, [size.width, size.height]);

    const handleHoverTableRow = (id: any) => () => {
        setHoverPoint(id);
    };

    const handleOutTableRow = (id: any) => () => {
        setHoverPoint('0');
    };

    const handleMove = (e: any) => {
        setCoordinates({
            x: e.target.x(),
            y: e.target.y(),
        });
    };

    return (
        <>
            {damageLabData.key_points_detection !== null ? (
                <Grid sx={classes.anim} container spacing={5} direction='row'>
                    <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
                        <Konva wheel={true} konvaRef={refDiv} style={classes.stage} width={size.width} height={size.height}>
                            <Layer>
                                <Group x={coordinates.x} y={coordinates.y} draggable onDragMove={handleMove} onDragEnd={handleMove}>
                                    <Pic
                                        width={imageMain.width * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                        height={imageMain.height * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                        image={imageMain.image}
                                    />
                                    {points.map((line, i) =>
                                        String(hoverPoint) === line.id ? (
                                            <Group key={i}>
                                                <Rect width={20} height={20} fill='rgba(255,0,0,0.9)' x={line.x - 10} y={line.y - 10} />
                                                <Text
                                                    width={20}
                                                    height={20}
                                                    fill='#fff'
                                                    x={line.x - 10}
                                                    y={line.y + 5 - 10}
                                                    align='center'
                                                    fontSize={10}
                                                    text={line.id}
                                                />
                                            </Group>
                                        ) : (
                                            <Group key={i}>
                                                <Rect width={17} height={17} fill='rgba(0,0,0,0.9)' x={line.x - 8.5} y={line.y - 8.5} />
                                                <Text
                                                    width={17}
                                                    height={17}
                                                    fill='#fff'
                                                    x={line.x - 8.5}
                                                    y={line.y + 3.5 - 8.5}
                                                    align='center'
                                                    fontSize={10}
                                                    text={line.id}
                                                />
                                            </Group>
                                        )
                                    )}
                                </Group>
                            </Layer>
                        </Konva>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                        <TableContainer sx={classes.table}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={classes.head} align='center'>
                                            TrainingClassID
                                        </TableCell>
                                        <TableCell sx={classes.head} align='center'>
                                            Name
                                        </TableCell>
                                        <TableCell sx={classes.head} align='center'>
                                            Score
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {damageLabData.key_points_detection.map((row, index: number) => (
                                        <TableRow
                                            hover
                                            onMouseOver={handleHoverTableRow(row.key_point_id)}
                                            onMouseOut={handleOutTableRow(row.key_point_id)}
                                            sx={classes.tableRow}
                                            key={index + 1}
                                        >
                                            <TableCell align='center'>{row.key_point_id}</TableCell>
                                            <TableCell align='center'>{row.key_point_name}</TableCell>
                                            <TableCell align='center'>{row.score}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            ) : (
                <Box sx={classes.emptyDiv}>Not Found</Box>
            )}
        </>
    );
};

export default KeyPointsDetection;
