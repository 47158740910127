import React, { useContext } from 'react';
import { Autocomplete } from '@mui/material';
import { IArgsProfile, IProfile } from '../../infrastructure/DTO/profile/profile.dto';
import TextField from '@mui/material/TextField';
import { IProfileSelect } from '../interface/IProfileSelect';
import { useSnackbar } from 'notistack';
import { CustomAuthContext } from '../../context/AuthProvider';
import { AppDispatch, AppState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { StatusRedux } from '../../enums/StatusRedux';
import { fetchGetProfiles } from '../../store/profile/profiles';

const ProfileSelect: React.FC<IProfileSelect> = (props: IProfileSelect) => {
    const { enqueueSnackbar } = useSnackbar();
    const { token } = useContext(CustomAuthContext);
    const dispatch: AppDispatch = useDispatch();
    const profilesState = useSelector((state: AppState) => state.profiles);
    const [profiles, setProfiles] = React.useState<Array<IProfile>>([]);

    /**
     * Set company Id and get Profiles
     * @param company
     * @type ICompany
     */
    React.useEffect(() => {
        if (props.company_id) {
            const args: IArgsProfile = {
                company_id: props.company_id,
            };

            dispatch(fetchGetProfiles({ token, args }));
        }
    }, [props.company_id]);

    /**
     * Get Profiles
     */
    React.useEffect(() => {
        if (profilesState.status === StatusRedux.Succeeded) {
            setProfiles(profilesState.profiles.profiles);
        }

        if (profilesState.status === StatusRedux.Failed) {
            enqueueSnackbar(profilesState.error.errors[0].message, {
                variant: 'error',
            });
        }
    }, [profilesState.status]);

    return (
        <Autocomplete
            size={props.size ?? 'medium'}
            disabled={props.disabled}
            value={props.data}
            disablePortal
            autoHighlight
            fullWidth
            onChange={(event: any, profile: IProfile | null) => {
                if (profile !== null) {
                    props.setData(profile);
                } else {
                    props.setData(null);
                }
            }}
            id='profiles'
            isOptionEqualToValue={(option, value) => option.name === value.name}
            options={profiles.length !== 0 ? profiles.filter((p) => p.is_active) : []}
            getOptionLabel={(option: IProfile) => (option.name ? option.name : '')}
            renderInput={(params) => <TextField {...params} label='Profiles' variant={props.variant ? props.variant : 'standard'} />}
        />
    );
};

export default ProfileSelect;
