import React, { useContext } from 'react';
import { Group, Layer } from 'react-konva';
import { FormControlLabel, IconButton, ListItem, ListItemAvatar, ListItemButton, Slider, Switch } from '@mui/material';
import { classes } from './style';
import Box from '@mui/material/Box';
import { useFullscreen } from '../../../../../hooks/use-fullscreen';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Konva as KonvaComponent } from '../../../../../common/ui-components';
import { ButtonPanel } from './button-panel';
import { DamageLabMenu } from './damagelab-menu';
import { MenuEnum, MenuType } from './damagelab-menu/menu';
import { PrincipalCarLayout } from './pincipal-car';
import { SegmentCarLayout } from './segment-car';
import { KeyPointsLayout } from './key-points-layout';
import { PartsLayout, PartsList } from './parts-layout';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { ImageLayout } from './image-layout';
import { ImagesList } from './images-list';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Spinner } from '../../../../spinner';
import { MeasurementLayout } from './measurement-layout';
import MeasurementList from './measurement-layout/measurement-list';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import CollectionsIcon from '@mui/icons-material/Collections';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DamageLabLayoutContext, { IDamageLabLayoutContext } from '../../../../../context/DamageLabLayoutContext';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../../store';
import { IArgsImagesOverView, IImageOverView } from '../../../../../infrastructure/DTO/multilayered/multilayered.dto';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { clearStoreImageOverView, fetchGetImageOverView } from '../../../../../store/multilayered/image-overview';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { ThreeDLayout } from './three-d-layout';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import MeasurementPopupInfo from './measurement-layout/measurement-popup-info';
import { SkeletonImg } from '../../../../../common/ui-components/Skeleton';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import { useTheme } from '@mui/material/styles';
import errorParseMessage from '../../../../../config/error-parse';
import Tooltip from '@mui/material/Tooltip';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const DamageLabLayout: React.FC = () => {
    const {
        refDiv,
        imageInfo,
        stageRef,
        image,
        images,
        coordinates,
        canvasSize,
        menu,
        drawerPanel,
        loading,
        panelViewLayout,
        opacitySliderThreeD,
        showMeasurementInfo,
        handleMeasurementInfo,
        setImage,
        setImages,
        setLoading,
        setMenu,
        setCoordinates,
        setScale,
        setCanvasSize,
        setDrawerPanel,
        setPanelViewLayout,
        setOpacitySliderThreeD,
        setParts,
        setSelectedPart,
        setCurrentPart,
    } = React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);
    const theme = useTheme();
    const [params] = useSearchParams();
    const inspection_id: string = params.has('inspection_id') ? (params.get('inspection_id') as string) : '';
    const image_id: string = params.has('image_id') ? (params.get('image_id') as string) : '';
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { fullscreenRef, enterFullscreen, exitFullscreen, fullscreenActive } = useFullscreen();
    const { status, error, imageOverView } = useSelector((state: AppState) => state.imageOverView);
    const { imagesOverView } = useSelector((state: AppState) => state.imagesOverView);
    const { token } = useContext(CustomAuthContext);

    React.useEffect(() => {
        if (inspection_id && image_id) {
            const args: IArgsImagesOverView = {
                inspection_case: inspection_id as string,
                image_id: image_id as string,
            };

            dispatch(
                fetchGetImageOverView({
                    token,
                    args,
                })
            );
        }
    }, [inspection_id, image_id]);

    React.useEffect(() => {
        if (status === StatusRedux.Loading) {
            setLoading(true);
        }
        if (status === StatusRedux.Succeeded) {
            let data = imagesOverView.images.find((i) => i.image_id === (image_id as string)) as IImageOverView;

            setImage(data);
            setImages(imagesOverView.images);
            setLoading(false);
        }
        if (status === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(error), {
                variant: 'error',
                autoHideDuration: 5000,
            });
            setLoading(false);
        }
    }, [status]);

    React.useEffect(() => {
        if (image_id) {
            reset();
        }
    }, [image_id]);

    const handleChangeMainImage = (id: string) => {
        dispatch(clearStoreImageOverView());

        const args: IArgsImagesOverView = {
            inspection_case: inspection_id,
            image_id: id,
        };

        dispatch(
            fetchGetImageOverView({
                token,
                args,
            })
        );

        navigate(`/home/drill-down?inspection_id=${inspection_id}&image_id=${id}`);

        setLoading(true);
        const findImage = images?.find((pic) => pic.image_id == id) as IImageOverView;
        setImage(findImage);
        reset();
    };

    const reset = () => {
        setMenu(
            menu.map((m) => {
                return { ...m, checked: false };
            })
        );
        setParts([]);
        setCurrentPart([]);
        setSelectedPart(null);
        setDrawerPanel({ ...drawerPanel, left: false, bottom: false });
        let scale = Math.min(refDiv.current.clientWidth / imageInfo.width, refDiv.current.clientHeight / imageInfo.height);
        setScale(scale);
        handleMeasurementInfo(false);
    };

    const handleMove = (e: any) => {
        setCoordinates({
            x: e.target.x(),
            y: e.target.y(),
        });
    };

    React.useEffect(() => {
        if (refDiv.current !== null) {
            setCanvasSize({
                width: refDiv.current.clientWidth,
                height: refDiv.current.clientHeight,
            });
        }
    }, [refDiv]);

    React.useEffect(() => {
        if (!viewLayout(MenuEnum.Damages) && panelViewLayout === MenuEnum.Damages) {
            handleMeasurementInfo(false);
            setDrawerPanel({ ...drawerPanel, left: false });
        }
        if (!viewLayout(MenuEnum.Parts) && panelViewLayout === MenuEnum.Parts) {
            setDrawerPanel({ ...drawerPanel, left: false });
        }
    }, [menu]);

    const handleSwitchMenu = (event: any) => {
        const index = menu.findIndex((m) => m.value === event.target.value);

        if (index !== -1) {
            let arr: Array<MenuType> = [
                ...menu.slice(0, index),
                {
                    ...menu[index],
                    checked: event.target.checked,
                },
                ...menu.slice(index + 1),
            ];
            setMenu(arr);
        }
    };

    const viewLayout = (value: MenuEnum): boolean => {
        return menu.find((m) => m.value === value)?.checked as boolean;
    };

    const downloadURI = (uri: any, name: any) => {
        let link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownloadPrintScreen = () => {
        if (stageRef !== null) {
            const uri = stageRef.current?.toDataURL();
            downloadURI(uri, `${inspection_id}-${image_id}.png`);
        }
    };

    const handleDownloadOriginalImage = async () => {
        if (image?.link) {
            const dwImage = await fetch(image.link);
            const imageBlob = await dwImage.blob();
            const imageURL = URL.createObjectURL(imageBlob);
            downloadURI(imageURL, image.name);
        }
    };

    const toggleDrawerPanel = (anchor: 'left' | 'top' | 'right' | 'bottom', open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.currentTarget.id) {
            setPanelViewLayout(event.currentTarget.id);
            setDrawerPanel({ ...drawerPanel, [anchor]: true });
        } else {
            setDrawerPanel({ ...drawerPanel, [anchor]: open });
        }

        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
    };

    return (
        <Box sx={classes.mainBox}>
            <Box ref={fullscreenRef} sx={classes.stage(fullscreenActive ? '0px' : '115px')}>
                <DamageLabMenu
                    iconClose={<ChevronRightIcon />}
                    drawerWidth={'260px'}
                    titleHeader={'Menu Layouts'}
                    anchor={'right'}
                    open={!drawerPanel.right}
                    toggleDrawer={() => toggleDrawerPanel('right', !drawerPanel.right)}
                >
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            <List dense={true}>
                                {menu.map((menu, index) => (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            menu.checked &&
                                            menu.icon && (
                                                <Tooltip title={menu?.tooltipText && menu?.tooltipText} placement='left'>
                                                    <IconButton
                                                        onClick={toggleDrawerPanel('left', !drawerPanel.left)}
                                                        edge='end'
                                                        aria-label='shoe list'
                                                        id={menu.name}
                                                    >
                                                        {<menu.icon color={'primary'}></menu.icon>}
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        }
                                    >
                                        <ListItemAvatar onClick={handleSwitchMenu} key={menu.id}>
                                            <FormControlLabel
                                                value={menu.value}
                                                control={<Switch disabled={!(status === StatusRedux.Succeeded)} checked={menu.checked} />}
                                                label={menu.name}
                                            />
                                        </ListItemAvatar>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Box>
                            {viewLayout(MenuEnum.Projection3D) && (
                                <Box sx={{ padding: '0 20px' }}>
                                    <Typography id='opacity-slider' gutterBottom>
                                        Opacity 3D Projection
                                    </Typography>
                                    <Slider
                                        aria-label='Opacity'
                                        value={opacitySliderThreeD}
                                        valueLabelDisplay='auto'
                                        onChange={setOpacitySliderThreeD}
                                        step={0.1}
                                        min={0}
                                        max={1}
                                    />
                                </Box>
                            )}
                            <Divider />
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={handleDownloadPrintScreen}>
                                        <ListItemIcon sx={classes.listIcon}>
                                            <ScreenshotMonitorIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Download Print Screen' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={toggleDrawerPanel('bottom', !drawerPanel.bottom)}>
                                        <ListItemIcon sx={classes.listIcon}>
                                            <CollectionsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Images' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={fullscreenActive ? exitFullscreen : enterFullscreen}>
                                        <ListItemIcon sx={classes.listIcon}>
                                            {fullscreenActive ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary='Full Screen' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={handleDownloadOriginalImage}>
                                        <ListItemIcon sx={classes.listIcon}>
                                            <SaveAltIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Download Original Image' />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                </DamageLabMenu>
                <DamageLabMenu
                    drawerWidth={'325px'}
                    iconClose={<ChevronLeftIcon />}
                    titleHeader={panelViewLayout}
                    anchor={'left'}
                    open={drawerPanel.left}
                    toggleDrawer={() => toggleDrawerPanel('left', !drawerPanel.left)}
                >
                    <Box p={1}>
                        <PartsList view={viewLayout(MenuEnum.Parts)} fullscreenActive={fullscreenActive} />
                        <MeasurementList view={viewLayout(MenuEnum.Damages)} fullscreenActive={fullscreenActive} />
                    </Box>
                </DamageLabMenu>
                <DamageLabMenu
                    drawerWidth={'100%'}
                    iconClose={<CloseIcon />}
                    anchor={'bottom'}
                    open={drawerPanel.bottom}
                    toggleDrawer={() => toggleDrawerPanel('bottom', !drawerPanel.bottom)}
                >
                    <Box sx={{ padding: '0 15px', overflow: 'hidden', width: '100%' }}>
                        <Swiper
                            style={{ padding: '0 0 10px 0' }}
                            pagination={{
                                clickable: true,
                            }}
                            spaceBetween={50}
                            modules={[Pagination]}
                            slidesPerView={5}
                        >
                            {images.length > 0 &&
                                images.map((pic: any, index: number) => (
                                    <SwiperSlide key={index}>
                                        <ImagesList
                                            handleChangeMainImage={handleChangeMainImage}
                                            id={pic.image_id}
                                            camera_view={pic.camera_view}
                                            link={pic.link}
                                            current={image_id as string}
                                        />
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    </Box>
                </DamageLabMenu>
                <ButtonPanel
                    fullscreenActive={fullscreenActive}
                    exitFullscreen={exitFullscreen}
                    enterFullscreen={enterFullscreen}
                    panelHandleRight={toggleDrawerPanel('right', !drawerPanel.right)}
                    panelHandleLeft={toggleDrawerPanel('left', !drawerPanel.left)}
                    panelHandleBottom={toggleDrawerPanel('bottom', !drawerPanel.bottom)}
                    downloadPrintScreen={handleDownloadPrintScreen}
                    downloadOriginalImage={handleDownloadOriginalImage}
                    isDisabled={viewLayout}
                />
                <Box component='main' sx={{ flexGrow: 1 }}>
                    {loading && status !== StatusRedux.Succeeded && (
                        <Spinner
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                margin: 'auto',
                                zIndex: 999,
                                height: '100%',
                            }}
                        />
                    )}
                    {!image?.image_id && <SkeletonImg width={'80%'} height={'100vh'} />}
                    <KonvaComponent
                        resetZoom={fullscreenActive}
                        wheel={true}
                        konvaRef={refDiv}
                        style={classes.stage(fullscreenActive ? '0px' : '115px')}
                        width={canvasSize.width}
                        height={canvasSize.height}
                    >
                        <Layer ref={stageRef}>
                            <Group x={coordinates.x} y={coordinates.y} draggable onDragMove={handleMove} onDragEnd={handleMove}>
                                <ImageLayout data={image} fullscreenActive={fullscreenActive} />
                                <ThreeDLayout view={viewLayout(MenuEnum.Projection3D)} />
                                <PartsLayout view={viewLayout(MenuEnum.Parts)} />
                                <PrincipalCarLayout view={viewLayout(MenuEnum.PrincipalCar)} />
                                <SegmentCarLayout view={viewLayout(MenuEnum.SegmentCar)} />
                                <MeasurementLayout view={viewLayout(MenuEnum.Damages)} />
                                <KeyPointsLayout view={viewLayout(MenuEnum.KeyPoints)} />
                            </Group>
                        </Layer>
                    </KonvaComponent>
                </Box>
            </Box>

            {showMeasurementInfo && (
                <Draggable handle='span' bounds='parent'>
                    <Resizable
                        defaultSize={{
                            width: 500,
                            height: 650,
                        }}
                        minWidth={500}
                        minHeight={450}
                        maxWidth={1200}
                        maxHeight={800}
                        resizeRatio={1}
                        style={{
                            backdropFilter: 'blur(10px)',
                            background: theme.palette.mode === 'light' ? 'rgb(255 255 255 / 70%)' : 'rgb(0 0 0 / 70%)',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            margin: 'auto',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                            zIndex: 9999,
                        }}
                    >
                        <Box p={1}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span style={{ cursor: 'move', padding: '5px', width: '100%', display: 'block' }}>Measurement</span>
                                <div style={{ cursor: 'pointer' }}>
                                    <CloseIcon onClick={() => handleMeasurementInfo(false)} />
                                </div>
                            </Box>
                            <MeasurementPopupInfo />
                        </Box>
                    </Resizable>
                </Draggable>
            )}
        </Box>
    );
};

export default DamageLabLayout;
