import CarCrashIcon from '@mui/icons-material/CarCrash';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';

export type MenuType = {
    id: string;
    name: MenuEnum;
    value: string;
    checked: boolean;
    icon: OverridableComponent<SvgIconTypeMap> | null;
    tooltipText: string | undefined;
};

export enum MenuEnum {
    PrincipalCar = 'PrincipalCar',
    SegmentCar = 'SegmentCar',
    KeyPoints = 'KeyPoints',
    Parts = 'Parts',
    Projection3D = 'Projection3D',
    GroupByTexture = 'GroupByTexture',
    GroupedAnomalies = 'GroupedAnomalies',
    Anomalies = 'Anomalies',
    Damages = 'Damages',
}

export default [
    {
        id: '1',
        name: 'Principal Car',
        value: MenuEnum.PrincipalCar,
        checked: false,
    },
    {
        id: '2',
        name: 'Segment Car',
        value: MenuEnum.SegmentCar,
        checked: false,
    },
    {
        id: '3',
        name: 'Key Points',
        value: MenuEnum.KeyPoints,
        checked: false,
    },
    {
        id: '4',
        name: 'Parts',
        value: MenuEnum.Parts,
        checked: false,
        icon: DirectionsCarIcon,
        tooltipText: 'Show list of Parts',
    },
    {
        id: '5',
        name: '3D Projection',
        value: MenuEnum.Projection3D,
        checked: false,
    },
    {
        id: '6',
        name: 'Damages',
        value: MenuEnum.Damages,
        checked: false,
        icon: CarCrashIcon,
        tooltipText: 'Show list of Damages',
    },
] as Array<MenuType>;
