import * as React from 'react';
import { classes } from './style';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { Spinner } from '../../../spinner';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import { Error } from '../../../error';
import TablePagination from '@mui/material/TablePagination';
import { AppDispatch, AppState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { clearStoreBusinessUnits, fetchGetBusinessUnits } from '../../../../store/business-units/business-units';
import { AddBusinessUnits } from './add-business-units';
import { EditBusinessUnits } from './edit-business-units';
import { useContext } from 'react';
import { Box, Link, Paper, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { UserPermission } from '../../../../config';
import CreateIcon from '@mui/icons-material/Create';
import { clearStoreStatusGetById } from '../../../../store/business-units/business-unit';
import { fetchLanguage } from '../../../../store/language/language';
import { IArgsBusinessUnit, IBusinessUnit } from '../../../../infrastructure/DTO/bussiness-unit/business-units.dto';
import { IUserManagementProvider, UserManagementContext } from '../../../../context/UserManagementProvider';
import errorParseMessage from '../../../../config/error-parse';

const BusinessUnits: React.FC = () => {
    const { checkPermission, token } = React.useContext(CustomAuthContext);
    const { page, limit, searchMode, searchText, company, changePage, changeRowsPerPage, handleEditBusinessUnit, handleCreateBusinessUnits } =
        useContext<IUserManagementProvider>(UserManagementContext);
    const [countBusinessUnits, setCountBusinessUnits] = React.useState<number>(0);
    const dispatch: AppDispatch = useDispatch();
    const { businessUnits, status, error } = useSelector((state: AppState) => state.businessUnits);
    const businessUnitById = useSelector((state: AppState) => state.businessUnit);
    const [dataByBusinessUnitId, setDataByBusinessUnitId] = React.useState<IBusinessUnit | null>(null);

    React.useEffect(() => {
        dispatch(fetchLanguage({ token: token, args: null }));
        return () => {
            dispatch(clearStoreBusinessUnits());
        };
    }, []);

    /**
     * Set count business units
     */
    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            setCountBusinessUnits(businessUnits.count);
        }
    }, [status]);

    /**
     * Update count business units
     */
    React.useEffect(() => {
        if (businessUnits.count) {
            setCountBusinessUnits(businessUnits.count);
        }
    }, [businessUnits.count]);

    /**
     * Get business unit By Id
     * after get query params from url
     * OpenEdit Popup
     */
    React.useEffect(() => {
        if (businessUnitById.status === StatusRedux.Succeeded) {
            setDataByBusinessUnitId(businessUnitById.businessUnit);
            handleEditBusinessUnit();
            dispatch(clearStoreStatusGetById());
        }
    }, [businessUnitById.status]);

    React.useEffect(() => {
        if (company) {
            let args: IArgsBusinessUnit = {
                company_id: company?.company_id as string,
                limit: limit,
                search_text: searchText,
                offset: page + 1,
            };
            if (searchMode) {
                dispatch(
                    fetchGetBusinessUnits({
                        token,
                        args,
                    })
                );
            } else {
                if (searchText.length === 0) {
                    args = { ...args, search_text: '' };
                    dispatch(
                        fetchGetBusinessUnits({
                            token,
                            args,
                        })
                    );
                }
            }
        }
    }, [company, searchMode, searchText, limit, page]);

    /**
     * @type IBusinessUnit
     * @param data
     * Open Popup and set Business Unit to update
     */
    const handlePopupEdit = (data: IBusinessUnit) => () => {
        setDataByBusinessUnitId(data);
        handleEditBusinessUnit();
    };

    return (
        <>
            <EditBusinessUnits company_id={company?.company_id as string} company_name={company?.name as string} data={dataByBusinessUnitId} />
            <AddBusinessUnits count={countBusinessUnits} setCountBusinessUnits={setCountBusinessUnits} />
            <Paper elevation={2} sx={classes.main}>
                {(() => {
                    switch (status) {
                        case StatusRedux.Loading:
                            return <Spinner style={{ height: 'calc(100vh - 235px)' }} />;
                        case StatusRedux.Succeeded:
                            if (businessUnits.business_units?.length > 0) {
                                return (
                                    <TableContainer sx={classes.container}>
                                        <Table stickyHeader aria-label='sticky table'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={classes.cellCnt} key={0} align='left'>
                                                        #
                                                    </TableCell>
                                                    <TableCell sx={classes.cellId} key={1} align='left'>
                                                        Business Unit ID
                                                    </TableCell>
                                                    <TableCell sx={classes.cellShort} key={2} align='left'>
                                                        Name
                                                    </TableCell>
                                                    <TableCell sx={classes.cellShort} key={3} align='left'>
                                                        Company
                                                    </TableCell>
                                                    <TableCell sx={classes.cellShort} key={4} align='left'>
                                                        Profile
                                                    </TableCell>
                                                    <TableCell sx={classes.cellShort} key={5} align='left'>
                                                        Location
                                                    </TableCell>
                                                    <TableCell sx={classes.cellShort} key={6} align='left'>
                                                        Language
                                                    </TableCell>
                                                    <TableCell sx={classes.cellShort} key={7} align='left'>
                                                        Date Format
                                                    </TableCell>
                                                    <TableCell sx={classes.cellShort} key={8} align='left'>
                                                        Status
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {businessUnits.business_units.length > 0 &&
                                                businessUnits.business_units.map((data, index) => (
                                                    <TableBody key={index}>
                                                        <TableRow hover role='checkbox' tabIndex={-1}>
                                                            <TableCell onClick={handlePopupEdit(data)} sx={classes.cellCnt}>
                                                                {limit * page + (index + 1)}
                                                            </TableCell>
                                                            <TableCell
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(data.business_unit_id as string).then((r) => r);
                                                                }}
                                                                sx={classes.cellId}
                                                            >
                                                                <Tooltip arrow disableFocusListener title={<Box>Click To Copy</Box>}>
                                                                    <Link color='inherit' href='#'>
                                                                        {data.business_unit_id}
                                                                    </Link>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell onClick={handlePopupEdit(data)} sx={classes.cellLong}>
                                                                {data.name}
                                                            </TableCell>
                                                            <TableCell onClick={handlePopupEdit(data)} sx={classes.cellLong}>
                                                                {company?.name}
                                                            </TableCell>
                                                            <TableCell onClick={handlePopupEdit(data)} sx={classes.cellLong}>
                                                                {data.profile?.name}
                                                            </TableCell>
                                                            <TableCell onClick={handlePopupEdit(data)} sx={classes.cellLong}>
                                                                {data.location}
                                                            </TableCell>
                                                            <TableCell onClick={handlePopupEdit(data)} sx={classes.cellShort}>
                                                                {data.language?.toLocaleUpperCase()}
                                                            </TableCell>
                                                            <TableCell onClick={handlePopupEdit(data)} sx={classes.cellShort}>
                                                                {data.date_format}
                                                            </TableCell>
                                                            <TableCell onClick={handlePopupEdit(data)} sx={classes.cellShort}>
                                                                {data.is_active ? (
                                                                    <Chip label='Enabled' variant='outlined' color='primary' />
                                                                ) : (
                                                                    <Chip
                                                                        label='Disabled'
                                                                        variant='outlined'
                                                                        style={{ color: 'red', borderColor: 'red' }}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                ))}
                                        </Table>
                                    </TableContainer>
                                );
                            } else {
                                return (
                                    <Box sx={classes.containerMain}>
                                        <Box mb={1}>
                                            <Typography
                                                variant='subtitle1'
                                                component='div'
                                            >{`Business Unit "${searchText}" in the company "${company?.name}" not found.`}</Typography>
                                        </Box>
                                        <Button
                                            disabled={checkPermission(UserPermission.USER_MANAGEMENT)}
                                            onClick={handleCreateBusinessUnits}
                                            variant='outlined'
                                            color='secondary'
                                            endIcon={<CreateIcon />}
                                        >
                                            Create Business Unit
                                        </Button>
                                    </Box>
                                );
                            }
                        case StatusRedux.Failed:
                            return (
                                <Box sx={classes.container}>
                                    <Error msg={errorParseMessage(error)} />
                                </Box>
                            );
                    }
                })()}
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component='div'
                    count={countBusinessUnits}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={changePage}
                    onRowsPerPageChange={changeRowsPerPage}
                />
            </Paper>
        </>
    );
};

export default BusinessUnits;
