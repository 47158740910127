import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import InspectionTypes from '../../services/inspection/inspection-types';
import { IFeaturesList } from '../../infrastructure/DTO/inspection/feature.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const inspectionTypeList = createAsyncThunk('inspections/type', async () => {
    return await InspectionTypes.getInspectionTypes();
});

export const inspectionTypeSlice = createSlice({
    name: 'inspections',
    initialState: {
        inspectionListType: [] as Array<IFeaturesList>,
        statusType: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        clearStore: (state) => {
            state.statusType = '';
            state.error = {} as IErrorResponse;
            state.inspectionListType = [] as Array<IFeaturesList>;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(inspectionTypeList.pending, (state) => {
            state.statusType = StatusRedux.Loading;
        });
        builder.addCase(inspectionTypeList.fulfilled, (state, action) => {
            state.statusType = StatusRedux.Succeeded;
            state.inspectionListType = action.payload;
        });
        builder.addCase(inspectionTypeList.rejected, (state, action) => {
            state.statusType = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStore } = inspectionTypeSlice.actions;
export default inspectionTypeSlice;
