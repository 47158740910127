import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Inspection from '../../services/inspection/inspection';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs, IFetchBody } from '../IFetch';
import {
    IImagesRequest,
    IImagesResponse,
    IInspectionCaseId,
    IInspectionImagesResponse,
    IInspectionRemoveImage,
    IInspectionUploadImage,
} from '../../infrastructure/DTO/inspection/inspection.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchUploadImagesInspection = createAsyncThunk<IInspectionImagesResponse, IFetchBody<IInspectionUploadImage, IInspectionCaseId>>(
    'inspection/images',
    async (params: IFetchBody<IInspectionUploadImage, IInspectionCaseId>) => {
        return await Inspection.uploadImages(params);
    }
);

export const fetchRemoveImageInspection = createAsyncThunk<any, IFetchArgs<IInspectionRemoveImage>>(
    'inspection/remove_image',
    async (params: IFetchArgs<IInspectionRemoveImage>) => {
        return await Inspection.removeImage(params);
    }
);

export const inspectionUploadImagesSlice = createSlice({
    name: 'inspection',
    initialState: {
        inspectionImagesData: [] as Array<IImagesResponse>,
        inspectionImagesDataRequest: [] as Array<IImagesRequest>,
        statusImagesData: '',
        statusUpdate: '',
        errorImagesData: {} as IErrorResponse,
        errorImagesDataMsg: {} as IErrorResponse,
        statusRemoveImage: 'init',
        errorRemoveImage: {} as IErrorResponse,
    },
    reducers: {
        clearStoreImages: (state) => {
            state.inspectionImagesData = [] as Array<IImagesResponse>;
            state.inspectionImagesDataRequest = [] as Array<IImagesRequest>;
        },
        clearStatus: (state) => {
            state.statusImagesData = '';
            state.errorImagesData = {} as IErrorResponse;
            state.errorImagesDataMsg = {} as IErrorResponse;
            state.statusUpdate = '';
        },
        clearStatusImgRemove: (state) => {
            state.statusRemoveImage = '';
            state.errorRemoveImage = {} as IErrorResponse;
        },
        updateImage: (state, action) => {
            const index = state.inspectionImagesData.findIndex((x) => x.image_id === action.payload.image_id);
            state.inspectionImagesData = [
                ...state.inspectionImagesData.slice(0, index),
                action.payload,
                ...state.inspectionImagesData.slice(index + 1),
            ];
            state.statusUpdate = StatusRedux.Succeeded;
        },
        removeDamageFromImg: (state, action) => {
            const index: number = action.payload.index;
            state.inspectionImagesData = [
                ...state.inspectionImagesData.slice(0, index),
                { ...state.inspectionImagesData[index], detected_damages: action.payload.damages },
                ...state.inspectionImagesData.slice(index + 1),
            ];
        },
        setImagesRequest: (state, action) => {
            state.inspectionImagesDataRequest.push(action.payload.images[0]);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUploadImagesInspection.pending, (state) => {
            state.statusImagesData = StatusRedux.Loading;
        });
        builder.addCase(fetchUploadImagesInspection.fulfilled, (state, action) => {
            if (action.payload.images === undefined) {
                state.statusImagesData = StatusRedux.Failed;
            } else {
                state.statusImagesData = StatusRedux.Succeeded;
                state.inspectionImagesData.push(action.payload.images[0]);
            }
        });
        builder.addCase(fetchUploadImagesInspection.rejected, (state, action) => {
            state.statusImagesData = StatusRedux.Failed;
            state.errorImagesDataMsg = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchRemoveImageInspection.pending, (state) => {
            state.statusRemoveImage = StatusRedux.Loading;
        });
        builder.addCase(fetchRemoveImageInspection.fulfilled, (state, action) => {
            const index = state.inspectionImagesData.findIndex((x: any) => x.image_id === action.meta.arg.args?.image_id);
            state.inspectionImagesData.splice(index, 1);
            state.inspectionImagesDataRequest.splice(index, 1);
            state.statusRemoveImage = StatusRedux.Succeeded;
        });
        builder.addCase(fetchRemoveImageInspection.rejected, (state, action) => {
            state.statusRemoveImage = StatusRedux.Failed;
            state.errorRemoveImage = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreImages, clearStatus, setImagesRequest, updateImage, removeDamageFromImg } = inspectionUploadImagesSlice.actions;
export default inspectionUploadImagesSlice;
