import { IFetchArgs, IFetchBody } from '../../store/IFetch';
import { ServicesBase } from '../ServicesBase';
import { IArgsPermission, IPermission, IPermissions } from '../../infrastructure/DTO/permission/permission.dto';

class PermissionServices extends ServicesBase {
    static async createPermission(params: IFetchBody<IPermission>): Promise<IPermission> {
        return await this.services('POST', `api/permissions`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async getPermissions(params: IFetchArgs<null>): Promise<IPermissions> {
        return await this.services('GET', `api/permissions`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async updatePermission(params: IFetchBody<IPermission, IArgsPermission>): Promise<IPermission> {
        return await this.services('PUT', `api/permissions/${params.args?.permission_id}`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async removePermission(params: IFetchArgs<IArgsPermission>): Promise<any> {
        return await this.services('DELETE', `home/api/permissions/${params.args?.permission_id}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async activePermission(params: IFetchArgs<IArgsPermission>): Promise<IPermission> {
        return await this.services('POST', `api/permissions/${params.args?.permission_id}/activation`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async deactivatePermission(params: IFetchArgs<IArgsPermission>): Promise<IPermission> {
        return await this.services('POST', `api/permissions/${params.args?.permission_id}/deactivation`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }
}

export default PermissionServices;
