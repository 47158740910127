import React from 'react';
import { Grid } from '@mui/material';
import DataMenu, { MenuType } from '../components/pages/inspection/drill-down/damage-lab-layout/damagelab-menu/menu';
import { IBoundingBox, IContours, ICoordinates, IDimensions } from '../infrastructure/DTO/damage-lab/damage-lab.dto';
import { IMeasurements } from '../infrastructure/DTO/damage-lab/anomaly-measurements.dto';
import DamageLabLayoutContext, {
    DrawerPanelType,
    IDamageLabLayoutContext,
    imageInfoType,
    MeasurementCurrentDataType,
    MeasurementType,
    PartsType,
    PointsType,
    SelectedPartType,
} from '../context/DamageLabLayoutContext';
import Konva from 'konva';
import { IImageOverView, IZoomMeasurement } from '../infrastructure/DTO/multilayered/multilayered.dto';

const DamageLabLayoutProvider = (props: any) => {
    const { children } = props;
    const refDiv = React.useRef<HTMLDivElement | null>(null);
    const stageRef = React.useRef<Konva.Layer | null>(null);
    const [image, _setImage] = React.useState<IImageOverView | null>(null);
    const [images, _setImages] = React.useState<Array<IImageOverView>>([]);
    const [coordinates, _setCoordinates] = React.useState<ICoordinates>({ x: 1, y: 1 });
    const [canvasSize, _setCanvasSize] = React.useState<IDimensions>({ width: 1, height: 1 });
    const [scale, _setScale] = React.useState<number>(1);
    const [menu, _setMenu] = React.useState<Array<MenuType>>(DataMenu);
    const [drawerPanel, _setDrawerPanel] = React.useState<{ left: boolean; right: boolean; top: boolean; bottom: boolean }>({
        left: false,
        right: false,
        bottom: false,
        top: false,
    });
    const [currentPart, _setCurrentPart] = React.useState<Array<IContours>>([]);
    const [selectedPart, _setSelectedPart] = React.useState<{ id: string; color: string } | null>(null);
    const [loading, _setLoading] = React.useState<boolean>(true);
    const [panelViewLayout, _setPanelViewLayout] = React.useState<string>('');
    const [measurements, _setMeasurements] = React.useState<Map<string, { measurement: Array<IMeasurements>; bounding_box: IBoundingBox }>>(
        new Map()
    );
    const [measurementCurrentData, _setMeasurementCurrentData] = React.useState<MeasurementCurrentDataType>({
        result: null,
        minimalEnclosingBox: null,
        threeBoundingBox: null,
    });
    const [checkedMeasurement, _setCheckedMeasurement] = React.useState<Array<string>>([]);
    const [points, _setPoints] = React.useState<Map<string, { pointsArr: Array<number>; color: string }>>(new Map());
    const [imageInfo, _setImageInfo] = React.useState<imageInfoType>({ weight: '', width: 1, height: 1 });
    const [opacitySliderThreeD, _setOpacitySliderThreeD] = React.useState<number>(1);
    const [zoomPerArea, _setZoomPerArea] = React.useState<IZoomMeasurement | undefined>(undefined);
    const [quadrangle, _setQuadrangle] = React.useState<Array<number>>([]);
    const [panelContours, _setPanelContours] = React.useState<Array<Array<number>>>([]);
    const [measurementPoints, _setMeasurementPoints] = React.useState<Array<number>>([]);
    const [showMeasurementInfo, _setShowMeasurementInfo] = React.useState<boolean>(false);
    const [parts, _setParts] = React.useState<Array<PartsType>>([]);

    const handleSetImage = (img: IImageOverView): void => _setImage(img);
    const handleSetImages = (img: any): void => _setImages(img);
    const handleSetCoordinates = (coordinates: ICoordinates): void => _setCoordinates(coordinates);
    const handleSetCanvasSize = (size: IDimensions): void => _setCanvasSize(size);
    const handleSetMenu = (menu: Array<MenuType>): void => _setMenu(menu);
    const handleSetScale = (scale: number): void => _setScale(scale);
    const handleSetDrawerPanel = (panel: DrawerPanelType): void => _setDrawerPanel(panel);
    const handleSetCurrentPart = (current: Array<IContours>): void => _setCurrentPart(current);
    const handleSetSelectedPart = (part: SelectedPartType): void => _setSelectedPart(part);
    const handleSetCheckedMeasurement = (checked: Array<string>): void => _setCheckedMeasurement(checked);
    const handleSetLoading = (loading: boolean): void => _setLoading(loading);
    const handleSetPanelViewLayout = (layout: string): void => _setPanelViewLayout(layout);
    const handleSetMeasurements = (measurement: MeasurementType): void => _setMeasurements(measurement);
    const handleSetMeasurementCurrentData = (current: MeasurementCurrentDataType): void => _setMeasurementCurrentData(current);
    const handleSetPoints = (points: PointsType): void => _setPoints(points);
    const handleSetImageInfo = (imageInfo: imageInfoType): void => _setImageInfo(imageInfo);
    const handleOpacitySliderThreeD = (event: Event, opacity: number | number[]): void => _setOpacitySliderThreeD(opacity as number);
    const handleSetParts = (parts: Array<PartsType>): void => _setParts(parts);
    const handleMeasurementInfo = (show: boolean) => _setShowMeasurementInfo(show);

    const contextValue: IDamageLabLayoutContext = {
        refDiv: refDiv,
        stageRef: stageRef,
        image: image,
        images: images,
        coordinates: coordinates,
        canvasSize: canvasSize,
        menu: menu,
        scale: scale,
        drawerPanel: drawerPanel,
        currentPart: currentPart,
        selectedPart: selectedPart,
        loading: loading,
        panelViewLayout: panelViewLayout,
        measurements: measurements,
        measurementCurrentData: measurementCurrentData,
        checkedMeasurement: checkedMeasurement,
        points: points,
        imageInfo: imageInfo,
        opacitySliderThreeD: opacitySliderThreeD,
        zoomPerArea: zoomPerArea,
        quadrangle: quadrangle,
        panelContours: panelContours,
        measurementPoints: measurementPoints,
        showMeasurementInfo: showMeasurementInfo,
        parts: parts,
        setImage: handleSetImage,
        setImages: handleSetImages,
        setCoordinates: handleSetCoordinates,
        setCanvasSize: handleSetCanvasSize,
        setMenu: handleSetMenu,
        setScale: handleSetScale,
        setDrawerPanel: handleSetDrawerPanel,
        setCurrentPart: handleSetCurrentPart,
        setSelectedPart: handleSetSelectedPart,
        setCheckedMeasurement: handleSetCheckedMeasurement,
        setLoading: handleSetLoading,
        setPanelViewLayout: handleSetPanelViewLayout,
        setMeasurements: handleSetMeasurements,
        setMeasurementCurrentData: handleSetMeasurementCurrentData,
        setPoints: handleSetPoints,
        setImageInfo: handleSetImageInfo,
        setOpacitySliderThreeD: handleOpacitySliderThreeD,
        setZoomPerArea: _setZoomPerArea,
        setQuadrangle: _setQuadrangle,
        setPanelContours: _setPanelContours,
        setMeasurementPoints: _setMeasurementPoints,
        handleMeasurementInfo: handleMeasurementInfo,
        setParts: handleSetParts,
    };

    return (
        <DamageLabLayoutContext.Provider value={contextValue}>
            <Grid container spacing={1} direction='row' justifyContent='center'>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </DamageLabLayoutContext.Provider>
    );
};

export default DamageLabLayoutProvider;
