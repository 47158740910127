import React, { PropsWithChildren, useState } from 'react';
import { decodeToken, isExpired } from 'react-jwt';
import { UserPermission } from '../config';
import { ITokenPayLoad } from '../infrastructure/DTO/users/token.dto';

export interface IAuthContext {
    token: string;
    isValid: boolean;
    setToken(token: string): void;
    parseToken(): ITokenPayLoad;
    checkPermission(check: string): boolean;
    getPermission(): Array<string>;
    removeToken(): void;
}

export const CustomAuthContext = React.createContext<IAuthContext>({
    token: '',
    isValid: false,
    setToken: (token: string): void => {},
    checkPermission: (check: string): boolean => {
        return false;
    },
    getPermission: (): Array<string> => {
        return [];
    },
    parseToken: (): ITokenPayLoad => {
        return {} as ITokenPayLoad;
    },
    removeToken: (): void => {},
});

const AuthContextProvider: React.FC<PropsWithChildren<any>> = (props) => {
    const { children } = props;
    const currentToken = localStorage.getItem('token') || '';
    const [isValid, setValid] = React.useState<boolean>(currentToken.length > 0);
    const [token, _setToken] = useState<string>(currentToken);

    const setToken = (token: string) => {
        localStorage.setItem('token', token);
        _setToken(token);
    };

    const removeToken = () => {
        localStorage.removeItem('token');
        _setToken('');
    };

    const checkPermission = (check: string): boolean => {
        let current = getPermission();

        if (current === null) {
            return false;
        }

        let checkAdmin = current.indexOf(UserPermission.ADMIN);
        if (checkAdmin !== -1) {
            return false;
        }

        let res = current
            .join(', ')
            .split(',')
            .findIndex((p) => p.replace(/\s+/g, '') === check.replace(/\s+/g, ''));
        return res === -1;
    };

    const getPermission = (): Array<string> => {
        if (parseToken() !== null && parseToken().permission !== undefined) {
            if (Array.isArray(parseToken().permission)) {
                return parseToken().permission as Array<string>;
            } else {
                return [parseToken().permission] as unknown as Array<string>;
            }
        } else {
            return [];
        }
    };

    const parseToken = (): ITokenPayLoad => {
        return decodeToken(token) as ITokenPayLoad;
    };

    const contextValue = {
        token: token,
        isValid: isValid,
        setToken: setToken,
        checkPermission: checkPermission,
        getPermission: getPermission,
        parseToken: parseToken,
        removeToken: removeToken,
    };

    React.useEffect(() => {
        let interval: any;

        if (!isExpired(token) && !isExpired(localStorage.getItem('token') as string)) {
            interval = setInterval(() => {
                setValid(!isExpired(token) && !isExpired(localStorage.getItem('token') as string));
            }, 1000);
        } else {
            setValid(false);
            removeToken();
        }

        return () => clearInterval(interval);
    }, [token, localStorage.getItem('token')]);

    return <CustomAuthContext.Provider value={contextValue}>{children}</CustomAuthContext.Provider>;
};

export default AuthContextProvider;
