import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { IUserManagementProvider, UserManagementContext } from '../../../../../context/UserManagementProvider';
import { IProfile } from '../../../../../infrastructure/DTO/profile/profile.dto';
import useCheckInputText from '../../../../../hooks/validation/use-input-text';
import { IInput } from '../../../../../hooks/validation/IInput';

interface IProfileNameComponent {
    profile?: IProfile | null;
    changeProfileName(name: string): void;
}

interface Fields {
    name: IInput;
}

const ProfileName: React.FC<IProfileNameComponent> = (props: IProfileNameComponent) => {
    const { setProfile, handleIsActiveButtonNext } = useContext<IUserManagementProvider>(UserManagementContext);
    const [profileName, setProfileName] = React.useState<string>('');
    const { inputsText, message, checkValueText, clearInputState } = useCheckInputText<Fields>({
        name: {
            required: true,
        },
    });

    /**
     * @type IProfile
     * Get profile
     */
    React.useEffect(() => {
        if (props.profile) {
            clearInputState(true);
            props.changeProfileName(props.profile?.name as string);
            setProfileName(props.profile?.name as string);
            setProfile(props.profile);
        }
    }, [props.profile]);

    React.useEffect(() => {
        handleIsActiveButtonNext(inputsText?.name as boolean);
    }, [inputsText?.name]);

    /**
     * Change profile name
     * @param event
     */
    const handleProfile = (event: any) => {
        props.changeProfileName(event.target.value as string);
        setProfileName(event.target.value as string);
    };

    return (
        <Grid item xs={12}>
            <TextField
                fullWidth
                name='name'
                required={true}
                id='name'
                type='text'
                label='Profile Name'
                variant='outlined'
                value={profileName.trimStart()}
                onChange={handleProfile}
                onKeyUp={checkValueText}
                helperText={message?.name}
                error={inputsText?.name}
            />
        </Grid>
    );
};

export default ProfileName;
