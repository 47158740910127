import { CSSObject } from '@mui/material';

const mainHeaderPage = (): CSSObject => ({
    marginBottom: '10px',
});

const titlePage = (): CSSObject => ({
    marginBottom: '10px',
    textTransform: 'uppercase',
});

const mainPage = (): CSSObject => ({
    minHeight: 'calc(100vh - 170px)',
    '& > div': {
        '& > div': {
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            padding: '20px',
            minHeight: 'calc(100vh - 170px)',
        },
        '& > div:last-child': {
            border: '0px',
        },
    },
});

export const classes = {
    mainHeaderPage,
    mainPage,
    titlePage,
};
