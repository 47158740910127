import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserServices from '../../services/users/user-services';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchBody } from '../IFetch';
import { IGenerateToken } from '../../infrastructure/DTO/users/user-view.dto';
import { IShortToken, IToken } from '../../infrastructure/DTO/users/token.dto';
import { stat } from 'fs';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGenerateToken = createAsyncThunk<IToken, IFetchBody<IGenerateToken>>('user/token', async (params) => {
    return await UserServices.generateToken(params);
});

export const fetchGenerateShortToken = createAsyncThunk<IShortToken, IFetchBody<IGenerateToken>>('user/short-token', async (params) => {
    return await UserServices.generateTokenShort(params);
});

export const generateTokenSlice = createSlice({
    name: 'user',
    initialState: {
        token: {} as IToken,
        shortToken: {} as IShortToken,
        status: '',
        error: {} as IErrorResponse,
        statusShortToken: '',
        errorShortToken: {} as IErrorResponse,
    },
    reducers: {
        ClearStoreGenerateToken: (state) => {
            state.token = {} as IToken;
            state.shortToken = {} as IShortToken;
            state.status = '';
            state.error = {} as IErrorResponse;
            state.statusShortToken = '';
            state.errorShortToken = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGenerateToken.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGenerateToken.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.token = action.payload;
            state.error = {} as IErrorResponse;
        });
        builder.addCase(fetchGenerateToken.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchGenerateShortToken.pending, (state) => {
            state.statusShortToken = StatusRedux.Loading;
        });
        builder.addCase(fetchGenerateShortToken.fulfilled, (state, action) => {
            state.statusShortToken = StatusRedux.Succeeded;
            state.shortToken = action.payload;
        });
        builder.addCase(fetchGenerateShortToken.rejected, (state, action) => {
            state.statusShortToken = StatusRedux.Failed;
            state.errorShortToken = JSON.parse(action.error.message as string);
        });
    },
});

export const { ClearStoreGenerateToken } = generateTokenSlice.actions;
export default generateTokenSlice;
