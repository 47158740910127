import {CSSObject, Theme} from "@mui/material";


const headerPopup = (theme: Theme): CSSObject => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
})

const paper = (): CSSObject => ({
    padding: '20px'
})

const btnGroup = (): CSSObject => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20
})
                                            
const headerPopupMain = (theme: Theme): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
})

const iconButton = (theme: Theme): CSSObject => ({
    padding: '0 20px!important',
    color: `${theme.palette.primary.contrastText}!important`,
})


export const classes = {
    iconButton, headerPopupMain, btnGroup, paper, headerPopup
}