import { ServicesBase } from '../ServicesBase';
import { ILanguage } from '../../infrastructure/DTO/bussiness-unit/language.dto';
import { IFetchArgs } from '../../store/IFetch';

class LanguageServices extends ServicesBase {
    static async getLanguage(params: IFetchArgs<null>): Promise<Array<ILanguage>> {
        return await this.services('GET', '/home/Language', null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }
}

export default LanguageServices;
