import { createSlice } from '@reduxjs/toolkit';

export const eventPopupSlice = createSlice({
    name: 'popup',
    initialState: {
        popup: false as boolean,
    },
    reducers: {
        showPopup: (state, action) => {
            state.popup = action.payload as boolean;
        },
    },
});

export const { showPopup } = eventPopupSlice.actions;
export default eventPopupSlice;
