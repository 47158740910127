import {CSSObject} from "@mui/material";

const iconBtn = (): CSSObject => ({
    height: '50px'
})

const odometerBlock = (): CSSObject => ({
    borderLeft: '1px solid rgba(0, 0, 0, 0.42)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    width: '100px!important',
    height: '48px',
    display: 'flex',
    justifyContent: 'center'
})

const odometerImg = (): CSSObject => ({
    width: '80px',
    position: 'absolute',
    '& > img': {
        width: '100%'
    }
})

const odometerBtn = (): CSSObject => ({
    position: 'absolute',
    top: '-20px!important',
    right: '-20px!important'
})


export const classes = {
    iconBtn, odometerBtn, odometerImg, odometerBlock
}