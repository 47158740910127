import * as React from 'react';
import { BasicDialog } from '../../../../common/ui-components/Dialog';
import { Box, Paper } from '@mui/material';
import { classes } from '../style';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import useCheckInputText from '../../../../hooks/validation/use-input-text';
import { AppDispatch, AppState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { ClearCreateCompany, fetchCreateCompany } from '../../../../store/company/companies';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { useSnackbar } from 'notistack';
import { ICompany } from '../../../../infrastructure/DTO/company/company.dto';
import SaveIcon from '@mui/icons-material/Save';
import { IInput } from '../../../../hooks/validation/IInput';
import errorParseMessage from '../../../../config/error-parse';

interface IAddCompanyComponent {
    open: boolean;
    handleClose: Function;
    count: number;
    setCount: Function;
}

interface Fields {
    name: IInput;
    country: IInput;
    industry: IInput;
}

const initStateCompany: ICompany = {
    name: '',
    country: '',
    industry: '',
    is_active: true,
    branding: {
        visuals_path: '',
    },
};

const AddCompany: React.FC<IAddCompanyComponent> = (props: IAddCompanyComponent) => {
    const { enqueueSnackbar } = useSnackbar();
    const { token } = React.useContext(CustomAuthContext);
    const { statusCreate, errorCreate } = useSelector((state: AppState) => state.companies);
    const dispatch: AppDispatch = useDispatch();
    const { inputsText, message, checkValueText, clearInputState } = useCheckInputText<Fields>({
        name: {
            required: true,
        },
        country: {
            required: true,
        },
        industry: {
            required: true,
        },
    });
    const [companyData, setCompanyData] = React.useState<ICompany>(initStateCompany);

    React.useEffect(() => {
        if (!props.open) {
            setCompanyData(initStateCompany);
            clearInputState(true);
        }
    }, [props.open]);

    /**
     * Change Inputs
     * @param event
     */
    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;

        setCompanyData({
            ...companyData,
            [name]: value,
        });
    };

    /**
     * Change branding input
     * @param event
     */
    const handleChangeInputBranding = (event: any) => {
        setCompanyData({
            ...companyData,
            branding: {
                visuals_path: event.target.value,
            },
        });
    };

    /**
     * Create Company Handler
     * @param event
     */
    const handleSubmit = (event: any) => {
        event.preventDefault();

        const body: ICompany = {
            ...companyData,
            name: companyData.name?.trim(),
            industry: companyData.industry?.trim(),
            country: companyData.country?.trim(),
            branding: { visuals_path: companyData.branding?.visuals_path.trim() ?? '' },
        };

        dispatch(fetchCreateCompany({ token, body }));
    };

    /**
     * Close Popup
     */
    const handleClosePopup = () => {
        props.handleClose();
    };

    /**
     * Get Status
     */
    React.useEffect(() => {
        if (statusCreate === StatusRedux.Succeeded) {
            enqueueSnackbar('Created', {
                variant: 'success',
            });
            props.setCount(props.count + 1);
            props.handleClose();
            dispatch(ClearCreateCompany());
        }

        if (statusCreate === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorCreate), {
                variant: 'error',
            });
            dispatch(ClearCreateCompany());
        }
    }, [statusCreate]);

    return (
        <BasicDialog
            handlePopup={handleClosePopup}
            open={props.open}
            title='Create New Company'
            width='md'
            buttons={
                <Grid container spacing={4} justifyContent='flex-end'>
                    <Grid item>
                        <Button
                            disabled={inputsText?.name || inputsText?.country || inputsText?.industry}
                            form='create-company'
                            size='small'
                            type='submit'
                            variant='contained'
                            color='secondary'
                            endIcon={<SaveIcon />}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            <Box>
                <form id='create-company' autoComplete='off' onSubmit={handleSubmit}>
                    <Paper sx={classes.paper} elevation={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name='name'
                                    required={true}
                                    type='text'
                                    label='Name'
                                    variant='outlined'
                                    value={companyData?.name?.trimStart() as string}
                                    onChange={handleChangeInput}
                                    onKeyUp={checkValueText}
                                    helperText={message?.name}
                                    error={inputsText?.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required={true}
                                    name='industry'
                                    type='text'
                                    label='Industry'
                                    variant='outlined'
                                    value={companyData?.industry?.trimStart() as string}
                                    onChange={handleChangeInput}
                                    onKeyUp={checkValueText}
                                    helperText={message?.industry}
                                    error={inputsText?.industry}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required={true}
                                    name='country'
                                    type='text'
                                    label='Country'
                                    variant='outlined'
                                    value={companyData?.country?.trimStart() as string}
                                    onChange={handleChangeInput}
                                    onKeyUp={checkValueText}
                                    helperText={message?.country}
                                    error={inputsText?.country}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name='branding'
                                    type='text'
                                    label='Branding'
                                    variant='outlined'
                                    value={companyData?.branding?.visuals_path}
                                    onChange={handleChangeInputBranding}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </form>
            </Box>
        </BasicDialog>
    );
};

export default AddCompany;
