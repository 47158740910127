import React from 'react';
import { IDialog } from '../interface/IDialog';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { AppBar, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from '../syles/dialog';
import DialogActions from '@mui/material/DialogActions';

export function BasicDialog(props: IDialog) {
    const { width, title, children, open, handlePopup, buttons, tabs } = props;

    const handlePopupOpen = () => {
        handlePopup();
    };

    return (
        <Dialog sx={{ '& > div > div': { overflowX: 'hidden' } }} fullWidth maxWidth={width} open={open} onClose={handlePopupOpen}>
            <DialogTitle sx={classes.headerPopup} id='max-width-dialog-title'>
                {title}
                <IconButton aria-label='close' sx={classes.closeIconPopup} onClick={handlePopupOpen}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {tabs && (
                <AppBar color='default' position='sticky'>
                    {tabs}
                </AppBar>
            )}
            <DialogContent sx={{ padding: '10px' }} dividers={true}>
                {children}
            </DialogContent>
            {buttons && <DialogActions>{buttons}</DialogActions>}
        </Dialog>
    );
}
