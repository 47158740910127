import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Features from '../../services/feature/features';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFeature } from '../../infrastructure/DTO/inspection/feature.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchAllFeatures = createAsyncThunk('features/all', async () => {
    return await Features.getAllFeatures();
});

export const featuresTypeSlice = createSlice({
    name: 'features',
    initialState: {
        featuresList: [] as Array<IFeature>,
        statusFeatures: '',
        errorFeatures: {} as IErrorResponse,
    },
    reducers: {
        clearStore: (state) => {
            state.statusFeatures = '';
            state.errorFeatures = {} as IErrorResponse;
            state.featuresList = [] as Array<IFeature>;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllFeatures.pending, (state) => {
            state.statusFeatures = StatusRedux.Loading;
        });
        builder.addCase(fetchAllFeatures.fulfilled, (state, action) => {
            state.statusFeatures = StatusRedux.Succeeded;
            state.featuresList = action.payload;
        });
        builder.addCase(fetchAllFeatures.rejected, (state, action) => {
            state.statusFeatures = StatusRedux.Failed;
            state.errorFeatures = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStore } = featuresTypeSlice.actions;
export default featuresTypeSlice;
