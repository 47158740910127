export const onbeforeunload = (show: boolean | undefined) => {
    window.onbeforeunload = () => show
}

export const backButtonDisabled = (show: boolean) => {
    if(show) {
        window.history.pushState(null, '', window.location.href);
        window.onpopstate = () => {
            window.history.go(1)
        }
    }
}