import React from 'react';
import { classes } from './style';
import { AppBar, Tab, Tabs } from '@mui/material';
import { Measurement } from './measurements';
import Parts from './parts/parts';
import { TabPanel, TabsProps } from '../../../../../common/ui-components/Tab';
import { Anomalies } from './anomalies';
import { ProjectionThreeD } from './3d-projection';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import Box from '@mui/material/Box';

const AnomaliesMain: React.FC = () => {
    const [value, setValue] = React.useState(0);
    const { status } = useSelector((state: AppState) => state.damageLabSingleImg);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar position='static' color='default'>
                <Tabs variant='fullWidth' value={value} indicatorColor='primary' textColor='primary' onChange={handleChange} aria-label='menu'>
                    <Tab disabled={status === StatusRedux.Loading} label='Group by texture' {...TabsProps(0)} />
                    <Tab disabled={status === StatusRedux.Loading} label='Grouped anomalies' {...TabsProps(1)} />
                    <Tab disabled={status === StatusRedux.Loading} label='Anomalies' {...TabsProps(2)} />
                    <Tab disabled={status === StatusRedux.Loading} label='Measurements' {...TabsProps(3)} />
                    <Tab disabled={status === StatusRedux.Loading} label='Parts' {...TabsProps(4)} />
                    <Tab disabled={status === StatusRedux.Loading} label='3D Projection' {...TabsProps(5)} />
                </Tabs>
            </AppBar>
            <Box sx={classes.tabBody}>
                <TabPanel value={value} index={0}>
                    <Anomalies load={true} group_results={true} combine_textured_anomalies={true} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Anomalies load={true} group_results={true} combine_textured_anomalies={false} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Anomalies load={true} group_results={false} combine_textured_anomalies={false} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Measurement />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Parts />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <ProjectionThreeD />
                </TabPanel>
            </Box>
        </>
    );
};

export default AnomaliesMain;
