import { ServicesBase } from '../ServicesBase';
import { IFetchArgs } from '../../store/IFetch';
import { IApiKey, IArgsApiKey } from '../../infrastructure/DTO/key/api-key.dto';

class ApiKeyServices extends ServicesBase {
    static async generateApiKey(params: IFetchArgs<IArgsApiKey>): Promise<IApiKey> {
        return await this.services('POST', `companies/${params.args?.company_id}/business_units/${params.args?.business_unit_id}/apikey`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async getApiKeyByUserId(params: IFetchArgs<IArgsApiKey>): Promise<IApiKey> {
        return await this.services('GET', `companies/${params.args?.company_id}/business_units/${params.args?.business_unit_id}/apikey`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }
}

export default ApiKeyServices;
