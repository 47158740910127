import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Divider, FormControl, FormControlLabel, ListItemIcon, ListItemText, MenuItem, Popover, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import { UserPermission } from '../../../../config';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { ICompany } from '../../../../infrastructure/DTO/company/company.dto';
import { IBusinessUnit } from '../../../../infrastructure/DTO/bussiness-unit/business-units.dto';
import FormGroup from '@mui/material/FormGroup';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ClearIcon from '@mui/icons-material/Clear';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import BusinessUnitSelect from '../../../../common/ui-components/BusinessUnitSelect';
import CompaniesSelect from '../../../../common/ui-components/CompaniesSelect';
import { fetchAllUsers } from '../../../../store/users/users';
import { AppDispatch } from '../../../../store';
import { useDispatch } from 'react-redux';
import { IUserManagementProvider, UserManagementContext } from '../../../../context/UserManagementProvider';
import SearchInput from '../../../../common/ui-components/SearchInput';

const UserPanel: React.FC = () => {
    const { order, orderBy, limit, page, searchText, searchMode, handleSearch, handleChangeSearchText, changePage, handleCreateUserPopup } =
        useContext<IUserManagementProvider>(UserManagementContext);
    const { checkPermission, parseToken, token } = useContext(CustomAuthContext);
    const dispatch: AppDispatch = useDispatch();

    const [companySelect, setCompanySelect] = React.useState<ICompany | null>(null);
    const [businessUnitSelect, setBusinessUnitSelect] = React.useState<IBusinessUnit | null>(null);
    const [showWithoutBU, setShowWithoutBU] = React.useState<boolean>(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    /**
     * Filter
     * @param event
     */
    const handleFilter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Clear Filter
     */
    const handleClearFilter = () => {
        setBusinessUnitSelect(null);
        setShowWithoutBU(false);
        if (!checkPermission(UserPermission.ADMIN)) {
            setCompanySelect(null);
        }
    };

    /**
     * Close Filter
     */
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    /**
     * ChangeSwitch to show/hidden users without business units
     */
    const handleChangeWithoutBU = () => {
        setShowWithoutBU(!showWithoutBU);
        changePage(null, 0);
    };

    React.useEffect(() => {
        let company_id: string = '';

        if (!checkPermission(UserPermission.ADMIN)) {
            if (companySelect?.company_id !== undefined) {
                company_id = companySelect?.company_id as string;
            }
        } else {
            company_id = parseToken().company as string;
        }

        if (searchMode) {
            dispatch_fn(company_id, businessUnitSelect, showWithoutBU, searchText, limit, page, orderBy, order);
        } else {
            if (searchText.length === 0) {
                dispatch_fn(company_id, businessUnitSelect, showWithoutBU, searchText, limit, page, orderBy, order);
            }
        }
    }, [searchMode, orderBy, order, searchText, limit, page, companySelect?.company_id, businessUnitSelect, showWithoutBU]);

    React.useEffect(() => {
        if (businessUnitSelect) {
            setShowWithoutBU(false);
        }
    }, [businessUnitSelect]);

    /**
     * @param companyId
     * @param searchText
     * @param rowsPerPage
     * @param business_unit
     * @param show_without_bu
     * @param page
     * @param orderBy
     * @param order
     * Get All Users
     */
    const dispatch_fn = (
        companyId: string | null,
        business_unit: IBusinessUnit | null,
        show_without_bu: boolean,
        searchText: string,
        rowsPerPage: number,
        page: number,
        orderBy: string,
        order: string
    ) => {
        dispatch(
            fetchAllUsers({
                token: token,
                args: {
                    company_id: companyId as string,
                    business_unit: business_unit !== null ? business_unit.business_unit_id : null,
                    user_id: parseToken().sub,
                    search_text: searchText,
                    limit: rowsPerPage,
                    show_without_business_unit: show_without_bu,
                    offset: page + 1,
                    sort_by_name: orderBy,
                    sort: order,
                },
            })
        );
    };

    return (
        <Grid container spacing={2} direction='row' justifyContent='flex-end' alignItems='center'>
            <Grid item>
                <Box sx={{ width: '100%', minWidth: '150px', maxWidth: '300px' }}>
                    <SearchInput name='Search' searchText={searchText} handleSearch={handleSearch} handleChangeSearchText={handleChangeSearchText} />
                </Box>
            </Grid>
            <Grid item>
                <Button
                    aria-describedby={id}
                    size='small'
                    variant='outlined'
                    color='primary'
                    onClick={handleFilter}
                    endIcon={
                        (!checkPermission(UserPermission.ADMIN) ? companySelect : false) || businessUnitSelect || showWithoutBU ? (
                            <FilterListIcon />
                        ) : (
                            <FilterListOffIcon />
                        )
                    }
                >
                    Filter
                </Button>
                <Popover
                    id={id}
                    anchorEl={anchorEl}
                    onClose={handleCloseFilter}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={open}
                >
                    <Box sx={{ height: '500px' }}>
                        {!checkPermission(UserPermission.ADMIN) && (
                            <Box sx={{ padding: '20px', width: '350px' }}>
                                <CompaniesSelect data={companySelect} setData={setCompanySelect} />
                            </Box>
                        )}

                        <Box sx={{ padding: '20px', width: '350px' }}>
                            <BusinessUnitSelect
                                clear={showWithoutBU}
                                company={companySelect}
                                disabled={
                                    !checkPermission(UserPermission.ADMIN)
                                        ? companySelect === null || showWithoutBU
                                        : checkPermission(UserPermission.USER_MANAGEMENT)
                                }
                                setData={setBusinessUnitSelect}
                                data={businessUnitSelect}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ padding: '20px' }}>
                        <FormControl component='fieldset' variant='standard'>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={showWithoutBU} onChange={handleChangeWithoutBU} name='without_bu' />}
                                    label='Show users without business unit'
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MenuItem
                            disabled={companySelect === null && !showWithoutBU && businessUnitSelect === null}
                            sx={{ width: '100%' }}
                            onClick={handleClearFilter}
                        >
                            <ListItemIcon>
                                <SettingsBackupRestoreIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Clear Filter</ListItemText>
                        </MenuItem>
                        <MenuItem sx={{ width: '100%' }} onClick={handleCloseFilter}>
                            <ListItemIcon>
                                <ClearIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Close Filter</ListItemText>
                        </MenuItem>
                    </Box>
                </Popover>
            </Grid>
            <Grid item>
                {!checkPermission(UserPermission.USER_MANAGEMENT) && (
                    <Button
                        size='small'
                        disabled={checkPermission(UserPermission.USER_MANAGEMENT)}
                        onClick={handleCreateUserPopup}
                        variant='contained'
                        color='secondary'
                        endIcon={<PersonAddIcon />}
                    >
                        Create new
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

export default UserPanel;
