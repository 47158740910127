import React, { useContext } from 'react';
import { CustomAuthContext } from '../context/AuthProvider';
import { Navigate } from 'react-router-dom';

interface IPrivateRoute {
    children: JSX.Element;
    permissions?: Array<string>;
}

const PrivateRoute: React.FC<IPrivateRoute> = (props: IPrivateRoute) => {
    const { isValid } = useContext(CustomAuthContext);

    return isValid ? props.children : <Navigate to='/' replace />;
};

export default PrivateRoute;
