import React from 'react';
import { Image as Pic } from 'react-konva';
import convertBytes from '../../../../../../config/convertByts';
import DamageLabLayoutContext, { IDamageLabLayoutContext } from '../../../../../../context/DamageLabLayoutContext';
import { useSearchParams } from 'react-router-dom';

interface IPic {
    image: HTMLImageElement | null;
    width: number;
    height: number;
}

interface IImageComponent {
    data: any;
    fullscreenActive: boolean;
}

const ImageLayout: React.FC<IImageComponent> = (props: IImageComponent) => {
    const { refDiv, canvasSize, setCoordinates, setCanvasSize, setScale, setImageInfo } =
        React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);
    const [pic, setPic] = React.useState<IPic>({
        width: 1,
        height: 1,
        image: null,
    });

    const fetchImage = async (link: string) => {
        const img = new Image();
        let response = await fetch(link);
        let blob = await response.blob();

        img.onload = () => {
            setPic({
                width: img.width,
                height: img.height,
                image: img,
            });

            setImageInfo({
                weight: convertBytes(blob.size),
                width: img.width,
                height: img.height,
            });

            let scale = Math.min(refDiv.current.clientWidth / img.width, refDiv.current.clientHeight / img.height);
            setScale(scale);

            setCoordinates({
                x: refDiv.current.clientWidth / 2 - (img.width * scale) / 2,
                y: refDiv.current.clientHeight / 2 - (img.height * scale) / 2,
            });
        };
        img.src = URL.createObjectURL(blob);
    };

    React.useEffect(() => {
        if (props.data) {
            fetchImage(props.data.link);

            if (refDiv?.current !== null) {
                const fitStageIntoParentContainer = () => {
                    if (refDiv?.current) {
                        setCanvasSize({
                            width: refDiv?.current.clientWidth,
                            height: refDiv?.current.clientHeight,
                        });
                    }
                };

                setCanvasSize({
                    width: refDiv?.current.clientWidth,
                    height: refDiv?.current.clientHeight,
                });

                window.addEventListener('resize', fitStageIntoParentContainer);
            }
        }
    }, [props.data, props.fullscreenActive]);

    React.useEffect(() => {
        if (refDiv.current && (props.fullscreenActive || !props.fullscreenActive)) {
            let scale = Math.min(refDiv.current.clientWidth / pic.width, refDiv.current.clientHeight / pic.height);
            setScale(scale);

            setCoordinates({
                x: refDiv.current.clientWidth / 2 - (pic.width * scale) / 2,
                y: refDiv.current.clientHeight / 2 - (pic.height * scale) / 2,
            });
            setCanvasSize({
                width: refDiv?.current.clientWidth,
                height: refDiv?.current.clientHeight,
            });
        }
    }, [refDiv, props.fullscreenActive]);

    return (
        <React.Fragment>
            {pic.image && (
                <Pic
                    width={pic.width * Math.min(canvasSize.width / pic.width, canvasSize.height / pic.height)}
                    height={pic.height * Math.min(canvasSize.width / pic.width, canvasSize.height / pic.height)}
                    image={pic.image as HTMLImageElement}
                />
            )}
        </React.Fragment>
    );
};

export default ImageLayout;
