import * as React from 'react';
import { classes } from './style';
import { Paper, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { ClearStoreChangePassword, fetchUserChangePass } from '../../../../../store/users/change-password';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import Box from '@mui/material/Box';
import useCheckInputPassword from '../../../../../hooks/validation/use-input-password';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { IChangePassword } from '../../../../../infrastructure/DTO/users/user-view.dto';
import errorParseMessage from "../../../../../config/error-parse";

interface IChangePasswordComponent {
    company_id: string | undefined;
    user_id: string | undefined;
    close: Function;
}

const ChangePassword: React.FC<IChangePasswordComponent> = (props: IChangePasswordComponent) => {
    const { token } = useContext(CustomAuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const { inputPassword, checkValuePassword } = useCheckInputPassword();
    const { userChangePswStatus, userChangePswError } = useSelector((state: AppState) => state.changeUserPassword);
    const [change, setChangePassword] = React.useState<IChangePassword>({
        password: '',
        password_confirmation: '',
        validate_policy: true,
    });

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;

        setChangePassword({
            ...change,
            [name]: value,
        });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        dispatch(
            fetchUserChangePass({
                token: token,
                body: change,
                args: {
                    company_id: props.company_id as string,
                    user_id: props.user_id as string,
                },
            })
        );
    };

    React.useEffect(() => {
        if (userChangePswStatus === StatusRedux.Succeeded) {
            enqueueSnackbar('Password Updated Successfully', {
                variant: 'success',
            });
            setChangePassword({
                ...change,
                password: '',
                password_confirmation: '',
            });

            dispatch(ClearStoreChangePassword());
            props.close();
        }
        if (userChangePswStatus === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(userChangePswError), {
                variant: 'error',
            });
            dispatch(ClearStoreChangePassword());
        }
    }, [userChangePswStatus]);

    return (
        <Paper sx={classes.paper} elevation={3}>
            <form id='reset-password' autoComplete='off' onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box sx={classes.headerBlockInput}>Create new Password</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required={true}
                            name='password'
                            error={inputPassword.error}
                            helperText={inputPassword.error ? inputPassword.errorMsg : null}
                            id='password'
                            type='password'
                            label='Password'
                            variant='outlined'
                            value={change.password}
                            onChange={handleChangeInput}
                            onBlur={checkValuePassword}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name='password_confirmation'
                            error={change.password_confirmation !== '' && change.password !== change.password_confirmation}
                            helperText={
                                change.password_confirmation !== '' && change.password !== change.password_confirmation && 'Passwords do not match!'
                            }
                            required={true}
                            id='password_confirmation'
                            type='password'
                            label='Confirm Password'
                            variant='outlined'
                            value={change.password_confirmation}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default ChangePassword;
