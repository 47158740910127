import { CSSObject, keyframes, Theme } from '@mui/material';

const fadeIn = keyframes`
    "0%": {
      opacity: 0
    },
    "100%": {
        opacity: 1
    }
`;

const stage = (): CSSObject => ({
    width: 'auto',
    minHeight: '650px',
    overflow: 'hidden',
    cursor: 'move',
});

const listHeader = (): CSSObject => ({});

const listMain = (): CSSObject => ({
    overflowY: 'auto',
    height: 'auto',
});

const anim = (theme: Theme): CSSObject => ({
    animation: `${fadeIn} 500ms ${theme.transitions.easing.easeInOut}`,
});

export const classes = {
    anim,
    listHeader,
    stage,
    listMain,
};
