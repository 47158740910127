import React, {useState} from "react";
import getTheme from "../theme";
import {ThemeProvider} from "@mui/material";


export interface ICustomThemeContext {
    currentTheme: string
    setTheme: Function
}

export const CustomThemeContext = React.createContext<ICustomThemeContext>({
    currentTheme: 'light',
    setTheme: (name: string): void => {}
})


const CustomThemeProvider = (props: any) => {
    const { children } = props
    const currentTheme = localStorage.getItem('theme') || 'light'
    const [themeName, _setThemeName] = useState(currentTheme)
    const theme = getTheme(themeName)

    const setThemeName = (name: string) => {
        localStorage.setItem('theme', name)
        _setThemeName(name)
    }

    const contextValue = {
        currentTheme: themeName,
        setTheme: setThemeName,
    }

    return (
        <CustomThemeContext.Provider value={contextValue}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </CustomThemeContext.Provider>
    )
}

export default CustomThemeProvider