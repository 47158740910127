import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import DamageLabServices from '../../services/damage-lab/damage-lab';
import { IFetchArgs, IFetchBody } from '../IFetch';
import { IDamageLab, IGetAnomaliesDetection, IImage } from '../../infrastructure/DTO/damage-lab/damage-lab.dto';
import { IAnomaliesDetectionRequest, IArgsDamageLab } from '../../infrastructure/DTO/damage-lab/anomalies-detection.dto';
import { IAnomalyMeasurements } from '../../infrastructure/DTO/damage-lab/anomaly-measurements.dto';
import { IInspectionCaseId } from '../../infrastructure/DTO/inspection/inspection.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';
import DataStatic from './data-static';

export const fetchDamageLabSingleImg = createAsyncThunk<IDamageLab, IFetchBody<IImage>>(
    'damage-lab/singleImg',
    async (params: IFetchBody<IImage>) => {
        return await DamageLabServices.uploadSingleImg(params);
    }
);

export const fetchAnomaliesDetection = createAsyncThunk<IGetAnomaliesDetection, IFetchBody<IAnomaliesDetectionRequest, IArgsDamageLab>>(
    'damage-lab/anomalies',
    async (params: IFetchBody<IAnomaliesDetectionRequest, IArgsDamageLab>) => {
        return await DamageLabServices.anomaliesDetection(params);
    }
);

export const fetchAnomalyMeasurements = createAsyncThunk<IAnomalyMeasurements, IFetchArgs<IArgsDamageLab>>(
    'damage-lab/measurements',
    async (params: IFetchArgs<IArgsDamageLab>) => {
        return await DamageLabServices.anomalyMeasurements(params);
    }
);

export const fetchRemoveInspectionCase = createAsyncThunk<any, IFetchArgs<IInspectionCaseId>>(
    'damage-lab/remove',
    async (params: IFetchArgs<IInspectionCaseId>) => {
        return await DamageLabServices.removeInspectionCase(params);
    }
);

export const damageLabSingleImgSlice = createSlice({
    name: 'damage-lab',
    initialState: {
        damageLabData: {} as IDamageLab,
        anomaliesDetection: {} as IGetAnomaliesDetection,
        anomalyMeasurements: {} as IAnomalyMeasurements,
        status: '',
        error: {} as IErrorResponse,
        statusRemove: '',
    },
    reducers: {
        clearStatusDamageLab: (state) => {
            state.status = '';
            state.error = {} as IErrorResponse;
            state.statusRemove = '';
        },
        clearDataDamageLab: (state) => {
            state.damageLabData = {} as IDamageLab;
        },
        clearAnomaliesDetection: (state) => {
            state.anomaliesDetection = {} as IGetAnomaliesDetection;
        },
        clearAnomalyMeasurements: (state) => {
            state.anomalyMeasurements = {} as IAnomalyMeasurements;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDamageLabSingleImg.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchDamageLabSingleImg.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.damageLabData = action.payload;
        });
        builder.addCase(fetchDamageLabSingleImg.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchAnomaliesDetection.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchAnomaliesDetection.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.anomaliesDetection = action.payload;
        });
        builder.addCase(fetchAnomaliesDetection.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchRemoveInspectionCase.pending, (state) => {
            state.statusRemove = StatusRedux.Loading;
        });
        builder.addCase(fetchRemoveInspectionCase.fulfilled, (state, action) => {
            state.statusRemove = StatusRedux.Succeeded;
        });
        builder.addCase(fetchRemoveInspectionCase.rejected, (state, action) => {
            state.statusRemove = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchAnomalyMeasurements.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchAnomalyMeasurements.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.anomalyMeasurements = action.payload;
        });
        builder.addCase(fetchAnomalyMeasurements.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStatusDamageLab, clearDataDamageLab, clearAnomaliesDetection, clearAnomalyMeasurements } = damageLabSingleImgSlice.actions;
export default damageLabSingleImgSlice;
