import { InspectionType } from '../../static-data/InspectionTypes';
import { IFeaturesList } from '../../infrastructure/DTO/inspection/feature.dto';

class InspectionTypes {
    static async getInspectionTypes(): Promise<Array<IFeaturesList>> {
        return new Promise((resolve) => {
            let typesResult: any[] = [];

            InspectionType.map((type: any) => {
                typesResult.push({
                    id: type.id,
                    name: type.name,
                    type: type.type,
                    content: type.content,
                    showPreviousInspectionId: !(type.id !== 6) || !(type.id !== 7) || !(type.id !== 8) || !(type.id !== 11),
                });
            });
            return resolve(typesResult);
        });
    }
}

export default InspectionTypes;
