import * as React from 'react';
import { classes } from '../style';
import {
    Autocomplete,
    Checkbox,
    createFilterOptions,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { InspectionStatusName } from '../../../../enums/InspectionStatus';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../store';
import { inspectionTypeList } from '../../../../store/inspection/inspection-type';
import Button from '@mui/material/Button';
import { fetchGetCompanyById } from '../../../../store/company/company';
import { ClearStateCompaniesReport, fetchGetCompaniesReport } from '../../../../store/company/report-company';
import Spinner from '../../../spinner/spinner';
import moment from 'moment';
import { UserPermission } from '../../../../config';
import { UtcTime } from '../../../../utils/UtcTime';
import { ExportExcel } from '../export-excel';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { fetchGetAllCompanies } from '../../../../store/company/companies';
import { IArgsCompanyReport, ICompanyReport } from '../../../../infrastructure/DTO/company/company-report.dto';
import { IArgsCompany } from '../../../../infrastructure/DTO/company/company.dto';
import { ClosureReason } from '../../../../enums/ClosureReason';
import { IArgsBusinessUnit, IBusinessUnit } from '../../../../infrastructure/DTO/bussiness-unit/business-units.dto';
import { clearStoreBusinessUnits, fetchGetBusinessUnits } from '../../../../store/business-units/business-units';

const InputCheckBoxProps = {
    PaperProps: {
        style: {
            width: 280,
            maxHeight: 350,
        },
    },
};

const CompanyReport: React.FC = () => {
    const [height, setHeight] = React.useState<any>(0);
    const ref = React.useRef<HTMLElement | null>(null);
    const { parseToken, checkPermission, token } = useContext(CustomAuthContext);
    const tableRef = React.useRef(null);
    const dispatch: AppDispatch = useDispatch();
    const matches = useMediaQuery('(max-width:600px)');
    const [selectedCompany, setCompany] = React.useState<string | null>(null);
    const businessUnits = useSelector((state: AppState) => state.businessUnits);
    const [stateBusinessUnits, setStateBusinessUnits] = React.useState<Array<IBusinessUnit>>([]);
    const [selectedBusinessUnit, setSelectedBusinessUnit] = React.useState<Array<IBusinessUnit>>([]);
    const { inspectionListType } = useSelector((state: AppState) => state.inspectionType);
    const { companies, statusCompanies } = useSelector((state: AppState) => state.companies);
    const [stateCompanies, setStateCompanies] = React.useState<Array<string>>([]);
    const report = useSelector((state: AppState) => state.reportCompanies);
    const [reports, setReports] = React.useState<ICompanyReport | null>(null);
    const [monthTable, setMonthTable] = React.useState<Array<string>>([]);
    const [weekTable, setWeekTable] = React.useState<Array<string>>([]);
    const [datesTable, setDatesTable] = React.useState<Array<Date>>([]);
    const [enumerateDates, setEnumerateDates] = React.useState<Array<Date>>([]);
    const [totalOfRow, setTotalOfRow] = React.useState<Array<number>>([]);
    const [months] = React.useState<Array<number>>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
    const [weeks] = React.useState<Array<number>>([1, 2, 3, 4, 5, 6]);
    const [btnDisable, setBtnDisable] = React.useState<boolean>(true);
    const [byDay, setByDate] = React.useState<boolean>(true);

    const [dates, setDates] = React.useState<{ from: any; to: any }>({
        from: null,
        to: new Date(),
    });

    const [dateFrom, setDateFrom] = React.useState<{ date: any; time: any }>({
        date: null,
        time: new Date().setHours(0, 0, 0, 0),
    });

    const [dateTo, setDateTo] = React.useState<{ date: any; time: any }>({
        date: new Date(),
        time: new Date().setHours(23, 59, 0, 0),
    });

    const [view, setView] = React.useState({
        arrDates: false,
        dates: false,
        month: false,
        week: false,
    });

    const [filter, setFilter] = React.useState<IArgsCompanyReport>({
        company_id: '',
        business_unit_ids: [],
        closure_reason: [],
        month: 0,
        week: 0,
        from_date: null,
        to_date: null,
        dates: [],
        inspection_types: [],
        inspection_statuses: [],
    });

    /**
     * Get All Companies
     */
    React.useEffect(() => {
        if (statusCompanies === StatusRedux.Succeeded) {
            let companiesNames: string[] = companies.companies.map((c) => c.name) as string[];
            setStateCompanies(companiesNames);
        }
    }, [statusCompanies]);

    React.useEffect(() => {
        dispatch(inspectionTypeList());

        setStateBusinessUnits([]);
        setSelectedBusinessUnit([]);

        let args: IArgsCompany = {
            company_id: parseToken().company,
        };

        if (!checkPermission(UserPermission.ADMIN)) {
            args = {
                ...args,
                user_id: parseToken().sub,
            };
            dispatch(fetchGetAllCompanies({ token, args }));
        } else {
            dispatch(
                fetchGetCompanyById({
                    token,
                    args,
                })
            );
        }

        return () => {
            setMonthTable([]);
            setSelectedBusinessUnit([]);
            setStateBusinessUnits([]);
            setCompany('');
            dispatch(ClearStateCompaniesReport());
            dispatch(clearStoreBusinessUnits());
        };
    }, []);

    React.useEffect(() => {
        if (
            (filter.month !== 0 && selectedCompany !== null && selectedBusinessUnit.length !== 0) ||
            (filter.week !== 0 && selectedCompany !== null && selectedBusinessUnit.length !== 0) ||
            (moment(dateFrom.date).isValid() && moment(dateTo.date).isValid() && selectedCompany !== null && selectedBusinessUnit.length !== 0)
        ) {
            setBtnDisable(false);
        } else {
            setBtnDisable(true);
        }
    }, [filter, dateFrom.date, dateTo.date, selectedCompany, selectedBusinessUnit]);

    React.useEffect(() => {
        if (dateFrom.date !== null && dateFrom.time !== null) {
            setDates({
                ...dates,
                from: new Date(
                    moment(moment(dateFrom.date).format('YYYY-MM-DD') + ' ' + moment(dateFrom.time).format('HH:mm')).format('YYYY-MM-DD HH:mm:ss')
                ),
            });
        } else {
            setDates({
                ...dates,
                from: null,
            });
            setFilter({
                ...filter,
                to_date: null,
            });
        }
    }, [dateFrom.date, dateFrom.time]);

    React.useEffect(() => {
        if (dateTo.date !== null && dateTo.time !== null) {
            setDates({
                ...dates,
                to: new Date(
                    moment(moment(dateTo.date).format('YYYY-MM-DD') + ' ' + moment(dateTo.time).format('HH:mm')).format('YYYY-MM-DD HH:mm:ss')
                ),
            });
        } else {
            setDates({
                ...dates,
                to: null,
            });
        }
    }, [dateTo.date, dateTo.time]);

    /**
     * Get Business Units By Company ID
     */
    React.useEffect(() => {
        if (businessUnits.status === StatusRedux.Succeeded && selectedCompany) {
            if (businessUnits?.businessUnits?.business_units) {
                setStateBusinessUnits(businessUnits?.businessUnits?.business_units);
                setSelectedBusinessUnit(businessUnits?.businessUnits?.business_units);
            }
        }
    }, [businessUnits.status, selectedCompany]);

    React.useEffect(() => {
        if (selectedCompany) {
            let company_id: string = '';

            setSelectedBusinessUnit([]);

            if (checkPermission(UserPermission.ADMIN)) {
                company_id = selectedCompany;
            } else {
                company_id = companies?.companies.find((c) => c.name === selectedCompany)?.company_id as string;
            }

            const args: IArgsBusinessUnit = {
                company_id: company_id,
                limit: null,
                offset: null,
            };

            dispatch(
                fetchGetBusinessUnits({
                    token,
                    args,
                })
            );
        }
    }, [selectedCompany]);

    const _totalOfRow = (dates: Date[], format: string) => {
        let totalOfDate: Map<Date, number> = new Map<Date, number>();

        dates.forEach((date) => {
            report.companiesReport.business_units.forEach((bu) => {
                bu.statistics.forEach((s) => {
                    if (moment(s.date).format(format) === moment(date).format(format)) {
                        if (totalOfDate.has(s.date)) {
                            totalOfDate.set(s.date, parseInt(totalOfDate.get(s.date) as unknown as string) + s.inspections_count);
                        } else {
                            totalOfDate.set(s.date, s.inspections_count);
                        }
                    }
                });
            });
        });

        setTotalOfRow(Array.from(totalOfDate.values()));
    };

    React.useEffect(() => {
        if (report.status === StatusRedux.Succeeded) {
            let cnt: number = 0;
            let monthResult: string[] = [];
            let weekResult: string[] = [];
            let monthOfRow: Date[] = [];
            let weekOfRow: Date[] = [];

            if (filter.month !== 0) {
                while (cnt <= filter.month) {
                    monthOfRow.push(moment(new Date()).subtract(cnt, 'month').toDate());
                    monthResult.push(moment(new Date()).subtract(cnt, 'month').format('MM/YYYY'));
                    cnt++;
                }
            }

            const reports: ICompanyReport | null = {
                company_id: report.companiesReport.company_id,
                country: report.companiesReport.company_id,
                industry: report.companiesReport.industry,
                is_active: report.companiesReport.is_active,
                name: report.companiesReport.name,
                business_units: report.companiesReport.business_units
                    .slice(0)
                    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0)),
            };

            if (filter.week !== 0) {
                reports.business_units[0].statistics.forEach((s) => {
                    weekOfRow.push(moment(s.date).toDate());
                    weekResult.push(`${moment(s.date).format('DD-MM-YYYY')} ${moment(s.date).add('days', 6).format('DD-MM-YYYY')}`);
                });
            }

            if (!byDay) {
                _totalOfRow([dates.from], 'DD-MM-YYYY HH:mm:ss');
            }

            if (datesTable.length) {
                _totalOfRow(datesTable, 'DD-MM-YYYY');
            }

            if (monthOfRow.length) {
                _totalOfRow(monthOfRow, 'MM');
            }

            if (weekOfRow.length) {
                _totalOfRow(weekOfRow, 'DD-MM-YYYY');
            }

            setMonthTable(monthResult);
            setWeekTable(weekResult);
            setReports(reports);
        }
    }, [report.status]);

    /**
     * Get and Set height to div's
     */
    React.useEffect(() => {
        if (ref.current) {
            setHeight(0);
            setTimeout(() => {
                setHeight(ref.current !== null ? ref.current.scrollHeight : 0);
            }, 100);
        }
    }, [ref.current]);

    React.useEffect(() => {
        enumerateDaysBetweenDates(dates.from, dates.to);
    }, [dates.from, dates.to, byDay]);

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;

        setFilter({
            ...filter,
            [name]: value,
        });
    };

    /**
     * Change Inspection Type
     * @param event
     */
    const handleChangeInspectionType = (event: SelectChangeEvent<typeof filter.inspection_types>) => {
        const {
            target: { value },
        } = event;

        setFilter({
            ...filter,
            inspection_types: typeof value === 'string' ? value.split(',') : value,
        });
    };

    /**
     * Change Inspection Status
     * @param event
     */
    const handleChangeInspectionStatus = (event: SelectChangeEvent<typeof filter.inspection_statuses>) => {
        const {
            target: { value },
        } = event;

        setFilter({
            ...filter,
            inspection_statuses: typeof value === 'string' ? value.split(',') : value,
        });
    };

    /**
     * Send Data
     */
    const handleReport = () => {
        let getCompanyBySelectedCompany = companies.companies.filter((company) => company.name === selectedCompany);

        if (byDay) {
            setDatesTable(enumerateDates);
        } else {
            setDatesTable([]);
        }

        setView({
            arrDates: enumerateDates.length > 0,
            dates: enumerateDates.length === 0 && dates.from !== null,
            month: filter.month > 0,
            week: filter.week > 0,
        });

        const body: IArgsCompanyReport = {
            ...filter,
            from_date: byDay ? null : dates.from,
            to_date: byDay ? null : dates.to,
            dates: byDay ? enumerateDates : [],
            business_unit_ids: selectedBusinessUnit.map((bu) => bu.business_unit_id) as string[],
            company_id: getCompanyBySelectedCompany ? (getCompanyBySelectedCompany[0].company_id as string) : '',
        };

        dispatch(
            fetchGetCompaniesReport({
                token,
                body,
            })
        );
    };

    const handleChangeInputDateFrom = (event: Date | null) => {
        setDateFrom({
            ...dateFrom,
            date: event,
        });
    };

    const handleChangeInputTimeFrom = (event: Date | null) => {
        setDateFrom({
            ...dateFrom,
            time: event,
        });
    };

    const handleChangeInputDateTo = (event: Date | null) => {
        setDateTo({
            ...dateTo,
            date: event,
        });
    };

    const handleChangeInputTimeTo = (event: Date | null) => {
        setDateTo({
            ...dateTo,
            time: event,
        });
    };

    const enumerateDaysBetweenDates = (startDate: Date | null, endDate: Date | null) => {
        if (startDate !== null && endDate !== null) {
            if (byDay) {
                let res: Date[] = [];
                let cnt: number = 0;
                let totalDays: number = Math.abs(moment(startDate).subtract(1, 'days').set({ hours: 23, minutes: 59 }).diff(moment(endDate), 'days'));

                if (totalDays) {
                    while (totalDays !== cnt) {
                        let date;

                        if (byDay) {
                            date = UtcTime(new Date(moment(startDate).add(cnt, 'days').format('MM-DD-YYYY')));
                        } else {
                            date = new Date(moment(startDate).add(cnt, 'days').format('MM-DD-YYYY HH:mm'));
                        }

                        res.push(date);
                        cnt++;
                    }
                }

                setEnumerateDates(res);
            } else {
                setEnumerateDates([]);
            }
        } else {
            setEnumerateDates([]);
        }
    };

    const handleChangeByDate = () => {
        setByDate(!byDay);
    };

    /**
     * Change Closure Reason
     * @param event
     */
    const handleChangeClosureReason = (event: SelectChangeEvent<typeof filter.closure_reason>) => {
        const {
            target: { value },
        } = event;

        setFilter({
            ...filter,
            closure_reason: typeof value === 'string' ? value.split(',') : value,
        });
    };

    return (
        <>
            <ExportExcel
                tableRef={tableRef}
                inspectionsType={filter.inspection_types}
                inspectionsStatus={filter.inspection_statuses}
                enumerateDates={enumerateDates}
                fromDate={enumerateDates.length > 0 ? null : dates.from}
                toDate={enumerateDates.length > 0 ? null : dates.to}
                byDate={byDay}
                monthTable={monthTable}
                datesTable={datesTable}
                weekTable={weekTable}
                reports={reports}
                tableName='report-company'
            />
            {reports && (
                <Box
                    sx={{
                        position: 'absolute',
                        right: '23px',
                        top: '90px',
                    }}
                >
                    <DownloadTableExcel
                        filename={`usage-report-${moment(new Date()).format('DD-MM-YYYY')}`}
                        sheet='companies'
                        currentTableRef={tableRef.current}
                    >
                        <Button variant='contained' color='secondary' endIcon={<FileDownloadIcon />}>
                            Export to Excel
                        </Button>
                    </DownloadTableExcel>
                </Box>
            )}

            <Paper elevation={2} sx={classes.tableRoot}>
                <Grid container spacing={1} direction='row' alignItems='center' columns={20}>
                    <Grid item xs={20} sm={10} md={5} lg={2} xl={2}>
                        <Autocomplete
                            value={selectedCompany}
                            fullWidth
                            componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                            onChange={(event: any, company_name: string | null) => {
                                if (company_name) {
                                    let company_id: string = companies?.companies.find((c) => c.name === company_name)?.company_id as string;
                                    setFilter({
                                        ...filter,
                                        company_id: company_id,
                                        business_unit_ids: [],
                                    });
                                    setCompany(company_name);
                                } else {
                                    setCompany(company_name);
                                    setSelectedBusinessUnit([]);
                                    setFilter({
                                        ...filter,
                                        company_id: '',
                                        business_unit_ids: [],
                                    });
                                }
                            }}
                            size='small'
                            id='companies'
                            options={stateCompanies}
                            getOptionLabel={(option: string) => option}
                            isOptionEqualToValue={(option: string, value: string) => option === value || value === ''}
                            renderInput={(params) => <TextField {...params} label='Company Name' variant='outlined' />}
                        />
                    </Grid>
                    <Grid item xs={20} sm={10} md={5} lg={2} xl={2}>
                        <Autocomplete
                            multiple
                            componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                            filterOptions={(options, params) => {
                                const filter = createFilterOptions();
                                // @ts-ignore
                                const filtered = filter(options, params);
                                return [{ name: 'Select All...' }, ...filtered] as IBusinessUnit[];
                            }}
                            value={selectedBusinessUnit}
                            onChange={(event, newValue) => {
                                if (newValue.find((option) => option.name === 'Select All...'))
                                    return setSelectedBusinessUnit(
                                        selectedBusinessUnit.length === stateBusinessUnits.length ? [] : stateBusinessUnits
                                    );
                                setSelectedBusinessUnit(newValue);
                            }}
                            size='small'
                            fullWidth
                            id='bu'
                            disabled={selectedCompany === null}
                            options={stateBusinessUnits}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                                <MenuItem {...props}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={
                                                option.name === 'Select All...' ? selectedBusinessUnit.length === stateBusinessUnits.length : selected
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={option.name} />
                                </MenuItem>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Business Unit'
                                    variant='outlined'
                                    placeholder={selectedBusinessUnit.map((b) => b.name).join(',')}
                                />
                            )}
                            renderTags={(tagValue, getTagProps) => (
                                <div
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                ></div>
                            )}
                        />
                    </Grid>
                    <Grid item xs={20} sm={10} md={5} lg={2} xl={2}>
                        <FormControl size='small' fullWidth>
                            <InputLabel id='inspection-type'>Inspection Type</InputLabel>
                            <Select
                                labelId='inspection-type'
                                id='inspection-type'
                                multiple
                                value={filter.inspection_types}
                                input={<OutlinedInput label='Inspection Type' />}
                                onChange={handleChangeInspectionType}
                                MenuProps={InputCheckBoxProps}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {inspectionListType.map((item, index) => (
                                    <MenuItem dense key={index} value={item.type}>
                                        <Checkbox checked={filter.inspection_types.indexOf(item.type) > -1} />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={20} sm={10} md={5} lg={2} xl={2}>
                        <FormControl size='small' fullWidth>
                            <InputLabel id='inspection-status'>Inspection Status</InputLabel>
                            <Select
                                labelId='inspection-status'
                                id='inspection-status'
                                multiple
                                value={filter.inspection_statuses}
                                onChange={handleChangeInspectionStatus}
                                input={<OutlinedInput label='Inspection Type' />}
                                MenuProps={InputCheckBoxProps}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {Object.keys(InspectionStatusName).map((item, index) => (
                                    <MenuItem dense key={index} value={item}>
                                        <Checkbox checked={filter.inspection_statuses.indexOf(item) > -1} />
                                        <ListItemText primary={item} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={20} sm={10} md={5} lg={2} xl={2}>
                        <FormControl size='small' fullWidth>
                            <InputLabel id='closure_reason'>Closure Reason</InputLabel>
                            <Select
                                labelId='closure-reason'
                                id='closure-reason'
                                multiple
                                value={filter.closure_reason}
                                onChange={handleChangeClosureReason}
                                input={<OutlinedInput label='Closure Reason' />}
                                MenuProps={InputCheckBoxProps}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {Object.keys(ClosureReason).map((item, index) => (
                                    <MenuItem dense key={index} value={item}>
                                        <Checkbox checked={filter?.closure_reason?.indexOf(item) > -1} />
                                        <ListItemText primary={item} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={20} sm={10} md={5} lg={2} xl={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id='select-month'>Month</InputLabel>
                            <Select
                                disabled={filter.week !== 0 || dateFrom.date !== null}
                                fullWidth
                                size='small'
                                label='Month'
                                labelId='select-month'
                                id='select-month'
                                name='month'
                                input={<OutlinedInput label='Month' />}
                                value={filter.month}
                                onChange={handleChangeInput}
                            >
                                <MenuItem dense key={0} value={0}>
                                    Month
                                </MenuItem>
                                {months.map((month: number, index: number) => (
                                    <MenuItem dense key={index} value={month}>
                                        {month} last month
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={20} sm={10} md={5} lg={2} xl={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id='select-week'>Week</InputLabel>
                            <Select
                                disabled={filter.month !== 0 || dateFrom.date !== null}
                                fullWidth
                                input={<OutlinedInput label='Week' />}
                                label='Week'
                                labelId='select-week'
                                id='select-week'
                                name='week'
                                value={filter.week}
                                onChange={handleChangeInput}
                            >
                                <MenuItem dense key={0} value={0}>
                                    Week
                                </MenuItem>
                                {weeks.map((month: number, index: number) => (
                                    <MenuItem dense key={index} value={month}>
                                        {month} last week
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={20} sm={10} md={5} lg={3} xl={3}>
                        <Grid container spacing={1}>
                            <Grid item xs={byDay ? 12 : 8}>
                                <DatePicker
                                    disabled={filter.month !== 0 || filter.week !== 0}
                                    label='Date'
                                    mask='__/__/____'
                                    value={dateFrom.date}
                                    inputFormat='dd/MM/yyyy'
                                    onChange={handleChangeInputDateFrom}
                                    renderInput={(params: any) => <TextField fullWidth={true} size='small' {...params} />}
                                />
                            </Grid>
                            {!byDay && (
                                <Grid item xs={4}>
                                    <TimePicker
                                        disabled={filter.month !== 0 || filter.week !== 0}
                                        onChange={handleChangeInputTimeFrom}
                                        value={dateFrom.time}
                                        label='Time'
                                        mask='__:__'
                                        ampm={false}
                                        disableOpenPicker={!matches}
                                        inputFormat='HH:mm'
                                        renderInput={(params: any) => <TextField fullWidth={true} size='small' {...params} />}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={20} sm={10} md={5} lg={3} xl={3}>
                        <Grid container spacing={1}>
                            <Grid item xs={byDay ? 12 : 8}>
                                <DatePicker
                                    disabled={filter.month !== 0 || filter.week !== 0}
                                    label='Date'
                                    mask='__/__/____'
                                    value={dateTo.date}
                                    inputFormat='dd/MM/yyyy'
                                    onChange={handleChangeInputDateTo}
                                    renderInput={(params: any) => <TextField fullWidth={true} size='small' {...params} />}
                                />
                            </Grid>
                            {!byDay && (
                                <Grid item xs={4}>
                                    <TimePicker
                                        disabled={filter.month !== 0 || filter.week !== 0}
                                        onChange={handleChangeInputTimeTo}
                                        value={dateTo.time}
                                        label='Time'
                                        mask='__:__'
                                        ampm={false}
                                        disableOpenPicker={!matches}
                                        inputFormat='HH:mm'
                                        renderInput={(params: any) => <TextField fullWidth={true} size='small' {...params} />}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction='row' justifyContent='end' alignItems='end'>
                    <Grid item>
                        <FormControlLabel
                            disabled={filter.week !== 0 || filter.month !== 0}
                            control={<Checkbox checked={byDay} color='primary' onChange={handleChangeByDate} />}
                            label='By day'
                            labelPlacement='start'
                        />
                    </Grid>
                    <Grid item>
                        <Button disabled={btnDisable} fullWidth onClick={handleReport} color='secondary' variant='contained'>
                            Search
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={5} direction='row' justifyContent='center' alignItems='center'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {(() => {
                            switch (report.status) {
                                case StatusRedux.Loading:
                                    return <Spinner style={{ height: 'calc(100vh - 295px)' }} />;
                                case StatusRedux.Succeeded:
                                    return (
                                        <>
                                            <Box sx={classes.tableMain}>
                                                <Box sx={classes.tableFixedLeft} style={{ height: `${height}px` }}>
                                                    <Box sx={classes.tableFixedLeftCell}># Dates</Box>
                                                    {view.arrDates &&
                                                        datesTable.map((date, index) => (
                                                            <Box key={index} sx={classes.tableFixedLeftCellByDay}>
                                                                {moment(date).format('DD/MM/YYYY')}
                                                            </Box>
                                                        ))}
                                                    {view.dates && (
                                                        <Box sx={classes.tableFixedLeftCellDate}>{`${moment(dates.from).format(
                                                            'DD/MM/YYYY'
                                                        )} ${moment(dates.to).format('DD/MM/YYYY')}`}</Box>
                                                    )}
                                                    {view.month &&
                                                        monthTable.map((month, index) => (
                                                            <Box key={index} sx={classes.tableFixedLeftCellByDay}>
                                                                {month}
                                                            </Box>
                                                        ))}
                                                    {view.week &&
                                                        weekTable.map((week, index) => (
                                                            <Box key={index} sx={classes.tableFixedLeftCellByDay}>
                                                                {week}
                                                            </Box>
                                                        ))}
                                                    <Box sx={classes.tableFixedLeftCell}># Total</Box>
                                                </Box>
                                                <Box ref={ref} sx={classes.tableBodyMain}>
                                                    {reports !== null &&
                                                        reports.business_units.map((report, index) => {
                                                            return (
                                                                <div style={{ width: '100%' }} key={index}>
                                                                    <Box sx={classes.tableFixedTop}>
                                                                        <Box sx={classes.tableFixedTopCell}>{report.name}</Box>
                                                                        {report.statistics.map((d, i) => (
                                                                            <Box key={i} sx={classes.tableBodyCell}>
                                                                                {d.inspections_count}
                                                                            </Box>
                                                                        ))}
                                                                        <Box sx={classes.tableFixedBottom}>
                                                                            {report.statistics
                                                                                .map((d) => d.inspections_count)
                                                                                .reduce((total, cnt) => total + cnt, 0)}
                                                                        </Box>
                                                                    </Box>
                                                                </div>
                                                            );
                                                        })}
                                                    <Box sx={classes.tableFixedRight} style={{ height: `${height}px` }}>
                                                        <Box sx={classes.tableFixedRightHeader}># Total</Box>
                                                        {totalOfRow.map((total) => (
                                                            <Box sx={classes.tableFixedRightRow}>{total}</Box>
                                                        ))}
                                                        <Box sx={classes.tableFixedRightRow}>
                                                            {totalOfRow.map((per_cnt) => per_cnt).reduce((total, cnt) => total + cnt, 0)}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>
                                    );
                                case StatusRedux.Failed:
                                    return (
                                        <Box sx={classes.emptyResult}>
                                            <Typography variant='overline' display='block' gutterBottom>
                                                Nothing found...
                                            </Typography>
                                        </Box>
                                    );
                            }
                        })()}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default CompanyReport;
