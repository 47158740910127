import {CSSObject, SxProps, Theme} from "@mui/material";
import {keyframes} from '@emotion/react';

const fadeIn = keyframes`
    "0%": {
      opacity: 0
    },
    "100%": {
        opacity: 1
    }
`;

const tableRow = (theme: Theme): CSSObject => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer'
})

const head = (theme: Theme): CSSObject => ({
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
})

const stage = (): CSSObject => ({
    width: 'auto',
    minHeight: '650px',
    height: '650px',
    overflow: 'hidden',
    cursor: 'move'
})

const table = (): CSSObject => ({
    height: '650px'
})

const emptyDiv = (): CSSObject => ({
    width: '100%',
    marginTop: '20px',
    minHeight: '580px',
    height: '100%',
    fontSize: '1.2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const anim = (theme: Theme): CSSObject => ({
    animation: `${fadeIn} 300ms ${theme.transitions.easing.easeInOut}`,
    animationDirection: 'alternate'
})

export const classes = {
    tableRow, anim, emptyDiv, table, stage, head
}