import {CSSObject, Theme} from "@mui/material";

const tabRoot = (): CSSObject => ({
    flexGrow: 1,
})

const form = (): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
})

const iconBtn = (): CSSObject => ({
    marginRight: '10px'
})

const paper = (): CSSObject => ({
    marginBottom: '15px',
    padding: '15px'
})

const headerPopup = (theme: Theme): CSSObject => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
})

const flex = (): CSSObject => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > button': {
        marginLeft: '15px'
    }
})

const marginTop = (): CSSObject => ({
    marginTop: '15px'
})

const buttonBlock = (): CSSObject => ({
    marginBottom: '15px'
})

const formControl = (theme: Theme): CSSObject => ({
    marginTop: theme.spacing(2),
    minWidth: '120px',
})

const formControlLabel = (theme: Theme): CSSObject => ({
    marginTop: theme.spacing(1),
})

const titleBlock = (theme: Theme): CSSObject => ({
    textAlign: 'center',
    marginTop: '10px',
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.primary.main
})

const numberBlock = (theme: Theme): CSSObject => ({
    fontSize: 25,
    marginTop: '5px',
    marginBottom: '10px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    textAlign: 'center',
})

const headerBlockInput = (): CSSObject => ({
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '20px'
})

const activeIcon = (): CSSObject => ({
    textAlign: 'right'
})

const msgPopup = (theme: Theme): CSSObject => ({
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main
})

const msgText = (): CSSObject => ({
    textTransform: 'uppercase',
    fontSize: '1.5em',
    fontWeight: 500
})



export const classes = { buttonBlock, msgText,msgPopup, formControl,marginTop, headerBlockInput,numberBlock,titleBlock,formControlLabel, activeIcon, tabRoot, form, iconBtn, paper, headerPopup, flex}