import {CSSObject, keyframes, Theme} from "@mui/material";

const fadeIn = keyframes`
    "0%": {
      opacity: 0
    },
    "100%": {
        opacity: 1
    }
`;

const stage = (): CSSObject => ({
    width: '100%',
    minHeight: '650px',
    overflow: 'hidden',
    cursor: 'move'
})

const anim = (theme: Theme): CSSObject => ({
    animation: `${fadeIn} 500ms ${theme.transitions.easing.easeInOut}`,
})

export const classes = {
    stage, anim
}