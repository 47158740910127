import {CSSObject, Theme} from "@mui/material";

const headerPopup = (theme: Theme): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '10px 25px'
})

const closeIconPopup = (theme: Theme): CSSObject => ({
    color: `${theme.palette.primary.contrastText}!important`
})

export const classes = {
    headerPopup, closeIconPopup
}