import React, { useContext } from 'react';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../../store';
import { classes } from './style';
import Box from '@mui/material/Box';
import { Spinner } from '../../../../spinner';
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import LensIcon from '@mui/icons-material/Lens';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { fetchCreateInspection, fetchInspectionOdometer } from '../../../../../store/inspection/inspection';
import { inspectionTypeList } from '../../../../../store/inspection/inspection-type';
import { fetchAllFeatures } from '../../../../../store/features/features';
import { backButtonDisabled } from '../../../../../utils/onbeforeunload';
import { InspectionContext } from '../../../../../context/InspectionProvider';
import { InspectionStepper } from '../stepper';
import Paper from '@mui/material/Paper';
import { IInspectionCaseId, IInspectionImageRequest } from '../../../../../infrastructure/DTO/inspection/inspection.dto';
import { IFeature, IFeaturesList } from '../../../../../infrastructure/DTO/inspection/feature.dto';
import errorParseMessage from '../../../../../config/error-parse';

const InspectionType: React.FC = () => {
    const navigate = useNavigate();
    const defaultInspectionType: IFeaturesList = {
        id: 0,
        name: 'Choose your inspection',
        type: 'Choose your inspection',
        content: 'Minimum Image Resolution, 16:9 - 1080X600, 4:3 - 800X600, Maximum Image Resolution, 16:9 - 2000x1125, 4:3 - 2000x1500',
        features: [],
        showFeatures: false,
        showPreviousInspectionId: false,
    };
    const otherInspectionType: IFeaturesList = {
        id: 1,
        name: 'Other',
        type: null,
        content: 'Choose features.',
        features: [],
        showFeatures: true,
        showPreviousInspectionId: false,
    };
    const { inspectionStateContext, inspectionDataContext, setInspectionToState, setInspectionToData } = React.useContext(InspectionContext);
    const { checkPermission, parseToken, token } = useContext(CustomAuthContext);
    const featureLicense: string = 'LICENSE_PLATE_NUMBER_DETECTION';
    const featureConsolidateRes: string = 'CONSOLIDATE_RESULTS';
    const { enqueueSnackbar } = useSnackbar();
    const [inspectionMenu, setInspectionMenu] = React.useState<Array<IFeaturesList>>([]);
    const [content, setContent] = React.useState<string>('');
    const [showInspectionId, setShowInspectionId] = React.useState<boolean>(false);
    const { inspections, statusInspections, errorInspections } = useSelector((state: AppState) => state.inspection);
    const { inspectionListType, statusType } = useSelector((state: AppState) => state.inspectionType);
    const { featuresList, statusFeatures } = useSelector((state: AppState) => state.getFeatures);
    const [featuresToChoose, setFeaturesToChoose] = React.useState<boolean>(false);
    const [selectCheckboxAll, setSelectAll] = React.useState<boolean>(inspectionStateContext.selectAll);
    const dispatch: AppDispatch = useDispatch();
    const [isBtnDisabled, setDisableBtn] = React.useState<{ next: boolean; prev: boolean }>({
        next: true,
        prev: false,
    });

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;

        setInspectionToState({
            ...inspectionStateContext,
            inspection_id: !(value !== 7) || !(value !== 11) ? inspectionStateContext.inspection_id : '',
            [name]: value,
        });
    };

    const handleChangeCheckboxDetection = () => {
        setInspectionToState({
            ...inspectionStateContext,
            detection: !inspectionStateContext.detection,
            matching: false,
        });
    };

    const handleChangeCheckboxMatching = () => {
        setInspectionToState({
            ...inspectionStateContext,
            detection: true,
            matching: !inspectionStateContext.matching,
        });
    };

    const handleChangeConsolidateRes = () => {
        setInspectionToState({
            ...inspectionStateContext,
            consolidateRes: !inspectionStateContext.consolidateRes,
        });
    };

    const handleChangeFeatures = (event: React.ChangeEvent<HTMLInputElement>) => {
        let id = parseInt(event.currentTarget.value);
        let result: IFeature[] = [];

        inspectionStateContext.allFeatures.forEach((item) => {
            if (item.id === id) {
                result.push({ ...item, checked: !item.checked });
            } else {
                result.push(item);
            }
        });

        setInspectionToState({
            ...inspectionStateContext,
            allFeatures: result,
        });
    };

    const handleChangeSelect = () => {
        setSelectAll(!selectCheckboxAll);

        setInspectionToState({
            ...inspectionStateContext,
            selectAll: !selectCheckboxAll,
        });
    };

    React.useEffect(() => {
        dispatch(inspectionTypeList());

        if (inspectionStateContext.allFeatures.length === 0) {
            dispatch(fetchAllFeatures());
        }

        backButtonDisabled(true);
    }, []);

    React.useEffect(() => {
        let result: IFeature[] = [];

        if (selectCheckboxAll) {
            inspectionStateContext.allFeatures.forEach((item) => {
                result.push({ ...item, checked: true });
            });
        } else {
            inspectionStateContext.allFeatures.forEach((item) => {
                result.push({ ...item, checked: false });
            });
        }

        setInspectionToState({
            ...inspectionStateContext,
            allFeatures: result,
        });
    }, [selectCheckboxAll]);

    React.useEffect(() => {
        if (statusType === StatusRedux.Succeeded) {
            setInspectionMenu([...inspectionListType.map((type) => (type.id === 0 ? defaultInspectionType : type)), { ...otherInspectionType }]);
        }
    }, [statusType]);

    React.useEffect(() => {
        if (statusFeatures === StatusRedux.Succeeded) {
            if (inspectionStateContext.allFeatures.length === 0) {
                let result = featuresList.filter((item) => item.type !== featureLicense && item.type !== featureConsolidateRes);
                setInspectionToState({
                    ...inspectionStateContext,
                    allFeatures: result,
                });
            } else {
                setInspectionToState({
                    ...inspectionStateContext,
                    allFeatures: inspectionStateContext.allFeatures,
                });
            }
        }
    }, [statusFeatures]);

    React.useEffect(() => {
        let featuresOther: Array<string> = inspectionStateContext.allFeatures.filter((item) => item.checked).map((item) => item.type);

        if (inspectionMenu.length > 0) {
            let index = inspectionMenu.findIndex((i) => i.type === inspectionStateContext.inspection_type);
            let license_number = inspectionStateContext.detection || inspectionStateContext.matching ? featureLicense : null;
            let consolidate_res = inspectionStateContext.consolidateRes ? featureConsolidateRes : null;

            if (index !== -1) {
                setContent(inspectionMenu[index].content);
                setShowInspectionId(inspectionMenu[index].showPreviousInspectionId);
                setFeaturesToChoose(inspectionMenu[index].showFeatures);

                setInspectionToData({
                    ...inspectionDataContext,
                    client_process_id: inspectionStateContext.claim,
                    client_token: parseToken().UserEmail,
                    basic_vehicle_data: {
                        ...inspectionDataContext.basic_vehicle_data,
                        license_plate_number: inspectionStateContext.licensePlateNumber,
                        country_code: '',
                        odometer: inspectionStateContext.odometer,
                    },
                    previous_inspection_case_id: inspectionStateContext.inspection_id,
                    inspection_type: inspectionStateContext.inspection_type,
                    features: [license_number, consolidate_res].filter((el) => el !== null),
                });
            }

            if (featuresOther.length !== 0) {
                setInspectionToData({
                    ...inspectionDataContext,
                    inspection_type: null,
                    previous_inspection_case_id: '',
                    features: [...featuresOther, license_number, consolidate_res].filter((el) => el !== null),
                });
            }
        }
    }, [inspectionStateContext]);

    React.useEffect(() => {
        if (statusInspections === StatusRedux.Succeeded) {
            if (inspectionStateContext.odometerImg?.image_id) {
                let body: IInspectionImageRequest = {
                    image: inspectionStateContext.odometerImg,
                };

                let args: IInspectionCaseId = {
                    inspection_case: inspections.inspection_case_id,
                };
                dispatch(
                    fetchInspectionOdometer({
                        token,
                        body,
                        args,
                    })
                );
            }

            enqueueSnackbar('Inspection Created Successfully', {
                variant: 'success',
            });

            navigate('/home/inspection/create/3');
        }

        if (statusInspections === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorInspections), {
                variant: 'error',
            });
        }
    }, [statusInspections]);

    React.useEffect(() => {
        if ((showInspectionId && inspectionStateContext.inspection_id === '') || inspectionStateContext.inspection_type === '0') {
            setDisableBtn({
                ...isBtnDisabled,
                next: true,
            });
        } else {
            setDisableBtn({
                ...isBtnDisabled,
                next: false,
            });
        }
    }, [showInspectionId, inspectionStateContext.inspection_id, inspectionStateContext.inspection_type]);

    const handlePrev = (): void => {
        navigate('/home/inspection/create/1');
    };

    const handleNext = (): void => {
        dispatch(
            fetchCreateInspection({
                token: token,
                body: inspectionDataContext,
            })
        );
    };

    return (
        <>
            <Paper elevation={2} sx={classes.paper}>
                <Grid sx={classes.main} container spacing={0} direction='row' justifyContent='flex-end' alignItems='baseline'>
                    {statusInspections === 'loading' ? (
                        <Box sx={classes.loaderBlock}>
                            <Spinner />
                        </Box>
                    ) : null}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={classes.block}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={classes.headerInputs}>Inspection Settings</Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl fullWidth sx={classes.formInput}>
                                <InputLabel id='demo-simple-select-label'>Choose your inspection</InputLabel>
                                <Select
                                    variant='standard'
                                    fullWidth
                                    labelId='select-features'
                                    id='select-features'
                                    name='inspection_type'
                                    value={inspectionStateContext.inspection_type}
                                    onChange={handleChangeInput}
                                >
                                    {inspectionMenu &&
                                        inspectionMenu.map((menu: IFeaturesList, index) => {
                                            return (
                                                <MenuItem key={index} value={menu.type}>
                                                    {menu.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                            {showInspectionId && (
                                <Box sx={classes.inspectionIdForm}>
                                    <Box sx={classes.inspectionIdFormHeader}>Old Inspection</Box>
                                    <TextField
                                        sx={{ marginTop: '15px' }}
                                        autoComplete='off'
                                        size='small'
                                        fullWidth
                                        name='inspection_id'
                                        id='outlined-basic'
                                        type='text'
                                        label='Enter Inspection ID Here'
                                        value={inspectionStateContext.inspection_id}
                                        onChange={handleChangeInput}
                                    />
                                </Box>
                            )}
                            {featuresToChoose && (
                                <>
                                    <Box sx={classes.checkOthersMainSetting}>
                                        <FormControlLabel
                                            sx={classes.checkOthers}
                                            key='50'
                                            control={
                                                <Checkbox
                                                    key='50'
                                                    size='small'
                                                    checked={selectCheckboxAll}
                                                    value='select-all'
                                                    onChange={handleChangeSelect}
                                                    name='select'
                                                />
                                            }
                                            label='Select/Remove All'
                                        />
                                    </Box>
                                    <FormGroup sx={classes.checkOthersMain}>
                                        {inspectionStateContext.allFeatures.map((item, index) => {
                                            return (
                                                <FormControlLabel
                                                    sx={classes.checkOthers}
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            key={item.id}
                                                            size='small'
                                                            checked={item.checked}
                                                            value={item.id}
                                                            onChange={handleChangeFeatures}
                                                            name={item.type}
                                                        />
                                                    }
                                                    label={item.type}
                                                />
                                            );
                                        })}
                                    </FormGroup>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl component='fieldset' sx={classes.formInput}>
                                <FormLabel sx={classes.headerCheckbox} component='legend'>
                                    Additional Features:
                                </FormLabel>
                                <FormGroup>
                                    <Box sx={classes.formCheckbox}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={inspectionStateContext.detection}
                                                    value='LICENSE_PLATE_NUMBER_DETECTION'
                                                    onChange={handleChangeCheckboxDetection}
                                                    name='detection'
                                                />
                                            }
                                            label='LPR Detection'
                                        />
                                        <Tooltip
                                            title='LPR Detection will provide the detected license plate number in the added images at the final inspection report'
                                            arrow
                                        >
                                            <HelpIcon />
                                        </Tooltip>
                                    </Box>
                                    <Box sx={classes.formCheckbox}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={inspectionStateContext.matching}
                                                    value='LICENSE_PLATE_NUMBER_DETECTION'
                                                    onChange={handleChangeCheckboxMatching}
                                                    name='matching'
                                                />
                                            }
                                            label='LPR Matching'
                                        />
                                        <Tooltip
                                            title='LPR Matching will compare the detected license plate number to the number provided in the inspection details and the result will be shown in the final inspection report'
                                            arrow
                                        >
                                            <HelpIcon />
                                        </Tooltip>
                                    </Box>
                                    <Box sx={classes.formCheckbox}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={inspectionStateContext.consolidateRes}
                                                    value='consolidateRes'
                                                    onChange={handleChangeConsolidateRes}
                                                    name='consolidateRes'
                                                />
                                            }
                                            label='CONSOLIDATE RESULTS'
                                        />
                                    </Box>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={classes.block}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={classes.headerInputs}>Inspection Details</Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={classes.list}>
                                <p>
                                    <LensIcon sx={classes.listIcon} />
                                    {content}
                                </p>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <InspectionStepper handleNext={handleNext} handlePrev={handlePrev} id={1} next={isBtnDisabled.next} prev={isBtnDisabled.prev} />
        </>
    );
};

export default InspectionType;
