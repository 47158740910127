import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserServices from '../../services/users/user-services';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs } from '../IFetch';
import { IUsers } from '../../infrastructure/DTO/users/user.dto';
import { IArgsRouteUser } from '../../infrastructure/DTO/users/user-view.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchUserCheckEmail = createAsyncThunk<IUsers, IFetchArgs<IArgsRouteUser>>('user/check-email', async (params) => {
    return await UserServices.checkUserEmail(params);
});

export const checkUserEmail = createSlice({
    name: 'user',
    initialState: {
        usersList: {} as IUsers,
        userListStatus: '',
        userListError: {} as IErrorResponse,
    },
    reducers: {
        ClearStore: (state) => {
            state.usersList = {} as IUsers;
            state.userListStatus = '';
            state.userListError = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserCheckEmail.pending, (state) => {
            state.userListStatus = StatusRedux.Loading;
        });
        builder.addCase(fetchUserCheckEmail.fulfilled, (state, action) => {
            state.userListStatus = StatusRedux.Succeeded;
            state.usersList = action.payload;
        });
        builder.addCase(fetchUserCheckEmail.rejected, (state, action) => {
            state.userListStatus = StatusRedux.Failed;
            state.userListError = JSON.parse(action.error.message as string);
        });
    },
});

export const { ClearStore } = checkUserEmail.actions;
export default checkUserEmail;
