import { CSSObject } from '@mui/material';

const paper = (): CSSObject => ({
    minHeight: '615px',
    marginBottom: '15px',
    padding: '15px',
});

const headerBlockInput = (): CSSObject => ({
    fontWeight: 600,
    textTransform: 'uppercase',
});

const errorMsg = (): CSSObject => ({
    color: 'red',
    fontWeight: 600,
    textTransform: 'uppercase',
});

const buttonBlock = (): CSSObject => ({
    width: '100%',
    textAlign: 'right',
});

export const classes = {
    buttonBlock,
    errorMsg,
    headerBlockInput,
    paper,
};
