import React, { useContext, useRef } from 'react';
import { AppDispatch, AppState } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import Konva from '../../../../../../common/ui-components/Konva';
import { Group, Layer, Image as Pic } from 'react-konva';
import { classes } from './style';
import { clearStatusDamageLab, fetchAnomalyMeasurements } from '../../../../../../store/damage-lab/upload-img';
import { StatusRedux } from '../../../../../../enums/StatusRedux';
import { SkeletonImg } from '../../../../../../common/ui-components/Skeleton';
import Box from '@mui/material/Box';
import { CustomAuthContext } from '../../../../../../context/AuthProvider';
import { IArgsDamageLab } from '../../../../../../infrastructure/DTO/damage-lab/anomalies-detection.dto';
import { useSnackbar } from 'notistack';
import errorParseMessage from "../../../../../../config/error-parse";

const ProjectionThreeD: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { token } = useContext(CustomAuthContext);
    const [coordinates, setCoordinates] = React.useState({ x: 1, y: 1 });
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);
    const { anomalyMeasurements, status, error } = useSelector((state: AppState) => state.damageLabSingleImg);
    const refDiv = useRef<HTMLDivElement | null>(null);
    const [image, setImage] = React.useState({
        img: new window.Image(),
        width: 1,
        height: 1,
    });
    const [size, setSize] = React.useState<{ width: number; height: number }>({ width: 1, height: 1 });
    const [msg, setMsg] = React.useState<string>('');

    React.useEffect(() => {
        if (refDiv.current !== null) {
            setSize({
                width: refDiv.current.clientWidth,
                height: refDiv.current.clientHeight,
            });

            if (anomalyMeasurements.anomaly_measurements !== undefined) {
                if (anomalyMeasurements['3d_projection'] !== null) {
                    renderImg(anomalyMeasurements['3d_projection'].overlay);
                } else {
                    setMsg('Not found!');
                }
            } else {
                const args: IArgsDamageLab = {
                    file_id: damageLabData.uploaded_file_id,
                    inspection_case_id: damageLabData.inspection_case_id,
                };

                dispatch(
                    fetchAnomalyMeasurements({
                        token,
                        args,
                    })
                );
            }
        }
    }, []);

    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            renderImg(anomalyMeasurements['3d_projection']?.overlay);
            dispatch(clearStatusDamageLab());
        }
        if (status === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(error), {
                variant: 'error',
            });
            dispatch(clearStatusDamageLab());
        }
    }, [status]);

    const renderImg = (pic: string) => {
        const img = new window.Image();
        img.src = `data:image/jpeg;base64, ${pic}`;

        img.onload = () => {
            setImage({
                img: img,
                width: img.width,
                height: img.height,
            });
        };
    };

    React.useEffect(() => {
        if (image.width > 1 && image.height > 1) {
            let scale = Math.min(size.width / image.width, size.height / image.height);

            setCoordinates({
                x: size.width / 2 - (image.width * scale) / 2,
                y: 0,
            });
        }
    }, [size.width, size.height, image.width, image.height]);

    const handleMove = (e: any) => {
        setCoordinates({
            x: e.target.x(),
            y: e.target.y(),
        });
    };

    return status === StatusRedux.Loading ? (
        <Grid container spacing={5} direction='row'>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SkeletonImg width={'100%'} height={'650px'} />
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={1} direction='row' justifyContent='center'>
            {msg === '' && (
                <Grid sx={classes.anim} item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Konva wheel={true} konvaRef={refDiv} style={classes.stage} width={size.width} height={size.height}>
                        <Layer>
                            <Group x={coordinates.x} y={coordinates.y} draggable onDragMove={handleMove} onDragEnd={handleMove}>
                                <Pic
                                    width={image.width * Math.min(size.width / image.width, size.height / image.height)}
                                    height={image.height * Math.min(size.width / image.width, size.height / image.height)}
                                    image={image.img}
                                />
                            </Group>
                        </Layer>
                    </Konva>
                </Grid>
            )}
            {msg !== '' && (
                <Grid sx={classes.anim} item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Box sx={classes.emptyMsg}>{msg}</Box>
                </Grid>
            )}
        </Grid>
    );
};

export default ProjectionThreeD;
