import React, { useContext } from 'react';
import { Autocomplete, Paper } from '@mui/material';
import { classes } from './style';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { BasicDialog } from '../../../../../common/ui-components/Dialog';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import { TimeZoneConfig, TimeZoneConfigType } from '../time-zone.config';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { clearStoreStatusCreate, fetchCreateBusinessUnit } from '../../../../../store/business-units/business-units';
import { useSnackbar } from 'notistack';
import { fetchGetProfiles } from '../../../../../store/profile/profiles';
import { UserPermission } from '../../../../../config';
import { IArgsBusinessUnit, ICreateBusinessUnit } from '../../../../../infrastructure/DTO/bussiness-unit/business-units.dto';
import { ILanguage } from '../../../../../infrastructure/DTO/bussiness-unit/language.dto';
import { IArgsProfile, IProfile } from '../../../../../infrastructure/DTO/profile/profile.dto';
import { IUserManagementProvider, UserManagementContext } from '../../../../../context/UserManagementProvider';
import { IInput } from '../../../../../hooks/validation/IInput';
import useCheckInputText from '../../../../../hooks/validation/use-input-text';
import ProfileSelect from '../../../../../common/ui-components/ProfileSelect';
import errorParseMessage from '../../../../../config/error-parse';

interface IAddBusinessUnitComponent {
    count: number;
    setCountBusinessUnits: Function;
}

const initData: Partial<ICreateBusinessUnit> = {
    name: '',
    location: '',
    language: '',
    time_zone: '',
    date_format: 'DD/MM/YYYY',
    profile_id: '',
    is_active: true,
};

interface Fields {
    name: IInput;
    location: null;
}

const AddBusinessUnits: React.FC<IAddBusinessUnitComponent> = (props: IAddBusinessUnitComponent) => {
    const { enqueueSnackbar } = useSnackbar();
    const { checkPermission, token } = useContext(CustomAuthContext);
    const { company, openCreateBusinessUnit, handleCreateBusinessUnits } = useContext<IUserManagementProvider>(UserManagementContext);
    const { statusCreated, error } = useSelector((state: AppState) => state.businessUnits);
    const lang = useSelector((state: AppState) => state.language);
    const [languages, setLanguages] = React.useState<Array<ILanguage>>([]);
    const [_, setProfileSelect] = React.useState<IProfile | null>(null);
    const [timeZoneSelect, setTimeZoneSelect] = React.useState<TimeZoneConfigType | null>(null);
    const [businessUnits, setBusinessUnits] = React.useState<Partial<ICreateBusinessUnit>>(initData);
    const dispatch: AppDispatch = useDispatch();
    const { inputsText, message, checkValueText, clearInputState } = useCheckInputText<Fields>({
        name: {
            required: true,
        },
        location: null,
    });

    /**
     * Handle Send New Business Unit
     * @param event
     */
    const handleSubmit = (event: any) => {
        event.preventDefault();
        const args: IArgsBusinessUnit = {
            company_id: company?.company_id as string,
        };

        const body: Partial<ICreateBusinessUnit> = {
            ...businessUnits,
            name: businessUnits.name?.trim(),
            location: businessUnits.location?.trim(),
            date_format: businessUnits.date_format?.trim(),
        };

        dispatch(fetchCreateBusinessUnit({ token, body, args }));
    };

    /**
     * Clear Input State
     */
    React.useEffect(() => {
        if (!openCreateBusinessUnit) {
            clearInputState(true);
        }
    }, [openCreateBusinessUnit]);

    /**
     * Change Inputs
     * @param event
     */
    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;

        setBusinessUnits({
            ...businessUnits,
            [name]: value,
        });
    };

    /**
     * Get Languages
     */
    React.useEffect(() => {
        if (lang.status === StatusRedux.Succeeded) {
            setLanguages(lang.language);
        }
    }, [lang.status]);

    /**
     * Get Status Create Business Unit
     */
    React.useEffect(() => {
        if (statusCreated === StatusRedux.Succeeded) {
            enqueueSnackbar('Created', {
                variant: 'success',
            });

            handleCreateBusinessUnits();

            props.setCountBusinessUnits(props.count + 1);
            setProfileSelect(null);
            setTimeZoneSelect(null);
            setBusinessUnits(initData);
            dispatch(clearStoreStatusCreate());
        }

        if (statusCreated === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(error), {
                variant: 'error',
            });
        }
    }, [statusCreated]);

    /**
     * Change Select Profile
     * @type IProfile
     * @param profile
     */
    const handelChangeProfile = (profile: IProfile | null) => {
        if (profile) {
            setProfileSelect(profile);
            setBusinessUnits({
                ...businessUnits,
                profile_id: profile.profile_id,
            });
        } else {
            setProfileSelect(null);
            setBusinessUnits({
                ...businessUnits,
                profile_id: '',
            });
        }
    };

    const handleClose = () => {
        setProfileSelect(null);
        setTimeZoneSelect(null);
        setBusinessUnits(initData);
        handleCreateBusinessUnits();
    };

    return (
        <BasicDialog
            handlePopup={handleClose}
            open={openCreateBusinessUnit}
            title='Create New Business Units'
            width='md'
            buttons={
                <Grid container spacing={2} justifyContent='flex-end'>
                    <Grid item>
                        {!checkPermission(UserPermission.USER_MANAGEMENT) && (
                            <Button
                                form='add-bu'
                                size='small'
                                fullWidth
                                disabled={checkPermission(UserPermission.USER_MANAGEMENT) || inputsText?.name || inputsText?.location}
                                type='submit'
                                variant='contained'
                                color='secondary'
                                endIcon={<SaveIcon />}
                            >
                                Save
                            </Button>
                        )}
                    </Grid>
                </Grid>
            }
        >
            <form id='add-bu' autoComplete='off' onSubmit={handleSubmit}>
                <Paper sx={classes.paper} elevation={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name='name'
                                required={true}
                                id='name'
                                type='text'
                                label='Name'
                                variant='outlined'
                                value={businessUnits.name?.trimStart() as string}
                                onChange={handleChangeInput}
                                onKeyUp={checkValueText}
                                helperText={message?.name}
                                error={inputsText?.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name='location'
                                required={false}
                                id='location'
                                type='text'
                                label='Location'
                                variant='outlined'
                                value={businessUnits.location?.trimStart() as string}
                                onChange={handleChangeInput}
                                onKeyUp={checkValueText}
                                helperText={message?.location}
                                error={inputsText?.location}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel id='language'>Language</InputLabel>
                                <Select
                                    labelId='language'
                                    id='language'
                                    name='language'
                                    value={businessUnits.language?.toUpperCase()}
                                    onChange={handleChangeInput}
                                    label='Language'
                                >
                                    <MenuItem key='0' value=''>
                                        Choose Language
                                    </MenuItem>
                                    {languages
                                        .filter((l) => l.is_active)
                                        .map((item, index) => (
                                            <MenuItem key={index + 1} value={item.name.toUpperCase()}>
                                                {item.name.toUpperCase()}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <ProfileSelect company_id={company?.company_id as string} variant='outlined' setData={handelChangeProfile} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name='date_format'
                                required={false}
                                id='timeZone'
                                type='text'
                                label='Date Format'
                                variant='outlined'
                                value={businessUnits.date_format?.trimStart()}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                value={timeZoneSelect}
                                disablePortal
                                autoHighlight
                                fullWidth
                                onChange={(event: any, newValue: TimeZoneConfigType | null) => {
                                    if (newValue !== null) {
                                        setTimeZoneSelect(newValue);
                                        setBusinessUnits({
                                            ...businessUnits,
                                            time_zone: newValue.text,
                                        });
                                    } else {
                                        setTimeZoneSelect(null);
                                        setBusinessUnits({
                                            ...businessUnits,
                                            time_zone: '',
                                        });
                                    }
                                }}
                                id='time_zone'
                                options={TimeZoneConfig?.length !== 0 ? TimeZoneConfig : []}
                                getOptionLabel={(option: TimeZoneConfigType) => (option.text ? option.text : '')}
                                renderInput={(params) => <TextField {...params} label='Time Zone' />}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </BasicDialog>
    );
};

export default AddBusinessUnits;
