import { ServicesBase } from '../ServicesBase';
import { IFetchArgs } from '../../store/IFetch';
import { IArgsImagesOverView, IImageOverViewAssessment, IImagesOverView } from '../../infrastructure/DTO/multilayered/multilayered.dto';

class Multilayered extends ServicesBase {
    static async getImagesOverView(params: IFetchArgs<IArgsImagesOverView>): Promise<IImagesOverView> {
        return await this.services('GET', `inspections/${params.args.inspection_case}/images/images_overview`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async getImageOverView(params: IFetchArgs<IArgsImagesOverView>): Promise<IImageOverViewAssessment> {
        return await this.services('GET', `/inspections/${params.args.inspection_case}/images/${params.args.image_id}/image_overview`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }
}

export default Multilayered;
