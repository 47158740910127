import { IFetchArgs, IFetchBody } from '../../store/IFetch';
import { ServicesBase } from '../ServicesBase';
import {
    IArgsDamages,
    ICreateInspection,
    IDamagesRequest,
    IGetInspectionReports,
    IImageResponse,
    IInspection,
    IInspectionCase,
    IInspectionCaseId,
    IInspectionFilter,
    IInspectionImageRequest,
    IInspectionImagesResponse,
    IInspectionOdometerResponse,
    IInspectionRemoveImage,
    IInspectionSendFileRequest,
    IInspectionSendFileResponse,
    IInspectionsList,
    IInspectionUploadImage,
    IRemoveDamage,
    IStartAsyncProcessRequest,
    IStartAsyncProcessResponse,
    IUpdateInspection,
} from '../../infrastructure/DTO/inspection/inspection.dto';
import { IThresholdSettings } from '../../infrastructure/DTO/threshold-settings/threshold-settings.dto';
import { UtcTime } from '../../utils/UtcTime';

class Inspection extends ServicesBase {
    static async createInspection(params: IFetchBody<ICreateInspection>): Promise<IInspection> {
        return await this.services('POST', '/home/create/inspection', JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async updateInspection(params: IFetchBody<IUpdateInspection, IInspectionCaseId>): Promise<IInspection> {
        return await this.services('PUT', `/home/update/inspection/${params.args?.inspection_case}`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async uploadImages(params: IFetchBody<IInspectionUploadImage, IInspectionCaseId>): Promise<IInspectionImagesResponse> {
        return await this.services(
            'POST',
            `/home/upload/images/inspection/${params.args?.inspection_case}`,
            JSON.stringify({ images: [params.body.images?.[0]] }),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async removeImage(params: IFetchArgs<IInspectionRemoveImage>): Promise<any> {
        return await this.services('DELETE', `/home/inspection/${params.args?.inspection_case}/image_id/${params.args?.image_id}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async startAsyncProcess(params: IFetchArgs<IStartAsyncProcessRequest>): Promise<IStartAsyncProcessResponse> {
        return await this.services('POST', `/home/start_async_process/inspection/${params.args?.inspection_case}/email/${params.args?.email}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async inspectionData(params: IFetchArgs<IInspectionCaseId>): Promise<IInspectionCase> {
        return await this.services('GET', `/home/inspection/${params.args?.inspection_case}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async getInspectionsByFilter(params: IFetchBody<IInspectionFilter>): Promise<IInspectionsList> {
        return await this.services('POST', '/home/inspections/filter', JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async uploadImageOdometer(params: IFetchBody<IInspectionImageRequest, IInspectionCaseId>): Promise<IInspectionOdometerResponse> {
        return await this.services(
            'POST',
            `/home/create/inspection/${params.args?.inspection_case}/odometer`,
            JSON.stringify({
                image: params.body?.image,
            }),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }
    static async getReportFile(params: IFetchArgs<IInspectionCaseId>): Promise<IGetInspectionReports> {
        return await this.services('GET', `/home/companies/inspections/${params.args.inspection_case}/reports`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async sendFile(params: IFetchArgs<IInspectionSendFileRequest>): Promise<IInspectionSendFileResponse> {
        return await this.services(
            'POST',
            `/home/companies/${params.args?.company_id}/inspection_case/${params.args?.inspection_case}/mail/${params.args?.email}`,
            null,
            {
                Authorization: params.token as string,
            }
        );
    }

    static async createDamage(params: IFetchBody<IDamagesRequest, IArgsDamages>): Promise<IImageResponse> {
        return await this.services(
            'POST',
            `/home/inspection/${params.args?.inspection_case}/images/${params.args?.image_id}/damages`,
            JSON.stringify(params.body),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async removeDamageById(params: IFetchArgs<IRemoveDamage>): Promise<any> {
        return await this.services(
            'DELETE',
            `/home/inspection/${params.args?.inspection_case}/images/${params.args?.image_id}/damages/${params.args?.damage_id}`,
            null,
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async createThresholdSettingsReset(params: IFetchBody<IThresholdSettings, IInspectionCaseId>): Promise<IThresholdSettings> {
        return await this.services(
            'POST',
            `/home/inspection/${params.args?.inspection_case}/settings`,
            JSON.stringify({ settings: params.body.settings }),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async getThresholdSettings(params: IFetchArgs<IInspectionCaseId>): Promise<IThresholdSettings> {
        return await this.services('GET', `/home/inspection/${params.args?.inspection_case}/settings`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }
}

export default Inspection;
