import * as React from 'react';
import { BasicDialog } from '../../../../common/ui-components/Dialog';
import { IconButton, Paper, Tooltip } from '@mui/material';
import { classes } from '../style';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { AppDispatch, AppState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import useCheckInputText from '../../../../hooks/validation/use-input-text';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { ClearUpdateCompany, fetchActivateDeactivateCompany, fetchUpdateCompany } from '../../../../store/company/companies';
import { useSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IArgsCompany, ICompany } from '../../../../infrastructure/DTO/company/company.dto';
import SaveIcon from '@mui/icons-material/Save';
import { IInput } from '../../../../hooks/validation/IInput';
import errorParseMessage from '../../../../config/error-parse';

export interface IEditCompanyComponent {
    open: boolean;
    handleClose: Function;
    data: ICompany | null;
}

interface Fields {
    name: IInput;
    country: IInput;
    industry: IInput;
}

const EditCompany: React.FC<IEditCompanyComponent> = (props: IEditCompanyComponent) => {
    const { enqueueSnackbar } = useSnackbar();
    const { token } = React.useContext(CustomAuthContext);
    const { statusUpdate, statusIsActive, errorIsActive, errorUpdate } = useSelector((state: AppState) => state.companies);
    const { inputsText, message, checkValueText, clearInputState } = useCheckInputText<Fields>({
        name: {
            required: true,
        },
        country: {
            required: true,
        },
        industry: {
            required: true,
        },
    });
    const dispatch: AppDispatch = useDispatch();
    const [dataCompany, setDataCompany] = React.useState<ICompany>({});

    /**
     * Reset State
     */
    React.useEffect(() => {
        if (!props.open) {
            setDataCompany({});
            clearInputState(true);
        }
    }, [props.open]);

    /**
     * Get Company to edit
     */
    React.useEffect(() => {
        if (props.data && props.open) {
            setDataCompany(props.data);
        }
    }, [props.data, props.open]);

    /**
     * Change input
     * @param event
     */
    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;
        setDataCompany({
            ...dataCompany,
            [name]: value,
        });
    };

    /**
     * Change branding input
     * @param event
     */
    const handleChangeInputBranding = (event: any) => {
        setDataCompany({
            ...dataCompany,
            branding: {
                visuals_path: event.target.value,
            },
        });
    };

    /**
     * Close Popup
     */
    const handleClosePopup = () => {
        props.handleClose();
    };

    /**
     * Send data
     * @param event
     */
    const handleSubmit = (event: any) => {
        event.preventDefault();

        const body: ICompany = {
            name: dataCompany.name?.trim(),
            industry: dataCompany.industry?.trim(),
            country: dataCompany.country?.trim(),
            branding: { visuals_path: dataCompany.branding?.visuals_path.trim() ?? '' },
        };

        const args: IArgsCompany = {
            company_id: props.data?.company_id,
        };

        dispatch(fetchUpdateCompany({ token, body, args }));
    };

    /**
     * Get status after update
     */
    React.useEffect(() => {
        if (statusUpdate === StatusRedux.Succeeded) {
            enqueueSnackbar('Updated', {
                variant: 'success',
            });

            props.handleClose();
            dispatch(ClearUpdateCompany());
        }

        if (statusUpdate === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorUpdate), {
                variant: 'error',
            });

            dispatch(ClearUpdateCompany());
        }
    }, [statusUpdate]);

    /**
     * Activated/Deactivated status
     */
    React.useEffect(() => {
        if (statusIsActive === StatusRedux.Succeeded) {
            enqueueSnackbar(dataCompany.is_active ? 'Company Deactivated' : 'Company Activated', {
                variant: 'success',
            });

            dispatch(ClearUpdateCompany());
        }

        if (statusIsActive === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorIsActive), {
                variant: 'error',
            });

            dispatch(ClearUpdateCompany());
        }
    }, [statusIsActive]);

    /**
     * Change input Activated/Deactivated
     * @param event
     */
    const handleChangeInputIsActive = (event: any) => {
        const args: IArgsCompany = {
            company_id: props.data?.company_id,
            switch: event.target.value ? 'activation' : 'deactivation',
        };

        setDataCompany({
            ...dataCompany,
            is_active: event.target.value,
        });

        if (event.target.value) {
            dispatch(fetchActivateDeactivateCompany({ token, args }));
        } else {
            dispatch(fetchActivateDeactivateCompany({ token, args }));
        }
    };

    return (
        <BasicDialog
            handlePopup={handleClosePopup}
            open={props.open}
            title={`Company ${props.data?.name}`}
            width='md'
            buttons={
                <Grid container spacing={4} justifyContent='flex-end'>
                    <Grid item>
                        <Button
                            size='small'
                            form='edit-company'
                            disabled={!dataCompany?.is_active || inputsText?.name || inputsText?.country || inputsText?.industry}
                            type='submit'
                            variant='contained'
                            color='secondary'
                            endIcon={<SaveIcon />}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            <form id='edit-company' autoComplete='off' onSubmit={handleSubmit}>
                <Paper sx={classes.paper} elevation={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
                                <Grid item xs>
                                    <TextField
                                        disabled={true}
                                        fullWidth
                                        name='id'
                                        type='text'
                                        label='Company ID'
                                        variant='outlined'
                                        value={dataCompany?.company_id || ''}
                                    />
                                </Grid>
                                <Grid>
                                    <Tooltip arrow disableFocusListener title={<Box>Click To Copy</Box>}>
                                        <IconButton
                                            onClick={() => {
                                                navigator.clipboard.writeText(dataCompany?.company_id as string).then((r) => r);
                                            }}
                                            color='primary'
                                            aria-label='copy profile id'
                                            component='label'
                                        >
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name='name'
                                required={true}
                                type='text'
                                label='Name'
                                variant='outlined'
                                value={dataCompany?.name?.trimStart() || ''}
                                onChange={handleChangeInput}
                                onKeyUp={checkValueText}
                                helperText={message?.name}
                                error={inputsText?.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required={true}
                                name='industry'
                                type='text'
                                label='Industry'
                                variant='outlined'
                                value={dataCompany?.industry?.trimStart() || ''}
                                onChange={handleChangeInput}
                                onKeyUp={checkValueText}
                                helperText={message?.industry}
                                error={inputsText?.industry}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required={true}
                                name='country'
                                type='text'
                                label='Country'
                                variant='outlined'
                                value={dataCompany?.country?.trimStart() || ''}
                                onChange={handleChangeInput}
                                onKeyUp={checkValueText}
                                helperText={message?.country}
                                error={inputsText?.country}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name='branding'
                                type='text'
                                label='Branding'
                                variant='outlined'
                                value={dataCompany?.branding?.visuals_path}
                                onChange={handleChangeInputBranding}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel id='active'>Status</InputLabel>
                                <Select
                                    labelId='active'
                                    id='active'
                                    name='is_active'
                                    value={Number(dataCompany?.is_active) || 0}
                                    onChange={handleChangeInputIsActive}
                                    label='Active'
                                >
                                    <MenuItem key={1} value={1}>
                                        Enabled
                                    </MenuItem>
                                    <MenuItem key={2} value={0}>
                                        Disabled
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </BasicDialog>
    );
};

export default EditCompany;
