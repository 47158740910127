import * as React from 'react';
import './print.css';
import { Provider } from 'react-redux';
import { App } from './components/app';
import store from './store';
import { SnackbarProvider } from 'notistack';
import CustomThemeProvider from './context/CustomThemeProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AuthContextProvider from './context/AuthProvider';
import { createRoot } from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import { Spinner } from './components/spinner';

const container = document.getElementById('root');
const root = createRoot(container!);
const isProduction = true; //process.env.REACT_APP_SERVER === 'SANDBOX';

root.render(
    <SnackbarProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Provider store={store}>
                <CustomThemeProvider>
                    <AuthContextProvider>
                        <CacheBuster
                            currentVersion={packageInfo.version}
                            isEnabled={isProduction}
                            isVerboseMode={false}
                            loadingComponent={<Spinner />}
                            metaFileDirectory={'.'}
                        >
                            <App />
                        </CacheBuster>
                    </AuthContextProvider>
                </CustomThemeProvider>
            </Provider>
        </LocalizationProvider>
    </SnackbarProvider>
);

serviceWorkerRegistration.unregister();
