import { combineReducers, configureStore } from '@reduxjs/toolkit';
import login from '../store/users/login';
import users from '../store/users/users';
import user from '../store/users/user';
import features from '../store/features/features';
import inspection from '../store/inspection/inspection';
import inspectionUploadImages from '../store/inspection/images';
import process from '../store/inspection/process';
import inspectionList from '../store/inspection/inspection-list';
import inspectionType from '../store/inspection/inspection-type';
import popupEvent from '../store/events/popup';
import inspectionFiles from '../store/inspection/files';
import generateApiKeyUser from '../store/api-key/generate-api-key';
import sendFile from './inspection/sendFile';
import roles from './role/role';
import permissions from './permission/permission';
import userCheck from './users/check-email';
import userRole from './role/user-role';
import changeUserPassword from './users/change-password';
import companies from './company/companies';
import company from './company/company';
import reportCompanies from './company/report-company';
import damages from './inspection/damage';
import damageLabSingleImg from './damage-lab/upload-img';
import closeUpRegistration from './damage-lab/close-up-registration';
import damageMatching from './damage-lab/damage-matching';
import createThresholdSettings from './inspection/create-threshold-settings';
import getThresholdSettings from './inspection/get-threshold-settings';
import damageLabImage from './damage-lab/image';
import switchTheme from './system/theme';
import businessUnitsSlice from './business-units/business-units';
import businessUnitSlice from './business-units/business-unit';
import profilesSlice from './profile/profiles';
import checkNameCompany from './company/check-name-company';
import effectiveSettingsSlice from './effective-settings/effective-settings';
import profileSlice from './profile/profile';
import generateTokenSlice from './users/generate-token';
import inspectionFlowSlice from './effective-settings/inspection-flow';
import languageSlice from './language/language';
import exportDamages from './company/damages-export';
import imagesOfInspectionSlice from './inspection/image';
import imagesOverViewSlice from './multilayered/images-overview';
import imageOverViewSlice from './multilayered/image-overview';

const rootReducer = combineReducers({
    userLogin: login.reducer,
    generateApiKey: generateApiKeyUser.reducer,
    users: users.reducer,
    user: user.reducer,
    getFeatures: features.reducer,
    inspectionType: inspectionType.reducer,
    inspection: inspection.reducer,
    inspectionOfImages: imagesOfInspectionSlice.reducer,
    inspectionUploadImages: inspectionUploadImages.reducer,
    process: process.reducer,
    inspectionList: inspectionList.reducer,
    popupEvent: popupEvent.reducer,
    files: inspectionFiles.reducer,
    exportDamages: exportDamages.reducer,
    sendFile: sendFile.reducer,
    getRoles: roles.reducer,
    getPermissions: permissions.reducer,
    checkUserEmail: userCheck.reducer,
    userRole: userRole.reducer,
    changeUserPassword: changeUserPassword.reducer,
    companies: companies.reducer,
    company: company.reducer,
    checkNameCompany: checkNameCompany.reducer,
    reportCompanies: reportCompanies.reducer,
    damages: damages.reducer,
    damageLabSingleImg: damageLabSingleImg.reducer,
    closeUpRegistration: closeUpRegistration.reducer,
    damageMatching: damageMatching.reducer,
    createThresholdSettings: createThresholdSettings.reducer,
    getThresholdSettings: getThresholdSettings.reducer,
    damageLabImage: damageLabImage.reducer,
    switchTheme: switchTheme.reducer,
    businessUnits: businessUnitsSlice.reducer,
    businessUnit: businessUnitSlice.reducer,
    profiles: profilesSlice.reducer,
    profile: profileSlice.reducer,
    effectiveSettings: effectiveSettingsSlice.reducer,
    generateToken: generateTokenSlice.reducer,
    inspectionFlow: inspectionFlowSlice.reducer,
    language: languageSlice.reducer,
    imagesOverView: imagesOverViewSlice.reducer,
    imageOverView: imageOverViewSlice.reducer,
});

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;
export default store;
