import { ServicesBase } from '../ServicesBase';
import {
    ICloseUpRegistration,
    ICloseUpRegistrationResponse,
    IDamageLab,
    IGetAnomaliesDetection,
    IImage,
} from '../../infrastructure/DTO/damage-lab/damage-lab.dto';
import { IFetchArgs, IFetchBody } from '../../store/IFetch';
import { IAnomaliesDetectionRequest, IArgsDamageLab } from '../../infrastructure/DTO/damage-lab/anomalies-detection.dto';
import { IAnomalyMeasurements } from '../../infrastructure/DTO/damage-lab/anomaly-measurements.dto';
import { IDamageMatching } from '../../infrastructure/DTO/damage-lab/damage-matching.dto';
import { IInspectionCaseId } from '../../infrastructure/DTO/inspection/inspection.dto';

class DamageLabServices extends ServicesBase {
    static async uploadSingleImg(params: IFetchBody<IImage>): Promise<IDamageLab> {
        return await this.services('POST', `/home/inspections/singleImage`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async anomaliesDetection(params: IFetchBody<IAnomaliesDetectionRequest, IArgsDamageLab>): Promise<IGetAnomaliesDetection> {
        return await this.services(
            'POST',
            `/home/inspections/${params.args?.inspection_case_id}/singleImage/${params.args?.file_id}/anomaliesDetection`,
            JSON.stringify(params.body),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async anomalyMeasurements(params: IFetchArgs<IArgsDamageLab>): Promise<IAnomalyMeasurements> {
        return await this.services(
            'POST',
            `/home/inspections/${params.args?.inspection_case_id}/singleImage/${params.args?.file_id}/anomalyMeasurements`,
            null,
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async removeInspectionCase(params: IFetchArgs<IInspectionCaseId>): Promise<any> {
        return await this.services('DELETE', `/home/inspection/${params.args?.inspection_case}/singleImage`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async closeUpRegistration(params: IFetchBody<ICloseUpRegistration>): Promise<ICloseUpRegistrationResponse | null> {
        return await this.services('POST', `/home/inspections/singleImage/closeUpRegistration`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async damageMatching(params: IFetchArgs<IArgsDamageLab>): Promise<IDamageMatching> {
        return await this.services(
            'GET',
            `/home/inspections/${params.args?.inspection_case_id}/singleImage/${params.args?.file_id}/damageMatching`,
            null,
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async getAnomaliesDetection(params: IFetchArgs<IArgsDamageLab>): Promise<IGetAnomaliesDetection> {
        return await this.services(
            'GET',
            `/home/inspections/${params.args?.inspection_case_id}/singleImage/${params.args?.file_id}/anomaliesDetection`,
            null,
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }
}

export default DamageLabServices;
