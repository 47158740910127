import React from 'react';
import { ServicesType } from '../types/ServicesBase';

type FetchSettingsType = {
    url: string;
    type: ServicesType;
};

const useFetch = (): [string | Blob, boolean, string, boolean, (url: string, type: ServicesType) => void, () => void] => {
    const [response, setResponse] = React.useState<string | Blob>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [errorMsg, setErrorMsg] = React.useState<string>('');
    const [hasError, setHasError] = React.useState<boolean>(false);

    const [fetchSettings, setFetchSettings] = React.useState<FetchSettingsType>({
        url: '',
        type: 'JSON',
    });

    const setSettings = (url: string, type: ServicesType): void => {
        setFetchSettings({
            url,
            type,
        });
    };

    const _fetchReport = async (url: string, responseType: ServicesType) => {
        const data = await fetch(url);
        let res;

        switch (responseType) {
            case 'JSON':
                res = await data.json();
                break;
            case 'TEXT':
                res = await data.text();
                break;
            case 'BLOB':
                res = await data.blob();
                break;
        }
        return res;
    };

    React.useEffect(() => {
        if (fetchSettings.url?.length > 0) {
            setLoading(true);
            setHasError(false);
            setErrorMsg('');

            _fetchReport(fetchSettings.url, fetchSettings.type)
                .then((data) => {
                    if (fetchSettings.type === 'BLOB') {
                        setResponse(URL.createObjectURL(data));
                    } else {
                        setResponse(data);
                    }
                    setTimeout(() => setLoading(false), 500);
                })
                .catch((error) => {
                    setLoading(false);
                    setHasError(true);
                    setErrorMsg(error.message);
                });
        } else {
            setHasError(true);
            setErrorMsg('Report Not Found!');
        }
    }, [fetchSettings]);

    const clearStateFetch = () => {
        setFetchSettings({
            url: '',
            type: 'JSON',
        });
        setResponse('');
    };

    return [response, loading, errorMsg, hasError, setSettings, clearStateFetch];
};

export default useFetch;
