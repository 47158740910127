import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs } from '../IFetch';
import ApiKeyServices from '../../services/api-key/api-key-services';
import { IApiKey, IArgsApiKey } from '../../infrastructure/DTO/key/api-key.dto';
import { IErrorResponse } from "../../infrastructure/DTO/error/error-response.dto";

export const fetchGenerateApiKey = createAsyncThunk<IApiKey, IFetchArgs<IArgsApiKey>>('key/post', async (params: IFetchArgs<IArgsApiKey>) => {
    return await ApiKeyServices.generateApiKey(params);
});

export const fetchGetApiKey = createAsyncThunk<IApiKey, IFetchArgs<IArgsApiKey>>('key/get', async (params: IFetchArgs<IArgsApiKey>) => {
    return await ApiKeyServices.getApiKeyByUserId(params);
});

export const userKeySlice = createSlice({
    name: 'key',
    initialState: {
        userKeyData: {} as IApiKey,
        createKeyStatus: '',
        createKeyError: {} as IErrorResponse,
        userKeyStatus: '',
        userKeyError: {} as IErrorResponse,
    },
    reducers: {
        clearStateCreateKey: (state) => {
            state.createKeyStatus = '';
            state.createKeyError = {} as IErrorResponse;
        },
        clearStatGetKey: (state) => {
            state.userKeyData = {} as IApiKey;
            state.userKeyStatus = '';
            state.userKeyError = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGenerateApiKey.pending, (state) => {
            state.createKeyStatus = StatusRedux.Loading;
        });
        builder.addCase(fetchGenerateApiKey.fulfilled, (state, action) => {
            state.createKeyStatus = StatusRedux.Succeeded;
            state.userKeyData = action.payload;
        });
        builder.addCase(fetchGenerateApiKey.rejected, (state, action) => {
            state.createKeyStatus = StatusRedux.Failed;
            state.createKeyError = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchGetApiKey.pending, (state) => {
            state.userKeyStatus = StatusRedux.Loading;
        });
        builder.addCase(fetchGetApiKey.fulfilled, (state, action) => {
            state.userKeyStatus = StatusRedux.Succeeded;
            state.userKeyData = action.payload;
        });
        builder.addCase(fetchGetApiKey.rejected, (state, action) => {
            state.userKeyStatus = StatusRedux.Failed;
            state.userKeyError = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStateCreateKey, clearStatGetKey } = userKeySlice.actions;
export default userKeySlice;
