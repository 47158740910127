import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AppDispatch, AppState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { clearStore, fetchUserCreate } from '../../../../store/users/users';
import { Paper } from '@mui/material';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { classes } from './style';
import { useSnackbar } from 'notistack';
import { UserPermission } from '../../../../config';
import { BasicDialog } from '../../../../common/ui-components/Dialog';
import Box from '@mui/material/Box';
import useCheckInputEmail from '../../../../hooks/validation/use-input-email';
import useCheckInputPassword from '../../../../hooks/validation/use-input-password';
import useCheckInputText from '../../../../hooks/validation/use-input-text';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { IUserCreate } from '../../../../infrastructure/DTO/users/user.dto';
import { IArgsRouteUser } from '../../../../infrastructure/DTO/users/user-view.dto';
import { ICompany } from '../../../../infrastructure/DTO/company/company.dto';
import { IBusinessUnit } from '../../../../infrastructure/DTO/bussiness-unit/business-units.dto';
import SaveIcon from '@mui/icons-material/Save';
import CompaniesSelect from '../../../../common/ui-components/CompaniesSelect';
import BusinessUnitSelect from '../../../../common/ui-components/BusinessUnitSelect';
import { IInput } from '../../../../hooks/validation/IInput';
import errorParseMessage from '../../../../config/error-parse';

interface ICreateUserComponent {
    company: ICompany | null;
    open: boolean;
    handleClose: Function;
    countUsers: number;
    setCountUsers: Function;
}

const initState = {
    email: '',
    name: '',
    is_active: true,
    password: '',
    business_unit_id: '',
};

interface Fields {
    name: IInput;
}

const CreateUser: React.FC<ICreateUserComponent> = (props: ICreateUserComponent) => {
    const { enqueueSnackbar } = useSnackbar();
    const { checkPermission, parseToken, token } = useContext(CustomAuthContext);
    const dispatch: AppDispatch = useDispatch();
    const { inputEmail, handleFocusInputEmail, checkValueEmail } = useCheckInputEmail();
    const { inputPassword, passwordConfirm, checkValuePassword, checkValuePasswordConfirm } = useCheckInputPassword();
    const { inputsText, message, checkValueText, clearInputState } = useCheckInputText<Fields>({
        name: {
            required: true,
        },
    });
    const [businessUnitSelect, setBusinessUnitSelect] = React.useState<IBusinessUnit | null>(null);
    const { userDataCreateStatus, userDataCreateError } = useSelector((state: AppState) => state.users);
    const [passwordValueConfirm, setPasswordValueConfirm] = React.useState<string>('');
    const [companySelect, setCompanySelect] = React.useState<ICompany | null>(null);
    const [dataUser, setDataUser] = React.useState<IUserCreate>(initState);

    /**
     * Close Popup
     */
    const handleClosePopup = () => {
        props.handleClose();
        setDataUser({
            ...dataUser,
            email: '',
            name: '',
        });
        setBusinessUnitSelect(null);
        setCompanySelect(null);
        clearInputState(true);
    };

    /**
     * @param event
     * Change input
     */
    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;

        setDataUser({
            ...dataUser,
            [name]: value,
        });
    };

    /**
     * @param event
     * Change input email
     */
    const handleChangeInputEmail = (event: any) => {
        setDataUser({
            ...dataUser,
            email: event.target.value.toLowerCase(),
        });
    };

    /**
     * @param event
     * Change input password
     */
    const handleChangeInputPassword = (event: any) => {
        setDataUser({
            ...dataUser,
            password: event.target.value,
        });
    };

    /**
     * @param event
     * Change input password confirm
     */
    const handleChangeInputPasswordConfirm = (event: any) => {
        setPasswordValueConfirm(event.target.value);
    };

    /**
     * @param event
     * Se to create data
     */
    const handleSubmit = (event: any) => {
        event.preventDefault();
        let company_id: string | undefined = companySelect?.company_id;

        const args: IArgsRouteUser = {
            company_id: company_id ? company_id : parseToken().company,
        };

        const data: IUserCreate = {
            ...dataUser,
            name: dataUser.name?.trim(),
        };

        dispatch(
            fetchUserCreate({
                body: data,
                args: args,
                token: token,
            })
        );
    };

    /**
     *  Get status
     */
    React.useEffect(() => {
        if (userDataCreateStatus === StatusRedux.Succeeded) {
            enqueueSnackbar('User added successfully', {
                variant: 'success',
            });
            props.setCountUsers(props.countUsers + 1);
            props.handleClose();
            setDataUser({
                email: '',
                name: '',
                is_active: true,
                password: '',
                business_unit_id: '',
            });
            setPasswordValueConfirm('');
            dispatch(clearStore());
        }
        if (userDataCreateStatus === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(userDataCreateError), {
                variant: 'error',
            });
            dispatch(clearStore());
        }
    }, [userDataCreateStatus]);

    return (
        <BasicDialog
            handlePopup={handleClosePopup}
            open={props.open}
            title='Create New User'
            width='md'
            buttons={
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={classes.flex}>
                        <Button
                            form='create-user'
                            size='small'
                            disabled={checkPermission(UserPermission.USER_MANAGEMENT) || inputsText?.name}
                            type='submit'
                            variant='contained'
                            color='secondary'
                            endIcon={<SaveIcon />}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            <form id='create-user' autoComplete='off' onSubmit={handleSubmit}>
                <Paper sx={classes.paper} elevation={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box sx={classes.headerBlockInput}>Contact Information</Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name='name'
                                required={true}
                                id='user_name'
                                type='text'
                                label='Name'
                                variant='outlined'
                                value={dataUser.name?.trimStart() as string}
                                onChange={handleChangeInput}
                                onKeyUp={checkValueText}
                                helperText={message?.name}
                                error={inputsText?.name}
                            />
                        </Grid>
                        {!checkPermission(UserPermission.ADMIN) && (
                            <Grid item xs={12}>
                                <CompaniesSelect variant='outlined' data={companySelect} setData={setCompanySelect} />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <BusinessUnitSelect
                                variant='outlined'
                                company={companySelect}
                                disabled={
                                    !checkPermission(UserPermission.ADMIN) ? companySelect === null : checkPermission(UserPermission.USER_MANAGEMENT)
                                }
                                setData={setBusinessUnitSelect}
                                data={businessUnitSelect}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name='email'
                                required={true}
                                id='email'
                                type='email'
                                label='E-mail'
                                variant='outlined'
                                value={dataUser.email}
                                onChange={handleChangeInputEmail}
                                onFocus={handleFocusInputEmail()}
                                onKeyUp={checkValueEmail}
                                error={inputEmail.error}
                                helperText={inputEmail.error ? inputEmail.errorMsg : null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name='password'
                                error={inputPassword.error}
                                helperText={inputPassword.error ? inputPassword.errorMsg : null}
                                required={true}
                                id='password'
                                type='password'
                                label='Password'
                                variant='outlined'
                                value={dataUser.password?.trim()}
                                onKeyUp={checkValuePassword}
                                onChange={handleChangeInputPassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name='password_confirmation'
                                error={passwordConfirm.error}
                                helperText={passwordConfirm.error ? passwordConfirm.errorMsg : null}
                                required={true}
                                id='password_confirmation'
                                type='password'
                                label='Confirm Password'
                                variant='outlined'
                                value={passwordValueConfirm.trim()}
                                onKeyUp={checkValuePasswordConfirm}
                                onChange={handleChangeInputPasswordConfirm}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </BasicDialog>
    );
};

export default CreateUser;
