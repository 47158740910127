import React, { useContext } from 'react';
import { Box, Button, Chip, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { SkeletonImg, SkeletonList } from '../../../../../../common/ui-components/Skeleton';
import { classes } from '../style';
import ImageMatching from './image-matching/image-matching';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../../../store';
import { clearStateStatusGetAnomaliesDetection, fetchGetAnomaliesDetection } from '../../../../../../store/damage-lab/damage-matching';
import { StatusRedux } from '../../../../../../enums/StatusRedux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CustomAuthContext } from '../../../../../../context/AuthProvider';
import { IArgsDamageLab } from '../../../../../../infrastructure/DTO/damage-lab/anomalies-detection.dto';
import { IMatchedDamages, IMatching } from '../../../../../../infrastructure/DTO/damage-lab/damage-matching.dto';
import { IRoi } from '../../../../../../infrastructure/DTO/damage-lab/damage-lab.dto';

interface IMatchingViewComponent {
    data: IMatching;
    handleClose: Function;
}

const MatchingView: React.FC<IMatchingViewComponent> = (props: IMatchingViewComponent) => {
    const { token } = useContext(CustomAuthContext);
    const { imageMain } = useSelector((state: AppState) => state.damageLabImage);
    const dispatch: AppDispatch = useDispatch();
    const [show, setShow] = React.useState<boolean>(true);

    const { getAnomaliesDetection, statusGetAnomaliesDetection } = useSelector((state: AppState) => state.damageMatching);
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);

    const [roiUploaded, setRoiUploaded] = React.useState<IRoi>({ height: 1, width: 1, x: 1, y: 1 });
    const [roiRepository, setRoiRepository] = React.useState<IRoi>({ height: 1, width: 1, x: 1, y: 1 });

    const [matchedDamageIdUploaded, setMatchedDamageIdUploaded] = React.useState<string | undefined>(undefined);
    const [matchedDamageIdRepository, setMatchedDamageIdRepository] = React.useState<string | undefined>(undefined);

    const [showAll, setShowAll] = React.useState<boolean>(true);
    const [color, setColor] = React.useState<string | undefined>(undefined);

    const [image, setImage] = React.useState({
        image: new window.Image(),
        width: 1,
        height: 1,
    });

    React.useEffect(() => {
        const args: IArgsDamageLab = {
            file_id: props.data.matched_uploaded_file_id,
            inspection_case_id: props.data.matched_inspection_case_id,
        };

        dispatch(
            fetchGetAnomaliesDetection({
                token,
                args,
            })
        );

        return () => {
            dispatch(clearStateStatusGetAnomaliesDetection());
        };
    }, []);

    React.useEffect(() => {
        if (statusGetAnomaliesDetection === StatusRedux.Succeeded) {
            if (getAnomaliesDetection.original_image !== undefined) {
                const img = new window.Image();
                img.src = `data:image/jpeg;base64, ${getAnomaliesDetection.original_image}`;
                img.onload = () => {
                    setImage({
                        image: img,
                        width: img.width,
                        height: img.height,
                    });
                };
            }

            setShow(false);
        }
    }, [statusGetAnomaliesDetection]);

    const handleToggle = (matched: IMatchedDamages) => () => {
        setMatchedDamageIdRepository(matched.matched_damage_id);
        setMatchedDamageIdUploaded(matched.detected_damage_id);
        setShowAll(false);
        setColor(`rgba(${matched.label_color.color_value})`);

        let resRoiRepository = getAnomaliesDetection.detected_damages.filter((i) => i.damage_id === matched.matched_damage_id);
        let resRoiUploaded = damageLabData.detected_damages.filter((i) => i.damage_id === matched.detected_damage_id);

        if (resRoiRepository.length > 0) {
            setRoiRepository(resRoiRepository[0].roi);
        }

        if (resRoiUploaded.length > 0) {
            setRoiUploaded(resRoiUploaded[0].roi);
        }
    };

    const handleBack = () => {
        props.handleClose();
    };

    const handleShowAllMatches = () => {
        setShowAll(!showAll);
    };

    return (
        <>
            <Grid container spacing={2} direction='row' justifyContent='flex-start' alignItems='flex-start'>
                <Grid item xs={12}>
                    <Box sx={classes.panelTop}>
                        <Button size='small' onClick={handleBack} variant='outlined' color='primary'>
                            <ChevronLeftIcon /> BACK
                        </Button>
                        <Button size='small' disabled={showAll} onClick={handleShowAllMatches} variant='outlined' color='primary'>
                            {showAll ? <VisibilityIcon /> : <VisibilityOffIcon />} Show All Matches
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            {show ? (
                <Grid container spacing={2} direction='row' justifyContent='flex-start' alignItems='flex-start'>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <SkeletonImg width={'100%'} height={'550px'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <SkeletonImg width={'100%'} height={'550px'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <SkeletonList checkbox={false} num={5} />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2} direction='row' justifyContent='flex-start' alignItems='flex-start'>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Box textAlign='center'>
                            <Typography variant='h6' gutterBottom>
                                Uploaded Image
                            </Typography>
                        </Box>
                        <Box sx={classes.imgUploaded}>
                            <ImageMatching
                                roi={roiUploaded}
                                view='uploaded'
                                color={color}
                                handleShowAllMatches={handleShowAllMatches}
                                viewAll={showAll}
                                matched_damages={props.data}
                                damages={damageLabData.detected_damages}
                                image={imageMain}
                                viewCurrentId={matchedDamageIdUploaded}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Box textAlign='center'>
                            <Typography variant='h6' gutterBottom>
                                Image From Repository
                            </Typography>
                        </Box>
                        <Box sx={classes.imgUploaded}>
                            <ImageMatching
                                roi={roiRepository}
                                view='repository'
                                color={color}
                                handleShowAllMatches={handleShowAllMatches}
                                viewAll={showAll}
                                viewCurrentId={matchedDamageIdRepository}
                                matched_damages={props.data}
                                damages={getAnomaliesDetection.detected_damages}
                                image={image}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Box textAlign='center'>
                            <Typography variant='h6' gutterBottom>
                                Detected Matches
                            </Typography>
                        </Box>
                        <TableContainer sx={classes.containerTable}>
                            <Table stickyHeader size='small' aria-label='a dense table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='left'>#</TableCell>
                                        <TableCell align='left'>Label</TableCell>
                                        <TableCell align='left'>Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.data.matched_damages.map((row, index) => (
                                        <TableRow sx={classes.tableRow} key={index + 1} onClick={handleToggle(row)}>
                                            <TableCell align='left'>{index + 1}</TableCell>
                                            <TableCell align='left'>
                                                {
                                                    <Chip
                                                        style={{ background: `rgba(${row.label_color.color_value})` }}
                                                        size='small'
                                                        label={row.score}
                                                    />
                                                }
                                            </TableCell>
                                            <TableCell align='left'>{row.score}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default MatchingView;
