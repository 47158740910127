import React from 'react';
import Typography from '@mui/material/Typography';
import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import Box from '@mui/material/Box';
import useFetch from '../../../../../../hooks/use-fetch';

interface IImagesListComponent {
    link: string;
    current: string;
    camera_view: string;
    id: string;
    handleChangeMainImage(id: string): void;
}

const ImagesList: React.FC<IImagesListComponent> = (props: IImagesListComponent) => {
    return (
        <Card raised={props.current !== props.id} sx={props.current === props.id ? { border: '2px solid #388e3c' } : null} variant={'elevation'}>
            <CardActionArea disabled={props.current === props.id}>
                <CardMedia
                    onClick={() => props.handleChangeMainImage(props.id)}
                    component='img'
                    height='150'
                    image={props.link}
                    alt={props.camera_view}
                />
                <CardContent>
                    <Typography gutterBottom variant='button' component='div'>
                        {props.camera_view}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ImagesList;
