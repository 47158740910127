import React, { useContext } from 'react';
import { Alert, AlertTitle, Button, Card, CardActionArea, CardMedia, Dialog, Paper, Slide } from '@mui/material';
import Box from '@mui/material/Box';
import { classes } from './style';
import ImageUploading from 'react-images-uploading';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PopupInfo from '../../popup-info/popup-info';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import PopupBack from '../../../../popup-back/popup-back';
import { TransitionProps } from '@mui/material/transitions';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearStatus,
    clearStoreImages,
    fetchRemoveImageInspection,
    fetchUploadImagesInspection,
    setImagesRequest,
} from '../../../../../store/inspection/images';
import { backButtonDisabled } from '../../../../../utils/onbeforeunload';
import { clearStatusInspections, fetchInspectionOdometer, fetchUpdateInspection } from '../../../../../store/inspection/inspection';
import { InspectionStepper } from '../stepper';
import InspectionStartProcess from './inspection-start-process';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Odometer from '../../odometer/odometer';
import { InspectionContext } from '../../../../../context/InspectionProvider';
import usePrevious from '../../../../../hooks/use-previous';
import { JsonViewerTheme } from '@textea/json-viewer';
import {
    IImagesRequest,
    IImageResponse,
    IInspectionCaseId,
    IInspectionImageRequest,
    IInspectionRemoveImage,
    IInspectionUploadImage,
    IUpdateInspection,
} from '../../../../../infrastructure/DTO/inspection/inspection.dto';
import errorParseMessage from '../../../../../config/error-parse';

const InspectionUploadImages: React.FC = () => {
    const { token } = useContext(CustomAuthContext);
    const { inspectionStateContext, inspectionDataContext } = React.useContext(InspectionContext);
    const [isBtnDisabled, setDisableBtn] = React.useState<{ next: boolean; prev: boolean }>({
        next: true,
        prev: false,
    });
    const maxNumber: number = 100;
    const currentTheme = localStorage.getItem('theme') || 'light';
    const [themeJson, setThemeJson] = React.useState<JsonViewerTheme>(currentTheme as JsonViewerTheme);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const [isClicked, setIsClicked] = React.useState<boolean>(false);
    const [images, setImagesView] = React.useState<any>([]);
    const [removeImg, setRemoveImg] = React.useState<{ id?: string | null; index?: number | null; name?: string | null; showDialog: boolean }>({
        id: null,
        index: null,
        name: null,
        showDialog: false,
    });
    const [popupInfo, setPopupInfo] = React.useState<{ open: boolean; id: string; imgBase64: string; data: IImageResponse | undefined }>({
        data: undefined,
        imgBase64: '',
        open: false,
        id: '',
    });
    const { inspections, inspectionOdometer, statusOdometer, errorOdometer } = useSelector((state: AppState) => state.inspection);
    const [asyncProcessOpen, setAsyncProcessOpen] = React.useState<boolean>(false);
    const [viewDisplay, setViewDisplay] = React.useState<string>('none');
    const { inspectionImagesData, inspectionImagesDataRequest, errorImagesDataMsg, statusImagesData } = useSelector(
        (state: AppState) => state.inspectionUploadImages
    );
    const { statusRemoveImage, errorRemoveImage } = useSelector((state: AppState) => state.inspectionUploadImages);
    const [popupBack, setPopupBack] = React.useState<boolean>(false);
    const [showAlertOdometer, setShowOdometer] = React.useState<{ show: boolean; inputs: boolean }>({
        show: false,
        inputs: false,
    });
    const prevInspectionImagesData = usePrevious<Array<IImageResponse>>(inspectionImagesData);

    const handleImages = (imageList: any) => {
        let res = imageList
            .filter((item: any) => item.image_id === undefined)
            .map((item: any, index: number) => {
                return {
                    image_id: `f${(+new Date()).toString(18) + index}`,
                    content: item.data_url.split(',')[1],
                    name: item.file.name,
                    content_type: item.file.type,
                    context: {
                        view_type: '',
                        side: '',
                        tag: '',
                    },
                    resolution: {
                        width: 0,
                        height: 0,
                    },
                    image_quality_result: {
                        status: 'FAILED',
                        message: 'error',
                    },
                    settings: {
                        image_classification: '',
                        isSend: true,
                        loading: true,
                        isCompleted: false,
                        error: false,
                    },
                };
            });

        setImagesView(images.concat(res));

        const data = {
            inspection_case: inspections.inspection_case_id,
            images: { images: res },
        };

        (async () => {
            let len = data.images.images.length;
            while (len !== 0) {
                let img: any = data.images.images.shift();
                await uploadImages({ images: [img] }).then((data) => {
                    if (data) {
                        len = len - 1;
                    }
                });
            }
        })();
    };

    const sendOdometerAgain = () => {
        let body: IInspectionImageRequest = {
            image: inspectionStateContext.odometerImg,
        };

        let args: IInspectionCaseId = {
            inspection_case: inspections.inspection_case_id,
        };

        if (body.image !== undefined) {
            dispatch(
                fetchInspectionOdometer({
                    token,
                    body,
                    args,
                })
            );

            setShowOdometer({ show: false, inputs: false });
        } else {
            const body: IUpdateInspection = {
                ...inspectionDataContext.basic_vehicle_data,
                odometer: inspectionStateContext.odometer,
            };

            const args: IInspectionCaseId = {
                inspection_case: inspections.inspection_case_id,
            };

            dispatch(
                fetchUpdateInspection({
                    token,
                    body,
                    args,
                })
            );

            setShowOdometer({ show: false, inputs: false });
        }
    };

    React.useEffect(() => {
        if (statusOdometer === StatusRedux.Succeeded) {
            if (inspectionOdometer.errors !== undefined) {
                setShowOdometer({
                    show: true,
                    inputs: false,
                });
            } else {
                enqueueSnackbar(`Odometer Number: ${inspectionOdometer.odometer}`, {
                    variant: 'success',
                    autoHideDuration: 5000,
                });
                setShowOdometer({ show: false, inputs: false });
            }
        }
        if (statusOdometer === StatusRedux.Failed) {
            setShowOdometer({
                show: true,
                inputs: false,
            });

            enqueueSnackbar(errorParseMessage(errorOdometer), {
                variant: 'error',
                autoHideDuration: 5000,
            });
        }
    }, [statusOdometer]);

    React.useEffect(() => {
        if (images.length !== 0) {
            setViewDisplay('flex');
        } else {
            setViewDisplay('none');
        }
    }, [images.length]);

    const handleImagePopupInfo = (img_id: string, img: string) => () => {
        let data: any = inspectionImagesData.filter((item: IImageResponse) => item.image_id === img_id);

        if (data.length !== 0) {
            setPopupInfo({
                open: true,
                id: img_id,
                imgBase64: img,
                data: data[0],
            });
        }
    };

    const handleClosePopup = () => {
        setPopupBack(false);
    };

    const handleClose = () => {
        setPopupInfo({
            open: false,
            id: '',
            imgBase64: '',
            data: undefined,
        });
    };

    React.useEffect(() => {
        setDisableBtn({
            next: inspectionImagesData.length !== images.length || images.length === 0,
            prev: false,
        });
    }, [inspectionImagesData.length, images.length]);

    React.useEffect(() => {
        if (inspectionImagesData !== prevInspectionImagesData) {
            inspectionImagesData.forEach((res: any) => {
                let index = images.findIndex((x: any) => x.image_id === res.image_id);

                setImagesView([
                    ...images.slice(0, index),
                    {
                        ...images[index],
                        image_quality_result: res.image_quality_result,
                        settings: { image_classification: res.image_classification, error: false, isCompleted: true, isSend: true, loading: false },
                    },
                    ...images.slice(index + 1),
                ]);
            });

            if (statusImagesData === StatusRedux.Succeeded || statusImagesData === StatusRedux.Failed) {
                dispatch(clearStatus());
            }
        }

        if (statusImagesData === StatusRedux.Failed) {
            let index = images.findIndex((x: any) => x.image_id === inspectionImagesDataRequest[0].image_id);

            setImagesView([
                ...images.slice(0, index),
                {
                    ...images[index],
                    image_quality_result: images[index].image_quality_result,
                    settings: {
                        image_classification: images[index].image_classification,
                        error: true,
                        isCompleted: true,
                        isSend: true,
                        loading: false,
                    },
                },
                ...images.slice(index + 1),
            ]);
        }
    }, [inspectionImagesData, statusImagesData]);

    const onRemoveImage = (i: number, id: string, name: string) => {
        setRemoveImg({
            index: i,
            id: id,
            name: name,
            showDialog: true,
        });
    };

    const handleCancelRemoveImg = () => {
        setRemoveImg({
            showDialog: false,
        });
    };

    const handleRemoveImg = () => {
        let imagesList = [...images];

        if (typeof removeImg.index === 'number') {
            if (images[removeImg.index].settings.error) {
                imagesList.splice(removeImg.index, 1);
                setImagesView(imagesList);
                setRemoveImg({
                    showDialog: false,
                });
            } else {
                const args: IInspectionRemoveImage = {
                    inspection_case: inspections.inspection_case_id,
                    image_id: removeImg.id,
                };
                dispatch(
                    fetchRemoveImageInspection({
                        token,
                        args,
                    })
                );
            }
        }
    };

    React.useEffect(() => {
        if (statusRemoveImage === StatusRedux.Loading) {
            setIsClicked(true);
        }

        if (statusRemoveImage === StatusRedux.Succeeded) {
            let imagesList = [...images];
            if (typeof removeImg.index === 'number') {
                imagesList.splice(removeImg.index, 1);
            }
            setImagesView(imagesList);
            setIsClicked(false);

            setRemoveImg({
                showDialog: false,
            });
        }

        if (statusRemoveImage === StatusRedux.Failed) {
            setRemoveImg({
                showDialog: false,
            });

            enqueueSnackbar(errorParseMessage(errorRemoveImage), {
                variant: 'error',
            });

            setIsClicked(false);
        }
    }, [statusRemoveImage]);

    React.useEffect(() => {
        if (statusImagesData === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorImagesDataMsg), {
                variant: 'error',
            });
        }
    }, [statusImagesData]);

    React.useEffect(() => {
        dispatch(clearStoreImages());
        backButtonDisabled(true);
        if (inspections.inspection_case_id === undefined) {
            navigate('/home/inspection/create/1');
        }

        return () => {
            dispatch(clearStoreImages());
        };
    }, []);

    const goToBack = () => {
        navigate('/home/inspection/create/2');
        dispatch(clearStatusInspections());
        dispatch(clearStatus());
        dispatch(clearStoreImages());
        setPopupBack(false);
    };

    const handlePrev = (): void => {
        setPopupBack(true);
    };

    const handleNext = (): void => {
        setAsyncProcessOpen(true);
    };

    const uploadImages = (body: IInspectionUploadImage) => {
        const args: IInspectionCaseId = {
            inspection_case: inspections.inspection_case_id,
        };
        return new Promise((resolve) => {
            dispatch(setImagesRequest(body));
            return resolve(dispatch(fetchUploadImagesInspection({ token, body, args })));
        });
    };

    React.useEffect(() => {
        setThemeJson(currentTheme as JsonViewerTheme);
    }, [currentTheme]);

    return (
        <>
            <Paper elevation={2} sx={classes.paper}>
                {showAlertOdometer.show && (
                    <Alert severity='error' sx={classes.alertBlock}>
                        <AlertTitle>Odometer</AlertTitle>
                        Would you like to upload a new <strong>image</strong> or enter the <strong>number</strong> manually?
                        <Box sx={classes.alertBtnsBlock}>
                            <Button
                                onClick={() => setShowOdometer({ ...showAlertOdometer, inputs: true })}
                                size='small'
                                variant='contained'
                                color='primary'
                            >
                                Upload new Image
                            </Button>
                            <Box sx={classes.alertBtnsBlockRight}>
                                <Button
                                    onClick={() => setShowOdometer({ show: false, inputs: false })}
                                    size='small'
                                    variant='outlined'
                                    color='primary'
                                >
                                    Close
                                </Button>
                                <Tooltip title='If you will press this button the odometer value will be removed from the final inspection' arrow>
                                    <HelpIcon />
                                </Tooltip>
                            </Box>
                        </Box>
                        {showAlertOdometer.inputs && (
                            <>
                                <Box mt={2} sx={classes.inputBlock}>
                                    <Odometer />
                                </Box>
                                <Box mt={2} sx={classes.inputBlock}>
                                    <Button onClick={sendOdometerAgain} size='small' variant='contained' color='primary'>
                                        Send
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Alert>
                )}
                <div className='upload-box'>
                    <ImageUploading multiple value={images} onChange={handleImages} maxNumber={maxNumber} dataURLKey='data_url'>
                        {({ onImageUpload, dragProps }) => (
                            <div className='uploadImageWrapper'>
                                <div className='upload' onClick={onImageUpload} {...dragProps}>
                                    <div className='icon'>
                                        <svg width='44' height='43' viewBox='0 0 44 43' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                            <path d='M21.6694 40.4079L21.6694 21.7413' stroke='#000133' strokeWidth='2' />
                                            <path d='M30.0308 27.5746L21.6685 19.4068L13.3063 27.5746' stroke='#000133' strokeWidth='2' />
                                            <path
                                                d='M34.3084 35.7409C38.2759 32.3205 40.7783 27.3161 40.7783 21.7409C40.7783 11.4316 32.222 3.07421 21.6672 3.07421C11.1124 3.07421 2.5561 11.4316 2.5561 21.7409C2.5561 27.3161 5.05848 32.3205 9.02605 35.7409'
                                                stroke='#000133'
                                                strokeWidth='2'
                                            />
                                        </svg>
                                    </div>
                                    <div className='upload-title'>
                                        Drop your images here or <span className='t-upload-title'>browse</span>
                                    </div>
                                    <div className='upload-sub-title'>Supports: JPG, JPEG, PNG</div>
                                </div>
                                <div className='view-block' style={{ display: viewDisplay }}>
                                    {images !== undefined &&
                                        images.map((image: IImagesRequest, index: number) => (
                                            <div key={index} className='image-item'>
                                                <Card variant='outlined'>
                                                    <CardActionArea onClick={handleImagePopupInfo(image.image_id, image.content)}>
                                                        {image.settings?.isSend &&
                                                        image.settings?.loading &&
                                                        statusImagesData !== StatusRedux.Failed ? (
                                                            <CircularProgress color='primary' size={26} sx={classes.fabProgress} />
                                                        ) : null}

                                                        {image.settings?.error ? (
                                                            <Box sx={classes.msgError}>
                                                                <Box sx={classes.textError}>Error Upload Image!</Box>
                                                            </Box>
                                                        ) : null}

                                                        {image.settings?.image_classification === 'NO_CAR' ||
                                                        image.settings?.image_classification === 'INTERIOR' ||
                                                        image.settings?.image_classification === 'TIRE' ? (
                                                            <Box sx={classes.msgError}>
                                                                <Box sx={classes.textError}>
                                                                    {image.settings?.image_classification === 'TIRE' ||
                                                                    image.settings?.image_classification === 'INTERIOR' ||
                                                                    image.settings?.image_classification === 'NO_CAR'
                                                                        ? image.settings?.image_classification.replace('_', ' ')
                                                                        : image.image_quality_result.message}
                                                                </Box>
                                                            </Box>
                                                        ) : null}
                                                        {image.image_quality_result.status === 'FAILED' &&
                                                        (image.settings?.image_classification === 'FULL_FRAME' ||
                                                            image.settings?.image_classification === 'CLOSEUP') ? (
                                                            <Box sx={classes.msgWarning}>
                                                                <Box sx={classes.textError}>
                                                                    {image.image_quality_result.status === 'FAILED' &&
                                                                    (image.settings?.image_classification === 'FULL_FRAME' ||
                                                                        image.settings?.image_classification === 'CLOSEUP') ? (
                                                                        <Box sx={classes.textOK}>CLICK TO PREVIEW</Box>
                                                                    ) : null}
                                                                </Box>
                                                            </Box>
                                                        ) : null}
                                                        {image.settings?.isCompleted &&
                                                        image.image_quality_result.status === 'PASSED' &&
                                                        (image.settings?.image_classification === 'FULL_FRAME' ||
                                                            image.settings?.image_classification === 'CLOSEUP') ? (
                                                            <Box sx={classes.msgOK}>
                                                                <Box sx={classes.textOK}>CLICK TO PREVIEW</Box>
                                                            </Box>
                                                        ) : null}
                                                        <CardMedia
                                                            sx={classes.img}
                                                            component='img'
                                                            alt={image.image_id}
                                                            image={`data:image/jpeg;base64, ${image.content}`}
                                                        />
                                                    </CardActionArea>
                                                </Card>
                                                <div className='image-item__btn-wrapper'>
                                                    {image.settings?.isCompleted || image.settings?.error ? (
                                                        <>
                                                            <IconButton
                                                                sx={classes.iconImg}
                                                                color='primary'
                                                                onClick={() => onRemoveImage(index, image.image_id, image.name)}
                                                            >
                                                                <HighlightOffIcon color='primary' />
                                                            </IconButton>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </ImageUploading>
                </div>

                <PopupInfo
                    img_id={popupInfo.id}
                    open={popupInfo.open}
                    themeJson={themeJson}
                    handleClose={handleClose}
                    data={popupInfo.data}
                    img={popupInfo.imgBase64}
                />
            </Paper>

            {asyncProcessOpen && <InspectionStartProcess popup={asyncProcessOpen} setAsyncProcessOpen={setAsyncProcessOpen} />}

            <Dialog
                maxWidth='xs'
                open={removeImg.showDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby='alert-dialog-img-remove'
                aria-describedby='alert-dialog-img-remove-description'
            >
                <DialogTitle id='alert-dialog-img-remove'>Remove Image</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-img-remove-description'>Are you sure you want to delete: {removeImg.name}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelRemoveImg} color='primary'>
                        Cancel
                    </Button>
                    <Button disabled={isClicked} onClick={handleRemoveImg} color='primary'>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>

            <PopupBack open={popupBack} close={handleClosePopup} back={goToBack} />
            <InspectionStepper
                showResetButton={true}
                handleNext={handleNext}
                handlePrev={handlePrev}
                id={asyncProcessOpen ? 3 : 2}
                next={isBtnDisabled.next}
                prev={isBtnDisabled.prev}
            />
        </>
    );
};

export default InspectionUploadImages;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} children={props.children} />;
});
