import React from 'react';
import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { classes } from './style';
import LoopIcon from '@mui/icons-material/Loop';

interface IResultMultipleComponent {
    imageFullCar: string;
    imageCloseUp: string;
    result: string | undefined;
    reset: Function;
}

type ImageType = {
    id: number;
    img: string;
    title: string | undefined;
};

const ResultMultiple: React.FC<IResultMultipleComponent> = (props: IResultMultipleComponent) => {
    const [images] = React.useState<Array<ImageType>>([
        {
            id: 1,
            img: `data:image/jpeg;base64, ${props.imageFullCar}`,
            title: 'Full Car',
        },
        {
            id: 2,
            img: `data:image/jpeg;base64, ${props.imageCloseUp}`,
            title: 'Close Up',
        },
    ]);

    const handleReset = () => {
        props.reset();
    };

    return (
        <>
            <Grid container spacing={5} direction='row' justifyContent='center' alignItems='center'>
                {images.map((pic, index) => (
                    <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card key={pic.id} sx={classes.card}>
                            <CardActionArea>
                                <CardMedia sx={classes.cardImg} component='img' alt={pic.title} image={pic.img} title={pic.title} />
                                <CardContent sx={classes.cardContent}>
                                    <Typography gutterBottom variant='h5' component='h2'>
                                        {pic.title}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={5} direction='row' justifyContent='center' alignItems='center'>
                {props.result ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card sx={classes.card}>
                            <CardActionArea>
                                <CardMedia
                                    sx={classes.cardImg}
                                    component='img'
                                    alt='Result'
                                    image={`data:image/jpeg;base64, ${props.result}`}
                                    title='Result'
                                />
                                <CardContent sx={classes.cardContent}>
                                    <Typography gutterBottom variant='h5' component='h2'>
                                        Result
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card>
                            <CardContent sx={classes.cardContentEmpty}>
                                <Typography sx={classes.textEmpty} gutterBottom variant='h5' component='h2'>
                                    No Result
                                </Typography>
                                <Button onClick={handleReset} variant='contained' color='primary'>
                                    <LoopIcon /> Upload New Images
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default ResultMultiple;
