import React from 'react';
import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import { InspectionContext } from '../../../../../context/InspectionProvider';
import PopupBack from '../../../../popup-back/popup-back';
import { classes } from './style';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Odometer from '../../odometer/odometer';
import LensIcon from '@mui/icons-material/Lens';
import moment from 'moment';
import { AppDispatch } from '../../../../../store';
import { useDispatch } from 'react-redux';
import { showPopup } from '../../../../../store/events/popup';
import { useNavigate } from 'react-router-dom';
import { backButtonDisabled, onbeforeunload } from '../../../../../utils/onbeforeunload';
import { InspectionStepper } from '../stepper';

const InspectionDetails: React.FC = () => {
    const { inspectionStateContext, setInspectionToState } = React.useContext(InspectionContext);
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const [isBtnDisabled, setDisableBtn] = React.useState<{ next: boolean; prev: boolean }>({
        next: true,
        prev: false,
    });
    const [popupBack, setPopupBack] = React.useState<boolean>(false);
    const matches = useMediaQuery('(max-width:600px)');
    const [dateTime, setDateTime] = React.useState<{ date: Date | null; time: Date | null }>({
        date: inspectionStateContext.inspectionDate !== null ? inspectionStateContext.inspectionDate : new Date(),
        time: inspectionStateContext.inspectionDate !== null ? inspectionStateContext.inspectionDate : new Date(),
    });

    const handleDateChange = (event: Date | null) => {
        setDateTime({
            ...dateTime,
            date: event,
        });
    };

    const handleTimeChange = (event: Date | null) => {
        setDateTime({
            ...dateTime,
            time: event,
        });
    };

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;

        setInspectionToState({
            ...inspectionStateContext,
            [name]: value.trimStart(),
        });
    };

    const handleChangeInputLicensePlateNumber = (event: any) => {
        const { name, value } = event.target;

        if (value.length < 9) {
            setInspectionToState({
                ...inspectionStateContext,
                [name]: value.toUpperCase().replace(/[^a-zA-Z\d]/g, ''),
            });
        }
    };

    React.useEffect(() => {
        setInspectionToState({
            ...inspectionStateContext,
            inspectionDate: new Date(
                moment(moment(dateTime.date).format('YYYY-MM-DD') + ' ' + moment(dateTime.time).format('HH:mm')).format('YYYY-MM-DD HH:mm:ss')
            ),
        });
    }, [dateTime.date, dateTime.time]);

    React.useEffect(() => {
        if (inspectionStateContext.claim.length > 0 && moment(inspectionStateContext.inspectionDate).isValid()) {
            setDisableBtn({
                ...isBtnDisabled,
                next: false,
            });
        } else {
            setDisableBtn({
                ...isBtnDisabled,
                next: true,
            });
        }
    }, [inspectionStateContext.claim, inspectionStateContext.inspectionDate]);

    const handlePrev = (): void => {
        if (
            inspectionStateContext.claim.length !== 0 ||
            inspectionStateContext.licensePlateNumber.length !== 0 ||
            inspectionStateContext.odometer !== null ||
            inspectionStateContext.odometerImgView !== null
        ) {
            setPopupBack(true);
        } else {
            navigate('/home/inspection');
            setPopupBack(false);
        }
    };

    const handleNext = (): void => {
        navigate('/home/inspection/create/2');
    };

    const handleClosePopup = () => {
        setPopupBack(false);
    };

    const back = () => {
        dispatch(showPopup(false));
        navigate('/home/inspection');
    };

    React.useEffect(() => {
        if (
            inspectionStateContext.claim.length !== 0 ||
            inspectionStateContext.licensePlateNumber.length !== 0 ||
            inspectionStateContext.odometer !== null ||
            inspectionStateContext.odometerImgView.length !== 0
        ) {
            dispatch(showPopup(true));
            onbeforeunload(undefined);
            backButtonDisabled(true);
        } else {
            dispatch(showPopup(false));
        }
    }, [
        inspectionStateContext.claim,
        inspectionStateContext.odometer,
        inspectionStateContext.licensePlateNumber,
        inspectionStateContext.odometerImgView,
    ]);

    return (
        <>
            <PopupBack open={popupBack} close={handleClosePopup} back={back} />
            <Paper elevation={2} sx={classes.paper}>
                <Grid container spacing={0} direction='row' justifyContent='flex-end' alignItems='baseline'>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={classes.block}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={classes.headerInputs}>Inspection Details</Box>
                        </Grid>
                        <Box sx={classes.formInput}>
                            <TextField
                                variant='standard'
                                autoComplete='off'
                                fullWidth
                                name='licensePlateNumber'
                                id='outlined-basic'
                                type='text'
                                label='License Plate Number'
                                value={inspectionStateContext.licensePlateNumber}
                                onChange={handleChangeInputLicensePlateNumber}
                            />
                        </Box>
                        <Box sx={classes.formInput}>
                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <DatePicker
                                        label='Date'
                                        mask='__/__/____'
                                        value={dateTime.date}
                                        inputFormat='dd/MM/yyyy'
                                        onChange={handleDateChange}
                                        renderInput={(params: any) => <TextField variant='standard' fullWidth={true} {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TimePicker
                                        onChange={handleTimeChange}
                                        value={dateTime.time}
                                        label='Time'
                                        mask='__:__'
                                        ampm={false}
                                        disableOpenPicker={!matches}
                                        inputFormat='HH:mm'
                                        renderInput={(params: any) => <TextField variant='standard' fullWidth={true} {...params} />}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={classes.formInput}>
                            <TextField
                                autoComplete='off'
                                helperText='This field is required'
                                fullWidth
                                variant='standard'
                                name='claim'
                                id='outlined-basic'
                                type='text'
                                label='Claim'
                                value={inspectionStateContext.claim}
                                onChange={handleChangeInput}
                            />
                        </Box>
                        <Box sx={classes.inputBlock}>
                            <Odometer />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={classes.block}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={classes.headerInputs}>Image Requirements</Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={classes.list}>
                                <p>
                                    <LensIcon sx={classes.listIcon} /> Minimum Image Resolution:
                                </p>
                                <p>16:9 - 1080X600</p>
                                <p>4:3 - 800X600</p>
                            </Box>
                            <Box sx={classes.list}>
                                <p>
                                    <LensIcon sx={classes.listIcon} /> Maximum Image Resolution:
                                </p>
                                <p>16:9 - 2000x1125</p>
                                <p>4:3 - 2000x1500</p>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <InspectionStepper handleNext={handleNext} handlePrev={handlePrev} id={1} next={isBtnDisabled.next} prev={isBtnDisabled.prev} />
        </>
    );
};

export default InspectionDetails;
