import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import DamageLabServices from '../../services/damage-lab/damage-lab';
import { IFetchBody } from '../IFetch';
import { ICloseUpRegistration, ICloseUpRegistrationResponse } from '../../infrastructure/DTO/damage-lab/damage-lab.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchCloseUpRegistration = createAsyncThunk<ICloseUpRegistrationResponse | null, IFetchBody<ICloseUpRegistration>>(
    'damage-lab/closeUpRegistration',
    async (params: IFetchBody<ICloseUpRegistration>) => {
        return await DamageLabServices.closeUpRegistration(params);
    }
);

export const closeUpRegistrationSlice = createSlice({
    name: 'damage-lab',
    initialState: {
        closeUpRegistration: {} as ICloseUpRegistrationResponse | null,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        clearStateCloseUpRegistration: (state) => {
            state.closeUpRegistration = {} as ICloseUpRegistrationResponse;
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCloseUpRegistration.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchCloseUpRegistration.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.closeUpRegistration = action.payload;
        });
        builder.addCase(fetchCloseUpRegistration.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStateCloseUpRegistration } = closeUpRegistrationSlice.actions;
export default closeUpRegistrationSlice;
