import React, { useRef } from 'react';
import { Grid, List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import Konva from '../../../../../../common/ui-components/Konva';
import { Group, Layer, Image as Pic, Line } from 'react-konva';
import Divider from '@mui/material/Divider';
import { classes } from './style';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import { IBoundingBox, IContours } from '../../../../../../infrastructure/DTO/damage-lab/damage-lab.dto';
import ListItemIcon from '@mui/material/ListItemIcon';
import Brightness1Icon from '@mui/icons-material/Brightness1';

type PartsType = {
    part_id: string;
    part_name: string;
    bounding_box: IBoundingBox;
    color: any;
    contours: Array<IContours>;
};

const Parts: React.FC = () => {
    const [coordinates, setCoordinates] = React.useState<{ x: number; y: number }>({ x: 1, y: 1 });
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);
    const [currentPart, setCurrentPart] = React.useState<Array<IContours>>([]);
    const refDiv = useRef<HTMLDivElement | null>(null);
    const { imageMain } = useSelector((state: AppState) => state.damageLabImage);
    const [size, setSize] = React.useState<{ width: number; height: number }>({ width: 1, height: 1 });
    const [selectedPart, setSelectedPart] = React.useState<{ id: string; color: string } | null>(null);
    const [parts, setParts] = React.useState<Array<PartsType>>([]);
    const [points, setPoints] = React.useState<
        Map<string, { pointsArr: any; color: string; bounding_box: IBoundingBox; contours: Array<IContours> }>
    >(new Map<string, { pointsArr: Array<{ x: number; y: number }>; color: string; bounding_box: IBoundingBox; contours: Array<IContours> }>());

    const handleMove = (e: any) => {
        setCoordinates({
            x: e.target.x(),
            y: e.target.y(),
        });
    };

    React.useEffect(() => {
        if (refDiv.current !== null) {
            setSize({
                width: refDiv.current.clientWidth,
                height: refDiv.current.clientHeight,
            });

            const arrParts: Array<PartsType> = [];
            let scale = Math.min(refDiv.current.clientWidth / imageMain.width, refDiv.current.clientHeight / imageMain.height);

            if (damageLabData.parts_segmentation !== null) {
                damageLabData.parts_segmentation.map((part) => {
                    arrParts.push({
                        part_id: part.part_id,
                        part_name: part.part_name,
                        bounding_box: part.bounding_box,
                        color: `rgba(${part.color_value}, 0.5)`,
                        contours: part.contours,
                    });
                });

                damageLabData.parts_segmentation.map((part) => {
                    let pointsConcat: any = [];

                    if (part.contours !== null) {
                        part.contours.forEach((points) => {
                            pointsConcat.push(
                                points.map((i) => {
                                    return { x: Math.floor(i.x * scale), y: Math.floor(i.y * scale) };
                                })
                            );
                        });
                        setPoints(
                            points.set(part.part_id, {
                                pointsArr: pointsConcat,
                                color: `rgba(${part.color_value}, 0.5)`,
                                contours: part.contours,
                                bounding_box: {
                                    width: part.bounding_box.width,
                                    height: part.bounding_box.height,
                                    x: Math.floor(part.bounding_box.x * scale),
                                    y: Math.floor(part.bounding_box.y * scale),
                                },
                            })
                        );
                    }
                });

                setParts(arrParts);
            }
        }
    }, []);

    React.useEffect(() => {
        let scale = Math.min(size.width / imageMain.width, size.height / imageMain.height);

        setCoordinates({
            x: size.width / 2 - (imageMain.width * scale) / 2,
            y: size.height / 2 - (imageMain.height * scale) / 2,
        });
    }, [size.width, size.height]);

    const handleToggle = (part: PartsType) => () => {
        let pointsConcat: any = [];
        let scale = Math.min(size.width / imageMain.width, size.height / imageMain.height);
        setSelectedPart({ id: part.part_id, color: part.color });

        if (selectedPart?.id === part.part_id) {
            setSelectedPart(null);
        }

        part.contours.forEach((points) => {
            pointsConcat.push(
                points.map((i) => {
                    return { x: Math.floor(i.x * scale), y: Math.floor(i.y * scale) };
                })
            );
        });

        setCurrentPart(pointsConcat);
    };

    return (
        <Grid container spacing={5} direction='row'>
            <Grid sx={classes.anim} item xs={12} sm={12} md={6} lg={9} xl={9}>
                <Konva wheel={true} konvaRef={refDiv} style={classes.stage} width={size.width} height={size.height}>
                    <Layer>
                        <Group x={coordinates.x} y={coordinates.y} draggable onDragMove={handleMove} onDragEnd={handleMove}>
                            <Pic
                                width={imageMain.width * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                height={imageMain.height * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                image={imageMain.image}
                            />
                            {selectedPart &&
                                currentPart.map((i: any, index: number) => {
                                    let pArr: any[] = [];
                                    i.forEach((j: any) => pArr.push(j.x, j.y));

                                    return (
                                        <Line
                                            key={index}
                                            points={pArr}
                                            stroke='black'
                                            fill={selectedPart?.color}
                                            strokeWidth={1}
                                            closed={true}
                                            lineCap='square'
                                            globalCompositeOperation='source-over'
                                        />
                                    );
                                })}
                            {points !== null &&
                                selectedPart === null &&
                                Array.from(points.values()).map((line, i) => {
                                    if (line.pointsArr.length === 1) {
                                        let pArr: any[] = [];

                                        line.pointsArr.forEach((i: any) => {
                                            i.forEach((j: any) => {
                                                pArr.push(j.x, j.y);
                                            });
                                        });

                                        return (
                                            <Line
                                                key={i}
                                                points={pArr}
                                                stroke={line.color}
                                                fill={line.color}
                                                strokeWidth={0}
                                                closed={true}
                                                lineCap='square'
                                                globalCompositeOperation='source-over'
                                            />
                                        );
                                    } else {
                                        return line.pointsArr.map((i: any, index: number) => {
                                            let pArr: any[] = [];

                                            i.forEach((j: any) => {
                                                pArr.push(j.x, j.y);
                                            });

                                            return (
                                                <Line
                                                    key={index}
                                                    points={pArr}
                                                    stroke={line.color}
                                                    fill={line.color}
                                                    strokeWidth={0}
                                                    closed={true}
                                                    lineCap='square'
                                                    globalCompositeOperation='source-over'
                                                />
                                            );
                                        });
                                    }
                                })}
                        </Group>
                    </Layer>
                </Konva>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                {parts.length > 0 && (
                    <List
                        sx={classes.listMain}
                        subheader={
                            <ListSubheader sx={classes.listHeader} component='div'>
                                Damages List
                            </ListSubheader>
                        }
                    >
                        {parts.map((value, index) => (
                            <div key={index}>
                                <ListItemButton selected={value.part_id === selectedPart?.id} dense onClick={handleToggle(value)}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <Brightness1Icon sx={{ color: `${value.color}` }} />
                                    </ListItemIcon>
                                    <ListItemText id={`checkbox-list-label-${value.part_id}`} primary={`${value.part_id} - ${value.part_name}`} />
                                </ListItemButton>
                                <Divider />
                            </div>
                        ))}
                    </List>
                )}
            </Grid>
        </Grid>
    );
};

export default Parts;
