import * as React from 'react';
import { DialogContent, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { classes } from '../../style';
import { useSnackbar } from 'notistack';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { clearStoreUpdate, fetchCreateRole } from '../../../../../store/role/role';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import Box from '@mui/material/Box';
import { BasicDialog } from '../../../../../common/ui-components/Dialog';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { IRole } from '../../../../../infrastructure/DTO/role/role.dto';
import errorParseMessage from '../../../../../config/error-parse';

interface IAddRoleComponent {
    open: boolean;
    close: Function;
}

const AddRole: React.FC<IAddRoleComponent> = (props: IAddRoleComponent) => {
    const { token } = useContext(CustomAuthContext);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const { statusCreate, errorCreate } = useSelector((state: AppState) => state.getRoles);

    const [roleData, setRoleData] = React.useState({
        name: '',
        is_active: true,
    });

    const handleClose = () => {
        setRoleData({
            ...roleData,
            name: '',
        });
        props.close();
        dispatch(clearStoreUpdate());
    };

    const handleSubmitAddRole = (event: any) => {
        event.preventDefault();

        const body: Partial<IRole> = {
            name: roleData.name,
            is_active: roleData.is_active,
        };

        dispatch(fetchCreateRole({ token, body }));
    };

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;
        setRoleData({
            ...roleData,
            [name]: value,
        });
    };

    React.useEffect(() => {
        if (statusCreate === StatusRedux.Succeeded) {
            enqueueSnackbar('Role Added Successfully', {
                variant: 'success',
            });
            setRoleData({
                ...roleData,
                name: '',
            });

            props.close();
            dispatch(clearStoreUpdate());
        }
        if (statusCreate === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorCreate), {
                variant: 'error',
            });

            dispatch(clearStoreUpdate());
        }
    }, [statusCreate]);

    return (
        <BasicDialog handlePopup={handleClose} open={props.open} title='Create Role' width='sm'>
            <DialogContent>
                <Box sx={classes.bodyPopup}>
                    <form autoComplete='off' onSubmit={handleSubmitAddRole}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box sx={classes.inputBtn}>
                                    <TextField
                                        fullWidth
                                        name='name'
                                        required={true}
                                        id='outlined-basic'
                                        type='text'
                                        label='Role Name'
                                        variant='outlined'
                                        value={roleData.name}
                                        onChange={handleChangeInput}
                                    />
                                    <Button type='submit' variant='contained' color='primary'>
                                        Save
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </DialogContent>
        </BasicDialog>
    );
};

export default AddRole;
