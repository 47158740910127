import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup } from '@mui/material';
import { classes } from '../edit-business-units/style';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import useCheckInputText from '../../../../../hooks/validation/use-input-text';
import useCheckInputUrl from '../../../../../hooks/validation/use-input-url';
import { IGenerateToken } from '../../../../../infrastructure/DTO/users/user-view.dto';
import { EnumGenerateToken } from './generate-token';
import { IInput } from '../../../../../hooks/validation/IInput';

interface IGenerateTokenFields {
    handleSubmit(): void;
    handleChangeInput(event: any): void;
    handleChooseToken(event: React.ChangeEvent<HTMLInputElement>): void;
    setIsButtonDisabled(is_disable: boolean): void;
    body: IGenerateToken;
    chooseToken: EnumGenerateToken;
}

interface Fields {
    client_process_id: IInput;
}

const GenerateTokenFields: React.FC<IGenerateTokenFields> = (props: IGenerateTokenFields) => {
    const { inputsText, message, checkValueText } = useCheckInputText<Fields>({
        client_process_id: {
            minLength: 4,
        },
    });

    const { inputsUrl, checkValueUrl } = useCheckInputUrl(['redirect_url', 'fail_url', 'unauthorized_url']);

    React.useEffect(() => {
        if (inputsText?.client_process_id || inputsUrl.redirect_url || inputsUrl.fail_url || inputsUrl.unauthorized_url) {
            props.setIsButtonDisabled(true);
        } else {
            props.setIsButtonDisabled(false);
        }
    }, [inputsText, inputsUrl]);

    const handleSubmitFields = () => {
        props.handleSubmit();
    };

    return (
        <form id='create-link-fields' autoComplete='off' onSubmit={handleSubmitFields}>
            <Paper sx={classes.paper} elevation={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size='small'
                            name='client_process_id'
                            id='client_process_id'
                            type='text'
                            label='Client Process Id'
                            variant='outlined'
                            value={props.body.client_process_id?.trimStart() as string}
                            onChange={props.handleChangeInput}
                            onKeyUp={checkValueText}
                            helperText={message?.client_process_id}
                            error={inputsText?.client_process_id}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size='small'
                            name='redirect_url'
                            id='redirect_url'
                            type='url'
                            label='Redirect URL'
                            variant='outlined'
                            value={props.body.redirect_url?.trimStart()}
                            onChange={props.handleChangeInput}
                            onKeyUp={checkValueUrl}
                            helperText={inputsUrl.redirect_url ? 'Please enter a URL (https://example.com)' : null}
                            error={inputsUrl.redirect_url}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size='small'
                            name='fail_url'
                            id='fail_url'
                            type='url'
                            label='Fail URL'
                            variant='outlined'
                            value={props.body.fail_url?.trimStart()}
                            onChange={props.handleChangeInput}
                            onKeyUp={checkValueUrl}
                            helperText={inputsUrl.fail_url ? 'Please enter a URL (https://example.com)' : null}
                            error={inputsUrl.fail_url}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size='small'
                            name='unauthorized_url'
                            id='unauthorized_url'
                            type='url'
                            label='Unauthorized  URL'
                            variant='outlined'
                            value={props.body.unauthorized_url?.trimStart()}
                            onChange={props.handleChangeInput}
                            onKeyUp={checkValueUrl}
                            helperText={inputsUrl.unauthorized_url ? 'Please enter a URL (https://example.com)' : null}
                            error={inputsUrl.unauthorized_url}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id='chose-create-link'>Choose Link Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby='chose-create-link'
                                value={props.chooseToken}
                                onChange={props.handleChooseToken}
                                name='create-link'
                            >
                                <FormControlLabel value={EnumGenerateToken.REGULAR} control={<Radio />} label='Regular' />
                                <FormControlLabel value={EnumGenerateToken.SHORT} control={<Radio />} label='Short' />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
};

export default GenerateTokenFields;
