import React from 'react';
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import PhotoIcon from '@mui/icons-material/Photo';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { Outlet, useNavigate } from 'react-router-dom';
import { classes } from './style';

const DamageLab: React.FC = () => {
    const navigate = useNavigate();

    const menuConfig = [
        {
            id: 0,
            title: 'SINGLE PICTURE',
            path: '/home/damage-lab/single',
            description: 'Matching car damage with a single picture',
            icon: <PhotoIcon sx={classes.cardIcon} />,
        },
        {
            id: 1,
            title: 'MULTIPLE PICTURES',
            path: '/home/damage-lab/multiple',
            description: 'Matching car damage with a picture-set',
            icon: <PhotoLibraryIcon sx={classes.cardIcon} />,
        },
    ];

    const handleMenu = (path: string) => () => {
        navigate(path);
    };

    return (
        <>
            <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h6' noWrap sx={classes.titlePage}>
                        DamageLab
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center' sx={classes.mainBlock}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid container spacing={7} direction='row' justifyContent='center'>
                        {menuConfig.map((menu, index) => (
                            <Grid key={index + 1} item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Card sx={classes.root} onClick={handleMenu(menu.path)}>
                                    <CardActionArea key={menu.id} sx={classes.card}>
                                        {menu.icon}
                                        <CardContent sx={classes.cardContent}>
                                            <Typography gutterBottom variant='h5' component='h2'>
                                                {menu.title}
                                            </Typography>
                                            <Typography variant='body2' color='textSecondary' component='p'>
                                                {menu.description}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default DamageLab;
