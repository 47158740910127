import { CSSObject } from '@mui/material';

const root = (): CSSObject => ({
    flexGrow: 1,
});

const dialogTitle = (): CSSObject => ({
    padding: '0px',
});

const appBar = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
});

const pageIndicator = (): CSSObject => ({
    border: '0px',
});

const padding = (): CSSObject => ({
    padding: '0px',
});

const reportContent = (): CSSObject => ({
    padding: '0px',
    minHeight: '730px',
});

const nextPageBtn = (): CSSObject => ({
    border: 'none',
    background: '#fff',
    height: '10px',
    position: 'relative',
    top: '6px',
    cursor: 'pointer',
});

const previousPageBtn = (): CSSObject => ({
    border: 'none',
    background: '#fff',
    height: '10px',
    position: 'relative',
    top: '6px',
    cursor: 'pointer',
});

const customPrevBtn = (): CSSObject => ({
    display: 'none',
    width: '0px!important',
});

const zoomOutBtn = (): CSSObject => ({
    display: 'none',
    width: '0px!important',
});

const customResetBtn = (): CSSObject => ({
    display: 'none',
    width: '0px!important',
});

const rotateRightBtn = (): CSSObject => ({
    display: 'none',
    width: '0px!important',
});

const zoomInBtn = (): CSSObject => ({
    display: 'none',
    width: '0px!important',
});

const mLeft = (): CSSObject => ({
    marginRight: '10px',
});

const appBarTab = (): CSSObject => ({
    zIndex: '1',
});

const fileError = (): CSSObject => ({
    color: 'red',
    width: '100%',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2em',
    textTransform: 'uppercase',
});

const loaderBlock = (): CSSObject => ({
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'flex',
    width: '100%',
    background: '#fff',
});

export const classes = {
    root,
    zoomInBtn,
    mLeft,
    loaderBlock,
    fileError,
    rotateRightBtn,
    nextPageBtn,
    reportContent,
    previousPageBtn,
    padding,
    pageIndicator,
    appBar,
    appBarTab,
    dialogTitle,
    customResetBtn,
    zoomOutBtn,
    customPrevBtn,
};
