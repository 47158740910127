import {CSSObject} from "@mui/material";


const skeletonImg = (): CSSObject => ({
    width: '100%',
    height: '600px'
})

const skeletonDivMain = (): CSSObject => ({
    width: '100%',
    display: 'flex',
    marginTop: '5px'
})

const skeletonCheckbox = (): CSSObject => ({
    width: '30px',
    height: '50px',
    marginRight: '10px'
})

const skeletonList = (): CSSObject => ({
    width: '100%',
    height: '50px'
})

const skeletonTable = (): CSSObject => ({
    width: '100%',
    height: '30px',
})

export const classes = {
    skeletonTable, skeletonList, skeletonCheckbox, skeletonDivMain, skeletonImg
}