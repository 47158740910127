import React from 'react';
import { IInputTextValidation } from './IInput';

export function useCheckInputText<T>(props: IInputTextValidation<T>): {
    checkValueText: (event: any) => void;
    clearInputState: (reset: boolean) => void;
    inputsText: { [p in keyof T]: boolean } | null;
    message: { [p in keyof T]: string } | null;
} {
    const [inputsText, setInputsText] = React.useState<{ [p in keyof T]: boolean } | null>(null);
    const [message, setMessage] = React.useState<{ [p in keyof T]: string } | null>(null);

    /**
     * Reset All States
     * @param reset
     * @type boolean
     */
    const clearInputState = (reset: boolean) => {
        if (reset) {
            setInputsText(null);
            setMessage(null);
        }
    };

    /**
     * Get all inputs
     * @param event
     */
    const checkValueText = (event: any) => {
        const { name, value } = event.target;

        setInputsText({
            ...inputsText,
            [name as keyof T]: _validation(value, name),
        } as { [p in keyof T]: boolean });
    };

    /**
     * Checks and removes special symbols
     * @param value
     * @param input_name
     * @return boolean
     */
    const _validation = (value: string, input_name: string): boolean => {
        const reg: RegExp = /^[a-zA-Z0-9\s@\/._-]*$/;
        const special_characters = value.search(/[a-zA-Z0-9\s@\/._-]*$/);
        const input: keyof T = input_name as keyof T;

        /**
         * Remove special characters
         * White Symbols (@ . - _)
         */
        if (special_characters !== 0) {
            setMessage({
                ...message,
                [input]: `Your ${input_name.replace(/_/g, ' ')} has special characters, these are not allowed. Please remove: ${value.charAt(
                    special_characters - 1
                )}`,
            } as { [p in keyof T]: string });
            return !reg.test(value);
        } else {
            setMessage({
                ...message,
                [input]: '',
            } as { [p in keyof T]: string });
        }

        /**
         * Minimum characters length
         * @type number
         * @type undefined
         * @param minLength
         */
        if (props[input] && props[input]?.minLength) {
            if (!props[input]?.required && value.length === 0) {
                setMessage({
                    ...message,
                    [input]: '',
                } as { [p in keyof T]: string });
                return false;
            } else {
                let min = props[input]?.minLength as number;
                if (value.length < min) {
                    setMessage({
                        ...message,
                        [input]: `Must contain at least ${min} symbols`,
                    } as { [p in keyof T]: string });
                    return true;
                } else {
                    setMessage({
                        ...message,
                        [input]: '',
                    } as { [p in keyof T]: string });
                }
            }
        }

        /**
         * Required field or empty
         * @type boolean
         * @type undefined
         * @param required
         */
        if (props[input] && !props[input]?.required && value.length === 0) {
            setMessage({
                ...message,
                [input]: '',
            } as { [p in keyof T]: string });
            return false;
        }

        /**
         * Maximum characters length
         * @type number
         * @type undefined
         * @param maxLength
         */
        if (props[input] && props[input]?.maxLength) {
            let max = props[input]?.maxLength as number;
            if (value.length > max) {
                setMessage({
                    ...message,
                    [input]: `Must contain no more than ${max} characters`,
                } as { [p in keyof T]: string });
                return true;
            } else {
                setMessage({
                    ...message,
                    [input]: '',
                } as { [p in keyof T]: string });
            }
        }

        /**
         * Required field or empty
         * @type boolean
         * @type undefined
         * @param required
         */
        if (props[input] && props[input]?.required) {
            if (value.length === 0) {
                setMessage({
                    ...message,
                    [input]: `This field ${input_name.replace(/_/g, ' ')} is required`,
                } as { [p in keyof T]: string });
                return true;
            } else {
                setMessage({
                    ...message,
                    [input]: '',
                } as { [p in keyof T]: string });
            }
        }

        return false;
    };

    return { inputsText, message, checkValueText, clearInputState };
}

export default useCheckInputText;
