import {CSSObject, keyframes, Theme} from "@mui/material";

const fadeIn = keyframes`
    "0%": {
      opacity: 0
    },
    "100%": {
        opacity: 1
    }
`;

const text = (): CSSObject => ({
    textAlign: 'center',
    margin: '0 auto'
})

const pic = (): CSSObject => ({
    marginTop: '20px',
    width: '100%',
    textAlign: 'center',
    '& > img': {
        width: 'auto',
        height: '550px',
    }
})

const anim = (theme: Theme): CSSObject => ({
    animation: `${fadeIn} 500ms ${theme.transitions.easing.easeInOut}`,
})

export const classes = {
    text, anim, pic
}