import React from 'react';
import { Tabs, Tab, AppBar } from '@mui/material';
import { KeyPointsDetection } from './key-points-detection';
import ImageQuality from './image-quality/image-quality';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store';
import { classes } from './style';
import { SegmentCar } from './segment-car';
import PrincipalCar from './principal-car';
import LicenseNumber from './license-number/license-number';
import { TabPanel, TabsProps } from '../../../../../common/ui-components/Tab';
import Box from '@mui/material/Box';

const PrincipalCarMain: React.FC = () => {
    const [value, setValue] = React.useState(0);
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar position='static' color='default'>
                <Tabs variant='fullWidth' value={value} indicatorColor='primary' textColor='primary' onChange={handleChange} aria-label='menu'>
                    <Tab label='Principal Car' {...TabsProps(0)} />
                    <Tab label='Segment Car' {...TabsProps(1)} />
                    <Tab disabled={damageLabData.license_plate_number === null} label='License Number' {...TabsProps(2)} />
                    <Tab label='Image Quality' {...TabsProps(3)} />
                    <Tab label='Key Points' {...TabsProps(4)} />
                </Tabs>
            </AppBar>
            <Box sx={classes.tabBody}>
                <TabPanel value={value} index={0}>
                    <PrincipalCar />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SegmentCar />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <LicenseNumber />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <ImageQuality />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <KeyPointsDetection />
                </TabPanel>
            </Box>
        </>
    );
};

export default PrincipalCarMain;
