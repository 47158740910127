import {CSSObject, Theme} from "@mui/material";

const backdrop = (): CSSObject => ({
    zIndex: 999999
})

const stage = (edit: string): CSSObject => ({
    width: '100%',
    height: 'calc(100vh - 100px)',
    overflow: 'hidden',
    cursor: edit
})

const container = (): CSSObject => ({
    position: 'relative'
})

const menuLeft = (theme: Theme): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'absolute',
    right: 45,
    top: 25,
    zIndex: 999,
    background: theme.palette.background.paper,
    width: 57,
    height: 'calc(100vh - 250px)',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'
})

const listDamages = (theme: Theme): CSSObject => ({
    position: 'absolute',
    top: 25,
    left: 45,
    height: 'calc(100vh - 250px)',
    background: theme.palette.background.paper,
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    width: '250px',
    overflowX: 'hidden'
})

const appBar = (): CSSObject => ({
    top: 'auto',
    bottom: 0,
})

const typeBlock = (theme: Theme): CSSObject => ({
    padding: '15px',
    position: 'sticky',
    bottom: 0,
    background: theme.palette.background.paper
})

const paper = (): CSSObject => ({
    overflowY: 'auto',
    height:' calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
})

const listHeader = (theme: Theme): CSSObject => ({
    background: theme.palette.background.paper
})

const dialogTitle= (): CSSObject => ({
    marginTop: '20px'
})


export const classes = {
    listHeader,paper,typeBlock,appBar,listDamages,backdrop,menuLeft,container,stage, dialogTitle
}