import React, { useState } from 'react';

const useCheckInputUrl = (inputs: Array<string>, required: boolean = false) => {
    const [inputsUrl, setInputsUrl] = useState(Object.fromEntries(inputs.map((k) => [k, false])));

    const checkValueUrl = (event: any) => {
        const { name, value } = event.target;
        const reg = new RegExp('^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$');

        setInputsUrl({
            ...inputsUrl,
            [name]: required ? !reg.test(value) : value.length === 0 ? false : !reg.test(value),
        });
    };

    return { inputsUrl, checkValueUrl };
};

export default useCheckInputUrl;
