import React, { useContext } from 'react';
import { Box, Button, Grid } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { IArgsCompany } from '../../../../infrastructure/DTO/company/company.dto';
import { fetchGetAllCompanies } from '../../../../store/company/companies';
import { IUserManagementProvider, UserManagementContext } from '../../../../context/UserManagementProvider';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { AppDispatch } from '../../../../store';
import { useDispatch } from 'react-redux';
import SearchInput from '../../../../common/ui-components/SearchInput';

const CompanyPanel: React.FC = () => {
    const { parseToken, token } = useContext(CustomAuthContext);
    const dispatch: AppDispatch = useDispatch();
    const { page, limit, searchText, searchMode, handleSearch, handleChangeSearchText, handleCreateCompanyPopup } =
        useContext<IUserManagementProvider>(UserManagementContext);

    React.useEffect(() => {
        if (searchMode) {
            const args: IArgsCompany = {
                company_id: parseToken().company,
                user_id: parseToken().sub,
                limit: limit,
                search_text: searchText,
                offset: 1,
            };
            dispatch(fetchGetAllCompanies({ token, args }));
        } else {
            if (searchText.length === 0) {
                const args: IArgsCompany = {
                    company_id: parseToken().company,
                    user_id: parseToken().sub,
                    limit: limit,
                    search_text: searchText,
                    offset: page + 1,
                };

                dispatch(fetchGetAllCompanies({ token, args }));
            }
        }
    }, [searchMode, limit, searchText, page]);

    return (
        <Grid container spacing={2} direction='row' justifyContent='flex-end' alignItems='center'>
            <Grid item>
                <Box sx={{ width: '100%', minWidth: '200px', maxWidth: '200px' }}>
                    <SearchInput name='Search' searchText={searchText} handleSearch={handleSearch} handleChangeSearchText={handleChangeSearchText} />
                </Box>
            </Grid>
            <Grid item>
                <Button onClick={handleCreateCompanyPopup} size='small' variant='contained' color='secondary' endIcon={<DescriptionIcon />}>
                    Create new
                </Button>
            </Grid>
        </Grid>
    );
};

export default CompanyPanel;
