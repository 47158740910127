import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RoleServices from '../../services/role/role';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs } from '../IFetch';
import { IArgsRole, IRole, IRoles } from '../../infrastructure/DTO/role/role.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetRoleByUserId = createAsyncThunk<IRoles, IFetchArgs<IArgsRole>>('roles/user', async (params: IFetchArgs<IArgsRole>) => {
    return await RoleServices.getRolesByUserId(params);
});

export const fetchAddRoleByUserId = createAsyncThunk<any, IFetchArgs<IArgsRole>>('roles/add/user', async (params: IFetchArgs<IArgsRole>) => {
    return RoleServices.addRoleToUser(params);
});

export const fetchRemoveRoleFromUser = createAsyncThunk<any, IFetchArgs<IArgsRole>>('roles/remove/user', async (params: IFetchArgs<IArgsRole>) => {
    return RoleServices.removeRoleFromUser(params);
});

export const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        roleUser: {} as IRole,
        rolesUser: {} as IRoles,
        statusRoleUser: '',
        errorRoleUser: {} as IErrorResponse,
        roleAddToUser: '' as unknown as boolean,
        statusRoleAddToUser: '',
        errorRoleAddToUser: {} as IErrorResponse,
        statusRoleRemoveFromUser: '',
        errorRoleRemoveFromUser: {} as IErrorResponse,
    },
    reducers: {
        clearStoreRoleUser: (state) => {
            state.statusRoleUser = '';
            state.errorRoleUser = {} as IErrorResponse;
        },
        clearStoreAddRoleToUser: (state) => {
            state.statusRoleAddToUser = '';
            state.errorRoleAddToUser = {} as IErrorResponse;
        },
        clearStoreRemoveRoleFromUser: (state) => {
            state.statusRoleRemoveFromUser = '';
            state.errorRoleRemoveFromUser = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetRoleByUserId.pending, (state) => {
            state.statusRoleUser = StatusRedux.Loading;
        });
        builder.addCase(fetchGetRoleByUserId.fulfilled, (state, action) => {
            state.statusRoleUser = StatusRedux.Succeeded;
            state.rolesUser = action.payload;
        });
        builder.addCase(fetchGetRoleByUserId.rejected, (state, action) => {
            state.statusRoleUser = StatusRedux.Failed;
            state.errorRoleUser = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchAddRoleByUserId.pending, (state) => {
            state.statusRoleAddToUser = StatusRedux.Loading;
        });
        builder.addCase(fetchAddRoleByUserId.fulfilled, (state, action) => {
            state.statusRoleAddToUser = StatusRedux.Succeeded;
            state.roleAddToUser = action.payload;
        });
        builder.addCase(fetchAddRoleByUserId.rejected, (state, action) => {
            state.statusRoleAddToUser = StatusRedux.Failed;
            state.errorRoleAddToUser = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreRoleUser, clearStoreAddRoleToUser } = rolesSlice.actions;
export default rolesSlice;
