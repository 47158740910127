import { InspectionFeature } from '../../static-data/InspectionFeature';
import { IFeature } from '../../infrastructure/DTO/inspection/feature.dto';

class Features {
    static async getAllFeatures(): Promise<Array<IFeature>> {
        return new Promise((resolve) => {
            resolve(InspectionFeature);
        });
    }
}

export default Features;
