import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Inspection from '../../services/inspection/inspection';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs } from '../IFetch';
import { IGetInspectionReports, IInspectionCaseId } from '../../infrastructure/DTO/inspection/inspection.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetFile = createAsyncThunk<IGetInspectionReports, IFetchArgs<IInspectionCaseId>>(
    'files/get',
    async (params: IFetchArgs<IInspectionCaseId>) => {
        return await Inspection.getReportFile(params);
    }
);

export const inspectionSlice = createSlice({
    name: 'files',
    initialState: {
        files: {} as IGetInspectionReports,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        clearStoreFiles: (state) => {
            state.files = {} as IGetInspectionReports;
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetFile.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetFile.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.files = action.payload;
        });
        builder.addCase(fetchGetFile.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreFiles } = inspectionSlice.actions;
export default inspectionSlice;
