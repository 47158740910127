import React from 'react';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { classes } from '../../../../damagelab/damagelab-single/parts&anomalies/parts/style';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ListItemIcon from '@mui/material/ListItemIcon';
import DamageLabLayoutContext, { IDamageLabLayoutContext, PartsType } from '../../../../../../context/DamageLabLayoutContext';
import { IDamageLabLayout } from '../IDamageLabLayout';
import { MenuEnum } from '../damagelab-menu/menu';
import Box from '@mui/material/Box';

interface IPartsListComponent extends IDamageLabLayout {
    fullscreenActive: boolean;
}

const PartsList: React.FC<IPartsListComponent> = (props: IPartsListComponent) => {
    const { panelViewLayout, scale, selectedPart, parts, setCurrentPart, setSelectedPart, setParts } =
        React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);
    const { imageOverView } = useSelector((state: AppState) => state.imageOverView);

    React.useEffect(() => {
        if (props.view) {
            const arrParts: Array<PartsType> = [];

            if (imageOverView.parts_segmentation !== null) {
                imageOverView.parts_segmentation.map((part) => {
                    arrParts.push({
                        part_id: part.part_id,
                        part_name: part.part_name,
                        bounding_box: part.bounding_box,
                        color: part.color_value,
                        contours: part.contours,
                    });
                });
            }

            setParts(arrParts);
        } else {
            setParts([]);
            setCurrentPart([]);
            setSelectedPart(null);
        }
    }, [props.view]);

    React.useEffect(() => {
        if (selectedPart?.id && scale) {
            let pointsConcat: any = [];
            let part = parts.find((p) => p.part_id === selectedPart?.id) as PartsType;

            part &&
                part.contours.forEach((points) => {
                    pointsConcat.push(
                        points.map((i) => {
                            return { x: Math.floor(i.x * scale), y: Math.floor(i.y * scale) };
                        })
                    );
                });

            setCurrentPart(pointsConcat);
        }
    }, [selectedPart, scale]);

    const handleToggle = (part: PartsType) => () => {
        let pointsConcat: any = [];
        setSelectedPart({ id: part.part_id, color: part.color });

        if (selectedPart?.id === part.part_id) {
            setSelectedPart(null);
        }

        part.contours.forEach((points) => {
            pointsConcat.push(
                points.map((i) => {
                    return { x: Math.floor(i.x * scale), y: Math.floor(i.y * scale) };
                })
            );
        });

        setCurrentPart(pointsConcat);
    };

    return (
        <Box sx={{ display: panelViewLayout === MenuEnum.Parts ? 'block' : 'none' }}>
            {parts.length > 0 && (
                <List sx={classes.listMain}>
                    {parts.map((value, index) => (
                        <div key={index}>
                            <ListItemButton selected={value.part_id === selectedPart?.id} dense onClick={handleToggle(value)}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <Brightness1Icon sx={{ color: `rgba(${value.color}, 1)` }} />
                                </ListItemIcon>
                                <ListItemText
                                    id={`checkbox-list-label-${value.part_id}`}
                                    primary={`${value.part_id} - ${value.part_name.replace(/_/g, ' ')}`}
                                />
                            </ListItemButton>
                            <Divider />
                        </div>
                    ))}
                </List>
            )}
        </Box>
    );
};

export default PartsList;
