import { IFetchArgs, IFetchBody } from '../../store/IFetch';
import { ServicesBase } from '../ServicesBase';
import { IAddPermissionToRole, IArgsRole, IRemovePermissionToRole, IRole, IRoles } from '../../infrastructure/DTO/role/role.dto';

class RoleServices extends ServicesBase {
    static async getRoles(params: IFetchArgs<null>): Promise<IRoles> {
        return await this.services('GET', `api/roles`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async updateRole(params: IFetchBody<IRole, IArgsRole>): Promise<IRole> {
        return await this.services('PUT', `api/roles/${params.args?.role_id}`, JSON.stringify({ name: params.body.name }), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async createRole(params: IFetchBody<IRole>): Promise<IRole> {
        return await this.services('POST', `api/roles`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async addPermissionToRole(params: IFetchArgs<IAddPermissionToRole>): Promise<IRole> {
        return await this.services('POST', `api/roles/${params.args?.role_id}/permission/${params.args?.permission_id}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async removePermissionFromRole(params: IFetchArgs<IRemovePermissionToRole>): Promise<any> {
        return await this.services('DELETE', `api/roles/${params.args?.role_id}/permission/${params.args?.permission_id}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async getRolesByUserId(params: IFetchArgs<IArgsRole>): Promise<IRoles> {
        return await this.services('GET', `api/companies/${params.args?.company_id}/users/${params.args?.user_id}/roles`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async addRoleToUser(params: IFetchArgs<IArgsRole>): Promise<any> {
        return await this.services('POST', `companies/${params.args?.company_id}/users/${params.args?.user_id}/role/${params.args?.role_id}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async removeRoleFromUser(params: IFetchArgs<IArgsRole>): Promise<any> {
        return await this.services(
            'DELETE',
            `api/companies/${params.args?.company_id}/users/${params.args?.user_id}/role/${params.args?.role_id}`,
            null,
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async removeRole(params: IFetchArgs<IArgsRole>): Promise<any> {
        return await this.services('DELETE', `home/api/roles/${params.args?.role_id}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async activeRole(params: IFetchArgs<IArgsRole>): Promise<IRole> {
        return await this.services('POST', `api/roles/${params.args?.role_id}/activation`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async deactivateRole(params: IFetchArgs<IArgsRole>): Promise<IRole> {
        return await this.services('POST', `api/roles/${params.args?.role_id}/deactivation`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }
}

export default RoleServices;
