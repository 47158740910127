import { CSSObject, Theme } from '@mui/material';

const appBar = (): CSSObject => ({
    marginTop: '30px',
});

const root = (): CSSObject => ({
    width: '100%',
    marginBottom: '50px',
});

const headerPopup = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
    minHeight: '35px',
});

const mainComponent = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
    height: '215px',
});

const titlePopup = (theme: Theme): CSSObject => ({
    color: theme.palette.primary.main,
    fontSize: '1.4em',
    fontWeight: 500,
});

const tableRoot = (): CSSObject => ({
    width: '100%',
    marginTop: '30px',
    minHeight: '680px',
});

const tableRow = (): CSSObject => ({
    '&:hover': {
        cursor: 'pointer',
    },
});

const container = (): CSSObject => ({
    height: 'calc(100vh - 235px)',
    position: 'relative',
});

const titlePage = (): CSSObject => ({
    textTransform: 'uppercase',
});

const btnPage = (): CSSObject => ({
    marginBottom: '10px',
    textAlign: 'right',
});

const titleMainBlock = (): CSSObject => ({
    textTransform: 'uppercase',
});

const subTitleMainBlock = (): CSSObject => ({
    fontSize: '1.3em',
    textAlign: 'center',
    fontWeight: 500,
    textTransform: 'uppercase',
    paddingTop: '20px',
    marginTop: '10px',
    paddingBottom: '20px',
});

const titleBlock = (theme: Theme): CSSObject => ({
    textAlign: 'center',
    paddingTop: '20px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.palette.primary.main,
});

const numberBlock = (theme: Theme): CSSObject => ({
    fontSize: 35,
    marginTop: 10,
    paddingBottom: 20,
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.secondary.main,
});

const visuallyHidden = (): CSSObject => ({
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: '20px',
    width: 1,
});

const content = (): CSSObject => ({
    padding: 0,
});

const cellLong = (): CSSObject => ({
    width: '250px',
    maxWidth: '250px',
    minWidth: '250px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:hover': {
        cursor: 'pointer',
    },
});

const iconBtn = (): CSSObject => ({
    marginRight: '5px',
});

const cellShort = (): CSSObject => ({
    width: '100px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:hover': {
        cursor: 'pointer',
    },
});

const chip = (): CSSObject => ({
    marginRight: '10px',
});

const cellId = (): CSSObject => ({
    width: '320px',
    minWidth: '320px',
    maxWidth: '320px',
});

const cellCnt = (): CSSObject => ({
    width: '50px',
    minWidth: '50px',
    maxWidth: '50px',
});

const btn = (): CSSObject => ({
    marginRight: '15px',
});

let containerMain = (): CSSObject => ({
    height: 'calc(100vh - 250px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
});

const main = (): CSSObject => ({
    width: '100%',
    height: 'calc(100vh - 180px)',
});

export const classes = {
    main,
    chip,
    cellShort,
    iconBtn,
    btn,
    content,
    cellId,
    cellCnt,
    cellLong,
    visuallyHidden,
    numberBlock,
    appBar,
    root,
    headerPopup,
    titlePopup,
    tableRoot,
    titleBlock,
    subTitleMainBlock,
    titleMainBlock,
    btnPage,
    titlePage,
    container,
    tableRow,
    containerMain,
    mainComponent,
};
