export enum DAMAGE_COLOR {
    "#FFFF00" = 6,
    "#FF0000" = 4,
    "#800080" = 12,
    "#FF8C00" = 50,
    "#90ee90" = 51,
    "#0000FF" = 52,
    "#008000" = 53,
    "#00FFFF" = 54,
}

export enum DAMAGE_NAME {
    'Dented' = 4,
    'Cracked' = 6,
    'Missing' = 8,
    'Scratched' = 12,
    'Hail' = 50,
    'Rust' = 51,
    'Broken lights' = 52,
    'Detachment' = 53,
    'Paint Peel' = 54
}

export const DAMAGE_TYPE = (type: number) => {
    switch (type) {
        case 1:
            return 'Bent'
        case 2:
            return 'Broken'
        case 3:
            return 'Cut'
        case 4:
            return 'Dented'
        case 5:
            return 'Chipped'
        case 6:
            return 'Cracked'
        case 7:
            return 'Gouged'
        case 8:
            return 'Missing (Except Molding/ Emblem)'
        case 9:
            return 'Scuffed'
        case 10:
            return 'Stained or Soiled'
        case 11:
            return 'Punctured'
        case 12:
            return 'Scratched'
        case 13:
            return 'Torn'
        case 14:
            return 'Dented Paint not Damaged'
        case 18:
            return 'Molding/Emblem, Weatherstrip-Damaged'
        case 19:
            return 'Molding/Emblem, Weatherstrip-Loose'
        case 20:
            return 'Glass- Cracked'
        case 21:
            return 'Glass- Broken'
        case 22:
            return 'Glass - Chipped'
        case 23:
            return 'Glass- Scratched'
        case 24:
            return 'Marker Light/Additional Turn Light Damage'
        case 25:
            return 'Decal/Paint Stripe Damaged'
        case 29:
            return 'Contamination - Exterior'
        case 30:
            return 'Fluid Spillage- Exterior'
        case 34:
            return 'Chipped Panel Edge'
        case 36:
            return 'Incorrect Part or Option not as Invoiced'
        case 37:
            return 'Hardware - Damage'
        case 38:
            return 'Hardware - Loose, Missing'
        case 50:
            return 'Hail'
        case 51:
            return 'Rust'
        case 52:
            return 'Broken lights'
        case 53:
            return 'Detachment'
        case 54:
            return 'Paint Peel'
    }
}