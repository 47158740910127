import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserServices from '../../services/users/user-services';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs, IFetchBody } from '../IFetch';
import { IArgsRouteUser, IUserRequestView, IUsersView } from '../../infrastructure/DTO/users/user-view.dto';
import { IUser, IUserCreate, IUsersManagement, IUserUpdate } from '../../infrastructure/DTO/users/user.dto';
import { ICompany } from '../../infrastructure/DTO/company/company.dto';
import { IBusinessUnit } from '../../infrastructure/DTO/bussiness-unit/business-units.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

interface IInitialState {
    usersData: IUsersManagement;
    status: string;
    statusActive: string;
    statusDeactivate: string;
    error: IErrorResponse;
    userDataCreateStatus: string;
    userDataUpdateStatus: string;
    userDataCreateError: IErrorResponse;
    userDataUpdateError: IErrorResponse;
}

export const fetchAllUsers = createAsyncThunk<IUsersView, IFetchArgs<IUserRequestView>>('user/get-all', async (params) => {
    return await UserServices.users(params);
});

export const fetchUserCreate = createAsyncThunk<IUser, IFetchBody<IUserCreate, IArgsRouteUser>>('user/post', async (params) => {
    return await UserServices.create(params);
});

export const fetchUserUpdate = createAsyncThunk<IUser, IFetchBody<IUserUpdate, IArgsRouteUser>>('user/put', async (params) => {
    return await UserServices.update(params);
});

export const fetchUserActive = createAsyncThunk<Partial<IUser>, IFetchArgs<IArgsRouteUser>>('user/active', async (params) => {
    return await UserServices.activeUser(params);
});

export const fetchUserDeactivate = createAsyncThunk<Partial<IUser>, IFetchArgs<IArgsRouteUser>>('user/deactivate', async (params) => {
    return await UserServices.deactivateUser(params);
});

export const usersSlice = createSlice({
    name: 'user',
    initialState: {
        usersData: {} as IUsersManagement,
        status: '',
        statusActive: '',
        statusDeactivate: '',
        error: {} as IErrorResponse,
        userDataCreateStatus: '',
        userDataUpdateStatus: '',
        userDataCreateError: {} as IErrorResponse,
        userDataUpdateError: {} as IErrorResponse,
    } as IInitialState,
    reducers: {
        clearStore: (state: IInitialState) => {
            state.userDataCreateStatus = '';
            state.userDataUpdateStatus = '';
            state.userDataCreateError = {} as IErrorResponse;
            state.userDataUpdateError = {} as IErrorResponse;
            state.statusActive = '';
            state.statusDeactivate = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllUsers.pending, (state: IInitialState) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchAllUsers.fulfilled, (state: IInitialState, action) => {
            state.status = StatusRedux.Succeeded;
            state.usersData = action.payload;
        });
        builder.addCase(fetchAllUsers.rejected, (state: IInitialState, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUserCreate.pending, (state: IInitialState) => {
            state.userDataCreateStatus = StatusRedux.Loading;
        });
        builder.addCase(fetchUserCreate.fulfilled, (state: IInitialState, action) => {
            state.userDataCreateStatus = StatusRedux.Succeeded;
            state.usersData.UsersList.push(action.payload);
        });
        builder.addCase(fetchUserCreate.rejected, (state: IInitialState, action) => {
            state.userDataCreateStatus = StatusRedux.Failed;
            state.userDataCreateError = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUserUpdate.pending, (state: IInitialState) => {
            state.userDataUpdateStatus = StatusRedux.Loading;
        });
        builder.addCase(fetchUserUpdate.fulfilled, (state: IInitialState, action) => {
            state.userDataUpdateStatus = StatusRedux.Succeeded;

            if (state.usersData.UsersList !== undefined) {
                const index = state.usersData.UsersList.findIndex((x: IUser) => x.user_id === action.payload.user_id);

                const email: string = action.payload.email as string;
                const name: string = action.payload.name as string;
                const is_active: boolean = action.payload.is_active as boolean;

                const business_unit: IBusinessUnit = {
                    ...action.payload.business_unit,
                    name: action.payload.business_unit?.name as string,
                    business_unit_id: action.payload.business_unit?.business_unit_id as string,
                };
                const company: ICompany = {
                    ...action.payload.company,
                    company_id: action.payload.company?.company_id,
                    name: action.payload.company?.name as string,
                };

                state.usersData.UsersList = [
                    ...state.usersData.UsersList.slice(0, index),
                    { ...state.usersData.UsersList[index], email, name, is_active, business_unit, company },
                    ...state.usersData.UsersList.slice(index + 1),
                ];
            }
        });
        builder.addCase(fetchUserUpdate.rejected, (state: IInitialState, action) => {
            state.userDataUpdateStatus = StatusRedux.Failed;
            state.userDataUpdateError = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUserActive.pending, (state: IInitialState, action) => {
            state.statusActive = StatusRedux.Loading;
        });
        builder.addCase(fetchUserActive.fulfilled, (state: IInitialState, action) => {
            state.statusActive = StatusRedux.Succeeded;
            const index = state.usersData.UsersList.findIndex((x: IUser) => x.user_id === action.payload.user_id);

            state.usersData.UsersList = [
                ...state.usersData.UsersList.slice(0, index),
                { ...state.usersData.UsersList[index], is_active: 1 },
                ...state.usersData.UsersList.slice(index + 1),
            ];
        });
        builder.addCase(fetchUserActive.rejected, (state: IInitialState, action) => {
            state.statusActive = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUserDeactivate.pending, (state: IInitialState, action) => {
            state.statusDeactivate = StatusRedux.Loading;
        });
        builder.addCase(fetchUserDeactivate.fulfilled, (state: IInitialState, action) => {
            state.statusDeactivate = StatusRedux.Succeeded;
            const index = state.usersData.UsersList.findIndex((x: IUser) => x.user_id === action.payload.user_id);

            state.usersData.UsersList = [
                ...state.usersData.UsersList.slice(0, index),
                { ...state.usersData.UsersList[index], is_active: 0 },
                ...state.usersData.UsersList.slice(index + 1),
            ];
        });
        builder.addCase(fetchUserDeactivate.rejected, (state: IInitialState, action) => {
            state.statusDeactivate = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStore } = usersSlice.actions;

export default usersSlice;
