import * as React from 'react';
import moment from 'moment';
import {
    Button,
    Checkbox,
    Drawer,
    FormControl,
    FormGroup,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { UserPermission } from '../../../../config';
import { classes } from '../list/style';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { InspectionStatusName } from '../../../../enums/InspectionStatus';
import { AppDispatch, AppState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { initFilter, inspectionFilterStore } from '../../../../store/inspection/inspection-list';
import { clearStoreBusinessUnits } from '../../../../store/business-units/business-units';
import { ClearStateCompanies } from '../../../../store/company/companies';
import { fetchGetCompanyById } from '../../../../store/company/company';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IArgsCompany, ICompany } from '../../../../infrastructure/DTO/company/company.dto';
import { IBusinessUnit } from '../../../../infrastructure/DTO/bussiness-unit/business-units.dto';
import { IInspectionFilter } from '../../../../infrastructure/DTO/inspection/inspection.dto';
import CompaniesSelect from '../../../../common/ui-components/CompaniesSelect';
import BusinessUnitSelect from '../../../../common/ui-components/BusinessUnitSelect';
import { ClosureReason } from '../../../../enums/ClosureReason';
import { StatusRedux } from '../../../../enums/StatusRedux';

interface IFilter {
    open: boolean;
    toggleDrawer(): void;
}

const InputCheckBoxProps = {
    PaperProps: {
        style: {
            maxHeight: 290,
        },
    },
};

const Filter: React.FC<IFilter> = (props: IFilter) => {
    const initFromDate: Date | null = new Date(moment(new Date().setHours(0, 0, 0)).subtract(1, 'month') as unknown as string);
    const initFromDateTime: number | null = new Date().setHours(0, 0, 0);
    const initToDate: Date | null = new Date(moment(new Date().setHours(23, 59, 59)).subtract(1, 'seconds') as unknown as string);
    const initToDateTime: number | null = new Date().setHours(23, 59, 59);

    const { token, parseToken, checkPermission } = useContext(CustomAuthContext);
    const dispatch: AppDispatch = useDispatch();
    const { inspectionFilter } = useSelector((state: AppState) => state.inspectionList);
    const { inspectionListType } = useSelector((state: AppState) => state.inspectionType);
    const [dateFrom, setDateFrom] = React.useState<{ date?: Date | null; time?: number | null }>({
        date: initFromDate,
        time: initFromDateTime,
    });
    const [dateTo, setDateTo] = React.useState<{ date?: Date | null; time?: number | null }>({
        date: initToDate,
        time: initToDateTime,
    });
    const [companySelect, setCompanySelect] = React.useState<ICompany | null>(null);
    const [businessUnitSelect, setBusinessUnitSelect] = React.useState<IBusinessUnit | null>(null);
    const matches = useMediaQuery('(max-width:600px)');
    const [filter, setFilter] = React.useState<IInspectionFilter>(initFilter);
    const [filterByDates, setFilterByDates] = React.useState<boolean>(false);

    const businessUnits = useSelector((state: AppState) => state.businessUnits);
    const companies = useSelector((state: AppState) => state.companies);

    /**
     * Change Inputs
     * @param event
     */
    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    /**
     * Change DateTime From/To
     */
    React.useEffect(() => {
        _changeDates();
    }, [dateFrom, dateTo]);

    /**
     * Change Inspection Status
     * @param event
     */
    const handleChangeInspectionStatus = (event: SelectChangeEvent<typeof filter.inspection_statuses>) => {
        const {
            target: { value },
        } = event;

        setFilter({
            ...filter,
            inspection_statuses: typeof value === 'string' ? value.split(',') : value,
        });
    };

    /**
     * Change Closure Reason
     * @param event
     */
    const handleChangeClosureReason = (event: SelectChangeEvent<typeof filter.closure_reason>) => {
        const {
            target: { value },
        } = event;

        setFilter({
            ...filter,
            closure_reason: typeof value === 'string' ? value.split(',') : value,
        });
    };

    const handleResultSent = (event: any) => {
        setFilter({
            ...filter,
            call_back_response_result_not_sent: event.target.checked ? event.target.checked : null,
        });
    };

    /**
     * Change Inspection Type
     * @param event
     */
    const handleChangeInspectionType = (event: SelectChangeEvent<typeof filter.inspection_types>) => {
        const {
            target: { value },
        } = event;

        setFilter({
            ...filter,
            inspection_types: typeof value === 'string' ? value.split(',') : value,
        });
    };

    /**
     * Change Date To
     * @param event
     */
    const handleChangeInputDateTo = (event: Date | null) => {
        setDateTo({
            ...dateTo,
            date: event,
        });
    };

    /**
     * Change Time To
     * @param event
     */
    const handleChangeInputTimeTo = (event: Date | null) => {
        setDateTo({
            ...dateTo,
            time: event?.getTime(),
        });
    };

    /**
     * Change Date From
     * @param event
     */
    const handleChangeInputDateFrom = (event: Date | null) => {
        setDateFrom({
            ...dateFrom,
            date: event,
        });
    };

    /**
     * Change Time From
     * @param event
     */
    const handleChangeInputTimeFrom = (event: Date | null) => {
        setDateFrom({
            ...dateFrom,
            time: event?.getTime(),
        });
    };

    /**
     * Set Filter
     */
    React.useEffect(() => {
        if (props.open) {
            if (checkPermission(UserPermission.ADMIN)) {
                const args: IArgsCompany = {
                    company_id: parseToken().company,
                };
                dispatch(fetchGetCompanyById({ token, args }));
            }
        } else {
            dispatch(ClearStateCompanies());
            dispatch(clearStoreBusinessUnits());
        }
    }, [props.open]);

    const _changeDates = () => {
        setFilter({
            ...filter,
            from_date: filterByDates
                ? new Date(
                      moment(moment(dateFrom.date).format('YYYY-MM-DD') + ' ' + moment(dateFrom.time).format('HH:mm')).format('YYYY-MM-DD HH:mm:ss')
                  )
                : null,
            to_date: filterByDates
                ? new Date(moment(moment(dateTo.date).format('YYYY-MM-DD') + ' ' + moment(dateTo.time).format('HH:mm')).format('YYYY-MM-DD HH:mm:ss'))
                : null,
        });
    };

    /**
     * Update Filter
     */
    React.useEffect(() => {
        if (inspectionFilter.company_id === null) {
            setCompanySelect(null);
        }

        if (inspectionFilter.business_unit_id === null) {
            setBusinessUnitSelect(null);
        }

        setFilter(inspectionFilter);
    }, [inspectionFilter]);

    /**
     * Close Filter
     */
    const onClose = () => {
        props.toggleDrawer();

        if (!inspectionFilter.company_id) {
            setCompanySelect(null);
        } else {
            let company = companies.companies.companies?.find((c) => c.company_id === inspectionFilter.company_id);
            setCompanySelect(company ? company : null);
        }

        if (!inspectionFilter.business_unit_id) {
            setBusinessUnitSelect(null);
        } else {
            let businessUnit = businessUnits.businessUnits.business_units?.find((b) => b.business_unit_id === inspectionFilter.business_unit_id);
            setBusinessUnitSelect(businessUnit ? businessUnit : null);
        }

        if (inspectionFilter.from_date !== null && inspectionFilter.to_date !== null) {
            setDateFrom({
                date: inspectionFilter.from_date,
                time: inspectionFilter.from_date?.getTime(),
            });
            setDateTo({
                date: inspectionFilter.to_date,
                time: inspectionFilter.to_date?.getTime(),
            });
        }

        setFilter(inspectionFilter);
    };

    /**
     * Search By Filter
     */
    const onFilter = () => {
        dispatch(
            inspectionFilterStore({
                ...filter,
                inspection_id: filter?.inspection_id?.trim(),
                client_process_id: filter?.client_process_id?.trim(),
                client_name: filter?.client_name?.trim(),
                lpn: filter?.lpn?.trim(),
                vin: filter?.vin?.trim(),
                limit: 25,
                offset: 0,
            })
        );

        props.toggleDrawer();
    };

    React.useEffect(() => {
        if (companySelect) {
            setFilter({
                ...filter,
                company_id: companySelect.company_id,
                business_unit_id: '',
            });
        } else {
            setFilter({
                ...filter,
                company_id: '',
            });
        }
    }, [companySelect]);

    React.useEffect(() => {
        if (businessUnitSelect) {
            setFilter({ ...filter, business_unit_id: businessUnitSelect.business_unit_id as string });
        } else {
            setFilter({
                ...filter,
                company_id: '',
            });
            setFilter({ ...filter, business_unit_id: '' });
        }
    }, [businessUnitSelect]);

    const handleFilterByDates = () => {
        setFilterByDates(!filterByDates);
    };

    React.useEffect(() => {
        if (!filterByDates) {
            setFilter({
                ...filter,
                from_date: null,
                to_date: null,
            });
            setDateFrom({
                date: initFromDate,
                time: initFromDateTime,
            });
            setDateTo({
                date: initToDate,
                time: initToDateTime,
            });
        } else {
            _changeDates();
        }
    }, [filterByDates]);

    React.useEffect(() => {
        if (filter.from_date === null || filter.to_date === null) {
            setFilterByDates(false);
        }
    }, [filter]);

    React.useEffect(() => {
        if (!checkPermission(UserPermission.ADMIN)) {
            if (businessUnits.status === StatusRedux.Succeeded && companySelect?.company_id) {
                let businessUnit = businessUnits.businessUnits.business_units.find((b) => b.business_unit_id === inspectionFilter.business_unit_id);
                setBusinessUnitSelect(businessUnit ? businessUnit : null);
            }
        } else {
            if (businessUnits.status === StatusRedux.Succeeded) {
                let businessUnit = businessUnits.businessUnits.business_units.find((b) => b.business_unit_id === inspectionFilter.business_unit_id);
                setBusinessUnitSelect(businessUnit ? businessUnit : null);
            }
        }
    }, [businessUnits, companySelect, !checkPermission(UserPermission.ADMIN)]);

    return (
        <Drawer style={{ zIndex: 1300, overflowY: 'scroll', height: '100vh' }} anchor='right' onClose={onClose} open={props.open}>
            <Paper elevation={3} sx={classes.filterBlock}>
                <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant='h5' noWrap sx={classes.titleFilterBlock}>
                            ADVANCED FILTER
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            name='inspection_id'
                            onChange={handleChangeInput}
                            value={filter.inspection_id}
                            fullWidth
                            size='small'
                            id='inspection-id'
                            label='Inspection ID'
                            variant='outlined'
                        />
                    </Grid>
                    {!checkPermission(UserPermission.ADMIN) && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CompaniesSelect removeValue={true} size='small' variant='outlined' data={companySelect} setData={setCompanySelect} />
                        </Grid>
                    )}
                    {!checkPermission(UserPermission.USER_MANAGEMENT) && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <BusinessUnitSelect
                                size='small'
                                variant='outlined'
                                clear={companySelect === null}
                                company={companySelect}
                                disabled={
                                    !checkPermission(UserPermission.ADMIN) ? companySelect === null : checkPermission(UserPermission.USER_MANAGEMENT)
                                }
                                setData={setBusinessUnitSelect}
                                data={businessUnitSelect}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            name='lpn'
                            onChange={handleChangeInput}
                            value={filter.lpn}
                            fullWidth
                            size='small'
                            id='lpn'
                            label='Plate number'
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            name='vin'
                            onChange={handleChangeInput}
                            value={filter.vin}
                            fullWidth
                            size='small'
                            id='vin'
                            label='VIN'
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            name='client_process_id'
                            onChange={handleChangeInput}
                            value={filter.client_process_id}
                            fullWidth
                            size='small'
                            id='client-pid'
                            label='Client PID'
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl size='small' fullWidth>
                            <InputLabel id='closure_reason'>Closure Reason</InputLabel>
                            <Select
                                labelId='closure-reason'
                                id='closure-reason'
                                multiple
                                value={filter.closure_reason}
                                onChange={handleChangeClosureReason}
                                input={<OutlinedInput label='Closure Reason' />}
                                MenuProps={InputCheckBoxProps}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {Object.keys(ClosureReason).map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        <Checkbox checked={filter?.closure_reason?.indexOf(item) > -1} />
                                        <ListItemText primary={item} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl size='small' fullWidth>
                            <InputLabel id='inspection-type'>Inspection Type</InputLabel>
                            <Select
                                labelId='inspection-type'
                                id='inspection-type'
                                multiple
                                value={filter.inspection_types}
                                input={<OutlinedInput label='Inspection Type' />}
                                onChange={handleChangeInspectionType}
                                MenuProps={InputCheckBoxProps}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {inspectionListType.map((item, index) => (
                                    <MenuItem key={index} value={item.type}>
                                        <Checkbox checked={filter.inspection_types.indexOf(item.type) > -1} />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl size='small' fullWidth>
                            <InputLabel id='inspection-status'>Inspection Status</InputLabel>
                            <Select
                                labelId='inspection-status'
                                id='inspection-status'
                                multiple
                                value={filter.inspection_statuses}
                                onChange={handleChangeInspectionStatus}
                                input={<OutlinedInput label='Inspection Type' />}
                                MenuProps={InputCheckBoxProps}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {Object.keys(InspectionStatusName).map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        <Checkbox checked={filter.inspection_statuses.indexOf(item) > -1} />
                                        <ListItemText primary={item} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch color='primary' onChange={handleFilterByDates} checked={filterByDates} />}
                                label='Filter By Dates'
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <DatePicker
                                    disabled={!filterByDates}
                                    label='Date'
                                    mask='__/__/____'
                                    value={dateFrom.date}
                                    inputFormat='dd/MM/yyyy'
                                    onChange={handleChangeInputDateFrom}
                                    renderInput={(params: any) => <TextField fullWidth={true} size='small' {...params} />}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TimePicker
                                    disabled={!filterByDates}
                                    onChange={handleChangeInputTimeFrom}
                                    value={dateFrom.time}
                                    label='Time'
                                    mask='__:__'
                                    ampm={false}
                                    disableOpenPicker={!matches}
                                    inputFormat='HH:mm'
                                    renderInput={(params: any) => <TextField fullWidth={true} size='small' {...params} />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <DatePicker
                                    disabled={!filterByDates}
                                    label='Date'
                                    mask='__/__/____'
                                    value={dateTo.date}
                                    inputFormat='dd/MM/yyyy'
                                    onChange={handleChangeInputDateTo}
                                    renderInput={(params: any) => <TextField fullWidth={true} size='small' {...params} />}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TimePicker
                                    disabled={!filterByDates}
                                    onChange={handleChangeInputTimeTo}
                                    value={dateTo.time}
                                    label='Time'
                                    mask='__:__'
                                    ampm={false}
                                    disableOpenPicker={!matches}
                                    inputFormat='HH:mm'
                                    renderInput={(params: any) => <TextField fullWidth={true} size='small' {...params} />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch color='primary' onChange={handleResultSent} checked={!!filter.call_back_response_result_not_sent} />}
                                label='Result Not Sent'
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid mt={5} mb={1} container spacing={3} direction='row' justifyContent='center' alignItems='center'>
                    <div style={{ background: '#fff', position: 'fixed', bottom: '10px', right: '10px', width: '260px', display: 'flex' }}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ padding: 7 }}>
                            <Button fullWidth onClick={onClose} variant='outlined' color='primary'>
                                Close
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ padding: 7 }}>
                            <Button fullWidth onClick={onFilter} variant='contained' color='primary'>
                                Search
                            </Button>
                        </Grid>
                    </div>
                </Grid>
            </Paper>
        </Drawer>
    );
};

export default Filter;
