import React from 'react';
import { Checkbox, Collapse, List, ListItemIcon, ListItemSecondaryAction, ListItemText, ListItemButton } from '@mui/material';
import { classes } from '../style';
import { DAMAGE_COLOR, DAMAGE_NAME } from '../../../../../../enums/Damage';
import Chip from '@mui/material/Chip';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import { IMeasurements } from '../../../../../../infrastructure/DTO/damage-lab/anomaly-measurements.dto';
import DamageLabLayoutContext, { IDamageLabLayoutContext } from '../../../../../../context/DamageLabLayoutContext';
import { IDamageLabLayout } from '../IDamageLabLayout';
import { MenuEnum } from '../damagelab-menu/menu';

type DamagesType = {
    id: number;
    damage_id: string;
    damage_status: string;
    damage_type: number;
    score: string;
    contours: Array<{ x: number; y: number }>;
};

interface IMeasurementListComponent extends IDamageLabLayout {
    fullscreenActive: boolean;
}

const MeasurementList: React.FC<IMeasurementListComponent> = (props: IMeasurementListComponent) => {
    const {
        panelViewLayout,
        scale,
        measurements,
        checkedMeasurement,
        points,
        setPoints,
        setCheckedMeasurement,
        handleMeasurementInfo,
        setPanelContours,
        setMeasurementPoints,
        setQuadrangle,
        setMeasurementCurrentData,
        setZoomPerArea,
    } = React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);
    const { imageOverView } = useSelector((state: AppState) => state.imageOverView);
    const [damages, setDamages] = React.useState<Array<DamagesType>>([]);
    const [opened, setOpened] = React.useState<Array<string>>([]);
    const [currentMeasurement, setCurrentMeasurement] = React.useState<IMeasurements | null>(null);

    React.useEffect(() => {
        if (props.view) {
            const arrDamages: Array<DamagesType> = [];

            if (imageOverView.detected_damages) {
                imageOverView.detected_damages
                    .filter((damage) => damage.damage_status.code !== 'DELETED')
                    .map((i, index: number) => {
                        arrDamages.push({
                            id: index + 1,
                            damage_id: i.damage_id,
                            damage_status: i.damage_status.code,
                            damage_type: i.damage_type,
                            score: (i.segmentation.score * 100).toFixed(2),
                            contours: i.segmentation.contours[0],
                        });
                    });
            }

            setDamages(arrDamages);
        } else {
            setCurrentMeasurement(null);
            handleMeasurementInfo(false);
            setDamages([]);
            setOpened([]);
        }
    }, [props.view, scale]);

    const handleToggle = (value: DamagesType) => () => {
        let point: Array<number> = [];

        value.contours.map((p) => {
            point.push(Number(p.x * scale), Number(p.y * scale));
        });

        if (points.has(value.damage_id)) {
            points.delete(value.damage_id);
        } else {
            setPoints(points.set(value.damage_id, { pointsArr: point, color: DAMAGE_COLOR[value.damage_type] }));
        }

        const currentIndex = checkedMeasurement.indexOf(value.damage_id);
        const newChecked = [...checkedMeasurement];

        if (currentIndex === -1) {
            newChecked.push(value.damage_id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedMeasurement(newChecked);
    };

    const handleClickOpen = (value: DamagesType) => () => {
        const currentIndex = opened.indexOf(value.damage_id);
        const openedArr = [...opened];

        if (currentIndex === -1) {
            openedArr.push(value.damage_id);
        } else {
            openedArr.splice(currentIndex, 1);
        }
        setOpened(openedArr);
    };

    const handleShowMeasure = () => {
        handleMeasurementInfo(true);
    };

    const handleAnomaly = (measurement: IMeasurements) => () => {
        if (measurement) {
            if (measurement.measurement_id === currentMeasurement?.measurement_id) {
                setZoomPerArea(undefined);
                setCurrentMeasurement(null);
            } else {
                setCurrentMeasurement(measurement);

                setZoomPerArea({
                    x: measurement?.roi.x,
                    y: measurement?.roi.y,
                    width: measurement?.roi.width,
                    height: measurement?.roi.height,
                });
            }
        }
    };

    React.useEffect(() => {
        if (currentMeasurement) {
            let points: number[] = [];
            let panelContoursPoints: any = [];
            let result: Array<{ key: string; value: any | null; tag: any }> = [
                { key: 'Damaged Part', value: currentMeasurement?.damaged_part.part_description, tag: null },
                // { key: 'Side', value: damageLabData.camera_view.replace('_', ' '), tag: null },
                {
                    key: 'Damage Area Codes',
                    value: currentMeasurement?.damage_area_codes !== null && currentMeasurement?.damage_area_codes.join(', '),
                    tag: null,
                },
                {
                    key: `Part Area, `,
                    value: !isNaN(currentMeasurement?.damaged_part_area as number) ? currentMeasurement?.damaged_part_area?.toFixed(2) : 0,
                    tag: (
                        <span>
                            cm<sup>2</sup>
                        </span>
                    ),
                },
                {
                    key: 'Damage Area, ',
                    value: !isNaN(currentMeasurement?.damage_area as number) ? currentMeasurement?.damage_area?.toFixed(2) : 0,
                    tag: (
                        <span>
                            cm<sup>2</sup>
                        </span>
                    ),
                },
                {
                    key: 'Damage Severity, %',
                    value: !isNaN(currentMeasurement?.damage_severity as number)
                        ? +((currentMeasurement?.damage_severity as number) * 100).toFixed(2)
                        : 0,
                    tag: null,
                },
            ];
            let minimalEnclosingBox: Array<{ key: string; value: any | null; tag: any }> = [
                { key: 'Width, cm', value: currentMeasurement?.minimal_enclosing_box?.width?.toFixed(2), tag: null },
                { key: 'Height, cm', value: currentMeasurement?.minimal_enclosing_box?.height?.toFixed(2), tag: null },
                {
                    key: 'Area, ',
                    value: currentMeasurement?.minimal_enclosing_box?.area?.toFixed(2),
                    tag: (
                        <span>
                            cm<sup>2</sup>
                        </span>
                    ),
                },
                { key: 'Minimum distance to Panel, cm', value: currentMeasurement?.minimum_distance_to_panels.distance?.toFixed(2), tag: null },
            ];
            let threeBoundingBox: Array<{ key: string; value: any | null }> = [
                { key: 'XgeoMin, cm', value: currentMeasurement?.minimal_3d_enclosing_box.min.x?.toFixed(2) },
                { key: 'YgeoMin, cm', value: currentMeasurement?.minimal_3d_enclosing_box.min.y?.toFixed(2) },
                { key: 'ZgeoMin, cm', value: currentMeasurement?.minimal_3d_enclosing_box.min.z?.toFixed(2) },
                { key: 'XgeoMax, cm', value: currentMeasurement?.minimal_3d_enclosing_box.max.x?.toFixed(2) },
                { key: 'YgeoMax, cm', value: currentMeasurement?.minimal_3d_enclosing_box.max.y?.toFixed(2) },
                { key: 'ZgeoMax, cm', value: currentMeasurement?.minimal_3d_enclosing_box.max.z?.toFixed(2) },
            ];

            setMeasurementCurrentData({
                minimalEnclosingBox: minimalEnclosingBox,
                result: result,
                threeBoundingBox: threeBoundingBox,
            });

            if (currentMeasurement?.contour !== null) {
                currentMeasurement?.contour.forEach((point) => {
                    points.push(Math.floor(point.x * scale), Math.floor(point.y * scale));
                });
            }

            if (currentMeasurement?.panel_contours !== null) {
                currentMeasurement?.panel_contours.forEach((point) => {
                    let points: number[] = [];
                    point.forEach((p) => {
                        points.push(Math.floor(p.x * scale), Math.floor(p.y * scale));
                    });
                    panelContoursPoints.push(points);
                });
            }

            setPanelContours(panelContoursPoints);
            setMeasurementPoints(points);

            if (currentMeasurement?.minimal_enclosing_box?.quadrangle !== undefined) {
                if (currentMeasurement?.minimal_enclosing_box?.quadrangle !== null) {
                    setQuadrangle([
                        Math.floor(currentMeasurement.minimal_enclosing_box.quadrangle.corner1.x * scale),
                        Math.floor(currentMeasurement.minimal_enclosing_box.quadrangle.corner1.y * scale),
                        Math.floor(currentMeasurement.minimal_enclosing_box.quadrangle.corner2.x * scale),
                        Math.floor(currentMeasurement.minimal_enclosing_box.quadrangle.corner2.y * scale),
                        Math.floor(currentMeasurement.minimal_enclosing_box.quadrangle.corner4.x * scale),
                        Math.floor(currentMeasurement.minimal_enclosing_box.quadrangle.corner4.y * scale),
                        Math.floor(currentMeasurement.minimal_enclosing_box.quadrangle.corner3.x * scale),
                        Math.floor(currentMeasurement.minimal_enclosing_box.quadrangle.corner3.y * scale),
                    ]);
                } else {
                    setQuadrangle([]);
                }
            } else {
                setQuadrangle([]);
            }
        }
    }, [currentMeasurement, scale]);

    return (
        <List sx={{ display: panelViewLayout === MenuEnum.Damages ? 'block' : 'none' }}>
            {damages !== null &&
                damages.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.damage_id}`;

                    return (
                        <div key={index}>
                            <ListItemButton dense>
                                <ListItemIcon sx={classes.checkBoxList}>
                                    <Checkbox
                                        onClick={handleToggle(value)}
                                        edge='start'
                                        checked={checkedMeasurement.indexOf(value.damage_id) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${DAMAGE_NAME[value.damage_type]}`} />
                                <ListItemIcon>
                                    <Chip
                                        size='small'
                                        label={`${value.score} %`}
                                        style={{ color: '#fff', background: DAMAGE_COLOR[value.damage_type] }}
                                    />
                                </ListItemIcon>
                                {opened.indexOf(value.damage_id) !== -1 ? (
                                    <ExpandLess onClick={handleClickOpen(value)} sx={classes.iconLessMore} />
                                ) : (
                                    <ExpandMore onClick={handleClickOpen(value)} sx={classes.iconLessMore} />
                                )}
                            </ListItemButton>
                            <Collapse in={opened.indexOf(value.damage_id) !== -1} timeout='auto' unmountOnExit>
                                <List key={index + 1} component='div' disablePadding>
                                    {measurements?.size > 0 &&
                                        measurements.get(value.damage_id)!.measurement.map((measurement, index) => {
                                            return (
                                                <ListItemButton
                                                    selected={measurement.measurement_id === currentMeasurement?.measurement_id}
                                                    key={index + 200}
                                                    sx={classes.nested}
                                                >
                                                    <ListItemText
                                                        onClick={handleAnomaly(measurement)}
                                                        id={measurement.measurement_id}
                                                        primary='Measure'
                                                        secondary={measurement.measurement_id}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            disabled={
                                                                props.fullscreenActive ||
                                                                measurement.measurement_id !== currentMeasurement?.measurement_id
                                                            }
                                                            onClick={handleShowMeasure}
                                                            edge='end'
                                                            aria-label='delete'
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItemButton>
                                            );
                                        })}
                                </List>
                            </Collapse>
                            <Divider />
                        </div>
                    );
                })}
        </List>
    );
};

export default MeasurementList;
