import {createTheme} from "@mui/material/styles";

const themeDark = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#a3b1ff',
            light: '#388e3c',
            dark: '#a3b1ff',
        },
        secondary: {
            main: '#8bc34a',
            light: '#8bc34a',
            dark: '#8bc34a',
        },
        error: {
            main: '#d50000',
        },
    },
    direction: 'ltr'
});

export default themeDark;