import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PublishIcon from '@mui/icons-material/Publish';
import { classes } from './style';
import ImageUploading from 'react-images-uploading';
import { CircularProgress, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ResultMultiple from './result-multiple';
import { AppDispatch, AppState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { clearStateCloseUpRegistration, fetchCloseUpRegistration } from '../../../../store/damage-lab/close-up-registration';
import LoopIcon from '@mui/icons-material/Loop';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { ICloseUpRegistration, IImage } from '../../../../infrastructure/DTO/damage-lab/damage-lab.dto';
import errorParseMessage from '../../../../config/error-parse';

const DamageLabMultiple: React.FC = () => {
    const navigate = useNavigate();
    const { token } = useContext(CustomAuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const { closeUpRegistration, status, error } = useSelector((state: AppState) => state.closeUpRegistration);
    const [imagesFullCar, setImageFullCar] = React.useState<Array<IImage>>([]);
    const [imagesCloseUp, setImageCloseUp] = React.useState<Array<IImage>>([]);
    const [result, setResult] = React.useState<boolean>(false);
    const maxNumber = 1;

    const handleImageFullCar = (imageList: any) => {
        let res: Array<IImage> = imageList.map((item: any, index: number) => {
            return {
                image_id: `f${(+new Date()).toString(15) + index}`,
                name: item.file.name,
                content_type: item.file.type,
                content: item.data_url.split(',')[1],
                context: {
                    view_type: null,
                    side: null,
                    tag: null,
                },
                resolution: null,
            };
        });

        setImageFullCar(imagesFullCar.concat(res));
    };

    const handleImageCloseUp = (imageList: any) => {
        let res: Array<IImage> = imageList.map((item: any, index: number) => {
            return {
                image_id: `f${(+new Date()).toString(15) + index}`,
                name: item.file.name,
                content_type: item.file.type,
                content: item.data_url.split(',')[1],
                context: {
                    view_type: null,
                    side: null,
                    tag: null,
                },
                resolution: null,
            };
        });

        setImageCloseUp(imagesCloseUp.concat(res));
    };

    const handleUploadNewPic = () => {
        navigate('/home/damage-lab/');
        dispatch(clearStateCloseUpRegistration());
    };

    const handleResetPic = () => {
        navigate('/home/damage-lab/multiple');
        dispatch(clearStateCloseUpRegistration());
        setImageFullCar([]);
        setImageCloseUp([]);
        setResult(false);
    };

    const handleClickSend = () => {
        const body: ICloseUpRegistration = {
            close_up_image: imagesCloseUp[0],
            full_car_image: imagesFullCar[0],
        };

        dispatch(
            fetchCloseUpRegistration({
                token,
                body,
            })
        );
    };

    React.useEffect(() => {
        return () => {
            dispatch(clearStateCloseUpRegistration());
            setImageFullCar([]);
            setImageCloseUp([]);
            setResult(false);
        };
    }, []);

    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            enqueueSnackbar('Submitted Successfully', {
                variant: 'success',
            });

            setResult(true);
        }

        if (status === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(error), {
                variant: 'error',
            });
        }
    }, [status]);

    return (
        <>
            <Grid container spacing={5} direction='row' justifyContent='center' alignItems='center' sx={classes.mainHeaderPage}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography variant='h6' noWrap sx={classes.titlePage}>
                        MULTIPLE PICTURES
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={classes.btnPage}>
                    <Button sx={classes.btn} onClick={handleUploadNewPic} size='small' variant='outlined' color='primary' endIcon={<PublishIcon />}>
                        To DamageLab
                    </Button>
                    <Button sx={classes.btn} onClick={handleResetPic} size='small' variant='outlined' color='primary' endIcon={<LoopIcon />}>
                        Reset
                    </Button>
                </Grid>
            </Grid>
            {result ? (
                <ResultMultiple
                    reset={handleResetPic}
                    imageFullCar={imagesFullCar[0].content}
                    imageCloseUp={imagesCloseUp[0].content}
                    result={closeUpRegistration?.fragment_registration}
                />
            ) : (
                <>
                    <Grid container spacing={5} direction='row' justifyContent='center' alignItems='center'>
                        <Grid item xs={12}>
                            <Grid container spacing={5} direction='row' justifyContent='center' alignItems='center'>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Paper elevation={2} sx={classes.paper}>
                                        <Box sx={classes.uploadBox}>
                                            <ImageUploading
                                                multiple
                                                value={imagesFullCar}
                                                onChange={handleImageFullCar}
                                                maxNumber={maxNumber}
                                                dataURLKey='data_url'
                                            >
                                                {({ onImageUpload, dragProps }) => (
                                                    <div className='uploadImageWrapper'>
                                                        {imagesFullCar.length === 0 && (
                                                            <div className='upload' onClick={onImageUpload} {...dragProps}>
                                                                <div className='icon'>
                                                                    <svg
                                                                        width='44'
                                                                        height='43'
                                                                        viewBox='0 0 44 43'
                                                                        fill='none'
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                    >
                                                                        <path d='M21.6694 40.4079L21.6694 21.7413' stroke='#000133' strokeWidth='2' />
                                                                        <path
                                                                            d='M30.0308 27.5746L21.6685 19.4068L13.3063 27.5746'
                                                                            stroke='#000133'
                                                                            strokeWidth='2'
                                                                        />
                                                                        <path
                                                                            d='M34.3084 35.7409C38.2759 32.3205 40.7783 27.3161 40.7783 21.7409C40.7783 11.4316 32.222 3.07421 21.6672 3.07421C11.1124 3.07421 2.5561 11.4316 2.5561 21.7409C2.5561 27.3161 5.05848 32.3205 9.02605 35.7409'
                                                                            stroke='#000133'
                                                                            strokeWidth='2'
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <div>Full Car Image</div>
                                                            </div>
                                                        )}
                                                        {imagesFullCar.length !== 0 &&
                                                            imagesFullCar.map((pic: IImage, index) => (
                                                                <Box key={index} sx={classes.imgUpload}>
                                                                    <img src={`data:image/jpeg;base64, ${pic.content}`} alt={pic.image_id} />
                                                                </Box>
                                                            ))}
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Paper elevation={2} sx={classes.paper}>
                                        <Box sx={classes.uploadBox}>
                                            <ImageUploading
                                                multiple
                                                value={imagesCloseUp}
                                                onChange={handleImageCloseUp}
                                                maxNumber={maxNumber}
                                                dataURLKey='data_url'
                                            >
                                                {({ onImageUpload, dragProps }) => (
                                                    <Box className='uploadImageWrapper'>
                                                        {imagesCloseUp.length === 0 && (
                                                            <div className='upload' onClick={onImageUpload} {...dragProps}>
                                                                <div className='icon'>
                                                                    <svg
                                                                        width='44'
                                                                        height='43'
                                                                        viewBox='0 0 44 43'
                                                                        fill='none'
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                    >
                                                                        <path d='M21.6694 40.4079L21.6694 21.7413' stroke='#000133' strokeWidth='2' />
                                                                        <path
                                                                            d='M30.0308 27.5746L21.6685 19.4068L13.3063 27.5746'
                                                                            stroke='#000133'
                                                                            strokeWidth='2'
                                                                        />
                                                                        <path
                                                                            d='M34.3084 35.7409C38.2759 32.3205 40.7783 27.3161 40.7783 21.7409C40.7783 11.4316 32.222 3.07421 21.6672 3.07421C11.1124 3.07421 2.5561 11.4316 2.5561 21.7409C2.5561 27.3161 5.05848 32.3205 9.02605 35.7409'
                                                                            stroke='#000133'
                                                                            strokeWidth='2'
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <div>Close-up Image</div>
                                                            </div>
                                                        )}
                                                        {imagesCloseUp.length !== 0 &&
                                                            imagesCloseUp.map((pic: IImage, index) => (
                                                                <Box key={index} sx={classes.imgUpload}>
                                                                    <img src={`data:image/jpeg;base64, ${pic.content}`} alt={pic.image_id} />
                                                                </Box>
                                                            ))}
                                                    </Box>
                                                )}
                                            </ImageUploading>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <Button
                                        disabled={status === StatusRedux.Loading || !(!(imagesFullCar.length === 0) && !(imagesCloseUp.length === 0))}
                                        onClick={handleClickSend}
                                        fullWidth
                                        size='large'
                                        variant='contained'
                                        color='secondary'
                                    >
                                        {status === StatusRedux.Loading ? 'Loading...' : 'Submit'}
                                        {status === StatusRedux.Loading && <CircularProgress size={25} sx={classes.buttonProgress} />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default DamageLabMultiple;
