import React, { useContext } from 'react';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../store';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Spinner } from '../../../spinner';
import Box from '@mui/material/Box';
import { Error } from '../../../error';
import { IArgsImagesOverView } from '../../../../infrastructure/DTO/multilayered/multilayered.dto';
import { fetchGetImagesOverView } from '../../../../store/multilayered/images-overview';
import errorParseMessage from '../../../../config/error-parse';
import { useNavigate, useSearchParams } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': { cursor: 'pointer' },
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const DrillDown: React.FC = () => {
    const [params, setParams] = useSearchParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const is_open: boolean = params.has('is_open') ? params.get('is_open')?.toLowerCase() === 'true' : false;
    const inspection_id: string = params.has('inspection_id') ? (params.get('inspection_id') as string) : '';
    const { token } = useContext(CustomAuthContext);
    const { imagesOverView, status, error } = useSelector((state: AppState) => state.imagesOverView);

    React.useEffect(() => {
        if (is_open && inspection_id) {
            const args: IArgsImagesOverView = {
                inspection_case: inspection_id,
            };

            dispatch(
                fetchGetImagesOverView({
                    token,
                    args,
                })
            );
        } else {
            navigate(`/home/inspections?is_open=${is_open}&inspection_id=${inspection_id}`);
        }
    }, [is_open, inspection_id]);

    const handleOpenImage = (image_id: string) => () => {
        navigate(`/home/drill-down?inspection_id=${inspection_id}&image_id=${image_id}`);
    };

    return (
        <>
            {status === StatusRedux.Loading && <Spinner />}
            {status === StatusRedux.Failed && <Error msg={errorParseMessage(error)} />}
            {status === StatusRedux.Succeeded && imagesOverView?.images?.length > 0 && (
                <Box mt={2} p={2}>
                    <TableContainer component={Paper}>
                        <Table size='small' aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>#</StyledTableCell>
                                    <StyledTableCell>Image</StyledTableCell>
                                    <StyledTableCell>Model</StyledTableCell>
                                    <StyledTableCell>Year</StyledTableCell>
                                    <StyledTableCell>BodyStyle</StyledTableCell>
                                    <StyledTableCell>Camera View</StyledTableCell>
                                    <StyledTableCell>Image Classification</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {imagesOverView.images.map((img, index: number) => (
                                    <StyledTableRow key={img.image_id} onClick={handleOpenImage(img.image_id)}>
                                        <StyledTableCell component='th' scope='row'>
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <img height='150' src={img.link} alt={img.camera_view} />
                                        </StyledTableCell>
                                        <StyledTableCell>{img.vehicle_model_detection_result?.full_name}</StyledTableCell>
                                        <StyledTableCell>{img.vehicle_model_detection_result?.year}</StyledTableCell>
                                        <StyledTableCell>{img.vehicle_model_detection_result?.bodystyle}</StyledTableCell>
                                        <StyledTableCell>{img.camera_view}</StyledTableCell>
                                        <StyledTableCell>{img.image_classification}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </>
    );
};

export default DrillDown;
