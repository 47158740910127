import React, { useContext } from 'react';
import {
    Box,
    Chip,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { classes } from '../style';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { Spinner } from '../../../spinner';
import { Error } from '../../../error';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../store';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { fetchGetProfiles } from '../../../../store/profile/profiles';
import CreateProfile from './create-profile/create-profile';
import { EditProfile } from './edit-profile';
import Button from '@mui/material/Button';
import { UserPermission } from '../../../../config';
import CreateIcon from '@mui/icons-material/Create';
import { clearStoreProfile } from '../../../../store/profile/profile';
import { IArgsProfile, IProfile } from '../../../../infrastructure/DTO/profile/profile.dto';
import { IUserManagementProvider, UserManagementContext } from '../../../../context/UserManagementProvider';
import { IArgsCompany } from '../../../../infrastructure/DTO/company/company.dto';
import { fetchGetCompanyById } from '../../../../store/company/company';

const FlexibleSettingsProfile: React.FC = () => {
    const { checkPermission, token, parseToken } = useContext(CustomAuthContext);
    const { page, limit, searchMode, searchText, company, setCompany, changePage, changeRowsPerPage, handleCreateProfile, handleEditProfile } =
        useContext<IUserManagementProvider>(UserManagementContext);
    const dispatch: AppDispatch = useDispatch();
    const profiles = useSelector((state: AppState) => state.profiles);
    const profile = useSelector((state: AppState) => state.profile);
    const [dataProfile, setDataProfileUpdate] = React.useState<IProfile | null>(null);
    const [countProfiles, setCountProfiles] = React.useState<number>(0);

    React.useEffect(() => {
        if (company) {
            if (searchMode) {
                const args: IArgsProfile = {
                    company_id: company?.company_id,
                    limit: limit,
                    offset: page + 1,
                    search_text: searchText,
                };
                dispatch(fetchGetProfiles({ token, args }));
            } else {
                if (searchText.length === 0) {
                    const args: IArgsProfile = {
                        company_id: company?.company_id,
                        limit: limit,
                        offset: page + 1,
                        search_text: '',
                    };
                    dispatch(fetchGetProfiles({ token, args }));
                }
            }
        }
    }, [searchText, searchMode, page, limit, company]);

    /**
     * Get Count profiles
     */
    React.useEffect(() => {
        if (profiles.status === StatusRedux.Succeeded) {
            setCountProfiles(profiles.profiles.count);
        }
    }, [profiles.status]);

    /**
     * Update count profiles
     */
    React.useEffect(() => {
        if (profiles.profiles.count) {
            setCountProfiles(profiles.profiles.count);
        }
    }, [profiles.profiles.count]);

    /**
     * Get Profile by profile id
     * set Profile data
     */
    React.useEffect(() => {
        if (profile.status === StatusRedux.Succeeded) {
            setDataProfileUpdate(profile.profile);
            handleEditProfile();
            dispatch(clearStoreProfile());
        }
    }, [profile.status]);

    /**
     * @type IProfile
     * @type null
     * @param data
     * Set Profile to Update
     * Open Popup
     */
    const handleEdit = (data: IProfile | null) => () => {
        setDataProfileUpdate(data);
        handleEditProfile();
    };

    return (
        <>
            <CreateProfile setCountProfiles={setCountProfiles} />
            <EditProfile data={dataProfile} company_name={company?.name as string} />

            <Paper elevation={2} sx={classes.main}>
                {(() => {
                    switch (profiles.status) {
                        case StatusRedux.Loading:
                            return <Spinner style={{ height: 'calc(100vh - 235px)' }} />;
                        case StatusRedux.Succeeded:
                            if (profiles.profiles.profiles.length > 0) {
                                return (
                                    <TableContainer sx={classes.container}>
                                        <Table stickyHeader aria-label='sticky table'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={classes.cellCnt} align='left'>
                                                        #
                                                    </TableCell>
                                                    <TableCell align='left'>Profile ID</TableCell>
                                                    <TableCell align='left'>Profile Name</TableCell>
                                                    <TableCell align='left'>Company</TableCell>
                                                    <TableCell align='left'>Active</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {profiles.profiles.profiles.map((data, index: number) => (
                                                <TableBody key={index}>
                                                    <TableRow hover role='table' tabIndex={-1}>
                                                        <TableCell onClick={handleEdit(data)} sx={classes.cellCnt}>
                                                            {limit * page + (index + 1)}
                                                        </TableCell>
                                                        <TableCell
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(data.profile_id as string).then((r) => r);
                                                            }}
                                                            sx={classes.cellID}
                                                        >
                                                            <Tooltip
                                                                arrow
                                                                disableFocusListener
                                                                title={<Box sx={classes.textTooltip}>Click To Copy</Box>}
                                                            >
                                                                <Link color='inherit' href='#'>
                                                                    {data.profile_id}
                                                                </Link>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell onClick={handleEdit(data)} sx={classes.cellLong}>
                                                            {data.name}
                                                        </TableCell>
                                                        <TableCell onClick={handleEdit(data)} sx={classes.cellLong}>
                                                            {company?.name}
                                                        </TableCell>
                                                        <TableCell onClick={handleEdit(data)} sx={classes.cellLong}>
                                                            {data.is_active ? (
                                                                <Chip label='Enabled' variant='outlined' color='primary' />
                                                            ) : (
                                                                <Chip
                                                                    label='Disabled'
                                                                    variant='outlined'
                                                                    style={{ color: 'red', borderColor: 'red' }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ))}
                                        </Table>
                                    </TableContainer>
                                );
                            } else {
                                return (
                                    <Box sx={classes.containerMain}>
                                        <Box mb={1}>
                                            <Typography
                                                variant='subtitle1'
                                                component='div'
                                            >{`Profile "${searchText}" in the company "${company?.name}" not found.`}</Typography>
                                        </Box>
                                        <Button
                                            disabled={checkPermission(UserPermission.USER_MANAGEMENT)}
                                            onClick={handleCreateProfile}
                                            variant='outlined'
                                            color='secondary'
                                            endIcon={<CreateIcon />}
                                        >
                                            Create Profile
                                        </Button>
                                    </Box>
                                );
                            }
                        case StatusRedux.Failed:
                            return (
                                <Box sx={classes.container}>
                                    <Error msg={profiles.error.errors[0].message} />
                                </Box>
                            );
                    }
                })()}
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component='div'
                    count={countProfiles}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={changePage}
                    onRowsPerPageChange={changeRowsPerPage}
                />
            </Paper>
        </>
    );
};

export default FlexibleSettingsProfile;
