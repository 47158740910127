import { ServicesBase } from '../ServicesBase';
import { IFetchArgs, IFetchBody } from '../../store/IFetch';
import {
    IActivateDeactivateBusinessUnit,
    IArgsBusinessUnit,
    IBusinessUnit,
    IBusinessUnits,
    ICreateBusinessUnit,
    IUpdateBusinessUnit,
} from '../../infrastructure/DTO/bussiness-unit/business-units.dto';
import { IInspectionSettings } from '../../infrastructure/DTO/threshold-settings/threshold-settings.dto';

class BusinessUnitsServices extends ServicesBase {
    static async getBusinessUnits(params: IFetchArgs<IArgsBusinessUnit>): Promise<IBusinessUnits> {
        let url: string = `/home/companies/${params.args?.company_id}/business_units/list/`;

        return await this.services(
            'POST',
            url,
            JSON.stringify({
                search_text: params.args?.search_text,
                limit: params.args?.limit,
                offset: params.args?.offset,
            }),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async createBusinessUnit(params: IFetchBody<ICreateBusinessUnit, IArgsBusinessUnit>): Promise<IBusinessUnit> {
        return await this.services('POST', `/home/companies/${params.args?.company_id}/business_units`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async updateBusinessUnit(params: IFetchBody<IUpdateBusinessUnit, IArgsBusinessUnit>): Promise<IBusinessUnit> {
        return await this.services(
            'PUT',
            `/home/companies/${params.args?.company_id}/business_units/${params.args?.business_unit_id}`,
            JSON.stringify(params.body),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async activateDeactivateBusinessUnit(params: IFetchArgs<IArgsBusinessUnit>): Promise<IActivateDeactivateBusinessUnit> {
        return await this.services(
            'POST',
            `/home/companies/${params.args?.company_id}/business_units/${params.args?.business_unit_id}/${params.args?.switch}`,
            null,
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async getBusinessUnitById(params: IFetchArgs<IArgsBusinessUnit>): Promise<IBusinessUnit> {
        return await this.services('GET', `/home/companies/${params.args?.company_id}/business_units/${params.args?.business_unit_id}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async addThresholdsToBusinessUnit(params: IFetchBody<IInspectionSettings, IArgsBusinessUnit>): Promise<IBusinessUnit> {
        return await this.services(
            'POST',
            `/home/companies/${params.args?.company_id}/business_units/${params.args?.business_unit_id}/inspection_settings`,
            JSON.stringify(params.body),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async removeThresholdsFromBusinessUnit(params: IFetchBody<IInspectionSettings, IArgsBusinessUnit>): Promise<boolean> {
        return await this.services(
            'DELETE',
            `/home/companies/${params.args?.company_id}/business_units/${params.args?.business_unit_id}/inspection_settings/damage_type_settings`,
            null,
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }
}

export default BusinessUnitsServices;
