import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFetchArgs, IFetchBody } from '../IFetch';
import { StatusRedux } from '../../enums/StatusRedux';
import InspectionFlowServices from '../../services/effective-settings/inspection-flow';
import { IArgsInspectionFlow, IInspectionFlow, IInspectionFlowSection } from '../../infrastructure/DTO/Inspection-flow/Inspection-flow.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetInspectionFlow = createAsyncThunk<IInspectionFlow, IFetchArgs<IArgsInspectionFlow>>(
    'inspection-flow/get',
    async (params: IFetchArgs<IArgsInspectionFlow>) => {
        return await InspectionFlowServices.getInspectionFlow(params);
    }
);

export const fetchUpdateInspectionFlow = createAsyncThunk<IInspectionFlow, IFetchBody<IInspectionFlow, IArgsInspectionFlow>>(
    'inspection-flow/put',
    async (params: IFetchBody<IInspectionFlow, IArgsInspectionFlow>) => {
        return await InspectionFlowServices.updateInspectionFlow(params);
    }
);

export const inspectionFlowSlice = createSlice({
    name: 'inspection-flow',
    initialState: {
        inspectionFlow: {} as IInspectionFlow,
        status: '',
        error: {} as IErrorResponse,
        statusUpdate: '',
        errorUpdate: {} as IErrorResponse,
        statusImport: '',
    },
    reducers: {
        importInspectionFlow: (state, action) => {
            state.inspectionFlow = action.payload;
            state.statusImport = StatusRedux.Succeeded;
        },
        clearStoreInspectionFlow: (state) => {
            state.inspectionFlow = {} as IInspectionFlow;
            state.status = '';
            state.error = {} as IErrorResponse;
            state.statusImport = '';
        },
        clearStoreUpdateStoreInspectionFlow: (state) => {
            state.statusUpdate = '';
            state.errorUpdate = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetInspectionFlow.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetInspectionFlow.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.inspectionFlow = action.payload;
        });
        builder.addCase(fetchGetInspectionFlow.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUpdateInspectionFlow.pending, (state) => {
            state.statusUpdate = StatusRedux.Loading;
        });
        builder.addCase(fetchUpdateInspectionFlow.fulfilled, (state, action) => {
            state.statusUpdate = StatusRedux.Succeeded;
            state.inspectionFlow = action.payload;
        });
        builder.addCase(fetchUpdateInspectionFlow.rejected, (state, action) => {
            state.statusUpdate = StatusRedux.Failed;
            state.errorUpdate = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreInspectionFlow, clearStoreUpdateStoreInspectionFlow, importInspectionFlow } = inspectionFlowSlice.actions;
export default inspectionFlowSlice;
