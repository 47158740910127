import React, { useState } from 'react';

const useCheckInputPassword = () => {
    const [inputPassword, setInputPassword] = useState({
        errorMsg: '',
        error: false,
    });
    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState({
        errorMsg: '',
        error: false,
    });

    const checkValuePassword = (event: any) => {
        const { _, value } = event.target;

        setPassword(value);

        if (value !== null && value !== undefined) {
            let passMatch = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/;

            if (!value?.match(passMatch)) {
                validatePassword(true);
            } else {
                validatePassword(false);
            }
        } else {
            validatePassword(false);
        }
    };

    const checkValuePasswordConfirm = (event: any) => {
        const { _, value } = event.target;

        if (value === password) {
            validatePasswordConfirm(false);
        } else {
            validatePasswordConfirm(true);
        }
    };

    const validatePassword = (check: boolean) => {
        setInputPassword({
            errorMsg: check ? 'Password Not Valid, it must be 8 letters, have a number, character and a symbol!' : '',
            error: check,
        });
    };

    const validatePasswordConfirm = (check: boolean) => {
        setPasswordConfirm({
            errorMsg: check ? 'Passwords do not match!' : '',
            error: check,
        });
    };

    return { inputPassword, passwordConfirm, checkValuePassword, checkValuePasswordConfirm };
};

export default useCheckInputPassword;
