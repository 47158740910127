import React from 'react';
import { Chip, Fab } from '@mui/material';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { classes } from '../style';
import CollectionsIcon from '@mui/icons-material/Collections';
import { MenuEnum } from '../damagelab-menu/menu';
import DamageLabLayoutContext, { IDamageLabLayoutContext } from '../../../../../../context/DamageLabLayoutContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IImageOverView } from '../../../../../../infrastructure/DTO/multilayered/multilayered.dto';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import Tooltip from '@mui/material/Tooltip';

interface IButtonPanelComponent {
    fullscreenActive: boolean;
    exitFullscreen(): void;
    enterFullscreen(): void;
    panelHandleRight(event: React.KeyboardEvent | React.MouseEvent): void;
    panelHandleLeft(event: React.KeyboardEvent | React.MouseEvent): void;
    panelHandleBottom(event: React.KeyboardEvent | React.MouseEvent): void;
    downloadPrintScreen(): any;
    downloadOriginalImage(): any;
    isDisabled(value: MenuEnum): boolean;
}

type InfoType = {
    classification: string | null;
    quality: string | null;
    car_coverage: number | null;
    camera_view: string | null;
};

const ButtonPanel: React.FC<IButtonPanelComponent> = (props: IButtonPanelComponent) => {
    const navigate = useNavigate();
    const { imageInfo } = React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);
    const { imagesOverView } = useSelector((state: AppState) => state.imagesOverView);
    const [params] = useSearchParams();
    const image_id: string = params.has('image_id') ? (params.get('image_id') as string) : '';
    const inspection_id: string = params.has('inspection_id') ? (params.get('inspection_id') as string) : '';
    const [info, setInfo] = React.useState<InfoType>({
        classification: null,
        quality: null,
        car_coverage: null,
        camera_view: null,
    });

    React.useEffect(() => {
        if (image_id.length > 0 && Object.keys(imagesOverView).length) {
            let data = imagesOverView.images.find((i) => i.image_id === image_id) as IImageOverView;

            setInfo({
                classification: data.image_classification ? data.image_classification.replace('_', ' ') : null,
                quality: data.image_quality_result?.status ? data.image_quality_result?.status.replace('_', ' ') : null,
                car_coverage: data.car_coverage ? data.car_coverage : null,
                camera_view: data.camera_view ? data.camera_view.replace('_', ' ') : null,
            });
        } else {
            navigate(`/home/inspections?is_open=${true}&inspection_id=${inspection_id}`);
        }
    }, [image_id, imagesOverView]);

    return (
        <Box sx={classes.panel}>
            <Box sx={classes.top}>
                <Box sx={classes.panelRight}>
                    <Tooltip title='Download Original Image' placement='bottom'>
                        <Fab
                            onClick={props.downloadOriginalImage}
                            variant='circular'
                            size='small'
                            color='secondary'
                            aria-label='download original image'
                        >
                            <SaveAltIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title='Fullscreen' placement='bottom'>
                        <Fab variant='circular' size='small' color='secondary' aria-label='add'>
                            {props.fullscreenActive ? (
                                <FullscreenExitIcon onClick={props.exitFullscreen} />
                            ) : (
                                <FullscreenIcon onClick={props.enterFullscreen} />
                            )}
                        </Fab>
                    </Tooltip>
                    <Tooltip title='Menu' placement='bottom'>
                        <Fab onClick={props.panelHandleRight} variant='circular' size='small' color='secondary' aria-label='show menu'>
                            <MenuIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title='Download Print Screen' placement='bottom'>
                        <Fab onClick={props.downloadPrintScreen} variant='circular' size='small' color='secondary' aria-label='download print screen'>
                            <ScreenshotMonitorIcon />
                        </Fab>
                    </Tooltip>
                </Box>
            </Box>
            <Box sx={classes.bottom}>
                <Box sx={classes.panelInfo}>
                    {info?.classification && (
                        <Box>
                            <Chip variant='filled' color='primary' size={'small'} label={`Classification: ${info.classification}`} />
                        </Box>
                    )}
                    {info?.quality && (
                        <Box>
                            <Chip variant='filled' color='primary' size={'small'} label={`Quality: ${info.quality}`} />
                        </Box>
                    )}
                    {info?.car_coverage && (
                        <Box>
                            <Chip variant='filled' color='primary' size={'small'} label={`Car Coverage: ${info.car_coverage}`} />
                        </Box>
                    )}
                    {info?.camera_view && (
                        <Box>
                            <Chip variant='filled' color='primary' size={'small'} label={`Camera View: ${info.camera_view}`} />
                        </Box>
                    )}
                    {imageInfo.weight && (
                        <Box>
                            <Chip variant='filled' color='primary' size={'small'} label={`Size: ${imageInfo.weight}`} />
                        </Box>
                    )}
                    {imageInfo.width > 1 && imageInfo.height > 1 && (
                        <Box>
                            <Chip variant='filled' color='primary' size={'small'} label={`Dimensions: ${imageInfo.width}x${imageInfo.height}`} />
                        </Box>
                    )}
                </Box>
                <Box sx={classes.panelBottom}>
                    <Tooltip title='Images' placement='top'>
                        <Fab onClick={props.panelHandleBottom} variant='circular' size='small' color='secondary' aria-label='show all images'>
                            <CollectionsIcon />
                        </Fab>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
};
export default ButtonPanel;
