import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import { Rect } from 'react-konva';
import { IDamageLabLayout } from '../IDamageLabLayout';
import DamageLabLayoutContext, { IDamageLabLayoutContext } from '../../../../../../context/DamageLabLayoutContext';
import { IBoundingBox } from '../../../../../../infrastructure/DTO/damage-lab/damage-lab.dto';

interface IPrincipalCarLayoutComponent extends IDamageLabLayout {}
const PrincipalCarLayout: React.FC<PropsWithChildren<IPrincipalCarLayoutComponent>> = (props: IPrincipalCarLayoutComponent) => {
    const { scale } = React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);
    const { imageOverView, status, error } = useSelector((state: AppState) => state.imageOverView);
    const [boundingBox, setBoundingBox] = React.useState<IBoundingBox | null>(null);

    React.useEffect(() => {
        if (props.view) {
            if (imageOverView.vehicle_segmentation !== null) {
                setBoundingBox({
                    x: imageOverView.vehicle_segmentation.bounding_box.x * scale,
                    y: imageOverView.vehicle_segmentation.bounding_box.y * scale,
                    width: imageOverView.vehicle_segmentation.bounding_box.width * scale,
                    height: imageOverView.vehicle_segmentation.bounding_box.height * scale,
                });
            }
        } else {
            setBoundingBox(null);
        }
    }, [scale, props.view]);

    return (
        <>
            {boundingBox && (
                <Rect x={boundingBox.x} y={boundingBox.y} width={boundingBox.width} height={boundingBox.height} stroke='red' strokeWidth={2} />
            )}
        </>
    );
};

export default PrincipalCarLayout;
