import { IFetchArgs, IFetchBody } from '../../store/IFetch';
import { ServicesBase } from '../ServicesBase';
import { IArgsCompany, ICheckCompanyResponse, ICompanies, ICompany, ICompanyDamagesExport } from '../../infrastructure/DTO/company/company.dto';
import { IArgsCompanyReport, ICompanyReport } from '../../infrastructure/DTO/company/company-report.dto';

class CompanyServices extends ServicesBase {
    static async create(params: IFetchBody<ICompany>): Promise<ICompany> {
        return await this.services('POST', `/api/companies`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async update(params: IFetchBody<ICompany, IArgsCompany>): Promise<ICompany> {
        return await this.services('PUT', `/api/companies/${params.args?.company_id}`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async checkName(params: IFetchBody<ICompany>): Promise<ICheckCompanyResponse> {
        return await this.services('POST', `/api/companies/check/name/${params.body.name}`, JSON.stringify(params.body.name), {
            'Content-Type': 'application/json',
        });
    }

    static async getCompanyById(params: IFetchArgs<IArgsCompany>): Promise<ICompany> {
        return await this.services('GET', `/api/companies/${params.args.company_id}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async getAllCompanies(params: IFetchArgs<IArgsCompany>): Promise<ICompanies> {
        let url: string = `/api/companies/list/`;

        return await this.services(
            'POST',
            url,
            JSON.stringify({
                company_id: params.args?.company_id,
                user_id: params.args?.user_id,
                search_text: params.args?.search_text,
                limit: params.args?.limit,
                offset: params.args?.offset,
            }),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async exportDamages(params: IFetchArgs<ICompanyDamagesExport>): Promise<string> {
        let url: string = '';

        if (params.args.business_unit_id) {
            url = `/statistics/companies/damages/export/${params.args.company_id}/${params.args.date_from}/${params.args.date_to}/${params.args.business_unit_id}`;
        } else {
            url = `/statistics/companies/damages/export/${params.args.company_id}/${params.args.date_from}/${params.args.date_to}`;
        }

        return await this.services(
            'GET',
            url,
            null,
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            },
            'TEXT'
        );
    }

    static async getReportsByCompany(params: IFetchBody<IArgsCompanyReport>): Promise<ICompanyReport> {
        return await this.services('POST', `/home/companies/usage_report`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async activateDeactivateCompany(params: IFetchArgs<IArgsCompany>): Promise<ICompany> {
        return await this.services('POST', `/home/companies/${params.args.company_id}/${params.args.switch}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }
}

export default CompanyServices;
