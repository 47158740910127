import { CSSObject } from '@mui/material';

const tableRoot = (): CSSObject => ({
    width: '100%',
    minHeight: 'calc(100vh - 190px)',
    padding: '20px',
});

const downloadBlock = (): CSSObject => ({
    textAlign: 'center',
    height: 'calc(100vh - 285px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const classes = {
    tableRoot,
    downloadBlock,
};
