import * as React from 'react';
import { useSnackbar } from 'notistack';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { DialogContent, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { classes } from '../../style';
import { clearStoreCreatePermissions, fetchCreatePermissions } from '../../../../../store/permission/permission';
import Box from '@mui/material/Box';
import { BasicDialog } from '../../../../../common/ui-components/Dialog';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { IPermission } from '../../../../../infrastructure/DTO/permission/permission.dto';
import errorParseMessage from "../../../../../config/error-parse";

interface IAddPermissionComponent {
    open: boolean;
    close: Function;
}

const AddPermission: React.FC<IAddPermissionComponent> = (props: IAddPermissionComponent) => {
    const { token } = useContext(CustomAuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const { statusCreate, errorCreate } = useSelector((state: AppState) => state.getPermissions);

    const [permissionData, setPermissionData] = React.useState({
        name: '',
        description: '',
        is_active: true,
    });

    const handleClose = () => {
        setPermissionData({
            ...permissionData,
            name: '',
            description: '',
        });
        props.close();
        dispatch(clearStoreCreatePermissions());
    };

    const handleSubmitAddPermission = (event: any) => {
        event.preventDefault();

        const body: IPermission = {
            name: permissionData.name,
            description: permissionData.description,
            is_active: permissionData.is_active,
        };

        dispatch(fetchCreatePermissions({ token, body }));
    };

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;
        setPermissionData({
            ...permissionData,
            [name]: value,
        });
    };

    React.useEffect(() => {
        if (statusCreate === StatusRedux.Succeeded) {
            enqueueSnackbar('Permission Updated Successfully', {
                variant: 'success',
            });
            setPermissionData({
                ...permissionData,
                name: '',
                description: '',
            });
            props.close();
            dispatch(clearStoreCreatePermissions());
        }
        if (statusCreate === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorCreate), {
                variant: 'error',
            });
        }
    }, [statusCreate]);

    return (
        <BasicDialog handlePopup={handleClose} open={props.open} title='Create Permission' width='sm'>
            <DialogContent>
                <Box sx={classes.bodyPopup}>
                    <form autoComplete='off' onSubmit={handleSubmitAddPermission}>
                        <Grid container spacing={3} justifyContent='flex-end'>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name='name'
                                    required={true}
                                    id='name'
                                    type='text'
                                    label='Permission Name'
                                    variant='outlined'
                                    value={permissionData.name}
                                    onChange={handleChangeInput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    name='description'
                                    id='desc'
                                    type='text'
                                    label='Permission Description'
                                    variant='outlined'
                                    value={permissionData.description}
                                    onChange={handleChangeInput}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button fullWidth type='submit' variant='contained' color='secondary'>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </DialogContent>
        </BasicDialog>
    );
};

export default AddPermission;
