import React, { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import SearchInput from '../../../../common/ui-components/SearchInput';
import { UserPermission } from '../../../../config';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import CompaniesSelect from '../../../../common/ui-components/CompaniesSelect';
import { IUserManagementProvider, UserManagementContext } from '../../../../context/UserManagementProvider';

const FlexibleSettingsPanel: React.FC = () => {
    const { checkPermission } = useContext(CustomAuthContext);
    const { valueTab, searchText, company, setCompany, handleChangeSearchText, handleCreateProfile, handleCreateBusinessUnits, handleSearch } =
        useContext<IUserManagementProvider>(UserManagementContext);

    return (
        <Grid container spacing={2} direction='row' justifyContent='end' alignItems='center'>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Box sx={{ width: '100%', minWidth: '150px', maxWidth: '300px' }}>
                    <SearchInput name='Search' searchText={searchText} handleSearch={handleSearch} handleChangeSearchText={handleChangeSearchText} />
                </Box>
            </Grid>
            {!checkPermission(UserPermission.ADMIN) && (
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <CompaniesSelect data={company} setData={setCompany} />
                </Grid>
            )}
            {!checkPermission(UserPermission.USER_MANAGEMENT) && (
                <Grid item>
                    {valueTab === 1 && (
                        <Button onClick={handleCreateProfile} variant='contained' color='secondary' size='small' endIcon={<CreateIcon />}>
                            Create Profile
                        </Button>
                    )}
                    {valueTab === 2 && (
                        <Button onClick={handleCreateBusinessUnits} variant='contained' color='secondary' size='small' endIcon={<CreateIcon />}>
                            Create Business Unit
                        </Button>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default FlexibleSettingsPanel;
