import * as React from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { clearStatusInspections } from '../../../store/inspection/inspection';
import { classes } from './style';
import { useContext } from 'react';
import Box from '@mui/material/Box';
import { CustomAuthContext } from '../../../context/AuthProvider';
import { showPopup } from '../../../store/events/popup';
import { backButtonDisabled, onbeforeunload } from '../../../utils/onbeforeunload';
import { Outline } from 'react-pdf';

const Inspection: React.FC = () => {
    const navigate = useNavigate();
    const { parseToken } = useContext(CustomAuthContext);
    const dispatch: AppDispatch = useDispatch();

    const handleGoToCreateInspection = () => {
        navigate('/home/inspection/create/1');
    };

    React.useEffect(() => {
        dispatch(clearStatusInspections());
        dispatch(showPopup(false));
        onbeforeunload(undefined);
        backButtonDisabled(false);
    }, []);

    return (
        <>
            <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={classes.titleTop}>
                        <Typography variant='caption' display='block' gutterBottom>
                            Hello, {parseToken().Name}
                        </Typography>
                        <Typography variant='h5' noWrap sx={classes.titlePage}>
                            Select inspection type
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={0} direction='row' justifyContent='center' alignItems='baseline'>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Paper square elevation={5}>
                        <Grid container spacing={0} direction='row' justifyContent='center' alignItems='baseline'>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={classes.blockMain}>
                                <Box sx={classes.block}>
                                    <Box sx={classes.blockTitleMain}>Live Inspection</Box>
                                    <Box sx={classes.blockDescription}>
                                        <p>Take photos of the damaged car, upload from your mobile device and receive a report immediately.</p>
                                    </Box>
                                    <Box sx={classes.blockButton}>
                                        <Button disabled={true} variant='contained' size='large' color='primary'>
                                            begin inspection <CheckIcon sx={classes.icon} />
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box sx={classes.block}>
                                    <Box sx={classes.blockTitleMain}>Offline Inspection</Box>
                                    <Box sx={classes.blockDescription}>Upload pre-taken car photos and receive a damage report.</Box>
                                    <Box sx={classes.blockButton}>
                                        <Button onClick={handleGoToCreateInspection} variant='contained' size='large' color='primary'>
                                            begin inspection <CheckIcon sx={classes.icon} />
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default Inspection;
