import { CSSObject, keyframes, Theme } from '@mui/material';

const fadeIn = keyframes`
    "0%": {
      opacity: 0
    },
    "100%": {
        opacity: 1
    }
`;

const root = (theme: Theme): CSSObject => ({
    flexGrow: 1,
    display: 'flex',
    height: 'calc(100vh - 175px)',
});

const mainHeaderPage = (theme: Theme): CSSObject => ({
    marginBottom: '20px',
    animation: `${fadeIn} 500ms ${theme.transitions.easing.easeInOut}`,
});

const tab = (): CSSObject => ({
    width: 'calc(100% - 40px)',
    height: 'calc(100vh - 165px)',
    overflowY: 'auto',
    padding: '20px',
});

const tabBody = (): CSSObject => ({
    width: '100%',
    textAlign: 'center',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const imgMain = (): CSSObject => ({
    '& > img': {
        maxHeight: '550px',
        width: '100%',
        margin: '0 auto',
        objectFit: 'contain',
    },
});

const titlePageBlock = (): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
});

const titlePage = (): CSSObject => ({
    textTransform: 'uppercase',
    marginRight: '25px!important',
});

const iconBtn = (): CSSObject => ({
    marginLeft: '10px',
});

const btnPage = (): CSSObject => ({
    marginBottom: '10px',
    textAlign: 'right',
    '& > button': {
        marginLeft: '25px',
    },
});

const tabs = (theme: Theme): CSSObject => ({
    borderRight: `1px solid ${theme.palette.divider}!important`,
    width: '220px',
});

const tabButton = (theme: Theme): CSSObject => ({
    borderBottom: `1px solid ${theme.palette.divider}!important`,
});

const topBlock = (): CSSObject => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30px',
});

const title = (): CSSObject => ({
    fontSize: '1.7em',
    fontWeight: '500px',
});

const headerPopupMain = (theme: Theme): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
});

const iconButton = (theme: Theme): CSSObject => ({
    padding: '0 20px',
    color: theme.palette.primary.contrastText,
});

const paper = (): CSSObject => ({
    padding: '20px',
});

const btnGroup = (): CSSObject => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
});

export const classes = {
    btnGroup,
    paper,
    iconButton,
    headerPopupMain,
    titlePageBlock,
    imgMain,
    tabBody,
    tab,
    root,
    mainHeaderPage,
    title,
    topBlock,
    tabButton,
    tabs,
    iconBtn,
    btnPage,
    titlePage,
};
