import { CSSObject, Theme } from '@mui/material';

const root = (theme: Theme): CSSObject => ({
    '& > *': {
        margin: theme.spacing(1),
    },
});

const paper = (): CSSObject => ({
    minHeight: 600,
    width: '100%',
});

const headerTitle = (): CSSObject => ({
    fontSize: '1.3em',
    fontWeight: 500,
    textTransform: 'uppercase',
    margin: '20px auto 25px',
    textAlign: 'center',
});

const input = (): CSSObject => ({
    display: 'none',
});

const inputBlock = (): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
        width: '100%',
    },
});

const headerInputs = (): CSSObject => ({
    fontSize: '1.2em',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 500,
    margin: '20px auto 35px',
});

const list = (): CSSObject => ({
    margin: '50px auto',
    '& > p:nth-of-type(2)': {
        paddingLeft: '10px',
    },
    '& > p:nth-of-type(3)': {
        paddingLeft: '10px',
    },
});

const listIcon = (): CSSObject => ({
    fontSize: '0.7em!important',
    marginRight: '5px',
});

const block = (): CSSObject => ({
    padding: '70px',
    minHeight: '600px',
    '&:first-of-type': {
        borderRight: '1px solid #ccc',
    },
});

const formInput = (): CSSObject => ({
    marginBottom: '25px',
});

const odometerBlock = (): CSSObject => ({
    borderLeft: '1px solid #CCC',
    borderBottom: '1px solid #CCC',
    width: '100px!important',
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
});

const odometerImg = (): CSSObject => ({
    width: '80px',
    position: 'absolute',
    '& > img': {
        width: '100%',
    },
});

const odometerBtn = (): CSSObject => ({
    position: 'absolute',
    top: '-20px!important',
    right: '-20px!important',
});

const main = (): CSSObject => ({
    position: 'relative',
});

const formCheckbox = (): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
});

const headerCheckbox = (): CSSObject => ({
    marginTop: '10px',
    marginBottom: '5px',
});

const inspectionIdForm = (): CSSObject => ({
    marginBottom: '30px',
});

const inspectionIdFormHeader = (): CSSObject => ({
    marginBottom: '5px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9em',
});

const loaderBlock = (): CSSObject => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '550px',
});

const headerErrorPopup = (): CSSObject => ({
    background: '#ba000d',
    color: '#fff',
});

const checkOthersMain = (): CSSObject => ({
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '35px',
    padding: '5px',
});

const checkOthersMainSetting = (): CSSObject => ({
    margin: '10px auto',
    borderBottom: '1px solid #ccc',
    padding: '5px',
});

const checkOthers = (): CSSObject => ({
    width: '49%',
    '& > span:nth-of-type(1)': {
        padding: '5px',
    },
    '& > span:nth-of-type(2)': {
        fontSize: '0.9em',
    },
});

const popupText = (): CSSObject => ({
    textAlign: 'center',
    margin: '15px auto',
});

const headerPopup = (): CSSObject => ({
    fontSize: '1.3em',
    textAlign: 'center',
});

const btnsPopup = (): CSSObject => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
});

const contentPopupError = (): CSSObject => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#EEEEEE',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5em',
    textTransform: 'uppercase',
    color: 'red',
    fontWeight: 700,
    margin: 'auto',
    top: 0,
    bottom: 0,
});

const contentPopupSuccess = (): CSSObject => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#EEEEEE',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textTransform: 'uppercase',
    justifyContent: 'center',
    fontSize: '1.5em',
    color: '#388e3c',
    fontWeight: 700,
    margin: 'auto',
    top: 0,
    bottom: 0,
});

const iconStatusSuccess = (): CSSObject => ({
    fontSize: '2em',
    color: '#388e3c',
    '& svg': {
        fontSize: '5rem',
        marginTop: '20px',
    },
});

const iconStatusError = (): CSSObject => ({
    fontSize: '2em',
    color: 'red',
    '& svg': {
        fontSize: '5rem',
        marginTop: '20px',
    },
});

const headerPopupMain = (theme: Theme): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
});

const iconButton = (theme: Theme): CSSObject => ({
    padding: '0 20px',
    color: theme.palette.primary.contrastText,
});

const headerSubTitle = (): CSSObject => ({
    fontSize: '1em',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: '25px',
});

const iconImg = (): CSSObject => ({
    zIndex: 10,
    color: '#2a3eb1',
});

const msgError = (): CSSObject => ({
    background: 'rgb(255 0 0 / 38%)',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '120px',
    textTransform: 'uppercase',
});

const msgWarning = (): CSSObject => ({
    background: 'rgb(255 247 11 / 70%)',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '120px',
    textTransform: 'uppercase',
});

const textError = (): CSSObject => ({
    color: '#fff',
    padding: '10px',
    width: '100%',
    fontSize: '1.3vh',
    textAlign: 'center',
    textShadow: '1px 1px 0px #000000',
});

const msgOK = (): CSSObject => ({
    background: 'rgb(56 142 60 / 45%)',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '120px',
    textTransform: 'uppercase',
});

const textOK = (): CSSObject => ({
    color: '#fff',
    padding: '10px',
    width: '100%',
    textAlign: 'center',
    textShadow: '1px 1px 0px #000000',
});

const fabProgress = (): CSSObject => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    left: 0,
    zIndex: 1,
});

const img = (): CSSObject => ({
    width: '100%',
    height: '120px',
});

const alertBlock = (): CSSObject => ({
    position: 'absolute',
    top: '80px',
    right: '35px',
});

const alertBtnsBlock = (): CSSObject => ({
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const alertBtnsBlockRight = (): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    '& > button': {
        marginRight: '10px',
    },
});

export const classes = {
    iconStatusError,
    img,
    textError,
    msgError,
    msgWarning,
    msgOK,
    textOK,
    fabProgress,
    alertBtnsBlockRight,
    alertBlock,
    alertBtnsBlock,
    headerPopupMain,
    iconButton,
    headerSubTitle,
    iconImg,
    iconStatusSuccess,
    block,
    listIcon,
    list,
    headerInputs,
    contentPopupSuccess,
    inputBlock,
    input,
    headerTitle,
    paper,
    root,
    main,
    odometerBtn,
    odometerImg,
    odometerBlock,
    formInput,
    contentPopupError,
    btnsPopup,
    headerPopup,
    popupText,
    checkOthers,
    checkOthersMainSetting,
    checkOthersMain,
    headerErrorPopup,
    loaderBlock,
    inspectionIdFormHeader,
    inspectionIdForm,
    headerCheckbox,
    formCheckbox,
};
