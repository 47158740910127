import * as React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import {
    clearStoreAddPermissionToRole,
    clearStoreIsActive,
    clearStoreRemove,
    clearStoreUpdate,
    fetchAddPermissionToRole,
    fetchRemovePermissionFromRole,
    fetchRoleActive,
    fetchRoleDeactivate,
    fetchUpdateRole,
} from '../../../../../store/role/role';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from '../../style';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { clearStorePermissions, fetchAllPermissions } from '../../../../../store/permission/permission';
import Box from '@mui/material/Box';
import { BasicDialog } from '../../../../../common/ui-components/Dialog';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { IPermission } from '../../../../../infrastructure/DTO/permission/permission.dto';
import { IAddPermissionToRole, IArgsRole, IRemovePermissionToRole, IRole } from '../../../../../infrastructure/DTO/role/role.dto';
import errorParseMessage from "../../../../../config/error-parse";

interface IEditRoleComponent {
    open: boolean;
    role: IRole;
    close: Function;
}

const EditRole: React.FC<IEditRoleComponent> = (props: IEditRoleComponent) => {
    const { token } = useContext(CustomAuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const {
        statusUpdate,
        errorUpdate,
        statusAddPermission,
        errorAddPermission,
        statusIsActive,
        errorIsActive,
        errorRemovePermission,
        statusRemovePermission,
    } = useSelector((state: AppState) => state.getRoles);
    const { permissions, statusPermissions } = useSelector((state: AppState) => state.getPermissions);
    const [openRolePermissionList, setRolePermissionDialog] = React.useState<boolean>(false);

    const [roleData, setRoleData] = React.useState<IRole>({
        role_id: props.role.role_id,
        name: props.role.name,
        permissions: props.role.permissions,
        is_active: props.role.is_active,
    });

    const [rolePermission, setRolePermission] = React.useState<Array<IPermission>>([]);

    const handleClose = () => {
        dispatch(clearStoreUpdate());
        setRoleData({
            role_id: props.role.role_id,
            name: props.role.name,
            permissions: props.role.permissions,
            is_active: props.role.is_active,
        });

        setRolePermission([]);
        props.close();
    };

    const handleSubmitEditRole = (event: any) => {
        event.preventDefault();

        const args: IArgsRole = {
            role_id: roleData.role_id,
        };

        const body: Partial<IRole> = {
            name: roleData.name,
        };

        dispatch(
            fetchUpdateRole({
                token,
                args,
                body,
            })
        );
    };

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;
        setRoleData({
            ...roleData,
            [name]: value,
        });
    };

    const handleToggleRole = () => {
        const args: IArgsRole = {
            role_id: roleData.role_id,
        };

        if (!roleData.is_active) {
            dispatch(
                fetchRoleActive({
                    token,
                    args,
                })
            );
        } else {
            dispatch(
                fetchRoleDeactivate({
                    token,
                    args,
                })
            );
        }

        setRoleData({
            ...roleData,
            is_active: !roleData.is_active,
        });
    };

    React.useEffect(() => {
        if (statusIsActive === StatusRedux.Succeeded) {
            let msg = !roleData.is_active ? 'Role Deactivated' : 'Role Activated';

            enqueueSnackbar(msg, {
                variant: 'success',
            });

            props.close();
            dispatch(clearStoreIsActive());
        }
        if (statusIsActive === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorIsActive), {
                variant: 'error',
            });

            dispatch(clearStoreIsActive());
        }
    }, [statusIsActive]);

    React.useEffect(() => {
        if (statusUpdate === StatusRedux.Succeeded) {
            enqueueSnackbar('Role Updated Successfully', {
                variant: 'success',
            });

            dispatch(clearStoreUpdate());
            props.close();
        }
        if (statusUpdate === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorUpdate), {
                variant: 'error',
            });

            dispatch(clearStoreUpdate());
        }
    }, [statusUpdate]);

    React.useEffect(() => {
        dispatch(
            fetchAllPermissions({
                token: token,
                args: null,
            })
        );
    }, [openRolePermissionList]);

    React.useEffect(() => {
        if (statusPermissions === StatusRedux.Succeeded) {
            let permissionsArr: IPermission[] = [...permissions.permissions];

            props.role.permissions.forEach((i) => {
                if (permissionsArr.length !== 0) {
                    let index = permissionsArr.findIndex((j) => j.name === i.name);
                    permissionsArr.splice(index, 1);
                }
            });

            setRolePermission(permissionsArr.filter((i) => i.is_active));
        }
    }, [statusPermissions]);

    const handleAddPermission = () => {
        setRolePermissionDialog(!openRolePermissionList);
    };

    React.useEffect(() => {
        if (statusAddPermission === StatusRedux.Succeeded) {
            setRolePermissionDialog(false);
            dispatch(clearStoreAddPermissionToRole());
        }
        if (statusAddPermission === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorAddPermission), {
                variant: 'error',
            });
            dispatch(clearStoreAddPermissionToRole());
        }
    }, [statusAddPermission]);

    const handleListItemClick = (id: string | undefined) => () => {
        let permissionObj = permissions.permissions.filter((p: IPermission) => p.permission_id === id);

        const args: IAddPermissionToRole = {
            role_id: props.role.role_id,
            permission_id: id as string,
            permission: permissionObj[0],
        };

        setRoleData({
            ...roleData,
            permissions: [...roleData.permissions, permissionObj[0]],
        });

        setRolePermissionDialog(!openRolePermissionList);
        dispatch(fetchAddPermissionToRole({ token, args }));
        dispatch(clearStorePermissions());
    };

    const handleRemovePermission = (permission_id: string) => () => {
        const args: IRemovePermissionToRole = {
            role_id: props.role.role_id,
            permission_id: permission_id,
        };
        dispatch(fetchRemovePermissionFromRole({ token, args }));
    };

    React.useEffect(() => {
        if (statusRemovePermission === StatusRedux.Succeeded) {
            setRoleData(props.role);
            dispatch(clearStoreRemove());
        }

        if (statusRemovePermission === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorRemovePermission), {
                variant: 'error',
            });
            dispatch(clearStoreRemove());
        }
    }, [statusRemovePermission]);

    return (
        <>
            <BasicDialog handlePopup={handleClose} open={props.open} title={roleData.name} width='sm'>
                <DialogContent>
                    <Box sx={classes.bodyPopup}>
                        <form autoComplete='off' onSubmit={handleSubmitEditRole}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box sx={classes.inputBtn}>
                                        <TextField
                                            fullWidth
                                            name='name'
                                            required={true}
                                            id='outlined-basic'
                                            type='text'
                                            label='Role Name'
                                            variant='outlined'
                                            value={roleData.name}
                                            onChange={handleChangeInput}
                                        />
                                        <Button type='submit' variant='contained' color='primary'>
                                            Save
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <List>
                                        <ListItem>
                                            <ListItemText
                                                id='switch-list-label-wifi'
                                                primary={
                                                    roleData.is_active ? (
                                                        <Box component='span' sx={classes.iconEdit}>
                                                            Enable
                                                        </Box>
                                                    ) : (
                                                        <Box component='span' sx={classes.iconDelete}>
                                                            Disable
                                                        </Box>
                                                    )
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <Switch
                                                    edge='end'
                                                    value={roleData.is_active}
                                                    onChange={handleToggleRole}
                                                    checked={roleData.is_active}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='h6' gutterBottom>
                                        Permissions
                                    </Typography>
                                    <List>
                                        {roleData.permissions.length !== 0 &&
                                            roleData.permissions
                                                .filter((i) => i.is_active)
                                                .map((permission, index) => (
                                                    <div key={index}>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={permission.name}
                                                                secondary={
                                                                    <Typography variant='caption' display='block'>
                                                                        {permission.description}
                                                                    </Typography>
                                                                }
                                                            />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    onClick={handleRemovePermission(permission.permission_id as string)}
                                                                    edge='end'
                                                                    aria-label='delete'
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <Divider variant='fullWidth' component='li' />
                                                    </div>
                                                ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </DialogContent>
                <DialogActions sx={classes.headerPopup}>
                    <Fab
                        disabled={!roleData.is_active}
                        onClick={handleAddPermission}
                        sx={classes.iconAdd}
                        size='small'
                        color='secondary'
                        aria-label='add'
                    >
                        {openRolePermissionList ? <CloseIcon /> : <AddIcon />}
                    </Fab>
                </DialogActions>
            </BasicDialog>

            <Dialog onClose={handleAddPermission} aria-labelledby='simple-dialog-title' open={openRolePermissionList}>
                <DialogTitle id='simple-dialog-title'>Set Permission</DialogTitle>
                {rolePermission.length === 0 ? <Box sx={classes.textWarn}>No Permissions</Box> : null}
                <List>
                    {rolePermission.map((p: IPermission, index) => (
                        <div key={index}>
                            <ListItem button onClick={handleListItemClick(p.permission_id)} key={p.permission_id}>
                                <ListItemIcon>
                                    <AddCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={p.name} />
                            </ListItem>
                            <Divider variant='inset' component='li' />
                        </div>
                    ))}
                </List>
            </Dialog>
        </>
    );
};

export default EditRole;
