import * as React from 'react';
import './spinner.css';
import CircularProgress from '@mui/material/CircularProgress';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';

interface ISpinner {
    style?: SxProps<Theme>;
}

const Spinner: React.FC<ISpinner> = (props: ISpinner) => {
    return (
        <Box sx={props.style} className='loader-container'>
            <CircularProgress color='primary' />
        </Box>
    );
};

export default Spinner;
