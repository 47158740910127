import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../../store';
import { clearStoreAddRoleToUser, fetchAddRoleByUserId, fetchGetRoleByUserId, fetchRemoveRoleFromUser } from '../../../../../store/role/user-role';
import { List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Switch } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { fetchAllRoles } from '../../../../../store/role/role';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { Spinner } from '../../../../spinner';
import { Error } from '../../../../error';
import Divider from '@mui/material/Divider';
import { classes } from './style';
import { useSnackbar } from 'notistack';
import { UserPermission } from '../../../../../config';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { IUser } from '../../../../../infrastructure/DTO/users/user.dto';
import { IArgsRole, IRole } from '../../../../../infrastructure/DTO/role/role.dto';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import errorParseMessage from "../../../../../config/error-parse";

interface IRoleUserComponent {
    user: IUser;
}

const RoleUser: React.FC<IRoleUserComponent> = (props: IRoleUserComponent) => {
    const { checkPermission, token } = useContext(CustomAuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const [checked, setChecked] = React.useState<Array<string>>([]);
    const { roles, statusRole, errorRole } = useSelector((state: AppState) => state.getRoles);
    const { rolesUser, statusRoleUser } = useSelector((state: AppState) => state.userRole);
    const { statusRoleAddToUser, errorRoleAddToUser } = useSelector((state: AppState) => state.userRole);
    const dispatch: AppDispatch = useDispatch();

    const handleChangeRole = (value: IRole) => () => {
        const currentIndex = checked.indexOf(value.role_id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value.role_id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);

        const args: IArgsRole = {
            company_id: props.user?.company?.company_id as string,
            user_id: props.user?.user_id as string,
            role_id: value.role_id,
        };

        if (currentIndex === -1) {
            dispatch(fetchAddRoleByUserId({ token, args }));
        } else {
            dispatch(fetchRemoveRoleFromUser({ token, args }));
        }
    };

    React.useEffect(() => {
        const args: IArgsRole = {
            company_id: props.user?.company?.company_id as string,
            user_id: props.user?.user_id as string,
        };

        dispatch(fetchGetRoleByUserId({ token, args }));
        dispatch(fetchAllRoles({ token: token, args: null }));
    }, []);

    React.useEffect(() => {
        if (statusRoleUser == StatusRedux.Succeeded) {
            let userRole: Array<string> = [];

            rolesUser.roles.forEach((role) => {
                userRole.push(role.role_id);
            });

            setChecked(userRole);
        }
    }, [statusRoleUser]);

    React.useEffect(() => {
        if (statusRoleAddToUser === StatusRedux.Succeeded) {
            enqueueSnackbar('User updated successfully', {
                variant: 'success',
            });

            dispatch(clearStoreAddRoleToUser());
        }

        if (statusRoleAddToUser === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorRoleAddToUser), {
                variant: 'error',
            });

            dispatch(clearStoreAddRoleToUser());
        }
    }, [statusRoleAddToUser]);

    return (
        <Paper sx={classes.paper} elevation={2}>
            <Grid item xs={12}>
                <Box sx={{ fontWeight: 600, textTransform: 'uppercase', marginBottom: '20px' }}>Role</Box>
            </Grid>
            <List sx={classes.listMain}>
                {(() => {
                    switch (statusRole) {
                        case StatusRedux.Loading:
                            return <Spinner style={{ height: 'calc(100vh - 400px)' }} />;
                        case StatusRedux.Succeeded:
                            return (
                                roles !== undefined &&
                                roles.roles
                                    .filter((r) =>
                                        !checkPermission(UserPermission.ADMIN) ? r.is_active : r.name !== UserPermission.ADMIN && r.is_active
                                    )
                                    .map((role, index) => {
                                        return (
                                            <div key={role.role_id}>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <VerifiedUserIcon
                                                            style={
                                                                checked.indexOf(role.role_id) !== -1
                                                                    ? { color: '#388e3c' }
                                                                    : { color: 'rgb(0 0 0 / 54%)' }
                                                            }
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={role.name}
                                                        secondary={role.permissions
                                                            .filter((i) => i.is_active)
                                                            .map((p, index) => (
                                                                <Typography key={index + 1} variant='caption' display='block'>
                                                                    {p.name}
                                                                </Typography>
                                                            ))}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Switch
                                                            disabled={checkPermission(UserPermission.USER_MANAGEMENT)}
                                                            edge='end'
                                                            onChange={handleChangeRole(role)}
                                                            checked={checked.indexOf(role.role_id) !== -1}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider key={index} style={{ display: index + 1 === roles.roles.length ? 'none' : 'block' }} />
                                            </div>
                                        );
                                    })
                            );
                        case StatusRedux.Failed:
                            return <Error msg={errorParseMessage(errorRole)} />;
                    }
                })()}
            </List>
        </Paper>
    );
};

export default RoleUser;
