import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFetchArgs } from '../IFetch';
import BusinessUnitsServices from '../../services/business-units/business-units';
import { StatusRedux } from '../../enums/StatusRedux';
import { IArgsBusinessUnit, IBusinessUnit } from '../../infrastructure/DTO/bussiness-unit/business-units.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetBusinessUnitById = createAsyncThunk<IBusinessUnit, IFetchArgs<IArgsBusinessUnit>>(
    'business-units/getById',
    async (params: IFetchArgs<IArgsBusinessUnit>) => {
        return await BusinessUnitsServices.getBusinessUnitById(params);
    }
);

export const businessUnitSlice = createSlice({
    name: 'business-units',
    initialState: {
        businessUnit: {} as IBusinessUnit,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        clearStoreStatusGetById: (state) => {
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetBusinessUnitById.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetBusinessUnitById.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.businessUnit = action.payload;
        });
        builder.addCase(fetchGetBusinessUnitById.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreStatusGetById } = businessUnitSlice.actions;
export default businessUnitSlice;
