import * as React from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/home/');
    };

    return (
        <div id='notfound'>
            <div className='notfound'>
                <div className='notfound-404'>
                    <h1>Oops!</h1>
                    <h2>404 - The Page can't be found</h2>
                </div>
                <Button size='large' variant='contained' color='primary' onClick={goBack}>
                    go to homepage
                </Button>
            </div>
        </div>
    );
};

export default NotFound;
