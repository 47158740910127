import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs } from '../IFetch';
import { IInspectionCaseId } from '../../infrastructure/DTO/inspection/inspection.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';
import ImagesOfInspection from '../../services/inspection/images';

export const fetchGetImage = createAsyncThunk<any, IFetchArgs<IInspectionCaseId>>(
    'inspection/images_layout',
    async (params: IFetchArgs<IInspectionCaseId>) => {
        return await ImagesOfInspection.getImages(params);
    }
);

export const imagesOfInspectionSlice = createSlice({
    name: 'inspection',
    initialState: {
        images: {} as any,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        clearStore: (state) => {
            state.images = {} as any;
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetImage.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetImage.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.images = action.payload;
        });
        builder.addCase(fetchGetImage.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStore } = imagesOfInspectionSlice.actions;
export default imagesOfInspectionSlice;
