import {createSlice} from '@reduxjs/toolkit';
import {PaletteMode} from "@mui/material";


export const themeSlice = createSlice({
    name: 'roles',
    initialState: {
        theme: 'light' as PaletteMode,
    },
    reducers: {
        switchTheme: (state,action) => {
            state.theme = action.payload;
        },
    },
});


export const { switchTheme } = themeSlice.actions;
export default themeSlice;