import { CSSObject, Theme } from '@mui/material';

const headerPopup = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
    height: '65px',
});

const titlePopup = (): CSSObject => ({
    fontSize: '1.4em',
    fontWeight: 500,
});

const popupMinHeight = (): CSSObject => ({
    minHeight: '600px',
});

const userTable = (theme: Theme): CSSObject => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
});

const titlePage = (): CSSObject => ({
    marginBottom: '10px',
    textTransform: 'uppercase',
});

const iconBtn = (): CSSObject => ({
    marginRight: '10px',
});

const btnPage = (): CSSObject => ({
    textAlign: 'right',
});

const tableRoot = (): CSSObject => ({
    width: '100%',
    minHeight: 'calc(100vh - 190px)',
    padding: '20px',
});

const inputLarge = (): CSSObject => ({
    width: '200px!important',
});

const inputSmall = (): CSSObject => ({
    width: '120px!important',
});

const container = (): CSSObject => ({
    maxHeight: 543,
    width: '100%',
});

const selectAllText = (): CSSObject => ({
    fontWeight: 500,
});

const datesList = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
});

const tableMain = (): CSSObject => ({
    width: '100%',
    height: 'calc(100vh - 336px)',
    overflowY: 'auto',
    marginTop: '25px',
    display: 'flex',
});

const tableBodyMain = (): CSSObject => ({
    width: 'calc(100% - 100px)',
    display: 'flex',
    flexDirection: 'row',
    float: 'right',
});

const tableFixedLeft = (): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100px',
    height: '100%',
    position: 'sticky',
    left: 0,
    zIndex: 999,
});

const tableFixedRight = (theme: Theme): CSSObject => ({
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    width: '100px',
    position: 'sticky',
    right: 0,
});

const tableFixedLeftCell = (theme: Theme): CSSObject => ({
    height: 50,
    display: 'flex',
    border: '1px solid #ccc',
    borderBottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    zIndex: 999,
    fontWeight: 500,
    position: 'sticky',
    left: 0,
    bottom: 0,
    '&:first-of-type': {
        position: 'sticky',
        top: 0,
        zIndex: 9999,
        lineHeight: '50px!important',
    },
    '&:last-of-type': {
        borderBottom: '1px solid #ccc',
        lineHeight: '50px!important',
    },
});

const tableFixedLeftCellByDay = (theme: Theme): CSSObject => ({
    height: 50,
    display: 'flex',
    border: '1px solid #ccc',
    borderBottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    zIndex: 999,
    padding: '16px',
    fontWeight: 500,
    position: 'sticky',
    left: 0,
    fontSize: '0.75em',
    '&:first-of-type': {
        position: 'sticky',
        top: 0,
        zIndex: 9999,
        lineHeight: '50px!important',
    },
    '&:last-of-type': {
        borderBottom: '1px solid #ccc',
        lineHeight: '50px!important',
    },
});

const tableFixedLeftCellDate = (theme: Theme): CSSObject => ({
    height: 50,
    display: 'flex',
    border: '1px solid #ccc',
    borderBottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    zIndex: 999,
    fontWeight: 500,
    position: 'sticky',
    textAlign: 'center',
    fontSize: '0.75em',
    left: 0,
});

const tableFixedTop = (): CSSObject => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'stretch',
    flexDirection: 'column',
});

const tableFixedTopCell = (theme: Theme): CSSObject => ({
    width: '100%',
    minWidth: '120px',
    position: 'sticky',
    top: 0,
    fontSize: '0.8em',
    lineHeight: '15px',
    height: 50,
    border: '1px solid #ccc',
    borderLeft: 0,
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    borderBottom: 0,
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
});

const tableBodyCell = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    border: '1px solid #ccc',
    borderBottom: 0,
    borderLeft: 0,
});

const tableBodyCellDate = (): CSSObject => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    border: '1px solid #ccc',
    borderBottom: 0,
    borderLeft: 0,
});

const tableBody = (): CSSObject => ({
    width: 'calc(100vh - 105px)',
    height: 500,
});

const emptyResult = (): CSSObject => ({
    width: '100%',
    height: 'calc(100vh - 340px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const tableFixedBottom = (theme: Theme): CSSObject => ({
    width: '100%',
    position: 'sticky',
    top: 0,
    bottom: 0,
    height: 50,
    border: '1px solid #ccc',
    borderLeft: 0,
    borderBottom: 0,
    padding: '0 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: 'bold',
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    '&:last-of-type': {
        borderBottom: '1px solid #ccc',
        lineHeight: '50px!important',
    },
});

const tableFixedRightHeader = (theme: Theme): CSSObject => ({
    position: 'sticky',
    top: 0,
    fontWeight: 500,
    width: '100px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '50px',
    padding: '0 15px',
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    border: '1px solid #ccc',
    borderBottom: 0,
});
const tableFixedRightRow = (theme: Theme): CSSObject => ({
    width: '100px',
    height: '50px',
    lineHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
    borderBottom: 0,
    fontWeight: 500,
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    '&:last-of-type': {
        borderBottom: '1px solid #ccc',
        position: 'sticky',
        bottom: 0,
    },
});

export const classes = {
    headerPopup,
    userTable,
    titlePopup,
    popupMinHeight,
    titlePage,
    iconBtn,
    btnPage,
    selectAllText,
    tableRoot,
    inputLarge,
    inputSmall,
    container,
    datesList,
    tableMain,
    tableBodyMain,
    tableFixedLeft,
    tableFixedLeftCell,
    tableFixedLeftCellByDay,
    tableFixedLeftCellDate,
    tableFixedTop,
    tableFixedTopCell,
    tableBodyCell,
    tableBodyCellDate,
    tableBody,
    tableFixedBottom,
    emptyResult,
    tableFixedRight,
    tableFixedRightHeader,
    tableFixedRightRow,
};
