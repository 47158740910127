import React, { PropsWithChildren, useContext } from 'react';
import { UserPermission } from '../config';
import { CustomAuthContext } from './AuthProvider';
import { Order, OrderBy } from '../types/UserManagment';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabsProps } from '../common/ui-components/Tab';
import { UserPanel } from '../components/pages/user/panel';
import { CompanyPanel } from '../components/pages/companies/panel';
import { IArgsCompany, ICompany } from '../infrastructure/DTO/company/company.dto';
import { FlexibleSettingsPanel } from '../components/pages/flexible-settings/flexible-settings-panel';
import { IProfile } from '../infrastructure/DTO/profile/profile.dto';
import GroupIcon from '@mui/icons-material/Group';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';
import { ClearStateCompany, fetchGetCompanyById } from '../store/company/company';
import { AppDispatch, AppState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { ClearStateCompanies } from '../store/company/companies';
import { StatusRedux } from '../enums/StatusRedux';
import { clearStoreBusinessUnits } from '../store/business-units/business-units';

export interface IUserManagementProvider {
    valueTab: number;
    profile: IProfile | null;
    company: ICompany | null;
    setCompany(company: ICompany | null): void;
    page: number;
    createUserPopup: boolean;
    createCompanyPopup: boolean;
    limit: number;
    order: Order;
    orderBy: OrderBy;
    searchText: string;
    searchMode: boolean;
    openCreateProfile: boolean;
    openEditProfile: boolean;
    openCreateBusinessUnit: boolean;
    openEditBusinessUnit: boolean;
    buttonNext: boolean;
    changePage(event: unknown, newPage: number): void;
    changeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>): void;
    handleCreateUserPopup(): void;
    handleIsActiveButtonNext(is_active: boolean): void;
    handleCreateCompanyPopup(): void;
    handleSearch(event: any): void;
    handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void;
    handleSortUser(event: OrderBy, property: Order): any;
    handleCreateProfile(): void;
    handleEditProfile(): void;
    setProfile(profile: IProfile): void;
    handleEditBusinessUnit(): void;
    handleCreateBusinessUnits(): void;
}

export const UserManagementContext = React.createContext<IUserManagementProvider>({
    valueTab: 0,
    profile: null,
    company: null,
    createUserPopup: false,
    createCompanyPopup: false,
    page: 0,
    limit: 25,
    order: 'asc',
    orderBy: 'client',
    searchText: '',
    searchMode: false,
    openCreateProfile: false,
    openEditProfile: false,
    openCreateBusinessUnit: false,
    openEditBusinessUnit: false,
    buttonNext: false,
    changePage: (event: unknown, newPage: number): void => {},
    setCompany: (company: ICompany | null): void => {},
    changeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>): void => {},
    handleCreateUserPopup: (): void => {},
    handleIsActiveButtonNext: (is_active: boolean): void => {},
    handleCreateCompanyPopup: (): void => {},
    handleSortUser: (event: OrderBy, property: Order) => () => {},
    handleSearch: (event: any): void => {},
    handleChangeSearchText: (event: React.ChangeEvent<HTMLInputElement>): void => {},
    handleCreateProfile: (): void => {},
    setProfile: (profile: IProfile): void => {},
    handleEditBusinessUnit: (): void => {},
    handleCreateBusinessUnits: (): void => {},
    handleEditProfile: (): void => {},
});

const UserManagementProvider: React.FC<PropsWithChildren<any>> = (props: PropsWithChildren<any>) => {
    const { children } = props;
    const dispatch: AppDispatch = useDispatch();

    const { checkPermission, token, parseToken } = useContext(CustomAuthContext);
    const getCompanyById = useSelector((state: AppState) => state.company);

    const [valueTab, _setValueTab] = React.useState<number>(0);
    const [order, _setOrder] = React.useState<Order>('asc');
    const [orderBy, _setOrderBy] = React.useState<OrderBy>('client');
    const [page, _setPage] = React.useState<number>(0);
    const [rowsPerPage, _setRowsPerPage] = React.useState<number>(25);

    const [company, _setCompany] = React.useState<ICompany | null>(null);

    const [searchMode, _setSearchMode] = React.useState<boolean>(false);
    const [searchText, _setSearchText] = React.useState<string>('');

    const [createUserPopup, _setCreateUserPopup] = React.useState<boolean>(false);
    const [createCompanyPopup, _setCreateCompanyPopup] = React.useState<boolean>(false);

    const [profile, _setProfile] = React.useState<IProfile | null>(null);

    const [openCreateProfile, _setOpenCreateProfile] = React.useState<boolean>(false);
    const [openEditProfile, _setOpenEditProfile] = React.useState<boolean>(false);
    const [openCreateBusinessUnit, _setOpenCreateBU] = React.useState<boolean>(false);
    const [openEditBusinessUnit, _setOpenEditBusinessUnit] = React.useState<boolean>(false);

    const [buttonNext, _setButtonNext] = React.useState<boolean>(false);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        _setValueTab(newValue);
    };

    /**
     * Get Company
     */
    React.useEffect(() => {
        const args: IArgsCompany = {
            company_id: parseToken().company,
        };

        dispatch(fetchGetCompanyById({ token, args }));

        return () => {
            dispatch(ClearStateCompanies());
            dispatch(ClearStateCompany());
            dispatch(clearStoreBusinessUnits());
        };
    }, []);

    /**
     * Set Company by Default
     */
    React.useEffect(() => {
        if (getCompanyById.status === StatusRedux.Succeeded) {
            _setCompany(getCompanyById.company);
        }
    }, [getCompanyById.status]);

    React.useEffect(() => {
        _setPage(0);
    }, [valueTab]);

    const handleCreateUserPopup = () => {
        _setCreateUserPopup(!createUserPopup);
    };

    const handleIsActiveButtonNext = (is_active: boolean) => {
        _setButtonNext(is_active);
    };

    const handleCreateCompanyPopup = () => {
        _setCreateCompanyPopup(!createCompanyPopup);
    };

    /**
     * Choose company and reset pagination
     * @param company
     */
    const handleSetCompany = (company: ICompany | null) => {
        _setCompany(company);
        _setPage(0);
    };

    /**
     * @param event
     * @param newPage
     * Change Page
     */
    const handleChangePage = (event: unknown, newPage: number) => {
        _setPage(newPage);
    };

    /**
     * @param event
     * Change Rows Per Page
     */
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        _setRowsPerPage(+event.target.value);
        _setPage(0);
    };

    /**
     * @param event
     * @param property
     * Set Order By Field
     */
    const handleRequestSort = (event: OrderBy, property: Order) => () => {
        const isAsc = orderBy === event && order === 'asc';
        _setOrder(isAsc ? 'desc' : 'asc');
        _setOrderBy(event);
    };

    /**
     * Reset page size
     */
    React.useEffect(() => {
        searchText.length > 0 ? _setPage(0) : _setPage(0);
    }, [searchText.length]);

    /**
     * @param event
     * Search mode
     */
    const handleSearch = (event: any) => {
        if ((event.type === 'click' || event.keyCode === 13) && searchText.length > 0) {
            _setPage(0);
            _setSearchMode(true);
            _setSearchText(searchText.trim());
        }
    };

    /**
     * Disable mod search
     */
    React.useEffect(() => {
        if (searchText.length === 0) {
            _setSearchMode(false);
        }
    }, [searchText]);

    /**
     * @param event
     * change search text
     * disable search mode
     */
    const handleChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        _setSearchMode(false);
        _setSearchText(event.target.value);
    };

    /**
     * @type IProfile
     * Set Profile
     * @param profile
     */
    const setProfile = (profile: IProfile) => {
        _setProfile(profile);
    };

    /**
     * Switch show/hidden edit popup Profile
     */
    const handleEditProfile = () => {
        _setOpenEditProfile(!openEditProfile);
    };

    /**
     * Switch show/hidden create popup Profile
     */
    const handleCreateProfile = () => {
        _setOpenCreateProfile(!openCreateProfile);
    };

    /**
     * Switch show/hidden edit popup Business Unit
     */
    const handleEditBusinessUnit = () => {
        _setOpenEditBusinessUnit(!openEditBusinessUnit);
    };

    /**
     * Switch show/hidden create popup Business Unit
     */
    const handleCreateBusinessUnits = () => {
        _setOpenCreateBU(!openCreateBusinessUnit);
    };

    const context = {
        valueTab: valueTab,
        profile: profile,
        page: page,
        limit: rowsPerPage,
        order: order,
        orderBy: orderBy,
        createUserPopup: createUserPopup,
        createCompanyPopup: createCompanyPopup,
        searchText: searchText,
        searchMode: searchMode,
        company: company,
        openCreateProfile: openCreateProfile,
        openEditProfile: openEditProfile,
        openCreateBusinessUnit: openCreateBusinessUnit,
        openEditBusinessUnit: openEditBusinessUnit,
        buttonNext: buttonNext,
        setProfile: setProfile,
        handleEditProfile: handleEditProfile,
        handleEditBusinessUnit: handleEditBusinessUnit,
        handleCreateBusinessUnits: handleCreateBusinessUnits,
        handleCreateProfile: handleCreateProfile,
        changePage: handleChangePage,
        setCompany: handleSetCompany,
        changeRowsPerPage: handleChangeRowsPerPage,
        handleCreateUserPopup: handleCreateUserPopup,
        handleIsActiveButtonNext: handleIsActiveButtonNext,
        handleCreateCompanyPopup: handleCreateCompanyPopup,
        handleSortUser: handleRequestSort,
        handleSearch: handleSearch,
        handleChangeSearchText: handleChangeSearchText,
    };

    return (
        <UserManagementContext.Provider value={context}>
            <Grid container spacing={2} direction='row' justifyContent='center' alignItems='center'>
                <Grid mb={2} item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Tabs value={valueTab} onChange={handleChangeTab} aria-label='tabs' variant='scrollable'>
                        {!checkPermission(UserPermission.ADMIN) && (
                            <Tab
                                label='Companies'
                                sx={{ minHeight: 'auto' }}
                                iconPosition='start'
                                icon={<HomeIcon sx={{ fontSize: '1.5em' }} />}
                                {...TabsProps(0)}
                            />
                        )}
                        {!checkPermission(UserPermission.ADMIN) && (
                            <Tab
                                sx={{ minHeight: 'auto' }}
                                iconPosition='start'
                                icon={<AdminPanelSettingsIcon sx={{ fontSize: '1.5em' }} />}
                                label='Profiles'
                                {...TabsProps(1)}
                            />
                        )}
                        <Tab
                            label='Business Units'
                            sx={{ minHeight: 'auto' }}
                            iconPosition='start'
                            icon={<WorkIcon sx={{ fontSize: '1.5em' }} />}
                            {...TabsProps(!checkPermission(UserPermission.ADMIN) ? 2 : 0)}
                        />
                        <Tab
                            sx={{ minHeight: 'auto' }}
                            iconPosition='start'
                            icon={<GroupIcon sx={{ fontSize: '1.5em' }} />}
                            label='Users'
                            {...TabsProps(!checkPermission(UserPermission.ADMIN) ? 3 : 1)}
                        />
                    </Tabs>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    {!checkPermission(UserPermission.ADMIN) && valueTab === 0 && <CompanyPanel />}
                    {!checkPermission(UserPermission.ADMIN) && valueTab === 1 && <FlexibleSettingsPanel />}
                    {valueTab === (!checkPermission(UserPermission.ADMIN) ? 2 : 0) && <FlexibleSettingsPanel />}
                    {valueTab === (!checkPermission(UserPermission.ADMIN) ? 3 : 1) && <UserPanel />}
                </Grid>
            </Grid>
            {children}
        </UserManagementContext.Provider>
    );
};

export default UserManagementProvider;
