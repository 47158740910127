import { IErrorResponse } from '../infrastructure/DTO/error/error-response.dto';

const errorParseMessage = (error: IErrorResponse): string => {
    if (error.errors?.length > 0) {
        let error_msg: string = '';
        error.errors.forEach((e) => {
            error_msg += `${e.message} `;
        });
        return error_msg;
    }
    return error.title ?? error;
};

export default errorParseMessage;
