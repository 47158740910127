import React, { useContext } from 'react';
import { classes } from './style';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { clearStateDamageMatching, fetchDamageMatching } from '../../../../../store/damage-lab/damage-matching';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { Box, Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { MatchingView } from './matching-view';
import { Spinner } from '../../../../spinner';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { IMatching } from '../../../../../infrastructure/DTO/damage-lab/damage-matching.dto';
import { IArgsDamageLab } from '../../../../../infrastructure/DTO/damage-lab/anomalies-detection.dto';
import errorParseMessage from '../../../../../config/error-parse';

const DamageMatching: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { token } = useContext(CustomAuthContext);
    const [view, setView] = React.useState<{ show: boolean; data: IMatching }>({
        show: false,
        data: {} as IMatching,
    });
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);
    const { damageMatching, status, error } = useSelector((state: AppState) => state.damageMatching);

    React.useEffect(() => {
        const args: IArgsDamageLab = {
            file_id: damageLabData.uploaded_file_id,
            inspection_case_id: damageLabData.inspection_case_id,
        };

        dispatch(
            fetchDamageMatching({
                token,
                args,
            })
        );

        return () => {
            dispatch(clearStateDamageMatching());
        };
    }, []);

    const handleClickCase = (matching: IMatching) => () => {
        setView({
            show: true,
            data: matching,
        });
    };

    const handleCloseCase = () => {
        setView({
            ...view,
            show: false,
        });
    };

    return (
        <>
            {status === StatusRedux.Loading ? (
                <Box sx={classes.spinner}>
                    <Spinner />
                </Box>
            ) : view.show ? (
                <MatchingView handleClose={handleCloseCase} data={view.data} />
            ) : (
                <>
                    <Grid container spacing={1} direction='row' justifyContent='center' alignItems='center' sx={classes.mainHeaderPage}>
                        <Grid item xs={12}>
                            <Typography variant='h6' noWrap sx={classes.titlePage}>
                                Matched Cases
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} direction='row' justifyContent='flex-start' alignItems='center'>
                        {damageMatching.matched_cases !== undefined &&
                            damageMatching.matched_cases.map((i, index) => (
                                <Grid key={index} item xs={12} sm={12} md={3} lg={2} xl={2}>
                                    <Card onClick={handleClickCase(i)}>
                                        <CardActionArea>
                                            <Box sx={classes.cardImg}>
                                                <img src={`data:image/jpeg;base64, ${i.thumbnail}`} alt={i.matched_inspection_case_id} />
                                            </Box>
                                            <CardContent>
                                                <Typography variant='subtitle2' gutterBottom>
                                                    {i.matched_inspection_case_id}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        {status === StatusRedux.Succeeded && damageMatching?.matched_cases.length === 0 && (
                            <Box sx={classes.emptyMsg}>No Matching</Box>
                        )}
                        {status === StatusRedux.Failed && <Box sx={classes.emptyMsg}>{errorParseMessage(error)}</Box>}
                    </Grid>
                </>
            )}
        </>
    );
};

export default DamageMatching;
