import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import { Group, Circle, Text, Tag, Label } from 'react-konva';
import { IDamageLabLayout } from '../IDamageLabLayout';
import DamageLabLayoutContext, { IDamageLabLayoutContext } from '../../../../../../context/DamageLabLayoutContext';

interface IKeyPointsLayoutComponent extends IDamageLabLayout {}

const KeyPointsLayout: React.FC<IKeyPointsLayoutComponent> = (props: IKeyPointsLayoutComponent) => {
    const { scale } = React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);
    const { imageOverView } = useSelector((state: AppState) => state.imageOverView);
    const [points, setPoints] = React.useState<Array<{ x: number; y: number; id: string; name: string; score: number }>>([]);
    const [hoverPoint, setHoverPoint] = React.useState('0');
    const anchorEl = React.useRef(null);

    React.useEffect(() => {
        if (props.view) {
            const points: Array<{ x: number; y: number; id: string; name: string; score: number }> = [];

            if (imageOverView.key_points_detection !== null) {
                imageOverView.key_points_detection.forEach((point) => {
                    points.push({
                        x: Math.floor(point.position.x * scale),
                        y: Math.floor(point.position.y * scale),
                        id: point.key_point_id,
                        name: point.key_point_name,
                        score: point.score,
                    });
                });
            }

            setPoints(points);
        } else {
            setPoints([]);
            setHoverPoint('0');
        }
    }, [scale, props.view]);

    const handleHoverTableRow = (line: { x: number; y: number; id: string }) => (event: any) => {
        setHoverPoint(line.id);
    };

    const handleOutTableRow = () => {
        setHoverPoint('0');
    };

    return (
        <React.Fragment>
            {points.map((line, i) =>
                String(hoverPoint) !== line.id ? (
                    <Group key={i} onClick={handleOutTableRow}>
                        <Circle
                            ref={anchorEl}
                            onClick={handleHoverTableRow(line)}
                            width={20}
                            height={20}
                            fill='rgba(0,0,0,0.9)'
                            x={line.x}
                            y={line.y}
                            shadowBlur={7}
                        />
                        <Text
                            onClick={handleHoverTableRow(line)}
                            width={20}
                            height={20}
                            fill='#fff'
                            x={line.x - 10}
                            y={line.y - 4}
                            align='center'
                            fontSize={10}
                            text={line.id}
                        />
                    </Group>
                ) : (
                    <Group key={i} onClick={handleOutTableRow}>
                        <Label x={line.x} y={line.y}>
                            <Tag pointerWidth={12} pointerHeight={12} pointerDirection={'up'} fill={'#fff'} lineJoin={'round'} shadowBlur={7} />
                            <Text fontSize={15} fill={'#000'} padding={12} align='center' text={`name: ${line?.name}; score: ${line.score}`} />
                        </Label>
                    </Group>
                )
            )}
        </React.Fragment>
    );
};

export default KeyPointsLayout;
