import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFetchArgs } from '../IFetch';
import { StatusRedux } from '../../enums/StatusRedux';
import ProfileServices from '../../services/profile/profile';
import { IArgsProfile, IProfile } from '../../infrastructure/DTO/profile/profile.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetProfileById = createAsyncThunk<IProfile, IFetchArgs<IArgsProfile>>(
    'profiles/getById',
    async (params: IFetchArgs<IArgsProfile>) => {
        return await ProfileServices.getProfileById(params);
    }
);

export const profileSlice = createSlice({
    name: 'profiles',
    initialState: {
        profile: {} as IProfile,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        clearStoreProfile: (state) => {
            state.profile = {} as IProfile;
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetProfileById.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetProfileById.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.profile = action.payload;
        });
        builder.addCase(fetchGetProfileById.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreProfile } = profileSlice.actions;
export default profileSlice;
