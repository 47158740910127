import * as React from 'react';
import { classes } from '../../style';
import { useSnackbar } from 'notistack';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContent, List, ListItem, ListItemSecondaryAction, ListItemText, Switch, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import {
    clearStoreIsActivePermissions,
    clearStoreUpdatePermissions,
    fetchPermissionActive,
    fetchPermissionDeactivate,
    fetchUpdatePermissions,
} from '../../../../../store/permission/permission';
import Box from '@mui/material/Box';
import { BasicDialog } from '../../../../../common/ui-components/Dialog';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { IArgsPermission, IPermission } from '../../../../../infrastructure/DTO/permission/permission.dto';
import errorParseMessage from "../../../../../config/error-parse";

interface IEditPermissionComponent {
    open: boolean;
    permission: IPermission;
    close: Function;
}

const EditPermission: React.FC<IEditPermissionComponent> = (props: IEditPermissionComponent) => {
    const { token } = useContext(CustomAuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const { statusUpdate, errorUpdate, statusIsActive, errorIsActive } = useSelector((state: AppState) => state.getPermissions);

    const [permissionData, setPermissionData] = React.useState<IPermission>({
        permission_id: props.permission.permission_id,
        name: props.permission.name,
        description: props.permission.description,
        is_active: props.permission.is_active,
    });

    const handleClose = () => {
        dispatch(clearStoreUpdatePermissions());
        setPermissionData({
            permission_id: props.permission.permission_id,
            name: props.permission.name,
            description: props.permission.description,
            is_active: props.permission.is_active,
        });
        props.close();
    };

    const handleSubmitEditPermission = (event: any) => {
        event.preventDefault();

        const args: IArgsPermission = {
            permission_id: permissionData.permission_id,
        };

        const body: IPermission = {
            name: permissionData.name,
            description: permissionData.description,
            is_active: permissionData.is_active,
        };

        dispatch(
            fetchUpdatePermissions({
                token,
                args,
                body,
            })
        );
    };

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;
        setPermissionData({
            ...permissionData,
            [name]: value,
        });
    };

    const handleTogglePermission = () => {
        const args: IArgsPermission = {
            permission_id: permissionData.permission_id,
        };

        if (!permissionData.is_active) {
            dispatch(
                fetchPermissionActive({
                    token,
                    args,
                })
            );
        } else {
            dispatch(
                fetchPermissionDeactivate({
                    token,
                    args,
                })
            );
        }

        setPermissionData({
            ...permissionData,
            is_active: !permissionData.is_active,
        });
    };

    React.useEffect(() => {
        if (statusUpdate === StatusRedux.Succeeded) {
            enqueueSnackbar('Permission Updated Successfully', {
                variant: 'success',
            });

            dispatch(clearStoreUpdatePermissions());
            props.close();
        }

        if (statusUpdate === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorUpdate), {
                variant: 'error',
            });
        }
    }, [statusUpdate]);

    React.useEffect(() => {
        if (statusIsActive === StatusRedux.Succeeded) {
            let msg = !permissionData.is_active ? 'Permission Deactivated' : 'Permission Activated';

            enqueueSnackbar(msg, {
                variant: 'success',
            });

            props.close();
            dispatch(clearStoreIsActivePermissions());
        }
        if (statusIsActive === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorIsActive), {
                variant: 'error',
            });

            dispatch(clearStoreIsActivePermissions());
        }
    }, [statusIsActive]);

    return (
        <BasicDialog handlePopup={handleClose} open={props.open} title={props.permission.name} width='sm'>
            <DialogContent>
                <Box sx={classes.bodyPopup}>
                    <form autoComplete='off' onSubmit={handleSubmitEditPermission}>
                        <Grid container spacing={3} justifyContent='flex-end'>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name='name'
                                    required={true}
                                    id='outlined-basic'
                                    type='text'
                                    label='Permission Name'
                                    variant='outlined'
                                    value={permissionData.name}
                                    onChange={handleChangeInput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    name='description'
                                    id='desc'
                                    type='text'
                                    label='Permission Description'
                                    variant='outlined'
                                    value={permissionData.description}
                                    onChange={handleChangeInput}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button fullWidth type='submit' variant='contained' color='primary'>
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            id='switch-list-label-wifi'
                                            primary={
                                                permissionData.is_active ? (
                                                    <Box component='span' sx={classes.iconEdit}>
                                                        Enable
                                                    </Box>
                                                ) : (
                                                    <Box component='span' sx={classes.iconDelete}>
                                                        Disable
                                                    </Box>
                                                )
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <Switch edge='end' onChange={handleTogglePermission} checked={permissionData.is_active} />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                                <Divider />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </DialogContent>
        </BasicDialog>
    );
};

export default EditPermission;
