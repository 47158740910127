import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { classes } from './style';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PublishIcon from '@mui/icons-material/Publish';
import { Chip, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { PrincipalCarMain } from '../principal-car';
import { MetaData } from '../metadata';
import { DetectedModel } from '../detected-model';
import AnomaliesMain from '../parts&anomalies/anomalies-main';
import { TabPanel, TabsProps } from '../../../../../common/ui-components/Tab';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearAnomaliesDetection,
    clearAnomalyMeasurements,
    clearDataDamageLab,
    clearStatusDamageLab,
    fetchRemoveInspectionCase,
} from '../../../../../store/damage-lab/upload-img';
import { useSnackbar } from 'notistack';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { DamageMatching } from '../matching';
import TuneIcon from '@mui/icons-material/Tune';
import Threshold from '../../../../../common/ui-components/Threshold';
import CloseIcon from '@mui/icons-material/Close';
import { ThresholdContext } from '../../../../../context/ThresholdContext';
import { clearGetStatusThresholdSettings, fetchGetThresholdSettings } from '../../../../../store/inspection/get-threshold-settings';
import Box from '@mui/material/Box';
import { backButtonDisabled, onbeforeunload } from '../../../../../utils/onbeforeunload';
import { showPopup } from '../../../../../store/events/popup';
import Hidden from '@mui/material/Hidden';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { ISettings } from '../../../../../infrastructure/DTO/threshold-settings/threshold-settings.dto';
import { IInspectionCaseId } from '../../../../../infrastructure/DTO/inspection/inspection.dto';
import { BasicDialog } from '../../../../../common/ui-components/Dialog';
import SaveIcon from '@mui/icons-material/Save';
import { InputThresholdsParams } from '../../../../../common/interface/IThreshold';
import errorParseMessage from '../../../../../config/error-parse';

type StateInfo = {
    classification: string | null;
    quality: string | undefined | null;
    car_coverage: number | null;
    camera_view: string | null;
    img: any;
};

const Main: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { token } = useContext(CustomAuthContext);
    const navigate = useNavigate();
    const getThresholdSettings = useSelector((state: AppState) => state.getThresholdSettings);
    const [showThresholdButton, setShowThresholdButton] = React.useState<boolean>(false);
    const [thresholdPopup, setThresholdPopup] = React.useState<boolean>(false);
    const [reset, setReset] = React.useState<boolean>(false);
    const [applyThresholdBtn, setApplyThresholdBtn] = React.useState<boolean>(true);
    const [thresholds, setThresholds] = React.useState<ISettings>({
        thresholds_per_damage_type: [
            { damage_type: 4, threshold: 0 },
            { damage_type: 6, threshold: 0 },
            { damage_type: 12, threshold: 0 },
            { damage_type: 50, threshold: 0 },
            { damage_type: 51, threshold: 0 },
            { damage_type: 52, threshold: 0 },
            { damage_type: 53, threshold: 0 },
            { damage_type: 54, threshold: 0 },
        ],
    });
    const { imageMain } = useSelector((state: AppState) => state.damageLabImage);
    const [value, setValue] = React.useState(0);
    const [imgInfo, setImgInfo] = React.useState<StateInfo>({
        classification: null,
        quality: null,
        car_coverage: null,
        camera_view: null,
        img: null,
    });
    const { damageLabData, statusRemove, error } = useSelector((state: AppState) => state.damageLabSingleImg);
    const dispatch: AppDispatch = useDispatch();

    React.useEffect(() => {
        if (getThresholdSettings.status === StatusRedux.Succeeded) {
            setThresholds({
                thresholds_per_damage_type: getThresholdSettings.thresholdSettings.settings.thresholds_per_damage_type
                    ?.filter((t) => t.damage_type !== 8)
                    .map((i) => {
                        return { damage_type: i.damage_type, threshold: i.threshold };
                    }),
            });

            dispatch(clearGetStatusThresholdSettings());
        }
    }, [getThresholdSettings.status]);

    React.useEffect(() => {
        if (Object.keys(damageLabData).length > 0) {
            const args: IInspectionCaseId = {
                inspection_case: damageLabData.inspection_case_id,
            };
            dispatch(
                fetchGetThresholdSettings({
                    token,
                    args,
                })
            );
            setImgInfo({
                classification: damageLabData.image_classification !== null ? damageLabData.image_classification.replace('_', ' ') : null,
                quality: damageLabData.image_quality_result?.status !== null ? damageLabData.image_quality_result?.status.replace('_', ' ') : null,
                car_coverage: damageLabData.car_coverage !== null ? damageLabData.car_coverage : null,
                camera_view: damageLabData.camera_view !== null ? damageLabData.camera_view.replace('_', ' ') : null,
                img: imageMain.image.src,
            });
        } else {
            navigate('/home/damage-lab/');
        }

        onbeforeunload(true);
        backButtonDisabled(true);

        return () => {
            dispatch(clearStatusDamageLab());
            dispatch(clearDataDamageLab());
            dispatch(clearAnomaliesDetection());
            dispatch(clearAnomalyMeasurements());
            onbeforeunload(undefined);
            backButtonDisabled(false);
            dispatch(showPopup(false));
        };
    }, []);

    React.useEffect(() => {
        if (statusRemove === StatusRedux.Succeeded) {
            enqueueSnackbar('Removed Successfully', {
                variant: 'success',
            });

            dispatch(clearStatusDamageLab());
            dispatch(clearDataDamageLab());
            dispatch(clearAnomaliesDetection());
            dispatch(clearAnomalyMeasurements());
            navigate('/home/damage-lab/');
        }

        if (statusRemove === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(error), {
                variant: 'error',
            });

            dispatch(clearStatusDamageLab());
        }
    }, [statusRemove]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleRemove = () => {
        const args: IInspectionCaseId = {
            inspection_case: damageLabData.inspection_case_id,
        };

        dispatch(
            fetchRemoveInspectionCase({
                token,
                args,
            })
        );
    };

    const handleUploadNewPic = () => {
        navigate('/home/damage-lab/');
        dispatch(clearDataDamageLab());
        dispatch(clearAnomaliesDetection());
        dispatch(clearAnomalyMeasurements());
    };

    const handleThreshold = () => {
        setThresholdPopup(!thresholdPopup);
    };

    const handleShowThresholds = (show: boolean): void => {
        setShowThresholdButton(show);
    };

    const handleChangeThresholds = (params: InputThresholdsParams) => {
        setThresholds({
            thresholds_per_damage_type: [
                ...thresholds.thresholds_per_damage_type.map((i) => {
                    if (i.damage_type === params.name) {
                        i.threshold = params.value;
                    }
                    return i;
                }),
            ],
        });
    };

    const handleApplyThreshold = () => {
        setReset(true);
        setThresholdPopup(false);
    };

    React.useEffect(() => {
        if (!thresholdPopup) {
            setReset(false);
        }
    }, [thresholdPopup]);

    React.useEffect(() => {
        let findNull = thresholds.thresholds_per_damage_type.find((i) => i.threshold === 0);
        setApplyThresholdBtn(Boolean(findNull));
    }, [thresholds.thresholds_per_damage_type]);

    return (
        <ThresholdContext.Provider value={{ showThresholdButton, reset, thresholds, handleShowThresholds, handleChangeThresholds }}>
            <BasicDialog
                handlePopup={handleThreshold}
                open={thresholdPopup}
                title='Thresholds'
                width='md'
                buttons={
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item>
                            <Button size='small' variant='outlined' color='primary' onClick={handleThreshold} endIcon={<CloseIcon />}>
                                Close
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={applyThresholdBtn}
                                form='thresholds-form'
                                size='small'
                                fullWidth
                                type='submit'
                                variant='contained'
                                color='secondary'
                                endIcon={<SaveIcon />}
                            >
                                APPLY
                            </Button>
                        </Grid>
                    </Grid>
                }
            >
                <Paper sx={classes.paper} elevation={2}>
                    <Threshold
                        handleChangeInput={handleChangeThresholds}
                        handleApplyThreshold={handleApplyThreshold}
                        thresholds_per_damage_type={thresholds.thresholds_per_damage_type}
                        thresholds_per_size={{ xs: 12, sm: 12, md: 6, lg: 3, xl: 3 }}
                    />
                </Paper>
            </BasicDialog>

            <Grid container spacing={3} direction='row' justifyContent='center' alignItems='center' sx={classes.mainHeaderPage}>
                <Grid sx={classes.titlePageBlock} item xs={12} sm={12} md={6} lg={6} xl={7}>
                    <Hidden only={['xs', 'sm', 'md', 'lg']}>
                        <Typography variant='h6' sx={classes.titlePage}>
                            SINGLE PICTURE
                        </Typography>
                    </Hidden>
                    <Typography variant='caption' sx={classes.titlePage}>
                        Classification: <Chip size='small' variant='outlined' color='secondary' label={imgInfo.classification} />
                    </Typography>
                    {imgInfo.quality !== undefined && (
                        <Typography variant='caption' sx={classes.titlePage}>
                            Quality: <Chip size='small' variant='outlined' color='secondary' label={imgInfo.quality} />
                        </Typography>
                    )}
                    {imgInfo.car_coverage !== null && (
                        <Typography variant='caption' sx={classes.titlePage}>
                            Car Coverage:
                            <Chip size='small' variant='outlined' color='primary' label={imgInfo.car_coverage.toFixed(3)} />
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={classes.btnPage}>
                    <Button onClick={handleRemove} size='small' variant='outlined' color='error' endIcon={<DeleteIcon />}>
                        Remove
                    </Button>
                    <Button onClick={handleUploadNewPic} size='small' variant='outlined' color='secondary' endIcon={<PublishIcon />}>
                        Upload New Image
                    </Button>
                    {showThresholdButton && (
                        <Button onClick={handleThreshold} size='small' variant='contained' color='primary' endIcon={<TuneIcon />}>
                            Thresholds
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Paper elevation={2}>
                <Box sx={classes.root}>
                    <Tabs
                        orientation='vertical'
                        variant='scrollable'
                        value={value}
                        onChange={handleChange}
                        aria-label='Vertical tabs example'
                        sx={classes.tabs}
                    >
                        <Tab sx={classes.tabButton} label='Main' {...TabsProps(0)} />
                        <Tab sx={classes.tabButton} label='Metadata' {...TabsProps(1)} />
                        <Tab sx={classes.tabButton} label='Principal Car' {...TabsProps(2)} />
                        <Tab sx={classes.tabButton} label='Detected Model' {...TabsProps(3)} />
                        <Tab sx={classes.tabButton} label='Parts And Anomalies' {...TabsProps(4)} />
                        <Tab sx={classes.tabButton} label='Matching' {...TabsProps(5)} />
                    </Tabs>
                    <TabPanel style={classes.tab} value={value} index={0}>
                        <Box sx={classes.tabBody}>
                            <Box sx={classes.topBlock}>
                                <Box sx={classes.title}>{imgInfo.camera_view}</Box>
                            </Box>
                            <Box sx={classes.imgMain}>
                                <img src={imgInfo.img} alt='pic' />
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel style={classes.tab} value={value} index={1}>
                        <MetaData />
                    </TabPanel>
                    <TabPanel style={classes.tab} value={value} index={2}>
                        <PrincipalCarMain />
                    </TabPanel>
                    <TabPanel style={classes.tab} value={value} index={3}>
                        <DetectedModel />
                    </TabPanel>
                    <TabPanel style={classes.tab} value={value} index={4}>
                        <AnomaliesMain />
                    </TabPanel>
                    <TabPanel style={classes.tab} value={value} index={5}>
                        <DamageMatching />
                    </TabPanel>
                </Box>
            </Paper>
        </ThresholdContext.Provider>
    );
};

export default Main;
