import {CSSObject} from "@mui/material";

const stage = (): CSSObject => ({
    width: '100%',
    minHeight: '650px',
    overflow: 'hidden',
})

const tabBody = (): CSSObject => ({
    marginTop: '25px'
})

const panelTab = (): CSSObject => ({
    width: '100%',
    overflow: 'hidden'
})

export const classes = {
    stage, tabBody, panelTab
}