import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel, TabsProps } from '../../../common/ui-components/Tab';
import CompanyReport from './company-report/company-report';
import { DamagesExport } from './damages-export';

const UsageReport: React.FC = () => {
    const [value, setValue] = React.useState<number>(0);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div>
            <Tabs value={value} onChange={handleChangeTab} aria-label='tabs'>
                <Tab label='Inspections' {...TabsProps(0)} />
                <Tab label='Damages' {...TabsProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Box mt={1}>
                    <CompanyReport />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box mt={1}>
                    <DamagesExport />
                </Box>
            </TabPanel>
        </div>
    );
};

export default UsageReport;
