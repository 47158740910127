import * as React from 'react';
import {
    Box,
    Button,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
} from '@mui/material';
import { Spinner } from '../../../spinner';
import Error from '../../../error/error';
import Moment from 'react-moment';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../store';
import { clearStoreInspectionList, fetchInspectionFilter, initFilter, inspectionFilterStore } from '../../../../store/inspection/inspection-list';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import ReportInspection from '../view-report/report';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { inspectionTypeList } from '../../../../store/inspection/inspection-type';
import { InspectionStatusColor, InspectionStatusId } from '../../../../enums/InspectionStatus';
import { UserPermission } from '../../../../config';
import CachedIcon from '@mui/icons-material/Cached';
import { classes } from './style';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { Filter } from '../filter';
import { FilterChip } from '../chip';
import { fetchGetAllCompanies } from '../../../../store/company/companies';
import { IInspection, IInspectionFilter } from '../../../../infrastructure/DTO/inspection/inspection.dto';
import { IArgsCompany } from '../../../../infrastructure/DTO/company/company.dto';

const InspectionList: React.FC = () => {
    const { checkPermission, parseToken, token } = useContext(CustomAuthContext);
    const [filterPanel, setFilterPanel] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const [countInspections, setCountInspections] = React.useState<number>(0);
    const { inspectionListData, statusInspectionList, errorInspectionList, inspectionFilter } = useSelector(
        (state: AppState) => state.inspectionList
    );
    const [search, setSearch] = React.useState<boolean>(false);
    const [searchText, setSearchText] = React.useState<string>('');

    /**
     * Change Page
     * @param event
     * @param newPage
     */
    const handleChangePage = (event: unknown, newPage: number) => {
        dispatch(
            inspectionFilterStore({
                ...inspectionFilter,
                limit: inspectionFilter.limit,
                offset: newPage,
            })
        );
    };

    /**
     * Change Rows Per Page
     * @param event
     */
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            inspectionFilterStore({
                ...inspectionFilter,
                limit: +event.target.value,
                offset: 0,
            })
        );
    };

    /**
     * Change Sort Table ASC/DESC
     * @param event
     */
    const handleRequestSort = (event: any) => () => {
        dispatch(
            inspectionFilterStore({
                ...inspectionFilter,
                sort_by: event,
                order_by: inspectionFilter.order_by === 'asc' ? 'desc' : 'asc',
            })
        );
    };

    /**
     * Get Inspections
     */
    React.useEffect(() => {
        const body: IInspectionFilter = {
            ...inspectionFilter,
            order_by: inspectionFilter.order_by,
            sort_by: inspectionFilter.sort_by,
            limit: inspectionFilter.limit,
            offset: inspectionFilter.offset,
            search: searchText,
        };

        if (search) {
            dispatch(
                fetchInspectionFilter({
                    token,
                    body,
                })
            );
        } else {
            if (searchText.length === 0) {
                dispatch(
                    fetchInspectionFilter({
                        token,
                        body,
                    })
                );
            }
        }
    }, [inspectionFilter, searchText, search]);

    /**
     * Set count Inspections
     */
    React.useEffect(() => {
        if (statusInspectionList === StatusRedux.Succeeded) {
            setCountInspections(inspectionListData.count);
        }
    }, [statusInspectionList]);

    /**
     * Get Inspection Type
     * Get All companies
     */
    React.useEffect(() => {
        if (inspectionListData.count !== 0) {
            dispatch(inspectionTypeList());

            const args: IArgsCompany = {
                company_id: parseToken().company,
                user_id: parseToken().sub,
            };

            dispatch(fetchGetAllCompanies({ token, args }));
        }

        return () => {
            dispatch(clearStoreInspectionList());
        };
    }, []);

    /**
     * Open Report panel
     * @param userId
     * @param inspectionId
     * @param inspectionType
     */
    const openPopupReport = (userId: string, inspectionId: string, inspectionType: string | null) => () => {
        navigate(`/home/inspections?is_open=${true}&inspection_id=${inspectionId}`);
    };

    /**
     * Refresh page
     */
    const handleRefresh = () => {
        const body: IInspectionFilter = { ...inspectionFilter, order_by: inspectionFilter.order_by };
        setSearchText('');
        dispatch(
            fetchInspectionFilter({
                token,
                body,
            })
        );
    };

    /**
     * Change global search text
     * @param event
     */
    const handleChangeSearchText = (event: any) => {
        setSearch(false);
        setSearchText(event.target.value);
    };

    /**
     * On/Off Global Search
     * @param event
     */
    const handleSearch = (event: any) => {
        if ((event.type === 'click' || event.keyCode === 13) && searchText.length > 0) {
            dispatch(inspectionFilterStore({ ...inspectionFilter, offset: 0 }));
            setSearch(true);
            setSearchText(searchText.trim());
        }
    };

    /**
     * Disable mod search
     */
    React.useEffect(() => {
        if (searchText.length === 0) {
            setSearch(false);
        }
    }, [searchText]);

    /**
     * Reset page size
     */
    React.useEffect(() => {
        searchText.length > 0 && dispatch(inspectionFilterStore({ ...inspectionFilter, offset: 0 }));
    }, [searchText.length]);

    /**
     * Open/Close Filter
     * @param event
     */
    const toggleDrawer = (event?: React.KeyboardEvent | React.MouseEvent) => {
        if (event && event?.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setFilterPanel(!filterPanel);
    };

    const disableReportViewBtn = (closure_reason: string | null, inspection_status: string) => {
        return !(
            (closure_reason === null || closure_reason === 'UserAction') &&
            (inspection_status.toUpperCase() === 'CLOSED' || inspection_status.toUpperCase() === 'COMPLETED')
        );
    };

    return (
        <>
            <Filter open={filterPanel} toggleDrawer={toggleDrawer} />
            <Grid container spacing={5} direction='row' justifyContent='center' alignItems='center'>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <Typography variant='h6' noWrap sx={classes.titlePage}>
                        Inspections History
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={9} xl={9} sx={classes.btnPage}>
                    <Grid container spacing={2} direction='row' justifyContent='flex-end' alignItems='center'>
                        <Grid item>
                            <Box sx={{ width: '100%', minWidth: '300px' }}>
                                <FormControl fullWidth variant='standard'>
                                    <InputLabel htmlFor='search'>Search in results</InputLabel>
                                    <Input
                                        value={searchText}
                                        onKeyUp={handleSearch}
                                        onChange={handleChangeSearchText}
                                        id='search'
                                        size='small'
                                        type='text'
                                        error={!(searchText.length > 2) && searchText.length > 0}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    onClick={handleSearch}
                                                    sx={{ display: searchText.length > 0 ? 'block' : 'none' }}
                                                    disabled={!(searchText.length > 2)}
                                                    color='primary'
                                                    component='span'
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {!(searchText.length > 2) && searchText.length > 0 && (
                                        <FormHelperText id='search'>Please, enter at least 3 symbols</FormHelperText>
                                    )}
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Button size='small' onClick={handleRefresh} variant='outlined' color='secondary' endIcon={<CachedIcon />}>
                                Refresh
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={toggleDrawer} size='small' variant='outlined' color='primary' endIcon={<FilterListIcon />}>
                                Advanced filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <FilterChip />
            <Paper elevation={2} sx={classes.tableRoot}>
                {(() => {
                    switch (statusInspectionList) {
                        case StatusRedux.Loading:
                            return <Spinner />;
                        case StatusRedux.Succeeded:
                            if (inspectionListData !== undefined && inspectionListData.inspection_list.length > 0) {
                                return (
                                    <TableContainer sx={classes.containerMain}>
                                        <Table stickyHeader aria-label='sticky table'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ width: '15px' }} align='left'>
                                                        #
                                                    </TableCell>
                                                    {!checkPermission(UserPermission.ADMIN) && (
                                                        <>
                                                            <TableCell sx={classes.companyName} align='left'>
                                                                Company Name
                                                            </TableCell>
                                                        </>
                                                    )}
                                                    <TableCell sx={classes.inspectionId} align='left'>
                                                        Inspection ID
                                                    </TableCell>
                                                    <TableCell sx={classes.licensePlate} align='left'>
                                                        Plate number
                                                    </TableCell>
                                                    <TableCell sx={classes.businessUnit} align='left'>
                                                        Business Unit
                                                    </TableCell>
                                                    <TableCell sx={classes.clientPID} align='left'>
                                                        <TableSortLabel
                                                            active={inspectionFilter.sort_by === 'clientPID'}
                                                            direction={inspectionFilter.sort_by === 'clientPID' ? inspectionFilter.order_by : 'desc'}
                                                            onClick={handleRequestSort('clientPID')}
                                                        >
                                                            Client PID
                                                            {inspectionFilter.sort_by === 'clientPID' ? (
                                                                <Box component='span' sx={classes.visuallyHidden}>
                                                                    {inspectionFilter.order_by === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sx={classes.clientInspector} align='left'>
                                                        Client Inspector
                                                    </TableCell>
                                                    <TableCell sx={classes.inspectionType} align='left'>
                                                        <TableSortLabel
                                                            active={inspectionFilter.sort_by === 'inspectionType'}
                                                            direction={
                                                                inspectionFilter.sort_by === 'inspectionType' ? inspectionFilter.order_by : 'desc'
                                                            }
                                                            onClick={handleRequestSort('inspectionType')}
                                                        >
                                                            Inspection Type
                                                            {inspectionFilter.sort_by === 'inspectionType' ? (
                                                                <Box component='span' sx={classes.visuallyHidden}>
                                                                    {inspectionFilter.order_by === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sx={classes.cellDate} align='left'>
                                                        Sent At
                                                    </TableCell>
                                                    <TableCell sx={classes.cellDate} align='left'>
                                                        <TableSortLabel
                                                            active={inspectionFilter.sort_by === 'date'}
                                                            direction={inspectionFilter.sort_by === 'date' ? inspectionFilter.order_by : 'desc'}
                                                            onClick={handleRequestSort('date')}
                                                        >
                                                            Created At
                                                            {inspectionFilter.sort_by === 'date' ? (
                                                                <Box component='span' sx={classes.visuallyHidden}>
                                                                    {inspectionFilter.order_by === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sx={classes.cellDate} align='left'>
                                                        Closure Reason
                                                    </TableCell>
                                                    <TableCell sx={classes.status} align='left'>
                                                        <TableSortLabel
                                                            active={inspectionFilter.sort_by === 'status'}
                                                            direction={inspectionFilter.sort_by === 'status' ? inspectionFilter.order_by : 'desc'}
                                                            onClick={handleRequestSort('status')}
                                                        >
                                                            Status
                                                            {inspectionFilter.sort_by === 'status' ? (
                                                                <Box component='span' sx={classes.visuallyHidden}>
                                                                    {inspectionFilter.order_by === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sx={classes.btnReport} align='left'>
                                                        Report
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {inspectionListData.inspection_list.map((data: IInspection, index: number) => {
                                                return (
                                                    <TableBody key={index}>
                                                        <TableRow hover role='checkbox' tabIndex={-1}>
                                                            <TableCell sx={{ width: '15px' }}>
                                                                {inspectionFilter.limit * inspectionFilter.offset + (index + 1)}
                                                            </TableCell>
                                                            {!checkPermission(UserPermission.ADMIN) && (
                                                                <>
                                                                    <TableCell sx={classes.companyName}>
                                                                        <Tooltip
                                                                            arrow
                                                                            disableFocusListener
                                                                            title={
                                                                                <React.Fragment>
                                                                                    <Box sx={classes.tooltipUserName}>
                                                                                        <Link
                                                                                            color='inherit'
                                                                                            onClick={() => {
                                                                                                navigator.clipboard
                                                                                                    .writeText(data.company?.name as string)
                                                                                                    .then((r) => r);
                                                                                            }}
                                                                                            component='button'
                                                                                        >
                                                                                            company name (click to copy)
                                                                                        </Link>
                                                                                    </Box>
                                                                                    <Box sx={classes.tooltipUserName}>
                                                                                        <Link
                                                                                            color='inherit'
                                                                                            onClick={() => {
                                                                                                navigator.clipboard
                                                                                                    .writeText(data.company?.company_id as string)
                                                                                                    .then((r) => r);
                                                                                            }}
                                                                                            component='button'
                                                                                        >
                                                                                            company id (click to copy)
                                                                                        </Link>
                                                                                    </Box>
                                                                                </React.Fragment>
                                                                            }
                                                                            aria-label='Copy to clipboard'
                                                                        >
                                                                            <Link color='inherit' href='#' sx={classes.btnCopy}>
                                                                                {data.company?.name}
                                                                            </Link>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </>
                                                            )}
                                                            <TableCell
                                                                sx={classes.inspectionId}
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(data.inspection_id).then((r) => r);
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    arrow
                                                                    disableFocusListener
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Box sx={classes.textCenter}>Click To Copy</Box>
                                                                        </React.Fragment>
                                                                    }
                                                                    aria-label='Click To Copy'
                                                                >
                                                                    <Link color='inherit' href='#' sx={classes.btnCopy}>
                                                                        {data.inspection_id}
                                                                    </Link>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={classes.licensePlate}
                                                                onClick={() => {
                                                                    data.lpn
                                                                        ? navigator.clipboard.writeText(data.lpn).then((r) => r)
                                                                        : navigator.clipboard.writeText(data.lpn_detected as string).then((r) => r);
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    arrow
                                                                    disableFocusListener
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Box sx={classes.textCenter}>Click To Copy</Box>
                                                                        </React.Fragment>
                                                                    }
                                                                    aria-label='Click To Copy'
                                                                >
                                                                    <Link color='inherit' href='#' sx={classes.btnCopy}>
                                                                        {data.lpn ? data.lpn : data.lpn_detected}
                                                                    </Link>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell sx={classes.businessUnit}>
                                                                <Tooltip
                                                                    arrow
                                                                    disableFocusListener
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Box sx={classes.tooltipUserName}>
                                                                                <Link
                                                                                    color='inherit'
                                                                                    onClick={() => {
                                                                                        navigator.clipboard
                                                                                            .writeText(data.company?.business_unit?.name as string)
                                                                                            .then((r) => r);
                                                                                    }}
                                                                                    component='button'
                                                                                >
                                                                                    business unit name (click to copy)
                                                                                </Link>
                                                                            </Box>
                                                                            <Box sx={classes.tooltipUserName}>
                                                                                <Link
                                                                                    color='inherit'
                                                                                    onClick={() => {
                                                                                        navigator.clipboard
                                                                                            .writeText(
                                                                                                data.company?.business_unit
                                                                                                    ?.business_unit_id as string
                                                                                            )
                                                                                            .then((r) => r);
                                                                                    }}
                                                                                    component='button'
                                                                                >
                                                                                    business unit id (click to copy)
                                                                                </Link>
                                                                            </Box>
                                                                        </React.Fragment>
                                                                    }
                                                                    aria-label='Copy to clipboard'
                                                                >
                                                                    <Link color='inherit' href='#' sx={classes.btnCopy}>
                                                                        {data.company?.business_unit?.name}
                                                                    </Link>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={classes.clientPID}
                                                                onClick={() => {
                                                                    data.pid !== null && navigator.clipboard.writeText(data.pid);
                                                                }}
                                                            >
                                                                {data.pid !== null && (
                                                                    <Tooltip
                                                                        arrow
                                                                        disableFocusListener
                                                                        title={
                                                                            <React.Fragment>
                                                                                <Box sx={classes.textCenter}>Click To Copy</Box>
                                                                            </React.Fragment>
                                                                        }
                                                                        aria-label='Copy to clipboard'
                                                                    >
                                                                        <Link color='inherit' href='#' sx={classes.btnCopy}>
                                                                            {data.pid}
                                                                        </Link>
                                                                    </Tooltip>
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={classes.clientInspector}
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(data.client_inspector_name).then((r) => r);
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    arrow
                                                                    disableFocusListener
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Box sx={classes.textCenter}>Click To Copy</Box>
                                                                        </React.Fragment>
                                                                    }
                                                                    aria-label='Click To Copy'
                                                                >
                                                                    <Link color='inherit' href='#' sx={classes.btnCopy}>
                                                                        {data.client_inspector_name}
                                                                    </Link>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell sx={classes.inspectionType}>
                                                                {data.inspection_type !== null && (
                                                                    <Chip
                                                                        variant='outlined'
                                                                        size='small'
                                                                        label={data.inspection_type.replace(/_/g, ' ')}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                            <TableCell sx={classes.cellDate}>
                                                                {data.callback_response_sent_status !== null &&
                                                                    moment(data.callback_response_sent_status).format('DD/MM/YYYY HH:mm')}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={classes.cellDate}
                                                                onClick={() => {
                                                                    navigator.clipboard
                                                                        .writeText(moment(data.created_at).format('DD/MM/YYYY HH:mm'))
                                                                        .then((r) => r);
                                                                }}
                                                            >
                                                                <Tooltip arrow disableFocusListener title='Click To Copy' aria-label='Click To Copy'>
                                                                    <Link color='inherit' href='#' sx={classes.btnCopy}>
                                                                        <Moment format='DD/MM/YYYY HH:mm' date={data.created_at} />
                                                                    </Link>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell sx={classes.clientPID}>
                                                                {data.closure_reason ? (
                                                                    <Chip
                                                                        label={data.closure_reason}
                                                                        size='small'
                                                                        variant='outlined'
                                                                        color='success'
                                                                    />
                                                                ) : null}
                                                            </TableCell>
                                                            <TableCell sx={classes.status}>
                                                                <Chip
                                                                    size='small'
                                                                    style={{
                                                                        background:
                                                                            InspectionStatusColor[
                                                                                InspectionStatusId[
                                                                                    data.inspection_status as keyof typeof InspectionStatusId
                                                                                ] as number
                                                                            ],
                                                                        color: '#ffffff',
                                                                    }}
                                                                    label={data.inspection_status.toUpperCase().replace('_', ' ')}
                                                                />
                                                            </TableCell>
                                                            <TableCell sx={classes.btnReport}>
                                                                <IconButton
                                                                    disableFocusRipple={true}
                                                                    disabled={disableReportViewBtn(data.closure_reason, data.inspection_status)}
                                                                    onClick={openPopupReport(data.user_id, data.inspection_id, data.inspection_type)}
                                                                    color='secondary'
                                                                >
                                                                    <OpenInNewIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                );
                                            })}
                                        </Table>
                                    </TableContainer>
                                );
                            } else {
                                return (
                                    <Box sx={classes.containerMain}>
                                        <Box sx={classes.emptyResult}>No Result!</Box>
                                    </Box>
                                );
                            }
                        case StatusRedux.Failed:
                            return <Error msg={errorInspectionList?.errors.length > 0 ? errorInspectionList?.errors[0].message : 'error'} />;
                    }
                })()}
                {statusInspectionList === StatusRedux.Succeeded && (
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component='div'
                        count={countInspections}
                        rowsPerPage={inspectionFilter.limit}
                        page={inspectionFilter.offset}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Paper>

            <ReportInspection />
        </>
    );
};

export default InspectionList;
