import { ServicesBase } from '../ServicesBase';
import { IFetchArgs, IFetchBody } from '../../store/IFetch';
import { IArgsProfile, IProfile, IProfiles } from '../../infrastructure/DTO/profile/profile.dto';

class ProfileServices extends ServicesBase {
    static async getProfilesByCompanyId(params: IFetchArgs<IArgsProfile>): Promise<IProfiles> {
        let url: string = `/home/companies/${params.args?.company_id}/profiles/list/`;

        return await this.services(
            'POST',
            url,
            JSON.stringify({
                search_text: params.args?.search_text,
                limit: params.args?.limit,
                offset: params.args?.offset,
            }),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async createProfile(params: IFetchBody<IProfile, IArgsProfile>): Promise<IProfile> {
        return await this.services('POST', `/home/companies/${params.args?.company_id}/profiles`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async getProfileById(params: IFetchArgs<IArgsProfile>): Promise<IProfile> {
        return await this.services('GET', `/home/companies/${params.args?.company_id}/profiles/${params.args?.profile_id}`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async updateProfile(params: IFetchBody<IProfile, IArgsProfile>): Promise<Partial<IProfile>> {
        return await this.services(
            'PUT',
            `/home/companies/${params.args?.company_id}/profiles/${params.args?.profile_id}`,
            JSON.stringify(params.body),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }
}

export default ProfileServices;
