import React from 'react';
import { Chip, Grid } from '@mui/material';
import { classes } from '../list/style';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../store';
import { inspectionFilterStore } from '../../../../store/inspection/inspection-list';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { IBusinessUnit } from '../../../../infrastructure/DTO/bussiness-unit/business-units.dto';
import { ICompany } from '../../../../infrastructure/DTO/company/company.dto';
import { Filter } from '../../../../enums/InspectionFilter';

const FilterChip: React.FC = () => {
    const [chips, setChips] = React.useState<Array<{ filter: string; value: string }>>([]);
    const { companies, statusCompanies } = useSelector((state: AppState) => state.companies);
    const businessUnits = useSelector((state: AppState) => state.businessUnits);
    const { inspectionFilter } = useSelector((state: AppState) => state.inspectionList);
    const [companiesState, setCompaniesState] = React.useState<Array<ICompany>>([]);
    const [businessUnitsState, setBusinessUnitsState] = React.useState<Array<IBusinessUnit>>([]);
    const dispatch: AppDispatch = useDispatch();

    /**
     * Get All Companies
     */
    React.useEffect(() => {
        if (statusCompanies === StatusRedux.Succeeded) {
            setCompaniesState(companies.companies);
        }
    }, [statusCompanies]);

    /**
     * Get All Business Units
     */
    React.useEffect(() => {
        if (businessUnits.status === StatusRedux.Succeeded) {
            setBusinessUnitsState(businessUnits.businessUnits.business_units);
        }
    }, [businessUnits.status]);

    /**
     * Set Chips
     */
    React.useEffect(() => {
        let arr: Array<{ filter: string; value: any }> = [];

        Object.entries(inspectionFilter).map(([key, value]) => {
            switch (key) {
                case Filter.INSPECTION_ID:
                case Filter.CLIENT_NAME:
                case Filter.CLIENT_PID:
                case Filter.LPN:
                case Filter.VIN:
                    let v: string | null = value as string;
                    if (v !== '' && v !== null) {
                        arr.push({ filter: key, value: v });
                    }
                    break;
                case Filter.FROM_DATE:
                case Filter.TO_DATE:
                    if (value !== null) {
                        arr.push({ filter: key, value: `${moment(value as Date).format('DD/MM/YYYY HH:mm')}` });
                    }
                    break;
                case Filter.COMPANY_ID:
                    if (companiesState.length > 0) {
                        let v: string = value as string;
                        companiesState.forEach((company) => {
                            if (company.company_id === v) {
                                arr.push({ filter: key, value: company.name as string });
                            }
                        });
                    }
                    break;
                case Filter.BUSINESS_UNIT_ID:
                    if (businessUnitsState.length > 0) {
                        let v: string = value as string;
                        businessUnitsState.forEach((bu) => {
                            if (bu.business_unit_id === v) {
                                arr.push({ filter: key, value: bu.name as string });
                            }
                        });
                    }
                    break;
                case Filter.RESULT_NOT_SENT:
                    let res: boolean = value as boolean;
                    if (res) {
                        arr.push({ filter: key, value: 'RESULT_NOT_SENT' });
                    }
                    break;
                case Filter.STATUS:
                case Filter.INSPECTION_TYPE:
                case Filter.CLOSURE_REASON:
                    let typeArr: Array<string> = value as Array<string>;
                    if (typeArr.length !== 0) {
                        typeArr.forEach((type) => {
                            arr.push({ filter: key, value: type });
                        });
                    }
                    break;
            }
        });

        let dates = { filter: 'dates', value: '' };

        arr.forEach((item) => {
            if (item.filter === 'from_date') {
                dates.value = `${item.value} - `;
            }

            if (item.filter === 'to_date') {
                dates.value += `${item.value}`;
            }
        });

        let res = arr.filter((i) => i.filter !== 'from_date' && i.filter !== 'to_date');
        if (dates.value.length > 0) {
            res.push(dates);
        }

        setChips(res);
    }, [inspectionFilter]);

    /**
     * Remove Chips and Filter
     * @param item
     */
    const handleDelete = (item: { filter: string; value: string }) => () => {
        Object.entries(inspectionFilter).map(([key, value]) => {
            switch (item.filter) {
                case Filter.INSPECTION_ID:
                case Filter.CLIENT_NAME:
                case Filter.CLIENT_PID:
                case Filter.LPN:
                case Filter.VIN:
                    setChips(chips.filter((i) => i.filter !== item.filter));
                    dispatch(inspectionFilterStore({ ...inspectionFilter, [item.filter]: '' }));
                    break;
                case Filter.COMPANY_ID:
                    setChips(chips.filter((i) => i.filter !== item.filter));
                    dispatch(inspectionFilterStore({ ...inspectionFilter, [item.filter]: null }));
                    break;
                case Filter.BUSINESS_UNIT_ID:
                    setChips(chips.filter((i) => i.filter !== item.filter));
                    dispatch(inspectionFilterStore({ ...inspectionFilter, [item.filter]: null }));
                    break;
                case Filter.RESULT_NOT_SENT:
                    setChips(chips.filter((i) => i.filter !== item.filter));
                    dispatch(inspectionFilterStore({ ...inspectionFilter, call_back_response_result_not_sent: null }));
                    break;
                case Filter.DATES:
                    setChips(chips.filter((i) => i.filter !== item.filter));
                    dispatch(inspectionFilterStore({ ...inspectionFilter, from_date: null, to_date: null }));
                    break;
                case Filter.STATUS:
                    setChips(chips.filter((i) => i.filter !== item.filter));
                    dispatch(
                        inspectionFilterStore({
                            ...inspectionFilter,
                            inspection_statuses: inspectionFilter.inspection_statuses?.filter((s: string) => s !== item.value),
                        })
                    );
                    break;
                case Filter.INSPECTION_TYPE:
                    setChips(chips.filter((i) => i.filter !== item.filter));
                    dispatch(
                        inspectionFilterStore({
                            ...inspectionFilter,
                            inspection_types: inspectionFilter.inspection_types?.filter((s: string) => s !== item.value),
                        })
                    );
                    break;
                case Filter.CLOSURE_REASON:
                    setChips(chips.filter((i) => i.filter !== item.filter));
                    dispatch(
                        inspectionFilterStore({
                            ...inspectionFilter,
                            closure_reason: inspectionFilter.closure_reason?.filter((s: string) => s !== item.value),
                        })
                    );
                    break;
            }
        });
    };

    return (
        <Grid spacing={2} container direction='row' justifyContent='flex-start' alignItems='center'>
            <Grid sx={chips.length > 0 ? { marginBottom: '7px' } : { marginBottom: '30px' }} item xs={12}>
                {chips.length > 0 &&
                    chips.map((item, index) => {
                        if (item.filter === 'company_id') {
                            return (
                                <Chip
                                    key={index}
                                    sx={classes.filterChip}
                                    size='small'
                                    label={item.value.replace(/_/g, ' ')}
                                    clickable
                                    disabled={!!chips.find((ch) => ch?.filter === 'business_unit_id')}
                                    color='primary'
                                    onDelete={handleDelete(item)}
                                    deleteIcon={<CancelIcon />}
                                />
                            );
                        }
                        return (
                            <Chip
                                key={index}
                                sx={classes.filterChip}
                                size='small'
                                label={item.value.replace(/_/g, ' ')}
                                clickable
                                color='primary'
                                onDelete={handleDelete(item)}
                                deleteIcon={<CancelIcon />}
                            />
                        );
                    })}
            </Grid>
        </Grid>
    );
};

export default FilterChip;
