import { DAMAGE_NAME } from '../../enums/Damage';
import React from 'react';
import { IThreshold } from '../interface/IThreshold';
import { Grid, TextField } from '@mui/material';

const Threshold: React.FC<IThreshold> = (props: IThreshold) => {
    const MIN = 0.001;
    const MAX = 1;
    const STEP = 0.001;

    const inputPropsPerDamageType = {
        step: STEP,
        min: MIN,
        max: MAX,
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value, name } = event.currentTarget;

        if (!isNaN(parseFloat(value))) {
            if (parseFloat(value) >= MAX) value = String(MAX);
            if (parseFloat(value) <= MIN) value = parseFloat(String(MIN)).toFixed(3);

            event.currentTarget.value = value;

            props.handleChangeInput({
                name: parseFloat(name),
                value: parseFloat(value),
            });
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        props.handleApplyThreshold();
    };

    return (
        <form id='thresholds-form' onSubmit={handleSubmit} autoComplete='off'>
            <Grid container spacing={3}>
                {props.thresholds_per_damage_type
                    ?.slice(0)
                    .sort((a, b) =>
                        DAMAGE_NAME[a.damage_type].toLowerCase() < DAMAGE_NAME[b.damage_type].toLowerCase()
                            ? -1
                            : DAMAGE_NAME[a.damage_type].toLowerCase() > DAMAGE_NAME[b.damage_type].toLowerCase()
                            ? 1
                            : 0
                    )
                    .map((type, index) => (
                        <Grid
                            item
                            key={index}
                            xs={props.thresholds_per_size.xs}
                            sm={props.thresholds_per_size.sm}
                            md={props.thresholds_per_size.md}
                            lg={props.thresholds_per_size.lg}
                            xl={props.thresholds_per_size.xl}
                        >
                            <TextField
                                inputProps={inputPropsPerDamageType}
                                fullWidth
                                name={String(type.damage_type)}
                                id='outlined-basic'
                                type='number'
                                label={DAMAGE_NAME[type.damage_type]}
                                variant='outlined'
                                value={type.threshold}
                                onChange={handleChangeInput}
                            />
                        </Grid>
                    ))}
            </Grid>
        </form>
    );
};

export default Threshold;
