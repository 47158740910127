import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { classes } from '../../style';
import Grid from '@mui/material/Grid';
import { Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import {
    clearStoreEffectiveSettings,
    clearStoreStatusUpdatedEffectiveSettings,
    fetchGetEffectiveSettings,
    fetchUpdateEffectiveSettings,
} from '../../../../../store/effective-settings/effective-settings';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { Error } from '../../../../error';
import { useSnackbar } from 'notistack';
import { showPopup } from '../../../../../store/events/popup';
import {
    IArgsEffectiveSettings,
    IEffectiveSettings,
    IUpdateEffectiveSettings,
    SettingAllowedValuesType,
} from '../../../../../infrastructure/DTO/effective-settings/effective-settings.dto';
import { ICompany } from '../../../../../infrastructure/DTO/company/company.dto';
import { IUserManagementProvider, UserManagementContext } from '../../../../../context/UserManagementProvider';
import SettingAllowedValuesPopup from './popup-values';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface IEffectiveSettingsStep {
    company: ICompany | null;
    handleNext: Function;
    profileName?: string;
}

const EffectiveSettingsStep: React.FC<IEffectiveSettingsStep> = (props: IEffectiveSettingsStep) => {
    const { enqueueSnackbar } = useSnackbar();
    const { token } = useContext(CustomAuthContext);
    const [popupValues, setPopupValues] = React.useState<{ open: boolean; settings: IEffectiveSettings | null }>({
        open: false,
        settings: null,
    });
    const { profile } = useContext<IUserManagementProvider>(UserManagementContext);
    const dispatch: AppDispatch = useDispatch();
    const { effectiveSettings, status, statusUpdate, errorUpdate, error } = useSelector((state: AppState) => state.effectiveSettings);
    const [effectiveSettingsState, setEffectiveSettingsState] = React.useState<Array<IEffectiveSettings>>([]);

    React.useEffect(() => {
        let args: IArgsEffectiveSettings = {
            company_id: props.company?.company_id,
            profile_id: profile?.profile_id,
        };

        dispatch(fetchGetEffectiveSettings({ token, args }));

        return () => {
            dispatch(clearStoreEffectiveSettings());
            setEffectiveSettingsState([]);
        };
    }, []);

    const handleSubmitEffectiveSettings = (event: any) => {
        event.preventDefault();

        const args: IArgsEffectiveSettings = {
            company_id: props.company?.company_id,
            profile_id: profile?.profile_id,
        };

        const body: IUpdateEffectiveSettings = {
            effective_settings: [],
        };

        effectiveSettingsState.forEach((item) => {
            body.effective_settings.push({
                value: item.value,
                setting_id: item.setting.setting_id,
            });
        });

        setEffectiveSettingsState(effectiveSettingsState);

        dispatch(showPopup(false));
        dispatch(fetchUpdateEffectiveSettings({ token, args, body }));

        props.handleNext();
    };

    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            setEffectiveSettingsState(effectiveSettings);
        }
    }, [status]);

    React.useEffect(() => {
        setEffectiveSettingsState(effectiveSettings);
    }, [effectiveSettings]);

    const handleChangeInputSelect = (event: any) => {
        const { name, value } = event.target;
        let index = effectiveSettingsState.findIndex((e) => e.effective_setting_id === name);

        let res: Array<IEffectiveSettings> = [
            ...effectiveSettingsState.slice(0, index),
            {
                ...effectiveSettingsState[index],
                value: value.trim(),
            },
            ...effectiveSettingsState.slice(index + 1),
        ];

        setEffectiveSettingsState(res);
    };

    const handleChangeAllowedValues = (value: IEffectiveSettings) => {
        let index = effectiveSettingsState.findIndex((e) => e.effective_setting_id === value.effective_setting_id);

        let res: Array<IEffectiveSettings> = [
            ...effectiveSettingsState.slice(0, index),
            {
                ...value,
            },
            ...effectiveSettingsState.slice(index + 1),
        ];

        setEffectiveSettingsState(res);
    };

    React.useEffect(() => {
        if (statusUpdate === StatusRedux.Succeeded) {
            enqueueSnackbar('Updated', {
                variant: 'success',
            });
        }

        if (statusUpdate === StatusRedux.Failed) {
            enqueueSnackbar('Some of the fields have mistakes. Please check the form', {
                variant: 'error',
            });
            errorSettings();
        }

        dispatch(clearStoreStatusUpdatedEffectiveSettings());
    }, [statusUpdate]);

    const handlePopup = (settings: IEffectiveSettings) => () => {
        setPopupValues({
            open: !popupValues.open,
            settings: settings,
        });
    };

    const handleClose = () => {
        setPopupValues({
            open: !popupValues.open,
            settings: null,
        });
    };

    const errorSettings = () => {
        if (errorUpdate.errors) {
            let res: Array<IEffectiveSettings> = [];

            effectiveSettingsState.forEach((s, i) => {
                res.push({
                    ...s,
                    error: errorUpdate.errors.find((e) => s.setting.setting_id === e.setting_id)?.message as string,
                });
            });

            setEffectiveSettingsState(res);
        }
    };

    return (
        <>
            <SettingAllowedValuesPopup popup={popupValues} handleClose={handleClose} handleChangeAllowedValues={handleChangeAllowedValues} />
            <Box sx={classes.bodyPopup}>
                {(() => {
                    switch (status) {
                        case StatusRedux.Loading:
                            return (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 'calc(100% - 150px)',
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CircularProgress color='primary' />
                                </Box>
                            );
                        case StatusRedux.Succeeded:
                            return (
                                <form id='effective-settings' autoComplete='off' onSubmit={handleSubmitEffectiveSettings}>
                                    <Grid container spacing={3} justifyContent='flex-end'>
                                        <Grid item xs={12}>
                                            <TableContainer sx={{ minHeight: '600px' }} component={Box}>
                                                <Table aria-label='simple table'>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={classes.cell}>Feature</TableCell>
                                                            <TableCell sx={classes.cell}>Value</TableCell>
                                                            <TableCell sx={classes.cell}>Description</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {effectiveSettingsState.length > 0 &&
                                                            effectiveSettingsState
                                                                .slice(0)
                                                                .sort((a, b) =>
                                                                    a.setting.name.toLowerCase() < b.setting.name.toLowerCase()
                                                                        ? -1
                                                                        : a.setting.name.toLowerCase() > b.setting.name.toLowerCase()
                                                                        ? 1
                                                                        : 0
                                                                )
                                                                .map((item, index) => {
                                                                    return (
                                                                        <TableRow
                                                                            sx={
                                                                                item.setting.is_active
                                                                                    ? { background: 'inherit' }
                                                                                    : { background: 'rgba(0, 0, 0, 0.05)' }
                                                                            }
                                                                            key={index}
                                                                            hover
                                                                            role='checkbox'
                                                                            tabIndex={-1}
                                                                        >
                                                                            <TableCell sx={classes.cell}>{item.setting.name}</TableCell>
                                                                            <TableCell sx={classes.cell}>
                                                                                {(
                                                                                    item.setting
                                                                                        ?.setting_allowed_values as Array<SettingAllowedValuesType>
                                                                                ).length > 0 ? (
                                                                                    <Button onClick={handlePopup(item)}>
                                                                                        <span style={{ borderBottom: '1px solid #000133' }}>
                                                                                            {item.value}
                                                                                        </span>
                                                                                    </Button>
                                                                                ) : (
                                                                                    <TextField
                                                                                        size='small'
                                                                                        disabled={!item.setting.is_active}
                                                                                        sx={{ width: '250px' }}
                                                                                        name={item.effective_setting_id}
                                                                                        id='name'
                                                                                        type='text'
                                                                                        label={item.setting.name}
                                                                                        variant='outlined'
                                                                                        value={item.value}
                                                                                        onChange={handleChangeInputSelect}
                                                                                        error={Boolean(item.error)}
                                                                                        helperText={item.error}
                                                                                    />
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell sx={classes.cell}>
                                                                                <Typography variant='body2' display='block' gutterBottom>
                                                                                    {item.setting.description}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        case StatusRedux.Failed:
                            return <Error msg={error.errors[0].message} />;
                    }
                })()}
            </Box>
        </>
    );
};

export default EffectiveSettingsStep;
