import React from 'react';
import { IMeasurements } from '../../../../../../infrastructure/DTO/damage-lab/anomaly-measurements.dto';
import { IBoundingBox } from '../../../../../../infrastructure/DTO/damage-lab/damage-lab.dto';
import { DAMAGE_COLOR } from '../../../../../../enums/Damage';
import { Group, Line } from 'react-konva';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import { IDamageLabLayout } from '../IDamageLabLayout';
import DamageLabLayoutContext, { IDamageLabLayoutContext } from '../../../../../../context/DamageLabLayoutContext';

interface IMeasurementLayoutComponent extends IDamageLabLayout {}

const MeasurementLayout: React.FC<IMeasurementLayoutComponent> = (props: IMeasurementLayoutComponent) => {
    const {
        scale,
        checkedMeasurement,
        points,
        panelContours,
        zoomPerArea,
        quadrangle,
        measurementPoints,
        setPoints,
        setPanelContours,
        setCheckedMeasurement,
        setMeasurements,
        setMeasurementPoints,
        setQuadrangle,
        setMeasurementCurrentData,
        setZoomPerArea,
    } = React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);
    const { imageOverView } = useSelector((state: AppState) => state.imageOverView);

    React.useEffect(() => {
        if (props.view) {
            const newChecked = [...checkedMeasurement];
            const obj = new Map();
            const objMeasurements = new Map<string, { measurement: Array<IMeasurements>; bounding_box: IBoundingBox }>(
                new Map<string, { measurement: Array<IMeasurements>; bounding_box: IBoundingBox }>()
            );

            if (imageOverView.anomaly_measurement.anomaly_measurements.length > 0) {
                if (imageOverView.detected_damages) {
                    imageOverView.detected_damages.forEach((damage) => {
                        let point: Array<number> = [];
                        let indexAnomaly = imageOverView.anomaly_measurement.anomaly_measurements.findIndex(
                            (anomaly) => anomaly.damage_id === damage.damage_id
                        );
                        let indexDamage = imageOverView.detected_damages.findIndex((anomaly) => anomaly.damage_id === damage.damage_id);

                        newChecked.push(damage.damage_id);

                        damage.segmentation.contours[0].forEach((p) => {
                            point.push(Number(p.x * scale), Number(p.y * scale));
                        });

                        obj.set(damage.damage_id, { pointsArr: point, color: DAMAGE_COLOR[damage.damage_type] });
                        setPoints(obj);

                        if (indexAnomaly !== -1) {
                            objMeasurements.set(damage.damage_id, {
                                measurement: imageOverView.anomaly_measurement.anomaly_measurements[indexAnomaly].measurements,
                                bounding_box: imageOverView.detected_damages[indexDamage].segmentation.bounding_box,
                            });
                        }
                    });
                }
            }

            setCheckedMeasurement(newChecked);
            setMeasurements(objMeasurements);
        } else {
            setPoints(new Map());
            setCheckedMeasurement([]);
            setMeasurements(new Map());
            setMeasurementCurrentData({
                minimalEnclosingBox: null,
                result: null,
                threeBoundingBox: null,
            });
            setQuadrangle([]);
            setMeasurementPoints([]);
            setPanelContours([]);
            setZoomPerArea(undefined);
        }
    }, [props.view, scale]);

    return (
        <React.Fragment>
            {!zoomPerArea && (
                <>
                    {Array.from(points.values()).map((line, i) => (
                        <Group key={i}>
                            <Line
                                key={i}
                                points={line.pointsArr}
                                stroke={line.color}
                                strokeWidth={1.5}
                                closed={true}
                                lineCap='square'
                                globalCompositeOperation='source-over'
                            />
                        </Group>
                    ))}
                </>
            )}
            {zoomPerArea && (
                <>
                    {
                        <>
                            <Line
                                key={1}
                                points={measurementPoints}
                                stroke='#ffffff'
                                fill='rgb(255 0 0 / 45%)'
                                closed={true}
                                strokeWidth={0.3}
                                globalCompositeOperation='source-over'
                            />
                            {panelContours.length > 0 &&
                                panelContours.map((point, index) => (
                                    <Line key={index} points={point} stroke='red' strokeWidth={0.5} globalCompositeOperation='source-over' />
                                ))}
                            {quadrangle.length > 0 && (
                                <Line
                                    key={3}
                                    points={quadrangle}
                                    stroke='yellow'
                                    closed={true}
                                    strokeWidth={0.5}
                                    globalCompositeOperation='source-over'
                                />
                            )}
                        </>
                    }
                </>
            )}
        </React.Fragment>
    );
};
export default MeasurementLayout;
