import React from 'react';
import { Divider, Grid, List, ListItem, ListItemText, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store';
import { classes } from './style';

const DetectedModel: React.FC = () => {
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);
    const { imageMain } = useSelector((state: AppState) => state.damageLabImage);

    return (
        <Grid container spacing={5} direction='row'>
            {damageLabData.vehicle_model_detection_result !== null ? (
                <>
                    <Grid sx={classes.anim} item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Box sx={classes.pic}>
                            <img src={imageMain.image.src} alt={damageLabData.vehicle_model_detection_result.model} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <List component='nav' aria-label='mailbox folders'>
                            <ListItem button divider>
                                <ListItemText primary={`ID: ${damageLabData.vehicle_model_detection_result.vehicle_model_id}`} />
                            </ListItem>
                            <Divider />
                            <ListItem button divider>
                                <ListItemText primary={`Make: ${damageLabData.vehicle_model_detection_result.make}`} />
                            </ListItem>
                            <Divider />
                            <ListItem button divider>
                                <ListItemText primary={`Model: ${damageLabData.vehicle_model_detection_result.model}`} />
                            </ListItem>
                            <Divider />
                            <ListItem button divider>
                                <ListItemText primary={`Trim: ${damageLabData.vehicle_model_detection_result.bodystyle}`} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primary={`Year: ${damageLabData.vehicle_model_detection_result.year}`} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                {damageLabData.color_detection_result !== null && (
                                    <ListItemText
                                        primary={`Color: ${damageLabData.color_detection_result.color_name}`}
                                        secondary={
                                            <span style={{ borderLeft: `100px solid rgba(${damageLabData.color_detection_result.color_value})` }} />
                                        }
                                    />
                                )}
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={`Detection Score: ${(damageLabData.vehicle_model_detection_result.score * 100).toFixed(2)} %`}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </>
            ) : (
                <Box sx={classes.emptyDiv}>Not Found</Box>
            )}
        </Grid>
    );
};

export default DetectedModel;
