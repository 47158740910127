import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AppDispatch, AppState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Chip, Paper } from '@mui/material';
import { clearStore, fetchUserDeactivate, fetchUserActive, fetchUserUpdate } from '../../../../store/users/users';
import { UserPermission } from '../../../../config';
import { classes } from './style';
import { clearStateCreateKey, clearStatGetKey } from '../../../../store/api-key/generate-api-key';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { RoleUser } from './role-user';
import { ChangePassword } from './change-password';
import { BasicDialog } from '../../../../common/ui-components/Dialog';
import useCheckInputEmail from '../../../../hooks/validation/use-input-email';
import useCheckInputText from '../../../../hooks/validation/use-input-text';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { TabPanel, TabsProps } from '../../../../common/ui-components/Tab';
import { IUser, IUserUpdate } from '../../../../infrastructure/DTO/users/user.dto';
import { IArgsRouteUser } from '../../../../infrastructure/DTO/users/user-view.dto';
import { IBusinessUnit } from '../../../../infrastructure/DTO/bussiness-unit/business-units.dto';
import SaveIcon from '@mui/icons-material/Save';
import BusinessUnitSelect from '../../../../common/ui-components/BusinessUnitSelect';
import { IInput } from '../../../../hooks/validation/IInput';
import errorParseMessage from '../../../../config/error-parse';

interface IEditUserComponent {
    open: boolean;
    handleClose: Function;
    data: IUser | null;
}

interface Fields {
    name: IInput;
}

const initStateDataUser: IUser = {
    business_unit: undefined,
    company: undefined,
    email: '',
    is_active: false,
    name: '',
    password: '',
    roles: undefined,
    user_id: '',
};

const EditUser: React.FC<IEditUserComponent> = (props: IEditUserComponent) => {
    const { checkPermission, parseToken, token } = useContext(CustomAuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();

    const { inputEmail, checkValueEmail } = useCheckInputEmail(props.data?.user_id);
    const { inputsText, message, checkValueText, clearInputState } = useCheckInputText<Fields>({
        name: {
            required: true,
        },
    });

    const [valueTab, setValueTab] = React.useState<number>(0);
    const [businessUnitSelect, setBusinessUnitSelect] = React.useState<IBusinessUnit | null>(null);

    const { userDataUpdateStatus, userDataUpdateError, statusActive, statusDeactivate, error } = useSelector((state: AppState) => state.users);
    const [dataUser, setDataUser] = React.useState<IUser>(initStateDataUser);

    const handleClosePopup = () => {
        dispatch(clearStatGetKey());
        props.handleClose();
        clearInputState(true);
        setValueTab(0);
        setDataUser(initStateDataUser);
    };

    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;
        setDataUser({
            ...dataUser,
            [name]: value,
        });
    };

    const handleChangeInputIsActive = (event: any) => {
        let args: IArgsRouteUser = {
            user_id: props.data?.user_id,
            company_id: props.data?.company?.company_id,
        };

        if (Number(event.target.value)) {
            dispatch(fetchUserActive({ token, args }));
        } else {
            dispatch(fetchUserDeactivate({ token, args }));
        }
    };

    React.useEffect(() => {
        if (statusActive === StatusRedux.Succeeded) {
            enqueueSnackbar('User Activated', {
                variant: 'success',
            });

            setDataUser({ ...dataUser, is_active: 1 });
            dispatch(clearStateCreateKey());
        }

        if (statusActive === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(error), {
                variant: 'error',
            });
            dispatch(clearStateCreateKey());
        }
    }, [statusActive]);

    React.useEffect(() => {
        if (statusDeactivate === StatusRedux.Succeeded) {
            enqueueSnackbar('User Deactivated', {
                variant: 'success',
            });

            setDataUser({ ...dataUser, is_active: 0 });
            dispatch(clearStateCreateKey());
        }

        if (statusDeactivate === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(error), {
                variant: 'error',
            });
            dispatch(clearStateCreateKey());
        }
    }, [statusDeactivate]);

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const body: Partial<IUserUpdate> = {
            name: dataUser?.name as string,
            email: dataUser?.email?.toLowerCase() as string,
            business_unit_id: businessUnitSelect ? (businessUnitSelect?.business_unit_id as string) : '',
        };

        const args: IArgsRouteUser = {
            company_id: dataUser?.company?.company_id,
            user_id: dataUser?.user_id,
        };

        dispatch(
            fetchUserUpdate({
                token,
                body,
                args,
            })
        );
    };

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValueTab(newValue);
    };

    React.useEffect(() => {
        if (props.open) {
            setDataUser({
                ...props.data,
                is_active: props.data?.is_active ? 1 : 0,
            });

            setBusinessUnitSelect(props.data?.business_unit as IBusinessUnit);
        }
    }, [props.open]);

    React.useEffect(() => {
        if (userDataUpdateStatus === StatusRedux.Succeeded) {
            enqueueSnackbar('User Updated', {
                variant: 'success',
            });
            props.handleClose();
        }

        if (userDataUpdateStatus === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(userDataUpdateError), {
                variant: 'error',
            });
        }

        dispatch(clearStore());
    }, [userDataUpdateStatus]);

    const _changePassword = (company_id: string | undefined): boolean => {
        if (company_id) {
            if (!checkPermission(UserPermission.ADMIN)) {
                return true;
            }

            if (!checkPermission(UserPermission.USER_MANAGEMENT)) {
                if (parseToken().company === company_id) {
                    return true;
                }
            }

            if (!checkPermission(UserPermission.USER_VIEW)) {
                if (parseToken().sub === props.data?.user_id) {
                    return true;
                }
            }
        }

        return false;
    };

    return (
        <BasicDialog
            handlePopup={handleClosePopup}
            open={props.open}
            title='User Information'
            width='md'
            tabs={
                <Tabs value={valueTab} onChange={handleChangeTab} aria-label='tabs'>
                    <Tab label='Information' {...TabsProps(0)} />
                    <Tab label='Role' {...TabsProps(1)} />
                    {!checkPermission(UserPermission.USER_MANAGEMENT) && <Tab label='Change Password' {...TabsProps(2)} />}
                </Tabs>
            }
            buttons={
                <Grid container spacing={4} justifyContent='flex-end'>
                    {valueTab === 0 && !checkPermission(UserPermission.USER_MANAGEMENT) && (
                        <Grid item>
                            <Button
                                size='small'
                                form='edit-user'
                                disabled={inputEmail.error || inputsText?.name || !dataUser.is_active}
                                type='submit'
                                variant='contained'
                                color='secondary'
                                endIcon={<SaveIcon />}
                            >
                                Save
                            </Button>
                        </Grid>
                    )}
                    {valueTab === 2 && !checkPermission(UserPermission.USER_MANAGEMENT) && (
                        <Grid item>
                            <Button
                                form='reset-password'
                                size='small'
                                disabled={!_changePassword(props.data?.company?.company_id)}
                                type='submit'
                                variant='contained'
                                color='secondary'
                                endIcon={<SaveIcon />}
                            >
                                Save
                            </Button>
                        </Grid>
                    )}
                </Grid>
            }
        >
            <TabPanel value={valueTab} index={0}>
                <form id='edit-user' autoComplete='off' onSubmit={handleSubmit}>
                    <Paper sx={classes.paper} elevation={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Box sx={classes.headerBlockInput}>Contact Information</Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={classes.activeIcon}>
                                    {dataUser?.is_active ? (
                                        <Chip label='Enabled' variant='outlined' color='primary' />
                                    ) : (
                                        <Chip label='Disabled' variant='outlined' style={{ color: 'red', borderColor: 'red' }} />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name='name'
                                    required={true}
                                    type='text'
                                    label='Name'
                                    variant='outlined'
                                    value={dataUser?.name?.trimStart() ?? ''}
                                    onChange={handleChangeInput}
                                    onKeyUp={checkValueText}
                                    helperText={message?.name}
                                    error={inputsText?.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={inputEmail.error}
                                    helperText={inputEmail.error ? inputEmail.errorMsg : null}
                                    fullWidth
                                    name='email'
                                    required={true}
                                    id='outlined-basic'
                                    type='email'
                                    label='E-mail'
                                    variant='outlined'
                                    value={dataUser?.email ?? ''}
                                    onChange={handleChangeInput}
                                    onKeyUp={checkValueEmail}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl disabled={checkPermission(UserPermission.USER_MANAGEMENT)} fullWidth variant='outlined'>
                                    <InputLabel id='active'>Status</InputLabel>
                                    <Select
                                        labelId='active'
                                        id='active'
                                        name='is_active'
                                        defaultValue=''
                                        value={dataUser?.is_active ? '1' : '0'}
                                        onChange={handleChangeInputIsActive}
                                        label='Active'
                                    >
                                        <MenuItem value={'1'}>Enabled</MenuItem>
                                        <MenuItem value={'0'}>Disabled</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <BusinessUnitSelect
                                    variant='outlined'
                                    company={dataUser.company}
                                    setData={setBusinessUnitSelect}
                                    data={businessUnitSelect}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper sx={classes.paper} elevation={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box sx={classes.headerBlockInput}>API Information</Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    name='UserId'
                                    id='outlined-basic'
                                    type='text'
                                    label='User ID'
                                    variant='outlined'
                                    value={dataUser?.user_id ?? ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    name='CompanyId'
                                    id='outlined-basic'
                                    type='text'
                                    label='Company ID'
                                    variant='outlined'
                                    value={dataUser?.company?.company_id ?? ''}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </form>
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
                <RoleUser user={dataUser} />
            </TabPanel>
            <TabPanel value={valueTab} index={2}>
                <ChangePassword company_id={props.data?.company?.company_id} user_id={props.data?.user_id} close={handleClosePopup} />
            </TabPanel>
        </BasicDialog>
    );
};

export default EditUser;
