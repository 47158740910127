import { ServicesBase } from '../ServicesBase';
import { IFetchArgs, IFetchBody } from '../../store/IFetch';
import {
    IArgsEffectiveSettings,
    IEffectiveSettings,
    IUpdateEffectiveSettings,
} from '../../infrastructure/DTO/effective-settings/effective-settings.dto';

class EffectiveSettingsServices extends ServicesBase {
    static async getEffectiveSettings(params: IFetchArgs<IArgsEffectiveSettings>): Promise<Array<IEffectiveSettings>> {
        return await this.services('GET', `/home/companies/${params.args?.company_id}/profiles/${params.args.profile_id}/effective_settings`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async updateEffectiveSettings(params: IFetchBody<IUpdateEffectiveSettings, IArgsEffectiveSettings>): Promise<Array<IEffectiveSettings>> {
        return await this.services(
            'PUT',
            `/home/companies/${params.args?.company_id}/profiles/${params.args?.profile_id}/effective_settings`,
            JSON.stringify(params.body),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }
}

export default EffectiveSettingsServices;
