import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import CompanyServices from '../../services/companies/company';
import { IFetchBody } from '../IFetch';
import { IArgsCompanyReport, ICompanyReport } from '../../infrastructure/DTO/company/company-report.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetCompaniesReport = createAsyncThunk<ICompanyReport, IFetchBody<IArgsCompanyReport>>(
    'company/report',
    async (params: IFetchBody<IArgsCompanyReport>) => {
        return await CompanyServices.getReportsByCompany(params);
    }
);

export const getCompaniesReportSlice = createSlice({
    name: 'company',
    initialState: {
        companiesReport: {} as ICompanyReport,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        ClearStateCompaniesReport: (state) => {
            state.companiesReport = {} as ICompanyReport;
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetCompaniesReport.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetCompaniesReport.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.companiesReport = action.payload;
            state.error = {} as IErrorResponse;
        });
        builder.addCase(fetchGetCompaniesReport.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { ClearStateCompaniesReport } = getCompaniesReportSlice.actions;
export default getCompaniesReportSlice;
