import React, { useRef } from 'react';
import { Grid } from '@mui/material';
import { Group, Image as Pic, Layer, Rect } from 'react-konva';
import { classes } from './style';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../store';
import Konva from '../../../../../common/ui-components/Konva';

const PrincipalCar: React.FC = () => {
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);
    const { imageMain } = useSelector((state: AppState) => state.damageLabImage);
    const refDiv = useRef<HTMLDivElement | null>(null);
    const [size, setSize] = React.useState<{ width: number; height: number }>({ width: 1, height: 1 });
    const [coordinates, setCoordinates] = React.useState({ x: 1, y: 1 });
    const [boundingBox, setBoundingBox] = React.useState<{ x: number; y: number; width: number; height: number }>({
        x: 1,
        y: 1,
        width: 1,
        height: 1,
    });

    React.useEffect(() => {
        if (refDiv.current !== null) {
            setSize({
                width: refDiv.current.clientWidth,
                height: refDiv.current.clientHeight,
            });
        }
    }, []);

    React.useEffect(() => {
        let scale = Math.min(size.width / imageMain.width, size.height / imageMain.height);

        setCoordinates({
            x: size.width / 2 - (imageMain.width * scale) / 2,
            y: 0,
        });

        if (damageLabData.vehicle_segmentation !== null) {
            setBoundingBox({
                x: damageLabData.vehicle_segmentation.bounding_box.x * scale,
                y: damageLabData.vehicle_segmentation.bounding_box.y * scale,
                width: damageLabData.vehicle_segmentation.bounding_box.width * scale,
                height: damageLabData.vehicle_segmentation.bounding_box.height * scale,
            });
        }
    }, [size.width, size.height]);

    const handleMove = (e: any) => {
        setCoordinates({
            x: e.target.x(),
            y: e.target.y(),
        });
    };

    return (
        <Grid container spacing={1} direction='row' justifyContent='center'>
            <Grid sx={classes.anim} item xs={12} sm={12} md={10} lg={10} xl={10}>
                <Konva wheel={true} konvaRef={refDiv} style={classes.stage} width={size.width} height={size.height}>
                    <Layer>
                        <Group x={coordinates.x} y={coordinates.y} draggable onDragMove={handleMove} onDragEnd={handleMove}>
                            <Pic
                                width={imageMain.width * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                height={imageMain.height * Math.min(size.width / imageMain.width, size.height / imageMain.height)}
                                image={imageMain.image}
                            />
                            <Rect
                                x={boundingBox.x}
                                y={boundingBox.y}
                                width={boundingBox.width}
                                height={boundingBox.height}
                                stroke='green'
                                strokeWidth={3.5}
                            />
                        </Group>
                    </Layer>
                </Konva>
            </Grid>
        </Grid>
    );
};

export default PrincipalCar;
