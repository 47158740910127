import React, { useContext } from 'react';
import { Autocomplete } from '@mui/material';
import { IArgsCompany, ICompany } from '../../infrastructure/DTO/company/company.dto';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../store';
import { ICompaniesSelect } from '../interface/ICompaniesSelect';
import { StatusRedux } from '../../enums/StatusRedux';
import { UserPermission } from '../../config';
import { ClearStateCompanies, fetchGetAllCompanies } from '../../store/company/companies';
import { CustomAuthContext } from '../../context/AuthProvider';

const CompaniesSelect: React.FC<ICompaniesSelect> = (props: ICompaniesSelect) => {
    const { token, parseToken, checkPermission } = useContext(CustomAuthContext);
    const dispatch: AppDispatch = useDispatch();
    const [companiesState, setCompaniesState] = React.useState<Array<ICompany>>([]);
    const companies = useSelector((state: AppState) => state.companies);

    React.useEffect(() => {
        const args: IArgsCompany = {
            company_id: parseToken().company,
            user_id: parseToken().sub,
        };

        if (!checkPermission(UserPermission.ADMIN)) {
            dispatch(fetchGetAllCompanies({ token, args }));
        }

        return () => {
            dispatch(ClearStateCompanies());
        };
    }, []);

    /**
     * @param statusCompanies
     * Get status companies
     */
    React.useEffect(() => {
        if (companies.statusCompanies === StatusRedux.Succeeded) {
            setCompaniesState(companies.companies.companies);
        }
    }, [companies.statusCompanies]);

    return (
        <Autocomplete
            value={props.data}
            fullWidth
            size={props.size ?? 'medium'}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onChange={(_, newValue: ICompany | null) => {
                if (newValue !== null) {
                    props.setData(newValue);
                } else {
                    if (props.removeValue) {
                        props.setData(newValue);
                    } else {
                        props.setData(props.data as ICompany);
                    }
                }
            }}
            id='companies'
            options={companiesState}
            getOptionLabel={(option: ICompany) => (option.name ? option.name : '')}
            renderInput={(params) => <TextField {...params} label='Choose Company' variant={props.variant ? props.variant : 'standard'} />}
        />
    );
};
export default CompaniesSelect;
