import React from 'react';
import { ISkeletonImage, ISkeletonList } from '../interface/ISkeleton';
import { classes } from '../syles/skeleton-damagelab';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';

export const SkeletonList: Function = ({ num, checkbox }: ISkeletonList) => {
    return [...Array(num)].map((i: number, index) => {
        return (
            <Box key={index} sx={classes.skeletonDivMain}>
                {checkbox && <Skeleton variant='text' sx={classes.skeletonCheckbox} />}
                <Skeleton variant='text' sx={classes.skeletonList} />
            </Box>
        );
    });
};

export const SkeletonImg: React.FC<ISkeletonImage> = ({ width, height }: ISkeletonImage) => {
    return (
        <Skeleton
            variant='rectangular'
            sx={{
                width: width,
                height: height,
                margin: '0 auto',
            }}
        />
    );
};
