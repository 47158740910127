import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RoleServices from '../../services/role/role';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs, IFetchBody } from '../IFetch';
import { IAddPermissionToRole, IArgsRole, IRemovePermissionToRole, IRole, IRoles } from '../../infrastructure/DTO/role/role.dto';
import { IPermission } from '../../infrastructure/DTO/permission/permission.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchAllRoles = createAsyncThunk<IRoles, IFetchArgs<null>>('roles/all', async (params: IFetchArgs<null>) => {
    return await RoleServices.getRoles(params);
});

export const fetchUpdateRole = createAsyncThunk<IRole, IFetchBody<IRole, IArgsRole>>('roles/update', async (params: IFetchBody<IRole, IArgsRole>) => {
    return await RoleServices.updateRole(params);
});

export const fetchCreateRole = createAsyncThunk<IRole, IFetchBody<IRole>>('roles/create', async (params: IFetchBody<IRole>) => {
    return await RoleServices.createRole(params);
});

export const fetchAddPermissionToRole = createAsyncThunk<IRole, IFetchArgs<IAddPermissionToRole>>(
    'roles/permission',
    async (params: IFetchArgs<IAddPermissionToRole>) => {
        return await RoleServices.addPermissionToRole(params);
    }
);

export const fetchRemoveRole = createAsyncThunk<any, IFetchArgs<IArgsRole>>('roles/remove', async (params: IFetchArgs<IArgsRole>) => {
    return await RoleServices.removeRole(params);
});

export const fetchRoleActive = createAsyncThunk<IRole, IFetchArgs<IArgsRole>>('roles/active', async (params: IFetchArgs<IArgsRole>) => {
    return await RoleServices.activeRole(params);
});

export const fetchRoleDeactivate = createAsyncThunk<IRole, IFetchArgs<IArgsRole>>('roles/deactivate', async (params: IFetchArgs<IArgsRole>) => {
    return await RoleServices.deactivateRole(params);
});

export const fetchRemovePermissionFromRole = createAsyncThunk<any, IFetchArgs<IRemovePermissionToRole>>(
    'roles/remove-permission',
    async (params: IFetchArgs<IRemovePermissionToRole>) => {
        return await RoleServices.removePermissionFromRole(params);
    }
);

export const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        roles: {} as IRoles,
        statusRole: '',
        errorRole: {} as IErrorResponse,
        statusUpdate: '',
        errorUpdate: {} as IErrorResponse,
        statusCreate: '',
        errorCreate: {} as IErrorResponse,
        statusAddPermission: '',
        errorAddPermission: {} as IErrorResponse,
        statusRemovePermission: '',
        errorRemovePermission: {} as IErrorResponse,
        statusRemove: '',
        errorRemove: {} as IErrorResponse,
        statusIsActive: '',
        errorIsActive: {} as IErrorResponse,
    },
    reducers: {
        clearStoreUpdate: (state) => {
            state.statusUpdate = '';
            state.errorUpdate = {} as IErrorResponse;
        },
        clearStoreCreate: (state) => {
            state.statusCreate = '';
            state.errorCreate = {} as IErrorResponse;
        },
        clearStoreAddPermissionToRole: (state) => {
            state.statusAddPermission = '';
            state.errorAddPermission = {} as IErrorResponse;
        },
        clearStoreRemovePermissionFromRole: (state) => {
            state.statusRemovePermission = '';
            state.errorRemovePermission = {} as IErrorResponse;
        },
        clearStoreIsActive: (state) => {
            state.statusIsActive = '';
            state.errorIsActive = {} as IErrorResponse;
        },
        clearStoreRemove: (state) => {
            state.statusRemove = '';
            state.errorRemove = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllRoles.pending, (state) => {
            state.statusRole = StatusRedux.Loading;
        });
        builder.addCase(fetchAllRoles.fulfilled, (state, action) => {
            state.statusRole = StatusRedux.Succeeded;
            state.roles = action.payload;
        });
        builder.addCase(fetchAllRoles.rejected, (state, action) => {
            state.statusRole = StatusRedux.Failed;
            state.errorRole = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUpdateRole.pending, (state) => {
            state.statusUpdate = StatusRedux.Loading;
        });
        builder.addCase(fetchUpdateRole.fulfilled, (state, action) => {
            state.statusUpdate = StatusRedux.Succeeded;
            const index = state.roles.roles.findIndex((x: any) => x.role_id === action.payload.role_id);
            const name = action.payload.name;
            const is_active = action.payload.is_active;

            state.roles.roles = [
                ...state.roles.roles.slice(0, index),
                { ...state.roles.roles[index], name: name, is_active: is_active },
                ...state.roles.roles.slice(index + 1),
            ];
        });
        builder.addCase(fetchUpdateRole.rejected, (state, action) => {
            state.statusUpdate = StatusRedux.Failed;
            state.errorUpdate = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchCreateRole.pending, (state) => {
            state.statusCreate = StatusRedux.Loading;
        });
        builder.addCase(fetchCreateRole.fulfilled, (state, action) => {
            state.statusCreate = StatusRedux.Succeeded;
            state.roles.roles.push(action.payload);
        });
        builder.addCase(fetchCreateRole.rejected, (state, action) => {
            state.statusCreate = StatusRedux.Failed;
            state.errorCreate = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchAddPermissionToRole.pending, (state) => {
            state.statusAddPermission = StatusRedux.Loading;
        });
        builder.addCase(fetchAddPermissionToRole.fulfilled, (state, action) => {
            state.statusAddPermission = StatusRedux.Succeeded;
            const index = state.roles.roles.findIndex((x: any) => x.role_id === (action.meta.arg.args?.role_id as string));

            state.roles.roles = [
                ...state.roles.roles.slice(0, index),
                {
                    ...state.roles.roles[index],
                    permissions: [...state.roles.roles[index].permissions, action.meta.arg.args?.permission as IPermission],
                },
                ...state.roles.roles.slice(index + 1),
            ];
        });
        builder.addCase(fetchAddPermissionToRole.rejected, (state, action) => {
            state.statusAddPermission = StatusRedux.Failed;
            state.errorAddPermission = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchRemoveRole.pending, (state) => {
            state.statusRemove = StatusRedux.Loading;
        });
        builder.addCase(fetchRemoveRole.fulfilled, (state, action) => {
            state.statusRemove = StatusRedux.Succeeded;
        });
        builder.addCase(fetchRemoveRole.rejected, (state, action) => {
            state.statusRemove = StatusRedux.Failed;
            state.errorRemove = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchRoleActive.pending, (state) => {
            state.statusIsActive = StatusRedux.Loading;
        });
        builder.addCase(fetchRoleActive.fulfilled, (state, action) => {
            state.statusIsActive = StatusRedux.Succeeded;
            const index = state.roles.roles.findIndex((x: any) => x.role_id === action.payload.role_id);

            state.roles.roles = [
                ...state.roles.roles.slice(0, index),
                { ...state.roles.roles[index], is_active: action.payload.is_active },
                ...state.roles.roles.slice(index + 1),
            ];
        });
        builder.addCase(fetchRoleActive.rejected, (state, action) => {
            state.statusIsActive = StatusRedux.Failed;
            state.errorRemove = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchRoleDeactivate.pending, (state) => {
            state.statusIsActive = StatusRedux.Loading;
        });
        builder.addCase(fetchRoleDeactivate.fulfilled, (state, action) => {
            state.statusIsActive = StatusRedux.Succeeded;
            const index = state.roles.roles.findIndex((x: any) => x.role_id === action.payload.role_id);

            state.roles.roles = [
                ...state.roles.roles.slice(0, index),
                { ...state.roles.roles[index], is_active: action.payload.is_active },
                ...state.roles.roles.slice(index + 1),
            ];
        });
        builder.addCase(fetchRoleDeactivate.rejected, (state, action) => {
            state.statusIsActive = StatusRedux.Failed;
            state.errorRemove = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchRemovePermissionFromRole.pending, (state) => {
            state.statusRemovePermission = StatusRedux.Loading;
        });
        builder.addCase(fetchRemovePermissionFromRole.fulfilled, (state, action) => {
            state.statusRemovePermission = StatusRedux.Succeeded;

            const index = state.roles.roles.findIndex((x: any) => x.role_id === action.meta.arg.args?.role_id);

            state.roles.roles = [
                ...state.roles.roles.slice(0, index),
                {
                    ...state.roles.roles[index],
                    permissions: [...state.roles.roles[index].permissions.filter((i) => i.permission_id !== action.meta.arg.args?.permission_id)],
                },
                ...state.roles.roles.slice(index + 1),
            ];
        });
        builder.addCase(fetchRemovePermissionFromRole.rejected, (state, action) => {
            state.statusRemovePermission = StatusRedux.Failed;
            state.errorRemovePermission = JSON.parse(action.error.message as string);
        });
    },
});

export const {
    clearStoreCreate,
    clearStoreUpdate,
    clearStoreRemove,
    clearStoreRemovePermissionFromRole,
    clearStoreIsActive,
    clearStoreAddPermissionToRole,
} = rolesSlice.actions;
export default rolesSlice;
