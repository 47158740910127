export const InspectionType: Array<any> = [
    {
        id: 0,
        type: 'UNDEFINED',
        content: 'UNDEFINED',
        name: 'UNDEFINED',
        extraFeatures: [],
    },
    {
        id: 1,
        type: 'QUALIFIED_INSPECTION',
        content: 'All Images pass the image quality process. Alerts if the inspection does not contain 4 angles of the car.',
        name: 'Qualified Inspection',
        extraFeatures: ['19', '10'],
    },
    {
        id: 3,
        type: 'BASIC_INSPECTION',
        content:
            'For each image, a response for image quality is returned. For all passed images, a response with the detected damages is returned. A list of damaged parts is returned with the detected damage types. For all passed images, a response with the detected damages is returned.',
        name: 'Basic Inspection',
        extraFeatures: ['19', '10'],
    },
    {
        id: 4,
        type: 'PRO_INSPECTION',
        content:
            'For each image, a response for image quality is returned. For all passed images, a response with the detected damages is returned. A list of damaged parts is returned with the detected damage types. Overall damage grade is returned for the inspection. The vehicle area map is returned in the inspection.',
        name: 'Pro Inspection',
        extraFeatures: ['19', '10'],
    },
    {
        id: 5,
        type: 'EXPERT_INSPECTION',
        content:
            'For each image, a response for image quality is returned. For all passed images, a response with the detected damages is returned. A list of damaged parts is returned with the detected damage types Overall damage grade is returned for the inspection. The vehicle area map is returned in the inspection. Measurement for detected damages is returned in the inspection.',
        name: 'Expert Inspection',
        extraFeatures: ['19', '10'],
    },
    {
        id: 7,
        type: 'PRO_COMPARE',
        content:
            'For each image, a response for image quality is returned. For all passed images, a response with the detected damages is returned. A list of damaged parts is returned with the detected damage types For all passed images, a response with the detected damages is returned. Overall damage grade is returned for the inspection. Areas with new damages will be highlighted.',
        name: 'Pro Compare',
        extraFeatures: ['19', '10'],
    },
    {
        id: 9,
        type: 'FULL_INSPECTION',
        content: 'Full inspection Add Description Later.',
        name: 'Full Inspection',
        extraFeatures: ['19', '10', '21'],
    },
    {
        id: 11,
        type: 'FULL_INSPECTION_COMPARE',
        content: 'Full inspection Compare Add Description Later.',
        name: 'Full Inspection Compare',
        extraFeatures: ['19', '10'],
    },
];
