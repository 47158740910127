import React, { createContext } from 'react';
import { ISettings } from '../infrastructure/DTO/threshold-settings/threshold-settings.dto';

export interface IThresholdContext {
    showThresholdButton: boolean;
    reset: boolean;
    thresholds: ISettings;
    handleShowThresholds: Function;
    handleChangeThresholds: Function;
}

export const ThresholdContext = createContext<IThresholdContext>({
    showThresholdButton: false,
    reset: false,
    thresholds: {
        thresholds_per_damage_type: [
            { damage_type: 4, threshold: 0.005 },
            { damage_type: 6, threshold: 0.005 },
            { damage_type: 12, threshold: 0.005 },
            { damage_type: 50, threshold: 0.005 },
            { damage_type: 51, threshold: 0.005 },
            { damage_type: 52, threshold: 0.005 },
            { damage_type: 53, threshold: 0.005 },
            { damage_type: 54, threshold: 0.005 },
        ],
    },
    handleShowThresholds: (show: boolean): void => {},
    handleChangeThresholds: (event: React.ChangeEvent<HTMLInputElement>): void => {},
});
