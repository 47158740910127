import React from 'react';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { classes } from '../components/pages/inspection/create-inspection/inspection-steps/style';
import { useParams } from 'react-router-dom';
import { ICreateInspection } from '../infrastructure/DTO/inspection/inspection.dto';
import { IFeature } from '../infrastructure/DTO/inspection/feature.dto';

type ImgView = {
    data_url: string;
    file: File;
};

export type InspectionData = {
    licensePlateNumber: string;
    claim: string;
    odometer: number | null;
    inspectionDate: Date | null;
    odometerImg: any;
    odometerImgView: Array<ImgView>;
    inspection_type: any;
    inspection_id: string;
    detection: boolean;
    matching: boolean;
    consolidateRes: boolean;
    showInspectionId: boolean;
    allFeatures: IFeature[];
    selectAll: boolean;
};

export interface IInspectionProvider {
    inspectionStateContext: InspectionData;
    setInspectionToState(data: InspectionData): void;
    inspectionDataContext: ICreateInspection;
    setInspectionToData(data: ICreateInspection): void;
}

export const InspectionContext = React.createContext<IInspectionProvider>({
    inspectionStateContext: {} as InspectionData,
    setInspectionToState: (data: InspectionData): void => {},
    inspectionDataContext: {} as ICreateInspection,
    setInspectionToData: (data: ICreateInspection): void => {},
});

const InspectionProvider = (props: any) => {
    const { children } = props;
    const { id } = useParams<{ id: string }>();
    const [inspectionState, setInspectionState] = React.useState<InspectionData>({
        allFeatures: [] as IFeature[],
        claim: '',
        consolidateRes: true,
        detection: true,
        inspectionDate: null,
        inspection_id: '',
        inspection_type: 'Choose your inspection',
        licensePlateNumber: '',
        matching: true,
        odometer: null,
        odometerImg: {} as any,
        odometerImgView: [] as ImgView[],
        selectAll: false,
        showInspectionId: false,
    });
    const [inspectionData, setInspectionData] = React.useState<ICreateInspection>({
        client_process_id: '',
        client_token: '',
        basic_vehicle_data: {
            model: {
                model_identifier: '',
                make: '',
                model: '',
                submodel: '',
                bodystyle: '',
                year: null,
                full_name: '',
            },
            image_side: null,
            license_plate_number: '',
            country_code: '',
            odometer: null,
        },
        previous_inspection_case_id: '',
        features: [],
        inspection_type: null,
        image_date_creation: new Date(),
        measurement_units: '',
        vehicle_mask_resolution: null,
    });

    const handleSetInspectionToState = (data: InspectionData): void => {
        setInspectionState(data);
    };

    const handleSetInspectionToData = (data: ICreateInspection): void => {
        setInspectionData(data);
    };

    const contextValue: IInspectionProvider = {
        inspectionStateContext: inspectionState,
        setInspectionToState: handleSetInspectionToState,
        inspectionDataContext: inspectionData,
        setInspectionToData: handleSetInspectionToData,
    };

    return (
        <InspectionContext.Provider value={contextValue}>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid item xs={12}>
                    <Box sx={classes.headerTitle}>
                        {id === '1' && 'PLEASE FILL OUT ANY INFORMATION YOU WANT TO APPEAR IN YOUR REPORT'}
                        {id === '2' && 'CHOOSE THE INSPECTION AND ADDITIONAL FEATURES YOU WANT TO USE IN YOUR REPORT'}
                        {id === '3' && 'UPLOAD ALL THE IMAGES YOU HAVE OF THE VEHICLE’S EXTERIOR. SIDES AND DAMAGE CLOSEUPS'}
                    </Box>
                    <Box sx={classes.headerSubTitle}>
                        {id === '3' && 'Please make sure the images are clear, high quality and the view of the vehicle is unobstructed'}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={11} lg={10} xl={10}>
                    {children}
                </Grid>
            </Grid>
        </InspectionContext.Provider>
    );
};

export default InspectionProvider;
