import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import DamageLabServices from '../../services/damage-lab/damage-lab';
import { IFetchArgs } from '../IFetch';
import { IDamageMatching } from '../../infrastructure/DTO/damage-lab/damage-matching.dto';
import { IGetAnomaliesDetection } from '../../infrastructure/DTO/damage-lab/damage-lab.dto';
import { IArgsDamageLab } from '../../infrastructure/DTO/damage-lab/anomalies-detection.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchDamageMatching = createAsyncThunk<IDamageMatching, IFetchArgs<IArgsDamageLab>>(
    'damage-lab/damageMatching',
    async (params: IFetchArgs<IArgsDamageLab>) => {
        return await DamageLabServices.damageMatching(params);
    }
);

export const fetchGetAnomaliesDetection = createAsyncThunk<IGetAnomaliesDetection, IFetchArgs<IArgsDamageLab>>(
    'damage-lab/get-anomalies-detection',
    async (params: IFetchArgs<IArgsDamageLab>) => {
        return await DamageLabServices.getAnomaliesDetection(params);
    }
);

export const damageMatchingSlice = createSlice({
    name: 'damage-lab',
    initialState: {
        damageMatching: {} as IDamageMatching,
        getAnomaliesDetection: {} as IGetAnomaliesDetection,
        status: '',
        error: {} as IErrorResponse,
        statusAnomaliesDetection: '',
        errorAnomaliesDetection: {} as IErrorResponse,
        statusGetAnomaliesDetection: '',
        errorGetAnomaliesDetection: {} as IErrorResponse,
    },
    reducers: {
        clearStateDamageMatching: (state) => {
            state.damageMatching = {} as IDamageMatching;
            state.getAnomaliesDetection = {} as IGetAnomaliesDetection;
            state.status = '';
            state.error = {} as IErrorResponse;
        },
        clearStateStatusDamageMatching: (state) => {
            state.status = '';
            state.error = {} as IErrorResponse;
        },
        clearStateStatusGetAnomaliesDetection: (state) => {
            state.getAnomaliesDetection = {} as IGetAnomaliesDetection;
            state.statusGetAnomaliesDetection = '';
            state.errorGetAnomaliesDetection = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDamageMatching.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchDamageMatching.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.damageMatching = action.payload;
        });
        builder.addCase(fetchDamageMatching.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchGetAnomaliesDetection.pending, (state) => {
            state.statusGetAnomaliesDetection = StatusRedux.Loading;
        });
        builder.addCase(fetchGetAnomaliesDetection.fulfilled, (state, action) => {
            state.statusGetAnomaliesDetection = StatusRedux.Succeeded;
            state.getAnomaliesDetection = action.payload;
        });
        builder.addCase(fetchGetAnomaliesDetection.rejected, (state, action) => {
            state.statusGetAnomaliesDetection = StatusRedux.Failed;
            state.errorGetAnomaliesDetection = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStateDamageMatching, clearStateStatusGetAnomaliesDetection } = damageMatchingSlice.actions;
export default damageMatchingSlice;
