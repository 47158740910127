import { alpha, CSSObject, Theme } from '@mui/material';

const paper = (): CSSObject => ({
    minHeight: 'calc(100vh - 200px)',
    width: '100%',
    position: 'relative',
});

const stage = (h: string = '115px'): CSSObject => ({
    width: '100%',
    height: `calc(100vh - ${h})`,
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'relative',
    // cursor: 'move',
});

const titlePage = (): CSSObject => ({
    marginBottom: '20px!important',
    textTransform: 'uppercase',
});

const imgUpload = (): CSSObject => ({
    width: '100%',
    '& > img': {
        width: '100%',
        height: ' calc(100vh - 240px)',
        objectFit: 'contain',
    },
});

const spinner = (theme: Theme): CSSObject => ({
    width: '100%',
    height: 'calc(100vh - 200px)',
    position: 'absolute',
    left: 0,
    right: 0,
    background: alpha(theme.palette.primary.main, 0.1),
});

const mainBox = (): CSSObject => ({
    display: 'flex',
    position: 'relative',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0px',
});

const panel = (): CSSObject => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
});

const top = (): CSSObject => ({
    position: 'absolute',
    top: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
});

const otherHeader = (theme: Theme): CSSObject => ({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    backdropFilter: 'blur(10px)',
    background: theme.palette.mode === 'light' ? 'rgb(255 255 255 / 70%)' : 'rgb(0 0 0 / 70%)',
    position: 'sticky',
    top: 0,
    zIndex: 999,
    paddingBottom: '8px',
});

const bottomHeader = (): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    height: 'auto!important',
    justifyContent: 'right',
});

const bottom = (): CSSObject => ({
    position: 'absolute',
    bottom: '10px',
    right: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const menu = (): CSSObject => ({
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
    },
    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
    },
});

const panelRight = (): CSSObject => ({
    '& > button': {
        margin: '0px 10px',
    },
});

const panelLeft = (): CSSObject => ({
    '& > button': {
        margin: '0px 10px',
    },
});

const panelBottom = (): CSSObject => ({
    '& > button': {
        margin: '0px 10px',
    },
});

const tableRow = (theme: Theme): CSSObject => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer',
});

const panelInfo = (theme: Theme): CSSObject => ({
    marginLeft: '10px',
    display: 'flex',
    '& > div': {
        marginRight: '7px',
        zIndex: 9,
    },
    zIndex: 999,
});

const head = (theme: Theme): CSSObject => ({
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
});

const table = (): CSSObject => ({
    height: 'auto',
});

const checkBoxList = (): CSSObject => ({
    minWidth: '30px',
});

const listIcon = (): CSSObject => ({
    minWidth: '40px',
});

const iconLessMore = (): CSSObject => ({
    marginLeft: '15px',
});

const nested = (theme: Theme): CSSObject => ({
    paddingLeft: theme.spacing(4),
});

const measurementPopup = (theme: Theme): CSSObject => ({
    overflowY: 'auto',
    backdropFilter: 'blur(10px)',
    background: theme.palette.mode === 'light' ? 'rgb(255 255 255 / 70%)' : 'rgb(0 0 0 / 70%)',
});

export const classes = {
    measurementPopup,
    paper,
    spinner,
    imgUpload,
    titlePage,
    stage,
    mainBox,
    panel,
    top,
    bottom,
    panelInfo,
    panelRight,
    panelLeft,
    panelBottom,
    menu,
    tableRow,
    head,
    table,
    otherHeader,
    bottomHeader,
    checkBoxList,
    iconLessMore,
    nested,
    listIcon,
};
