import React from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../../../../../store";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {classes} from "./style";
import Box from "@mui/material/Box";


const ImageQuality: React.FC = () => {
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);

    return (
        <Box sx={classes.anim}>
            <Typography variant="subtitle2" gutterBottom>
                {damageLabData.image_quality_result?.message}
            </Typography>
            <TableContainer sx={classes.table}>
                {
                    damageLabData.image_quality_result !== null ?
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={classes.head}># ID</TableCell>
                                    <TableCell sx={classes.head} align="center">Name</TableCell>
                                    <TableCell sx={classes.head} align="center">Score</TableCell>
                                    <TableCell sx={classes.head} align="center">Error</TableCell>
                                    <TableCell sx={classes.head} align="center">Reason Score</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    damageLabData.image_quality_result?.metrics.map((row, index: number) => (
                                        <TableRow hover sx={classes.tableRow} key={index+1}>
                                            <TableCell component="th" scope="row">
                                                {index+1}
                                            </TableCell>
                                            <TableCell align="center">{row.name}</TableCell>
                                            <TableCell align="center">{row.score}</TableCell>
                                            <TableCell align="center">{row.error}</TableCell>
                                            <TableCell align="center">{row.reason_score}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table> : <Box sx={classes.emptyDiv}>Not Found</Box>
                }
            </TableContainer>
        </Box>
    )
}

export default ImageQuality;