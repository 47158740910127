import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs } from '../IFetch';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';
import Multilayered from '../../services/multilayered/multilayered';
import { IArgsImagesOverView, IImagesOverView } from '../../infrastructure/DTO/multilayered/multilayered.dto';

export const fetchGetImagesOverView = createAsyncThunk<IImagesOverView, IFetchArgs<IArgsImagesOverView>>(
    'images-overview/all',
    async (params: IFetchArgs<IArgsImagesOverView>) => {
        return await Multilayered.getImagesOverView(params);
    }
);

export const imagesOverViewSlice = createSlice({
    name: 'images-overview',
    initialState: {
        imagesOverView: {} as IImagesOverView,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        clearStoreImagesOverView: (state) => {
            state.status = '';
            state.error = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetImagesOverView.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetImagesOverView.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.imagesOverView = action.payload;
        });
        builder.addCase(fetchGetImagesOverView.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreImagesOverView } = imagesOverViewSlice.actions;
export default imagesOverViewSlice;
