import React, { useContext } from 'react';
import { Button, Dialog, TextField } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { classes } from './style';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AppDispatch, AppState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { clearProcess, fetchStartAsyncProcess } from '../../../../../store/inspection/process';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { showPopup } from '../../../../../store/events/popup';
import { clearStoreImages } from '../../../../../store/inspection/images';
import { clearStoreInspections } from '../../../../../store/inspection/inspection';
import { IStartAsyncProcessRequest } from '../../../../../infrastructure/DTO/inspection/inspection.dto';

interface InspectionStartProcessComponent {
    popup: boolean;
    setAsyncProcessOpen: Function;
}

const InspectionStartProcess: React.FC<InspectionStartProcessComponent> = (props: InspectionStartProcessComponent) => {
    const { parseToken, token } = useContext(CustomAuthContext);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const [email, setEmail] = React.useState<string>(parseToken().UserEmail);
    const { inspections } = useSelector((state: AppState) => state.inspection);
    const { processData, statusProcess } = useSelector((state: AppState) => state.process);

    const handleSendProcess = (event: any) => {
        event.preventDefault();
        const args: IStartAsyncProcessRequest = {
            inspection_case: inspections.inspection_case_id,
            email: email,
        };

        dispatch(
            fetchStartAsyncProcess({
                token,
                args,
            })
        );
    };

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.target.value);
    };

    const handleClose = () => {
        props.setAsyncProcessOpen(false);
    };

    React.useEffect(() => {
        if (statusProcess === StatusRedux.Succeeded) {
            if (processData.status) {
                enqueueSnackbar('Report Sent Successfully', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('Error, Report Not Sent', {
                    variant: 'success',
                });
            }

            props.setAsyncProcessOpen(false);
            navigate('/home/inspection');

            return () => {
                dispatch(showPopup(false));
                dispatch(clearStoreImages());
                dispatch(clearStoreInspections());
                dispatch(clearProcess());
            };
        }
    }, [statusProcess]);

    return (
        <>
            <Dialog maxWidth='xs' open={props.popup} onClose={handleClose} aria-labelledby='max-width-dialog-title'>
                <DialogTitle sx={classes.headerPopup} id='max-width-dialog-title'>
                    Report will be proccessed and sent to the following Email
                </DialogTitle>
                <DialogContent>
                    <form autoComplete='off' onSubmit={handleSendProcess}>
                        <TextField
                            fullWidth
                            autoComplete='off'
                            name='email'
                            required={true}
                            variant='standard'
                            className='input-mail'
                            id='outlined-basic'
                            type='email'
                            label='E-mail'
                            value={email}
                            onChange={handleChangeEmail}
                        />
                        <Box sx={classes.btnsPopup}>
                            <Button onClick={handleClose} variant='contained' color='primary'>
                                Cancel
                            </Button>
                            <Button type='submit' variant='contained' color='secondary'>
                                Submit
                            </Button>
                        </Box>
                    </form>
                    <Box sx={classes.popupText}>it may take a few minutes</Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default InspectionStartProcess;
