import React from 'react';
import { Line } from 'react-konva';
import { IBoundingBox, IContours } from '../../../../../../infrastructure/DTO/damage-lab/damage-lab.dto';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store';
import { IDamageLabLayout } from '../IDamageLabLayout';
import DamageLabLayoutContext, { IDamageLabLayoutContext } from '../../../../../../context/DamageLabLayoutContext';

interface IPartsLayoutComponent extends IDamageLabLayout {}

type PointsType = { pointsArr: any; color: string; bounding_box: IBoundingBox; contours: Array<IContours> };

const PartsLayout: React.FC<IPartsLayoutComponent> = (props: IPartsLayoutComponent) => {
    const { scale, currentPart, setCurrentPart, setSelectedPart, selectedPart } = React.useContext<IDamageLabLayoutContext>(DamageLabLayoutContext);
    const { imageOverView } = useSelector((state: AppState) => state.imageOverView);
    const [points, setPoints] = React.useState<Map<string, PointsType>>(new Map<string, PointsType>());

    React.useEffect(() => {
        if (props.view) {
            if (imageOverView.parts_segmentation !== null) {
                let partsMap: Map<string, PointsType> = new Map<string, PointsType>();

                imageOverView.parts_segmentation.forEach((part) => {
                    let pointsConcat: any = [];

                    part.contours !== null &&
                        part.contours.forEach((points) => {
                            pointsConcat.push(
                                points.map((i) => {
                                    return { x: Math.floor(i.x * scale), y: Math.floor(i.y * scale) };
                                })
                            );
                        });

                    partsMap.set(part.part_id, {
                        pointsArr: pointsConcat,
                        color: `rgba(${part.color_value}, 0.5)`,
                        contours: part.contours,
                        bounding_box: {
                            width: part.bounding_box.width,
                            height: part.bounding_box.height,
                            x: Math.floor(part.bounding_box.x * scale),
                            y: Math.floor(part.bounding_box.y * scale),
                        },
                    });
                });

                setPoints(partsMap);
            }
        } else {
            setPoints(new Map());
            setCurrentPart([]);
            setSelectedPart(null);
        }
    }, [props.view, scale]);

    return (
        <React.Fragment>
            {selectedPart &&
                currentPart.map((i: any, index: number) => {
                    let pArr: any[] = [];
                    i.forEach((j: any) => pArr.push(j.x, j.y));

                    return (
                        <Line
                            key={index}
                            points={pArr}
                            stroke='black'
                            fill={`rgba(${selectedPart?.color}, 0.5)`}
                            strokeWidth={1}
                            closed={true}
                            lineCap='square'
                            globalCompositeOperation='source-over'
                        />
                    );
                })}
            {points !== null &&
                selectedPart === null &&
                Array.from(points.values()).map((line, i) => {
                    if (line.pointsArr.length === 1) {
                        let pArr: any[] = [];

                        line.pointsArr.forEach((i: any) => {
                            i.forEach((j: any) => {
                                pArr.push(j.x, j.y);
                            });
                        });

                        return (
                            <Line
                                key={i}
                                points={pArr}
                                stroke={'black'}
                                fill={line.color}
                                strokeWidth={0.5}
                                closed={true}
                                lineCap='square'
                                globalCompositeOperation='source-over'
                            />
                        );
                    } else {
                        return line.pointsArr.map((i: any, index: number) => {
                            let pArr: any[] = [];

                            i.forEach((j: any) => {
                                pArr.push(j.x, j.y);
                            });

                            return (
                                <Line
                                    key={index}
                                    points={pArr}
                                    stroke={'#000'}
                                    fill={line.color}
                                    strokeWidth={0.5}
                                    closed={true}
                                    lineCap='square'
                                    globalCompositeOperation='source-over'
                                />
                            );
                        });
                    }
                })}
        </React.Fragment>
    );
};

export default PartsLayout;
