import * as React from 'react';
import { Dialog, IconButton, Table, TableContainer, TableRow } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { StatusRedux } from '../../../../enums/StatusRedux';
import { Spinner } from '../../../spinner';
import Chip from '@mui/material/Chip';
import { Error } from '../../../error';
import { classes } from '../../user/style';
import { AppDispatch, AppState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import EditPermission from './edit-permission/edit-permission';
import { clearStoreRemove, fetchAllPermissions, fetchRemovePermissions } from '../../../../store/permission/permission';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../../context/AuthProvider';
import { IArgsPermission, IPermission } from '../../../../infrastructure/DTO/permission/permission.dto';
import errorParseMessage from "../../../../config/error-parse";

type EditPopup = {
    open: boolean;
    id: string | undefined;
};

const PermissionView: React.FC = () => {
    const { token } = useContext(CustomAuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const { permissions, statusPermissions, errorPermissions, statusRemove, errorRemove } = useSelector((state: AppState) => state.getPermissions);
    const [edit, setEdit] = React.useState<EditPopup>({
        open: false,
        id: '',
    });
    const [open, setOpen] = React.useState<{ permission_id: string; modal: boolean }>({
        permission_id: '',
        modal: false,
    });
    const handleEdit = (id: string | undefined) => () => {
        setEdit({
            open: true,
            id: id,
        });
    };

    const handleEditClose = () => {
        setEdit({
            ...edit,
            open: false,
        });
    };

    const handleRemovePermission = () => {
        const args: IArgsPermission = {
            permission_id: open.permission_id,
        };
        dispatch(
            fetchRemovePermissions({
                token,
                args,
            })
        );
        setOpen({
            modal: false,
            permission_id: '',
        });
    };

    const handlePopup = (permission_id: string) => () => {
        setOpen({
            modal: !open.modal,
            permission_id: permission_id,
        });
    };

    React.useEffect(() => {
        dispatch(
            fetchAllPermissions({
                token: token,
                args: null,
            })
        );
    }, []);

    React.useEffect(() => {
        if (statusRemove === StatusRedux.Succeeded) {
            enqueueSnackbar('Permission Removed Successfully', {
                variant: 'success',
            });

            dispatch(clearStoreRemove());
        }
        if (statusRemove === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(errorRemove), {
                variant: 'error',
            });

            dispatch(clearStoreRemove());
        }
    }, [statusRemove]);

    return (
        <>
            <Dialog open={open.modal} onClose={handlePopup}>
                <Box sx={classes.headerPopup}>
                    <Box sx={classes.titlePopup}>Are you sure you want to delete?</Box>
                </Box>
                <Box p={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button fullWidth onClick={handlePopup('')} variant='contained' color='primary'>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth onClick={handleRemovePermission} variant='contained' style={{ color: 'white', background: 'red' }}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
            {(() => {
                switch (statusPermissions) {
                    case StatusRedux.Loading:
                        return <Spinner />;
                    case StatusRedux.Succeeded:
                        return (
                            <TableContainer sx={classes.container}>
                                <Table stickyHeader aria-label='sticky table'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={classes.cellLong} key={1} align='left'>
                                                #
                                            </TableCell>
                                            <TableCell sx={classes.cellLong} key={2} align='left'>
                                                Name
                                            </TableCell>
                                            <TableCell sx={classes.cellLong} key={3} align='left'>
                                                Description
                                            </TableCell>
                                            <TableCell sx={classes.cellLong} key={4} align='left'>
                                                Active
                                            </TableCell>
                                            <TableCell sx={classes.cellLong} key={5} align='left'>
                                                Remove
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {permissions !== undefined &&
                                        permissions.permissions.map((data: IPermission, index: number) => (
                                            <TableBody key={index}>
                                                <TableRow hover role='checkbox' tabIndex={-1}>
                                                    <TableCell onClick={handleEdit(data.permission_id)} sx={classes.cellShort} key={1}>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell onClick={handleEdit(data.permission_id)} sx={classes.cellShort} key={2}>
                                                        {data.name}
                                                    </TableCell>
                                                    <TableCell onClick={handleEdit(data.permission_id)} sx={classes.cellShort} key={3}>
                                                        {data.description}
                                                    </TableCell>
                                                    <TableCell onClick={handleEdit(data.permission_id)} sx={classes.cellShort} key={4}>
                                                        {data.is_active ? (
                                                            <Chip label='Enable' variant='outlined' color='primary' />
                                                        ) : (
                                                            <Chip label='Disable' variant='outlined' style={{ color: 'red', borderColor: 'red' }} />
                                                        )}
                                                    </TableCell>
                                                    <TableCell key={5}>
                                                        <IconButton onClick={handlePopup(data.permission_id as string)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                {edit.id === data.permission_id && (
                                                    <EditPermission permission={data} open={edit.open} close={handleEditClose} />
                                                )}
                                            </TableBody>
                                        ))}
                                </Table>
                            </TableContainer>
                        );
                    case StatusRedux.Failed:
                        return <Error msg={errorParseMessage(errorPermissions)} />;
                }
            })()}
        </>
    );
};

export default PermissionView;
