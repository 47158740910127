import {CSSObject, Theme} from "@mui/material";

const listMain = (): CSSObject => ({
    minHeight: '615px'
})

const chip = (): CSSObject => ({
    marginRight: '10px',
    marginTop: '10px'
})

const paper = (): CSSObject => ({
    marginBottom: '15px',
    padding: '15px'
})

export const classes = {
    paper, chip, listMain
}