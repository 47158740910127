import * as React from 'react';
import './error.css';

type ErrorType = {
    msg: string
}

const Error: React.FC<ErrorType> = ({ msg }: ErrorType) => {
    return (
        <div className="error-block">{msg}</div>
    )
}

export default Error;