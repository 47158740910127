import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFetchArgs, IFetchBody } from '../IFetch';
import { StatusRedux } from '../../enums/StatusRedux';
import EffectiveSettingsServices from '../../services/effective-settings/effective-settings';
import {
    IArgsEffectiveSettings,
    IEffectiveSettings,
    IUpdateEffectiveSettings,
} from '../../infrastructure/DTO/effective-settings/effective-settings.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetEffectiveSettings = createAsyncThunk<Array<IEffectiveSettings>, IFetchArgs<IArgsEffectiveSettings>>(
    'effective-settings/get',
    async (params: IFetchArgs<IArgsEffectiveSettings>) => {
        return await EffectiveSettingsServices.getEffectiveSettings(params);
    }
);

export const fetchUpdateEffectiveSettings = createAsyncThunk<Array<IEffectiveSettings>, IFetchBody<IUpdateEffectiveSettings, IArgsEffectiveSettings>>(
    'effective-settings/put',
    async (params: IFetchBody<IUpdateEffectiveSettings, IArgsEffectiveSettings>) => {
        return await EffectiveSettingsServices.updateEffectiveSettings(params);
    }
);

export const effectiveSettingsSlice = createSlice({
    name: 'effective-settings',
    initialState: {
        effectiveSettings: [] as Array<IEffectiveSettings>,
        status: '',
        error: {} as IErrorResponse,
        statusUpdate: '',
        errorUpdate: {} as IErrorResponse,
    },
    reducers: {
        clearStoreStatusUpdatedEffectiveSettings: (state) => {
            state.statusUpdate = '';
            state.errorUpdate = {} as IErrorResponse;
        },
        clearStoreEffectiveSettings: (state) => {
            state.effectiveSettings = [];
        },
        importEffectiveSettings: (state, action) => {
            state.effectiveSettings = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetEffectiveSettings.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetEffectiveSettings.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.effectiveSettings = action.payload;
        });
        builder.addCase(fetchGetEffectiveSettings.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUpdateEffectiveSettings.pending, (state) => {
            state.statusUpdate = StatusRedux.Loading;
        });
        builder.addCase(fetchUpdateEffectiveSettings.fulfilled, (state, action) => {
            state.statusUpdate = StatusRedux.Succeeded;
            state.effectiveSettings = action.payload;
        });
        builder.addCase(fetchUpdateEffectiveSettings.rejected, (state, action) => {
            state.statusUpdate = StatusRedux.Failed;
            state.errorUpdate = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreStatusUpdatedEffectiveSettings, clearStoreEffectiveSettings, importEffectiveSettings } = effectiveSettingsSlice.actions;
export default effectiveSettingsSlice;
