import React from 'react';
import { ITabPanelProps } from '../interface/ITab';
import Box from '@mui/material/Box';

export function TabsProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export function TabPanel(props: ITabPanelProps) {
    const { children, style, value, index, ...other } = props;

    return (
        <Box sx={style} role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
            {value === index && <div>{children}</div>}
        </Box>
    );
}
