import React, { useContext } from 'react';
import { BasicDialog } from '../../../../../common/ui-components/Dialog';
import { Autocomplete, Dialog, IconButton, Paper, Tooltip } from '@mui/material';
import { classes } from './style';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../../store';
import { UserPermission } from '../../../../../config';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { clearStateCreateKey, clearStatGetKey, fetchGenerateApiKey, fetchGetApiKey } from '../../../../../store/api-key/generate-api-key';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import LinkIcon from '@mui/icons-material/Link';
import { TimeZoneConfig, TimeZoneConfigType } from '../time-zone.config';
import {
    clearStoreRemoveStatusThresholds,
    clearStoreStatusActive,
    clearStoreStatusThresholds,
    clearStoreStatusUpdate,
    fetchActivateDeactivateBusinessUnit,
    fetchRemoveThresholdsBusinessUnit,
    fetchThresholdsBusinessUnit,
    fetchUpdateBusinessUnit,
} from '../../../../../store/business-units/business-units';
import { useSnackbar } from 'notistack';
import { fetchGetProfiles } from '../../../../../store/profile/profiles';
import { LoadingButton } from '@mui/lab';
import TuneIcon from '@mui/icons-material/Tune';
import Box from '@mui/material/Box';
import Threshold from '../../../../../common/ui-components/Threshold';
import { IArgsBusinessUnit, IBusinessUnit, IUpdateBusinessUnit } from '../../../../../infrastructure/DTO/bussiness-unit/business-units.dto';
import { IInspectionSettings, ISettings, IThresholdsPerDamage } from '../../../../../infrastructure/DTO/threshold-settings/threshold-settings.dto';
import { ILanguage } from '../../../../../infrastructure/DTO/bussiness-unit/language.dto';
import { IArgsProfile, IProfile } from '../../../../../infrastructure/DTO/profile/profile.dto';
import { IArgsApiKey } from '../../../../../infrastructure/DTO/key/api-key.dto';
import { showPopup } from '../../../../../store/events/popup';
import { PopupBack } from '../../../../popup-back';
import { IUserManagementProvider, UserManagementContext } from '../../../../../context/UserManagementProvider';
import { InputThresholdsParams } from '../../../../../common/interface/IThreshold';
import { GenerateToken } from '../generate-token';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { IInput } from '../../../../../hooks/validation/IInput';
import useCheckInputText from '../../../../../hooks/validation/use-input-text';
import ProfileSelect from '../../../../../common/ui-components/ProfileSelect';

interface IEditBusinessUnitComponent {
    company_id: string;
    company_name: string;
    data: IBusinessUnit | null;
}

const initData: IUpdateBusinessUnit = {
    name: '',
    is_active: true,
    location: '',
    language: 'NONE',
    time_zone: '',
    date_format: '',
    profile_id: '',
    inspection_settings: null,
};

const initThresholds = {
    thresholds_per_damage_type: [
        { damage_type: 4, threshold: 0 },
        { damage_type: 6, threshold: 0 },
        { damage_type: 12, threshold: 0 },
        { damage_type: 50, threshold: 0 },
        { damage_type: 51, threshold: 0 },
        { damage_type: 52, threshold: 0 },
        { damage_type: 53, threshold: 0 },
        { damage_type: 54, threshold: 0 },
    ],
};

interface Fields {
    name: IInput;
    location: null;
}

const EditBusinessUnits: React.FC<IEditBusinessUnitComponent> = (props: IEditBusinessUnitComponent) => {
    const { popup } = useSelector((state: AppState) => state.popupEvent);
    const { enqueueSnackbar } = useSnackbar();
    const { checkPermission, token } = useContext(CustomAuthContext);
    const generateToken = useSelector((state: AppState) => state.generateToken);
    const { openEditBusinessUnit, handleEditBusinessUnit } = useContext<IUserManagementProvider>(UserManagementContext);
    const dispatch: AppDispatch = useDispatch();
    const [openThresholdPopup, setOpenThresholdPopup] = React.useState<boolean>(false);
    const [languages, setLanguages] = React.useState<Array<ILanguage>>([]);
    const [profiles, setProfiles] = React.useState<Array<IProfile>>([]);
    const [profileSelect, setProfileSelect] = React.useState<IProfile | null>(null);
    const [timeZoneSelect, setTimeZoneSelect] = React.useState<TimeZoneConfigType | null>(null);
    const bu = useSelector((state: AppState) => state.businessUnits);
    const lang = useSelector((state: AppState) => state.language);
    const { userKeyData, userKeyStatus, createKeyStatus } = useSelector((state: AppState) => state.generateApiKey);
    const profilesState = useSelector((state: AppState) => state.profiles);
    const [apiKey, setApiKey] = React.useState<string>('');
    const [thresholds, setThresholds] = React.useState<ISettings>(initThresholds);
    const [isChangeThreshold, setIsChangeThreshold] = React.useState<boolean>(false);
    const [applyThresholdBtn, setApplyThresholdBtn] = React.useState<boolean>(true);
    const [resetThresholdBtn, setResetThresholdBtn] = React.useState<boolean>(true);
    const [generateTokenPopup, setPopupGenerateToken] = React.useState<boolean>(false);
    const [businessUnits, setBusinessUnits] = React.useState<IUpdateBusinessUnit>(initData);
    const [openBackPopup, setOpenBackPopup] = React.useState<boolean>(false);
    const { inputsText, message, checkValueText, clearInputState } = useCheckInputText<Fields>({
        name: {
            required: true,
        },
        location: null,
    });

    React.useEffect(() => {
        if (!openEditBusinessUnit) {
            setBusinessUnits(initData);
            setThresholds(initThresholds);
            setResetThresholdBtn(true);
            clearInputState(true);
            dispatch(showPopup(false));
        }
    }, [openEditBusinessUnit]);

    /**
     * @type IBusinessUnit
     * Get data to update Business Unit
     */
    React.useEffect(() => {
        if (props.data !== null && openEditBusinessUnit) {
            setBusinessUnits({
                ...props.data,
                language: props.data.language !== '' ? props.data.language?.toUpperCase() : 'NONE',
            });

            setProfileSelect(props.data.profile as IProfile);

            const timeZone = TimeZoneConfig.find((t) => t.text === props.data?.time_zone) as TimeZoneConfigType;
            if (timeZone !== undefined) {
                setTimeZoneSelect(timeZone);
            }

            const args: IArgsApiKey = {
                company_id: props.company_id,
                business_unit_id: props.data?.business_unit_id as string,
            };

            dispatch(fetchGetApiKey({ token, args }));

            const argsProfile: IArgsProfile = {
                company_id: props.company_id,
            };

            dispatch(fetchGetProfiles({ token: token, args: argsProfile }));

            if (props.data?.inspection_settings) {
                setResetThresholdBtn(false);
                setThresholds(props.data?.inspection_settings as ISettings);
            }
        }

        return () => {
            setApiKey('');
            setProfileSelect(null);
            setTimeZoneSelect(null);
            setPopupGenerateToken(false);
        };
    }, [props.data, openEditBusinessUnit]);

    /**
     * Get Languages
     */
    React.useEffect(() => {
        if (lang.status === StatusRedux.Succeeded) {
            let langs = lang.language.map((l) => {
                if (businessUnits.language === l.name.toUpperCase()) {
                    if (!l.is_active) {
                        return {
                            ...l,
                            is_active: true,
                        };
                    }
                }
                return l;
            });

            setLanguages(langs);
        }
    }, [lang.status]);

    /**
     * Get status
     * API KEY
     */
    React.useEffect(() => {
        if (userKeyStatus === StatusRedux.Succeeded) {
            setApiKey(userKeyData.api_key);
        }

        if (userKeyStatus === StatusRedux.Failed) {
            setApiKey(bu.error.errors[0].message);
        }
    }, [userKeyStatus]);

    /**
     * Change input
     * @param event
     */
    const handleChangeInput = (event: any) => {
        const { name, value } = event.target;
        setBusinessUnits({
            ...businessUnits,
            [name]: value,
        });
    };

    /**
     * Change Thresholds
     */
    const handleChangeThresholds = (params: InputThresholdsParams) => {
        let thresholds_per_damage_type: Array<IThresholdsPerDamage> = [];

        thresholds.thresholds_per_damage_type.forEach((item) => {
            if (item.damage_type === params.name) {
                thresholds_per_damage_type.push({
                    damage_type: item.damage_type,
                    threshold: params.value,
                });
            } else {
                thresholds_per_damage_type.push({
                    ...item,
                });
            }
        });

        let data: ISettings = {
            thresholds_per_damage_type: thresholds_per_damage_type,
        };

        setIsChangeThreshold(true);
        setThresholds(data);
    };

    /**
     * Activation/Deactivation
     * @param event
     */
    const handleChangeInputIsActive = (event: any) => {
        let args: IArgsBusinessUnit = {
            business_unit_id: props.data?.business_unit_id as string,
            company_id: props.company_id,
            switch: event.target.value ? 'activation' : 'deactivation',
        };

        setBusinessUnits({ ...businessUnits, is_active: Boolean(event.target.value) });
        dispatch(fetchActivateDeactivateBusinessUnit({ token, args }));
    };

    /**
     * Status Activation/Deactivation
     */
    React.useEffect(() => {
        if (bu.statusIsActive === StatusRedux.Succeeded) {
            enqueueSnackbar(businessUnits.is_active ? 'Business Unit Activated' : 'Business Unit Deactivated', {
                variant: 'success',
            });

            dispatch(clearStoreStatusActive());
        }

        if (bu.statusIsActive === StatusRedux.Failed) {
            enqueueSnackbar(bu.error.errors[0].message, {
                variant: 'error',
            });
            dispatch(clearStoreStatusActive());
        }
    }, [bu.statusIsActive]);

    /**
     * Create/Update API KEY
     */
    const handleGenerateApiKey = () => {
        setOpenBackPopup(!openBackPopup);
    };

    const handleGenerateToken = () => {
        setPopupGenerateToken(!generateTokenPopup);
    };

    /**
     * Send data to update
     * @param event
     */
    const handleSubmit = (event: any) => {
        event.preventDefault();

        const args: IArgsBusinessUnit = {
            company_id: props.company_id,
            business_unit_id: props.data?.business_unit_id as string,
        };

        const body: IUpdateBusinessUnit = {
            name: businessUnits.name.trim(),
            location: businessUnits.location?.trim(),
            language:
                businessUnits.language !== 'NONE'
                    ? `${businessUnits.language?.charAt(0).toUpperCase()}` + `${businessUnits.language?.slice(1).toLowerCase()}`
                    : '',
            time_zone: businessUnits.time_zone,
            date_format: businessUnits.date_format?.trim(),
            profile_id: profileSelect?.profile_id !== undefined ? profileSelect?.profile_id : '',
            is_active: true,
        };

        dispatch(fetchUpdateBusinessUnit({ token, body, args }));
    };

    /**
     * Status Update
     */
    React.useEffect(() => {
        if (bu.statusUpdated === StatusRedux.Succeeded) {
            enqueueSnackbar('Updated', {
                variant: 'success',
            });
            handleEditBusinessUnit();
            dispatch(clearStoreStatusUpdate());
        }

        if (bu.statusUpdated === StatusRedux.Failed) {
            enqueueSnackbar(bu.error.errors[0].message, {
                variant: 'error',
            });
            dispatch(clearStoreStatusUpdate());
        }
    }, [bu.statusUpdated]);

    /**
     * Status Create API KEY
     */
    React.useEffect(() => {
        if (createKeyStatus === StatusRedux.Succeeded) {
            enqueueSnackbar('API KEY Updated', {
                variant: 'success',
            });
            setApiKey(userKeyData.api_key);
            dispatch(clearStateCreateKey());
            dispatch(clearStatGetKey());
        }

        if (createKeyStatus === StatusRedux.Failed) {
            enqueueSnackbar(bu.error.errors[0].message, {
                variant: 'error',
            });
            dispatch(clearStateCreateKey());
            dispatch(clearStatGetKey());
        }
    }, [createKeyStatus]);

    /**
     * Status Create/Update Thresholds
     */
    React.useEffect(() => {
        if (bu.statusThresholds === StatusRedux.Succeeded) {
            enqueueSnackbar('Thresholds Created!', {
                variant: 'success',
            });
            setOpenThresholdPopup(false);
            setIsChangeThreshold(false);
            setResetThresholdBtn(false);
            dispatch(clearStoreStatusThresholds());
        }

        if (bu.statusThresholds === StatusRedux.Failed) {
            enqueueSnackbar(bu.errorThresholds.errors[0].message, {
                variant: 'error',
            });
            dispatch(clearStoreStatusThresholds());
        }
    }, [bu.statusThresholds]);

    /**
     * Status Remove Thresholds
     */
    React.useEffect(() => {
        if (bu.statusRemoveThresholds === StatusRedux.Succeeded) {
            enqueueSnackbar('Thresholds Removed!', {
                variant: 'success',
            });

            setResetThresholdBtn(true);
            setIsChangeThreshold(false);
            setThresholds(initThresholds);
        }

        if (bu.statusRemoveThresholds === StatusRedux.Failed) {
            enqueueSnackbar(bu.errorRemoveThresholds.errors[0].message, {
                variant: 'error',
            });
        }

        dispatch(clearStoreRemoveStatusThresholds());
    }, [bu.statusRemoveThresholds]);

    /**
     * Set profiles to state
     */
    React.useEffect(() => {
        if (profilesState.status === StatusRedux.Succeeded) {
            setProfiles(profilesState.profiles.profiles);
        }
    }, [profilesState.status]);

    /**
     * Handle Close Popup
     */
    const handlePopup = () => {
        handleEditBusinessUnit();
        setBusinessUnits(props.data as IBusinessUnit);
    };

    /**
     * Open/Close Threshold Popup
     */
    const handleThresholdsPopup = () => {
        if (isChangeThreshold) {
            dispatch(showPopup(true));
        } else {
            setOpenThresholdPopup(!openThresholdPopup);
        }
    };

    /**
     * Apply Threshold Popup
     */
    const handleApplyThreshold = () => {
        const args: IArgsBusinessUnit = {
            company_id: props.company_id,
            business_unit_id: props.data?.business_unit_id as string,
        };

        const body: IInspectionSettings = {
            inspection_settings: thresholds,
        };

        dispatch(fetchThresholdsBusinessUnit({ token, body, args }));
        setIsChangeThreshold(false);
        setOpenThresholdPopup(false);
    };

    React.useEffect(() => {
        if (thresholds?.thresholds_per_damage_type) {
            let findNull = thresholds.thresholds_per_damage_type.find((i) => i.threshold === 0);
            setApplyThresholdBtn(Boolean(findNull));
        }
    }, [thresholds]);

    const handleClosePopup = () => {
        dispatch(showPopup(false));
    };

    const back = () => {
        dispatch(showPopup(false));
        setOpenThresholdPopup(!openThresholdPopup);
        setIsChangeThreshold(false);

        let _thresholds = bu.businessUnits?.business_units.find((bu) => bu.business_unit_id === props.data?.business_unit_id);

        if (_thresholds?.inspection_settings) {
            setThresholds(_thresholds?.inspection_settings as ISettings);
        } else {
            setThresholds(initThresholds);
        }
    };

    React.useEffect(() => {
        if (resetThresholdBtn) {
            setThresholds(initThresholds);
        }
    }, [resetThresholdBtn]);

    const handleRemoveThresholds = () => {
        let thresholds_per_damage_type: Array<IThresholdsPerDamage> = [];

        const args: IArgsBusinessUnit = {
            company_id: props.company_id,
            business_unit_id: props.data?.business_unit_id as string,
        };

        thresholds.thresholds_per_damage_type.forEach((item) => {
            thresholds_per_damage_type.push({
                damage_type: item.damage_type,
                threshold: 0,
            });
        });

        const body: IInspectionSettings = {
            inspection_settings: {
                thresholds_per_damage_type: thresholds_per_damage_type,
            },
        };

        dispatch(fetchRemoveThresholdsBusinessUnit({ token, args, body }));
    };

    const backPopupGenerateApiKey = () => {
        setOpenBackPopup(!openBackPopup);

        const args: IArgsApiKey = {
            company_id: props.company_id,
            business_unit_id: props.data?.business_unit_id as string,
        };

        dispatch(fetchGenerateApiKey({ token, args }));
    };

    const closePopupGenerateApiKey = () => {
        setOpenBackPopup(!openBackPopup);
    };

    /**
     * Change Select Profile
     * @type IProfile
     * @param profile
     */
    const handelChangeProfile = (profile: IProfile | null) => {
        if (profile) {
            setProfileSelect(profile);
            setBusinessUnits({
                ...businessUnits,
                profile_id: profile.profile_id,
            });
        } else {
            setProfileSelect(null);
            setBusinessUnits({
                ...businessUnits,
                profile_id: '',
            });
        }
    };

    return (
        <>
            <PopupBack
                titleText={''}
                description={'Your changes will not be saved. Are you sure you want to leave?'}
                open={popup}
                close={handleClosePopup}
                back={back}
            />
            <Dialog
                sx={{ zIndex: 9999 }}
                maxWidth='xs'
                open={openBackPopup}
                keepMounted
                onClose={closePopupGenerateApiKey}
                aria-labelledby='alert-dialog-back'
                aria-describedby='alert-dialog-back-description'
            >
                <DialogTitle id='alert-back-remove'>Generate API Key</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-back-description'>
                        You about to generate an API Key for this Business Unit. The current API Key will be deprecated. If any integration was
                        implemented using the old APIKey it will not work until a new one will be set. Are you sure you want to generate a new APIKey?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePopupGenerateApiKey} color='primary'>
                        No
                    </Button>
                    <Button onClick={backPopupGenerateApiKey} color='primary'>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <BasicDialog
                handlePopup={handleThresholdsPopup}
                open={openThresholdPopup}
                title='Thresholds'
                width='md'
                buttons={
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item>
                            <Button size='small' variant='outlined' color='primary' onClick={handleThresholdsPopup} endIcon={<CloseIcon />}>
                                Close
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={resetThresholdBtn}
                                onClick={handleRemoveThresholds}
                                size='small'
                                fullWidth
                                type='submit'
                                variant='outlined'
                                color='primary'
                                endIcon={<RestartAltIcon />}
                            >
                                Reset
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={applyThresholdBtn || !isChangeThreshold}
                                form='thresholds-form'
                                size='small'
                                fullWidth
                                type='submit'
                                variant='contained'
                                color='secondary'
                                endIcon={<SaveIcon />}
                            >
                                APPLY
                            </Button>
                        </Grid>
                    </Grid>
                }
            >
                <Paper sx={classes.paper} elevation={2}>
                    <Threshold
                        handleChangeInput={handleChangeThresholds}
                        handleApplyThreshold={handleApplyThreshold}
                        thresholds_per_damage_type={thresholds?.thresholds_per_damage_type}
                        thresholds_per_size={{ xs: 12, sm: 12, md: 6, lg: 3, xl: 3 }}
                    />
                </Paper>
            </BasicDialog>

            <BasicDialog
                handlePopup={handlePopup}
                open={openEditBusinessUnit}
                title={businessUnits && businessUnits.name}
                width='md'
                buttons={
                    !checkPermission(UserPermission.USER_MANAGEMENT) && (
                        <Grid container spacing={2} justifyContent='space-between'>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Grid container spacing={2} justifyContent='flex-end'>
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <Button
                                            size='small'
                                            fullWidth
                                            disabled={
                                                !businessUnits.is_active ||
                                                (checkPermission(UserPermission.USER_MANAGEMENT_APIKEY) && !checkPermission(UserPermission.USER_VIEW))
                                            }
                                            variant='outlined'
                                            onClick={handleGenerateApiKey}
                                            color='primary'
                                            endIcon={<VpnKeyIcon />}
                                        >
                                            Generate API Key
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <LoadingButton
                                            size='small'
                                            fullWidth
                                            disabled={apiKey.length === 0 || !businessUnits.is_active}
                                            color='primary'
                                            onClick={handleGenerateToken}
                                            loading={generateToken.status === StatusRedux.Loading}
                                            loadingPosition='start'
                                            endIcon={<LinkIcon />}
                                            variant='outlined'
                                        >
                                            Create link
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                        {!checkPermission(UserPermission.ADMIN) && (
                                            <Button
                                                disabled={!businessUnits.is_active}
                                                onClick={handleThresholdsPopup}
                                                size='small'
                                                variant='outlined'
                                                color='primary'
                                                endIcon={<TuneIcon />}
                                            >
                                                Thresholds
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    form='edit-bu'
                                    disabled={!businessUnits.is_active || inputsText?.name || inputsText?.location}
                                    size='small'
                                    type='submit'
                                    variant='contained'
                                    color='secondary'
                                    endIcon={<SaveIcon />}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    )
                }
            >
                <GenerateToken apiKey={apiKey} open={generateTokenPopup} close={setPopupGenerateToken} />
                <form id='edit-bu' autoComplete='off' onSubmit={handleSubmit}>
                    <Paper sx={classes.paper} elevation={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    disabled
                                    name='company_name'
                                    id='company_name'
                                    type='text'
                                    label='Company Name'
                                    variant='outlined'
                                    value={props.company_name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name='name'
                                    required={true}
                                    id='name'
                                    type='text'
                                    label='Name'
                                    variant='outlined'
                                    value={businessUnits.name.trimStart()}
                                    onChange={handleChangeInput}
                                    onKeyUp={checkValueText}
                                    helperText={message?.name}
                                    error={inputsText?.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            disabled
                                            name='business_unit_id'
                                            id='business_unit_id'
                                            type='text'
                                            label='Business Unit ID'
                                            variant='outlined'
                                            value={props.data?.business_unit_id}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Tooltip arrow disableFocusListener title={<Box>Click To Copy</Box>}>
                                            <IconButton
                                                onClick={() => {
                                                    navigator.clipboard.writeText(props.data?.business_unit_id as string).then((r) => r);
                                                }}
                                                color='primary'
                                                aria-label='copy profile id'
                                                component='label'
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name='location'
                                    required={false}
                                    id='location'
                                    type='text'
                                    label='Location'
                                    variant='outlined'
                                    value={businessUnits.location?.trimStart() ?? ''}
                                    onChange={handleChangeInput}
                                    onKeyUp={checkValueText}
                                    helperText={message?.location}
                                    error={inputsText?.location}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant='outlined'>
                                    <InputLabel id='language'>Language</InputLabel>
                                    <Select
                                        labelId='language'
                                        id='language'
                                        name='language'
                                        value={businessUnits.language?.toUpperCase()}
                                        onChange={handleChangeInput}
                                        label='Language'
                                    >
                                        <MenuItem key='0' value='NONE'>
                                            Choose Language
                                        </MenuItem>
                                        {languages
                                            .filter((l) => l.is_active)
                                            .map((item, index) => (
                                                <MenuItem key={index + 1} value={item.name.toUpperCase()}>
                                                    {item.name.toUpperCase()}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name='date_format'
                                    id='dateFormat'
                                    type='text'
                                    label='Date Format'
                                    variant='outlined'
                                    value={businessUnits.date_format?.trimStart()}
                                    onChange={handleChangeInput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <ProfileSelect
                                        data={profileSelect}
                                        company_id={props.company_id as string}
                                        variant='outlined'
                                        setData={handelChangeProfile}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    value={timeZoneSelect}
                                    disablePortal
                                    autoHighlight
                                    fullWidth
                                    onChange={(event: any, newValue: TimeZoneConfigType | null) => {
                                        if (newValue !== null) {
                                            setTimeZoneSelect(newValue);
                                            setBusinessUnits({
                                                ...businessUnits,
                                                time_zone: newValue.text,
                                            });
                                        } else {
                                            setTimeZoneSelect(null);
                                            setBusinessUnits({
                                                ...businessUnits,
                                                time_zone: '',
                                            });
                                        }
                                    }}
                                    id='time_zone'
                                    options={TimeZoneConfig?.length !== 0 ? TimeZoneConfig : []}
                                    getOptionLabel={(option: TimeZoneConfigType) => (option.text ? option.text : '')}
                                    renderInput={(params) => <TextField {...params} label='Time Zone' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
                                    <Grid item xs>
                                        <TextField
                                            disabled
                                            fullWidth
                                            name='Key'
                                            id='outlined-basic'
                                            type='text'
                                            label='Key'
                                            variant='outlined'
                                            value={apiKey}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Grid>
                                            <Tooltip arrow disableFocusListener title={<Box>Click To Copy</Box>}>
                                                <IconButton
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(apiKey as string).then((r) => r);
                                                    }}
                                                    color='primary'
                                                    aria-label='copy api key'
                                                    component='label'
                                                >
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl disabled={checkPermission(UserPermission.USER_MANAGEMENT)} fullWidth variant='outlined'>
                                    <InputLabel id='active'>Status</InputLabel>
                                    <Select
                                        labelId='active'
                                        id='isActive'
                                        name='is_active'
                                        value={businessUnits.is_active ? 1 : 0}
                                        onChange={handleChangeInputIsActive}
                                        label='Active'
                                    >
                                        <MenuItem value={1}>Enabled</MenuItem>
                                        <MenuItem value={0}>Disabled</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                </form>
            </BasicDialog>
        </>
    );
};

export default EditBusinessUnits;
