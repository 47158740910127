import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFetchArgs, IFetchBody } from '../IFetch';
import { StatusRedux } from '../../enums/StatusRedux';
import ProfileServices from '../../services/profile/profile';
import { IArgsProfile, IProfile, IProfiles } from '../../infrastructure/DTO/profile/profile.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetProfiles = createAsyncThunk<IProfiles, IFetchArgs<IArgsProfile>>('profiles/get', async (params: IFetchArgs<IArgsProfile>) => {
    return await ProfileServices.getProfilesByCompanyId(params);
});

export const fetchCreateProfile = createAsyncThunk<IProfile, IFetchBody<IProfile, IArgsProfile>>(
    'profiles/post',
    async (params: IFetchBody<IProfile, IArgsProfile>) => {
        return await ProfileServices.createProfile(params);
    }
);

export const fetchUpdateProfile = createAsyncThunk<Partial<IProfile>, IFetchBody<IProfile, IArgsProfile>>(
    'profiles/put',
    async (params: IFetchBody<IProfile, IArgsProfile>) => {
        return await ProfileServices.updateProfile(params);
    }
);

export const profilesSlice = createSlice({
    name: 'profiles',
    initialState: {
        profiles: {} as IProfiles,
        status: '',
        error: {} as IErrorResponse,
        statusCreate: '',
        errorCreate: {} as IErrorResponse,
        statusUpdate: '',
        errorUpdate: {} as IErrorResponse,
    },
    reducers: {
        clearStoreProfiles: (state) => {
            state.profiles = {} as IProfiles;
            state.status = '';
            state.error = {} as IErrorResponse;
        },
        clearStoreCreateProfile: (state) => {
            state.statusCreate = '';
            state.errorCreate = {} as IErrorResponse;
        },
        clearStoreUpdateProfile: (state) => {
            state.statusUpdate = '';
            state.errorUpdate = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetProfiles.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetProfiles.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.profiles = action.payload;
        });
        builder.addCase(fetchGetProfiles.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchCreateProfile.pending, (state) => {
            state.statusCreate = StatusRedux.Loading;
        });
        builder.addCase(fetchCreateProfile.fulfilled, (state, action) => {
            state.statusCreate = StatusRedux.Succeeded;
            state.profiles.profiles.push(action.payload);
        });
        builder.addCase(fetchCreateProfile.rejected, (state, action) => {
            state.statusCreate = StatusRedux.Failed;
            state.errorCreate = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUpdateProfile.pending, (state) => {
            state.statusUpdate = StatusRedux.Loading;
        });
        builder.addCase(fetchUpdateProfile.fulfilled, (state, action) => {
            state.statusUpdate = StatusRedux.Succeeded;
            const index = state.profiles.profiles.findIndex((x: IProfile) => x.profile_id === action.meta.arg.args?.profile_id);

            state.profiles.profiles = [
                ...state.profiles.profiles.slice(0, index),
                { ...state.profiles.profiles[index], name: action.payload.name },
                ...state.profiles.profiles.slice(index + 1),
            ];
        });
        builder.addCase(fetchUpdateProfile.rejected, (state, action) => {
            state.statusUpdate = StatusRedux.Failed;
            state.errorUpdate = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreProfiles, clearStoreCreateProfile, clearStoreUpdateProfile } = profilesSlice.actions;
export default profilesSlice;
