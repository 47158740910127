import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import CompanyServices from '../../services/companies/company';
import { IFetchBody } from '../IFetch';
import { ICheckCompanyResponse, ICompany } from '../../infrastructure/DTO/company/company.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchCheckCompanyName = createAsyncThunk<ICheckCompanyResponse, IFetchBody<ICompany>>(
    'company/check-name',
    async (params: IFetchBody<ICompany>) => {
        return await CompanyServices.checkName(params);
    }
);

export const createCompanySlice = createSlice({
    name: 'company',
    initialState: {
        checkName: {} as ICheckCompanyResponse,
        checkStatusName: '',
        checkErrorName: {} as IErrorResponse,
    },
    reducers: {
        ClearCheckName: (state) => {
            state.checkName = {} as ICheckCompanyResponse;
            state.checkStatusName = '';
            state.checkErrorName = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCheckCompanyName.pending, (state) => {
            state.checkStatusName = StatusRedux.Loading;
        });
        builder.addCase(fetchCheckCompanyName.fulfilled, (state, action) => {
            state.checkStatusName = StatusRedux.Succeeded;
            state.checkName = action.payload;
        });
        builder.addCase(fetchCheckCompanyName.rejected, (state, action) => {
            state.checkStatusName = StatusRedux.Failed;
            state.checkErrorName = JSON.parse(action.error.message as string);
        });
    },
});

export const { ClearCheckName } = createCompanySlice.actions;

export default createCompanySlice;
