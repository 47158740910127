import React, { useContext } from 'react';
import {
    Backdrop,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    MenuItem,
    Paper,
    Select,
    Slide,
} from '@mui/material';
import { classes } from './style';
import SaveIcon from '@mui/icons-material/Save';
import { Group, Image as Pic, Layer, Line, Rect, Text } from 'react-konva';
import useResizeObserver from 'use-resize-observer';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import { DAMAGE_COLOR, DAMAGE_NAME } from '../../../../../enums/Damage';
import CreateIcon from '@mui/icons-material/Create';
import FormControl from '@mui/material/FormControl';
import PanToolIcon from '@mui/icons-material/PanTool';
import Button from '@mui/material/Button';
import { TransitionProps } from '@mui/material/transitions';
import Tooltip from '@mui/material/Tooltip';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../../../store';
import { useSnackbar } from 'notistack';
import { clearStatusDamage, fetchDamageCreate, fetchDamageRemove } from '../../../../../store/inspection/damage';
import { StatusRedux } from '../../../../../enums/StatusRedux';
import { clearStatus, removeDamageFromImg, updateImage } from '../../../../../store/inspection/images';
import Konva from '../../../../../common/ui-components/Konva';
import Box from '@mui/material/Box';
import { backButtonDisabled } from '../../../../../utils/onbeforeunload';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import { IArgsDamages, IDamage, IDamagesRequest, IImageResponse, IRemoveDamage } from '../../../../../infrastructure/DTO/inspection/inspection.dto';

interface DamagesComponent {
    pic: string;
    image_id: string;
    data: Array<IImageResponse>;
}

type DamagesType = {
    id: number;
    damage_id: string;
    damage_status: string;
    damage_type: number;
    contours: Array<{ x: number; y: number }>;
};

const Damages: React.FC<DamagesComponent> = (props: DamagesComponent) => {
    const { token } = useContext(CustomAuthContext);
    const imgRef = React.useRef(null);
    const { damagesList, status, statusRemove } = useSelector((state: AppState) => state.damages);
    const { inspections } = useSelector((state: AppState) => state.inspection);
    const { inspectionImagesData, statusUpdate } = useSelector((state: AppState) => state.inspectionUploadImages);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();
    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

    const [image, setImage] = React.useState({
        img: new window.Image(),
        width: 0,
        height: 0,
    });
    const [imgSize, setImgSize] = React.useState({
        width: 0,
        height: 0,
    });

    const [edit, setEdit] = React.useState<boolean>(false);
    const [damages, setDamages] = React.useState<Array<DamagesType>>([]);
    const [points, setPoints] = React.useState<Map<string, { pointsArr: Array<{ x: number; y: number }>; color: string; id: number }>>(
        new Map<string, { pointsArr: Array<{ x: number; y: number }>; color: string; id: number }>()
    );
    const [damagesCreated, setDamagesCreated] = React.useState<Array<DamagesType>>([]);
    const [coordinates, setCoordinates] = React.useState({ x: 0, y: 0 });
    const [pointsTemp, setPointsTemp] = React.useState<Array<{ x: number; y: number }>>([]);
    const [checked, setChecked] = React.useState<Array<string>>([]);
    const [damageType, setDamageType] = React.useState<number>(0);
    const [popupDamageType, setPopupDamageType] = React.useState<boolean>(false);
    const isDrawing = React.useRef(false);
    const [removeDamagePopup, openPopupRemove] = React.useState<boolean>(false);
    const [damageId, setDamageId] = React.useState<{ id: string; inClient: boolean }>({ id: '', inClient: false });
    const [damageList, setDamageList] = React.useState<boolean>(true);
    const [progress, setProgress] = React.useState<boolean>(false);

    const handleClickPopup =
        (id = '', api = false) =>
        () => {
            setDamageId({ id: id, inClient: api });
            openPopupRemove(!removeDamagePopup);
        };

    const handlePopupDamageType = () => {
        setPopupDamageType(!popupDamageType);
    };

    const handleRemoveDamage = () => {
        if (damageId.inClient) {
            const args: IRemoveDamage = {
                image_id: props.image_id,
                inspection_case: inspections.inspection_case_id,
                damage_id: damageId.id,
            };

            dispatch(fetchDamageRemove({ token, args }));
        } else {
            setDamages(damages.filter((i) => i.damage_id !== damageId.id));
            setDamagesCreated(damagesCreated.filter((i) => i.damage_id !== damageId.id));

            if (points.has(damageId.id)) {
                points.delete(damageId.id);
            }
            openPopupRemove(false);
        }
    };

    const handleToggle = (value: DamagesType, isNewDamage: boolean) => () => {
        let point: Array<{ x: number; y: number }> = [];
        let scale = Math.min(width / image.width, height / image.height);

        if (isNewDamage) {
            value.contours.map((p) => {
                point.push({ x: Number(p.x), y: Number(p.y) });
            });
            if (point.length > 0) {
                point.push({ x: value.contours[0].x, y: value.contours[0].y });
            }
        } else {
            value.contours.map((p) => {
                point.push({ x: Number(p.x * scale), y: Number(p.y * scale) });
            });
            if (point.length > 0) {
                point.push({ x: value.contours[0].x * scale, y: value.contours[0].y * scale });
            }
        }

        if (points.has(value.damage_id)) {
            points.delete(value.damage_id);
        } else {
            setPoints(points.set(value.damage_id, { pointsArr: point, color: DAMAGE_COLOR[value.damage_type], id: value.id }));
        }

        const currentIndex = checked.indexOf(value.damage_id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value.damage_id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleSave = () => {
        const damages: Array<IDamage> = [];
        let scale = Math.min(width / image.width, height / image.height);

        damagesCreated.forEach((damage) => {
            damages.push({
                damage_type: damage.damage_type,
                damage_contour: damage.contours.map((p) => {
                    return { x: Math.floor(p.x / scale), y: Math.floor(p.y / scale) };
                }),
                damage_comment: `Damage ${DAMAGE_NAME[damage.damage_type]} from Portal.`,
            });
        });

        const body: IDamagesRequest = {
            damages: damages,
        };

        const args: IArgsDamages = {
            image_id: props.image_id,
            inspection_case: inspections.inspection_case_id,
        };

        setProgress(true);
        dispatch(fetchDamageCreate({ token, body, args }));
    };

    const handleEdit = () => {
        setEdit(!edit);
    };

    const handleChangeDamage = (event: any) => {
        setDamageType(event.target.value as number);
    };

    const handleMouseDown = () => {
        isDrawing.current = true;
        if (damageType === 0 && edit) {
            setPopupDamageType(true);
            isDrawing.current = false;
        }
    };

    const handleMove = (e: any) => {
        const stage = e.target.getStage();

        setCoordinates({
            x: stage.children[0].children[0].attrs.x,
            y: stage.children[0].children[0].attrs.y,
        });
    };

    const handleMouseMove = (points: { x: number; y: number }) => {
        if (edit && damageType !== 0) {
            if (!isDrawing.current) {
                return;
            }
            setPointsTemp(pointsTemp.concat([{ x: points.x, y: points.y }]));
        }
    };

    const handleMouseUp = () => {
        isDrawing.current = false;

        if (!isDrawing.current) {
            setPointsTemp([]);
        }
    };

    const handleOpenDamagesList = () => {
        setDamageList(!damageList);
    };

    React.useEffect(() => {
        if (statusRemove === StatusRedux.Succeeded) {
            enqueueSnackbar('Damage Removed Successfully', {
                variant: 'success',
                autoHideDuration: 2000,
            });

            setDamages(damages.filter((i) => i.damage_id !== damageId.id));
            setDamagesCreated(damagesCreated.filter((i) => i.damage_id !== damageId.id));

            if (points.has(damageId.id)) {
                points.delete(damageId.id);
            }

            let index = inspectionImagesData.findIndex((i) => i.image_id === props.image_id);
            let damagesFilter = inspectionImagesData[index].detected_damages?.filter((i) => i.damage_id !== damageId.id);
            dispatch(removeDamageFromImg({ index: index, damages: damagesFilter }));
            openPopupRemove(false);

            dispatch(clearStatusDamage());
        }

        if (statusRemove === StatusRedux.Failed) {
            enqueueSnackbar('Error', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        }
    }, [statusRemove]);

    React.useEffect(() => {
        const arrDamages: Array<DamagesType> = [];

        const img = new window.Image();
        img.src = `data:image/jpeg;base64,${props.pic}`;

        img.onload = () => {
            setImage({
                img: img,
                width: img.width,
                height: img.height,
            });
        };

        let res = inspectionImagesData.find((reqItem) => reqItem.image_id === props.image_id);

        if (res !== undefined) {
            res.detected_damages
                ?.filter((damage) => damage.damage_status.code !== 'DELETED')
                .map((i, index: number) => {
                    arrDamages.push({
                        id: index + 1,
                        damage_id: i.damage_id,
                        damage_status: i.damage_status.code,
                        damage_type: i.damage_type,
                        contours: i.segmentation.contours[0],
                    });
                });
        }

        backButtonDisabled(true);
        setDamages(arrDamages);
    }, []);

    const selectedAllDamages = () => {
        const scale = Math.min(width / image.width, height / image.height);
        const obj = new Map();
        const newChecked = [...checked];

        damages.forEach((value) => {
            let point: Array<{ x: number; y: number }> = [];
            value.contours.forEach((p) => {
                point.push({ x: Number(p.x * scale), y: Number(p.y * scale) });
            });
            if (point.length > 0) {
                point.push({ x: value.contours[0].x * scale, y: value.contours[0].y * scale });
            }
            obj.set(value.damage_id, { pointsArr: point, color: DAMAGE_COLOR[value.damage_type], id: value.id });
            newChecked.push(value.damage_id);
        });

        setPoints(obj);
        setChecked(newChecked);
    };

    React.useEffect(() => {
        let scale = Math.min(width / image.width, height / image.height);

        if (image.width !== null && image.height !== null) {
            setImgSize({
                width: image.width * scale,
                height: image.height * scale,
            });
        }

        selectedAllDamages();

        if (width !== null && height !== null) {
            setCoordinates({
                x: width / 2 - (image.width * scale) / 2,
                y: height / 2 - (image.height * scale) / 2,
            });
        }
    }, [image.width, image.height]);

    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            enqueueSnackbar('Damage Sent Successfully', {
                variant: 'success',
                autoHideDuration: 2000,
            });

            setProgress(false);
            setDamagesCreated([]);
            setChecked([]);
            setPoints(new Map());
            dispatch(updateImage(damagesList));
        }

        if (status === StatusRedux.Failed) {
            enqueueSnackbar('Error', {
                variant: 'error',
                autoHideDuration: 2000,
            });

            setProgress(false);
        }
    }, [status]);

    React.useEffect(() => {
        if (statusUpdate === StatusRedux.Succeeded) {
            const arrDamages: Array<DamagesType> = [];
            let res = inspectionImagesData.find((reqItem) => reqItem.image_id === props.image_id);

            if (res !== undefined) {
                res.detected_damages
                    ?.filter((damage) => damage.damage_status.code !== 'DELETED')
                    .map((i, index: number) => {
                        arrDamages.push({
                            id: index + 1,
                            damage_id: i.damage_id,
                            damage_status: i.damage_status.code,
                            damage_type: i.damage_type,
                            contours: i.segmentation.contours[0],
                        });
                    });
            }

            setDamages(arrDamages);
            dispatch(clearStatus());
        }
    }, [statusUpdate]);

    React.useEffect(() => {
        if (!isDrawing.current) {
            if (points.has('new-damage')) {
                setDamagesCreated([
                    ...damagesCreated,
                    {
                        id:
                            damagesCreated.length === 0 && damages.length === 0
                                ? damagesCreated.length + 1
                                : damagesCreated.length === 0
                                ? damages[damages.length - 1].id + 1
                                : damagesCreated[damagesCreated.length - 1].id + 1,
                        damage_id: `p${(+new Date()).toString(18)}`,
                        damage_status: '',
                        damage_type: damageType,
                        contours: points.get('new-damage')?.pointsArr ?? [],
                    },
                ]);

                setChecked([...checked, `p${(+new Date()).toString(18)}`]);
                setPoints(
                    points.set(`p${(+new Date()).toString(18)}`, {
                        pointsArr: points.get('new-damage')?.pointsArr ?? [],
                        color: DAMAGE_COLOR[damageType],
                        id:
                            damagesCreated.length === 0 && damages.length === 0
                                ? damagesCreated.length + 1
                                : damagesCreated.length === 0
                                ? damages[damages.length - 1].id + 1
                                : damagesCreated[damagesCreated.length - 1].id + 1,
                    })
                );
                points.delete(`new-damage`);
            }
        }
    }, [isDrawing.current]);

    React.useEffect(() => {
        if (pointsTemp.length > 0) {
            setPoints(
                points.set(`new-damage`, {
                    pointsArr: pointsTemp,
                    color: DAMAGE_COLOR[damageType],
                    id:
                        damagesCreated.length === 0 && damages.length === 0
                            ? damagesCreated.length + 1
                            : damagesCreated.length === 0
                            ? damages[damages.length - 1].id + 1
                            : damagesCreated[damagesCreated.length - 1].id + 1,
                })
            );
        }
    }, [pointsTemp, damageType]);

    React.useEffect(() => {
        if (status === StatusRedux.Succeeded) {
            if (damagesList) {
                let scale = Math.min(width / image.width, height / image.height);
                const obj = new Map();
                const newChecked: string[] = [];

                damagesList.detected_damages
                    ?.filter((damage) => damage.damage_status.code !== 'DELETED')
                    .map((list, index) => {
                        newChecked.push(list.damage_id);
                        obj.set(list.damage_id, {
                            pointsArr: list.segmentation.contours.map((p) => {
                                let points: Array<{ x: number; y: number }> = [];

                                p.forEach((point) => {
                                    points.push({ x: Math.floor(point.x * scale), y: Math.floor(point.y * scale) });
                                });

                                points.push({ x: points[0].x, y: points[0].y });
                                return points;
                            })[0],
                            color: DAMAGE_COLOR[list.damage_type],
                            id: index + 1,
                        });
                    });

                setPoints(obj);
                setChecked(newChecked);
            }

            dispatch(clearStatusDamage());
        }
    }, [damagesList, status]);

    return (
        <>
            <Paper sx={classes.paper} elevation={3}>
                <Backdrop sx={classes.backdrop} open={progress}>
                    <CircularProgress color='secondary' />
                </Backdrop>
                <Box sx={classes.container}>
                    <Box sx={classes.menuLeft}>
                        <div>
                            <List component='nav'>
                                <ListItemButton onClick={handleEdit}>
                                    <Tooltip title={!edit ? 'Edit' : 'Move'} placement='left-end'>
                                        <ListItemIcon>{!edit ? <CreateIcon /> : <PanToolIcon />}</ListItemIcon>
                                    </Tooltip>
                                </ListItemButton>
                                <Divider />
                            </List>
                            <List component='nav'>
                                <ListItemButton onClick={handleOpenDamagesList}>
                                    <Tooltip title='Damages List' placement='left-end'>
                                        <ListItemIcon>
                                            <FormatListNumberedIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                </ListItemButton>
                            </List>
                        </div>
                        <List component='nav'>
                            <ListItemButton disabled={damagesCreated.length === 0} onClick={handleSave}>
                                <Tooltip title='Save' placement='left-end'>
                                    <ListItemIcon>
                                        <SaveIcon />
                                    </ListItemIcon>
                                </Tooltip>
                            </ListItemButton>
                        </List>
                    </Box>
                    {props.pic && (
                        <Konva
                            wheel={true}
                            konvaRef={ref}
                            style={classes.stage(!edit ? 'all-scroll' : 'pointer')}
                            width={width}
                            height={height}
                            drawing={{
                                edit: edit,
                                coordinates: coordinates,
                                onMousemove: handleMouseMove,
                                onMouseDown: handleMouseDown,
                                onMouseUp: handleMouseUp,
                            }}
                        >
                            <Layer>
                                <Group
                                    width={imgSize.width}
                                    height={imgSize.height}
                                    x={coordinates.x}
                                    y={coordinates.y}
                                    ref={imgRef}
                                    draggable={!edit}
                                    onDragMove={handleMove}
                                    onDragEnd={handleMove}
                                >
                                    <Pic width={imgSize.width} height={imgSize.height} image={image.img} />
                                    {Array.from(points.values()).map((line, i) => (
                                        <Group key={i}>
                                            <Group>
                                                <Rect width={16} height={16} fill='#000' x={line.pointsArr[0].x - 20} y={line.pointsArr[0].y - 18} />
                                                <Text
                                                    width={16}
                                                    height={16}
                                                    fill='#fff'
                                                    shadowColor='red'
                                                    x={line.pointsArr[0].x - 20}
                                                    y={line.pointsArr[0].y - 14.5}
                                                    align='center'
                                                    fontSize={10}
                                                    text={`${line.id}`}
                                                />
                                            </Group>
                                            <Line
                                                key={i}
                                                points={line.pointsArr.map((i) => Object.values(i)).reduce((acc, val) => acc.concat(val))}
                                                stroke={line.color}
                                                strokeWidth={2.5}
                                                tension={0.7}
                                                lineCap='square'
                                                globalCompositeOperation='source-over'
                                            />
                                        </Group>
                                    ))}
                                </Group>
                            </Layer>
                        </Konva>
                    )}
                    {damageList && (
                        <Box sx={classes.listDamages}>
                            <div>
                                <List
                                    subheader={
                                        <ListSubheader sx={classes.listHeader} component='div'>
                                            Damages List
                                        </ListSubheader>
                                    }
                                >
                                    {damages !== null &&
                                        damages.map((value) => {
                                            const labelId = `checkbox-list-label-${value.damage_id}`;

                                            return (
                                                <div key={value.damage_id}>
                                                    <ListItem dense button onClick={handleToggle(value, false)}>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge='start'
                                                                checked={checked.indexOf(value.damage_id) !== -1}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText id={labelId} primary={`#${value.id} - ${DAMAGE_NAME[value.damage_type]}`} />
                                                        <ListItemSecondaryAction>
                                                            <IconButton
                                                                edge='end'
                                                                aria-label='comments'
                                                                onClick={handleClickPopup(value.damage_id, true)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <Divider />
                                                </div>
                                            );
                                        })}
                                </List>
                                {damagesCreated.length !== 0 && (
                                    <List
                                        subheader={
                                            <ListSubheader sx={classes.listHeader} component='div'>
                                                My Damages List
                                            </ListSubheader>
                                        }
                                    >
                                        {damagesCreated.map((value) => {
                                            const labelId = `checkbox-list-label-${value.damage_id}`;

                                            return (
                                                <div key={value.damage_id}>
                                                    <ListItem dense button onClick={handleToggle(value, true)}>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge='start'
                                                                checked={checked.indexOf(value.damage_id) !== -1}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText id={labelId} primary={`#${value.id} - ${DAMAGE_NAME[value.damage_type]}`} />
                                                        <ListItemSecondaryAction>
                                                            <IconButton
                                                                edge='end'
                                                                aria-label='comments'
                                                                onClick={handleClickPopup(value.damage_id, false)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <Divider />
                                                </div>
                                            );
                                        })}
                                    </List>
                                )}
                            </div>
                            <Box sx={classes.typeBlock}>
                                <FormControl fullWidth size='small' variant='outlined'>
                                    <DamageType damageType={damageType} handleChangeDamage={handleChangeDamage} />
                                </FormControl>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Paper>
            <Dialog
                open={removeDamagePopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClickPopup()}
                aria-labelledby='alert-dialog-remove-title'
                aria-describedby='alert-dialog-slide-description'
            >
                <DialogTitle id='alert-dialog-slide-title'>{'Are you sure you want to delete?'}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClickPopup()} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={handleRemoveDamage} color='primary'>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={popupDamageType}
                TransitionComponent={Transition}
                keepMounted
                onClose={handlePopupDamageType}
                aria-labelledby='alert-dialog-remove-title'
                aria-describedby='alert-dialog-slide-description'
            >
                <DialogTitle sx={classes.dialogTitle} id='alert-dialog-slide-title'>
                    {'Choose Damage Type'}
                </DialogTitle>
                <DialogContent>
                    <DamageType damageType={damageType} handleChangeDamage={handleChangeDamage} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePopupDamageType} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={handlePopupDamageType} color='primary'>
                        Choose
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const DamageType = (props: { damageType: number; handleChangeDamage: Function }) => {
    const handleChangeDamage = (event: any) => {
        props.handleChangeDamage(event);
    };

    return (
        <Box sx={{ padding: '7px 10px' }}>
            <FormControl fullWidth size='small' variant='outlined'>
                <InputLabel id='damage-type-label'>Damage Type</InputLabel>
                <Select labelId='damage-type-label' id='damage-type-label' value={props.damageType} onChange={handleChangeDamage} label='Damage Type'>
                    <MenuItem value={0}>Choose Damage Type</MenuItem>
                    <MenuItem value={DAMAGE_NAME.Dented}>{DAMAGE_NAME[DAMAGE_NAME.Dented]}</MenuItem>
                    <MenuItem value={DAMAGE_NAME.Cracked}>{DAMAGE_NAME[DAMAGE_NAME.Cracked]} </MenuItem>
                    <MenuItem value={DAMAGE_NAME.Scratched}>{DAMAGE_NAME[DAMAGE_NAME.Scratched]}</MenuItem>
                    <MenuItem value={DAMAGE_NAME.Hail}>{DAMAGE_NAME[DAMAGE_NAME.Hail]}</MenuItem>
                    <MenuItem value={DAMAGE_NAME.Rust}>{DAMAGE_NAME[DAMAGE_NAME.Rust]}</MenuItem>
                    <MenuItem value={DAMAGE_NAME['Broken lights']}>{DAMAGE_NAME[DAMAGE_NAME['Broken lights']]}</MenuItem>
                    <MenuItem value={DAMAGE_NAME.Detachment}>{DAMAGE_NAME[DAMAGE_NAME.Detachment]}</MenuItem>
                    <MenuItem value={DAMAGE_NAME['Paint Peel']}>{DAMAGE_NAME[DAMAGE_NAME['Paint Peel']]}</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} children={props.children} />;
});

export default Damages;
