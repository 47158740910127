export enum Filter {
    BUSINESS_UNIT_ID = 'business_unit_id',
    INSPECTION_ID = 'inspection_id',
    COMPANY_ID = 'company_id',
    CLIENT_NAME = 'client_name',
    CLIENT_PID = 'client_process_id',
    STATUS = 'inspection_statuses',
    INSPECTION_TYPE = 'inspection_types',
    CLOSURE_REASON = 'closure_reason',
    RESULT_NOT_SENT = 'call_back_response_result_not_sent',
    FROM_DATE = 'from_date',
    TO_DATE = 'to_date',
    DATES = 'dates',
    LPN = 'lpn',
    VIN = 'vin',
}
