import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../store';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { classes } from './style';
import { StatusRedux } from '../../../enums/StatusRedux';
import { Spinner } from '../../spinner';
import { Error } from '../../error';
import { useContext } from 'react';
import { CustomAuthContext } from '../../../context/AuthProvider';
import Paper from '@mui/material/Paper';
import { TabsProps, TabPanel } from '../../../common/ui-components/Tab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Autocomplete, ListItem, TextField } from '@mui/material';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { UserPermission } from '../../../config';
import { useSnackbar } from 'notistack';
import useCheckInputPassword from '../../../hooks/validation/use-input-password';
import { ClearStoreChangePassword, fetchUserChangePass } from '../../../store/users/change-password';
import { clearStore, fetchUserUpdate } from '../../../store/users/users';
import useCheckInputEmail from '../../../hooks/validation/use-input-email';
import useCheckInputText from '../../../hooks/validation/use-input-text';
import { fetchGetBusinessUnits } from '../../../store/business-units/business-units';
import { fetchUserById } from '../../../store/users/user';
import { IArgsRouteUser, IChangePassword } from '../../../infrastructure/DTO/users/user-view.dto';
import { IUserUpdate } from '../../../infrastructure/DTO/users/user.dto';
import { IArgsBusinessUnit, IBusinessUnit } from '../../../infrastructure/DTO/bussiness-unit/business-units.dto';
import errorParseMessage from "../../../config/error-parse";

type UserInfoType = {
    company_id: string;
    company_name: string;
    email: string;
    name: string;
    user_id: string;
};

interface Fields {
    name: null;
}

const ProfileCompany: React.FC = () => {
    const { checkPermission, parseToken, token } = useContext(CustomAuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const { inputPassword, checkValuePassword } = useCheckInputPassword();

    const dispatch: AppDispatch = useDispatch();
    const [disable, setDisable] = React.useState<boolean>(false);

    const { inputEmail, checkValueEmail } = useCheckInputEmail(parseToken().sub);
    const { inputsText, message, checkValueText } = useCheckInputText<Fields>({
        name: null,
    });

    const [businessUnit, setBusinessUnit] = React.useState<Array<IBusinessUnit>>([]);
    const [businessUnitSelect, setBusinessUnitSelect] = React.useState<IBusinessUnit | null>(null);

    const businessUnits = useSelector((state: AppState) => state.businessUnits);
    const { userDataUpdateStatus, userDataUpdateError } = useSelector((state: AppState) => state.users);
    const user = useSelector((state: AppState) => state.user);

    const [userData, setUserData] = React.useState<{ name: string; email: string }>({ name: '', email: '' });

    const { userChangePswStatus, userChangePswError } = useSelector((state: AppState) => state.changeUserPassword);

    const [change, setChangePassword] = React.useState<IChangePassword>({
        password: '',
        password_confirmation: '',
        validate_policy: true,
    });
    const [userInfo, setInfoUser] = React.useState<UserInfoType>({
        company_id: '',
        company_name: '',
        email: '',
        name: '',
        user_id: '',
    });
    const [value, setValue] = React.useState<number>(!checkPermission(UserPermission.USER_MANAGEMENT) ? 0 : 1);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        const argsBu: IArgsBusinessUnit = {
            company_id: parseToken().company as string,
            limit: null,
            offset: null,
        };

        dispatch(
            fetchGetBusinessUnits({
                token: token,
                args: argsBu,
            })
        );

        const args: IArgsRouteUser = {
            company_id: parseToken().company,
            user_id: parseToken().sub,
        };

        dispatch(
            fetchUserById({
                token,
                args,
            })
        );
    }, []);

    const handleChangePasswordInput = (event: any) => {
        const { name, value } = event.target;

        setChangePassword({
            ...change,
            [name]: value,
        });
    };

    const handleSubmitChangePassword = (event: any) => {
        event.preventDefault();

        const args: IArgsRouteUser = {
            company_id: parseToken().company,
            user_id: parseToken().sub,
        };

        dispatch(
            fetchUserChangePass({
                token: token,
                body: change,
                args: args,
            })
        );
    };

    React.useEffect(() => {
        if (userChangePswStatus === StatusRedux.Succeeded) {
            enqueueSnackbar('Password Updated Successfully', {
                variant: 'success',
            });
            setChangePassword({
                ...change,
                password: '',
                password_confirmation: '',
            });
        }
        if (userChangePswStatus === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(userChangePswError), {
                variant: 'error',
            });
        }

        dispatch(ClearStoreChangePassword());
    }, [userChangePswStatus]);

    React.useEffect(() => {
        if (user.status === StatusRedux.Succeeded && businessUnits.status === StatusRedux.Succeeded) {
            setBusinessUnit(businessUnits.businessUnits.business_units);
            setInfoUser({
                company_id: user.user.company?.company_id as string,
                company_name: user.user.company?.name as string,
                email: user.user.email as string,
                name: user.user.name as string,
                user_id: parseToken().sub,
            });

            const findBu = businessUnits.businessUnits.business_units.find((b) => b.business_unit_id === user.user.business_unit?.business_unit_id);

            if (findBu !== undefined) {
                setBusinessUnitSelect(findBu);
            }

            setUserData({
                name: user.user.name as string,
                email: user.user.email as string,
            });
        }
    }, [user.status, businessUnits.status]);

    const handleUpdateUser = (event: any) => {
        event.preventDefault();

        const body: IUserUpdate = {
            name: userData.name.trim(),
            email: userData.email.toLowerCase(),
            business_unit_id: businessUnitSelect ? (businessUnitSelect?.business_unit_id as string) : '',
        };

        const args: IArgsRouteUser = {
            company_id: user.user.company?.company_id,
            user_id: user.user?.user_id,
        };

        dispatch(
            fetchUserUpdate({
                token,
                body,
                args,
            })
        );
    };

    const handleUserChangeInput = (event: any) => {
        const { name, value } = event.target;
        setUserData({
            ...userData,
            [name]: value,
        });

        setInfoUser({
            ...userInfo,
            [name]: value,
        });
    };

    React.useEffect(() => {
        if (inputEmail.error || inputsText?.name) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [inputEmail.error, inputsText?.name]);

    React.useEffect(() => {
        if (userDataUpdateStatus === StatusRedux.Succeeded) {
            enqueueSnackbar('User Updated Successfully', {
                variant: 'success',
            });
        }

        if (userDataUpdateStatus === StatusRedux.Failed) {
            enqueueSnackbar(errorParseMessage(userDataUpdateError), {
                variant: 'error',
            });
        }

        dispatch(clearStore());
    }, [userDataUpdateStatus]);

    return (
        <>
            <Grid container spacing={5} direction='row' justifyContent='center' alignItems='center' sx={classes.mainHeaderPage}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h6' noWrap sx={classes.titlePage}>
                        My Profile
                    </Typography>
                </Grid>
            </Grid>
            <Paper elevation={3} sx={classes.mainPage}>
                <Grid container>
                    <Grid item xs>
                        {(() => {
                            switch (user.status) {
                                case StatusRedux.Loading:
                                    return <Spinner />;
                                case StatusRedux.Succeeded:
                                    return (
                                        <div>
                                            <List>
                                                {Object.entries(userInfo).map(([key, user], index) => (
                                                    <div key={index}>
                                                        <ListItem
                                                            secondaryAction={
                                                                <IconButton
                                                                    onClick={() => {
                                                                        navigator.clipboard.writeText(user).then((r) => r);
                                                                    }}
                                                                    edge='end'
                                                                    aria-label='delete'
                                                                >
                                                                    <ContentCopyIcon />
                                                                </IconButton>
                                                            }
                                                        >
                                                            <ListItemText primary={`${key.replace('_', ' ').toUpperCase()}: ${user}`} />
                                                        </ListItem>
                                                        <Divider variant='inset' component='li' />
                                                    </div>
                                                ))}
                                            </List>
                                        </div>
                                    );
                                case StatusRedux.Failed:
                                    return <Error msg={user.error.errors[0].message} />;
                            }
                        })()}
                    </Grid>
                    {!checkPermission(UserPermission.USER_MANAGEMENT) && (
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            {(() => {
                                switch (user.status) {
                                    case StatusRedux.Loading:
                                        return <Spinner />;
                                    case StatusRedux.Succeeded:
                                        return (
                                            <div>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <Tabs value={value} onChange={handleChangeTab} aria-label='basic tabs example'>
                                                        <Tab label='Edit Profile' {...TabsProps(0)} />
                                                        <Tab label='Change Password' {...TabsProps(1)} />
                                                    </Tabs>
                                                </Box>
                                                <TabPanel value={value} index={0}>
                                                    <Box mt={2} p={2}>
                                                        <form autoComplete='off' onSubmit={handleUpdateUser}>
                                                            <Grid container spacing={3} justifyContent='flex-end'>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        size='small'
                                                                        fullWidth
                                                                        name='name'
                                                                        required={true}
                                                                        type='text'
                                                                        label='Name'
                                                                        variant='outlined'
                                                                        value={userData?.name.trimStart() as string}
                                                                        onChange={handleUserChangeInput}
                                                                        onKeyUp={checkValueText}
                                                                        helperText={message?.name}
                                                                        error={inputsText?.name}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        size='small'
                                                                        error={inputEmail.error}
                                                                        helperText={inputEmail.error ? inputEmail.errorMsg : null}
                                                                        fullWidth
                                                                        name='email'
                                                                        required={true}
                                                                        id='outlined-basic'
                                                                        type='email'
                                                                        label='E-mail'
                                                                        variant='outlined'
                                                                        value={userData?.email}
                                                                        onChange={handleUserChangeInput}
                                                                        onKeyUp={checkValueEmail}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Autocomplete
                                                                        size='small'
                                                                        value={businessUnitSelect}
                                                                        disablePortal
                                                                        autoHighlight
                                                                        fullWidth
                                                                        onChange={(event: any, newValue: IBusinessUnit | null) => {
                                                                            if (newValue !== null) {
                                                                                setBusinessUnitSelect(newValue);
                                                                            } else {
                                                                                setBusinessUnitSelect(null);
                                                                            }
                                                                        }}
                                                                        id='bu-edit'
                                                                        options={
                                                                            businessUnit.length !== 0 ? businessUnit.filter((b) => b.is_active) : []
                                                                        }
                                                                        getOptionLabel={(option: IBusinessUnit) => (option.name ? option.name : '')}
                                                                        renderInput={(params) => <TextField {...params} label='Business Unit' />}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        size='small'
                                                                        disabled={disable}
                                                                        type='submit'
                                                                        variant='contained'
                                                                        color='secondary'
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </form>
                                                    </Box>
                                                </TabPanel>
                                                <TabPanel value={value} index={1}>
                                                    <Box mt={2} p={2}>
                                                        <form autoComplete='off' onSubmit={handleSubmitChangePassword}>
                                                            <Grid container spacing={3} justifyContent='flex-end'>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        size='small'
                                                                        fullWidth
                                                                        required={true}
                                                                        name='password'
                                                                        error={inputPassword.error}
                                                                        helperText={inputPassword.error ? inputPassword.errorMsg : null}
                                                                        id='password'
                                                                        type='password'
                                                                        label='Password'
                                                                        variant='outlined'
                                                                        value={change.password}
                                                                        onChange={handleChangePasswordInput}
                                                                        onBlur={checkValuePassword}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        size='small'
                                                                        fullWidth
                                                                        name='password_confirmation'
                                                                        error={
                                                                            change.password_confirmation !== '' &&
                                                                            change.password !== change.password_confirmation
                                                                        }
                                                                        helperText={
                                                                            change.password_confirmation !== '' &&
                                                                            change.password !== change.password_confirmation &&
                                                                            'Passwords do not match!'
                                                                        }
                                                                        required={true}
                                                                        id='password_confirmation'
                                                                        type='password'
                                                                        label='Confirm Password'
                                                                        variant='outlined'
                                                                        value={change.password_confirmation}
                                                                        onChange={handleChangePasswordInput}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        disabled={
                                                                            inputPassword.error || change.password !== change.password_confirmation
                                                                        }
                                                                        size='small'
                                                                        type='submit'
                                                                        variant='contained'
                                                                        color='secondary'
                                                                    >
                                                                        Reset Password
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </form>
                                                    </Box>
                                                </TabPanel>
                                            </div>
                                        );
                                    case StatusRedux.Failed:
                                        return <Error msg={user.error.errors[0].message} />;
                                }
                            })()}
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </>
    );
};

export default ProfileCompany;
