import React from 'react';
import moment from 'moment';
import { ICompanyReport } from '../../../../infrastructure/DTO/company/company-report.dto';

interface IExportExcelComponent {
    company_name?: string;
    enumerateDates: Array<Date>;
    inspectionsType: Array<string>;
    inspectionsStatus: Array<string>;
    fromDate: Date | null;
    toDate: Date | null;
    byDate: boolean;
    monthTable: Array<string>;
    datesTable: Array<Date>;
    weekTable: Array<string>;
    reports: ICompanyReport | null;
    tableName: any;
    tableRef: any;
}

const ExportExcel: React.FC<IExportExcelComponent> = (props: IExportExcelComponent) => {
    return (
        <div style={{ display: 'none' }}>
            {
                <table ref={props.tableRef} id={props.tableName}>
                    <thead>
                        <tr>
                            <th>Business Unit Name</th>
                            {props.monthTable.map((month, index) => (
                                <th align='center' key={index}>
                                    {month}
                                </th>
                            ))}
                            {props.weekTable.map((week, index) => (
                                <th align='center' key={index}>
                                    {week}
                                </th>
                            ))}
                            {props.byDate && props.datesTable.map((date, index) => <th key={index}>{`${moment(date).format('DD/MM/YYYY')}`}</th>)}
                            {!props.byDate && props.fromDate !== null && (
                                <th>{`${moment(props.fromDate).format('DD/MM/YYYY HH:mm')} - ${moment(props.toDate).format('DD/MM/YYYY HH:mm')}`}</th>
                            )}
                            {props.inspectionsType.length > 0 && <th>Inspection Type</th>}
                            {props.inspectionsStatus.length > 0 && <th>Inspection Status</th>}
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.reports?.business_units.map((report, index) => (
                            <tr key={index}>
                                <td>{report.name}</td>
                                {report.statistics.map((d, i) => (
                                    <td key={i}>{d.inspections_count}</td>
                                ))}
                                {props.inspectionsType.length > 0 && <td>{props.inspectionsType.join(', ')}</td>}
                                {props.inspectionsStatus.length > 0 && <td>{props.inspectionsStatus.join(', ')}</td>}
                                <td>{report.statistics.map((d) => d.inspections_count).reduce((total, cnt) => total + cnt, 0)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div>
    );
};

export default ExportExcel;
