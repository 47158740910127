import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { AppBar, DialogActions, Grid, List, ListItem, ListItemText, Paper, Slide, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import { Layer, Image as Pic, Group } from 'react-konva';
import { DAMAGE_TYPE } from '../../../../enums/Damage';
import useResizeObserver from 'use-resize-observer';
import { classes } from './style';
import { TransitionProps } from '@mui/material/transitions';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Damages } from './damages';
//import { Prompt } from 'react-router-dom';
import Konva from '../../../../common/ui-components/Konva';
import { backButtonDisabled } from '../../../../utils/onbeforeunload';
import { JsonViewer, JsonViewerTheme } from '@textea/json-viewer';
import { IImageResponse, IImagesRequest } from '../../../../infrastructure/DTO/inspection/inspection.dto';

interface PopupInfoComponent {
    data: IImageResponse | undefined;
    img_id: string;
    img: string;
    open: boolean;
    handleClose: Function;
    themeJson: JsonViewerTheme;
}

interface TabPanelProps {
    children?: React.ReactNode;
    padding: number;
    index: any;
    value: any;
}

const PopupInfo: React.FC<PopupInfoComponent> = (props: PopupInfoComponent) => {
    const { inspectionImagesData, inspectionImagesDataRequest } = useSelector((state: AppState) => state.inspectionUploadImages);
    const [tabValue, setTabValue] = React.useState<number>(0);
    const [state, setState] = React.useState<{ checked: boolean; disabled: boolean }>({
        checked: false,
        disabled: false,
    });
    const [jsonData, setJsonData] = React.useState({
        reqData: {},
        resData: {},
    });
    const [coordinates, setCoordinates] = React.useState({ x: 0, y: 0 });
    const [damagesData, setDamagesData] = React.useState<Array<any>>([]);
    const [image, setImage] = React.useState({
        img: new window.Image(),
        width: 0,
        height: 0,
    });
    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
    const [imgSize, setImgSize] = React.useState({
        width: 0,
        height: 0,
    });

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        if (image.width !== 0 && image.height !== 0) {
            let scale = Math.min(width / image.width, height / image.height);

            setCoordinates({
                x: width / 2 - (image.width * scale) / 2,
                y: height / 2 - (image.height * scale) / 2,
            });

            setImgSize({
                width: image.width * scale,
                height: image.height * scale,
            });
        }
    }, [image.width, image.height]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const img = new window.Image();
        const requestJson: any = inspectionImagesDataRequest.find((reqItem: IImagesRequest) => reqItem.image_id === props.img_id);
        const responseJson = inspectionImagesData.find((resItem: any) => resItem.image_id === props.img_id);

        if (event.target.checked) {
            img.src = `data:image/jpeg;base64, ${responseJson?.damage_overlay}`;
            img.onload = () => {
                setImage({
                    img: img,
                    width: img.width,
                    height: img.height,
                });
            };
        } else {
            img.src = `data:image/jpeg;base64, ${requestJson.content}`;
            img.onload = () => {
                setImage({
                    img: img,
                    width: img.width,
                    height: img.height,
                });
            };
        }
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleClose = () => {
        props.handleClose();
        setTabValue(0);
    };

    React.useEffect(() => {
        if (props.open && props.data !== undefined) {
            let arr: any[] = [];

            inspectionImagesData.map((i) => {
                if (i.image_id === props.img_id) {
                    i.detected_damages
                        ?.filter((i) => i.damage_status.code !== 'DELETED')
                        .map((item) => {
                            item.vehicle_parts !== undefined &&
                                item.vehicle_parts.map((damage) => {
                                    arr.push({ id: damage.part_id, name: damage.part_description, type: DAMAGE_TYPE(item.damage_type) });
                                });
                        });
                }
            });

            setDamagesData(arr);

            const requestJson: any = inspectionImagesDataRequest.find((reqItem: IImagesRequest) => reqItem.image_id === props.img_id);
            let imgT = document.createElement('img');
            imgT.src = 'data:image/jpeg;base64,' + requestJson.content;

            let res = {
                image_id: requestJson.image_id,
                name: requestJson.name,
                content_type: requestJson.content_type,
                content: requestJson.content,
                context: requestJson.context,
                resolution: {
                    width: 0,
                    height: 0,
                },
            };

            const responseJson = inspectionImagesData.find((resItem) => resItem.image_id === props.img_id);
            backButtonDisabled(true);

            setJsonData({
                reqData: { images: [res] },
                resData: {
                    images: [
                        {
                            ...responseJson,
                            thumbnail_image: responseJson?.thumbnail_image,
                            damage_overlay: responseJson?.damage_overlay,
                        },
                    ],
                },
            });
        }
    }, [props, inspectionImagesData]);

    React.useEffect(() => {
        if (props.open) {
            const img = new window.Image();
            const requestJson: any = inspectionImagesDataRequest.find((reqItem: IImagesRequest) => reqItem.image_id === props.img_id);

            img.src = `data:image/jpeg;base64, ${requestJson.content}`;
            img.onload = () => {
                setImage({
                    img: img,
                    width: img.width,
                    height: img.height,
                });
            };

            setState({
                ...state,
                checked: false,
            });
        }
    }, [props.img_id, tabValue]);

    return (
        <Dialog fullScreen TransitionComponent={Transition} open={props.open} onClose={handleClose} aria-labelledby='max-width-dialog-title'>
            {/*<Prompt when={props.open} message={() => 'Are you sure you want to go back? All data will be lost!'} />*/}
            <AppBar position='static' color='default'>
                <Tabs value={tabValue} onChange={handleChangeTab} indicatorColor='primary' textColor='primary'>
                    <Tab label='Preview' {...TabProps(0)} />
                    <Tab label='Damages List' {...TabProps(1)} />
                    <Tab label='Request' {...TabProps(2)} />
                    <Tab label='Response' {...TabProps(3)} />
                </Tabs>
            </AppBar>
            <DialogContent sx={classes.dialogContent}>
                <TabPanel value={tabValue} padding={3} index={0}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Paper elevation={3} sx={classes.pd}>
                                <Konva wheel={true} konvaRef={ref} style={classes.stage} width={width} height={height}>
                                    <Layer>
                                        <Group draggable x={coordinates.x} y={coordinates.y}>
                                            <Pic image={image.img} width={imgSize.width} height={imgSize.height} />
                                        </Group>
                                    </Layer>
                                </Konva>
                                <Box sx={classes.imgBottomBlock}>
                                    <Box sx={classes.panelButtons}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    disabled={damagesData.length === 0}
                                                    checked={state.checked}
                                                    onChange={handleChange}
                                                    name='checked'
                                                    color='primary'
                                                />
                                            }
                                            label='Show damages'
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper elevation={3} sx={classes.listDetectedDamages}>
                                <Typography variant='h6'>Detected damages:</Typography>
                                <List>
                                    {damagesData.map((item: { id: string; name: string; type: string }, index: number) => {
                                        return (
                                            <div key={index}>
                                                <ListItem>
                                                    <ListItemText primary={`#${index + 1}: ${item.name} - ${item.type}`} />
                                                </ListItem>
                                                <Divider variant='inset' component='li' />
                                            </div>
                                        );
                                    })}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box sx={classes.imgBottomBlockText}>
                                {props.data?.image_quality_result?.status === 'FAILED' && (
                                    <div>Alert Image Quality: {props.data?.image_quality_result?.message}</div>
                                )}
                                {props.data?.image_classification === 'INTERIOR' ||
                                props.data?.image_classification === 'NO_CAR' ||
                                props.data?.image_classification === 'SEVERELY_DAMAGED' ? (
                                    <div>Alert Image Classification: {props.data?.image_classification.replace('_', ' ')}</div>
                                ) : null}
                            </Box>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel index={tabValue} padding={0} value={1}>
                    <Damages pic={props.img} data={inspectionImagesData} image_id={props.img_id} />
                </TabPanel>
                <TabPanel index={tabValue} padding={2} value={2}>
                    <JsonViewer theme={props.themeJson} value={jsonData.reqData} />
                </TabPanel>
                <TabPanel index={tabValue} padding={2} value={3}>
                    <JsonViewer theme={props.themeJson} value={jsonData.resData} />
                </TabPanel>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopupInfo;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} children={props.children} />;
});

function TabProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, padding, ...other } = props;

    return (
        <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
            {value === index && <Box p={padding}>{children}</Box>}
        </div>
    );
}
