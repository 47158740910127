import { Button, Dialog, Slide } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import * as React from 'react';
import { TransitionProps } from '@mui/material/transitions';

type PropsType = {
    open: boolean;
    close: Function;
    back: Function;
    titleText?: string;
    description?: string;
};

const PopupBack: React.FC<PropsType> = (props: PropsType) => {
    const [open, setOpen] = React.useState<boolean>(props.open);

    const close = () => {
        props.close();
    };

    const back = () => {
        props.back();
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <Dialog
            sx={{ zIndex: 9999, overflowX: 'hidden' }}
            maxWidth='xs'
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-labelledby='alert-dialog-back'
            aria-describedby='alert-dialog-back-description'
        >
            <DialogTitle id='alert-back-remove'>{props.titleText ?? 'Go Back'}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-back-description'>
                    {props.description ?? 'You changes will not be saved. Are you sure you want to leave?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color='primary'>
                    No
                </Button>
                <Button onClick={back} color='primary'>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopupBack;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} children={props.children} />;
});
