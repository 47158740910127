import React from 'react';
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { ISearchInput } from '../interface/ISearchInput';

const SearchInput: React.FC<ISearchInput> = (props: ISearchInput) => {
    return (
        <FormControl fullWidth variant='standard'>
            <InputLabel htmlFor='search'>{props.name}</InputLabel>
            <Input
                value={props.searchText}
                onKeyUp={props.handleSearch}
                onChange={props.handleChangeSearchText}
                id='search'
                size='small'
                type='text'
                name={props.name}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton color='primary' component='span' onClick={props.handleSearch}>
                            <SearchIcon sx={{ display: props.searchText.length > 0 ? 'block' : 'none' }} />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

export default SearchInput;
