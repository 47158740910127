import { CSSObject, keyframes, Theme } from '@mui/material';

const fadeIn = keyframes`
    "0%": {
      opacity: 0
    },
    "100%": {
        opacity: 1
    }
`;

const tableRow = (theme: Theme): CSSObject => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer',
});

const emptyDiv = (): CSSObject => ({
    width: '100%',
    minHeight: '600px',
    height: '100%',
    fontSize: '1.2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const head = (theme: Theme): CSSObject => ({
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    minWidth: '100px',
});

const table = (theme: Theme): CSSObject => ({
    height: '730px',
    animation: `${fadeIn} 500ms ${theme.transitions.easing.easeInOut}`,
});

export const classes = {
    table,
    head,
    emptyDiv,
    tableRow,
};
