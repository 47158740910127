import React, { PropsWithChildren } from 'react';
import { CSSObject, IconButton, styled, Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { classes } from '../style';

interface IDamageLabMenuComponent {
    anchor: 'left' | 'top' | 'right' | 'bottom';
    open: boolean;
    toggleDrawer: (anchor: 'left' | 'top' | 'right' | 'bottom', open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
    children: React.ReactNode;
    iconClose: any;
    titleHeader?: string;
    drawerWidth: string;
}

const openedMixin = (theme: Theme, props: IDamageLabMenuComponent): CSSObject => ({
    ...(props.anchor !== 'bottom' && {
        width: props.drawerWidth,
    }),
    ...(props.anchor === 'bottom' && {
        '& > div': { height: '280px' },
    }),
    transition: theme.transitions.create(props.anchor !== 'bottom' ? 'width' : 'height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    ...(props.anchor !== 'bottom' && {
        overflowX: 'hidden',
    }),
    ...(props.anchor === 'bottom' && {
        overflowY: 'hidden',
    }),
});

const closedMixin = (theme: Theme, props: IDamageLabMenuComponent): CSSObject => ({
    ...(props.anchor !== 'bottom' && {
        width: 0,
    }),
    ...(props.anchor === 'bottom' && {
        height: 0,
    }),
    transition: theme.transitions.create(props.anchor !== 'bottom' ? 'width' : 'height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(props.anchor !== 'bottom' && {
        overflowX: 'hidden',
    }),
    ...(props.anchor === 'bottom' && {
        overflowY: 'hidden',
    }),
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'IDamageLabMenuComponent' })<{ props: IDamageLabMenuComponent }>(
    ({ theme, props }) => ({
        width: props.drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(props.open && {
            ...openedMixin(theme, props),
            '& .MuiDrawer-paper': {
                ...openedMixin(theme, props),
                position: 'absolute',
                ...(props.anchor === 'bottom' && {
                    bottom: 0,
                }),
                backdropFilter: 'blur(10px)',
                background: theme.palette.mode === 'light' ? 'rgb(255 255 255 / 70%)' : 'rgb(0 0 0 / 70%)',
            },
        }),
        ...(!props.open && {
            ...closedMixin(theme, props),
            '& .MuiDrawer-paper': {
                ...closedMixin(theme, props),
                position: 'absolute',
                ...(props.anchor === 'bottom' && {
                    bottom: 0,
                }),
                backdropFilter: 'blur(10px)',
                background: theme.palette.mode === 'light' ? 'rgb(255 255 255 / 70%)' : 'rgb(0 0 0 / 70%)',
            },
        }),
    })
);

const DamageLabMenu: React.FC<PropsWithChildren<IDamageLabMenuComponent>> = (props: IDamageLabMenuComponent) => {
    return (
        <Drawer variant='permanent' anchor={props.anchor} open={props.open} props={props}>
            <Box pt={1} sx={props.anchor !== 'bottom' ? classes.otherHeader : classes.bottomHeader}>
                <IconButton
                    sx={{ ml: 1 }}
                    onClick={props.toggleDrawer(props.anchor, props.open)}
                    onKeyDown={props.toggleDrawer(props.anchor, props.open)}
                >
                    {props.iconClose}
                </IconButton>
                <Typography ml={1} variant='button' display='block'>
                    {props.titleHeader}
                </Typography>
            </Box>
            {props.children}
        </Drawer>
    );
};

export default DamageLabMenu;
