import { createTheme } from '@mui/material/styles';

const themeLight = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#000133',
            light: '#388e3c',
            dark: '#1a237e',
        },
        secondary: {
            main: '#388e3c',
            light: '#388e3c',
            dark: '#388e3c',
        },
        error: {
            main: '#d50000',
        },
        background: {
            paper: '#ffffff',
            default: '#F5F5F5',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    direction: 'ltr',
});

export default themeLight;
