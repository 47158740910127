import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import PermissionServices from '../../services/permission/permission';
import { IFetchArgs, IFetchBody } from '../IFetch';
import { IArgsPermission, IPermission, IPermissions } from '../../infrastructure/DTO/permission/permission.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchAllPermissions = createAsyncThunk<IPermissions, IFetchArgs<null>>('permissions/all', async (params: IFetchArgs<null>) => {
    return await PermissionServices.getPermissions(params);
});

export const fetchCreatePermissions = createAsyncThunk<IPermission, IFetchBody<IPermission>>(
    'permissions/create',
    async (params: IFetchBody<IPermission>) => {
        return await PermissionServices.createPermission(params);
    }
);

export const fetchUpdatePermissions = createAsyncThunk<IPermission, IFetchBody<IPermission, IArgsPermission>>(
    'permissions/update',
    async (params: IFetchBody<IPermission, IArgsPermission>) => {
        return await PermissionServices.updatePermission(params);
    }
);

export const fetchRemovePermissions = createAsyncThunk<any, IFetchArgs<IArgsPermission>>(
    'permissions/remove',
    async (params: IFetchArgs<IArgsPermission>) => {
        return await PermissionServices.removePermission(params);
    }
);

export const fetchPermissionActive = createAsyncThunk<IPermission, IFetchArgs<IArgsPermission>>(
    'permissions/active',
    async (params: IFetchArgs<IArgsPermission>) => {
        return await PermissionServices.activePermission(params);
    }
);

export const fetchPermissionDeactivate = createAsyncThunk<IPermission, IFetchArgs<IArgsPermission>>(
    'permissions/deactivate',
    async (params: IFetchArgs<IArgsPermission>) => {
        return await PermissionServices.deactivatePermission(params);
    }
);

export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState: {
        permissions: {} as IPermissions,
        statusPermissions: '',
        errorPermissions: {} as IErrorResponse,
        statusCreate: '',
        errorCreate: {} as IErrorResponse,
        statusUpdate: '',
        errorUpdate: {} as IErrorResponse,
        statusRemove: '',
        errorRemove: {} as IErrorResponse,
        statusIsActive: '',
        errorIsActive: {} as IErrorResponse,
    },
    reducers: {
        clearStorePermissions: (state) => {
            state.statusPermissions = '';
            state.errorPermissions = {} as IErrorResponse;
        },
        clearStoreCreatePermissions: (state) => {
            state.statusCreate = '';
            state.errorCreate = {} as IErrorResponse;
        },
        clearStoreUpdatePermissions: (state) => {
            state.statusUpdate = '';
            state.errorUpdate = {} as IErrorResponse;
        },
        clearStoreIsActivePermissions: (state) => {
            state.statusIsActive = '';
            state.errorIsActive = {} as IErrorResponse;
        },
        clearStoreRemove: (state) => {
            state.statusRemove = '';
            state.errorRemove = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllPermissions.pending, (state) => {
            state.statusPermissions = StatusRedux.Loading;
        });
        builder.addCase(fetchAllPermissions.fulfilled, (state, action) => {
            state.statusPermissions = StatusRedux.Succeeded;
            state.permissions = action.payload;
        });
        builder.addCase(fetchAllPermissions.rejected, (state, action) => {
            state.statusPermissions = StatusRedux.Failed;
            state.errorPermissions = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchCreatePermissions.pending, (state) => {
            state.statusCreate = StatusRedux.Loading;
        });
        builder.addCase(fetchCreatePermissions.fulfilled, (state, action) => {
            state.statusCreate = StatusRedux.Succeeded;
            state.permissions.permissions.push(action.payload);
        });
        builder.addCase(fetchCreatePermissions.rejected, (state, action) => {
            state.statusCreate = StatusRedux.Failed;
            state.errorCreate = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUpdatePermissions.pending, (state) => {
            state.statusUpdate = StatusRedux.Loading;
        });
        builder.addCase(fetchUpdatePermissions.fulfilled, (state, action) => {
            state.statusUpdate = StatusRedux.Succeeded;

            const index = state.permissions.permissions.findIndex((x: any) => x.permission_id === action.payload.permission_id);
            const name = action.payload.name;
            const description = action.payload.description;
            const is_active = action.payload.is_active;

            state.permissions.permissions = [
                ...state.permissions.permissions.slice(0, index),
                { ...state.permissions.permissions[index], name: name, description: description, is_active: is_active },
                ...state.permissions.permissions.slice(index + 1),
            ];
        });
        builder.addCase(fetchUpdatePermissions.rejected, (state, action) => {
            state.statusUpdate = StatusRedux.Failed;
            state.errorUpdate = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchRemovePermissions.pending, (state) => {
            state.statusRemove = StatusRedux.Loading;
        });
        builder.addCase(fetchRemovePermissions.fulfilled, (state, action) => {
            state.statusRemove = StatusRedux.Succeeded;
        });
        builder.addCase(fetchRemovePermissions.rejected, (state, action) => {
            state.statusRemove = StatusRedux.Failed;
            state.errorRemove = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchPermissionActive.pending, (state) => {
            state.statusIsActive = StatusRedux.Loading;
        });
        builder.addCase(fetchPermissionActive.fulfilled, (state, action) => {
            state.statusIsActive = StatusRedux.Succeeded;

            const index = state.permissions.permissions.findIndex((x: any) => x.permission_id === action.payload.permission_id);
            state.permissions.permissions = [
                ...state.permissions.permissions.slice(0, index),
                { ...state.permissions.permissions[index], is_active: action.payload.is_active },
                ...state.permissions.permissions.slice(index + 1),
            ];
        });
        builder.addCase(fetchPermissionActive.rejected, (state, action) => {
            state.statusIsActive = StatusRedux.Failed;
            state.errorIsActive = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchPermissionDeactivate.pending, (state) => {
            state.statusIsActive = StatusRedux.Loading;
        });
        builder.addCase(fetchPermissionDeactivate.fulfilled, (state, action) => {
            state.statusIsActive = StatusRedux.Succeeded;

            const index = state.permissions.permissions.findIndex((x: any) => x.permission_id === action.payload.permission_id);
            state.permissions.permissions = [
                ...state.permissions.permissions.slice(0, index),
                { ...state.permissions.permissions[index], is_active: action.payload.is_active },
                ...state.permissions.permissions.slice(index + 1),
            ];
        });
        builder.addCase(fetchPermissionDeactivate.rejected, (state, action) => {
            state.statusIsActive = StatusRedux.Failed;
            state.errorIsActive = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreCreatePermissions, clearStoreRemove, clearStoreIsActivePermissions, clearStoreUpdatePermissions, clearStorePermissions } =
    permissionsSlice.actions;
export default permissionsSlice;
