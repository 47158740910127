import React from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../../../../../store";
import {Grid} from "@mui/material";
import {classes} from "./style";
import Box from "@mui/material/Box";


const LicenseNumber: React.FC = () => {
    const { damageLabData } = useSelector((state: AppState) => state.damageLabSingleImg);
    const {imageMain} = useSelector((state: AppState) => state.damageLabImage);

    return (
        <Grid container spacing={1} direction="row" justifyContent="center">
            <Grid sx={classes.anim} item xs={12} sm={12} md={10} lg={10} xl={10}>
                <Box component='h1' sx={classes.text}>{damageLabData.license_plate_number}</Box>
                <Box sx={classes.pic}>
                    <img src={imageMain.image.src} alt='license number' />
                </Box>
            </Grid>
        </Grid>
    )
}

export default LicenseNumber;