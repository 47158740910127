import { CSSObject, Theme } from '@mui/material';

const collapse = (): CSSObject => ({
    height: '570px!important',
    overflowY: 'auto',
});

const sectionBox = (): CSSObject => ({
    width: '100%',
});

const sectionBoxMain = (): CSSObject => ({
    display: 'flex',
    width: '100%',
});

const icon = (): CSSObject => ({
    minWidth: '35px',
});

const sectionTitle = (): CSSObject => ({
    lineHeight: 1.5,
    marginBottom: 0,
});

const switchController = (): CSSObject => ({
    marginRight: '5px',
    marginLeft: '5px',
});

export const classes = { switchController, icon, sectionTitle, sectionBoxMain, collapse, sectionBox };
