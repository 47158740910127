import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserServices from '../../services/users/user-services';
import { StatusRedux } from '../../enums/StatusRedux';
import { IFetchArgs } from '../IFetch';
import { IUser } from '../../infrastructure/DTO/users/user.dto';
import { IArgsRouteUser } from '../../infrastructure/DTO/users/user-view.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchUserById = createAsyncThunk<IUser, IFetchArgs<IArgsRouteUser>>('user/get', async (params) => {
    return await UserServices.user(params);
});

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {} as IUser,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {
        clearStoreUser: (state) => {
            state.user = {} as IUser;
            state.error = {} as IErrorResponse;
            state.status = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserById.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchUserById.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.user = action.payload;
        });
        builder.addCase(fetchUserById.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export const { clearStoreUser } = userSlice.actions;
export default userSlice;
