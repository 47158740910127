import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFetchArgs, IFetchBody } from '../IFetch';
import BusinessUnitsServices from '../../services/business-units/business-units';
import { StatusRedux } from '../../enums/StatusRedux';
import {
    IActivateDeactivateBusinessUnit,
    IArgsBusinessUnit,
    IBusinessUnit,
    IBusinessUnits,
    ICreateBusinessUnit,
    IUpdateBusinessUnit,
} from '../../infrastructure/DTO/bussiness-unit/business-units.dto';
import { IInspectionSettings } from '../../infrastructure/DTO/threshold-settings/threshold-settings.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchGetBusinessUnits = createAsyncThunk<IBusinessUnits, IFetchArgs<IArgsBusinessUnit>>(
    'business-units/get',
    async (params: IFetchArgs<IArgsBusinessUnit>) => {
        return await BusinessUnitsServices.getBusinessUnits(params);
    }
);

export const fetchCreateBusinessUnit = createAsyncThunk<IBusinessUnit, IFetchBody<ICreateBusinessUnit, IArgsBusinessUnit>>(
    'business-units/post',
    async (params: IFetchBody<ICreateBusinessUnit, IArgsBusinessUnit>) => {
        return await BusinessUnitsServices.createBusinessUnit(params);
    }
);

export const fetchUpdateBusinessUnit = createAsyncThunk<IBusinessUnit, IFetchBody<IUpdateBusinessUnit, IArgsBusinessUnit>>(
    'business-units/put',
    async (params: IFetchBody<IUpdateBusinessUnit, IArgsBusinessUnit>) => {
        return await BusinessUnitsServices.updateBusinessUnit(params);
    }
);

export const fetchActivateDeactivateBusinessUnit = createAsyncThunk<IActivateDeactivateBusinessUnit, IFetchArgs<IArgsBusinessUnit>>(
    'business-units/activateDeactivate',
    async (params: IFetchArgs<IArgsBusinessUnit>) => {
        return await BusinessUnitsServices.activateDeactivateBusinessUnit(params);
    }
);

export const fetchThresholdsBusinessUnit = createAsyncThunk<IBusinessUnit, IFetchBody<IInspectionSettings, IArgsBusinessUnit>>(
    'business-units/thresholds',
    async (params: IFetchBody<IInspectionSettings, IArgsBusinessUnit>) => {
        return await BusinessUnitsServices.addThresholdsToBusinessUnit(params);
    }
);

export const fetchRemoveThresholdsBusinessUnit = createAsyncThunk<boolean, IFetchBody<IInspectionSettings, IArgsBusinessUnit>>(
    'business-units/remove/thresholds',
    async (params: IFetchBody<IInspectionSettings, IArgsBusinessUnit>) => {
        return await BusinessUnitsServices.removeThresholdsFromBusinessUnit(params);
    }
);

export const businessUnitsSlice = createSlice({
    name: 'business-units',
    initialState: {
        businessUnits: {} as IBusinessUnits,
        status: '',
        statusCreated: '',
        statusUpdated: '',
        statusIsActive: '',
        statusThresholds: '',
        errorThresholds: {} as IErrorResponse,
        statusRemoveThresholds: '',
        errorRemoveThresholds: {} as IErrorResponse,
        error: {} as IErrorResponse,
    },
    reducers: {
        clearStoreRemoveStatusThresholds: (state) => {
            state.statusRemoveThresholds = '';
            state.statusRemoveThresholds = '';
        },
        clearStoreBusinessUnits: (state) => {
            state.status = '';
            state.businessUnits = {} as IBusinessUnits;
        },
        clearStoreStatusUpdate: (state) => {
            state.statusUpdated = '';
            state.error = {} as IErrorResponse;
        },
        clearStoreStatusCreate: (state) => {
            state.statusCreated = '';
            state.error = {} as IErrorResponse;
        },
        clearStoreStatusActive: (state) => {
            state.statusIsActive = '';
        },
        clearStoreStatusThresholds: (state) => {
            state.statusThresholds = '';
            state.errorThresholds = {} as IErrorResponse;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetBusinessUnits.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchGetBusinessUnits.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.businessUnits = action.payload;
        });
        builder.addCase(fetchGetBusinessUnits.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchCreateBusinessUnit.pending, (state) => {
            state.statusCreated = StatusRedux.Loading;
        });
        builder.addCase(fetchCreateBusinessUnit.fulfilled, (state, action) => {
            state.statusCreated = StatusRedux.Succeeded;
            state.businessUnits.business_units.push(action.payload);
        });
        builder.addCase(fetchCreateBusinessUnit.rejected, (state, action) => {
            state.statusCreated = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchUpdateBusinessUnit.pending, (state) => {
            state.statusUpdated = StatusRedux.Loading;
        });
        builder.addCase(fetchUpdateBusinessUnit.fulfilled, (state, action) => {
            state.statusUpdated = StatusRedux.Succeeded;
            const index = state.businessUnits.business_units.findIndex((x: IBusinessUnit) => x.business_unit_id === action.payload.business_unit_id);

            state.businessUnits.business_units = [
                ...state.businessUnits.business_units.slice(0, index),
                { ...state.businessUnits.business_units[index], ...action.payload },
                ...state.businessUnits.business_units.slice(index + 1),
            ];
        });
        builder.addCase(fetchUpdateBusinessUnit.rejected, (state, action) => {
            state.statusUpdated = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchActivateDeactivateBusinessUnit.pending, (state) => {
            state.statusIsActive = StatusRedux.Loading;
        });
        builder.addCase(fetchActivateDeactivateBusinessUnit.fulfilled, (state, action) => {
            state.statusIsActive = StatusRedux.Succeeded;
            const index = state.businessUnits.business_units.findIndex((x) => x.business_unit_id === action.payload.business_unit_id);

            state.businessUnits.business_units = [
                ...state.businessUnits.business_units.slice(0, index),
                { ...state.businessUnits.business_units[index], is_active: !state.businessUnits.business_units[index].is_active },
                ...state.businessUnits.business_units.slice(index + 1),
            ];
        });
        builder.addCase(fetchActivateDeactivateBusinessUnit.rejected, (state, action) => {
            state.statusIsActive = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchThresholdsBusinessUnit.pending, (state) => {
            state.statusThresholds = StatusRedux.Loading;
        });
        builder.addCase(fetchThresholdsBusinessUnit.fulfilled, (state, action) => {
            state.statusThresholds = StatusRedux.Succeeded;
            const index = state.businessUnits.business_units.findIndex((x: IBusinessUnit) => x.business_unit_id === action.payload.business_unit_id);

            state.businessUnits.business_units = [
                ...state.businessUnits.business_units.slice(0, index),
                { ...state.businessUnits.business_units[index], ...action.payload },
                ...state.businessUnits.business_units.slice(index + 1),
            ];
        });
        builder.addCase(fetchThresholdsBusinessUnit.rejected, (state, action) => {
            state.statusThresholds = StatusRedux.Failed;
            state.errorThresholds = JSON.parse(action.error.message as string);
        });

        builder.addCase(fetchRemoveThresholdsBusinessUnit.pending, (state) => {
            state.statusRemoveThresholds = StatusRedux.Loading;
        });
        builder.addCase(fetchRemoveThresholdsBusinessUnit.fulfilled, (state, action) => {
            state.statusRemoveThresholds = StatusRedux.Succeeded;

            const index = state.businessUnits.business_units.findIndex(
                (x: IBusinessUnit) => x.business_unit_id === action.meta.arg.args?.business_unit_id
            );

            state.businessUnits.business_units = [
                ...state.businessUnits.business_units.slice(0, index),
                { ...state.businessUnits.business_units[index], inspection_settings: null },
                ...state.businessUnits.business_units.slice(index + 1),
            ];
        });
        builder.addCase(fetchRemoveThresholdsBusinessUnit.rejected, (state, action) => {
            state.statusRemoveThresholds = StatusRedux.Failed;
            state.errorRemoveThresholds = JSON.parse(action.error.message as string);
        });
    },
});

export const {
    clearStoreStatusUpdate,
    clearStoreBusinessUnits,
    clearStoreStatusCreate,
    clearStoreStatusActive,
    clearStoreStatusThresholds,
    clearStoreRemoveStatusThresholds,
} = businessUnitsSlice.actions;
export default businessUnitsSlice;
