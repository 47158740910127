import {alpha, CSSObject, Theme} from "@mui/material";

const paper = (): CSSObject => ({
    minHeight: 'calc(100vh - 200px)',
    width: '100%',
    position: 'relative'
})

const uploadBox = (): CSSObject => ({
    padding: '20px',
    width: '100%',
    height: 'calc(100vh - 200px)'
})

const titlePage = (): CSSObject => ({
    marginBottom: '20px!important',
    textTransform: 'uppercase'
})

const imgUpload = (): CSSObject => ({
    width: '100%',
    '& > img': {
        width: '100%',
        height:' calc(100vh - 240px)',
        objectFit: 'contain'
    },
})

const spinner = (theme: Theme): CSSObject => ({
    width: '100%',
    height: 'calc(100vh - 200px)',
    position: 'absolute',
    left: 0,
    right: 0,
    background: alpha(theme.palette.primary.main, 0.1)
})

export const classes = {
    paper, spinner, imgUpload, uploadBox, titlePage
}