import React from 'react';
import useResizeObserver from 'use-resize-observer';
import { classes } from '../../style';
import Konva from '../../../../../../../common/ui-components/Konva';
import { Group, Image as Pic, Layer, Line } from 'react-konva';
import { IDetectedDamages, IRoi } from '../../../../../../../infrastructure/DTO/damage-lab/damage-lab.dto';
import { IMatching, IZoom } from '../../../../../../../infrastructure/DTO/damage-lab/damage-matching.dto';
import { IPic } from '../../../../../../../infrastructure/DTO/multilayered/multilayered.dto';

interface IImageMatchingComponent {
    view: 'uploaded' | 'repository';
    roi: IRoi;
    image: IPic;
    damages: Array<IDetectedDamages>;
    matched_damages: IMatching;
    viewAll: boolean;
    color: string | undefined;
    viewCurrentId?: string | undefined;
    handleShowAllMatches: Function;
}

const ImageMatching: React.FC<IImageMatchingComponent> = (props: IImageMatchingComponent) => {
    const { ref = null, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
    const [coordinates, setCoordinates] = React.useState<{ x: number; y: number }>({
        x: 1,
        y: 1,
    });
    const [wheel, setWheel] = React.useState<boolean>(true);
    const [zoom, setZoom] = React.useState<IZoom>({
        isZoom: false,
        x: 1,
        y: 1,
        width: 1,
        height: 1,
    });
    const [points, setPoints] = React.useState<Map<number, { id: string; pointsArr: Array<number>; color: string; show: boolean }>>(
        new Map<
            number,
            {
                id: string;
                pointsArr: Array<number>;
                color: string;
                show: boolean;
            }
        >()
    );

    React.useEffect(() => {
        if (width > 1 && height > 1) {
            let scale = Math.min(width / props.image.width, height / props.image.height);

            setCoordinates({
                x: width / 2 - (props.image.width * scale) / 2,
                y: height / 2 - (props.image.height * scale) / 2,
            });

            props.matched_damages.matched_damages.forEach((matched) => {
                props.damages.forEach((damages, index) => {
                    if (props.view === 'repository') {
                        if (matched.matched_damage_id === damages.damage_id) {
                            let arrPoints: number[] = [];

                            damages.segmentation.contours.forEach((contours) => {
                                contours.forEach((p) => {
                                    arrPoints.push(Math.floor(p.x * scale), Math.floor(p.y * scale));
                                });
                            });

                            setPoints(
                                points.set(index, {
                                    id: damages.damage_id,
                                    pointsArr: arrPoints,
                                    color: `rgba(${matched.label_color.color_value})`,
                                    show: true,
                                })
                            );
                        }
                    }

                    if (props.view === 'uploaded') {
                        if (matched.detected_damage_id === damages.damage_id) {
                            let arrPoints: number[] = [];

                            damages.segmentation.contours.forEach((contours) => {
                                contours.forEach((p) => {
                                    arrPoints.push(Math.floor(p.x * scale), Math.floor(p.y * scale));
                                });
                            });

                            setPoints(
                                points.set(index, {
                                    id: damages.damage_id,
                                    pointsArr: arrPoints,
                                    color: `rgba(${matched.label_color.color_value})`,
                                    show: true,
                                })
                            );
                        }
                    }
                });
            });
        }
    }, [props.view, props.image.width, props.image.height, width, height]);

    React.useEffect(() => {
        Array.from(points.values()).forEach((i) => {
            i.show = i.id === props.viewCurrentId;
        });

        setWheel(false);
    }, [props.viewCurrentId]);

    React.useEffect(() => {
        if (props.viewAll) {
            Array.from(points.values()).forEach((i) => {
                i.show = false;
            });

            let scale = Math.min(width / props.image.width, height / props.image.height);

            setCoordinates({
                x: width / 2 - (props.image.width * scale) / 2,
                y: height / 2 - (props.image.height * scale) / 2,
            });

            setZoom({ ...zoom, isZoom: true, id: (+new Date()).toString(22), x: 0, y: 0 });
            setWheel(true);
        }
    }, [props.viewAll]);

    React.useEffect(() => {
        let scale = Math.min(width / props.image.width, height / props.image.height);

        setCoordinates({
            x: -(props.roi.x * scale),
            y: -(props.roi.y * scale),
        });

        setZoom({
            isZoom: true,
            id: props.viewCurrentId,
            x: props.roi.x * scale,
            y: props.roi.y * scale,
            width: props.roi.width * scale,
            height: props.roi.height * scale,
        });
    }, [props.roi, props.image, width, height]);

    return (
        <Konva zoomPerArea={zoom} wheel={wheel} konvaRef={ref} style={classes.stage} width={width} height={height}>
            <Layer>
                <Group x={coordinates.x} y={coordinates.y} draggable>
                    <Pic
                        width={props.image.width * Math.min(width / props.image.width, height / props.image.height)}
                        height={props.image.height * Math.min(width / props.image.width, height / props.image.height)}
                        image={props.image?.image as HTMLImageElement}
                    />
                    {props.viewAll
                        ? Array.from(points.values()).map((i, index) => (
                              <Line
                                  key={index + 1}
                                  points={i.pointsArr}
                                  stroke={i.color}
                                  strokeWidth={0.7}
                                  closed={true}
                                  lineCap='square'
                                  globalCompositeOperation='source-over'
                              />
                          ))
                        : Array.from(points.values()).map((i, index) => (
                              <Line
                                  visible={i.show}
                                  key={index + 1}
                                  points={i.pointsArr}
                                  stroke={props.color}
                                  strokeWidth={0.7}
                                  closed={true}
                                  lineCap='square'
                                  globalCompositeOperation='source-over'
                              />
                          ))}
                </Group>
            </Layer>
        </Konva>
    );
};

export default ImageMatching;
