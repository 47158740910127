import { ServicesBase } from '../ServicesBase';
import { IFetchArgs, IFetchBody } from "../../store/IFetch";
import { IArgsInspectionFlow, IInspectionFlow } from "../../infrastructure/DTO/Inspection-flow/Inspection-flow.dto";

class InspectionFlowServices extends ServicesBase {
    static async getInspectionFlow(params: IFetchArgs<IArgsInspectionFlow>): Promise<IInspectionFlow> {
        return await this.services('GET', `/home/companies/${params.args?.company_id}/profiles/${params.args.profile_id}/inspection_flow`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async updateInspectionFlow(params: IFetchBody<IInspectionFlow, IArgsInspectionFlow>): Promise<IInspectionFlow> {
        return await this.services(
            'PUT',
            `/home/companies/${params.args?.company_id}/profiles/${params.args?.profile_id}/inspection_flow`,
            JSON.stringify(params.body),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }
}

export default InspectionFlowServices;
