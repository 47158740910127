export enum InspectionStatusId {
    'REGISTERED',
    'IN_PROGRESS',
    'COMPLETED',
    'FAILED',
    'IN_QUEUE',
    'RESULT_SENT',
    'CLOSED',
}

export enum InspectionStatusColor {
    '#9a0036',
    '#ff9800',
    '#2196f3',
    '#0039cb',
    '#388e3c',
    '#d32f2f',
    '#00867d',
}

export enum InspectionStatusName {
    REGISTERED = 'REGISTERED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    IN_QUEUE = 'IN_QUEUE',
    CLOSED = 'CLOSED',
}
