import { IFetchArgs, IFetchBody } from '../../store/IFetch';
import { ServicesBase } from '../ServicesBase';
import { IUser, IUserCreate, IUsers, IUserUpdate } from '../../infrastructure/DTO/users/user.dto';
import { IArgsRouteUser, IChangePassword, IGenerateToken, IUserRequestView, IUsersView } from '../../infrastructure/DTO/users/user-view.dto';
import { IShortToken, IToken } from '../../infrastructure/DTO/users/token.dto';

class UserServices extends ServicesBase {
    static async login(params: IFetchBody<IUser>): Promise<IToken> {
        return await this.services('POST', `/api/account/login`, JSON.stringify(params.body), {
            'Content-Type': 'application/json',
        });
    }

    static async users(params: IFetchArgs<IUserRequestView>): Promise<IUsersView> {
        let url: string = `companies/users?${params.args.company_id ? `company_id=${params.args.company_id}` : ``}
        ${params.args.business_unit ? `&business_unit=${params.args.business_unit}` : ``}
        ${`&show_without_business_unit=${params.args.show_without_business_unit}`}
        ${params.args.user_id ? `&user_id=${params.args.user_id}` : ``}
        ${params.args.limit ? `&limit=${params.args.limit}` : ``}
        ${params.args.offset ? `&offset=${params.args.offset}` : ``}
        ${params.args.sort_by_name ? `&sortbyname=${params.args.sort_by_name}` : ``}
        ${params.args.sort ? `&sort=${params.args.sort}` : ``}
        ${params.args.search_text ? `&search_text=${params.args.search_text}` : ``}`
            .split(' ')
            .join('');

        return await this.services('GET', url, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async create(params: IFetchBody<IUserCreate, IArgsRouteUser>): Promise<IUser> {
        return await this.services('POST', `companies/${params.args?.company_id}/users`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async update(params: IFetchBody<IUserUpdate, IArgsRouteUser>): Promise<IUser> {
        return await this.services('PUT', `companies/${params.args?.company_id}/users/${params.args?.user_id}`, JSON.stringify(params.body), {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async user(params: IFetchArgs<IArgsRouteUser>): Promise<IUser> {
        return await this.services('GET', `/home/companies/${params.args?.company_id}/users/${params.args?.user_id}`, null, {
            Authorization: params.token as string,
        });
    }

    static async checkUserEmail(params: IFetchArgs<IArgsRouteUser>): Promise<IUsers> {
        return await this.services('GET', `/api/user/check/email/${params.args?.email}`, null, {
            'Content-Type': 'application/json',
        });
    }

    static async changeUserPassword(params: IFetchBody<IChangePassword, IArgsRouteUser>): Promise<IUser> {
        return await this.services(
            'POST',
            `api/companies/${params.args?.company_id}/users/${params.args?.user_id}/change_password`,
            JSON.stringify(params.body),
            {
                Authorization: params.token as string,
                'Content-Type': 'application/json',
            }
        );
    }

    static async activeUser(params: IFetchArgs<IArgsRouteUser>): Promise<Partial<IUser>> {
        return await this.services('POST', `api/companies/${params.args.company_id}/users/${params.args.user_id}/activation`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async deactivateUser(params: IFetchArgs<IArgsRouteUser>): Promise<Partial<IUser>> {
        return await this.services('POST', `api/companies/${params.args.company_id}/users/${params.args.user_id}/deactivation`, null, {
            Authorization: params.token as string,
            'Content-Type': 'application/json',
        });
    }

    static async generateToken(params: IFetchBody<IGenerateToken>): Promise<IToken> {
        return await this.services('POST', `oauth2/token`, JSON.stringify(params.body), {
            'Content-Type': 'application/json',
        });
    }

    static async generateTokenShort(params: IFetchBody<IGenerateToken>): Promise<IShortToken> {
        return await this.services('POST', `oauth2/url_shortener`, JSON.stringify(params.body), {
            'Content-Type': 'application/json',
        });
    }
}

export default UserServices;
