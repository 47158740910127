import {CSSObject} from "@mui/material";


const mainBlock = (): CSSObject => ({
    height: 'calc(100vh - 225px)'
})

const root = (): CSSObject => ({
    maxWidth: '100%',
})

const card = (): CSSObject => ({
    display: 'flex!important',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '350px',
})

const cardContent = (): CSSObject => ({
    textAlign: 'center'
})

const cardIcon = (): CSSObject => ({
    fontSize: '7em!important'
})

const titlePage = (): CSSObject => ({
    marginBottom: '10px',
    textTransform: 'uppercase'
})

export const classes = {
    mainBlock, root, card, cardContent, cardIcon, titlePage
}