import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { classes } from '../user/style';
import AddIcon from '@mui/icons-material/Add';
import { DialogContent, Paper } from '@mui/material';
import { AddRole } from './role/add-role';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { RoleView } from './role';
import { PermissionView } from './permission';
import { AddPermission } from './permission/add-permission';
import { TabPanel, TabsProps } from '../../../common/ui-components/Tab';

const RolePermission: React.FC = () => {
    const [valueTab, setValueTab] = React.useState<number>(0);
    const [create, setCreate] = React.useState<boolean>(false);

    const handleCreate = () => {
        setCreate(!create);
    };

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValueTab(newValue);
    };

    return (
        <>
            {valueTab ? <AddPermission open={create} close={handleCreate} /> : <AddRole open={create} close={handleCreate} />}
            <Grid container spacing={5} direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography variant='h6' noWrap sx={classes.titlePage}>
                        Role & Permission
                    </Typography>
                </Grid>
                <Grid item>
                    {valueTab ? (
                        <Button sx={classes.btn} onClick={handleCreate} size='small' variant='contained' color='secondary' endIcon={<AddIcon />}>
                            Create Permission
                        </Button>
                    ) : (
                        <Button sx={classes.btn} onClick={handleCreate} size='small' variant='contained' color='secondary' endIcon={<AddIcon />}>
                            Create Role
                        </Button>
                    )}
                </Grid>
            </Grid>
            <AppBar sx={classes.appBar} color='default' position='sticky'>
                <Tabs value={valueTab} onChange={handleChangeTab} aria-label='tabs'>
                    <Tab label='Roles' {...TabsProps(0)} />
                    <Tab label='Permissions' {...TabsProps(1)} />
                </Tabs>
            </AppBar>
            <Paper elevation={2}>
                <DialogContent sx={classes.content} dividers={true}>
                    <TabPanel value={valueTab} index={0}>
                        <RoleView />
                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                        <PermissionView />
                    </TabPanel>
                </DialogContent>
            </Paper>
        </>
    );
};

export default RolePermission;
