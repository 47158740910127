import React, { useContext } from 'react';
import { Button, Grid } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { UserPermission } from '../../../../../config';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { CustomAuthContext } from '../../../../../context/AuthProvider';
import Threshold from '../../threshold/threshold';

interface IInspectionStepperComponent {
    handlePrev(): void;
    handleNext(): void;
    showResetButton?: boolean;
    next: boolean;
    prev: boolean;
    id: number;
}

const InspectionStepper: React.FC<IInspectionStepperComponent> = (props: IInspectionStepperComponent) => {
    const { checkPermission } = useContext(CustomAuthContext);
    const [openResetThreshold, setOpenResetThreshold] = React.useState<boolean>(false);

    const handleResetThreshold = () => {
        setOpenResetThreshold(!openResetThreshold);
    };

    return (
        <>
            {openResetThreshold && <Threshold open={openResetThreshold} close={handleResetThreshold} />}
            <Grid container spacing={0} direction='row' justifyContent='flex-end' alignItems='baseline'>
                <Grid item xs={12}>
                    <div className='control-panel'>
                        <div className='number-buttons'>
                            <div className='num-page' data-active={props.id === 1}>
                                <span>1</span>
                                <span>DETAILS</span>
                            </div>
                            <ArrowForwardIosIcon />
                            <div className='num-page' data-active={props.id === 2}>
                                <span>2</span>
                                <span>image upload</span>
                            </div>
                            <ArrowForwardIosIcon />
                            <div className='num-page' data-active={props.id === 3}>
                                <span>3</span>
                                <span>finalize</span>
                            </div>
                        </div>
                        <div className='buttons'>
                            <Button
                                onClick={props.handlePrev}
                                disabled={props.prev}
                                variant='contained'
                                color='primary'
                                startIcon={<ArrowBackIosIcon />}
                            >
                                return
                            </Button>
                            {!checkPermission(UserPermission.ADMIN) && props.showResetButton && (
                                <Button onClick={handleResetThreshold} variant='contained' color='primary' endIcon={<AutorenewIcon />}>
                                    Reset Thresholds
                                </Button>
                            )}
                            <Button
                                onClick={props.handleNext}
                                disabled={props.next}
                                variant='contained'
                                color='secondary'
                                endIcon={<ArrowForwardIosIcon />}
                            >
                                Begin
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default InspectionStepper;
