import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusRedux } from '../../enums/StatusRedux';
import LanguageServices from '../../services/business-units/language';
import { IFetchArgs } from '../IFetch';
import { ILanguage } from '../../infrastructure/DTO/bussiness-unit/language.dto';
import { IErrorResponse } from '../../infrastructure/DTO/error/error-response.dto';

export const fetchLanguage = createAsyncThunk<Array<ILanguage>, IFetchArgs<null>>('language/get', async (params: IFetchArgs<null>) => {
    return await LanguageServices.getLanguage(params);
});

export const languageSlice = createSlice({
    name: 'language',
    initialState: {
        language: [] as Array<ILanguage>,
        status: '',
        error: {} as IErrorResponse,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchLanguage.pending, (state) => {
            state.status = StatusRedux.Loading;
        });
        builder.addCase(fetchLanguage.fulfilled, (state, action) => {
            state.status = StatusRedux.Succeeded;
            state.language = action.payload;
        });
        builder.addCase(fetchLanguage.rejected, (state, action) => {
            state.status = StatusRedux.Failed;
            state.error = JSON.parse(action.error.message as string);
        });
    },
});

export default languageSlice;
